/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import moment from "moment";

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import DatePickerUi from "../../../components/form/DatePickerUi";
import validator from "./validator"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import AutoComplete from "../../../components/form/AutoComplete";
import { PageSize } from "../../../config/const"

import { getOrderId } from "../../../store/transactions/thunk/order/getId"
import { postOrder } from "../../../store/transactions/thunk/order/post"
import { putOrder } from "../../../store/transactions/thunk/order/put"
import { getClient } from "../../../store/masters/thunk/client/getAll";
import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";

const NewEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("order");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.order.detail);
    const put = useSelector(state => state.transactions.order.put);
    const post = useSelector(state => state.transactions.order.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const client = useSelector(state => state.masters.client);

    const orderOperativeStatus = __(`orderOperativeStatus`, { returnObjects: true });
    const orderStatus = __(`orderStatus`, { returnObjects: true });
    const clients = map(client?.data, ({ idClient, name }) => ({ value: idClient, label: name }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}` }));

    const isActive = "isActive==true";

    const getDataClient = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getClient(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `deliveryName@=${search},${isActive}`
        dispatch(getDeliveryPoint(params))
    }

    useEffect(() => {
        if (!!id) {
            dispatch(getOrderId({ id }))
        }
        getDataClient()
        getDataDelivery()
    }, [dispatch, id])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/transactions/orders/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
    }, [post])


    const submitPut = (values) => {
        const body = {
            ...values,
        }

        dispatch(putOrder(body))
    }

    const onSubmit = (values) => {
        const idOrder = id;
        const body = {
            ...values,
            creationDate: get(current, "data.creationDate", ""),
            deliveryComments: get(values, "deliveryComments"),
            deliveryDate: get(values, "deliveryDate"),
            docNum: get(values, "docNum"),
            idClient: Number(get(values, "idClient")),
            idDeliveryPoint: Number(get(values, "idDeliveryPoint")),
            idOrder: get(values, "idOrder"),
            idOrderOperativeStatus: Number(get(values, "idOrderOperativeStatus")),
            idOrderStatus: Number(get(values, "idOrderStatus")),
            idUser: get(values, "idUser"),
            isActive: get(values, "isActive"),
        }
        const whitOutNulls = pickBy(body, identity);

        if (idOrder) { submitPut(whitOutNulls) }
        else { dispatch(postOrder(whitOutNulls)) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,
        docNum: id ? get(current, "data.docNum", "") : "",
        // creationDate: moment().format(),
        // deliveryDate: "",
        idOrderStatus: id ? get(current, "data.idOrderStatus", 1) : 1,
        idOrderOperativeStatus: id ? get(current, "data.idOrderOperativeStatus", 1) : 1,
        idClient: id ? get(current, "data.idClient", "") : "",
        idDeliveryPoint: id ? get(current, "data.idDeliveryPoint", "") : "",
        deliveryComments: id ? get(current, "data.deliveryComments", "") : "",
        idUser: get(user, "idUser"),
    }

    return (
        <Layout
            propsToolbar={{
                title: !id ? __(`head.new.name`) : __(`head.edit.name`),
                srute: !id ? __(`head.new.code`) : id
            }}
        >
            <div>
                {get(current, "isLoading")
                    ? (
                        <Skeleton />
                    ) : (
                        <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                            {formik => (
                                <form onSubmit={get(formik, "handleSubmit")}>
                                    <Paper className='p-4'>
                                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                                            <LoadingButton variant="contained" color="secondary" type="submit" loading={get(post, "isLoading") || get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                            <Button variant='outlined' color="secondary" onClick={() => navigate("/transactions/orders")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                                        </Stack>
                                    </Paper>
                                    <Paper className='mt-4'>
                                        <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                            <Typography component="h4" variant="h4">{__(`labels.2`)}</Typography>
                                            <Switch formik={formik} name="isActive" __={__} />
                                        </Stack>
                                        <Divider />
                                        <Box className='p-4' >
                                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <InputText
                                                        formik={formik}
                                                        name="docNum"
                                                        label={__(`form.docNum.label`)}
                                                        placeholder={__(`form.docNum.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <DatePickerUi
                                                        formik={formik}
                                                        name="deliveryDate"
                                                        label={__(`form.deliveryDate.label`)}
                                                        placeholder={__(`form.deliveryDate.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    />
                                                </Grid>
                                                {id &&
                                                    <AutoComplete
                                                        name={"idOrderStatus"}
                                                        formik={formik}
                                                        label={__(`form.idOrderStatus.label`)}
                                                        placeholder={__(`form.idOrderStatus.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        options={orderStatus}
                                                    />
                                                }
                                                {id &&
                                                    <AutoComplete
                                                        name={"idOrderOperativeStatus"}
                                                        formik={formik}
                                                        label={__(`form.idOrderOperativeStatus.label`)}
                                                        placeholder={__(`form.idOrderOperativeStatus.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        options={orderOperativeStatus}
                                                    />
                                                }
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <AutoComplete
                                                        name={"idClient"}
                                                        formik={formik}
                                                        label={__(`form.idClient.label`)}
                                                        placeholder={__(`form.idClient.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        options={clients}
                                                        reload={getDataClient}
                                                        loading={get(client, "isLoading")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <AutoComplete
                                                        name={"idDeliveryPoint"}
                                                        formik={formik}
                                                        label={__(`form.idDeliveryPoint.label`)}
                                                        placeholder={__(`form.idDeliveryPoint.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        options={deliveryPoints}
                                                        reload={getDataDelivery}
                                                        loading={get(deliveryPoint, "isLoading")}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={6} md={4}>
                                                    <DatePickerUi
                                                        formik={formik}
                                                        name="startDate"
                                                        label={__(`form.startDate.label`)}
                                                        placeholder={__(`form.startDate.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    />
                                                </Grid> */}
                                                {/* <Grid item xs={12} sm={6} md={4}>
                                                    <DatePickerUi
                                                        formik={formik}
                                                        name="endDate"
                                                        label={__(`form.endDate.label`)}
                                                        placeholder={__(`form.endDate.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12} sm={6} md={4} >
                                                    <InputText
                                                        formik={formik}
                                                        name="deliveryComments"
                                                        label={__(`form.deliveryComments.label`)}
                                                        placeholder={__(`form.deliveryComments.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </form>
                            )}
                        </Formik>
                    )}
                <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
                <Alert
                    title={get(alert, "title")}
                    subtitle={get(alert, "subtitle")}
                    btn1={{ func: get(alert, "func") }}
                    btn2={{}}
                    type={get(alert, "type")}
                    openAlert={get(alert, "open")}
                    closeAlert={closeAlert}
                />
            </div>
        </Layout>
    )
}

export default NewEdit