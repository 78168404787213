import {
    getTransportLoading,
    getTransportReject,
    getTransportRequest,
    getTransportSuccess
} from "../../actions/transport/getAll";

export const getTransport = (formData) => async (dispatch, getState) => {
    dispatch(getTransportLoading());
    try {
        const { data } = await getTransportRequest(formData, getState);
        dispatch(getTransportSuccess(data))
    } catch (error) {
        dispatch(getTransportReject(error))
    }
    return Promise.resolve();
};