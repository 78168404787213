import { combineReducers } from 'redux';

import auth from './auth/reducers';
import masters from './masters/reducers';
import reports from './reports/reducers';
import transactions from './transactions/reducers';
import catchError from "./catchError"

export const appReducer = combineReducers({
    auth,
    masters,
    reports,
    transactions,
    catchError
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer;
