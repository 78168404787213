import React, { useEffect } from 'react';
import { Toolbar, Grid, Stack, IconButton, Paper } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { useFormik } from 'formik';
import get from "lodash/get";

import DatePickerUi from "../../../components/form/DatePickerUi";

const ToolbarComponent = ({ setFilter, __, reload, isLoading }) => {

    const onSubmit = (values) => {
        const Filters = {};

        if (values.from && values.till) {
            Filters.from = values.from;
            Filters.till = values.till;
        }

        setFilter(Filters)
    }

    const formik = useFormik({
        initialValues: {
            from: null,
            till: null,
        },
        onSubmit,
    });

    useEffect(() => {
        onSubmit(formik.values)
    }, [formik.values.from, formik.values.till, formik.values.idClient])

    return (
        <Toolbar component={Paper} className='p-2 mb-4' sx={{ borderBottomWidth: "1px", borderBottomColor: "text.sslite", borderBottomStyle: "solid" }}>
            <Stack component="form" onSubmit={get(formik, "handleSubmit")} direction={{ sx: "column", xl: "row" }} alignItems={{ sx: "flex-start", xl: "center" }} justifyContent={{ sx: "center", xl: "space-between" }} spacing={{ sx: 1, xl: 1 }} className='w-full'>
                <Grid container spacing={1} justifyContent={{ sx: "flex-start", md: "flex-end" }}>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <DatePickerUi
                            formik={formik}
                            name="from"
                            label={__(`form.from.label`)}
                            labelInput
                            placeholder={__(`form.from.placeholder`)}
                            disabled={isLoading}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <DatePickerUi
                            formik={formik}
                            name="till"
                            label={__(`form.till.label`)}
                            labelInput
                            placeholder={__(`form.till.placeholder`)}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Stack className='ml-[10px] h-[40px] mt-auto' direction="row" spacing={1} justifyContent="flex-end">
                        <IconButton title={__(`toolbar.reload`)} onClick={reload} disabled={isLoading}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
                    </Stack>
                </Grid>
            </Stack>
        </Toolbar>
    )
}

export default ToolbarComponent