/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Typography, FormControl, TextField } from '@mui/material';
import get from "lodash/get";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import moment from "moment";
import { useEffect } from 'react';

const DatePickerUi = ({ children, formik, name, label, disabled, labelInput = null, ...props }) => {
    const [value, setValue] = useState(null)
    moment.locale("es")

    const onChange = (e) => {
        setValue(e)
        formik.setFieldValue([name], moment(e).format())
    }
    useEffect(() => {
        setValue(get(formik, `values.[${name}]`))
    }, [])

    return (
        <FormControl fullWidth >
            {!labelInput &&
                <Typography className='pb-2 pl-4' component="label" htmlFor="username" color={disabled ? "text.lite" : "text.main"} >
                    {label}
                </Typography>
            }
            <DatePicker
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                defaultValue={get(formik, `values.[${name}]`)}
                sx={{ "& .MuiInputBase-root": { height: 40 } }}
                disabled={disabled}
                slotProps={{ textField: { size: 'small' } }}
                {...props}
                {...(labelInput ? { label } : {})}
            />
        </FormControl>
    )
}

export default DatePickerUi