/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { get, map, isEmpty, find, filter } from "lodash";
import {
  IconButton,
  Stack,
  Button,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import ReplayIcon from '@mui/icons-material/Replay';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import OrdersModal from "./OrdersModal"
import InputText from "../../../components/form/InputText";
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { postUploadInvoiceDetails } from "../../../store/transactions/thunk/invoice/postUploadInvoiceDetails";
import { postInvoiceDocument } from "../../../store/transactions/thunk/invoice/postInvoiceDocument";
import { getOrdersToInvoice } from "../../../store/transactions/thunk/invoice/getOrdersToInvoiceAll";
import { getInvoiceId } from "../../../store/transactions/thunk/invoice/getId"

const Orders = ({ setBtns, isEdit }) => {
  const [__] = useTranslation("invoice");
  const [__o] = useTranslation("order");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
  const [openModal, setOpenModal] = useState(false);
  const [ordersSelectedFull, setOrdersSelectedFull] = useState([])
  const [openQuestion, setOpenQuestion] = useState(null);
  const [openQuestionBilling, setOpenQuestionBilling] = useState(null);
  const [toEdit, setToEdit] = useState({});
  const [valueTotal, setValueTotal] = useState({});
  const [toSelected, setToSelected] = useState({});

  const current = useSelector(state => state.transactions.invoice.detail);
  const post = useSelector(state => state.transactions.invoice.invoiceDetails);
  const document = useSelector(state => state.transactions.invoice.document);

  const getData = () => {
    dispatch(getInvoiceId({ id }))
  }
  const getOrder = () => {
    dispatch(getOrdersToInvoice({ idClient: get(current, "data.idClient") }))
  }

  const reload = async () => {
    await getData()
    getOrder()
  }

  useEffect(() => {
    setOrdersSelectedFull(get(current, "data.invoiceDetailList", []))
  }, [get(current, "data.invoiceDetailList.length")])

  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        {isEdit ? <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="primary" loading={get(document, "isLoading")} onClick={() => setOpenQuestionBilling(true)}>{__(`toolbar.billing`)}</LoadingButton> : <div className='flex-1' />}
        {isEdit ? <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.btn`)}</Button> : <div className='flex-1' />}
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [isEdit])

  const onDeleteQuestion = (row) => (e) => {
    setOpenQuestion(true)
    setSelected(row)
  };

  const handleOpenMenu = (row) => (event) => {
    setToSelected(row)
    setAnchorEl(event.currentTarget);
  };

  const onCancel = () => {
    setAnchorEl(null);
    setSelected({})
    setOpenModal(false)
    setOpenQuestion(false)
    setOpenQuestionBilling(false)
    setValueTotal(undefined)
    setToEdit({})
    setToSelected({})
  };


  const onDelete = () => {
    const newsOrders = filter(ordersSelectedFull, ({ idOrder }) => idOrder !== get(selected, "idOrder"));
    const body = {
      orders: map(newsOrders, ({ idOrder, total, idInvoice }) => ({ idOrder, total, idInvoice })),
      idInvoice: Number(id)
    }
    dispatch(postUploadInvoiceDetails(body))
  }

  const onBilling = () => {
    dispatch(postInvoiceDocument({ idInvoice: Number(id) }))
  }

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    setOpenQuestion(false)
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
    onCancel()
  }

  useEffect(() => {
    if (get(post, "isSuccess", false)) { setOpenModal(false); onCancel(); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(post, "isReject", false)) { setError(get(post, "error", {}), "put") }
    if (get(document, "isSuccess", false)) { setOpenModal(false); onCancel(); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(document, "isReject", false)) { setError(get(document, "error", {}), "put") }
  }, [post, document])


  const onEdit = (row) => () => {
    if (!!valueTotal) {
      const newObject = { ...row, total: Number(valueTotal) || 0 }
      const body = {
        orders: map(ordersSelectedFull, ({ idInvoicesDetail, ...rest }) => idInvoicesDetail === row.idInvoicesDetail ? ({ ...newObject }) : ({ ...rest })),
        idInvoice: Number(id)
      }
      dispatch(postUploadInvoiceDetails(body))
    } else {
      onCancel()
    }
  }

  const headTable = [
    {
      key: "idOrder",
      label: __(`table.colsOrders.idOrder`),
      align: "left"
    },
    {
      key: "orderInvoice.docNum",
      label: __(`table.colsOrders.docNum`),
      align: "left"
    },
    {
      key: "total",
      label: __(`table.colsOrders.total`),
      align: "center",
    },
    {
      ...(!isEdit ? {} :
        {
          key: "options",
          label: __(`table.colsOrders.options`),
          align: "center",
          sx: { width: 80, position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
        }
      ),
    },
  ]

  const dataTable = map(get(current, "data.invoiceDetailList", []), (row, i) => ({
    ...row,
    total: toEdit.idOrder === row.idOrder ? (
      <Box className='flex justify-center items-center' sx={{ ".MuiInputBase-root": { height: 25 } }}>
        <InputText name="total" size="small" defaultValue={row.total} sx={{ width: 75, }} fullWidth={false} onChange={(e) => setValueTotal(e.target.value)} />
        <div className='pl-4'>
          <IconButton aria-label="edit" size="small" onClick={onEdit(row)}>
            {get(post, "isLoading", false)
              ? <CircularProgress size={20} />
              : <EditIcon fontSize="inherit" />
            }
          </IconButton>
        </div>
        <div className='pl-2'>
          <IconButton aria-label="delete" size="small" onClick={onCancel}>
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Box>
    ) : row.total,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  return (
    <div>

      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(current, "isLoading", false)}
        empty="orders"
      />
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={onCancel}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={() => { setToEdit(toSelected); setAnchorEl(null) }}>{__(`table.menu.edit`)}</MenuItem>
        <MenuItem onClick={onDeleteQuestion(toSelected)}>{__(`table.menu.delete`)}</MenuItem>
      </Menu>
      <OrdersModal
        __={__}
        __o={__o}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        ordersSelected={ordersSelectedFull}
      />
      <Question
        title={__("alert.deleteOrder.title")}
        detail={__("alert.deleteOrder.detail")}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(post, "isLoading")}
      />
      <Question
        title={__("alert.billing.title")}
        detail={__("alert.billing.detail")}
        onSuccess={onBilling}
        onCancel={() => setOpenQuestionBilling(false)}
        open={openQuestionBilling}
        loading={get(post, "isLoading")}
      />
      {/* <Question
        title={__("alert.liqOrder")}
        detail={""}
        onSuccess={liquidate}
        onCancel={() => setOpenQuestionLiq(false)}
        open={openQuestionLiq}
      /> */}
    </div >
  )
}

export default Orders;
