import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_TRANSPORT_RESET = 'DELETE_TRANSPORT_RESET';
export const DELETE_TRANSPORT_LOADING = 'DELETE_TRANSPORT_LOADING';
export const DELETE_TRANSPORT_SUCCESS = 'DELETE_TRANSPORT_SUCCESS';
export const DELETE_TRANSPORT_REJECT = 'DELETE_TRANSPORT_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteTransportReset = () => ({ type: DELETE_TRANSPORT_RESET });
export const deleteTransportLoading = () => ({ type: DELETE_TRANSPORT_LOADING });
export const deleteTransportSuccess = (payload) => ({ type: DELETE_TRANSPORT_SUCCESS, payload });
export const deleteTransportReject = (payload) => ({ type: DELETE_TRANSPORT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteTransportRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Transport/${data.id}`, options);
};