/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { func, shape, bool, any } from 'prop-types';
import { useTranslation } from "react-i18next";
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import { Backdrop } from "@mui/material"
import CircularProgress from "../form/CircularProgress"

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const libraries = ["places"];

const ComponentMap = ({
    hasTwoAddresses,
    coord,
    initial,
    response,
    setResponse,
}) => {
    const directionsCallback = (response) => {
        if (response !== null && response.status === 'OK') {
            setResponse(response);
        }
    };

    return (
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
            <GoogleMap
                id="location-map"
                mapContainerStyle={{
                    flex: 1
                }}
                {...(hasTwoAddresses ? {} : {
                    zoom: 9,
                    center: {
                        lat: initial.lat,
                        lng: initial.lng,
                    }
                })}
                options={{
                    panControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                }}
                mapTypeId="terrain" //roadmap|satellite|hybrid|terrain
            >
                {
                    hasTwoAddresses && (
                        <DirectionsService
                            options={{
                                origin: get(coord, "[0]"),
                                destination: get(coord, "[1]"),
                                travelMode: 'DRIVING'
                            }}
                            callback={directionsCallback}
                        >
                        </DirectionsService>
                    )
                }
                {
                    hasTwoAddresses && response !== null && (
                        <DirectionsRenderer
                            options={{
                                directions: response,
                                suppressMarkers: true
                            }}
                        />
                    )
                }

                <MarkerF
                    key={"marker_B"}
                    options={{
                        icon: {
                            url: "/person.png",
                            scale: 0.05
                        },
                    }}
                    position={get(coord, "[0]")}
                />
                <MarkerF
                    key={"marker_A"}
                    options={{
                        icon: {
                            url: "/truck.png",
                            scale: 0.05
                        },
                    }}
                    position={get(coord, "[1]")}
                />
            </GoogleMap>
        </div>
    );
}

const Map = ({ coords, loading }) => {
    const [__] = useTranslation("global");
    const [response, setResponse] = useState(null);
    const [coord, setCoord] = useState([]);
    const [isLoad, setIsLoad] = useState();
    const initial = {
        lat: Number(__("map.initial.lat")),
        lng: Number(__("map.initial.lng")),
    }

    useEffect(() => {
        if (!!get(coords, "[0].lat") && !!get(coords, "[0].lng") && !!get(coords, "[1].lat") && !!get(coords, "[1].lng")) {
            setIsLoad(true)
            setCoord(coords)
            setTimeout(() => {
                setCoord([{ lat: null, lng: null }, { lat: null, lng: null }])
            }, 100);
        } else {
            setCoord([{ lat: null, lng: null }, { lat: null, lng: null }])
        }
    }, [coords])


    const { isLoaded, loadError } = useLoadScript({
        id: `google-map-${__("lang.current.lang")}`,
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        language: __("lang.current.lang"),
        libraries: libraries
    });

    // const hasTwoAddresses = !isEmpty(get(coord, "[0]"), {}) && !isEmpty(get(coord, "[1]", {}))
    const hasTwoAddresses = !!get(coords, "[0].lat") && !!get(coords, "[0].lng") && !!get(coords, "[1].lat") && !!get(coords, "[1].lng")

    if (loadError) {
        return <div>Lo sentimos, el mapa no puede ser cargado por el momento</div>;
    }

    return (
        <div className="h-full w-full relative">
            {isLoaded &&
                <div className='relative flex flex-col h-full w-full'>
                    <ComponentMap
                        hasTwoAddresses={hasTwoAddresses}
                        response={response}
                        setResponse={setResponse}
                        coord={coord}
                        initial={initial}
                    />

                </div>
            }
            <Backdrop
                className='!absolute'
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!isLoaded || loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};


export default Map;


