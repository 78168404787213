/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControl,
    InputAdornment,
    IconButton,
    Paper,
    Stack,
    Button,
    Alert as AlertUi,
    AlertTitle,
    Collapse,
    OutlinedInput,
    FormHelperText
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { get, map, isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff, Error as ErrorIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from '@mui/lab';

import LayoutAuth from "../../../components/layout/LayoutAuth";
import validator from "./validator";

import { register } from "../../../store/auth/thunk/register";

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const registerState = useSelector(state => state.auth.login);
    const [showPass, setShowPass] = useState(false);
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error" })

    const [step, setStep] = useState(0);

    const [__, i18n] = useTranslation("register");
    const inputs = __(`input`, { returnObjects: true })
    const links = __(`link`, { returnObjects: true })

    const handleClickShowPassword = () => {
        setShowPass(state => !state)
    }

    const onSubmit = (values) => {
        if (step === 0) {
            setStep(1)
            return;
        }
        dispatch(register({ ...values, login: get(values, "email") }))
    }

    const onBack = () => {
        setStep(state => state - 1)
    }

    const formik = useFormik({
        initialValues: {
            userName: '',
            phone: '',
            email: '',
            companyName: '',
            // passWord: 'Raul.123',
            // confirmation: 'Raul.123',
            passWord: '',
            confirmation: '',
            deviceToken: process.env.REACT_APP_DEVICE_TOKEN,
            idLanguage: i18n.resolvedLanguage
        },
        validationSchema: validator(inputs, step),
        onSubmit
    });

    useEffect(() => {
        let timer = null;
        if (get(registerState, "failUser")) {
            navigate("/login")
        } else if (get(registerState, "isLoged")) {
            navigate("/")
        } else if (get(registerState, "isReject") && !isEmpty(get(formik, "touched"))) {
            const msg = get(registerState, "allResp")
            setShowNoti({ open: true, msg, variant: "error" })
            timer = setTimeout(() => {
                localStorage.clear()
            }, 3000);
        } else {
            setShowNoti({ open: false, msg: undefined, variant: "" })
        }
        return () => {
            clearTimeout(timer)
        }
    }, [registerState, navigate, isEmpty(get(formik, "touched"))])

    return (
        <LayoutAuth title={__(`name`)} type={step === 0 ? "register" : step === 1 ? "pass" : "shield"} onBack={!!step && onBack}>
            <Box className='flex flex-col justify-center items-center h-full' >
                <Paper className='py-8 px-4 sm:px-12 w-full relative' elevation={3} style={{ minWidth: 360, maxWidth: "calc(100vw - 40px)" }} >
                    <Box className='mb-2 text-center' ><Typography variant='h3'>{__(`title`)}</Typography></Box>
                    <Box className='mb-4 text-center' ><Typography variant='caption'>{__(`subtitle`)}</Typography></Box>
                    <Collapse in={get(showNoti, "open")}>
                        <AlertUi severity="error" sx={{ mb: 2 }} icon={<ErrorIcon sx={{ color: "error.dark" }} />}>
                            <AlertTitle><Typography variant="body1" color="error.dark">{get(showNoti, "msg.Message") || `Code: ${get(showNoti, "msg.ErrorCode")}`}</Typography></AlertTitle>
                            {isEmpty(get(showNoti, "msg.ValidationError", []))
                                ? (
                                    <Typography variant="caption">{get(showNoti, "msg.ErrorMessage")}</Typography>
                                ) : (
                                    <ul>
                                        {map(get(showNoti, "msg.ValidationError", []), (err, i) => (
                                            <Typography key={i} component="li" variant="caption">- {err}</Typography>
                                        ))}
                                    </ul>
                                )
                            }
                        </AlertUi>
                    </Collapse>
                    <Box component="form" onSubmit={get(formik, "handleSubmit")}>
                        {step === 0 &&
                            <Box>
                                <Box className='mb-8'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="userName" >
                                            {get(inputs, "user.name")} <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="username"
                                            name="userName"
                                            placeholder={get(inputs, "user.placeholder")}
                                            value={get(formik, "values.userName")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.userName") && Boolean(get(formik, "errors.userName"))}
                                            helperText={get(formik, "touched.userName") && get(formik, "errors.userName")}
                                        />
                                    </FormControl>
                                </Box>
                                <Box className='mb-8'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="phone" >
                                            {get(inputs, "phone.name")}
                                        </Typography>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            placeholder={get(inputs, "phone.placeholder")}
                                            value={get(formik, "values.phone")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.phone") && Boolean(get(formik, "errors.phone"))}
                                            helperText={get(formik, "touched.phone") && get(formik, "errors.phone")}
                                        />
                                    </FormControl>
                                </Box>
                                <Box className='mb-8'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="email" >
                                            {get(inputs, "email.name")} <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="email"
                                            name="email"
                                            placeholder={get(inputs, "email.placeholder")}
                                            value={get(formik, "values.email")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.email") && Boolean(get(formik, "errors.email"))}
                                            helperText={get(formik, "touched.email") && get(formik, "errors.email")}
                                        />
                                    </FormControl>
                                </Box>
                                <Box className='mb-8'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="companyName" >
                                            {get(inputs, "company.name")} <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="companyNombre"
                                            name="companyName"
                                            placeholder={get(inputs, "company.placeholder")}
                                            value={get(formik, "values.companyName")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.companyName") && Boolean(get(formik, "errors.companyName"))}
                                            helperText={get(formik, "touched.companyName") && get(formik, "errors.companyName")}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        }
                        {step > 0 &&
                            <Box>
                                <Box className='mb-8'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="pass" >
                                            {get(inputs, "pass.name")} <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <OutlinedInput
                                            size="small"
                                            id="pass"
                                            name="passWord"
                                            placeholder={get(inputs, "pass.placeholder")}
                                            type={showPass ? 'text' : 'password'}
                                            value={get(formik, "values.passWord")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.passWord") && Boolean(get(formik, "errors.passWord"))}
                                            helperText={get(formik, "touched.passWord") && get(formik, "errors.passWord")}
                                            InputProps={{ autoComplete: 'new-password' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPass ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormHelperText id="helper-password" error={get(formik, "touched.passWord") && Boolean(get(formik, "errors.passWord"))} >
                                        {get(formik, "touched.passWord") && get(formik, "errors.passWord")}
                                    </FormHelperText>
                                </Box>
                                <Box className='mb-6'>
                                    <FormControl fullWidth >
                                        <Typography className='pb-2' component="label" htmlFor="confirmation" >
                                            {get(inputs, "confirm.name")} <Typography component="span" color="error">*</Typography>
                                        </Typography>
                                        <OutlinedInput
                                            size="small"
                                            id="confirmation"
                                            name="confirmation"
                                            placeholder={get(inputs, "confirm.placeholder")}
                                            type={showPass ? 'text' : 'password'}
                                            value={get(formik, "values.confirmation")}
                                            onChange={get(formik, "handleChange")}
                                            error={get(formik, "touched.confirmation") && Boolean(get(formik, "errors.confirmation"))}
                                            helperText={get(formik, "touched.confirmation") && get(formik, "errors.confirmation")}
                                            InputProps={{ autoComplete: 'new-password' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPass ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="helper-confirm" error={get(formik, "touched.confirmation") && Boolean(get(formik, "errors.confirmation"))} >
                                            {get(formik, "touched.confirmation") && get(formik, "errors.confirmation")}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Box>
                        }
                        <Stack spacing={3}>
                            <LoadingButton
                                color="secondary"
                                variant="contained" fullWidth
                                type="submit"
                                size='large'
                                loading={get(registerState, "isLoading")}
                                disabled={!isEmpty(get(formik, "errors"))}>
                                {__(`button.submit`)}
                            </LoadingButton>

                            <Button component={Link} to={get(links, "login.href")} fullWidth variant="text" size='large' color="secondary">
                                {get(links, "login.name")}
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </LayoutAuth>
    )
}

export default Register