import Routes from "../../../../pages/Report/Routes";

const routerRoutes = [
    {
        key: "routes",
        path: "/report/routes",
        element: <Routes />,
    },
]

export default routerRoutes
