import {
    postRouteLoading,
    postRouteRequest,
    postRouteSuccess,
    postRouteReject,
    postRouteReset
} from "../../actions/route/post";
import {
    getRouteIdRequest,
    getRouteIdSuccess
} from "../../actions/route/getId"

export const postRoute = (formData) => async (dispatch, getState) => {
    dispatch(postRouteLoading());
    try {
        const { data } = await postRouteRequest(formData, getState);
        const id = data?.id
        const getId = await getRouteIdRequest({id}, getState);
        await dispatch(getRouteIdSuccess(getId?.data))
        dispatch(postRouteSuccess(data))
    } catch (error) {
        dispatch(postRouteReject(error))
    } finally {
        setTimeout(() => { dispatch(postRouteReset()) }, 3000);
    }
    return Promise.resolve();
};