import React, { useState } from 'react'
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { LoadingButton } from '@mui/lab';

const Question = ({ title, detail, open, onSuccess, onCancel, loading = false }) => {
    const [__] = useTranslation("global");

    return (
        <Dialog onClose={onCancel} open={open} maxWidth="xs" fullWidth>
            <Card sx={{ border: "1px solid lightgray" }} elevation={4}>
                <CardContent>
                    <Typography variant="h4" color="text.secondary">
                        {title}
                    </Typography>
                    <Typography component="pre" className='pt-2' variant="body2" color="text.secondary">
                        {detail}
                    </Typography>
                </CardContent>
                <CardActions classes={{ root: "justify-end" }}>
                    <LoadingButton className='w-[100px] min-w-[100px]' size="small" onClick={onSuccess} variant="contained" loading={loading} >{__("btn.accept")}</LoadingButton>
                    <Button className='w-[100px] min-w-[100px]' size="small" onClick={onCancel} variant="outlined" >{__("btn.cancel")}</Button>
                </CardActions>
            </Card>
        </Dialog>
    )
}

export default Question