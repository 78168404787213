import {
    putRouteCostLoading,
    putRouteCostRequest,
    putRouteCostSuccess,
    putRouteCostReject,
    putRouteCostReset
} from "../../actions/route/putCost";

export const putRouteCost = (formData) => async (dispatch, getState) => {
    dispatch(putRouteCostLoading());
    try {
        const { data } = await putRouteCostRequest(formData, getState);
        dispatch(putRouteCostSuccess(data))
    } catch (error) {
        dispatch(putRouteCostReject(error))
    } finally {
        setTimeout(() => { dispatch(putRouteCostReset()) }, 3000);
    }
    return Promise.resolve();
};