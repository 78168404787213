/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import moment from "moment";

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import DatePickerUi from "../../../components/form/DatePickerUi";
import validator from "./validator"
import Skeleton from "../../../components/form/Skeleton"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import PhotoModal from "./PhotoModal";
import { PageSize } from "../../../config/const"

import { getOrderId } from "../../../store/transactions/thunk/order/getId"
import { postOrder } from "../../../store/transactions/thunk/order/post"
import { putOrder } from "../../../store/transactions/thunk/order/put"
import { getClient } from "../../../store/masters/thunk/client/getAll";
import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";

const NewEdit = ({ setBtns }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("order");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [isMount, setIsMount] = useState(false);
    const [showGalery, setShowGalery] = useState(false);

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.order.detail);
    const put = useSelector(state => state.transactions.order.put);
    const post = useSelector(state => state.transactions.order.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const client = useSelector(state => state.masters.client);

    const orderOperativeStatus = __(`orderOperativeStatus`, { returnObjects: true });
    const orderStatus = __(`orderStatus`, { returnObjects: true });
    const clients = map(client?.data, ({ idClient, name }) => ({ value: idClient, label: name }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}` }));

    const isActive = "isActive==true";

    const getDataClient = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getClient(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `deliveryName@=${search},${isActive}`
        dispatch(getDeliveryPoint(params))
    }

    useEffect(() => {
        setIsMount(true)
        if (!!id) {
            dispatch(getOrderId({ id }))
        }
        getDataClient()
        getDataDelivery()
    }, [dispatch, id])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); setTimeout(() => { navigate(-1); }, 3000); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); setTimeout(() => { navigate(-1); }, 3000); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    }, [put, post])

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                <Button className='w-[100px] min-w-[100px]' variant='text' color="secondary" onClick={() => setShowGalery(true)} disabled={get(put, "isLoading")} ><div className='whitespace-nowrap'>{__(`action.showGalery`)}</div></Button>
                <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate(-1)} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [])

    const submitPut = (values) => {
        const body = {
            ...values,
        }
        dispatch(putOrder(body))
    }

    const onSubmit = (values) => {
        const idOrder = id;
        if (idOrder) { submitPut(values) }
        else { dispatch(postOrder(values)) }
    }

    const initialValues = {
        isActive: get(current, "data.isActive", ""),
        docNum: get(current, "data.docNum", ""),
        creationDate: moment(get(current, "data.creationDate")).format("L"),
        deliveryDate: moment(get(current, "data.deliveryDate")).format("L"),
        idOrderStatus: get(find(orderStatus, ({ value }) => value === get(current, "data.idOrderStatus", 1)), "label"),
        idOrderOperativeStatus: get(find(orderOperativeStatus, ({ value }) => value === get(current, "data.idOrderOperativeStatus", 1)), "label"),
        idClient: get(find(clients, ({ value }) => value === get(current, "data.idClient", 1)), "label"),
        idDeliveryPoint: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idDeliveryPoint", 1)), "label"),

        // startDate: moment().format("L"),
        // endDate: moment().format("L"),
        deliveryComments: get(current, "data.deliveryComments", ""),

        idUser: get(user, "idUser"),
    }

    return (
        <div>
            {get(current, "isLoading") || get(deliveryPoint, "isLoading") || get(client, "isLoading")
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                {/* <Paper className='p-4'>
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <Button variant='outlined' color="secondary" onClick={() => navigate(-1)} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                            </Stack>
                        </Paper> */}
                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.2`)}</Typography>
                                        <Switch formik={formik} name="isActive" __={__} onChange={() => { }} />
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="docNum"
                                                    label={__(`form.docNum.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="creationDate"
                                                    label={__(`form.creationDate.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="deliveryDate"
                                                    label={__(`form.deliveryDate.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    name={"idOrderStatus"}
                                                    formik={formik}
                                                    label={__(`form.idOrderStatus.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    name={"idOrderOperativeStatus"}
                                                    formik={formik}
                                                    label={__(`form.idOrderOperativeStatus.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    name={"idClient"}
                                                    formik={formik}
                                                    label={__(`form.idClient.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    name={"idDeliveryPoint"}
                                                    formik={formik}
                                                    label={__(`form.idDeliveryPoint.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="startDate"
                                                    label={__(`form.startDate.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid> */}
                                            {/* <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="endDate"
                                                    label={__(`form.endDate.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} sm={6} md={4} >
                                                <InputText
                                                    formik={formik}
                                                    name="deliveryComments"
                                                    label={__(`form.deliveryComments.label`)}
                                                    multiline
                                                    rows={3}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
            <PhotoModal
                __={__}
                open={showGalery}
                close={() => setShowGalery(false)}
            />
        </div>
    )
}

export default NewEdit