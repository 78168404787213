import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_ROUTE_RESET = 'DELETE_ROUTE_RESET';
export const DELETE_ROUTE_LOADING = 'DELETE_ROUTE_LOADING';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const DELETE_ROUTE_REJECT = 'DELETE_ROUTE_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteRouteReset = () => ({ type: DELETE_ROUTE_RESET });
export const deleteRouteLoading = () => ({ type: DELETE_ROUTE_LOADING });
export const deleteRouteSuccess = (payload) => ({ type: DELETE_ROUTE_SUCCESS, payload });
export const deleteRouteReject = (payload) => ({ type: DELETE_ROUTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteRouteRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Route/${data.id}`, options);
};