import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_QUOTE_LOADING = 'GET_QUOTE_LOADING';
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_QUOTE_REJECT = 'GET_QUOTE_REJECT';

//* ACTIONS ------------------------------------------------
export const getQuoteLoading = () => ({ type: GET_QUOTE_LOADING });
export const getQuoteSuccess = (payload) => ({ type: GET_QUOTE_SUCCESS, payload });
export const getQuoteReject = (payload) => ({ type: GET_QUOTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getQuoteRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Quote`, options);
};

