import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_ORDER_ITEM_RESET = 'DELETE_ORDER_ITEM_RESET';
export const DELETE_ORDER_ITEM_LOADING = 'DELETE_ORDER_ITEM_LOADING';
export const DELETE_ORDER_ITEM_SUCCESS = 'DELETE_ORDER_ITEM_SUCCESS';
export const DELETE_ORDER_ITEM_REJECT = 'DELETE_ORDER_ITEM_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteOrderItemReset = () => ({ type: DELETE_ORDER_ITEM_RESET });
export const deleteOrderItemLoading = () => ({ type: DELETE_ORDER_ITEM_LOADING });
export const deleteOrderItemSuccess = (payload) => ({ type: DELETE_ORDER_ITEM_SUCCESS, payload });
export const deleteOrderItemReject = (payload) => ({ type: DELETE_ORDER_ITEM_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteOrderItemRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Items/DeleteOrderItem/${data.id}`, options);
};