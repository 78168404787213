import React from 'react';
import { SvgIcon } from '@mui/material'

const BoxSuccess = (props) => (
    <SvgIcon viewBox="0 0 234 205" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "234px", height: "auto" }}>
        <path d="M14.4526 159.479C14.9878 159.801 14.9878 160.284 14.4526 160.606C13.9174 160.927 13.0611 160.927 12.5259 160.606C11.9907 160.284 11.9907 159.801 12.5259 159.479C13.0611 159.157 13.9174 159.157 14.4526 159.479Z" fill="#00283B" />
        <path d="M19.5369 156.528C20.0721 156.85 20.0721 157.333 19.5369 157.655C19.0017 157.977 18.1453 157.977 17.6101 157.655C17.0749 157.333 17.0749 156.85 17.6101 156.528C18.1453 156.206 19.0017 156.206 19.5369 156.528Z" fill="#00283B" />
        <path d="M24.5947 153.604C25.1299 153.926 25.1299 154.409 24.5947 154.731C24.0595 155.053 23.2032 155.053 22.668 154.731C22.1328 154.409 22.1328 153.926 22.668 153.604C23.2032 153.283 24.0595 153.283 24.5947 153.604Z" fill="#00283B" />
        <path d="M29.679 150.654C30.2142 150.975 30.2142 151.458 29.679 151.78C29.1438 152.102 28.2874 152.102 27.7522 151.78C27.217 151.458 27.217 150.975 27.7522 150.654C28.2874 150.332 29.1438 150.332 29.679 150.654Z" fill="#00283B" />
        <path d="M34.7368 147.73C35.272 148.052 35.272 148.534 34.7368 148.856C34.2016 149.178 33.3453 149.178 32.8101 148.856C32.2749 148.534 32.2749 148.052 32.8101 147.73C33.3453 147.408 34.2016 147.408 34.7368 147.73Z" fill="#00283B" />
        <path d="M39.821 144.833C40.3562 145.155 40.3562 145.637 39.821 145.959C39.2858 146.281 38.4295 146.281 37.8943 145.959C37.3591 145.637 37.3591 145.155 37.8943 144.833C38.4295 144.511 39.2858 144.511 39.821 144.833Z" fill="#00283B" />
        <path d="M44.9055 141.882C45.4407 142.204 45.4407 142.687 44.9055 143.009C44.3703 143.331 43.514 143.331 42.9788 143.009C42.4436 142.687 42.4436 142.204 42.9788 141.882C43.514 141.56 44.3703 141.56 44.9055 141.882Z" fill="#00283B" />
        <path d="M49.9631 138.958C50.4983 139.28 50.4983 139.763 49.9631 140.085C49.4279 140.407 48.5716 140.407 48.0364 140.085C47.5012 139.763 47.5012 139.28 48.0364 138.958C48.5716 138.636 49.4279 138.636 49.9631 138.958Z" fill="#00283B" />
        <path d="M55.0476 136.034C55.5828 136.356 55.5828 136.839 55.0476 137.161C54.5124 137.483 53.6561 137.483 53.1209 137.161C52.5857 136.839 52.5857 136.356 53.1209 136.034C53.6561 135.712 54.5124 135.712 55.0476 136.034Z" fill="#00283B" />
        <path d="M60.1052 133.084C60.6404 133.406 60.6404 133.888 60.1052 134.21C59.57 134.532 58.7137 134.532 58.1785 134.21C57.6433 133.888 57.6433 133.406 58.1785 133.084C58.7137 132.762 59.57 132.762 60.1052 133.084Z" fill="#00283B" />
        <path d="M65.1897 130.16C65.7249 130.482 65.7249 130.965 65.1897 131.287C64.6545 131.608 63.7982 131.608 63.263 131.287C62.7278 130.965 62.7278 130.482 63.263 130.16C63.7982 129.838 64.6545 129.838 65.1897 130.16Z" fill="#00283B" />
        <path d="M70.2742 127.209C70.8094 127.531 70.8094 128.014 70.2742 128.336C69.739 128.658 68.8826 128.658 68.3474 128.336C67.8122 128.014 67.8122 127.531 68.3474 127.209C68.8826 126.887 69.739 126.887 70.2742 127.209Z" fill="#00283B" />
        <path d="M75.3318 124.285C75.867 124.607 75.867 125.09 75.3318 125.412C74.7966 125.734 73.9403 125.734 73.4051 125.412C72.8699 125.09 72.8699 124.607 73.4051 124.285C73.9403 123.963 74.7966 123.963 75.3318 124.285Z" fill="#00283B" />
        <path d="M80.4163 121.334C80.9515 121.656 80.9515 122.139 80.4163 122.461C79.8811 122.783 79.0247 122.783 78.4895 122.461C77.9543 122.139 77.9543 121.656 78.4895 121.334C79.0247 121.013 79.8811 121.013 80.4163 121.334Z" fill="#00283B" />
        <path d="M85.4739 118.411C86.0091 118.733 86.0091 119.215 85.4739 119.537C84.9387 119.859 84.0824 119.859 83.5472 119.537C83.012 119.215 83.012 118.733 83.5472 118.411C84.0824 118.089 84.9387 118.089 85.4739 118.411Z" fill="#00283B" />
        <path d="M90.5581 115.487C91.0933 115.809 91.0933 116.292 90.5581 116.613C90.0229 116.935 89.1666 116.935 88.6314 116.613C88.0962 116.292 88.0962 115.809 88.6314 115.487C89.1666 115.165 90.0229 115.165 90.5581 115.487Z" fill="#00283B" />
        <path d="M95.616 112.536C96.1512 112.858 96.1512 113.341 95.616 113.663C95.0808 113.985 94.2244 113.985 93.6892 113.663C93.154 113.341 93.154 112.858 93.6892 112.536C94.2244 112.214 95.0808 112.214 95.616 112.536Z" fill="#00283B" />
        <path d="M100.7 109.612C101.235 109.934 101.235 110.417 100.7 110.739C100.165 111.061 99.3087 111.061 98.7735 110.739C98.2383 110.417 98.2383 109.934 98.7735 109.612C99.3087 109.29 100.165 109.29 100.7 109.612Z" fill="#00283B" />
        <path d="M70.2742 121.334C70.8094 121.656 70.8094 122.139 70.2742 122.461C69.739 122.783 68.8826 122.783 68.3474 122.461C67.8122 122.139 67.8122 121.656 68.3474 121.334C68.8826 121.013 69.739 121.013 70.2742 121.334Z" fill="#00283B" />
        <path d="M75.3318 118.411C75.867 118.733 75.867 119.215 75.3318 119.537C74.7966 119.859 73.9403 119.859 73.4051 119.537C72.8699 119.215 72.8699 118.733 73.4051 118.411C73.9403 118.089 74.7966 118.089 75.3318 118.411Z" fill="#00283B" />
        <path d="M80.4163 115.487C80.9515 115.809 80.9515 116.292 80.4163 116.613C79.8811 116.935 79.0247 116.935 78.4895 116.613C77.9543 116.292 77.9543 115.809 78.4895 115.487C79.0247 115.165 79.8811 115.165 80.4163 115.487Z" fill="#00283B" />
        <path d="M85.4739 112.536C86.0091 112.858 86.0091 113.341 85.4739 113.663C84.9387 113.985 84.0824 113.985 83.5472 113.663C83.012 113.341 83.012 112.858 83.5472 112.536C84.0824 112.214 84.9387 112.214 85.4739 112.536Z" fill="#00283B" />
        <path d="M90.5581 109.612C91.0933 109.934 91.0933 110.417 90.5581 110.739C90.0229 111.061 89.1666 111.061 88.6314 110.739C88.0962 110.417 88.0962 109.934 88.6314 109.612C89.1666 109.29 90.0229 109.29 90.5581 109.612C91.0933 109.934 90.0229 109.29 90.5581 109.612Z" fill="#00283B" />
        <path d="M65.1897 118.411C65.7249 118.733 65.7249 119.215 65.1897 119.537C64.6545 119.859 63.7982 119.859 63.263 119.537C62.7278 119.215 62.7278 118.733 63.263 118.411C63.7982 118.089 64.6545 118.089 65.1897 118.411Z" fill="#00283B" />
        <path d="M70.2742 115.487C70.8094 115.809 70.8094 116.292 70.2742 116.613C69.739 116.935 68.8826 116.935 68.3474 116.613C67.8122 116.292 67.8122 115.809 68.3474 115.487C68.8826 115.165 69.739 115.165 70.2742 115.487Z" fill="#00283B" />
        <path d="M75.3318 112.536C75.867 112.858 75.867 113.341 75.3318 113.663C74.7966 113.985 73.9403 113.985 73.4051 113.663C72.8699 113.341 72.8699 112.858 73.4051 112.536C73.9403 112.214 74.7966 112.214 75.3318 112.536Z" fill="#00283B" />
        <path d="M80.4163 109.612C80.9515 109.934 80.9515 110.417 80.4163 110.739C79.8811 111.061 79.0247 111.061 78.4895 110.739C77.9543 110.417 77.9543 109.934 78.4895 109.612C79.0247 109.29 79.8811 109.29 80.4163 109.612Z" fill="#00283B" />
        <path d="M60.1052 115.487C60.6404 115.809 60.6404 116.292 60.1052 116.613C59.57 116.935 58.7137 116.935 58.1785 116.613C57.6433 116.292 57.6433 115.809 58.1785 115.487C58.7137 115.165 59.57 115.165 60.1052 115.487Z" fill="#00283B" />
        <path d="M65.1897 112.536C65.7249 112.858 65.7249 113.341 65.1897 113.663C64.6545 113.985 63.7982 113.985 63.263 113.663C62.7278 113.341 62.7278 112.858 63.263 112.536C63.7982 112.214 64.6545 112.214 65.1897 112.536Z" fill="#00283B" />
        <path d="M70.2742 109.612C70.8094 109.934 70.8094 110.417 70.2742 110.739C69.739 111.061 68.8826 111.061 68.3474 110.739C67.8122 110.417 67.8122 109.934 68.3474 109.612C68.8826 109.29 69.739 109.29 70.2742 109.612Z" fill="#00283B" />
        <path d="M75.3318 106.662C75.867 106.983 75.867 107.466 75.3318 107.788C74.7966 108.11 73.9403 108.11 73.4051 107.788C72.8699 107.466 72.8699 106.983 73.4051 106.662C73.9403 106.34 74.7966 106.34 75.3318 106.662Z" fill="#00283B" />
        <path d="M55.0476 112.536C55.5828 112.858 55.5828 113.341 55.0476 113.663C54.5124 113.985 53.6561 113.985 53.1209 113.663C52.5857 113.341 52.5857 112.858 53.1209 112.536C53.6561 112.214 54.5124 112.214 55.0476 112.536Z" fill="#00283B" />
        <path d="M60.1052 109.612C60.6404 109.934 60.6404 110.417 60.1052 110.739C59.57 111.061 58.7137 111.061 58.1785 110.739C57.6433 110.417 57.6433 109.934 58.1785 109.612C58.7137 109.29 59.57 109.29 60.1052 109.612Z" fill="#00283B" />
        <path d="M65.1897 106.662C65.7249 106.983 65.7249 107.466 65.1897 107.788C64.6545 108.11 63.7982 108.11 63.263 107.788C62.7278 107.466 62.7278 106.983 63.263 106.662C63.7982 106.34 64.6545 106.34 65.1897 106.662Z" fill="#00283B" />
        <path d="M70.2742 103.738C70.8094 104.06 70.8094 104.542 70.2742 104.864C69.739 105.186 68.8826 105.186 68.3474 104.864C67.8122 104.542 67.8122 104.06 68.3474 103.738C68.8826 103.416 69.739 103.416 70.2742 103.738C70.8094 104.06 69.739 103.416 70.2742 103.738Z" fill="#00283B" />
        <path d="M49.9365 109.585C50.4717 109.907 50.4717 110.39 49.9365 110.712C49.4013 111.034 48.545 111.034 48.0098 110.712C47.4746 110.39 47.4746 109.907 48.0098 109.585C48.545 109.264 49.4013 109.264 49.9365 109.585Z" fill="#00283B" />
        <path d="M54.9941 106.635C55.5293 106.957 55.5293 107.439 54.9941 107.761C54.4589 108.083 53.6026 108.083 53.0674 107.761C52.5322 107.439 52.5322 106.957 53.0674 106.635C53.6026 106.313 54.4589 106.313 54.9941 106.635Z" fill="#00283B" />
        <path d="M60.0786 103.711C60.6138 104.033 60.6138 104.516 60.0786 104.838C59.5434 105.159 58.6871 105.159 58.1519 104.838C57.6167 104.516 57.6167 104.033 58.1519 103.711C58.6871 103.389 59.5434 103.389 60.0786 103.711Z" fill="#00283B" />
        <path d="M65.1362 100.76C65.6714 101.082 65.6714 101.565 65.1362 101.887C64.601 102.209 63.7447 102.209 63.2095 101.887C62.6743 101.565 62.6743 101.082 63.2095 100.76C63.7447 100.438 64.601 100.438 65.1362 100.76Z" fill="#00283B" />
        <path d="M49.9365 103.711C50.4717 104.033 50.4717 104.516 49.9365 104.838C49.4013 105.159 48.545 105.159 48.0098 104.838C47.4746 104.516 47.4746 104.033 48.0098 103.711C48.545 103.389 49.4013 103.389 49.9365 103.711Z" fill="#00283B" />
        <path d="M54.9941 100.76C55.5293 101.082 55.5293 101.565 54.9941 101.887C54.4589 102.209 53.6026 102.209 53.0674 101.887C52.5322 101.565 52.5322 101.082 53.0674 100.76C53.6026 100.438 54.4589 100.438 54.9941 100.76Z" fill="#00283B" />
        <path d="M60.0786 97.8364C60.6138 98.1583 60.6138 98.6411 60.0786 98.963C59.5434 99.2849 58.6871 99.2849 58.1519 98.963C57.6167 98.6411 57.6167 98.1583 58.1519 97.8364C58.6871 97.5145 59.5434 97.5145 60.0786 97.8364C60.6138 98.1583 59.5434 97.5145 60.0786 97.8364Z" fill="#00283B" />
        <path d="M49.9365 97.8364C50.4717 98.1583 50.4717 98.6411 49.9365 98.963C49.4013 99.2849 48.545 99.2849 48.0098 98.963C47.4746 98.6411 47.4746 98.1583 48.0098 97.8364C48.545 97.5145 49.4013 97.5145 49.9365 97.8364Z" fill="#00283B" />
        <path d="M54.9941 94.9123C55.5293 95.2342 55.5293 95.717 54.9941 96.0389C54.4589 96.3608 53.6026 96.3608 53.0674 96.0389C52.5322 95.717 52.5322 95.2342 53.0674 94.9123C53.6026 94.5904 54.4589 94.5904 54.9941 94.9123Z" fill="#00283B" />
        <path d="M116.034 200.467C116.569 200.789 116.569 201.271 116.034 201.593C115.499 201.915 114.642 201.915 114.107 201.593C113.572 201.271 113.572 200.789 114.107 200.467C114.642 200.145 115.499 200.145 116.034 200.467Z" fill="#00283B" />
        <path d="M121.092 197.543C121.627 197.865 121.627 198.348 121.092 198.67C120.556 198.991 119.7 198.991 119.165 198.67C118.63 198.348 118.63 197.865 119.165 197.543C119.7 197.221 120.556 197.221 121.092 197.543Z" fill="#00283B" />
        <path d="M105.892 200.467C106.427 200.789 106.427 201.271 105.892 201.593C105.357 201.915 104.5 201.915 103.965 201.593C103.43 201.271 103.43 200.789 103.965 200.467C104.5 200.145 105.357 200.145 105.892 200.467Z" fill="#00283B" />
        <path d="M110.949 197.543C111.485 197.865 111.485 198.348 110.949 198.67C110.414 198.991 109.558 198.991 109.023 198.67C108.488 198.348 108.488 197.865 109.023 197.543C109.558 197.221 110.414 197.221 110.949 197.543Z" fill="#00283B" />
        <path d="M116.034 194.619C116.569 194.941 116.569 195.424 116.034 195.745C115.499 196.067 114.642 196.067 114.107 195.745C113.572 195.424 113.572 194.941 114.107 194.619C114.642 194.297 115.499 194.297 116.034 194.619Z" fill="#00283B" />
        <path d="M100.807 197.543C101.343 197.865 101.343 198.348 100.807 198.67C100.272 198.991 99.4159 198.991 98.8806 198.67C98.3454 198.348 98.3454 197.865 98.8806 197.543C99.4159 197.221 100.272 197.221 100.807 197.543Z" fill="#00283B" />
        <path d="M105.892 194.619C106.427 194.941 106.427 195.424 105.892 195.745C105.357 196.067 104.5 196.067 103.965 195.745C103.43 195.424 103.43 194.941 103.965 194.619C104.5 194.297 105.357 194.297 105.892 194.619Z" fill="#00283B" />
        <path d="M110.949 191.668C111.485 191.99 111.485 192.473 110.949 192.795C110.414 193.117 109.558 193.117 109.023 192.795C108.488 192.473 108.488 191.99 109.023 191.668C109.558 191.346 110.414 191.346 110.949 191.668Z" fill="#00283B" />
        <path d="M95.7498 194.619C96.285 194.941 96.285 195.424 95.7498 195.745C95.2146 196.067 94.3582 196.067 93.823 195.745C93.2878 195.424 93.2878 194.941 93.823 194.619C94.3582 194.297 95.2146 194.297 95.7498 194.619Z" fill="#00283B" />
        <path d="M100.807 191.668C101.343 191.99 101.343 192.473 100.807 192.795C100.272 193.117 99.4159 193.117 98.8806 192.795C98.3454 192.473 98.3454 191.99 98.8806 191.668C99.4159 191.346 100.272 191.346 100.807 191.668Z" fill="#00283B" />
        <path d="M105.892 188.744C106.427 189.066 106.427 189.549 105.892 189.871C105.357 190.193 104.5 190.193 103.965 189.871C103.43 189.549 103.43 189.066 103.965 188.744C104.5 188.422 105.357 188.422 105.892 188.744Z" fill="#00283B" />
        <path d="M95.7498 188.744C96.285 189.066 96.285 189.549 95.7498 189.871C95.2146 190.193 94.3582 190.193 93.823 189.871C93.2878 189.549 93.2878 189.066 93.823 188.744C94.3582 188.422 95.2146 188.422 95.7498 188.744Z" fill="#00283B" />
        <path d="M100.807 185.794C101.343 186.116 101.343 186.598 100.807 186.92C100.272 187.242 99.4159 187.242 98.8806 186.92C98.3454 186.598 98.3454 186.116 98.8806 185.794C99.4159 185.472 100.272 185.472 100.807 185.794Z" fill="#00283B" />
        <path d="M95.6962 182.843C96.2314 183.165 96.2314 183.648 95.6962 183.97C95.161 184.291 94.3047 184.291 93.7695 183.97C93.2343 183.648 95.161 182.548 95.6962 182.843Z" fill="#00283B" />
        <path d="M44.9858 188.717C45.521 189.039 45.521 189.522 44.9858 189.844C44.4506 190.166 43.5943 190.166 43.0591 189.844C42.5239 189.522 42.5239 189.039 43.0591 188.717C43.5943 188.396 44.4506 188.396 44.9858 188.717Z" fill="#00283B" />
        <path d="M50.0703 185.767C50.6055 186.089 50.6055 186.572 50.0703 186.893C49.5351 187.215 48.6788 187.215 48.1436 186.893C47.6084 186.572 47.6084 186.089 48.1436 185.767C48.6788 185.445 49.5351 185.445 50.0703 185.767Z" fill="#00283B" />
        <path d="M55.1279 182.843C55.6631 183.165 55.6631 183.648 55.1279 183.97C54.5927 184.291 53.7364 184.291 53.2012 183.97C52.666 183.648 52.666 183.165 53.2012 182.843C53.7364 182.521 54.5927 182.521 55.1279 182.843Z" fill="#00283B" />
        <path d="M60.2124 179.892C60.7476 180.214 60.7476 180.697 60.2124 181.019C59.6772 181.341 58.8209 181.341 58.2857 181.019C57.7505 180.697 57.7505 180.214 58.2857 179.892C58.8209 179.57 59.6772 179.57 60.2124 179.892Z" fill="#00283B" />
        <path d="M65.27 176.968C65.8052 177.29 65.8052 177.773 65.27 178.095C64.7348 178.417 63.8785 178.417 63.3433 178.095C62.8081 177.773 62.8081 177.29 63.3433 176.968C63.8785 176.647 64.7348 176.647 65.27 176.968Z" fill="#00283B" />
        <path d="M70.3545 174.045C70.8897 174.367 70.8897 174.849 70.3545 175.171C69.8193 175.493 68.963 175.493 68.4278 175.171C67.8926 174.849 67.8926 174.367 68.4278 174.045C68.963 173.723 69.8193 173.723 70.3545 174.045Z" fill="#00283B" />
        <path d="M75.4121 171.094C75.9473 171.416 75.9473 171.899 75.4121 172.221C74.8769 172.542 74.0206 172.542 73.4854 172.221C72.9502 171.899 72.9502 171.416 73.4854 171.094C74.0206 170.772 74.8769 170.772 75.4121 171.094Z" fill="#00283B" />
        <path d="M34.8437 188.717C35.379 189.039 35.379 189.522 34.8437 189.844C34.3085 190.166 33.4522 190.166 32.917 189.844C32.3818 189.522 32.3818 189.039 32.917 188.717C33.4522 188.396 34.3085 188.396 34.8437 188.717Z" fill="#00283B" />
        <path d="M39.9282 185.767C40.4634 186.089 40.4634 186.572 39.9282 186.893C39.393 187.215 38.5367 187.215 38.0015 186.893C37.4663 186.572 37.4663 186.089 38.0015 185.767C38.5367 185.445 39.393 185.445 39.9282 185.767Z" fill="#00283B" />
        <path d="M44.9858 182.843C45.521 183.165 45.521 183.648 44.9858 183.97C44.4506 184.291 43.5943 184.291 43.0591 183.97C42.5239 183.648 42.5239 183.165 43.0591 182.843C43.5943 182.521 44.4506 182.521 44.9858 182.843Z" fill="#00283B" />
        <path d="M50.0703 179.892C50.6055 180.214 50.6055 180.697 50.0703 181.019C49.5351 181.341 48.6788 181.341 48.1436 181.019C47.6084 180.697 47.6084 180.214 48.1436 179.892C48.6788 179.57 49.5351 179.57 50.0703 179.892Z" fill="#00283B" />
        <path d="M55.1279 176.968C55.6631 177.29 55.6631 177.773 55.1279 178.095C54.5927 178.417 53.7364 178.417 53.2012 178.095C52.666 177.773 52.666 177.29 53.2012 176.968C53.7364 176.647 54.5927 176.647 55.1279 176.968Z" fill="#00283B" />
        <path d="M60.2124 174.045C60.7476 174.367 60.7476 174.849 60.2124 175.171C59.6772 175.493 58.8209 175.493 58.2857 175.171C57.7505 174.849 57.7505 174.367 58.2857 174.045C58.8209 173.723 59.6772 173.723 60.2124 174.045Z" fill="#00283B" />
        <path d="M65.27 171.094C65.8052 171.416 65.8052 171.899 65.27 172.221C64.7348 172.542 63.8785 172.542 63.3433 172.221C62.8081 171.899 62.8081 171.416 63.3433 171.094C63.8785 170.772 64.7348 170.772 65.27 171.094Z" fill="#00283B" />
        <path d="M70.3545 168.17C70.8897 168.492 70.8897 168.975 70.3545 169.297C69.8193 169.619 68.963 169.619 68.4278 169.297C67.8926 168.975 67.8926 168.492 68.4278 168.17C68.963 167.848 69.8193 167.848 70.3545 168.17Z" fill="#00283B" />
        <path d="M24.7017 188.717C25.2369 189.039 25.2369 189.522 24.7017 189.844C24.1665 190.166 23.3101 190.166 22.7749 189.844C22.2397 189.522 22.2397 189.039 22.7749 188.717C23.3101 188.396 24.1665 188.396 24.7017 188.717Z" fill="#00283B" />
        <path d="M29.7593 185.767C30.2945 186.089 30.2945 186.572 29.7593 186.893C29.2241 187.215 28.3678 187.215 27.8326 186.893C27.2974 186.572 27.2974 186.089 27.8326 185.767C28.3678 185.445 29.2241 185.445 29.7593 185.767Z" fill="#00283B" />
        <path d="M34.8437 182.843C35.379 183.165 35.379 183.648 34.8437 183.97C34.3085 184.291 33.4522 184.291 32.917 183.97C32.3818 183.648 32.3818 183.165 32.917 182.843C33.4522 182.521 34.3085 182.521 34.8437 182.843Z" fill="#00283B" />
        <path d="M19.6172 185.767C20.1524 186.089 20.1524 186.572 19.6172 186.893C19.082 187.215 18.2257 187.215 17.6905 186.893C17.1553 186.572 17.1553 186.089 17.6905 185.767C18.2257 185.445 19.082 185.445 19.6172 185.767Z" fill="#00283B" />
        <path d="M24.7017 182.843C25.2369 183.165 25.2369 183.648 24.7017 183.97C24.1665 184.291 23.3101 184.291 22.7749 183.97C22.2397 183.648 22.2397 183.165 22.7749 182.843C23.3101 182.521 24.1665 182.521 24.7017 182.843Z" fill="#00283B" />
        <path d="M14.5061 182.816C15.0413 183.138 15.0413 183.621 14.5061 183.943C13.9709 184.265 13.1146 184.265 12.5794 183.943C12.0442 183.621 12.0442 183.138 12.5794 182.816C13.1146 182.494 13.9709 182.494 14.5061 182.816Z" fill="#00283B" />
        <path d="M19.5906 179.865C20.1258 180.187 20.1258 180.67 19.5906 180.992C19.0554 181.314 18.1991 181.314 17.6639 180.992C17.1287 180.67 17.1287 180.187 17.6639 179.865C18.1991 179.544 19.0554 179.544 19.5906 179.865Z" fill="#00283B" />
        <path d="M9.44849 179.865C9.98369 180.187 9.98369 180.67 9.44849 180.992C8.91329 181.314 8.05696 181.314 7.52176 180.992C6.98656 180.67 6.98656 180.187 7.52176 179.865C8.05696 179.544 8.91329 179.544 9.44849 179.865Z" fill="#00283B" />
        <path d="M14.5061 176.942C15.0413 177.264 15.0413 177.746 14.5061 178.068C13.9709 178.39 13.1146 178.39 12.5794 178.068C12.0442 177.746 12.0442 177.264 12.5794 176.942C13.1146 176.62 13.9709 176.62 14.5061 176.942Z" fill="#00283B" />
        <path d="M4.36402 176.942C4.89922 177.264 4.89922 177.746 4.36402 178.068C3.82881 178.39 2.97249 178.39 2.43729 178.068C1.90209 177.746 1.90209 177.264 2.43729 176.942C2.97249 176.62 3.82881 176.62 4.36402 176.942Z" fill="#00283B" />
        <path d="M9.44849 173.991C9.98369 174.313 9.98369 174.796 9.44849 175.118C8.91329 175.439 8.05696 175.439 7.52176 175.118C6.98656 174.796 6.98656 174.313 7.52176 173.991C8.05696 173.669 8.91329 173.669 9.44849 173.991Z" fill="#00283B" />
        <path d="M4.36402 171.067C4.89922 171.389 4.89922 171.872 4.36402 172.194C3.82881 172.516 2.97249 172.516 2.43729 172.194C1.90209 171.872 1.90209 171.389 2.43729 171.067C2.97249 170.745 3.82881 170.745 4.36402 171.067Z" fill="#00283B" />
        <path d="M12.5793 165.193C13.1145 164.871 13.9708 164.871 14.506 165.193C15.0412 165.514 15.0412 165.997 14.506 166.319C13.9708 166.641 12.0441 165.514 12.5793 165.193Z" fill="#00283B" />
        <path d="M19.5906 162.269C20.1258 162.591 20.1258 163.073 19.5906 163.395C19.0554 163.717 18.1991 163.717 17.6639 163.395C17.1287 163.073 17.1287 162.591 17.6639 162.269C18.1991 161.947 19.0554 161.947 19.5906 162.269Z" fill="#00283B" />
        <path d="M24.6482 159.318C25.1834 159.64 25.1834 160.123 24.6482 160.445C24.113 160.767 23.2567 160.767 22.7215 160.445C22.1863 160.123 22.1863 159.64 22.7215 159.318C23.2567 158.996 24.113 158.996 24.6482 159.318Z" fill="#00283B" />
        <path d="M29.7327 156.394C30.2679 156.716 30.2679 157.199 29.7327 157.521C29.1975 157.843 28.3411 157.843 27.8059 157.521C27.2707 157.199 27.2707 156.716 27.8059 156.394C28.3411 156.072 29.1975 156.072 29.7327 156.394Z" fill="#00283B" />
        <path d="M34.7903 153.443C35.3255 153.765 35.3255 154.248 34.7903 154.57C34.2551 154.892 33.3988 154.892 32.8636 154.57C32.3284 154.248 32.3284 153.765 32.8636 153.443C33.3988 153.121 34.2551 153.121 34.7903 153.443Z" fill="#00283B" />
        <path d="M39.8748 150.519C40.41 150.841 40.41 151.324 39.8748 151.646C39.3396 151.968 38.4832 151.968 37.948 151.646C37.4128 151.324 37.4128 150.841 37.948 150.519C38.4832 150.198 39.3396 150.198 39.8748 150.519Z" fill="#00283B" />
        <path d="M126.203 195.772C125.667 196.094 124.811 196.094 124.276 195.772C123.741 195.45 123.741 194.968 124.276 194.646C124.811 194.324 125.667 194.324 126.203 194.646C126.738 194.968 126.738 195.45 126.203 195.772C126.738 195.45 125.667 196.094 126.203 195.772Z" fill="#00283B" />
        <path d="M131.26 191.722C131.795 192.044 131.795 192.527 131.26 192.849C130.725 193.17 129.869 193.17 129.334 192.849C128.798 192.527 128.798 192.044 129.334 191.722C129.869 191.4 130.725 191.4 131.26 191.722Z" fill="#00283B" />
        <path d="M136.345 188.771C136.88 189.093 136.88 189.576 136.345 189.898C135.81 190.22 134.953 190.22 134.418 189.898C133.883 189.576 133.883 189.093 134.418 188.771C134.953 188.449 135.81 188.449 136.345 188.771Z" fill="#00283B" />
        <path d="M141.402 185.847C141.938 186.169 141.938 186.652 141.402 186.974C140.867 187.296 140.011 187.296 139.476 186.974C138.94 186.652 138.94 186.169 139.476 185.847C140.011 185.525 140.867 185.525 141.402 185.847Z" fill="#00283B" />
        <path d="M146.487 182.897C147.022 183.219 147.022 183.701 146.487 184.023C145.952 184.345 145.095 184.345 144.56 184.023C144.025 183.701 144.025 183.219 144.56 182.897C145.095 182.575 145.952 182.575 146.487 182.897Z" fill="#00283B" />
        <path d="M151.544 179.973C152.08 180.295 152.08 180.778 151.544 181.099C151.009 181.421 150.153 181.421 149.618 181.099C149.083 180.778 149.083 180.295 149.618 179.973C150.153 179.651 151.009 179.651 151.544 179.973Z" fill="#00283B" />
        <path d="M156.629 177.049C157.164 177.371 157.164 177.854 156.629 178.176C156.094 178.498 155.237 178.498 154.702 178.176C154.167 177.854 154.167 177.371 154.702 177.049C155.237 176.727 156.094 176.727 156.629 177.049Z" fill="#00283B" />
        <path d="M161.687 174.098C162.222 174.42 162.222 174.903 161.687 175.225C161.151 175.547 160.295 175.547 159.76 175.225C159.225 174.903 159.225 174.42 159.76 174.098C160.295 173.776 161.151 173.776 161.687 174.098Z" fill="#00283B" />
        <path d="M166.771 171.174C167.306 171.496 167.306 171.979 166.771 172.301C166.236 172.623 165.379 172.623 164.844 172.301C164.309 171.979 164.309 171.496 164.844 171.174C165.379 170.852 166.236 170.852 166.771 171.174Z" fill="#00283B" />
        <path d="M171.829 168.224C172.364 168.545 172.364 169.028 171.829 169.35C171.293 169.672 170.437 169.672 169.902 169.35C169.367 169.028 169.367 168.545 169.902 168.224C170.437 167.902 171.293 167.902 171.829 168.224Z" fill="#00283B" />
        <path d="M119.165 191.722C119.7 191.4 120.556 191.4 121.092 191.722C121.627 192.044 121.627 192.527 121.092 192.849C120.556 193.17 119.7 193.17 119.165 192.849C118.63 192.527 118.63 192.044 119.165 191.722Z" fill="#00283B" />
        <path d="M126.203 188.771C126.738 189.093 126.738 189.576 126.203 189.898C125.667 190.22 124.811 190.22 124.276 189.898C123.741 189.576 123.741 189.093 124.276 188.771C124.811 188.449 125.667 188.449 126.203 188.771Z" fill="#00283B" />
        <path d="M131.26 185.847C131.795 186.169 131.795 186.652 131.26 186.974C130.725 187.296 129.869 187.296 129.334 186.974C128.798 186.652 128.798 186.169 129.334 185.847C129.869 185.525 130.725 185.525 131.26 185.847Z" fill="#00283B" />
        <path d="M136.345 182.897C136.88 183.219 136.88 183.701 136.345 184.023C135.81 184.345 134.953 184.345 134.418 184.023C133.883 183.701 133.883 183.219 134.418 182.897C134.953 182.575 135.81 182.575 136.345 182.897Z" fill="#00283B" />
        <path d="M141.402 179.973C141.938 180.295 141.938 180.778 141.402 181.099C140.867 181.421 140.011 181.421 139.476 181.099C138.94 180.778 138.94 180.295 139.476 179.973C140.011 179.651 140.867 179.651 141.402 179.973Z" fill="#00283B" />
        <path d="M146.487 177.049C147.022 177.371 147.022 177.854 146.487 178.176C145.952 178.498 145.095 178.498 144.56 178.176C144.025 177.854 144.025 177.371 144.56 177.049C145.095 176.727 145.952 176.727 146.487 177.049Z" fill="#00283B" />
        <path d="M151.544 174.098C152.08 174.42 152.08 174.903 151.544 175.225C151.009 175.547 150.153 175.547 149.618 175.225C149.083 174.903 149.083 174.42 149.618 174.098C150.153 173.776 151.009 173.776 151.544 174.098Z" fill="#00283B" />
        <path d="M156.629 171.174C157.164 171.496 157.164 171.979 156.629 172.301C156.094 172.623 155.237 172.623 154.702 172.301C154.167 171.979 154.167 171.496 154.702 171.174C155.237 170.852 156.094 170.852 156.629 171.174Z" fill="#00283B" />
        <path d="M161.687 168.224C162.222 168.545 162.222 169.028 161.687 169.35C161.151 169.672 160.295 169.672 159.76 169.35C159.225 169.028 159.225 168.545 159.76 168.224C160.295 167.902 161.151 167.902 161.687 168.224Z" fill="#00283B" />
        <path d="M166.771 165.3C167.306 165.622 167.306 166.104 166.771 166.426C166.236 166.748 165.379 166.748 164.844 166.426C164.309 166.104 164.309 165.622 164.844 165.3C165.379 164.978 166.236 164.978 166.771 165.3Z" fill="#00283B" />
        <path d="M114.107 188.771C114.642 188.449 115.499 188.449 116.034 188.771C116.569 189.093 116.569 189.576 116.034 189.898C115.499 190.22 114.642 190.22 114.107 189.898C113.572 189.576 113.572 189.093 114.107 188.771Z" fill="#00283B" />
        <path d="M121.118 185.847C121.653 186.169 121.653 186.652 121.118 186.974C120.583 187.296 119.727 187.296 119.191 186.974C118.656 186.652 118.656 186.169 119.191 185.847C119.727 185.525 120.583 185.525 121.118 185.847Z" fill="#00283B" />
        <path d="M126.203 182.897C126.738 183.219 126.738 183.701 126.203 184.023C125.667 184.345 124.811 184.345 124.276 184.023C123.741 183.701 123.741 183.219 124.276 182.897C124.811 182.575 125.667 182.575 126.203 182.897Z" fill="#00283B" />
        <path d="M131.26 179.973C131.795 180.295 131.795 180.778 131.26 181.099C130.725 181.421 129.869 181.421 129.334 181.099C128.798 180.778 128.798 180.295 129.334 179.973C129.869 179.651 130.725 179.651 131.26 179.973Z" fill="#00283B" />
        <path d="M136.345 177.049C136.88 177.371 136.88 177.854 136.345 178.176C135.81 178.498 134.953 178.498 134.418 178.176C133.883 177.854 133.883 177.371 134.418 177.049C134.953 176.727 135.81 176.727 136.345 177.049Z" fill="#00283B" />
        <path d="M139.476 174.098C140.011 173.776 140.867 173.776 141.402 174.098C141.938 174.42 141.938 174.903 141.402 175.225C140.867 175.547 140.011 175.547 139.476 175.225C138.94 174.903 138.94 174.42 139.476 174.098C140.011 173.776 138.94 174.42 139.476 174.098Z" fill="#00283B" />
        <path d="M146.487 171.174C147.022 171.496 147.022 171.979 146.487 172.301C145.952 172.623 145.095 172.623 144.56 172.301C144.025 171.979 144.025 171.496 144.56 171.174C145.095 170.852 145.952 170.852 146.487 171.174Z" fill="#00283B" />
        <path d="M151.544 168.224C152.08 168.545 152.08 169.028 151.544 169.35C151.009 169.672 150.153 169.672 149.618 169.35C149.083 169.028 149.083 168.545 149.618 168.224C150.153 167.902 151.009 167.902 151.544 168.224Z" fill="#00283B" />
        <path d="M156.629 165.3C157.164 165.622 157.164 166.104 156.629 166.426C156.094 166.748 155.237 166.748 154.702 166.426C154.167 166.104 154.167 165.622 154.702 165.3C155.237 164.978 156.094 164.978 156.629 165.3Z" fill="#00283B" />
        <path d="M109.023 185.847C109.558 185.525 110.414 185.525 110.949 185.847C111.485 186.169 111.485 186.652 110.949 186.974C110.414 187.296 109.558 187.296 109.023 186.974C108.488 186.652 108.488 186.169 109.023 185.847Z" fill="#00283B" />
        <path d="M105.892 184.023C105.357 184.345 104.5 184.345 103.965 184.023C103.43 183.701 103.43 183.218 103.965 182.897C104.5 182.575 106.454 183.701 105.919 184.023H105.892Z" fill="#00283B" />
        <path d="M105.865 176.995C106.4 177.317 106.4 177.8 105.865 178.122C105.33 178.444 104.473 178.444 103.938 178.122C103.403 177.8 103.403 177.317 103.938 176.995C104.473 176.673 105.33 176.673 105.865 176.995Z" fill="#00283B" />
        <path d="M110.923 174.071C111.458 174.393 111.458 174.876 110.923 175.198C110.387 175.52 109.531 175.52 108.996 175.198C108.461 174.876 108.461 174.393 108.996 174.071C109.531 173.75 110.387 173.75 110.923 174.071Z" fill="#00283B" />
        <path d="M116.007 171.148C116.542 171.47 116.542 171.952 116.007 172.274C115.472 172.596 114.616 172.596 114.08 172.274C113.545 171.952 113.545 171.47 114.08 171.148C114.616 170.826 115.472 170.826 116.007 171.148Z" fill="#00283B" />
        <path d="M121.065 168.197C121.6 168.519 121.6 169.002 121.065 169.324C120.529 169.646 119.673 169.646 119.138 169.324C118.603 169.002 118.603 168.519 119.138 168.197C119.673 167.875 120.529 167.875 121.065 168.197Z" fill="#00283B" />
        <path d="M126.149 165.273C126.684 165.595 126.684 166.078 126.149 166.4C125.614 166.721 124.758 166.721 124.222 166.4C123.687 166.078 123.687 165.595 124.222 165.273C124.758 164.951 125.614 164.951 126.149 165.273Z" fill="#00283B" />
        <path d="M131.207 162.322C131.742 162.644 131.742 163.127 131.207 163.449C130.672 163.771 129.815 163.771 129.28 163.449C128.745 163.127 128.745 162.644 129.28 162.322C129.815 162 130.672 162 131.207 162.322Z" fill="#00283B" />
        <path d="M136.291 159.398C136.826 159.72 136.826 160.203 136.291 160.525C135.756 160.847 134.9 160.847 134.365 160.525C133.829 160.203 133.829 159.72 134.365 159.398C134.9 159.077 135.756 159.077 136.291 159.398Z" fill="#00283B" />
        <path d="M141.349 156.448C141.884 156.77 141.884 157.252 141.349 157.574C140.814 157.896 139.957 157.896 139.422 157.574C138.887 157.252 138.887 156.77 139.422 156.448C139.957 156.126 140.814 156.126 141.349 156.448Z" fill="#00283B" />
        <path d="M100.781 174.071C101.316 174.393 101.316 174.876 100.781 175.198C100.245 175.52 99.389 175.52 98.8538 175.198C98.3186 174.876 98.3186 174.393 98.8538 174.071C99.389 173.75 100.245 173.75 100.781 174.071Z" fill="#00283B" />
        <path d="M105.865 171.148C106.4 171.47 106.4 171.952 105.865 172.274C105.33 172.596 104.473 172.596 103.938 172.274C103.403 171.952 103.403 171.47 103.938 171.148C104.473 170.826 105.33 170.826 105.865 171.148Z" fill="#00283B" />
        <path d="M110.923 168.197C111.458 168.519 111.458 169.002 110.923 169.324C110.387 169.646 109.531 169.646 108.996 169.324C108.461 169.002 108.461 168.519 108.996 168.197C109.531 167.875 110.387 167.875 110.923 168.197Z" fill="#00283B" />
        <path d="M116.007 165.273C116.542 165.595 116.542 166.078 116.007 166.4C115.472 166.721 114.616 166.721 114.08 166.4C113.545 166.078 113.545 165.595 114.08 165.273C114.616 164.951 115.472 164.951 116.007 165.273Z" fill="#00283B" />
        <path d="M121.065 162.322C121.6 162.644 121.6 163.127 121.065 163.449C120.529 163.771 119.673 163.771 119.138 163.449C118.603 163.127 118.603 162.644 119.138 162.322C119.673 162 120.529 162 121.065 162.322Z" fill="#00283B" />
        <path d="M126.149 159.398C126.684 159.72 126.684 160.203 126.149 160.525C125.614 160.847 124.758 160.847 124.222 160.525C123.687 160.203 123.687 159.72 124.222 159.398C124.758 159.077 125.614 159.077 126.149 159.398Z" fill="#00283B" />
        <path d="M131.207 156.448C131.742 156.77 131.742 157.252 131.207 157.574C130.672 157.896 129.815 157.896 129.28 157.574C128.745 157.252 128.745 156.77 129.28 156.448C129.815 156.126 130.672 156.126 131.207 156.448Z" fill="#00283B" />
        <path d="M136.291 153.524C136.826 153.846 136.826 154.329 136.291 154.651C135.756 154.972 134.9 154.972 134.365 154.651C133.829 154.329 133.829 153.846 134.365 153.524C134.9 153.202 135.756 153.202 136.291 153.524Z" fill="#00283B" />
        <path d="M141.349 150.6C141.884 150.922 141.884 151.405 141.349 151.727C140.814 152.049 139.957 152.049 139.422 151.727C138.887 151.405 138.887 150.922 139.422 150.6C139.957 150.278 140.814 150.278 141.349 150.6Z" fill="#00283B" />
        <path d="M93.7693 171.148C94.3045 170.826 95.1608 170.826 95.696 171.148C96.2313 171.47 96.2313 171.952 95.696 172.274C95.1608 172.596 94.3045 172.596 93.7693 172.274C93.2341 171.952 93.2341 171.47 93.7693 171.148Z" fill="#00283B" />
        <path d="M100.781 168.197C101.316 168.519 101.316 169.002 100.781 169.324C100.245 169.646 99.389 169.646 98.8538 169.324C98.3186 169.002 98.3186 168.519 98.8538 168.197C99.389 167.875 100.245 167.875 100.781 168.197Z" fill="#00283B" />
        <path d="M103.911 165.273C104.447 164.951 105.303 164.951 105.838 165.273C106.373 165.595 106.373 166.078 105.838 166.4C105.303 166.721 104.447 166.721 103.911 166.4C103.376 166.078 103.376 165.595 103.911 165.273C104.447 164.951 103.376 165.595 103.911 165.273Z" fill="#00283B" />
        <path d="M110.923 162.322C111.458 162.644 111.458 163.127 110.923 163.449C110.387 163.771 109.531 163.771 108.996 163.449C108.461 163.127 110.387 162.027 110.923 162.322Z" fill="#00283B" />
        <path d="M116.007 159.398C116.542 159.72 116.542 160.203 116.007 160.525C115.472 160.847 114.615 160.847 114.08 160.525C113.545 160.203 115.472 159.103 116.007 159.398Z" fill="#00283B" />
        <path d="M129.28 151.7C128.745 151.378 128.745 150.895 129.28 150.573C129.815 150.251 130.672 150.251 131.207 150.573C131.742 150.895 129.815 151.995 129.28 151.7Z" fill="#00283B" />
        <path d="M126.149 147.649C126.684 147.971 126.684 148.454 126.149 148.776C125.614 149.098 124.758 149.098 124.222 148.776C123.687 148.454 123.687 147.971 124.222 147.649C124.758 147.327 125.614 147.327 126.149 147.649Z" fill="#00283B" />
        <path d="M131.207 144.726C131.742 145.047 131.742 145.53 131.207 145.852C130.672 146.174 129.815 146.174 129.28 145.852C128.745 145.53 128.745 145.047 129.28 144.726C129.815 144.404 130.672 144.404 131.207 144.726Z" fill="#00283B" />
        <path d="M78.5696 168.197C79.1048 167.875 79.9611 167.875 80.4963 168.197C81.0315 168.519 81.0315 169.002 80.4963 169.324C79.9611 169.646 79.1048 169.646 78.5696 169.324C78.0344 169.002 78.0344 168.519 78.5696 168.197Z" fill="#00283B" />
        <path d="M85.5808 165.273C86.116 165.595 86.116 166.078 85.5808 166.4C85.0456 166.721 84.1893 166.721 83.6541 166.4C83.1189 166.078 83.1189 165.595 83.6541 165.273C84.1893 164.951 85.0456 164.951 85.5808 165.273Z" fill="#00283B" />
        <path d="M90.6384 162.322C91.1736 162.644 91.1736 163.127 90.6384 163.449C90.1032 163.771 89.2469 163.771 88.7117 163.449C88.1765 163.127 88.1765 162.644 88.7117 162.322C89.2469 162 90.1032 162 90.6384 162.322Z" fill="#00283B" />
        <path d="M95.7229 159.398C96.2581 159.72 96.2581 160.203 95.7229 160.525C95.1877 160.847 94.3314 160.847 93.7962 160.525C93.261 160.203 93.261 159.72 93.7962 159.398C94.3314 159.077 95.1877 159.077 95.7229 159.398Z" fill="#00283B" />
        <path d="M100.781 156.448C101.316 156.77 101.316 157.252 100.781 157.574C100.245 157.896 99.389 157.896 98.8538 157.574C98.3186 157.252 98.3186 156.77 98.8538 156.448C99.389 156.126 100.245 156.126 100.781 156.448Z" fill="#00283B" />
        <path d="M105.865 153.524C106.4 153.846 106.4 154.329 105.865 154.651C105.33 154.972 104.473 154.972 103.938 154.651C103.403 154.329 103.403 153.846 103.938 153.524C104.473 153.202 105.33 153.202 105.865 153.524Z" fill="#00283B" />
        <path d="M110.923 150.6C111.458 150.922 111.458 151.405 110.923 151.727C110.387 152.049 109.531 152.049 108.996 151.727C108.461 151.405 108.461 150.922 108.996 150.6C109.531 150.278 110.387 150.278 110.923 150.6Z" fill="#00283B" />
        <path d="M116.007 147.649C116.542 147.971 116.542 148.454 116.007 148.776C115.472 149.098 114.616 149.098 114.08 148.776C113.545 148.454 113.545 147.971 114.08 147.649C114.616 147.327 115.472 147.327 116.007 147.649Z" fill="#00283B" />
        <path d="M121.065 144.726C121.6 145.047 121.6 145.53 121.065 145.852C120.529 146.174 119.673 146.174 119.138 145.852C118.603 145.53 118.603 145.047 119.138 144.726C119.673 144.404 120.529 144.404 121.065 144.726Z" fill="#00283B" />
        <path d="M126.149 141.775C126.684 142.097 126.684 142.58 126.149 142.901C125.614 143.223 124.758 143.223 124.222 142.901C123.687 142.58 123.687 142.097 124.222 141.775C124.758 141.453 125.614 141.453 126.149 141.775Z" fill="#00283B" />
        <path d="M73.4854 165.273C74.0206 164.951 74.8769 164.951 75.4121 165.273C75.9473 165.595 75.9473 166.078 75.4121 166.4C74.8769 166.721 74.0206 166.721 73.4854 166.4C72.9502 166.078 72.9502 165.595 73.4854 165.273Z" fill="#00283B" />
        <path d="M80.4963 162.322C81.0315 162.644 81.0315 163.127 80.4963 163.449C79.9611 163.771 79.1048 163.771 78.5696 163.449C78.0344 163.127 78.0344 162.644 78.5696 162.322C79.1048 162 79.9611 162 80.4963 162.322Z" fill="#00283B" />
        <path d="M85.5808 159.398C86.116 159.72 86.116 160.203 85.5808 160.525C85.0456 160.847 84.1893 160.847 83.6541 160.525C83.1189 160.203 83.1189 159.72 83.6541 159.398C84.1893 159.077 85.0456 159.077 85.5808 159.398Z" fill="#00283B" />
        <path d="M90.6384 156.448C91.1736 156.77 91.1736 157.252 90.6384 157.574C90.1032 157.896 89.2469 157.896 88.7117 157.574C88.1765 157.252 88.1765 156.77 88.7117 156.448C89.2469 156.126 90.1032 156.126 90.6384 156.448Z" fill="#00283B" />
        <path d="M95.7229 153.524C96.2581 153.846 96.2581 154.329 95.7229 154.651C95.1877 154.972 94.3314 154.972 93.7962 154.651C93.261 154.329 93.261 153.846 93.7962 153.524C94.3314 153.202 95.1877 153.202 95.7229 153.524Z" fill="#00283B" />
        <path d="M100.781 150.6C101.316 150.922 101.316 151.405 100.781 151.727C100.245 152.049 99.389 152.049 98.8538 151.727C98.3186 151.405 98.3186 150.922 98.8538 150.6C99.389 150.278 100.245 150.278 100.781 150.6Z" fill="#00283B" />
        <path d="M105.865 147.649C106.4 147.971 106.4 148.454 105.865 148.776C105.33 149.098 104.473 149.098 103.938 148.776C103.403 148.454 103.403 147.971 103.938 147.649C104.473 147.327 105.33 147.327 105.865 147.649Z" fill="#00283B" />
        <path d="M110.923 144.726C111.458 145.047 111.458 145.53 110.923 145.852C110.387 146.174 109.531 146.174 108.996 145.852C108.461 145.53 108.461 145.047 108.996 144.726C109.531 144.404 110.387 144.404 110.923 144.726Z" fill="#00283B" />
        <path d="M116.007 141.775C116.542 142.097 116.542 142.58 116.007 142.901C115.472 143.223 114.616 143.223 114.08 142.901C113.545 142.58 113.545 142.097 114.08 141.775C114.616 141.453 115.472 141.453 116.007 141.775Z" fill="#00283B" />
        <path d="M121.065 138.851C121.6 139.173 121.6 139.656 121.065 139.977C120.529 140.299 119.673 140.299 119.138 139.977C118.603 139.656 118.603 139.173 119.138 138.851C119.673 138.529 120.529 138.529 121.065 138.851Z" fill="#00283B" />
        <path d="M121.065 132.976C121.6 133.298 121.6 133.781 121.065 134.103C120.529 134.425 119.673 134.425 119.138 134.103C118.603 133.781 118.603 133.298 119.138 132.976C119.673 132.654 120.529 132.654 121.065 132.976Z" fill="#00283B" />
        <path d="M126.149 130.052C126.684 130.374 126.684 130.857 126.149 131.179C125.614 131.501 124.758 131.501 124.222 131.179C123.687 130.857 123.687 130.374 124.222 130.052C124.758 129.731 125.614 129.731 126.149 130.052Z" fill="#00283B" />
        <path d="M131.207 127.102C131.742 127.424 131.742 127.907 131.207 128.228C130.672 128.55 129.815 128.55 129.28 128.228C128.745 127.907 128.745 127.424 129.28 127.102C129.815 126.78 130.672 126.78 131.207 127.102Z" fill="#00283B" />
        <path d="M136.291 124.178C136.826 124.5 136.826 124.983 136.291 125.305C135.756 125.626 134.9 125.626 134.365 125.305C133.829 124.983 133.829 124.5 134.365 124.178C134.9 123.856 135.756 123.856 136.291 124.178Z" fill="#00283B" />
        <path d="M141.349 121.227C141.884 121.549 141.884 122.032 141.349 122.354C140.814 122.676 139.957 122.676 139.422 122.354C138.887 122.032 138.887 121.549 139.422 121.227C139.957 120.905 140.814 120.905 141.349 121.227Z" fill="#00283B" />
        <path d="M146.433 118.303C146.969 118.625 146.969 119.108 146.433 119.43C145.898 119.752 145.042 119.752 144.507 119.43C143.971 119.108 143.971 118.625 144.507 118.303C145.042 117.982 145.898 117.982 146.433 118.303Z" fill="#00283B" />
        <path d="M151.518 115.38C152.053 115.701 152.053 116.184 151.518 116.506C150.982 116.828 150.126 116.828 149.591 116.506C149.056 116.184 149.056 115.701 149.591 115.38C150.126 115.058 150.982 115.058 151.518 115.38Z" fill="#00283B" />
        <path d="M98.8538 139.977C98.3186 139.656 98.3186 139.173 98.8538 138.851C99.389 138.529 100.245 138.529 100.781 138.851C101.316 139.173 101.316 139.656 100.781 139.977C100.245 140.299 99.389 140.299 98.8538 139.977Z" fill="#00283B" />
        <path d="M105.865 135.927C106.4 136.249 106.4 136.732 105.865 137.054C105.33 137.375 104.473 137.375 103.938 137.054C103.403 136.732 103.403 136.249 103.938 135.927C104.473 135.605 105.33 135.605 105.865 135.927Z" fill="#00283B" />
        <path d="M110.923 132.976C111.458 133.298 111.458 133.781 110.923 134.103C110.387 134.425 109.531 134.425 108.996 134.103C108.461 133.781 108.461 133.298 108.996 132.976C109.531 132.654 110.387 132.654 110.923 132.976Z" fill="#00283B" />
        <path d="M116.007 130.052C116.542 130.374 116.542 130.857 116.007 131.179C115.472 131.501 114.616 131.501 114.08 131.179C113.545 130.857 113.545 130.374 114.08 130.052C114.616 129.731 115.472 129.731 116.007 130.052Z" fill="#00283B" />
        <path d="M121.065 127.102C121.6 127.424 121.6 127.907 121.065 128.228C120.529 128.55 119.673 128.55 119.138 128.228C118.603 127.907 118.603 127.424 119.138 127.102C119.673 126.78 120.529 126.78 121.065 127.102Z" fill="#00283B" />
        <path d="M126.149 124.178C126.684 124.5 126.684 124.983 126.149 125.305C125.614 125.626 124.758 125.626 124.222 125.305C123.687 124.983 123.687 124.5 124.222 124.178C124.758 123.856 125.614 123.856 126.149 124.178Z" fill="#00283B" />
        <path d="M131.207 121.227C131.742 121.549 131.742 122.032 131.207 122.354C130.672 122.676 129.815 122.676 129.28 122.354C128.745 122.032 128.745 121.549 129.28 121.227C129.815 120.905 130.672 120.905 131.207 121.227Z" fill="#00283B" />
        <path d="M136.291 118.303C136.826 118.625 136.826 119.108 136.291 119.43C135.756 119.752 134.9 119.752 134.365 119.43C133.829 119.108 133.829 118.625 134.365 118.303C134.9 117.982 135.756 117.982 136.291 118.303Z" fill="#00283B" />
        <path d="M141.349 115.38C141.884 115.701 141.884 116.184 141.349 116.506C140.814 116.828 139.957 116.828 139.422 116.506C138.887 116.184 138.887 115.701 139.422 115.38C139.957 115.058 140.814 115.058 141.349 115.38Z" fill="#00283B" />
        <path d="M146.433 112.429C146.969 112.751 146.969 113.234 146.433 113.556C145.898 113.877 145.042 113.877 144.507 113.556C143.971 113.234 143.971 112.751 144.507 112.429C145.042 112.107 145.898 112.107 146.433 112.429Z" fill="#00283B" />
        <path d="M95.6694 135.873C96.2046 136.195 96.2046 136.678 95.6694 137C95.1342 137.322 94.2779 137.322 93.7427 137C93.2075 136.678 93.2075 136.195 93.7427 135.873C94.2779 135.551 95.1342 135.551 95.6694 135.873Z" fill="#00283B" />
        <path d="M100.754 132.949C101.289 133.271 101.289 133.754 100.754 134.076C100.219 134.398 99.3624 134.398 98.8272 134.076C98.292 133.754 98.292 133.271 98.8272 132.949C99.3624 132.628 100.219 132.628 100.754 132.949Z" fill="#00283B" />
        <path d="M105.812 130.026C106.347 130.347 106.347 130.83 105.812 131.152C105.276 131.474 104.42 131.474 103.885 131.152C103.35 130.83 103.35 130.347 103.885 130.026C104.42 129.704 105.276 129.704 105.812 130.026Z" fill="#00283B" />
        <path d="M110.896 127.075C111.431 127.397 111.431 127.88 110.896 128.202C110.361 128.523 109.504 128.523 108.969 128.202C108.434 127.88 108.434 127.397 108.969 127.075C109.504 126.753 110.361 126.753 110.896 127.075Z" fill="#00283B" />
        <path d="M115.954 124.151C116.489 124.473 116.489 124.956 115.954 125.278C115.418 125.6 114.562 125.6 114.027 125.278C113.492 124.956 113.492 124.473 114.027 124.151C114.562 123.829 115.418 123.829 115.954 124.151Z" fill="#00283B" />
        <path d="M121.038 121.2C121.573 121.522 121.573 122.005 121.038 122.327C120.503 122.649 119.646 122.649 119.111 122.327C118.576 122.005 118.576 121.522 119.111 121.2C119.646 120.879 120.503 120.879 121.038 121.2Z" fill="#00283B" />
        <path d="M126.096 118.277C126.631 118.598 126.631 119.081 126.096 119.403C125.561 119.725 124.704 119.725 124.169 119.403C123.634 119.081 123.634 118.598 124.169 118.277C124.704 117.955 125.561 117.955 126.096 118.277Z" fill="#00283B" />
        <path d="M131.18 115.326C131.715 115.648 131.715 116.131 131.18 116.453C130.645 116.774 129.788 116.774 129.253 116.453C128.718 116.131 128.718 115.648 129.253 115.326C129.788 115.004 130.645 115.004 131.18 115.326Z" fill="#00283B" />
        <path d="M136.238 112.402C136.773 112.724 136.773 113.207 136.238 113.529C135.703 113.851 134.846 113.851 134.311 113.529C133.776 113.207 133.776 112.724 134.311 112.402C134.846 112.08 135.703 112.08 136.238 112.402Z" fill="#00283B" />
        <path d="M141.322 109.478C141.857 109.8 141.857 110.283 141.322 110.605C140.787 110.927 139.93 110.927 139.395 110.605C138.86 110.283 138.86 109.8 139.395 109.478C139.93 109.156 140.787 109.156 141.322 109.478Z" fill="#00283B" />
        <path d="M90.6118 132.949C91.147 133.271 91.147 133.754 90.6118 134.076C90.0766 134.398 89.2203 134.398 88.6851 134.076C88.1499 133.754 88.1499 133.271 88.6851 132.949C89.2203 132.628 90.0766 132.628 90.6118 132.949Z" fill="#00283B" />
        <path d="M95.6694 130.026C96.2046 130.347 96.2046 130.83 95.6694 131.152C95.1342 131.474 94.2779 131.474 93.7427 131.152C93.2075 130.83 93.2075 130.347 93.7427 130.026C94.2779 129.704 95.1342 129.704 95.6694 130.026Z" fill="#00283B" />
        <path d="M100.754 127.075C101.289 127.397 101.289 127.88 100.754 128.202C100.219 128.523 99.3624 128.523 98.8272 128.202C98.292 127.88 98.292 127.397 98.8272 127.075C99.3624 126.753 100.219 126.753 100.754 127.075Z" fill="#00283B" />
        <path d="M105.812 124.151C106.347 124.473 106.347 124.956 105.812 125.278C105.276 125.6 104.42 125.6 103.885 125.278C103.35 124.956 103.35 124.473 103.885 124.151C104.42 123.829 105.276 123.829 105.812 124.151Z" fill="#00283B" />
        <path d="M110.896 121.2C111.431 121.522 111.431 122.005 110.896 122.327C110.361 122.649 109.504 122.649 108.969 122.327C108.434 122.005 108.434 121.522 108.969 121.2C109.504 120.879 110.361 120.879 110.896 121.2Z" fill="#00283B" />
        <path d="M115.954 118.277C116.489 118.598 116.489 119.081 115.954 119.403C115.418 119.725 114.562 119.725 114.027 119.403C113.492 119.081 113.492 118.598 114.027 118.277C114.562 117.955 115.418 117.955 115.954 118.277Z" fill="#00283B" />
        <path d="M121.038 115.326C121.573 115.648 121.573 116.131 121.038 116.453C120.503 116.774 119.646 116.774 119.111 116.453C118.576 116.131 118.576 115.648 119.111 115.326C119.646 115.004 120.503 115.004 121.038 115.326Z" fill="#00283B" />
        <path d="M126.096 112.402C126.631 112.724 126.631 113.207 126.096 113.529C125.561 113.851 124.704 113.851 124.169 113.529C123.634 113.207 123.634 112.724 124.169 112.402C124.704 112.08 125.561 112.08 126.096 112.402Z" fill="#00283B" />
        <path d="M131.18 109.478C131.715 109.8 131.715 110.283 131.18 110.605C130.645 110.927 129.788 110.927 129.253 110.605C128.718 110.283 128.718 109.8 129.253 109.478C129.788 109.156 130.645 109.156 131.18 109.478Z" fill="#00283B" />
        <path d="M136.238 106.528C136.773 106.849 136.773 107.332 136.238 107.654C135.703 107.976 134.846 107.976 134.311 107.654C133.776 107.332 133.776 106.849 134.311 106.528C134.846 106.206 135.703 106.206 136.238 106.528Z" fill="#00283B" />
        <path d="M85.5273 130.026C86.0625 130.347 86.0625 130.83 85.5273 131.152C84.9921 131.474 84.1358 131.474 83.6006 131.152C83.0654 130.83 83.0654 130.347 83.6006 130.026C84.1358 129.704 84.9921 129.704 85.5273 130.026Z" fill="#00283B" />
        <path d="M90.6118 127.075C91.147 127.397 91.147 127.88 90.6118 128.202C90.0766 128.523 89.2203 128.523 88.6851 128.202C88.1499 127.88 88.1499 127.397 88.6851 127.075C89.2203 126.753 90.0766 126.753 90.6118 127.075Z" fill="#00283B" />
        <path d="M95.6694 124.151C96.2046 124.473 96.2046 124.956 95.6694 125.278C95.1342 125.6 94.2779 125.6 93.7427 125.278C93.2075 124.956 93.2075 124.473 93.7427 124.151C94.2779 123.829 95.1342 123.829 95.6694 124.151Z" fill="#00283B" />
        <path d="M100.754 121.2C101.289 121.522 101.289 122.005 100.754 122.327C100.219 122.649 99.3624 122.649 98.8272 122.327C98.292 122.005 98.292 121.522 98.8272 121.2C99.3624 120.879 100.219 120.879 100.754 121.2Z" fill="#00283B" />
        <path d="M105.812 118.277C106.347 118.598 106.347 119.081 105.812 119.403C105.276 119.725 104.42 119.725 103.885 119.403C103.35 119.081 103.35 118.598 103.885 118.277C104.42 117.955 105.276 117.955 105.812 118.277Z" fill="#00283B" />
        <path d="M110.896 115.326C111.431 115.648 111.431 116.131 110.896 116.453C110.361 116.774 109.504 116.774 108.969 116.453C108.434 116.131 108.434 115.648 108.969 115.326C109.504 115.004 110.361 115.004 110.896 115.326Z" fill="#00283B" />
        <path d="M115.954 112.402C116.489 112.724 116.489 113.207 115.954 113.529C115.418 113.851 114.562 113.851 114.027 113.529C113.492 113.207 113.492 112.724 114.027 112.402C114.562 112.08 115.418 112.08 115.954 112.402Z" fill="#00283B" />
        <path d="M121.038 109.478C121.573 109.8 121.573 110.283 121.038 110.605C120.503 110.927 119.646 110.927 119.111 110.605C118.576 110.283 118.576 109.8 119.111 109.478C119.646 109.156 120.503 109.156 121.038 109.478Z" fill="#00283B" />
        <path d="M126.096 106.528C126.631 106.849 126.631 107.332 126.096 107.654C125.561 107.976 124.704 107.976 124.169 107.654C123.634 107.332 123.634 106.849 124.169 106.528C124.704 106.206 125.561 106.206 126.096 106.528Z" fill="#00283B" />
        <path d="M43.0056 147.623C43.5409 147.301 44.3972 147.301 44.9324 147.623C45.4676 147.944 45.4676 148.427 44.9324 148.749C44.3972 149.071 43.5409 149.071 43.0056 148.749C42.4704 148.427 42.4704 147.944 43.0056 147.623Z" fill="#00283B" />
        <path d="M50.0166 144.699C50.5518 145.021 50.5518 145.503 50.0166 145.825C49.4814 146.147 48.6251 146.147 48.0899 145.825C47.5547 145.503 47.5547 145.021 48.0899 144.699C48.6251 144.377 49.4814 144.377 50.0166 144.699Z" fill="#00283B" />
        <path d="M55.1011 141.748C55.6363 142.07 55.6363 142.553 55.1011 142.875C54.5659 143.197 53.7096 143.197 53.1744 142.875C52.6391 142.553 52.6391 142.07 53.1744 141.748C53.7096 141.426 54.5659 141.426 55.1011 141.748Z" fill="#00283B" />
        <path d="M60.1856 138.824C60.7208 139.146 60.7208 139.629 60.1856 139.951C59.6504 140.273 58.794 140.273 58.2588 139.951C57.7236 139.629 57.7236 139.146 58.2588 138.824C58.794 138.502 59.6504 138.502 60.1856 138.824Z" fill="#00283B" />
        <path d="M65.2432 135.873C65.7784 136.195 65.7784 136.678 65.2432 137C64.708 137.322 63.8516 137.322 63.3164 137C62.7812 136.678 62.7812 136.195 63.3164 135.873C63.8516 135.551 64.708 135.551 65.2432 135.873Z" fill="#00283B" />
        <path d="M70.3276 132.949C70.8628 133.271 70.8628 133.754 70.3276 134.076C69.7924 134.398 68.9361 134.398 68.4009 134.076C67.8657 133.754 67.8657 133.271 68.4009 132.949C68.9361 132.628 69.7924 132.628 70.3276 132.949Z" fill="#00283B" />
        <path d="M75.3853 130.026C75.9205 130.347 75.9205 130.83 75.3853 131.152C74.8501 131.474 73.9937 131.474 73.4585 131.152C72.9233 130.83 72.9233 130.347 73.4585 130.026C73.9937 129.704 74.8501 129.704 75.3853 130.026Z" fill="#00283B" />
        <path d="M80.4697 127.075C81.0049 127.397 81.0049 127.88 80.4697 128.202C79.9345 128.523 79.0782 128.523 78.543 128.202C78.0078 127.88 78.0078 127.397 78.543 127.075C79.0782 126.753 79.9345 126.753 80.4697 127.075Z" fill="#00283B" />
        <path d="M85.5273 124.151C86.0625 124.473 86.0625 124.956 85.5273 125.278C84.9921 125.6 84.1358 125.6 83.6006 125.278C83.0654 124.956 83.0654 124.473 83.6006 124.151C84.1358 123.829 84.9921 123.829 85.5273 124.151Z" fill="#00283B" />
        <path d="M90.6118 121.2C91.147 121.522 91.147 122.005 90.6118 122.327C90.0766 122.649 89.2203 122.649 88.6851 122.327C88.1499 122.005 88.1499 121.522 88.6851 121.2C89.2203 120.879 90.0766 120.879 90.6118 121.2Z" fill="#00283B" />
        <path d="M95.6694 118.277C96.2046 118.598 96.2046 119.081 95.6694 119.403C95.1342 119.725 94.2779 119.725 93.7427 119.403C93.2075 119.081 93.2075 118.598 93.7427 118.277C94.2779 117.955 95.1342 117.955 95.6694 118.277Z" fill="#00283B" />
        <path d="M100.754 115.326C101.289 115.648 101.289 116.131 100.754 116.453C100.219 116.774 99.3624 116.774 98.8272 116.453C98.292 116.131 98.292 115.648 98.8272 115.326C99.3624 115.004 100.219 115.004 100.754 115.326Z" fill="#00283B" />
        <path d="M105.812 112.402C106.347 112.724 106.347 113.207 105.812 113.529C105.276 113.851 104.42 113.851 103.885 113.529C103.35 113.207 103.35 112.724 103.885 112.402C104.42 112.08 105.276 112.08 105.812 112.402Z" fill="#00283B" />
        <path d="M110.896 109.478C111.431 109.8 111.431 110.283 110.896 110.605C110.361 110.927 109.504 110.927 108.969 110.605C108.434 110.283 108.434 109.8 108.969 109.478C109.504 109.156 110.361 109.156 110.896 109.478Z" fill="#00283B" />
        <path d="M212.638 203.015C213.173 203.337 213.173 203.82 212.638 204.142C212.102 204.464 211.246 204.464 210.711 204.142C210.176 203.82 210.176 203.337 210.711 203.015C211.246 202.693 212.102 202.693 212.638 203.015Z" fill="#00283B" />
        <path d="M217.722 200.091C218.257 200.413 218.257 200.896 217.722 201.218C217.187 201.54 216.331 201.54 215.795 201.218C215.26 200.896 215.26 200.413 215.795 200.091C216.331 199.769 217.187 199.769 217.722 200.091Z" fill="#00283B" />
        <path d="M222.78 197.14C223.315 197.462 223.315 197.945 222.78 198.267C222.245 198.589 221.388 198.589 220.853 198.267C220.318 197.945 220.318 197.462 220.853 197.14C221.388 196.818 222.245 196.818 222.78 197.14Z" fill="#00283B" />
        <path d="M227.864 194.217C228.399 194.538 228.399 195.021 227.864 195.343C227.329 195.665 226.473 195.665 225.938 195.343C225.402 195.021 225.402 194.538 225.938 194.217C226.473 193.895 227.329 193.895 227.864 194.217Z" fill="#00283B" />
        <path d="M202.496 203.015C203.031 203.337 203.031 203.82 202.496 204.142C201.96 204.464 201.104 204.464 200.569 204.142C200.034 203.82 200.034 203.337 200.569 203.015C201.104 202.693 201.96 202.693 202.496 203.015Z" fill="#00283B" />
        <path d="M207.58 200.091C208.115 200.413 208.115 200.896 207.58 201.218C207.045 201.54 206.189 201.54 205.653 201.218C205.118 200.896 205.118 200.413 205.653 200.091C206.189 199.769 207.045 199.769 207.58 200.091Z" fill="#00283B" />
        <path d="M212.638 197.14C213.173 197.462 213.173 197.945 212.638 198.267C212.102 198.589 211.246 198.589 210.711 198.267C210.176 197.945 210.176 197.462 210.711 197.14C211.246 196.818 212.102 196.818 212.638 197.14Z" fill="#00283B" />
        <path d="M217.722 194.217C218.257 194.538 218.257 195.021 217.722 195.343C217.187 195.665 216.331 195.665 215.795 195.343C215.26 195.021 215.26 194.538 215.795 194.217C216.331 193.895 217.187 193.895 217.722 194.217Z" fill="#00283B" />
        <path d="M222.78 191.266C223.315 191.588 223.315 192.071 222.78 192.392C222.245 192.714 221.388 192.714 220.853 192.392C220.318 192.071 220.318 191.588 220.853 191.266C221.388 190.944 222.245 190.944 222.78 191.266Z" fill="#00283B" />
        <path d="M227.864 188.342C228.399 188.664 228.399 189.147 227.864 189.469C227.329 189.791 226.473 189.791 225.938 189.469C225.402 189.147 225.402 188.664 225.938 188.342C226.473 188.02 227.329 188.02 227.864 188.342Z" fill="#00283B" />
        <path d="M192.354 203.015C192.889 203.337 192.889 203.82 192.354 204.142C191.818 204.464 190.962 204.464 190.427 204.142C189.892 203.82 189.892 203.337 190.427 203.015C190.962 202.693 191.818 202.693 192.354 203.015Z" fill="#00283B" />
        <path d="M197.438 200.091C197.973 200.413 197.973 200.896 197.438 201.218C196.903 201.54 196.046 201.54 195.511 201.218C194.976 200.896 194.976 200.413 195.511 200.091C196.046 199.769 196.903 199.769 197.438 200.091Z" fill="#00283B" />
        <path d="M202.496 197.14C203.031 197.462 203.031 197.945 202.496 198.267C201.96 198.589 201.104 198.589 200.569 198.267C200.034 197.945 200.034 197.462 200.569 197.14C201.104 196.818 201.96 196.818 202.496 197.14Z" fill="#00283B" />
        <path d="M207.58 194.217C208.115 194.538 208.115 195.021 207.58 195.343C207.045 195.665 206.189 195.665 205.653 195.343C205.118 195.021 205.118 194.538 205.653 194.217C206.189 193.895 207.045 193.895 207.58 194.217Z" fill="#00283B" />
        <path d="M212.638 191.266C213.173 191.588 213.173 192.071 212.638 192.392C212.102 192.714 211.246 192.714 210.711 192.392C210.176 192.071 210.176 191.588 210.711 191.266C211.246 190.944 212.102 190.944 212.638 191.266Z" fill="#00283B" />
        <path d="M217.722 188.342C218.257 188.664 218.257 189.147 217.722 189.469C217.187 189.791 216.331 189.791 215.795 189.469C215.26 189.147 215.26 188.664 215.795 188.342C216.331 188.02 217.187 188.02 217.722 188.342Z" fill="#00283B" />
        <path d="M222.78 185.391C223.315 185.713 223.315 186.196 222.78 186.518C222.245 186.84 221.388 186.84 220.853 186.518C220.318 186.196 220.318 185.713 220.853 185.391C221.388 185.069 222.245 185.069 222.78 185.391Z" fill="#00283B" />
        <path d="M187.296 200.091C187.831 200.413 187.831 200.896 187.296 201.218C186.761 201.54 185.904 201.54 185.369 201.218C184.834 200.896 184.834 200.413 185.369 200.091C185.904 199.769 186.761 199.769 187.296 200.091Z" fill="#00283B" />
        <path d="M192.354 197.14C192.889 197.462 192.889 197.945 192.354 198.267C191.818 198.589 190.962 198.589 190.427 198.267C189.892 197.945 189.892 197.462 190.427 197.14C190.962 196.818 191.818 196.818 192.354 197.14Z" fill="#00283B" />
        <path d="M197.438 194.217C197.973 194.538 197.973 195.021 197.438 195.343C196.903 195.665 196.046 195.665 195.511 195.343C194.976 195.021 194.976 194.538 195.511 194.217C196.046 193.895 196.903 193.895 197.438 194.217Z" fill="#00283B" />
        <path d="M202.496 191.266C203.031 191.588 203.031 192.071 202.496 192.392C201.96 192.714 201.104 192.714 200.569 192.392C200.034 192.071 200.034 191.588 200.569 191.266C201.104 190.944 201.96 190.944 202.496 191.266Z" fill="#00283B" />
        <path d="M182.211 197.14C182.747 197.462 182.747 197.945 182.211 198.267C181.676 198.589 180.82 198.589 180.285 198.267C179.75 197.945 179.75 197.462 180.285 197.14C180.82 196.818 181.676 196.818 182.211 197.14Z" fill="#00283B" />
        <path d="M187.296 194.217C187.831 194.538 187.831 195.021 187.296 195.343C186.761 195.665 185.904 195.665 185.369 195.343C184.834 195.021 184.834 194.538 185.369 194.217C185.904 193.895 186.761 193.895 187.296 194.217Z" fill="#00283B" />
        <path d="M192.354 191.266C192.889 191.588 192.889 192.071 192.354 192.392C191.818 192.714 190.962 192.714 190.427 192.392C189.892 192.071 189.892 191.588 190.427 191.266C190.962 190.944 191.818 190.944 192.354 191.266Z" fill="#00283B" />
        <path d="M182.211 191.266C182.747 191.588 182.747 192.071 182.211 192.392C181.676 192.714 180.82 192.714 180.285 192.392C179.75 192.071 179.75 191.588 180.285 191.266C180.82 190.944 181.676 190.944 182.211 191.266Z" fill="#00283B" />
        <path d="M187.296 188.342C187.831 188.664 187.831 189.147 187.296 189.469C186.761 189.791 185.904 189.791 185.369 189.469C184.834 189.147 184.834 188.664 185.369 188.342C185.904 188.02 186.761 188.02 187.296 188.342Z" fill="#00283B" />
        <path d="M182.185 185.364C182.72 185.686 182.72 186.169 182.185 186.491C181.65 186.813 180.793 186.813 180.258 186.491C179.723 186.169 179.723 185.686 180.258 185.364C180.793 185.043 181.65 185.043 182.185 185.364Z" fill="#00283B" />
        <path d="M177.1 182.441C177.636 182.763 177.636 183.245 177.1 183.567C176.565 183.889 175.709 183.889 175.174 183.567C174.638 183.245 174.638 182.763 175.174 182.441C175.709 182.119 176.565 182.119 177.1 182.441Z" fill="#00283B" />
        <path d="M182.185 179.49C182.72 179.812 182.72 180.295 182.185 180.617C181.65 180.938 180.793 180.938 180.258 180.617C179.723 180.295 179.723 179.812 180.258 179.49C180.793 179.168 181.65 179.168 182.185 179.49Z" fill="#00283B" />
        <path d="M172.043 179.49C172.578 179.812 172.578 180.295 172.043 180.617C171.508 180.938 170.651 180.938 170.116 180.617C169.581 180.295 169.581 179.812 170.116 179.49C170.651 179.168 171.508 179.168 172.043 179.49Z" fill="#00283B" />
        <path d="M177.1 176.566C177.636 176.888 177.636 177.371 177.1 177.693C176.565 178.015 175.709 178.015 175.174 177.693C174.638 177.371 174.638 176.888 175.174 176.566C175.709 176.244 176.565 176.244 177.1 176.566Z" fill="#00283B" />
        <path d="M182.185 173.642C182.72 173.964 182.72 174.447 182.185 174.769C181.65 175.091 180.793 175.091 180.258 174.769C179.723 174.447 179.723 173.964 180.258 173.642C180.793 173.32 181.65 173.32 182.185 173.642Z" fill="#00283B" />
        <path d="M166.958 176.566C167.493 176.888 167.493 177.371 166.958 177.693C166.423 178.015 165.567 178.015 165.032 177.693C164.496 177.371 164.496 176.888 165.032 176.566C165.567 176.244 166.423 176.244 166.958 176.566Z" fill="#00283B" />
        <path d="M172.043 173.642C172.578 173.964 172.578 174.447 172.043 174.769C171.508 175.091 170.651 175.091 170.116 174.769C169.581 174.447 169.581 173.964 170.116 173.642C170.651 173.32 171.508 173.32 172.043 173.642Z" fill="#00283B" />
        <path d="M177.1 170.692C177.636 171.014 177.636 171.496 177.1 171.818C176.565 172.14 175.709 172.14 175.174 171.818C174.638 171.496 174.638 171.014 175.174 170.692C175.709 170.37 176.565 170.37 177.1 170.692Z" fill="#00283B" />
        <path d="M154.675 112.456C155.211 112.134 156.067 112.134 156.602 112.456C157.137 112.777 157.137 113.26 156.602 113.582C156.067 113.904 155.211 113.904 154.675 113.582C154.14 113.26 154.14 112.777 154.675 112.456Z" fill="#00283B" />
        <path d="M161.687 109.532C162.222 109.854 162.222 110.336 161.687 110.658C161.151 110.98 160.295 110.98 159.76 110.658C159.225 110.336 159.225 109.854 159.76 109.532C160.295 109.21 161.151 109.21 161.687 109.532Z" fill="#00283B" />
        <path d="M166.771 106.581C167.306 106.903 167.306 107.386 166.771 107.708C166.236 108.03 165.379 108.03 164.844 107.708C164.309 107.386 164.309 106.903 164.844 106.581C165.379 106.259 166.236 106.259 166.771 106.581Z" fill="#00283B" />
        <path d="M149.591 109.532C150.126 109.21 150.982 109.21 151.518 109.532C152.053 109.854 152.053 110.336 151.518 110.658C150.982 110.98 150.126 110.98 149.591 110.658C149.056 110.336 149.056 109.854 149.591 109.532Z" fill="#00283B" />
        <path d="M156.629 106.581C157.164 106.903 157.164 107.386 156.629 107.708C156.094 108.03 155.237 108.03 154.702 107.708C154.167 107.386 154.167 106.903 154.702 106.581C155.237 106.259 156.094 106.259 156.629 106.581Z" fill="#00283B" />
        <path d="M161.687 103.657C162.222 103.979 162.222 104.462 161.687 104.784C161.151 105.106 160.295 105.106 159.76 104.784C159.225 104.462 159.225 103.979 159.76 103.657C160.295 103.335 161.151 103.335 161.687 103.657Z" fill="#00283B" />
        <path d="M166.771 100.707C167.306 101.028 167.306 101.511 166.771 101.833C166.236 102.155 165.379 102.155 164.844 101.833C164.309 101.511 164.309 101.028 164.844 100.707C165.379 100.385 166.236 100.385 166.771 100.707Z" fill="#00283B" />
        <path d="M144.48 106.554C145.015 106.232 145.871 106.232 146.406 106.554C146.942 106.876 146.942 107.359 146.406 107.681C145.871 108.003 145.015 108.003 144.48 107.681C143.945 107.359 143.945 106.876 144.48 106.554Z" fill="#00283B" />
        <path d="M151.491 103.63C152.026 103.952 152.026 104.435 151.491 104.757C150.956 105.079 150.099 105.079 149.564 104.757C149.029 104.435 149.029 103.952 149.564 103.63C150.099 103.308 150.956 103.308 151.491 103.63Z" fill="#00283B" />
        <path d="M156.575 100.68C157.111 101.002 157.111 101.484 156.575 101.806C156.04 102.128 155.184 102.128 154.649 101.806C154.114 101.484 154.114 101.002 154.649 100.68C155.184 100.358 156.04 100.358 156.575 100.68Z" fill="#00283B" />
        <path d="M161.633 97.7558C162.168 98.0777 162.168 98.5606 161.633 98.8824C161.098 99.2043 160.242 99.2043 159.706 98.8824C159.171 98.5606 159.171 98.0777 159.706 97.7558C160.242 97.4339 161.098 97.4339 161.633 97.7558Z" fill="#00283B" />
        <path d="M139.422 103.63C139.957 103.308 140.814 103.308 141.349 103.63C141.884 103.952 141.884 104.435 141.349 104.757C140.814 105.079 139.957 105.079 139.422 104.757C138.887 104.435 138.887 103.952 139.422 103.63Z" fill="#00283B" />
        <path d="M146.433 100.68C146.969 101.002 146.969 101.484 146.433 101.806C145.898 102.128 145.042 102.128 144.507 101.806C143.971 101.484 143.971 101.002 144.507 100.68C145.042 100.358 145.898 100.358 146.433 100.68Z" fill="#00283B" />
        <path d="M151.491 97.7558C152.026 98.0777 152.026 98.5606 151.491 98.8824C150.956 99.2043 150.099 99.2043 149.564 98.8824C149.029 98.5606 149.029 98.0777 149.564 97.7558C150.099 97.4339 150.956 97.4339 151.491 97.7558Z" fill="#00283B" />
        <path d="M156.575 94.8051C157.111 95.127 157.111 95.6099 156.575 95.9318C156.04 96.2537 155.184 96.2537 154.649 95.9318C154.114 95.6099 154.114 95.127 154.649 94.8051C155.184 94.4832 156.04 94.4832 156.575 94.8051Z" fill="#00283B" />
        <path d="M176.03 167.365C168.671 163.1 157.11 163.1 149.751 167.365L121.493 183.728C115.659 187.108 105.811 187.108 99.9777 183.728C97.5961 182.36 96.2313 180.59 96.2313 178.873C96.2313 177.156 97.5961 175.413 99.9777 174.018L132.598 155.133C136.559 152.853 138.753 149.661 138.753 146.147C138.753 142.633 136.559 139.441 132.625 137.161L131.902 136.732C124.517 132.467 112.983 132.467 105.597 136.732L42.5506 173.24C36.7169 176.62 26.8692 176.62 21.0355 173.24L9.60893 166.614C7.22729 165.219 5.86252 163.476 5.86252 161.759C5.86252 160.042 7.22729 158.299 9.60893 156.904L108.461 100.009C112.207 101.619 117.479 101.458 120.851 99.4994C124.57 97.3534 124.57 93.8394 120.851 91.6935C117.131 89.5475 111.083 89.5475 107.364 91.6935C105.035 93.0347 104.179 94.8856 104.741 96.6292L7.14701 152.773C3.18651 155.053 0.992188 158.245 0.992188 161.759C0.992188 165.273 3.18651 168.465 7.14701 170.745L18.5736 177.371C25.9326 181.636 37.4929 181.636 44.852 177.371L107.899 140.89C113.732 137.51 123.58 137.51 129.414 140.89L130.136 141.319C132.518 142.687 133.883 144.457 133.883 146.174C133.883 147.891 132.518 149.634 130.136 151.029L97.5158 169.914C93.5553 172.194 91.361 175.386 91.361 178.9C91.361 182.414 93.5553 185.606 97.5158 187.886C104.875 192.151 116.435 192.151 123.794 187.886L152.053 171.523C157.887 168.143 167.761 168.143 173.541 171.523L186.333 179.57L188.607 173.213L175.976 167.392L176.03 167.365Z" fill="#00283B" />
        <path d="M176.565 163.368C169.206 159.103 157.646 159.103 150.287 163.368L122.028 179.731C116.194 183.111 106.347 183.111 100.513 179.731C98.1312 178.363 96.7665 176.593 96.7665 174.876C96.7665 173.159 98.1312 171.416 100.513 170.021L133.133 151.136C137.094 148.856 139.288 145.664 139.288 142.15C139.288 138.636 137.094 135.444 133.16 133.164L132.438 132.735C125.052 128.47 113.518 128.47 106.132 132.735L43.0857 169.243C37.252 172.623 27.4043 172.623 21.5706 169.243L10.1441 162.617C7.76244 161.222 6.39768 159.479 6.39768 157.762C6.39768 156.045 7.76244 154.302 10.1441 152.907L110.013 95.422C113.545 96.3876 117.907 96.0389 120.824 94.349C124.543 92.203 124.543 88.689 120.824 86.5431C117.104 84.3971 111.056 84.3971 107.337 86.5431C104.5 88.1794 103.831 90.5935 105.303 92.6054L7.68216 148.803C3.72167 151.083 1.52734 154.275 1.52734 157.789C1.52734 161.303 3.72167 164.495 7.68216 166.775L19.1087 173.401C26.4677 177.666 38.0281 177.666 45.3871 173.401L108.434 136.919C114.268 133.54 124.115 133.54 129.949 136.919L130.671 137.349C133.053 138.717 134.418 140.487 134.418 142.204C134.418 143.921 133.053 145.664 130.671 147.059L98.0509 165.943C94.0905 168.224 91.8961 171.416 91.8961 174.93C91.8961 178.444 94.0905 181.636 98.0509 183.916C105.41 188.181 116.97 188.181 124.356 183.916L152.615 167.553C158.448 164.173 168.323 164.173 174.103 167.553L186.894 175.6L192.193 173.213L176.538 163.422L176.565 163.368Z" fill="#F5FCFF" />
        <path d="M207.634 179.892C205.118 178.444 201.051 178.444 198.535 179.892C196.02 181.341 196.02 183.701 198.535 185.15C201.051 186.598 205.118 186.598 207.634 185.15C210.149 183.701 210.149 181.341 207.634 179.892Z" fill="#00283B" />
        <path d="M217.535 173.159C209.453 168.465 196.769 168.465 188.661 173.159C184.486 175.573 182.185 178.9 182.185 182.494C182.185 186.115 184.486 189.415 188.661 191.856C192.702 194.19 197.893 195.37 203.084 195.37C208.276 195.37 213.467 194.19 217.508 191.856C221.683 189.442 223.984 186.115 223.984 182.494C223.984 178.873 221.683 175.573 217.508 173.159H217.535ZM215.822 188.878C208.811 192.929 197.411 192.929 190.4 188.878C187.323 187.108 185.637 184.828 185.637 182.521C185.637 180.187 187.323 177.934 190.4 176.163C193.906 174.125 198.508 173.132 203.111 173.132C207.714 173.132 212.317 174.152 215.822 176.163C218.9 177.934 220.586 180.214 220.586 182.521C220.586 184.855 218.9 187.108 215.822 188.878Z" fill="#00283B" />
        <path d="M207.634 176.432C205.118 174.983 201.051 174.983 198.535 176.432C196.02 177.88 196.02 180.241 198.535 181.689C201.051 183.138 205.118 183.138 207.634 181.689C210.149 180.241 210.149 177.88 207.634 176.432Z" fill="#F5FCFF" />
        <path d="M217.535 169.726C209.453 165.032 196.769 165.032 188.661 169.726C184.486 172.14 182.185 175.466 182.185 179.061C182.185 182.682 184.486 185.981 188.661 188.422C192.702 190.756 197.893 191.936 203.084 191.936C208.276 191.936 213.467 190.756 217.508 188.422C221.683 186.008 223.984 182.682 223.984 179.061C223.984 175.439 221.683 172.14 217.508 169.726H217.535ZM215.822 185.445C208.811 189.495 197.411 189.495 190.4 185.445C187.323 183.675 185.637 181.394 185.637 179.088C185.637 176.754 187.323 174.501 190.4 172.73C193.906 170.691 198.508 169.699 203.111 169.699C207.714 169.699 212.317 170.718 215.822 172.73C218.9 174.501 220.586 176.781 220.586 179.088C220.586 181.421 218.9 183.675 215.822 185.445Z" fill="#F5FCFF" />
        <path d="M200.381 74.0698C186.814 80.3199 174.665 99.2847 173.514 116.828C173.059 123.829 174.424 129.516 177.18 133.379L196.715 170.504L206.081 175.091C206.911 173.186 220.585 107.976 221.201 106.018C222.673 101.431 232.654 98.5605 232.734 94.0808C233.002 77.7447 213.601 67.9806 200.381 74.0698ZM198.669 115.889C193.344 118.572 189.249 115.272 189.57 108.62C189.892 102.048 194.414 94.7514 199.632 92.2567C204.797 89.7889 208.784 92.9274 208.57 99.3384C208.356 105.83 203.941 113.26 198.669 115.916V115.889Z" fill="#B0E2FA" />
        <path d="M202.067 77.4229C188.5 83.673 176.351 102.638 175.2 120.181C174.986 123.507 175.2 126.512 175.762 129.194C191.23 138.529 202.817 137.966 214.912 146.228C218.685 128.711 222.566 110.39 222.887 109.344C224.358 104.757 231.851 101.323 231.905 96.8706C232.172 80.5345 215.26 71.3069 202.041 77.4229H202.067ZM200.355 119.242C195.029 121.925 190.935 118.625 191.256 111.973C191.577 105.401 196.1 98.1045 201.318 95.6099C206.483 93.142 210.47 96.2805 210.256 102.692C210.042 109.183 205.626 116.613 200.355 119.269V119.242Z" fill="#F5FCFF" />
        <path d="M206.59 176.485L195.966 171.282L195.806 170.986L176.271 133.861C173.354 129.784 172.016 123.829 172.498 116.774C173.648 99.1774 185.985 79.6224 199.953 73.1577C206.991 69.9119 216.197 70.8508 223.422 75.5719C230.139 79.9711 233.912 86.7308 233.778 94.1076C233.725 96.9778 230.647 99.0969 227.65 101.162C225.242 102.799 222.78 104.515 222.191 106.34C221.95 107.117 219.408 119.027 216.705 131.608C211.353 156.582 207.527 174.366 207.018 175.52L206.59 176.512V176.485ZM197.465 169.726L205.412 173.615C206.67 168.545 211.246 147.193 214.671 131.179C217.936 115.916 219.97 106.5 220.211 105.696C221.014 103.174 223.797 101.27 226.473 99.4456C229.015 97.702 231.664 95.878 231.691 94.0807C231.798 87.4015 228.373 81.2855 222.271 77.2886C215.635 72.9431 207.205 72.0579 200.81 75.0086C187.43 81.1782 175.655 99.9821 174.531 116.882C174.103 123.454 175.307 128.953 178.01 132.762L197.438 169.699L197.465 169.726ZM195.137 117.874C193.932 117.874 192.808 117.552 191.818 116.908C189.544 115.433 188.366 112.402 188.554 108.593C188.901 101.618 193.585 94.0271 199.204 91.3446C201.853 90.0839 204.396 90.0839 206.349 91.3446C208.543 92.7932 209.748 95.6902 209.614 99.3652C209.4 106.205 204.69 114.038 199.151 116.828C197.759 117.525 196.421 117.874 195.137 117.874ZM203.004 92.4713C202.094 92.4713 201.131 92.7127 200.087 93.1955C195.163 95.5561 190.909 102.504 190.614 108.7C190.454 111.758 191.31 114.119 192.942 115.192C194.307 116.077 196.18 116.023 198.214 115.004C203.165 112.536 207.339 105.481 207.553 99.3383C207.634 96.4145 206.804 94.1612 205.198 93.1151C204.556 92.6859 203.807 92.4981 202.977 92.4981L203.004 92.4713Z" fill="#00283B" />
        <path d="M232.948 96.8705C233.216 80.5344 222.967 72.5408 209.747 78.6567C196.18 84.9068 184.031 103.872 182.88 121.415C182.425 128.416 183.79 134.103 186.546 137.966L206.081 175.091L228.372 116.426C229.229 114.521 229.951 112.563 230.594 110.605C232.065 106.018 232.895 101.35 232.975 96.8974L232.948 96.8705Z" fill="#00283B" />
        <path d="M206.59 176.485L205.332 175.868L185.637 138.422C182.72 134.344 181.382 128.389 181.864 121.334C183.014 103.737 195.351 84.1825 209.319 77.7178C215.581 74.8207 221.522 74.8744 226.071 77.8519C231.316 81.2854 234.126 88.0452 233.965 96.8972C233.885 101.377 233.082 106.071 231.53 110.9C230.888 112.885 230.139 114.87 229.282 116.828L206.563 176.485H206.59ZM218.204 77.6641C215.688 77.6641 212.986 78.3079 210.176 79.5955C196.796 85.7651 185.021 104.569 183.897 121.468C183.469 128.04 184.673 133.539 187.376 137.349L205.921 172.569L227.382 116.023C228.239 114.092 228.961 112.16 229.577 110.256C231.049 105.642 231.851 101.109 231.905 96.8436C232.039 88.8499 229.496 82.5462 224.921 79.5686C222.967 78.2811 220.666 77.6373 218.177 77.6373L218.204 77.6641Z" fill="#00283B" />
        <path d="M206.858 133.244L196.93 126.458C195.538 125.519 195.645 122.729 197.144 120.235C198.669 117.74 201.024 116.506 202.415 117.445L207.419 120.852L216.678 102.021C217.99 99.4188 220.345 97.7825 221.923 98.3726C223.529 98.9628 223.77 101.538 222.459 104.14L208.597 132.171C208.142 133.057 207.339 133.566 206.858 133.244Z" fill="#B0E2FA" />
        <path d="M158.663 32.6528L104.982 1.59009L51.3279 32.6528V94.7783L104.982 125.841V63.8765L158.663 32.6528Z" fill="#F5FCFF" />
        <path d="M51.3279 94.5637L104.982 125.626V63.6888L51.3279 32.4651V94.5637Z" fill="#B0E2FA" />
        <path d="M159.733 32.0359L104.982 0.356201L50.3913 31.9554L50.2307 32.0627V33.2966V95.4221L103.885 126.485L104.955 127.102L106.025 126.485L159.144 95.744L159.679 95.4221V33.2698V32.0359H159.733ZM104.982 2.85088L130.19 17.4434L78.6767 47.2454L53.4419 32.6528L104.982 2.85088ZM103.911 123.963L52.3983 94.1613V34.5306L103.911 64.3325V123.963ZM126.818 49.8205L104.982 62.4548L79.7471 47.8623L131.26 18.0603L156.468 32.6528L126.791 49.8205H126.818Z" fill="#00283B" />
        <path d="M83.6807 63.7959L73.7259 58.0287C72.6288 57.3849 71.5049 57.3313 70.595 57.8677C69.5514 58.4579 68.9626 59.7455 68.9626 61.3549C68.9626 64.0642 70.595 67.1222 72.6555 68.3293L82.6103 74.0965C83.2258 74.4453 83.8145 74.633 84.4032 74.633C84.8849 74.633 85.3398 74.4989 85.7412 74.2843C86.7849 73.6942 87.3736 72.4066 87.3736 70.7971C87.3736 68.0879 85.7412 65.0299 83.6807 63.8228V63.7959ZM84.6708 72.4066C84.3765 72.5675 83.9215 72.3798 83.6807 72.2456L73.7259 66.4784C72.3077 65.6468 71.1035 63.3131 71.1035 61.3817C71.1035 60.4161 71.3978 59.9064 71.6654 59.7723C71.9598 59.5845 72.4147 59.7991 72.6555 59.9332L73.3513 60.3356C73.5921 62.6693 74.9837 65.1103 76.7231 66.1297L85.2328 71.0386C85.1793 71.8433 84.9384 72.2993 84.6976 72.4334L84.6708 72.4066Z" fill="#00283B" />
    </SvgIcon>
)



export default BoxSuccess