import React, { useEffect } from 'react';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Stack,
    Divider,
    Grid,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import CloseIcon from '@mui/icons-material/Close';

import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import validatorContact from "./validatorContact"

import { postContact } from "../../../store/masters/thunk/contact/post"
import { putContact } from "../../../store/masters/thunk/contact/put"

const ContactModal = ({
    __,
    open,
    close,
    selected,
    setSelected,
    idClient,
    reload,
    setError
}) => {
    const idContact = get(selected, "idContact")
    const dispatch = useDispatch();

    // const current = useSelector(state => state.masters.client.detail);
    const current = selected
    const put = useSelector(state => state.masters.contact.put);
    const post = useSelector(state => state.masters.contact.post);

    const onCloseModal = (r) => {
        setSelected({})
        close()
        if (r === "1") {
            reload()
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { onCloseModal("1") }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(put, "isSuccess", false)) { onCloseModal("1") }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    }, [post, put])

    const onSubmit = (values) => {
        const body = {
            ...values,
            phone: get(values, "phone"),
            email: get(values, "email"),
            idClient: Number(get(values, "idClient")),
            isActive: get(values, "isActive"),
            name: get(values, "name"),
        }
        const whitOutNulls = pickBy(body, identity);
        if (idContact) { dispatch(putContact(whitOutNulls)) }
        else { dispatch(postContact(whitOutNulls)) }
    }

    const initialValues = {
        isActive: idContact ? get(current, "isActive", true) : true,
        name: idContact ? get(current, "name", "") : "",
        phone: idContact ? get(current, "phone", "") : "",
        email: idContact ? get(current, "email", "") : "",
        idContact: idContact ? get(current, "idContact", 0) : 0,
        idClient,
    }

    return (
        <div>
            <Dialog onClose={onCloseModal} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {idContact ? __("head.edit.name") : __("head.new.name")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div>
                    {!open
                        ? (
                            <Box sx={{ minHeight: 200, width: "100%" }} />
                        ) : (
                            <Formik initialValues={initialValues}
                                validationSchema={validatorContact(__)}
                                onSubmit={onSubmit}>
                                {formik => (
                                    <form id="client-contact-form" onSubmit={get(formik, "handleSubmit")}>
                                        <div className='relative'>
                                            <Divider />
                                            <Box sx={{ position: "absolute", top: 10, right: 20, zIndex: 10 }} >
                                                <Switch formik={formik} name="isActive" __={__} />
                                            </Box>
                                            <Box className='p-4' >
                                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="name"
                                                            label={__(`form.name.label`)}
                                                            placeholder={__(`form.name.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="phone"
                                                            label={__(`form.phone.label`)}
                                                            placeholder={__(`form.phone.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="email"
                                                            label={__(`form.email.label`)}
                                                            placeholder={__(`form.email.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        )}
                </div>
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton variant="contained" color="secondary" type="submit" form="client-contact-form" loading={get(post, "isLoading") || get(put, "isLoading")}>{__("action.save")}</LoadingButton>
                    <Button variant='outlined' color="secondary" onClick={onCloseModal} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div>
    );
}

export default ContactModal