import React from 'react';
import { useTranslation } from "react-i18next";
import {
    Dialog,
    Box,
    SvgIcon,
    Typography,
    Button,
    IconButton
} from '@mui/material';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';


const Alert = ({
    title,
    subtitle,
    btn1 = { func: () => { } },
    btn2 = { func: () => { } },
    type,
    openAlert,
    closeAlert,
    loading
}) => {
    const [__] = useTranslation("global");

    return (
        <div>
            <Dialog onClose={closeAlert} open={openAlert} maxWidth="xs" fullWidth>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlert}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className='flex flex-col justify-center m-10'>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                        {type === "default" ? __("error.title") : title}
                    </Typography>
                    <Typography variant="bml" >
                        {type === "default" ? __("error.subtitle") : subtitle}
                    </Typography>
                    {!isEmpty(btn1) &&
                        <LoadingButton autoFocus loading={loading} variant="contained" color="primary" sx={{ mt: 5 }} onClick={get(btn1, "func")}>
                            {__("btn.close")}
                        </LoadingButton>
                    }
                    {!isEmpty(btn2) &&
                        <Button className='w-[100px] min-w-[100px]' variant="text" color="secondary" sx={{ mt: 1 }} onClick={get(btn2, "func")}>
                            {__("btn.accept")}
                        </Button>
                    }
                </Box>
            </Dialog>
        </div >
    );
}

export default Alert