/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, isEmpty, find } from "lodash";

import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Link as LinkMui,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom"

import Layout from "../../../components/layout/Layout"
import Table from "../../../components/form/Table";
import Toolbar from "./Toolbar";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import { PageSize, countPage } from "../../../config/const"
import Question from "../../../components/form/Question";

import { getTransport } from "../../../store/masters/thunk/transport/getAll";
import { putTransport } from "../../../store/masters/thunk/transport/put";
import { deleteTransport } from "../../../store/masters/thunk/transport/delete";

const Transport = () => {
  const [__] = useTranslation("transport");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
  const [openQuestion, setOpenQuestion] = useState(null);

  const transport = useSelector(state => state.masters.transport);
  const put = useSelector(state => state.masters.transport.put);
  const delet = useSelector(state => state.masters.transport.delete);

  const transportFuelType = __(`transportFuelType`, { returnObjects: true });
  const transportDivision = __(`transportDivision`, { returnObjects: true });


  const getData = ({ page, filter }) => {
    dispatch(getTransport({ page, PageSize, Filters: filter }))
  }

  const reload = () => {
    getData({ page: 1, filter })
    closeAlert()
    handleCloseMenu()
  }

  useEffect(() => {
    setAnchorEl(null)
    getData({ page: 1, filter })
  }, [dispatch, JSON.stringify(filter)])


  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  const onDelete = () => {
    dispatch(deleteTransport({ id: get(selected, "idTransport") }))
    setSelected({})
  }
  const handleDelete = (row) => () => {
    setAnchorEl(null)
    setOpenQuestion(true)
    setSelected(row)
  }

  const handleDisabled = (values) => () => {
    dispatch(putTransport({ ...values, isActive: false }))
  }

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    setOpenQuestion(false)

  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { handleCloseMenu(); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delet" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delet") }
    if (get(put, "isSuccess", false)) { handleCloseMenu(); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
  }, [put, delet])

  const headTable = [
    {
      key: "code",
      label: __(`table.cols.numberPlate`),
      align: "left"
    },
    {
      key: "name",
      label: __(`table.cols.name`),
      align: "left",
      sx: { minWidth: 100 }
    },
    {
      key: "status",
      label: __(`table.cols.isActive`),
      align: "center"
    },
    {
      key: "vin",
      label: __(`table.cols.vin`),
      align: "center"
    },
    {
      key: "cost",
      label: __(`table.cols.dailyCost`),
      align: "center"
    },
    {
      key: "volumetry",
      label: __(`table.cols.volumetry`),
      align: "center"
    },
    {
      key: "kiloXGallon",
      label: __(`table.cols.kiloXGallon`),
      align: "center"
    },
    {
      key: "transportBrand",
      label: __(`table.cols.transportBrand`),
      align: "center"
    },
    {
      key: "transportDivision",
      label: __(`table.cols.idTransportDivision`),
      align: "center"
    },
    {
      key: "transportFuelType",
      label: __(`table.cols.idTransportFuelType`),
      align: "center"
    },
    {
      key: "options",
      label: __(`table.cols.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(transport, "data", []), (row, i) => ({
    ...row,
    code: <LinkMui component={Link} to={toString(get(row, "idTransport"))} >{get(row, "numberPlate")}</LinkMui>,
    status: <Chip label={__(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
    cost: `$${get(row, "dailyCost")}`,
    transportDivision: get(find(transportDivision, ({ value }) => value === get(row, "idTransportDivision", "")), "label"),
    transportFuelType: get(find(transportFuelType, ({ value }) => value === get(row, "idTransportFuelType", "")), "label"),
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <Layout
      propsToolbar={{
        title: __(`head.name`),
        code: null,
        btnLabel: null
      }}
    >
      <Toolbar setFilter={setFilter} __={__} getData={getData} reload={reload} isLoading={get(transport, "isLoading", false)} />
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(transport, "isLoading", false)}
      />
      {get(transport, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignItems="flex-end">
          <Pagination
            count={countPage(get(transport, "totalRecords", 1))}
            page={get(transport, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      {openMenu &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'center' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
        >
          <Link to={toString(get(selected, "idTransport"))}><MenuItem >{__(`table.menu.edit`)}</MenuItem></Link>
          <MenuItem onClick={handleDelete(selected)}>{__(`table.menu.delete`)}</MenuItem>
        </Menu>
      }
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <Question
        title={__("alert.delete.title")}
        detail={__("alert.delete.detail")}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(delet, "isLoading")}
      />
    </Layout >
  )
}

export default Transport;


