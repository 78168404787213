import {
    getTrackingRouteLoading,
    getTrackingRouteReject,
    getTrackingRouteRequest,
    getTrackingRouteSuccess
} from "../../actions/route/trackingRoute";

export const getTrackingRoute = (formData) => async (dispatch, getState) => {
    dispatch(getTrackingRouteLoading());
    try {
        const { data } = await getTrackingRouteRequest(formData, getState);
        dispatch(getTrackingRouteSuccess(data))
    } catch (error) {
        dispatch(getTrackingRouteReject(error))
    }
    return Promise.resolve();
};