import {
    getReportOrdersLoading,
    getReportOrdersReject,
    getReportOrdersRequest,
    getReportOrdersSuccess
} from "../../actions/orders/getAll";

export const getReportOrders = (formData) => async (dispatch, getState) => {
    dispatch(getReportOrdersLoading());
    try {
        const { data } = await getReportOrdersRequest(formData, getState);
        dispatch(getReportOrdersSuccess(data))
    } catch (error) {
        dispatch(getReportOrdersReject(error))
    }
    return Promise.resolve();
};