import Cost from "../../../../pages/Transactions/Cost";
import CostNewEdit from "../../../../pages/Transactions/Cost/NewEdit";

const routerCost = [
    {
        key: "cost",
        path: "/config/cost",
        element: <Cost />,
    },
    {
        key: "cost",
        path: "/config/cost/new",
        element: <CostNewEdit />,
    },
    {
        key: "cost",
        path: "/config/cost/:id",
        element: <CostNewEdit />,
    },
]

export default routerCost
