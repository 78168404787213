/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { Formik } from 'formik';
import moment from "moment";

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import compact from "lodash/compact";

import InputText from "../../../components/form/InputText";
import validator from "./validator"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import Map from "../../../components/map/MapMany2"
import Graf from "./Graf"
import RoutesModal from "./RoutesModal"

import { getRouteId } from "../../../store/transactions/thunk/route/getId"
import { postRoute } from "../../../store/transactions/thunk/route/post"
import { putRoute } from "../../../store/transactions/thunk/route/put"
import { PageSize } from "../../../config/const"

import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";
import { getTransport } from "../../../store/masters/thunk/transport/getAll";
import { getDriver } from "../../../store/masters/thunk/driver/getAll";
import { getRouteAllCoordsRoute } from "../../../store/transactions/thunk/route/getAllCoordsRoute";

const NewEdit = ({ setBtns }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("route");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [km, setKm] = useState(null)
    const [routes, setRoutes] = useState([])
    const [showMap, setShowMap] = useState(false);
    const [mapLoad, setMapLoad] = useState(false);

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.route.detail);
    const put = useSelector(state => state.transactions.route.put);
    const post = useSelector(state => state.transactions.route.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const transport = useSelector(state => state.masters.transport);
    const driver = useSelector(state => state.masters.driver);
    const coordsAllRoute = useSelector(state => state.transactions.route.coordsAllRoute);

    const routeStatus = __(`routeStatus`, { returnObjects: true });
    const transports = map(transport?.data, ({ idTransport, numberPlate, name }) => ({ value: idTransport, label: `${numberPlate} | ${name}` }));
    const drivers = map(driver?.data, ({ idDriver, user, license }) => ({ value: idDriver, label: `${license} | ${user.name}` }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}` }));
    // const orders = useSelector(state => state.transactions.route.orderAll);

    const isActive = "isActive==true";

    const getDataDriver = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `user.name@=${search},${isActive}`
        dispatch(getDriver(params))
    }
    const getDataTransport = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getTransport(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `deliveryName@=${search},${isActive}`
        dispatch(getDeliveryPoint(params))
    }

    useEffect(() => {
        if (!!id) {
            dispatch(getRouteId({ id }))
            dispatch(getRouteAllCoordsRoute({ id }))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (!get(current, "isLoading") && get(current, "isSuccess")) {
            getDataDriver()
            getDataTransport()
            getDataDelivery()
        }
    }, [get(current, "isLoading"), get(current, "isSuccess")])

    const getMapRoutes = async () => {
        setMapLoad(true)
        const rutas = await map(get(coordsAllRoute, "data", []), (item) => ({
            location: {
                id: item.order + 1,
                lat: Number(item.latitude),
                lng: Number(item.longitude)
            }
        }))
        setRoutes(rutas)
        setMapLoad(false)
    }

    useEffect(() => {
        if (get(coordsAllRoute, "isSuccess")) {
            getMapRoutes()
        }
    }, [get(coordsAllRoute, "isSuccess")])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item, i) => <li key={i}>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    const submitPut = (values) => {
        const body = {
            ...values,
        }
        dispatch(putRoute(body))
    }

    const onSubmit = (values) => {
        const idRoute = id;
        if (idRoute) { submitPut(values) }
        else { dispatch(postRoute(values)) }
    }

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                {get(current, "data.idRouteStatus", 1) >= 4 &&
                    <Button className='w-[100px] min-w-[100px]' variant='text' color="secondary" onClick={() => setShowMap(true)} disabled={get(put, "isLoading")} ><div className='whitespace-nowrap'>{__(`action.showMap`)}</div></Button>
                }
                <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/transactions/routes")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [get(current, "data.idRouteStatus", 1)])

    const status = find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1))

    const initialValues = {
        isActive: get(current, "data.isActive", ""),
        routeName: get(current, "data.routeName", ""),
        routeNumb: get(current, "data.routeNumb", ""),

        idRouteStatus: get(find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1)), "label"),
        routeStatus: get(find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1)), "label"),

        idDriver: get(find(drivers, ({ value }) => value === get(current, "data.idDriver", "")), "label"),
        idStartDPL: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idStartDP", 1)), "label"),
        idReturnDPL: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idReturnDP", "")), "label"),
        idStartDP: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idStartDP", 1)), "value"),
        idReturnDP: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idReturnDP", "")), "value"),
        idTransport: get(find(transports, ({ value }) => value === get(current, "data.idTransport", "")), "label"),

        creationDate: moment(get(current, "data.creationDate", "")).format("L"),
        // startDate: moment(get(current, "data.startDate", "")).format("L"),
        dateToRoute: moment(get(current, "data.dateToRoute", "")).format("L"),
        address: get(current, "data.address", ""),
        comments: get(current, "data.comments", ""),

        idUser: get(user, "idUser"),
    }

    return (
        <div>
            {get(current, "isLoading") || get(deliveryPoint, "isLoading") || get(transport, "isLoading") || get(driver, "isLoading")
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Paper className='mt-4' >
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                                <Typography component="h4" variant="h4">{`${__(`labels.1`)} - ${id}`}</Typography>
                                                <InputText
                                                    name={"routeStatus"}
                                                    formik={formik}
                                                    label=""
                                                    placeholder=""
                                                    disabled={true}
                                                    fullWidth={false}
                                                    sx={{ "fieldset": { borderColor: `${get(status, "color")} !important` }, "& .Mui-disabled": { "-webkit-text-fill-color": `${get(status, "color")} !important` } }}
                                                />
                                            </Stack>
                                            <Divider />
                                            <Box className='p-4' >
                                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                                    <Grid item xs={12}>
                                                        <InputText
                                                            formik={formik}
                                                            name="routeName"
                                                            label={__(`form.routeName.label`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputText
                                                            name="creationDate"
                                                            formik={formik}
                                                            label={__(`form.creationDate.label`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputText
                                                            formik={formik}
                                                            name="dateToRoute"
                                                            label={__(`form.dateToRoute.label`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputText
                                                            name={"idDriver"}
                                                            formik={formik}
                                                            label={__(`form.idDriver.label`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography component="h5" variant="h5" color="text.lite">{__(`labels.2`)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputText
                                                            name={"idStartDP"}
                                                            formik={formik}
                                                            label={__(`form.idStartDP.label`)}
                                                            value={get(formik, `values.idStartDPL`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputText
                                                            name={"idReturnDP"}
                                                            formik={formik}
                                                            label={__(`form.idReturnDP.label`)}
                                                            value={get(formik, `values.idReturnDPL`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography component="h5" variant="h5" color="text.lite">{__(`labels.3`)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <InputText
                                                            name={"idTransport"}
                                                            formik={formik}
                                                            label={__(`form.idTransport.label`)}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <InputText
                                                            formik={formik}
                                                            name="comments"
                                                            label={__(`form.comments.label`)}
                                                            multiline
                                                            rows={3}
                                                            onChange={() => { }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 500 }}>
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                {routes.length > 1
                                                    ? <Typography component="h4" variant="h4">{__(`labels.4`)}{km && ` (${get(km, "distance.text")})`}</Typography>
                                                    : <Typography component="h4" variant="h4">{__(`labels.4`)}{`0 km`}</Typography>
                                                }
                                            </Stack>
                                            <Map loading={mapLoad} routes={routes} setKm={setKm} __={__} />
                                        </Paper>
                                        <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 450 }}>
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                <Typography component="h4" variant="h4">{__(`labels.5`)}</Typography>
                                            </Stack>
                                            <Graf />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
            <RoutesModal
                __={__}
                open={showMap}
                close={() => setShowMap(false)}
            />
        </div>
    )
}

export default NewEdit