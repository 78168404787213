import { combineReducers } from "redux";

import order from "./order";
import route from "./route";
import quote from "./quote";
import cost from "./cost";
import invoice from "./invoice";

export default combineReducers({
  order,
  route,
  quote,
  cost,
  invoice,
});
