/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { get, map, pickBy, identity, isEmpty, find, filter } from "lodash";
import {
  IconButton,
  Stack,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneIcon from '@mui/icons-material/Phone';
import moment from "moment";
import ReplayIcon from '@mui/icons-material/Replay';
import InsertLinkSharpIcon from '@mui/icons-material/InsertLinkSharp';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import OrdersModal from "./OrdersModal"

import { getRouteOrderAll } from "../../../store/transactions/thunk/route/getOrderAll";
import { putRoute } from "../../../store/transactions/thunk/route/put"
import { putRouteOrder } from "../../../store/transactions/thunk/route/putOrder";
import { getOrderNoInRoute } from "../../../store/transactions/thunk/order/getNoInRoute";
import { putLiqOrderRoute } from "../../../store/transactions/thunk/route/putLiquidate"

const Orders = ({ setBtns, isEdit }) => {
  const [__] = useTranslation("route");
  const [__o] = useTranslation("order");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
  const [openModal, setOpenModal] = useState(false);
  const [ordersSelected, setOrdersSelected] = useState([])
  const [openQuestion, setOpenQuestion] = useState(null);
  const [openQuestionLiq, setOpenQuestionLiq] = useState(null);

  const current = useSelector(state => state.transactions.route.detail);
  const route = useSelector(state => state.transactions.route.orderAll);
  const put = useSelector(state => state.transactions.route.putOrder);
  const post = useSelector(state => state.transactions.route.postOrder);
  const putLiquidateOrder = useSelector(state => state.transactions.route.putLiquidateOrder);

  const orderOperativeStatus = __o(`orderOperativeStatus`, { returnObjects: true });

  const getData = async () => {
    await dispatch(getRouteOrderAll({ id }))
  }
  const getOrder = () => {
    dispatch(getOrderNoInRoute({}))
  }

  const reload = async () => {
    await getData()
    getOrder()
  }

  useEffect(() => {
    setAnchorEl(false)
    // getData()
  }, [])

  useEffect(() => {
    setOrdersSelected(map(get(route, "data", []), ({ idOrder }) => idOrder))
  }, [get(route, "data.length")])


  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        {isEdit ? <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.btn`)}</Button> : <div className='flex-1' />}
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [isEdit])

  const handleOpenMenu = (row) => (e) => {
    setOpenQuestion(true)
    setSelected(row)
  };
  const handleOpenMenuLiq = (row) => (e) => {
    setOpenQuestionLiq(true)
    setSelected(row)
  };

  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  const onDelete = () => {
    const newsOrders = filter(ordersSelected, (item) => item !== get(selected, "idOrder"));
    const body = {
      orders: map(newsOrders, (item) => ({ idOrder: item })),
      idRoute: id
    }
    dispatch(putRouteOrder(body))
  }

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    setOpenQuestion(false)
    setOpenQuestionLiq(false)
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(put, "isSuccess", false)) { setOpenModal(false); setOpenQuestion(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    if (get(post, "isSuccess", false)) { setOpenModal(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(post, "isReject", false)) { setError(get(post, "error", {}), "put") }
    if (get(putLiquidateOrder, "isSuccess", false)) { setOpenModal(false); setOpenQuestionLiq(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(putLiquidateOrder, "isReject", false)) { setError(get(putLiquidateOrder, "error", {}), "put") }
  }, [put, post, putLiquidateOrder])

  const liquidate = () => {
    const { idOrder, idRoute } = selected
    dispatch(putLiqOrderRoute({ idOrder, idRoute }))
  }

  const saveLink = ({ token }) => () => {
    const enlace = `${window.location.origin}/tracking/${token}`
    navigator.clipboard.writeText(enlace)
    setShowNoti({ open: true, msg: "", variant: "success", action: "clipboard" })
  }

  const headTable = [
    {
      key: "idOrder",
      label: __(`table.colsOrders.idOrder`),
      align: "left"
    },
    {
      key: "order.docNum",
      label: __(`table.colsOrders.docNum`),
      align: "left"
    },
    {
      key: "order.deliveryPoint.contactName",
      label: __(`table.colsOrders.contactoDelivery`),
      align: "left"
    },
    {
      key: "order.deliveryPoint.contactPhone",
      label: __(`table.colsOrders.phoneDelivery`),
      align: "left",
    },
    {
      key: "operativeStatus",
      label: __(`table.colsOrders.operativeStatus`),
      align: "left"
    },
    {
      key: "delivery",
      label: __(`table.colsOrders.deliveryPoint`),
      align: "left"
    },
    {
      ...(get(current, "data.idRouteStatus") === 7 ? {} :
        {
          key: "liq",
          label: __(`table.colsOrders.liquidate`),
          align: "center",
        }
      ),
    },
    {
      ...(!isEdit ? {} :
        {
          key: "delete",
          label: __(`table.colsOrders.options`),
          align: "center",
          sx: { position: "sticky", right: 85, pr: 1, width: 50, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
        }
      ),
    },
    {
      key: "token",
      label: __(`table.colsOrders.token`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, width: 80, background: "#FFFFFF" }
    },
  ]

  const dataTable = map(get(route, "data", []), (row, i) => ({
    ...row,
    create: get(row, "order.creationDate", null) && moment(get(row, "order.creationDate")).format("L hh:mm a"),
    rotueDate: get(row, "order.startDate", null) && moment(get(row, "order.startDate")).format("L hh:mm a"),
    operativeStatus: get(find(orderOperativeStatus, ({ value }) => value === get(row, "order.idOrderOperativeStatus")), "label"),
    delivery: `${get(row, "order.deliveryPoint.deliveryCode")} | ${get(row, "order.deliveryPoint.deliveryName")}`,
    telePhone: get(row, "order.deliveryPoint.contactPhone", "") && <Stack direction="row" alignItems="center" spacing={1} ><PhoneIcon fontSize="small" /><div>{get(row, "order.deliveryPoint.contactPhone")}</div></Stack>,
    liq: <Button color="primary" variant="outlined" size="small" sx={{ height: 25 }} onClick={handleOpenMenuLiq(row)}>{__("action.liquidate")}</Button>,
    token: !!(row.token) ? <IconButton aria-label="link" size="small" onClick={saveLink(row)} ><InsertLinkSharpIcon fontSize="inherit" /></IconButton> : null,
    delete: <IconButton aria-label="delete" size="small" onClick={handleOpenMenu(row)} ><DeleteIcon fontSize="inherit" /></IconButton>
  }))

  return (
    <div>

      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(route, "isLoading", false)}
        empty="orders"
      />
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <OrdersModal
        __={__}
        __o={__o}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        selected={ordersSelected}
        setSelected={setOrdersSelected}
      />
      <Question
        title={__("alert.deleteOrder")}
        detail={""}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(post, "isLoading") || get(put, "isLoading")}
      />
      <Question
        title={__("alert.liqOrder")}
        detail={""}
        onSuccess={liquidate}
        onCancel={() => setOpenQuestionLiq(false)}
        open={openQuestionLiq}
        loading={get(putLiquidateOrder, "isLoading")}
      />
    </div >
  )
}

export default Orders;
