import {
    deleteContactLoading,
    deleteContactRequest,
    deleteContactSuccess,
    deleteContactReject,
    deleteContactReset
} from "../../actions/contact/delete";

export const deleteContact = (formData) => async (dispatch, getState) => {
    dispatch(deleteContactLoading());
    try {
        const { data } = await deleteContactRequest(formData, getState);
        dispatch(deleteContactSuccess(data))
    } catch (error) {
        dispatch(deleteContactReject(error))
    } finally {
        setTimeout(() => { dispatch(deleteContactReset()) }, 3000);
    }
    return Promise.resolve();
};