/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';


import get from "lodash/get"
import toString from "lodash/toString"
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

// import InputText from "../form/InputText";
import { FormControl, Typography, TextField, Input } from '@mui/material';

/*
https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=es-419
*/

const Search = ({ __, setPositions, REACT_APP_GOOGLE_MAP_API_KEY }) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    });


    const onPlaceSelected = (place) => {
        try {
            const location = get(place, "geometry.location")
            setPositions({
                lat: location.lat(),
                lng: location.lng()
            })
        } catch (error) {

        }
    };

    const { ref: materialRef } = usePlacesWidget({
        apiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: onPlaceSelected,
        // inputAutocompleteValue: "country",
        options: {
            searchOptions: { types: ['locality', 'sublocality', 'country', 'postal_code', 'administrative_area_level_1', "administrative_area_level_2", "administrative_area_level_3"] },
            componentRestrictions: {
            },
            types: ['establishment']
        },
    });

    useEffect(() => {
        if (placePredictions.length)
            placesService?.getDetails(
                {
                    placeId: placePredictions[0].place_id,
                },
            );
    }, [placePredictions]);

    return (
        <div>
            <FormControl fullWidth >
                <Typography className='pb-2 pl-4' component="label" htmlFor="username" >
                    {__(`map.search`)}
                </Typography>
                <TextField
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    inputRef={materialRef}
                    size='small'
                />
            </FormControl>
            <br />
            <br />
        </div>
    )
}

export default Search