import React, { useState, useEffect } from 'react'
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Box } from "@mui/material"
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { connect } from 'react-redux'

import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Layout from "../../../components/layout/Layout";
import MapTracking from "../../../components/map/MapTracking";
import { getTrackingRoute } from "../../../store/transactions/thunk/route/trackingRoute";
import Detail from "./components/Detail"

import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';

const drawerBleeding = 100;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Tracking = (props) => {
  const [__] = useTranslation("global");
  const { id } = useParams();
  const { window, getRute, tracking } = props;

  const [open, setOpen] = React.useState(false);
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

  const getData = ({ page }) => {
    getRute({ token: id })
  }

  useEffect(() => {
    getData({ page: 1 })
  }, [])

  useEffect(() => {
    if (get(tracking, "isReject", false)) {
      if (!get(tracking, "isLoading", false)) {
        setError(get(tracking, "error", {}))
      }
    } else {
      closeAlert()
    }
  }, [tracking])

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Layout propsToolbar={{ title: "Tracking" }} disableHead={true} disableLayout={true}>
      <div>
        {get(tracking, "isSuccess", false)
          ? (
            <div className='relative h-screen w-full'>
              <MapTracking
                coords={[
                  {
                    lat: Number(get(tracking, "data.deliveryPointLatitude")),
                    lng: Number(get(tracking, "data.deliveryPointLongitude")),
                  },
                  {
                    lat: Number(get(tracking, "data.lastLatitude")),
                    lng: Number(get(tracking, "data.lastLongitude")),
                  }
                ]}
                __={__}
              />
              <div className='absolute left-[10px] top-[50px] w-[300px] h-[80vh] hidden md:block'>
                <Detail tracking={tracking} />
              </div>
              <Box className='block md:hidden' >
                <Root>
                  <CssBaseline />
                  <Global
                    styles={{
                      '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(90vh - ${drawerBleeding}px)`,
                        overflow: 'visible',
                      },
                    }}
                  />
                  <SwipeableDrawer
                    container={container}
                    anchor="bottom"
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    <div className='block md:hidden'>
                      <StyledBox
                        sx={{
                          position: 'absolute',
                          top: -drawerBleeding,
                          borderTopLeftRadius: 8,
                          borderTopRightRadius: 8,
                          visibility: 'visible',
                          right: 0,
                          left: 0,
                        }}
                      >
                        <Puller />
                        <div className='p-6 border-b border-gray-300 h-[100px] py-4'>
                          <div className='flex flex-row justify-center items-center gap-4 h-[85px]'>
                            <img className='h-[37px] w-[46px] min-w-[46px]' src="/icp.png" />
                            <div className='flex flex-1 justify-center bg-gray-100 text-center rounded-md py-1 text-gray-400'>
                              ICP Logística
                            </div>
                          </div>
                        </div>
                      </StyledBox>
                      <StyledBox
                        sx={{
                          px: 2,
                          pb: 2,
                          height: '100%',
                          overflow: 'auto',
                        }}
                      >
                        <Detail tracking={tracking} />
                      </StyledBox>
                    </div>
                  </SwipeableDrawer>
                </Root>
              </Box>
            </div>
          ) : (
            <div></div>
          )
        }

      </div>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
    </Layout >
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    tracking: state.transactions.route.tracking
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRute: (token) => {
      dispatch(getTrackingRoute(token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracking)
