/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Paper,
    Grid,
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import { useSelector } from "react-redux";
import get from "lodash/get"
import includes from "lodash/includes"
import { useLocation } from 'react-router-dom';

import Layout from "../../../components/layout/Layout";
import Form from "./Form"
import FormDetail from "./FormDetail"
import Orders from "./Orders"
import { useEffect } from 'react';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </div>
    );
}

const Detail = () => {
    const [__] = useTranslation("invoice");
    const location = useLocation()

    const [value, setValue] = React.useState(0);
    const [btns, setBtns] = useState(<></>)
    const [isEdit, setIsEdit] = useState(!includes(get(location, "pathname"), "/detail/"));

    const current = useSelector(state => state.transactions.invoice.detail);

    useEffect(() => {
        if (get(current, "isSuccess")) {
            if (get(current, "data.idInvoiceStatus", 1) === 2) {
                setIsEdit(false)
            }
        }
    }, [get(current, "data.idInvoiceStatus")])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout
             propsToolbar={{
                title: isEdit ? __(`head.edit.name`) : __(`head.detail.name`),
                srute: isEdit ? __(`head.edit.code`) : __(`head.detail.code`),
            }}
        >
            <Box display="block">
                <Box className="ml-4 mr-0">
                    <Grid className="py-2" container component={Paper} spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label={__(`tabs.invoice`)} disabled={get(current, "isLoading")} />
                                <Tab label={__(`tabs.orders`)} disabled={get(current, "isLoading")} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {btns}
                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value={value} index={0}>
                    {isEdit ? <Form setBtns={setBtns} __={__} isEdit={isEdit} /> : <FormDetail setBtns={setBtns} __={__} isEdit={false} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Orders isEdit={isEdit} setBtns={setBtns} __={__} />
                </TabPanel>
            </Box>
        </Layout >
    )
}

export default Detail