import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_DRIVER_LOADING = 'GET_DRIVER_LOADING';
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS';
export const GET_DRIVER_REJECT = 'GET_DRIVER_REJECT';

//* ACTIONS ------------------------------------------------
export const getDriverLoading = () => ({ type: GET_DRIVER_LOADING });
export const getDriverSuccess = (payload) => ({ type: GET_DRIVER_SUCCESS, payload });
export const getDriverReject = (payload) => ({ type: GET_DRIVER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getDriverRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Driver`, options);
};

