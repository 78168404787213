import {
    getOperatorsIdLoading,
    getOperatorsIdRequest,
    getOperatorsIdSuccess,
    getOperatorsIdReject,
} from "../../actions/operators/getId";

export const getOperatorsId = (formData) => async (dispatch, getState) => {
    dispatch(getOperatorsIdLoading());
    try {
        const { data } = await getOperatorsIdRequest(formData, getState);
        dispatch(getOperatorsIdSuccess(data))
    } catch (error) {
        dispatch(getOperatorsIdReject(error))
    }
    return Promise.resolve();
};