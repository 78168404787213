import {
    getTransportIdLoading,
    getTransportIdRequest,
    getTransportIdSuccess,
    getTransportIdReject,
} from "../../actions/transport/getId";

export const getTransportId = (formData) => async (dispatch, getState) => {
    dispatch(getTransportIdLoading());
    try {
        const { data } = await getTransportIdRequest(formData, getState);
        dispatch(getTransportIdSuccess(data))
    } catch (error) {
        dispatch(getTransportIdReject(error))
    }
    return Promise.resolve();
};