import Clients from "../../../../pages/Masters/Clients";
import ClientsNewEdit from "../../../../pages/Masters/Clients/NewEdit";
import Detail from "../../../../pages/Masters/Clients/Detail";

const routerClients = [
    {
        key: "clients",
        path: "/masters/clients",
        element: <Clients />,
    },
    {
        key: "clients",
        path: "/masters/clients/new",
        element: <ClientsNewEdit />,
    },
    {
        key: "clients",
        path: "/masters/clients/:id",
        element: <Detail />,
    },
]

export default routerClients
