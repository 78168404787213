import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_QUOTE_RESET = 'PUT_QUOTE_RESET';
export const PUT_QUOTE_LOADING = 'PUT_QUOTE_LOADING';
export const PUT_QUOTE_SUCCESS = 'PUT_QUOTE_SUCCESS';
export const PUT_QUOTE_REJECT = 'PUT_QUOTE_REJECT';

//* ACTIONS ------------------------------------------------
export const putQuoteReset = () => ({ type: PUT_QUOTE_RESET });
export const putQuoteLoading = () => ({ type: PUT_QUOTE_LOADING });
export const putQuoteSuccess = (payload) => ({ type: PUT_QUOTE_SUCCESS, payload });
export const putQuoteReject = (payload) => ({ type: PUT_QUOTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putQuoteRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Quote`, options);
};


