import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_TRANSPORT_SUPPLIER_ID_LOADING = 'GET_TRANSPORT_SUPPLIER_ID_LOADING';
export const GET_TRANSPORT_SUPPLIER_ID_SUCCESS = 'GET_TRANSPORT_SUPPLIER_ID_SUCCESS';
export const GET_TRANSPORT_SUPPLIER_ID_REJECT = 'GET_TRANSPORT_SUPPLIER_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getTransportSupplierIdLoading = () => ({ type: GET_TRANSPORT_SUPPLIER_ID_LOADING });
export const getTransportSupplierIdSuccess = (payload) => ({ type: GET_TRANSPORT_SUPPLIER_ID_SUCCESS, payload });
export const getTransportSupplierIdReject = (payload) => ({ type: GET_TRANSPORT_SUPPLIER_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getTransportSupplierIdRequest = async ({ params = {}, id }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TransportSuppliers/${id}`, options);
};