import * as yup from 'yup';

const validators = (__, id) => () => {
    return (
        yup.object({
            license: yup.string().required(__(`form.license.error`)),
            name: yup.string().required(__(`form.name.error`)),
            phone: yup.string().required(__(`form.phone.error`)),
            email: yup.string().email(__(`form.email.error2`)).required(__(`form.email.error`)),
            pass: !id && yup.string()
            .matches(/^(?=.*[a-z])/, __(`changePass.errors.1`))
            .matches(/^(?=.*[A-Z])/, __(`changePass.errors.2`))
            .matches(/^(?=.*[0-9])/, __(`changePass.errors.3`))
            .min(8, __(`changePass.errors.4`))
            .required(__(`changePass.pass.error`)),
        })
    )
}

export default validators
