import {
    getCostLoading,
    getCostReject,
    getCostRequest,
    getCostSuccess
} from "../../actions/cost/getAll";

export const getCost = (formData) => async (dispatch, getState) => {
    dispatch(getCostLoading());
    try {
        const { data } = await getCostRequest(formData, getState);
        dispatch(getCostSuccess(data))
    } catch (error) {
        dispatch(getCostReject(error))
    }
    return Promise.resolve();
};