import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_DELIVERY_POINT_LOADING = 'GET_DELIVERY_POINT_LOADING';
export const GET_DELIVERY_POINT_SUCCESS = 'GET_DELIVERY_POINT_SUCCESS';
export const GET_DELIVERY_POINT_REJECT = 'GET_DELIVERY_POINT_REJECT';

//* ACTIONS ------------------------------------------------
export const getDeliveryPointLoading = () => ({ type: GET_DELIVERY_POINT_LOADING });
export const getDeliveryPointSuccess = (payload) => ({ type: GET_DELIVERY_POINT_SUCCESS, payload });
export const getDeliveryPointReject = (payload) => ({ type: GET_DELIVERY_POINT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getDeliveryPointRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/DeliveryPoints`, options);
};

