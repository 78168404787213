/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, pickBy, identity, isEmpty } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Link as LinkMui,
  CircularProgress,
  Box,
  Fade,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from "react-router-dom";
import moment from "moment";

import Layout from "../../../components/layout/Layout"
import Table from "../../../components/form/Table";
import Toolbar from "./Toolbar";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";

import { getOrder } from "../../../store/transactions/thunk/order/getAll";
// import { deleteOrder } from "../../../store/transactions/thunk/order/delete"
import { putOrder } from "../../../store/transactions/thunk/order/put"

const Order = () => {
  const [__] = useTranslation("order");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

  // const order = useSelector(state => state.transactions.order);
  const order = [];
  const put = useSelector(state => state.transactions.order.put);
  const delet = useSelector(state => state.transactions.order.delete);

  const getData = ({ page, filter }) => {
    // const filters = { page, ...pickBy(filter, identity) }
    // switch (get(filter, "isActive")) {
    //   case 1: filters.isActive = true; break;
    //   case 2: filters.isActive = false; break;
    //   default: break;
    // }
    // dispatch(getOrder(filters))
  }

  const reload = () => getData({ page: 1, filter })

  useEffect(() => {
    setAnchorEl(false)
    getData({ page: 1, filter })
  }, [dispatch, JSON.stringify(filter)])


  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  // const handleDelete = (id) => () => {
  //   dispatch(deleteOrder({ id }))
  // }
  const handleDisabled = (values) => () => {
    dispatch(putOrder({ ...values, isActive: false }))
  }

  //  --------- Delete -------------

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
    })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delet" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delet") }
    if (get(put, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
  }, [put, delet])

  const headTable = [
    {
      key: "date",
      label: __(`table.colsHistory.date`),
      align: "center"
    },
    {
      key: "gps",
      label: __(`table.colsHistory.gps`),
      align: "center",
    },
    {
      key: "pitcs",
      label: __(`table.colsHistory.pitcs`),
      align: "center",
    },
    {
      key: "status",
      label: __(`table.colsHistory.status`),
      align: "center"
    },
    {
      key: "user",
      label: __(`table.colsHistory.user`),
      align: "center"
    },
    
    {
      key: "options",
      label: __(`table.cols.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(order, "data", []), (row, i) => ({
    ...row,
    status: <Chip label={__(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <div>
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(order, "isLoading", false)}
        empty="history"
      />
      <Stack sx={{ mt: 2 }} alignItems="flex-end">
        <Pagination
          count={get({}, "totalPage", 1)}
          page={get({}, "currentPage", 1)}
          onChange={onChangePagination}
          color="secondary"
          sx={{ "& li > button": { borderRadius: 1 } }}
        />
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <Link to={toString(get(selected, "idOrder"))}><MenuItem >{__(`table.menu.edit`)}</MenuItem></Link>
        <MenuItem onClick={handleDisabled(selected)} disabled={get(put, "isLoading")} >
          <Box height={28} position="relative">
            <Fade in={get(put, "isLoading")}>
              <Box position="absolute" width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="primary" size={20} />
              </Box>
            </Fade>
            <Typography className='text-center w-full ' variant="bms" color={get(put, "isLoading") ? "text.slite" : "text.main"} >{__(`table.menu.deactivate`)}</Typography>
          </Box >
        </MenuItem>
        {/* <MenuItem onClick={handleDelete(get(selected, "idOrder"))}>{__(`table.menu.delete`)}</MenuItem> */}
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
    </div>
  )
}

export default Order;


