import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_INVOICE_RESET = 'POST_INVOICE_RESET';
export const POST_INVOICE_LOADING = 'POST_INVOICE_LOADING';
export const POST_INVOICE_SUCCESS = 'POST_INVOICE_SUCCESS';
export const POST_INVOICE_REJECT = 'POST_INVOICE_REJECT';

//* ACTIONS ------------------------------------------------
export const postInvoiceReset = () => ({ type: POST_INVOICE_RESET });
export const postInvoiceLoading = () => ({ type: POST_INVOICE_LOADING });
export const postInvoiceSuccess = (payload) => ({ type: POST_INVOICE_SUCCESS, payload });
export const postInvoiceReject = (payload) => ({ type: POST_INVOICE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postInvoiceRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Invoices`, options);
};


