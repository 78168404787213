import {
    postDeliveryPointLoading,
    postDeliveryPointRequest,
    postDeliveryPointSuccess,
    postDeliveryPointReject,
    postDeliveryPointReset
} from "../../actions/deliveryPoints/post";

export const postDeliveryPoint = (formData) => async (dispatch, getState) => {
    dispatch(postDeliveryPointLoading());
    try {
        const { data } = await postDeliveryPointRequest(formData, getState);
        dispatch(postDeliveryPointSuccess(data))
    } catch (error) {
        dispatch(postDeliveryPointReject(error))
    } finally {
        setTimeout(() => { dispatch(postDeliveryPointReset()) }, 3000);
    }
    return Promise.resolve();
};