import React, { useEffect, useState } from 'react';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Stack,
    OutlinedInput,
    Paper,
    Divider,
    Grid,
    MenuItem,
    Chip,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import filter from "lodash/filter";
import includes from "lodash/includes";
import trim from "lodash/trim";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Table from "../../../components/form/Table";
import InputText from "../../../components/form/InputText";
import CloseIcon from '@mui/icons-material/Close';

import { getClientItem } from "../../../store/masters/thunk/client/getItem";
import { getOrderItem } from "../../../store/transactions/thunk/order/getItem"
import { postOrderItem } from "../../../store/transactions/thunk/order/postItem"
import { putOrderItem } from "../../../store/transactions/thunk/order/putItem"

const ItemModal = ({
    __,
    open,
    close,
    selected,
    setSelected,
    reload,
    idOrder,
    setShowNoti,
    setError
}) => {
    const [__c] = useTranslation("client");

    const dispatch = useDispatch();
    const { id } = useParams();

    const [searchValue, setSearchValue] = useState("");
    const [quantities, setQuantities] = useState([])

    const current = useSelector(state => state.transactions.order.detail);
    const order = useSelector(state => state.transactions.order.getItem);
    const item = useSelector(state => state.masters.client.item);
    const put = useSelector(state => state.transactions.order.putItem);
    const post = useSelector(state => state.transactions.order.postItem);

    const ItemsAll = get(item, "data", [])


    const getData = () => {
        const Filters = encodeURI(`idClient==${get(current, "data.idClient")}`)
        const parmas = { Filters }
        dispatch(getClientItem(parmas))
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = map(ItemsAll, (n) => n.idClientItem);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1
    }

    const handleChecked = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    useEffect(() => {
        if (get(post, "isSuccess", false)) { reload(); close(); setSelected([]); getData(); setShowNoti({ open: true, msg: "", variant: "success", action: "post" }) }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
    }, [post])



    const headTable = [
        {
            key: "checkbox",
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < ItemsAll.length}
                    checked={ItemsAll.length > 0 && selected.length === ItemsAll.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            align: "center",
            width: 70,
        },
        {
            key: "quantity",
            label: __c(`table.colsItems.quantity`),
            align: "left",
        },
        {
            key: "erpCode",
            label: __c(`table.colsItems.erpCode`),
            align: "left",
        },
        {
            key: "name",
            label: __c(`table.colsItems.name`),
            align: "left",
        },
        {
            key: "value",
            label: __c(`table.colsItems.price`),
            align: "center"
        },
        {
            key: "weight",
            label: __c(`table.colsItems.weight`),
            align: "center"
        },
        {
            key: "volume",
            label: __c(`table.colsItems.volume`),
            align: "center"
        },
        {
            key: "status",
            label: __c(`table.cols.isActive`),
            align: "center"
        },
        {
            key: "options",
            label: __c(`table.colsItems.options`),
            align: "center",
            sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
        },
    ]

    const dataTable = map(ItemsAll, (row, i) => {
        const isItemSelected = isSelected(get(row, "idClientItem"));
        const labelId = `enhanced-table-checkbox-${i}`;

        return ({
            ...row,
            checkbox: (
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e, v) => handleChecked(e, get(row, "idClientItem"))}
                    inputProps={{ 'aria-labelledby': labelId, }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            quantity: find(selected, (item) => item === row.idClientItem) ? <Box sx={{ ".MuiInputBase-root": { height: 25 } }}><InputText name="quantity" size="small" defaultValue={0} sx={{ width: 75 }} onChange={(e) => setQuantities(state => ({ ...state, [row.idClientItem]: e.target.value }))} /> </Box> : <div className='w-[75px]' />,
            status: <Chip label={__c(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
            value: `$ ${get(row, "price", 0)?.toFixed(2)}`,
        })
    })

    const postOrderToRoute = () => {
        const itemsSelected = filter(dataTable, ({ idClientItem }) => !!find(selected, (item) => item === idClientItem))
        const body = map(itemsSelected, ({ idClientItem, quantity }) => ({
            idClientItem,
            idOrder,
            quantity: quantities[idClientItem]
        }))

        dispatch(postOrderItem(body))

    }

    return (
        <div>
            <Dialog onClose={close} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {__("head.newItem.name")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Table
                    headTable={headTable}
                    dataTable={dataTable}
                    __={__}
                    sizeFilters={125}
                    loading={get(order, "isLoading", false)}
                    empty="orders"
                    propsTable={{
                        size: "small"
                    }}
                />
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={postOrderToRoute} loading={get(post, "isLoading") || get(put, "isLoading")} disabled={isEmpty(selected)}>{__("action.save")}</LoadingButton>
                    <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={close} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default ItemModal