import {
    postClientLoading,
    postClientRequest,
    postClientSuccess,
    postClientReject,
    postClientReset
} from "../../actions/client/post";

import {
    getClientIdRequest,
    getClientIdSuccess
} from "../../actions/client/getId";

export const postClient = (formData) => async (dispatch, getState) => {
    dispatch(postClientLoading());
    try {
        const { data } = await postClientRequest(formData, getState);
        const id = data?.id

        const getId = await getClientIdRequest({id}, getState);
        await dispatch(getClientIdSuccess(getId?.data))

        dispatch(postClientSuccess(data))
    } catch (error) {
        dispatch(postClientReject(error))
    } finally {
        setTimeout(() => { dispatch(postClientReset()) }, 3000);
    }
    return Promise.resolve();
};