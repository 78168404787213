import {
    getCostIdLoading,
    getCostIdRequest,
    getCostIdSuccess,
    getCostIdReject,
} from "../../actions/cost/getId";

export const getCostId = (formData) => async (dispatch, getState) => {
    dispatch(getCostIdLoading());
    try {
        const { data } = await getCostIdRequest(formData, getState);
        dispatch(getCostIdSuccess(data))
    } catch (error) {
        dispatch(getCostIdReject(error))
    }
    return Promise.resolve();
};