import Invoices from "../../../../pages/Report/Invoices";

const routerInvoices = [
    {
        key: "invoices",
        path: "/report/invoices",
        element: <Invoices />,
    },
]

export default routerInvoices
