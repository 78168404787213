import {
    getDeliveryPointLoading,
    getDeliveryPointReject,
    getDeliveryPointRequest,
    getDeliveryPointSuccess
} from "../../actions/deliveryPoints/getAll";

export const getDeliveryPoint = (formData) => async (dispatch, getState) => {
    dispatch(getDeliveryPointLoading());
    try {
        const { data } = await getDeliveryPointRequest(formData, getState);
        dispatch(getDeliveryPointSuccess(data))
    } catch (error) {
        dispatch(getDeliveryPointReject(error))
    }
    return Promise.resolve();
};