import React, { useState } from 'react'
import {
    Box,
    Typography,
    FormControl,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormHelperText
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import get from "lodash/get";

const InputText = ({ children, formik, name, label, disabled, labelInput = null, fullWidth = true, ...props }) => {
    const [showPass, setShowPass] = useState(false);


    const handleClickShowPassword = () => {
        setShowPass(state => !state)
    }

    return (
        <FormControl fullWidth={fullWidth} >
            {!labelInput &&
                <Typography className='pb-2 pl-4' component="label" htmlFor={name} color={disabled ? "text.lite" : "text.main"} >
                    {label}
                </Typography>
            }
            <OutlinedInput
                id={name}
                name={name}
                size="small"
                value={get(formik, `values.${name}`)}
                onChange={get(formik, `handleChange`)}
                error={get(formik, `touched.${name}`) && Boolean(get(formik, `errors.${name}`))}
                // helperText={get(formik, `touched.${name}`) && get(formik, `errors.${name}`)}
                disabled={disabled}
                label={labelInput}
                {...props}



                type={showPass ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleClickShowPassword}
                            edge="end"
                        >
                            {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText id={`helper-${name}`} error={get(formik, `touched.${name}`) && Boolean(get(formik, `errors.${name}`))} >
                {get(formik, `touched.${name}`) && get(formik, `errors.${name}`)}
            </FormHelperText>
        </FormControl>
    )
}

export default InputText