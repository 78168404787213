import {
    Dashboard as DashboardIcon,
    Report as ReportIcon,
    Help as HelpIcon,
    Assignment as AssignmentIcon,
    InsertDriveFile as InsertDriveFileIcon,
    ViewCarousel as ViewCarouselIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import { map, flatten } from "lodash";


export const main = (__) => [
    {
        key: 1,
        ico: <DashboardIcon color="inherit" />,
        name: __("menu.main.name"),
        path: "/masters",
        children: [
            {
                key: 11,
                name: __("menu.main.children.clint"),
                path: "/masters/clients",
                parent: __("menu.main.name"),
                parentPath: "/masters"
            },
            // {
            //     key: 13,
            //     name: __("menu.main.children.contc"),
            //     path: "/masters/contacts",
            //     parent: __("menu.main.name"),
            //     parentPath: "/masters"
            // },
            {
                key: 12,
                name: __("menu.main.children.point"),
                path: "/masters/delivery_points",
                parent: __("menu.main.name"),
                parentPath: "/masters"
            },
            {
                key: 14,
                name: __("menu.main.children.driver"),
                path: "/masters/driver",
                parent: __("menu.main.name"),
                parentPath: "/masters"
            },
            {
                key: 15,
                name: __("menu.main.children.transp"),
                path: "/masters/transport",
                parent: __("menu.main.name"),
                parentPath: "/masters"
            },
            {
                key: 16,
                name: __("menu.main.children.operators"),
                path: "/masters/operators",
                parent: __("menu.main.name"),
                parentPath: "/masters"
            },
        ],
    },
    {
        key: 2,
        ico: <ViewCarouselIcon color="inherit" />,
        name: __("menu.trade.name"),
        path: "/transactions",
        children: [
            // {
            //     key: 21,
            //     name: __("menu.trade.children.quote"),
            //     path: "/transactions/quotes",
            //     parent: __("menu.trade.name"),
            //     parentPath: "/transactions"
            // },
            {
                key: 22,
                name: __("menu.trade.children.order"),
                path: "/transactions/orders",
                parent: __("menu.trade.name"),
                parentPath: "/transactions"
            },
            {
                key: 23,
                name: __("menu.trade.children.route"),
                path: "/transactions/routes",
                parent: __("menu.trade.name"),
                parentPath: "/transactions"
            },
            {
                key: 24,
                name: __("menu.trade.children.invoice"),
                path: "/transactions/invoices",
                parent: __("menu.trade.name"),
                parentPath: "/transactions"
            },
           
        ]
    },
    {
        key: 3,
        ico: <ReportIcon color="inherit" />,
        name: __("menu.report.name"),
        path: "/report",
        children: [
            {
                key: 31,
                name: __("menu.report.children.invoices"),
                path: "/report/invoices",
                parent: __("menu.report.name"),
                parentPath: "/report"
            },
            {
                key: 32,
                name: __("menu.report.children.orders"),
                path: "/report/orders",
                parent: __("menu.report.name"),
                parentPath: "/report"
            },
            {
                key: 33,
                name: __("menu.report.children.routeCost"),
                path: "/report/route-cost",
                parent: __("menu.report.name"),
                parentPath: "/report"
            },
            {
                key: 34,
                name: __("menu.report.children.routes"),
                path: "/report/routes",
                parent: __("menu.report.name"),
                parentPath: "/report"
            },
        ],
    },
    {
        key: 4,
        ico: <SettingsIcon color="inherit" />,
        name: __("menu.other.sett"),
        path: "/config",
        children: [
            {
                key: 41,
                name: __("menu.config.children.cost"),
                path: "/config/cost",
                parent: __("menu.config.name"),
                parentPath: "/config"
            },
            // {
            //     key: 42,
            //     name: __("menu.config.children.transpBrand"),
            //     path: "/config/transportBrand",
            //     parent: __("menu.config.name"),
            //     parentPath: "/config"
            // },
            // {
            //     key: 43,
            //     name: __("menu.config.children.transpSupplier"),
            //     path: "/config/transportSupplier",
            //     parent: __("menu.config.name"),
            //     parentPath: "/config"
            // },
        ],
    }
]

export const others = (__) => [
    {
        key: 6,
        ico: <HelpIcon color="inherit" />,
        name: __("menu.other.help"),
        path: "/help",
        parent: null
    },
    {
        key: 7,
        ico: <AssignmentIcon color="inherit" />,
        name: __("menu.other.term"),
        path: "/terms_and_conditions",
        parent: null
    }
]

export const auth = (__) => [
    {
        key: 8,
        name: __("menu.auth.prof"),
        path: "/profile",
        parent: null
    },
]

export const all_options = (__) => flatten([...map(main(__), ({ children }) => children), main(__), others(__), auth(__)]);
