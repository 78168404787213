import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { API_URL } from "../../../../assets/util/request"

import {
    registerLoading,
    registerReject,
    registerRequest,
    registerSuccess,
    getUser401
} from "../../actions/register";

export const register = (formData) => async (dispatch, getState) => {
    dispatch(registerLoading());
    try {
        const { data } = await registerRequest(formData, getState);
        if (!isEmpty(get(data, "data", {}))) {
            const { tokenGenerated, idUser } = get(data, "data")
            try {
                const user = await axios(`/api/Users/${idUser}`, {
                    baseURL: API_URL,
                    headers: { Authorization: `Bearer ${tokenGenerated}` }
                })
                data.data = { ...data.data, ...user.data.data }
                dispatch(registerSuccess(data))
            } catch (error) {
                dispatch(getUser401())
            }
        } else {
            dispatch(registerReject(data))
        }
    } catch (error) {
        dispatch(registerReject(get(error, "response.data")))
    }
    return Promise.resolve();
};