import React from 'react';
import { SvgIcon } from '@mui/material'

const Mail = (props) => (
    <SvgIcon viewBox="0 0 170 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "170px", height: "135px" }}>
        <path d="M14.1864 93.9921C15.2128 94.5864 15.2128 95.5318 14.1864 96.1261C13.1599 96.7203 11.5121 96.7203 10.4857 96.1261C9.4592 95.5318 9.4592 94.5864 10.4857 93.9921C11.5121 93.3978 13.1599 93.3978 14.1864 93.9921Z" fill="#00283B" />
        <path d="M23.8309 88.4276C24.8574 89.0218 24.8574 89.9673 23.8309 90.5615C22.8044 91.1558 21.1567 91.1558 20.1302 90.5615C19.1037 89.9673 19.1037 89.0218 20.1302 88.4276C21.1567 87.8333 22.8044 87.8333 23.8309 88.4276Z" fill="#00283B" />
        <path d="M4.54378 88.4276C5.57025 89.0218 5.57025 89.9673 4.54378 90.5615C3.51732 91.1558 1.86956 91.1558 0.843093 90.5615C-0.183375 89.9673 -0.183375 89.0218 0.843093 88.4276C1.86956 87.8333 3.51732 87.8333 4.54378 88.4276Z" fill="#00283B" />
        <path d="M14.1864 82.836C15.2128 83.4303 15.2128 84.3757 14.1864 84.97C13.1599 85.5643 11.5121 85.5643 10.4857 84.97C9.4592 84.3757 9.4592 83.4303 10.4857 82.836C11.5121 82.2417 13.1599 82.2417 14.1864 82.836Z" fill="#00283B" />
        <path d="M4.54378 77.2715C5.57025 77.8657 5.57025 78.8112 4.54378 79.4055C3.51732 79.9997 1.86956 79.9997 0.843093 79.4055C-0.183375 78.8112 -0.183375 77.8657 0.843093 77.2715C1.86956 76.6772 3.51732 76.6772 4.54378 77.2715Z" fill="#00283B" />
        <path d="M101.545 132.187C102.572 132.782 102.572 133.727 101.545 134.321C100.519 134.916 98.871 134.916 97.8446 134.321C96.8181 133.727 96.8181 132.782 97.8446 132.187C98.871 131.593 100.519 131.593 101.545 132.187Z" fill="#00283B" />
        <path d="M111.216 126.623C112.243 127.217 112.243 128.163 111.216 128.757C110.19 129.351 108.542 129.351 107.515 128.757C106.489 128.163 106.489 127.217 107.515 126.623C108.542 126.029 110.19 126.029 111.216 126.623Z" fill="#00283B" />
        <path d="M140.173 109.875C141.199 110.47 141.199 111.415 140.173 112.009C139.146 112.604 137.498 112.604 136.472 112.009C135.446 111.415 135.446 110.47 136.472 109.875C137.498 109.281 139.146 109.281 140.173 109.875Z" fill="#00283B" />
        <path d="M149.843 104.311C150.87 104.905 150.87 105.85 149.843 106.445C148.817 107.039 147.169 107.039 146.142 106.445C145.116 105.85 145.116 104.905 146.142 104.311C147.169 103.717 148.817 103.717 149.843 104.311Z" fill="#00283B" />
        <path d="M159.486 98.7462C160.512 99.3405 160.512 100.286 159.486 100.88C158.459 101.474 156.811 101.474 155.785 100.88C154.759 100.286 154.759 99.3405 155.785 98.7462C156.811 98.152 158.459 98.152 159.486 98.7462Z" fill="#00283B" />
        <path d="M169.157 93.1547C170.183 93.749 170.183 94.6944 169.157 95.2887C168.13 95.8829 166.482 95.8829 165.456 95.2887C164.429 94.6944 164.429 93.749 165.456 93.1547C166.482 92.5604 168.13 92.5604 169.157 93.1547Z" fill="#00283B" />
        <path d="M120.859 121.031C121.885 121.626 121.885 122.571 120.859 123.165C119.832 123.76 118.185 123.76 117.158 123.165C116.132 122.571 116.132 121.626 117.158 121.031C118.185 120.437 119.832 120.437 120.859 121.031Z" fill="#00283B" />
        <path d="M130.53 115.467C131.556 116.061 131.556 117.007 130.53 117.601C129.503 118.195 127.855 118.195 126.829 117.601C125.802 117.007 125.802 116.061 126.829 115.467C127.855 114.873 129.503 114.873 130.53 115.467Z" fill="#00283B" />
        <path d="M82.2308 132.187C83.2573 132.782 83.2573 133.727 82.2308 134.321C81.2043 134.916 79.5566 134.916 78.5301 134.321C77.5036 133.727 77.5036 132.782 78.5301 132.187C79.5566 131.593 81.2043 131.593 82.2308 132.187Z" fill="#00283B" />
        <path d="M91.9012 126.623C92.9277 127.217 92.9277 128.163 91.9012 128.757C90.8747 129.351 89.227 129.351 88.2005 128.757C87.174 128.163 87.174 127.217 88.2005 126.623C89.227 126.029 90.8747 126.029 91.9012 126.623Z" fill="#00283B" />
        <path d="M101.545 121.031C102.572 121.626 102.572 122.571 101.545 123.165C100.519 123.76 98.871 123.76 97.8446 123.165C96.8181 122.571 96.8181 121.626 97.8446 121.031C98.871 120.437 100.519 120.437 101.545 121.031Z" fill="#00283B" />
        <path d="M111.216 115.467C112.243 116.061 112.243 117.007 111.216 117.601C110.19 118.195 108.542 118.195 107.515 117.601C106.489 117.007 106.489 116.061 107.515 115.467C108.542 114.873 110.19 114.873 111.216 115.467Z" fill="#00283B" />
        <path d="M140.173 98.7462C141.199 99.3405 141.199 100.286 140.173 100.88C139.146 101.474 137.498 101.474 136.472 100.88C135.446 100.286 135.446 99.3405 136.472 98.7462C137.498 98.152 139.146 98.152 140.173 98.7462Z" fill="#00283B" />
        <path d="M149.843 93.1547C150.87 93.749 150.87 94.6944 149.843 95.2887C148.817 95.8829 147.169 95.8829 146.142 95.2887C145.116 94.6944 145.116 93.749 146.142 93.1547C147.169 92.5604 148.817 92.5604 149.843 93.1547Z" fill="#00283B" />
        <path d="M159.486 87.5902C160.512 88.1844 160.512 89.1299 159.486 89.7241C158.459 90.3184 156.811 90.3184 155.785 89.7241C154.759 89.1299 154.759 88.1844 155.785 87.5902C156.811 86.9959 158.459 86.9959 159.486 87.5902Z" fill="#00283B" />
        <path d="M169.157 82.0257C170.183 82.6199 170.183 83.5654 169.157 84.1596C168.13 84.7539 166.482 84.7539 165.456 84.1596C164.429 83.5654 164.429 82.6199 165.456 82.0257C166.482 81.4314 168.13 81.4314 169.157 82.0257Z" fill="#00283B" />
        <path d="M120.859 109.875C121.885 110.47 121.885 111.415 120.859 112.009C119.832 112.604 118.185 112.604 117.158 112.009C116.132 111.415 116.132 110.47 117.158 109.875C118.185 109.281 119.832 109.281 120.859 109.875Z" fill="#00283B" />
        <path d="M130.53 104.311C131.556 104.905 131.556 105.85 130.53 106.445C129.503 107.039 127.855 107.039 126.829 106.445C125.802 105.85 125.802 104.905 126.829 104.311C127.855 103.717 129.503 103.717 130.53 104.311Z" fill="#00283B" />
        <path d="M72.5877 126.623C73.6142 127.217 73.6142 128.163 72.5877 128.757C71.5613 129.351 69.9135 129.351 68.887 128.757C67.8606 128.163 67.8606 127.217 68.887 126.623C69.9135 126.029 71.5613 126.029 72.5877 126.623Z" fill="#00283B" />
        <path d="M82.2308 121.031C83.2573 121.626 83.2573 122.571 82.2308 123.165C81.2043 123.76 79.5566 123.76 78.5301 123.165C77.5036 122.571 77.5036 121.626 78.5301 121.031C79.5566 120.437 81.2043 120.437 82.2308 121.031Z" fill="#00283B" />
        <path d="M91.9012 115.467C92.9277 116.061 92.9277 117.007 91.9012 117.601C90.8747 118.195 89.227 118.195 88.2005 117.601C87.174 117.007 87.174 116.061 88.2005 115.467C89.227 114.873 90.8747 114.873 91.9012 115.467Z" fill="#00283B" />
        <path d="M101.545 109.875C102.572 110.47 102.572 111.415 101.545 112.009C100.519 112.604 98.871 112.604 97.8446 112.009C96.8181 111.415 96.8181 110.47 97.8446 109.875C98.871 109.281 100.519 109.281 101.545 109.875Z" fill="#00283B" />
        <path d="M111.216 104.311C112.243 104.905 112.243 105.85 111.216 106.445C110.19 107.039 108.542 107.039 107.515 106.445C106.489 105.85 106.489 104.905 107.515 104.311C108.542 103.717 110.19 103.717 111.216 104.311Z" fill="#00283B" />
        <path d="M140.173 87.5902C141.199 88.1844 141.199 89.1299 140.173 89.7241C139.146 90.3184 137.498 90.3184 136.472 89.7241C135.446 89.1299 135.446 88.1844 136.472 87.5902C137.498 86.9959 139.146 86.9959 140.173 87.5902Z" fill="#00283B" />
        <path d="M149.843 82.0257C150.87 82.6199 150.87 83.5654 149.843 84.1596C148.817 84.7539 147.169 84.7539 146.142 84.1596C145.116 83.5654 145.116 82.6199 146.142 82.0257C147.169 81.4314 148.817 81.4314 149.843 82.0257Z" fill="#00283B" />
        <path d="M159.486 76.4341C160.512 77.0284 160.512 77.9738 159.486 78.5681C158.459 79.1624 156.811 79.1624 155.785 78.5681C154.759 77.9738 154.759 77.0284 155.785 76.4341C156.811 75.8398 158.459 75.8398 159.486 76.4341Z" fill="#00283B" />
        <path d="M120.859 98.7462C121.885 99.3405 121.885 100.286 120.859 100.88C119.832 101.474 118.185 101.474 117.158 100.88C116.132 100.286 116.132 99.3405 117.158 98.7462C118.185 98.152 119.832 98.152 120.859 98.7462Z" fill="#00283B" />
        <path d="M130.53 93.1547C131.556 93.749 131.556 94.6944 130.53 95.2887C129.503 95.8829 127.855 95.8829 126.829 95.2887C125.802 94.6944 125.802 93.749 126.829 93.1547C127.855 92.5604 129.503 92.5604 130.53 93.1547Z" fill="#00283B" />
        <path d="M62.9168 121.031C63.9433 121.626 63.9433 122.571 62.9168 123.165C61.8903 123.76 60.2426 123.76 59.2161 123.165C58.1897 122.571 58.1897 121.626 59.2161 121.031C60.2426 120.437 61.8903 120.437 62.9168 121.031Z" fill="#00283B" />
        <path d="M72.5877 115.467C73.6142 116.061 73.6142 117.007 72.5877 117.601C71.5613 118.195 69.9135 118.195 68.887 117.601C67.8606 117.007 67.8606 116.061 68.887 115.467C69.9135 114.873 71.5613 114.873 72.5877 115.467Z" fill="#00283B" />
        <path d="M53.1927 115.413C54.2192 116.007 54.2192 116.953 53.1927 117.547C52.1662 118.141 50.5185 118.141 49.492 117.547C48.4656 116.953 48.4656 116.007 49.492 115.413C50.5185 114.819 52.1662 114.819 53.1927 115.413Z" fill="#00283B" />
        <path d="M62.8363 109.821C63.8627 110.416 63.8627 111.361 62.8363 111.955C61.8098 112.55 60.162 112.55 59.1356 111.955C58.1091 111.361 58.1091 110.416 59.1356 109.821C60.162 109.227 61.8098 109.227 62.8363 109.821Z" fill="#00283B" />
        <path d="M43.5233 109.821C44.5497 110.416 44.5497 111.361 43.5233 111.955C42.4968 112.55 40.8491 112.55 39.8226 111.955C38.7961 111.361 38.7961 110.416 39.8226 109.821C40.8491 109.227 42.4968 109.227 43.5233 109.821Z" fill="#00283B" />
        <path d="M53.1927 104.257C54.2192 104.851 54.2192 105.796 53.1927 106.391C52.1662 106.985 50.5185 106.985 49.492 106.391C48.4656 105.796 48.4656 104.851 49.492 104.257C50.5185 103.662 52.1662 103.662 53.1927 104.257Z" fill="#00283B" />
        <path d="M33.8787 104.257C34.9052 104.851 34.9052 105.796 33.8787 106.391C32.8523 106.985 31.2045 106.985 30.1781 106.391C29.1516 105.796 29.1516 104.851 30.1781 104.257C31.2045 103.662 32.8523 103.662 33.8787 104.257Z" fill="#00283B" />
        <path d="M43.5233 98.6652C44.5497 99.2595 44.5497 100.205 43.5233 100.799C42.4968 101.393 40.8491 101.393 39.8226 100.799C38.7961 100.205 38.7961 99.2595 39.8226 98.6652C40.8491 98.0709 42.4968 98.0709 43.5233 98.6652Z" fill="#00283B" />
        <path d="M24.2083 98.6652C25.2348 99.2595 25.2348 100.205 24.2083 100.799C23.1819 101.393 21.5341 101.393 20.5076 100.799C19.4812 100.205 19.4812 99.2595 20.5076 98.6652C21.5341 98.0709 23.1819 98.0709 24.2083 98.6652Z" fill="#00283B" />
        <path d="M33.8787 93.1007C34.9052 93.695 34.9052 94.6404 33.8787 95.2347C32.8523 95.8289 31.2045 95.8289 30.1781 95.2347C29.1516 94.6404 29.1516 93.695 30.1781 93.1007C31.2045 92.5064 32.8523 92.5064 33.8787 93.1007Z" fill="#00283B" />
        <path d="M156.839 64.0895L156.514 45.478H2.89551L3.1116 63.6033C3.05758 64.0625 3.1116 63.6033 3.16563 64.0895C3.51679 66.7907 5.62374 69.546 9.43248 71.734L66.7526 104.824C74.397 109.254 85.9853 109.713 92.6033 105.905L152.409 71.3828C155.731 69.4649 157.19 66.8177 156.839 64.1165V64.0895Z" fill="#B0E2FA" />
        <path d="M81.3667 109.794C76.0453 109.794 70.4267 108.498 66.0778 105.986L8.75769 72.8955C4.75987 70.5724 2.30176 67.5741 1.84255 64.3866C1.70749 64.0625 1.73449 63.7383 1.7615 63.4952L1.54541 44.1544L158.001 46.7476L158.163 64.0084C158.568 67.3309 156.704 70.4374 153.084 72.5173L93.2792 107.066C90.0917 108.903 85.8508 109.794 81.3937 109.794H81.3667ZM4.40871 63.6573C4.40871 63.6573 4.43572 63.8194 4.46273 63.9274C4.75987 66.2775 6.81281 68.7086 10.0813 70.5994L67.4014 103.689C74.6677 107.903 85.6887 108.363 91.9285 104.77L151.734 70.2213C154.462 68.6546 155.786 66.5206 155.488 64.2516V64.1165L155.164 46.8016H4.19261L4.3817 63.6573H4.40871Z" fill="#00283B" />
        <path d="M152.193 53.2305L92.3883 87.7522C85.7703 91.588 74.182 91.1018 66.5375 86.6718L9.21742 53.5817C1.57294 49.1517 0.735549 42.4796 7.35356 38.6439L67.1588 4.12219C73.7768 0.28644 85.3651 0.772666 93.0095 5.20268L150.33 38.2927C157.974 42.7228 158.811 49.3948 152.193 53.2305Z" fill="#B0E2FA" />
        <path d="M150.33 46.8286L93.0096 13.7386C85.3651 9.30856 73.7769 8.84934 67.1589 12.6581L7.35364 47.1798C6.00303 47.9631 4.97656 48.8816 4.22021 49.854V57.7416C5.30071 59.3083 6.94846 60.821 9.19048 62.1176L66.5106 95.2076C74.155 99.6377 85.7433 100.097 92.3613 96.2881L152.167 61.7664C158.785 57.9307 157.974 51.2586 150.303 46.8286H150.33Z" fill="#F5FCFF" />
        <path d="M81.1238 91.642C75.8024 91.642 70.1838 90.3454 65.8348 87.8333L8.51474 54.7432C4.05771 52.1771 1.51855 48.6925 1.51855 45.1539C1.51855 42.1555 3.35539 39.4273 6.65089 37.5094L66.4561 2.96066C73.4523 -1.06417 85.6348 -0.577943 93.6305 4.04116L150.951 37.1312C155.408 39.6974 157.947 43.182 157.947 46.7206C157.947 49.719 156.11 52.4472 152.814 54.365L93.0092 88.8868C89.8217 90.7236 85.5808 91.615 81.1238 91.615V91.642ZM78.3685 2.85261C74.3977 2.85261 70.616 3.63597 67.8067 5.28372L8.00151 39.8324C5.54339 41.2641 4.19278 43.155 4.19278 45.1539C4.19278 47.72 6.24571 50.3672 9.86535 52.4472L67.1854 85.5372C74.4517 89.7512 85.4728 90.2104 91.7126 86.6177L151.518 52.096C153.976 50.6644 155.327 48.7735 155.327 46.7746C155.327 44.2084 153.274 41.5612 149.654 39.4813L92.3339 6.39124C88.3361 4.09519 83.2037 2.90665 78.3685 2.90665V2.85261Z" fill="#00283B" />
        <path d="M73.1554 49.6919L76.3159 27.4338C76.3159 27.4338 76.1538 27.5148 76.0728 27.5419L34.906 51.3127C34.5279 51.5288 34.2307 51.7449 33.9336 51.988L73.1824 49.6919H73.1554Z" fill="#B0E2FA" />
        <path d="M30.9897 46.6666L34.1232 44.0734C34.4743 43.8033 34.8525 43.5332 35.2847 43.263L76.7216 19.3571L78.9095 18.3847L78.6935 20.6808L75.398 44.0194L31.0168 46.6125L30.9897 46.6666ZM75.6681 23.0308L40.255 43.4791L73.0479 41.5612L75.6681 23.0308Z" fill="#00283B" />
        <path d="M81.665 54.4731L33.9072 57.2824C34.0963 57.4175 34.2584 57.5795 34.5015 57.6876L67.6726 76.8393C70.6169 78.5411 75.5872 78.433 78.7476 76.5962L119.914 52.8254C123.075 50.9885 123.264 48.1252 120.32 46.4235L87.1484 27.2717C86.6892 27.0016 86.149 26.7855 85.6087 26.6234L81.665 54.4731Z" fill="#B0E2FA" />
        <path d="M73.9117 72.5443C71.7778 72.5443 69.6978 72.0851 68.0771 71.1397L34.879 51.988C34.6629 51.8529 34.4738 51.7179 34.2847 51.5828L31.5024 49.4218L34.879 49.0977L81.5562 46.3694L85.554 18.0335L87.0667 18.5198C87.7961 18.7629 88.3633 19.006 88.8765 19.2761L122.075 38.4278C123.857 39.4543 124.83 40.913 124.83 42.5607C124.83 44.2895 123.668 45.9642 121.669 47.1258L80.5027 70.8966C78.6389 71.9771 76.2618 72.5173 73.9387 72.5173L73.9117 72.5443ZM39.336 51.5018L69.4007 68.8707C71.9398 70.3293 76.3969 70.2213 79.1521 68.6276L120.319 44.8567C121.48 44.1814 122.156 43.3441 122.156 42.5877C122.156 41.9394 121.642 41.2911 120.724 40.7509L87.715 21.7072L83.8793 48.9086L39.336 51.5288V51.5018Z" fill="#00283B" />
        <path d="M80.7461 90.3454V109.065L87.148 108.498L95.0896 104.932L155.543 69.8701L156.84 64.0895L157.596 49.0706L89.5521 89.0488L81.9887 90.3454H80.7461Z" fill="#00283B" />
    </SvgIcon>
)

export default Mail