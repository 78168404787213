import React, { useState } from 'react';
import {
    FormControl,
    Autocomplete as AutocompleteUi,
    Typography,
    TextField,
} from '@mui/material'
import get from "lodash/get"
import find from "lodash/find"

const Autocomplete = ({
    formik,
    name,
    label,
    disabled,
    labelInput = null,
    fullWidth = true,
    loading,
    inputProps = {},
    options = [],
    reload = () => { },
    setIsMount = () => { },
    ...props
}) => {
    let TIME = null

    const onChange = (e, v) => {
        if (!!v) {
            clearTimeout(TIME)
        }
        formik?.setFieldValue(name, get(v, "value"))
    }

    const onInputChange = (e, v) => {
        // if (get(find(options, ({ value }) => value === get(formik, `values.${name}`)), "label") !== v) {
        //     if (!v) {
        //         formik?.setFieldValue(name, get(v, ""))
        //     }
        //     clearTimeout(TIME)
        //     TIME = setTimeout(() => {
        //         setIsMount(true)
        //         reload(v)
        //     }, 3000)
        // }
    }


    return (
        <FormControl fullWidth={fullWidth} >
            {!labelInput &&
                <Typography className='pb-2 pl-4' component="label" htmlFor={name} color={disabled ? "text.lite" : "text.main"} >
                    {label}
                </Typography>
            }
            <AutocompleteUi
                id={name}
                name={name}
                disablePortal
                fullWidth
                includeInputInList
                disabled={disabled}
                onChange={onChange}
                onInputChange={onInputChange}
                getOptionLabel={({ label }) => label}
                options={options}
                value={find(options, ({ value }) => value === get(formik, `values.${name}`))}
                loading={loading}
                {...props}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        size="small"
                        name={name}
                        variant="outlined"
                        helperText={get(formik, `touched.${name}`) && get(formik, `errors.${name}`)}
                        error={Boolean(get(formik, `touched.${name}`) && get(formik, `errors.${name}`))}
                        {...inputProps}
                    />
                )}
            />
        </FormControl>
    );
}

export default Autocomplete;
