import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ORDER_HISTORY_LOADING = 'GET_ORDER_HISTORY_LOADING';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_REJECT = 'GET_ORDER_HISTORY_REJECT';

//* ACTIONS ------------------------------------------------
export const getOrderHistoryLoading = () => ({ type: GET_ORDER_HISTORY_LOADING });
export const getOrderHistorySuccess = (payload) => ({ type: GET_ORDER_HISTORY_SUCCESS, payload });
export const getOrderHistoryReject = (payload) => ({ type: GET_ORDER_HISTORY_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOrderHistoryRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/OrderHistory`, options);
};

