import {
    postOperatorsLoading,
    postOperatorsRequest,
    postOperatorsSuccess,
    postOperatorsReject,
    postOperatorsReset
} from "../../actions/operators/post";

export const postOperators = (formData) => async (dispatch, getState) => {
    dispatch(postOperatorsLoading());
    try {
        const { data } = await postOperatorsRequest(formData, getState);
        dispatch(postOperatorsSuccess(data))
    } catch (error) {
        dispatch(postOperatorsReject(error))
    } finally {
        setTimeout(() => { dispatch(postOperatorsReset()) }, 3000);
    }
    return Promise.resolve();
};