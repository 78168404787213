/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import { func, shape, bool, any } from 'prop-types';
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty"
import toString from "lodash/toString"
import Search from "./Search";

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const Map = ({
    position,
    setPositions,
    draggable,
    formik,
    disableInput,
    idMap = "location-map"
}) => {
    const [__] = useTranslation("global");

    const { isLoaded, loadError } = useLoadScript({
        id: `google-map-${__("lang.current.lang")}`,
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        language: __("lang.current.lang"),
        libraries: ["places"]
    });

    const [coord, setCoord] = useState({})
    const [coordExist, setCoordExist] = useState(false);

    useEffect(() => {
        if (!isEmpty(formik) && draggable) {
            const lat = coord.lat;
            const lng = coord.lng;
            formik.setFieldValue("latitude", toString(lat));
            formik.setFieldValue("longitude", toString(lng));
        }
    }, [coord.lat, coord.lng])

    useEffect(() => {
        if (!isEmpty(formik) && draggable) {
            const lat = Number(formik?.values?.latitude)
            const lng = Number(formik?.values?.longitude)
            if (lat !== position.lat && !!lat) {
                setPositions(state => ({ ...state, lat }))
            }
            if (lng !== position.lng && !!lng) {
                setPositions(state => ({ ...state, lng }))
            }
        }
    }, [formik?.values?.latitude, formik?.values?.longitude])

    useEffect(() => {
        const exist = !!(position.lat) && !!(position.lng)
        setCoordExist(exist)
        if (exist) {
            setCoord({
                lat: Number(position.lat),
                lng: Number(position.lng)
            })
        } else {
            setCoord({
                lat: null,
                lng: null
            })
        }
    }, [position.lat, position.lng])

    const renderMap = () => {
        const formatedCoords = (latLng) => {
            const lat = latLng.lat();
            const lng = latLng.lng();

            setPositions({
                lat: lat,
                lng: lng,
            })
        }

        const getCenter = (p) => {
            const defaultCenter = {
                lat: __("map.initial.lat"),
                lng: __("map.initial.lng"),
            }
            if (coordExist) {
                return {
                    lat: Number(p.lat),
                    lng: Number(p.lng)
                };
            } else {
                return defaultCenter;
            }
        }

        const onDraggMarker = (coord) => {
            const { latLng } = coord;
            formatedCoords(latLng)
        };

        const getCoords = (coord) => {
            if (draggable) {
                const { latLng } = coord;
                formatedCoords(latLng)
                setCoordExist(false)
            }
        }


        return (
            <div style={{ flex: 1, flexDirection: "column", display: "flex", height: "100%", width: "100%" }}>
                {
                    isLoaded && !disableInput &&
                    <Search
                        __={__}
                        setPositions={setPositions}
                        REACT_APP_GOOGLE_MAP_API_KEY={REACT_APP_GOOGLE_MAP_API_KEY}
                    />
                }
                <GoogleMap
                    id={idMap}
                    mapContainerStyle={{
                        flex: 1
                    }}
                    zoom={coordExist ? 14 : 8}
                    center={getCenter(coord)}
                    options={{
                        panControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                    }}
                    onClick={getCoords}
                    mapTypeId="terrain" //roadmap|satellite|hybrid|terrain
                >
                    <MarkerF
                        name={"marker a editar"}
                        visible={coordExist}
                        position={coord}
                        draggable={draggable}
                        onDragEnd={onDraggMarker}
                    />
                </GoogleMap>
            </div>
        );
    };
    if (loadError) {
        return <div>Lo sentimos, el mapa no puede ser cargado por el momento</div>;
    }
    return isLoaded ? renderMap() : <div>Cargando...</div>;
};

Map.defaultProps = {
    setPositions: () => { },
    position: {
        lat: null,
        lng: null
    },
    draggable: false,
}

Map.propTypes = {
    setPositions: func,
    position: shape({
        lat: any,
        lng: any
    }),
    draggable: bool,
}

export default Map;


