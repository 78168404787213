/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { func, shape, bool, any } from 'prop-types';
import { useTranslation } from "react-i18next";
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import { Backdrop } from "@mui/material"
import CircularProgress from "../form/CircularProgress"

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const libraries = ["places"];

const ComponentMap = ({
    hasTwoAddresses,
    coord,
    coords,
    initial,
    response,
    setResponse,
    getKm,
}) => {
    const directionsCallback = (response) => {
        if (response !== null && response.status === 'OK') {
            setResponse(response);
        }
    };

    return (
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
            <GoogleMap
                id="location-map"
                mapContainerStyle={{
                    flex: 1
                }}
                {...(hasTwoAddresses ? {} : {
                    zoom: 9,
                    center: {
                        lat: initial.lat,
                        lng: initial.lng,
                    }
                })}
                options={{
                    panControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                }}
                mapTypeId="terrain" //roadmap|satellite|hybrid|terrain
            >
                {
                    hasTwoAddresses && (
                        <DirectionsService
                            options={{
                                origin: get(coord, "[0]"),
                                destination: get(coord, "[1]"),
                                travelMode: 'DRIVING'
                            }}
                            callback={directionsCallback}
                        >
                        </DirectionsService>
                    )
                }
                {
                    hasTwoAddresses && response !== null && (
                        <DirectionsRenderer
                            options={{
                                directions: response,
                                suppressMarkers: true
                            }}

                        />
                    )
                }
                {map([get(coord, "[0]"), get(coord, "[1]")], (location, i) =>
                    <MarkerF
                        key={i}
                        label={`${i + 1}`}
                        position={location}
                        options={{
                            zIndex: i + 1
                        }}
                    />
                )}

            </GoogleMap>
        </div>
    );
}

const Map = ({ coords, getKm, loading }) => {
    const [__] = useTranslation("global");
    const [response, setResponse] = useState(null);
    const [coord, setCoord] = useState([]);
    const [isLoad, setIsLoad] = useState();
    const initial = {
        lat: __("map.initial.lat"),
        lng: __("map.initial.lng"),
    }

    useEffect(() => {
        const sum = sumBy(get(response, "routes[0].legs"), ({ distance }) => distance.value)
        const tim = sumBy(get(response, "routes[0].legs"), ({ duration }) => duration.value)
        getKm({
            distance:
                { value: sum, text: `${(sum / 1000).toFixed(1)} Km` },
            time: { value: tim }
        })
    }, [get(response, "routes[0].legs.length")])

    useEffect(() => {
        if (!!get(coords, "[0].lat") && !!get(coords, "[0].lng") && !!get(coords, "[1].lat") && !!get(coords, "[1].lng")) {
            setIsLoad(true)
            setCoord(coords)
            setTimeout(() => {
                setCoord([{ lat: null, lng: null }, { lat: null, lng: null }])
            }, 100);
        } else {
            setCoord([{ lat: null, lng: null }, { lat: null, lng: null }])
        }
    }, [coords])


    const { isLoaded, loadError } = useLoadScript({
        id: `google-map-${__("lang.current.lang")}`,
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        language: __("lang.current.lang"),
        libraries: libraries
    });

    const hasTwoAddresses = !!get(coords, "[0].lat") && !!get(coords, "[0].lng") && !!get(coords, "[1].lat") && !!get(coords, "[1].lng")

    if (loadError) {
        return <div>Lo sentimos, el mapa no puede ser cargado por el momento</div>;
    }


    return (
        <div className="h-full w-full relative">
            {isLoaded &&
                <div style={{ flexDirection: "column", display: "flex", height: "100%", width: "100%" }}>
                    <ComponentMap
                        hasTwoAddresses={hasTwoAddresses}
                        response={response}
                        setResponse={setResponse}
                        coord={coord}
                        initial={initial}
                        getKm={getKm}
                        coords={coords}
                    />
                </div>
            }
            <Backdrop
                className='!absolute'
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!isLoaded || loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
};


export default Map;


