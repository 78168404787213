import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            description: yup.string().required(__(`form.description.error`)),
        })
    )
}

export default validators
