import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_DRIVER_RESET = 'POST_DRIVER_RESET';
export const POST_DRIVER_LOADING = 'POST_DRIVER_LOADING';
export const POST_DRIVER_SUCCESS = 'POST_DRIVER_SUCCESS';
export const POST_DRIVER_REJECT = 'POST_DRIVER_REJECT';

//* ACTIONS ------------------------------------------------
export const postDriverReset = () => ({ type: POST_DRIVER_RESET });
export const postDriverLoading = () => ({ type: POST_DRIVER_LOADING });
export const postDriverSuccess = (payload) => ({ type: POST_DRIVER_SUCCESS, payload });
export const postDriverReject = (payload) => ({ type: POST_DRIVER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postDriverRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Driver`, options);
};


