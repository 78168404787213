import request, { Methods, withToken } from "../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_PROFILE_RESET = 'GET_PROFILE_RESET';
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_REJECT = 'GET_PROFILE_REJECT';

//* ACTIONS ------------------------------------------------
export const getProfileReset = () => ({ type: GET_PROFILE_RESET });
export const getProfileLoading = () => ({ type: GET_PROFILE_LOADING });
export const getProfileSuccess = (payload) => ({ type: GET_PROFILE_SUCCESS, payload });
export const getProfileReject = (payload) => ({ type: GET_PROFILE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getProfileRequest = async ({ id, params }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Users/${id}`, options);
};

export const getCompanyRequest = async (params, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Company`, options);
};
