import { skyblue, navy, blue, neutral, error, warning, success, info } from "./colors";

const palette = {
    primary: {
        main: skyblue[500],
    },
    secondary: {
        main: blue[300],
    },
    error: {
        main: error[200],
        contrastText: neutral[0]
    },
    warning: {
        main: warning[200],
        contrastText: neutral[0]
    },
    success: {
        main: success[200],
        contrastText: neutral[0]
    },
    info: {
        main: info[200],
        contrastText: neutral[0]
    },
    disabled: {
        main: neutral[300],
        contrastText: neutral[0]
    },
    text: {
        sslite: neutral[100],
        slite: neutral[200],
        lite: neutral[400],
        main: neutral[600],
        dark: neutral[700],
    },
    background: {
        base: neutral[100]
    },
    color: { skyblue, navy, blue, neutral, error, warning, success, info }
};

export default palette;