/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import moment from "moment";
import LocationOffIcon from '@mui/icons-material/LocationOff';

import get from "lodash/get";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import find from "lodash/find";

import InputText from "../../../components/form/InputText";
import DatePickerUi from "../../../components/form/DatePickerUi";
import validator from "./validator"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import Map from "../../../components/map/MapMany2"
import Graf from "./Graf"
import RoutesModal from "./RoutesModal"
import AutoComplete2 from "../../../components/form/AutoComplete2";
import { PageSize } from "../../../config/const"

import { getRouteId } from "../../../store/transactions/thunk/route/getId"
import { putRoute } from "../../../store/transactions/thunk/route/put"

import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";
import { getTransport } from "../../../store/masters/thunk/transport/getAll";
import { getDriver } from "../../../store/masters/thunk/driver/getAll";
import { getRouteOrderAll } from "../../../store/transactions/thunk/route/getOrderAll";
import { putRouteReset } from "../../../store/transactions/actions/route/put";
import { getRouteAllCoordsRoute } from "../../../store/transactions/thunk/route/getAllCoordsRoute";

const NewEdit = ({ setBtns }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [__] = useTranslation("route");
    const { id } = useParams();
    const [km, setKm] = useState(null)
    const [showMap, setShowMap] = useState(false);
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "put" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });
    const [routes, setRoutes] = useState([])
    const [isMount, setIsMount] = useState(false);
    const [mapLoad, setMapLoad] = useState(false);

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.route.detail);
    const put = useSelector(state => state.transactions.route.put);
    const post = useSelector(state => state.transactions.route.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const transport = useSelector(state => state.masters.transport);
    const driver = useSelector(state => state.masters.driver);
    const coordsAllRoute = useSelector(state => state.transactions.route.coordsAllRoute);

    const routeStatus = __(`routeStatus`, { returnObjects: true });
    const transports = map(transport?.data, ({ idTransport, numberPlate, name, isActive }) => ({ value: idTransport, label: `${numberPlate} | ${name}`, isActive }));
    const drivers = map(driver?.data, ({ idDriver, user, license, isActive }) => ({ value: idDriver, label: `${license} | ${user.name}`, isActive }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName, isActive }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}`, isActive }));
    // const orders = useSelector(state => state.transactions.route.orderAll);

    const getDataDriver = (search) => {
        const params = { PageSize }
        if (search) params.Filters = `user.name@=${search}`
        dispatch(getDriver(params))
    }
    const getDataTransport = (search) => {
        const params = { PageSize }
        if (search) params.Filters = `name@=${search}`
        dispatch(getTransport(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize }
        if (search) params.Filters = `deliveryName@=${search}`
        dispatch(getDeliveryPoint(params))
    }

    useEffect(() => {
        if ((searchParams.get('from') !== "new")) {
            if (!!id) {
                dispatch(getRouteId({ id }))
                dispatch(getRouteAllCoordsRoute({ id }))
            }
            getDataDriver()
            getDataTransport()
            getDataDelivery()
        }
    }, [id, searchParams.get('from')])

    useEffect(() => {
        dispatch(getRouteOrderAll({ id }))
    }, [])

    const getMapRoutes = async () => {
        setMapLoad(true)
        const rutas = await map(get(coordsAllRoute, "data", []), (item) => ({
            location: {
                id: item.order + 1,
                lat: Number(item.latitude),
                lng: Number(item.longitude)
            }
        }))
        setRoutes(rutas)
        setMapLoad(false)
    }

    useEffect(() => {
        if (get(coordsAllRoute, "isSuccess")) {
            getMapRoutes()
        }
    }, [get(coordsAllRoute, "isSuccess")])


    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }
    const closeNoti = (e) => {
        setShowNoti(e)
        dispatch(putRouteReset())
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item, i) => <li key={i} >{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put"); }
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }) }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
    }, [put, post])

    const onSubmit = (values) => {
        const body = {
            ...values,
            IsActive: get(values, "isActive"),
            routeName: get(values, "routeName"),
            idRouteStatus: Number(get(values, "idRouteStatus")),
            comments: get(values, "comments"),
            idTransport: Number(get(values, "idTransport")),
            idDriver: Number(get(values, "idDriver")),
            idUser: Number(get(values, "idUser")),
            idReturnDP: Number(get(values, "idReturnDP")),
            idStartDP: Number(get(values, "idStartDP")),
            routeStatus: get(values, "routeStatus"),
            address: get(values, "address"),
            dateToRoute: moment(get(values, "dateToRoute")).format(),
        }
        delete body.routeStatus
        const whitOutNulls = pickBy(body, identity);
        dispatch(putRoute(whitOutNulls))
    }

    const status = find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1))

    const initialValues = {
        isActive: get(current, "data.isActive", true),
        idRoute: get(current, "data.idRoute"),
        routeName: get(current, "data.routeName", ""),

        idRouteStatus: get(find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1)), "value"),
        routeStatus: get(find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1)), "label"),

        idStartDP: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idStartDP", "")), "value"),
        idReturnDP: get(find(deliveryPoints, ({ value }) => value === get(current, "data.idReturnDP", "")), "value"),
        idTransport: get(find(transports, ({ value }) => value === get(current, "data.idTransport", "")), "value"),
        idDriver: get(find(drivers, ({ value }) => value === get(current, "data.idDriver", "")), "value"),

        dateToRoute: moment(get(current, "data.dateToRoute")),
        comments: get(current, "data.comments", ""),

        idUser: get(user, "idUser"),
    }

    const evaluateCoord = (idDP, v) => {
        const { latitude, longitude } = find(deliveryPoint?.data, ({ idDeliveryPoint }) => idDeliveryPoint === idDP) || {}
        if ((!!latitude && !!longitude) || !idDP) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                {get(current, "data.idRouteStatus", 1) >= 4 &&
                    <Button className='w-[100px] min-w-[100px]' variant='text' color="secondary" onClick={() => setShowMap(true)} disabled={get(put, "isLoading")} ><div className='whitespace-nowrap'>{__(`action.showMap`)}</div></Button>
                }
                <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" form="form-route" loading={get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/transactions/routes")} disabled={get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [get(put, "isLoading"), get(current, "data.idRouteStatus", 1)])

    return (
        <div>
            {get(current, "isLoading") || (get(deliveryPoint, "isLoading") && !isMount) || (get(transport, "isLoading") && !isMount) || (get(driver, "isLoading") && !isMount)
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form id="form-route" onSubmit={get(formik, "handleSubmit")}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Paper className='mt-4' >
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                                <Typography component="h4" variant="h4">{`${__(`labels.1`)} - ${id}`}</Typography>
                                                <InputText
                                                    name={"routeStatus"}
                                                    formik={formik}
                                                    label=""
                                                    placeholder=""
                                                    disabled={true}
                                                    fullWidth={false}
                                                    sx={{ "fieldset": { borderColor: `${get(status, "color")} !important` }, "& .Mui-disabled": { "-webkit-text-fill-color": `${get(status, "color")} !important` } }}
                                                />
                                            </Stack>
                                            <Divider />
                                            <Box className='p-4' >
                                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                                    <Grid item xs={12}>
                                                        <InputText
                                                            formik={formik}
                                                            name="routeName"
                                                            label={__(`form.routeName.label`)}
                                                            placeholder={__(`form.routeName.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <DatePickerUi
                                                            formik={formik}
                                                            name="dateToRoute"
                                                            label={__(`form.dateToRoute.label`)}
                                                            placeholder={__(`form.dateToRoute.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <AutoComplete2
                                                            name={"idDriver"}
                                                            formik={formik}
                                                            label={__(`form.idDriver.label`)}
                                                            placeholder={__(`form.idDriver.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                            options={filter(drivers, (item) => item.isActive)}
                                                            reload={getDataDriver}
                                                            loading={get(driver, "isLoading")}
                                                            setIsMount={setIsMount}
                                                            value={find(drivers, ({ value }) => value === get(formik, `values.idDriver`))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography component="h5" variant="h5" color="text.lite">{__(`labels.2`)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoComplete2
                                                            name={"idStartDP"}
                                                            formik={formik}
                                                            label={__(`form.idStartDP.label`)}
                                                            placeholder={__(`form.idStartDP.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                            options={filter(deliveryPoints, (item) => item.isActive)}
                                                            reload={getDataDelivery}
                                                            loading={get(deliveryPoint, "isLoading")}
                                                            setIsMount={setIsMount}
                                                            getOptionDisabled={(option) => evaluateCoord(option.value, 1)}
                                                            getOptionLabel={({ label, value }, v) =>
                                                                evaluateCoord(value) ? <Stack direction="row" spacing={1} alignItems="center"><LocationOffIcon /><div>{label}</div></Stack> : label
                                                            }
                                                            value={find(deliveryPoints, ({ value }) => value === get(formik, `values.idStartDP`))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoComplete2
                                                            name={"idReturnDP"}
                                                            formik={formik}
                                                            label={__(`form.idReturnDP.label`)}
                                                            placeholder={__(`form.idReturnDP.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                            options={filter(deliveryPoints, (item) => item.isActive)}
                                                            reload={getDataDelivery}
                                                            loading={get(deliveryPoint, "isLoading")}
                                                            setIsMount={setIsMount}
                                                            getOptionDisabled={(option) => evaluateCoord(option.value, 1)}
                                                            getOptionLabel={({ label, value }, v) =>
                                                                evaluateCoord(value) ? <Stack direction="row" spacing={1} alignItems="center"><LocationOffIcon /><div>{label}</div></Stack> : label
                                                            }
                                                            value={find(deliveryPoints, ({ value }) => value === get(formik, `values.idReturnDP`))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography component="h5" variant="h5" color="text.lite">{__(`labels.3`)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoComplete2
                                                            name={"idTransport"}
                                                            formik={formik}
                                                            label={__(`form.idTransport.label`)}
                                                            placeholder={__(`form.idTransport.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                            options={filter(transports, (item) => item.isActive)}
                                                            reload={getDataTransport}
                                                            loading={get(transport, "isLoading")}
                                                            setIsMount={setIsMount}
                                                            value={find(transports, ({ value }) => value === get(formik, `values.idTransport`))}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <InputText
                                                            formik={formik}
                                                            name="comments"
                                                            label={__(`form.comments.label`)}
                                                            placeholder={__(`form.comments.placeholder`)}
                                                            disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} lg={6}>
                                        <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 500 }}>
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                {routes.length > 1
                                                    ? <Typography component="h4" variant="h4">{__(`labels.4`)}{km && ` (${get(km, "distance.text")})`}</Typography>
                                                    : <Typography component="h4" variant="h4">{__(`labels.4`)}{`0 km`}</Typography>
                                                }
                                            </Stack>
                                            <Map loading={mapLoad} routes={routes} setKm={setKm} __={__} />
                                        </Paper>
                                        <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 450 }}>
                                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                <Typography component="h4" variant="h4">{__(`labels.5`)}</Typography>
                                            </Stack>
                                            <Graf />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={closeNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
            <RoutesModal
                __={__}
                open={showMap}
                close={() => setShowMap(false)}
            />
        </div>
    )
}

export default NewEdit