import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_ORDER_RESET = 'PUT_ORDER_RESET';
export const PUT_ORDER_LOADING = 'PUT_ORDER_LOADING';
export const PUT_ORDER_SUCCESS = 'PUT_ORDER_SUCCESS';
export const PUT_ORDER_REJECT = 'PUT_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const putOrderReset = () => ({ type: PUT_ORDER_RESET });
export const putOrderLoading = () => ({ type: PUT_ORDER_LOADING });
export const putOrderSuccess = (payload) => ({ type: PUT_ORDER_SUCCESS, payload });
export const putOrderReject = (payload) => ({ type: PUT_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putOrderRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Order`, options);
};


