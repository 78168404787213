import {
    putCostLoading,
    putCostRequest,
    putCostSuccess,
    putCostReject,
    putCostReset
} from "../../actions/cost/put";

export const putCost = (formData) => async (dispatch, getState) => {
    dispatch(putCostLoading());
    try {
        const { data } = await putCostRequest(formData, getState);
        dispatch(putCostSuccess(data))
    } catch (error) {
        dispatch(putCostReject(error))
    } finally {
        setTimeout(() => { dispatch(putCostReset()) }, 3000);
    }
    return Promise.resolve();
};