import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_CLIENT_RESET = 'PUT_CLIENT_RESET';
export const PUT_CLIENT_LOADING = 'PUT_CLIENT_LOADING';
export const PUT_CLIENT_SUCCESS = 'PUT_CLIENT_SUCCESS';
export const PUT_CLIENT_REJECT = 'PUT_CLIENT_REJECT';

//* ACTIONS ------------------------------------------------
export const putClientReset = () => ({ type: PUT_CLIENT_RESET });
export const putClientLoading = () => ({ type: PUT_CLIENT_LOADING });
export const putClientSuccess = (payload) => ({ type: PUT_CLIENT_SUCCESS, payload });
export const putClientReject = (payload) => ({ type: PUT_CLIENT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putClientRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Clients`, options);
};


