import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            deliveryName: yup.string().required(__(`form.deliveryName.error`)),
            deliveryCode: yup.string().required(__(`form.deliveryCode.error`)),
            description: yup.string().required(__(`form.description.error`)),
            
            contactName: yup.string().required(__(`form.contactName.error`)),
            contactPhone: yup.string().required(__(`form.contactPhone.error`)),
            contactEmail:  yup.string().email(__(`form.contactEmail.error2`)).required(__(`form.contactEmail.error`)),
            
            state: yup.string().required(__(`form.state.error`)),
            city: yup.string().required(__(`form.city.error`)),
            address: yup.string().required(__(`form.address.error`)),
        })
    )
}

export default validators
