import React from 'react';
import { SvgIcon } from '@mui/material'

const TMS = (props) => (
    <SvgIcon viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "38px", height: "37px" }}>
        <mask id="mask0_201_53942" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="7" y="5" width="24" height="12">
            <path d="M7.16797 10.9279L19.0961 16.8074L30.6376 10.9279L18.8392 5.94348L7.16797 10.9279Z" fill="white" />
        </mask>
        <g mask="url(#mask0_201_53942)">
            <path d="M7.16898 5.69851L7.05225 16.8068L30.6342 17.0529L30.751 5.94462L7.16898 5.69851Z" fill="url(#paint0_linear_201_53942)" />
        </g>
        <mask id="mask1_201_53942" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="18" y="10" width="13" height="22">
            <path d="M19.4805 17.701L18.8394 31.1206L28.9701 23.1976L30.6378 10.9279L19.4805 17.701Z" fill="white" />
        </mask>
        <g mask="url(#mask1_201_53942)">
            <path d="M21.6323 5.92676L10.2236 26.3364L27.8432 36.1174L39.2519 15.7078L21.6323 5.92676Z" fill="url(#paint1_linear_201_53942)" />
        </g>
        <mask id="mask2_201_53942" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="7" y="10" width="12" height="22">
            <path d="M8.70716 23.4524L18.8392 31.1206L18.5822 17.701L7.16797 10.9279L8.70716 23.4524Z" fill="white" />
        </mask>
        <g mask="url(#mask2_201_53942)">
            <path d="M14.5934 5.33463L-2.58105 18.2739L11.4111 36.7175L28.5855 23.7782L14.5934 5.33463Z" fill="url(#paint2_linear_201_53942)" />
        </g>
        <path d="M22.4712 28.2806V37L34.1646 26.1361L37.5 10.9279L32.4982 14.0584L31.3432 21.5997L22.4712 28.2806Z" fill="#5B94CC" />
        <path d="M29.9855 15.7313L32.4981 14.0583L31.3431 21.5997L29.001 23.3637L29.9855 15.7313Z" fill="#67C1EC" />
        <path d="M33.3306 9.20218L32.498 14.0586L37.4986 10.9281L33.3306 9.20218Z" fill="#60B0E0" />
        <path d="M18.8394 5.94324L21.5966 4.72821L33.3308 9.20179L32.4982 14.0582L29.9856 15.7312L30.6378 10.9277L18.8394 5.94324Z" fill="#76C8ED" />
        <path d="M8.70703 23.4525L9.02821 26.1362L22.4708 37.0001V28.2806L18.839 31.1206L8.70703 23.4525Z" fill="#436CAF" />
        <path d="M15.5891 37.0001L15.1963 31.1207L18.8343 34.0604L15.5891 37.0001Z" fill="#60B0E0" />
        <path d="M3.64139 26.2641L0.5 10.9279L5.30906 14.0584L6.52707 21.5997L8.7074 23.4524L9.02858 26.1361L15.1965 31.1206L15.5893 37L3.64139 26.2641Z" fill="#5B94CC" />
        <path d="M7.7587 15.7313L5.30908 14.0583L6.5271 21.5997L8.70742 23.4524L7.7587 15.7313Z" fill="#67C1EC" />
        <path d="M0.5 10.9281L4.6037 9.20218L5.30906 14.0586L0.5 10.9281Z" fill="#60B0E0" />
        <path d="M8.70721 7.5412L11.4867 9.08369L7.16802 10.9278L7.7585 15.7313L5.30888 14.0583L4.60352 9.20186L8.70721 7.5412Z" fill="#76C8ED" />
        <path d="M3.77002 4.72821L4.60385 9.20179L8.70755 7.54113L3.77002 4.72821Z" fill="#3775B7" />
        <path d="M16.2103 4.72821L18.839 5.94324L11.4865 9.08238L8.70703 7.54112L16.2103 4.72821Z" fill="#4BBEEB" />
        <path d="M29.001 7.54136L33.9089 4.72968L33.3307 9.20203L29.001 7.54136Z" fill="#3775B7" />
        <path d="M21.5967 4.7284L18.8395 5.94344L16.2108 4.7284L8.70749 7.54132L3.76996 4.7284L18.9037 0L33.9078 4.7284L29.0012 7.54132" fill="#008ED0" />
        <path d="M18.833 34.0604L22.471 31.1207V37.0001L18.833 34.0604Z" fill="#60B0E0" />
        <path d="M15.1963 31.1205V28.3629L18.8392 31.1205L22.471 28.2805V31.1205L18.833 34.0602L15.1963 31.1205Z" fill="#5B94CC" />
        <path d="M22.4712 31.1207L28.7441 26.1362L29.001 23.364L22.4712 28.2807V31.1207Z" fill="#4576B6" />
        <path d="M21.5325 4.72821L18.9038 5.94324L26.2564 9.08238L29.0346 7.54112L21.5325 4.72821Z" fill="#4BBEEB" />
        <defs>
            <linearGradient id="paint0_linear_201_53942" x1="18.8444" y1="18.0288" x2="18.972" y2="5.80692" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B3CBDA" />
                <stop offset="0.01" stopColor="#B3CBDA" />
                <stop offset="0.44" stopColor="#DBE6ED" />
                <stop offset="0.79" stopColor="#F5F8FA" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient id="paint1_linear_201_53942" x1="20.3259" y1="18.7263" x2="30.6322" y2="24.4873" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B3CBDA" />
                <stop offset="0.01" stopColor="#B3CBDA" />
                <stop offset="0.44" stopColor="#DBE6ED" />
                <stop offset="0.79" stopColor="#F5F8FA" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient id="paint2_linear_201_53942" x1="8.97583" y1="24.1151" x2="18.2539" y2="17.0764" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" />
                <stop offset="0.21" stopColor="#F5F8FA" />
                <stop offset="0.56" stopColor="#DBE6ED" />
                <stop offset="0.99" stopColor="#B3CBDA" />
                <stop offset="1" stopColor="#B3CBDA" />
            </linearGradient>
        </defs>
    </SvgIcon>
)

export default TMS