import {
    postOrderLoading,
    postOrderRequest,
    postOrderSuccess,
    postOrderReject,
    postOrderReset
} from "../../actions/order/post";
import {
    getOrderIdRequest,
    getOrderIdSuccess
} from "../../actions/order/getId"

export const postOrder = (formData) => async (dispatch, getState) => {
    dispatch(postOrderLoading());
    try {
        const { data } = await postOrderRequest(formData, getState);
        const id = data?.id
        
        const getId = await getOrderIdRequest({id}, getState);
        
        await dispatch(getOrderIdSuccess(getId?.data))

        dispatch(postOrderSuccess(data))
    } catch (error) {
        dispatch(postOrderReject(error))
    } finally {
        setTimeout(() => { dispatch(postOrderReset()) }, 3000);
    }
    return Promise.resolve();
};