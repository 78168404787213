import {
    postTransportLoading,
    postTransportRequest,
    postTransportSuccess,
    postTransportReject,
    postTransportReset
} from "../../actions/transport/post";

export const postTransport = (formData) => async (dispatch, getState) => {
    dispatch(postTransportLoading());
    try {
        const { data } = await postTransportRequest(formData, getState);
        dispatch(postTransportSuccess(data))
    } catch (error) {
        dispatch(postTransportReject(error))
    } finally {
        setTimeout(() => { dispatch(postTransportReset()) }, 3000);
    }
    return Promise.resolve();
};