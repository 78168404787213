import { concat } from "lodash";

import transportBrand from "./transportBrand";
import transportSupplier from "./transportSupplier";
import cost from "./cost";

import Config from "../../../../pages/Config";

const routesConfig = [{ key: "config", path: "/config", element: <Config />, }]

const routes = concat(
    routesConfig,
    transportBrand,
    transportSupplier,
    cost,
)

export default routes