import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_DELIVERY_POINT_RESET = 'POST_DELIVERY_POINT_RESET';
export const POST_DELIVERY_POINT_LOADING = 'POST_DELIVERY_POINT_LOADING';
export const POST_DELIVERY_POINT_SUCCESS = 'POST_DELIVERY_POINT_SUCCESS';
export const POST_DELIVERY_POINT_REJECT = 'POST_DELIVERY_POINT_REJECT';

//* ACTIONS ------------------------------------------------
export const postDeliveryPointReset = () => ({ type: POST_DELIVERY_POINT_RESET });
export const postDeliveryPointLoading = () => ({ type: POST_DELIVERY_POINT_LOADING });
export const postDeliveryPointSuccess = (payload) => ({ type: POST_DELIVERY_POINT_SUCCESS, payload });
export const postDeliveryPointReject = (payload) => ({ type: POST_DELIVERY_POINT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postDeliveryPointRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/DeliveryPoints`, options);
};


