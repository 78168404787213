import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_TRANSPORT_BRAND_RESET = 'POST_TRANSPORT_BRAND_RESET';
export const POST_TRANSPORT_BRAND_LOADING = 'POST_TRANSPORT_BRAND_LOADING';
export const POST_TRANSPORT_BRAND_SUCCESS = 'POST_TRANSPORT_BRAND_SUCCESS';
export const POST_TRANSPORT_BRAND_REJECT = 'POST_TRANSPORT_BRAND_REJECT';

//* ACTIONS ------------------------------------------------
export const postTransportBrandReset = () => ({ type: POST_TRANSPORT_BRAND_RESET });
export const postTransportBrandLoading = () => ({ type: POST_TRANSPORT_BRAND_LOADING });
export const postTransportBrandSuccess = (payload) => ({ type: POST_TRANSPORT_BRAND_SUCCESS, payload });
export const postTransportBrandReject = (payload) => ({ type: POST_TRANSPORT_BRAND_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postTransportBrandRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/TransportBrand`, options);
};


