import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            routeName: yup.string().required(__(`form.routeName.error`)),
            idRouteStatus: yup.string().required(__(`form.idRouteStatus.error`)),
            routeStatus: yup.string().required(__(`form.routeStatus.error`)),
            idStartDP: yup.string().required(__(`form.idStartDP.error`)),
            // idReturnDP: yup.string().required(__(`form.idReturnDP.error`)),
            idTransport: yup.string().required(__(`form.idTransport.error`)),
            idDriver: yup.string().required(__(`form.idDriver.error`)),
            // startDate: yup.string().required(__(`form.startDate.error`)),
            dateToRoute: yup.string().required(__(`form.dateToRoute.error`)),
            // comments: yup.string().required(__(`form.comments.error`)),
        })
    )
}

export default validators
