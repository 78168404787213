/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Typography, Snackbar, AlertTitle, Alert as MuiAlert, Button } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ __, showNoti, setShowNoti, timer = 6000 }) => {
    useEffect(() => {
        const time = setTimeout(() => {
            setShowNoti({ open: false, msg: undefined, variant: "success", action: "" })
        }, timer);

        return () => {
            clearTimeout(time)
        }
    }, [showNoti.open])


    const handleClose = () => {
        setShowNoti({ open: false, msg: undefined, variant: "success", action: "" });
    };

    const getVariant = () => {
        const variant = showNoti.variant;
        switch (variant) {
            case "error": return { severity: variant, sx: { width: '100%', color: "error.dark", bgcolor: "common.white" } }
            case "info": return { severity: variant, sx: { width: '100%', color: "info.dark", bgcolor: "common.white" } }
            case "success": return { severity: variant, sx: { width: '100%', color: "success.dark", bgcolor: "common.white" } }
            default: return { severity: variant, sx: { width: '100%', color: "common.white", bgcolor: "common.white" } }
        }
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
                open={showNoti.open}
                onClose={handleClose}
                key={"msg"}
                autoHideDuration={timer}
                sx={{ minWidth: 300 }}
            >
                <Alert {...getVariant()}>
                    <AlertTitle sx={{ minHeight: 20 }} >{showNoti.open ? __(`notification.${showNoti.action}.${showNoti.variant}`) : " "}</AlertTitle>
                    <Typography component="p" variant="caption" color="text.main" >{showNoti.open && showNoti.msg}</Typography>
                    <Button color="secondary" size="small" onClick={handleClose}>{__(`notification.btn`)}</Button>
                </Alert>
            </Snackbar>
        </div >
    );
}

export default Notification