import {
    getOrderItemLoading,
    getOrderItemReject,
    getOrderItemRequest,
    getOrderItemSuccess
} from "../../actions/order/getItem";

export const getOrderItem = (formData) => async (dispatch, getState) => {
    dispatch(getOrderItemLoading());
    try {
        const { data } = await getOrderItemRequest(formData, getState);
        dispatch(getOrderItemSuccess(data))
    } catch (error) {
        dispatch(getOrderItemReject(error))
    }
    return Promise.resolve();
};