import { combineReducers } from "redux";

import client from "./client";
import deliveryPoints from "./deliveryPoints";
import contact from "./contact";
import driver from "./driver";
import transport from "./transport";
import transportBrand from "./transportBrand";
import transportSupplier from "./transportSupplier";
import operators from "./operators";

export default combineReducers({
  client,
  deliveryPoints,
  contact,
  driver,
  transport,
  transportBrand,
  transportSupplier,
  operators
});
