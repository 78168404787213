import {
    postTransportBrandLoading,
    postTransportBrandRequest,
    postTransportBrandSuccess,
    postTransportBrandReject,
    postTransportBrandReset
} from "../../actions/transportBrand/post";

export const postTransportBrand = (formData) => async (dispatch, getState) => {
    dispatch(postTransportBrandLoading());
    try {
        const { data } = await postTransportBrandRequest(formData, getState);
        dispatch(postTransportBrandSuccess(data))
    } catch (error) {
        dispatch(postTransportBrandReject(error))
    } finally {
        setTimeout(() => { dispatch(postTransportBrandReset()) }, 3000);
    }
    return Promise.resolve();
};