import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Stack,
    OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import find from "lodash/find";
import concat from "lodash/concat";
import includes from "lodash/includes";
import trim from "lodash/trim";

import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"

import InputText from "../../../components/form/InputText";
import Table from "../../../components/form/Table";

import { getOrdersToInvoice } from "../../../store/transactions/thunk/invoice/getOrdersToInvoiceAll";

import { postUploadInvoiceDetails } from "../../../store/transactions/thunk/invoice/postUploadInvoiceDetails";

const Alert = ({
    __,
    open,
    close,
    __o,
    ordersSelected
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [selected, setSelected] = useState([])
    const [total, setTotal] = useState([])

    const current = useSelector(state => state.transactions.invoice.detail);
    const order = useSelector(state => state.transactions.invoice.ordersToInvoice);
    const post = useSelector(state => state.transactions.invoice.invoiceDetails);

    const orderAll = get(order, "data", [])

    useEffect(() => {
        dispatch(getOrdersToInvoice({ idClient: get(current, "data.idClient") }))
    }, [])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = map(orderAll, (n) => n.idOrder);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1
    }

    const handleChecked = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const headTable = [
        {
            key: "checkbox",
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < orderAll.length}
                    checked={orderAll.length > 0 && selected.length === orderAll.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                    disabled={get(post, "isLoading")}
                />
            ),
            align: "center",
            width: 70,
        },
        {
            key: "idOrder",
            label: __(`table.colsOrders.idOrder`),
            align: "left"
        },
        {
            key: "docNum",
            label: __(`table.colsOrders.docNum`),
            align: "left"
        },
        {
            key: "total",
            label: __(`table.colsOrders.total`),
            align: "left"
        },
    ]

    const dataTable = map(orderAll, (row, i) => {
        const isItemSelected = isSelected(get(row, "idOrder"));
        const labelId = `enhanced-table-checkbox-${i}`;

        return ({
            ...row,
            checkbox: (
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e, v) => handleChecked(e, get(row, "idOrder"))}
                    inputProps={{ 'aria-labelledby': labelId, }}
                    disabled={get(post, "isLoading")}
                />
            ),
            total: find(selected, (item) => item === row.idOrder) ? <Box sx={{ ".MuiInputBase-root": { height: 25 } }}><InputText name="total" size="small" defaultValue={0} sx={{ width: 75 }} onChange={(e) => setTotal(state => ({ ...state, [row.idOrder]: e.target.value }))} /> </Box> : <div className='w-[75px]' />,
        })
    })

    const postOrderToRoute = () => {
        const body = {
            orders: concat(
                map(selected, (item) => ({
                    idOrder: item,
                    idInvoice: Number(id),
                    total: Number(total[item]) || 0
                })),
                map(ordersSelected, ({ idOrder, total }) => ({ idInvoice: Number(id), idOrder, total }))
            ),
            idInvoice: Number(id),
        }

        dispatch(postUploadInvoiceDetails(body))
        setSelected([])
    }



    return (
        <div>
            <Dialog onClose={close} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {__("labels.3")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Table
                    headTable={headTable}
                    dataTable={dataTable}
                    __={__}
                    sizeFilters={125}
                    loading={get(order, "isLoading", false)}
                    empty="orders"
                    propsTable={{
                        size: "small"
                    }}
                />
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={postOrderToRoute} disabled={get(post, "isLoading") || isEmpty(selected)} loading={get(post, "isLoading")}>{__("action.save")}</LoadingButton>
                    <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={close} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default Alert