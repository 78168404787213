import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_REJECT = 'LOGIN_REJECT';
export const GET_USER_401 = 'GET_USER_401';

//* ACTIONS ------------------------------------------------
export const registerLoading = () => ({ type: LOGIN_LOADING });
export const registerSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });
export const registerReject = (payload) => ({ type: LOGIN_REJECT, payload });
export const getUser401 = (payload) => ({ type: GET_USER_401 });

//* REQUEST SERVICE ---------------------------------------
export const registerRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Auth/RegisterCompany`, options);
};
