/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Stack,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoadingButton } from '@mui/lab';

import LayoutAuth from "../../../components/layout/LayoutAuth";

const Register = () => {

    const [step, setStep] = useState(0);

    const [__] = useTranslation("register");

    const onBack = () => {
        setStep(state => state - 1)
    }

    return (
        <LayoutAuth title={__(`name`)} type={step === 0 ? "register_success" : step === 1 ? "pass" : "shield"} onBack={!!step && onBack}>
            <Box className='flex flex-col justify-center items-center h-full' >
                <Paper className='py-8 px-4 sm:px-8 w-full relative' elevation={3} style={{ minWidth: 360, maxWidth: "calc(100vw - 40px)" }} >
                    <Box className='mb-2 text-center' ><Typography variant='h3'>{__(`titleSuccess`)}</Typography></Box>
                    <Box className='mb-4 text-center' ><Typography variant='caption'>{__(`subtitleSuccess`)}</Typography></Box>
                    <Stack className='mt-8'>
                        <LoadingButton
                            color="secondary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            size='large'
                            LinkComponent={Link}
                            to="/"
                        >
                            {__(`button.success`)}
                        </LoadingButton>
                    </Stack>
                </Paper>
            </Box>
        </LayoutAuth>
    )
}

export default Register