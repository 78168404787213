import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_REPORT_ORDERS_LOADING = 'GET_REPORT_ORDERS_LOADING';
export const GET_REPORT_ORDERS_SUCCESS = 'GET_REPORT_ORDERS_SUCCESS';
export const GET_REPORT_ORDERS_REJECT = 'GET_REPORT_ORDERS_REJECT';

//* ACTIONS ------------------------------------------------
export const getReportOrdersLoading = () => ({ type: GET_REPORT_ORDERS_LOADING });
export const getReportOrdersSuccess = (payload) => ({ type: GET_REPORT_ORDERS_SUCCESS, payload });
export const getReportOrdersReject = (payload) => ({ type: GET_REPORT_ORDERS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getReportOrdersRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Reports/GetAllOrdersBaseReport`, options);
};

