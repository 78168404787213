import {
    GET_ROUTE_LOADING,
    GET_ROUTE_SUCCESS,
    GET_ROUTE_REJECT,
} from "../../actions/route/getAll";

import {
    GET_ROUTE_ID_LOADING,
    GET_ROUTE_ID_SUCCESS,
    GET_ROUTE_ID_REJECT,
} from "../../actions/route/getId";

import {
    GET_ROUTE_ORDER_ALL_LOADING,
    GET_ROUTE_ORDER_ALL_SUCCESS,
    GET_ROUTE_ORDER_ALL_REJECT,
} from "../../actions/route/getOrderAll";

import {
    GET_ROUTE_COST_ALL_LOADING,
    GET_ROUTE_COST_ALL_SUCCESS,
    GET_ROUTE_COST_ALL_REJECT,
} from "../../actions/route/getCostAll";

import {
    GET_ROUTE_ALL_COORDS_LOADING,
    GET_ROUTE_ALL_COORDS_SUCCESS,
    GET_ROUTE_ALL_COORDS_REJECT,
} from "../../actions/route/getAllCoords";

import {
    GET_ROUTE_ALL_COORDS_ROUTE_LOADING,
    GET_ROUTE_ALL_COORDS_ROUTE_SUCCESS,
    GET_ROUTE_ALL_COORDS_ROUTE_REJECT,
} from "../../actions/route/getAllCoordsRoute";

import {
    GET_ROUTE_HOME_LOADING,
    GET_ROUTE_HOME_SUCCESS,
    GET_ROUTE_HOME_REJECT,
} from "../../actions/route/getDashboard";

import {
    GET_ROUTE_TRACKING_LOADING,
    GET_ROUTE_TRACKING_SUCCESS,
    GET_ROUTE_TRACKING_REJECT,
} from "../../actions/route/trackingRoute";

import {
    POST_ROUTE_ORDER_LOADING,
    POST_ROUTE_ORDER_SUCCESS,
    POST_ROUTE_ORDER_REJECT,
    POST_ROUTE_ORDER_RESET,
} from "../../actions/route/postOrders";

import {
    PUT_ROUTE_ORDER_LOADING,
    PUT_ROUTE_ORDER_SUCCESS,
    PUT_ROUTE_ORDER_REJECT,
    PUT_ROUTE_ORDER_RESET,
} from "../../actions/route/putOrders";

import {
    POST_ROUTE_COST_LOADING,
    POST_ROUTE_COST_SUCCESS,
    POST_ROUTE_COST_REJECT,
    POST_ROUTE_COST_RESET,
} from "../../actions/route/postCost";

import {
    PUT_ROUTE_COST_LOADING,
    PUT_ROUTE_COST_SUCCESS,
    PUT_ROUTE_COST_REJECT,
    PUT_ROUTE_COST_RESET,
} from "../../actions/route/putCost";

import {
    POST_ROUTE_LOADING,
    POST_ROUTE_SUCCESS,
    POST_ROUTE_REJECT,
    POST_ROUTE_RESET,
} from "../../actions/route/post";

import {
    PUT_ROUTE_LOADING,
    PUT_ROUTE_SUCCESS,
    PUT_ROUTE_REJECT,
    PUT_ROUTE_RESET,
} from "../../actions/route/put";

import {
    PUT_LIQ_ORDER_ROUTE_LOADING,
    PUT_LIQ_ORDER_ROUTE_SUCCESS,
    PUT_LIQ_ORDER_ROUTE_REJECT,
    PUT_LIQ_ORDER_ROUTE_RESET,
} from "../../actions/route/putLiquidate";

import {
    DELETE_ROUTE_LOADING,
    DELETE_ROUTE_SUCCESS,
    DELETE_ROUTE_REJECT,
    DELETE_ROUTE_RESET,
} from "../../actions/route/delete";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
    detail: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    orderAll: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    costAll: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    coordsAll: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    coordsAllRoute: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    dashboard: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    tracking: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    postOrder: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    putOrder: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    postCost: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    putCost: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    post: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    put: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    putLiquidateOrder: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    delete: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}

const routeReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_ROUTE_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_ROUTE_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_ROUTE_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        // ------------- GET ID ID --------------------
        case GET_ROUTE_ID_LOADING: return {
            ...state,
            detail: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_ROUTE_ID_SUCCESS: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_ID_REJECT: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }

        // ------------- GET ORDER ALL --------------------
        case GET_ROUTE_ORDER_ALL_LOADING: return {
            ...state,
            orderAll: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_ORDER_ALL_SUCCESS: return {
            ...state,
            orderAll: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_ORDER_ALL_REJECT: return {
            ...state,
            orderAll: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- GET COST ALL --------------------
        case GET_ROUTE_COST_ALL_LOADING: return {
            ...state,
            costAll: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_COST_ALL_SUCCESS: return {
            ...state,
            costAll: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_COST_ALL_REJECT: return {
            ...state,
            costAll: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }
        // ------------- GET ORDER ALL --------------------
        case GET_ROUTE_ALL_COORDS_LOADING: return {
            ...state,
            coordsAll: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_ALL_COORDS_SUCCESS: return {
            ...state,
            coordsAll: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_ALL_COORDS_REJECT: return {
            ...state,
            coordsAll: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }
        // ------------- GET ORDER ALL ROUTE --------------------
        case GET_ROUTE_ALL_COORDS_ROUTE_LOADING: return {
            ...state,
            coordsAllRoute: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_ALL_COORDS_ROUTE_SUCCESS: return {
            ...state,
            coordsAllRoute: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_ALL_COORDS_ROUTE_REJECT: return {
            ...state,
            coordsAllRoute: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- GET DASHBOARD --------------------
        case GET_ROUTE_HOME_LOADING: return {
            ...state,
            dashboard: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_HOME_SUCCESS: return {
            ...state,
            dashboard: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_HOME_REJECT: return {
            ...state,
            dashboard: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- GET TRACKING --------------------
        case GET_ROUTE_TRACKING_LOADING: return {
            ...state,
            tracking: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ROUTE_TRACKING_SUCCESS: return {
            ...state,
            tracking: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ROUTE_TRACKING_REJECT: return {
            ...state,
            tracking: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }


        // ------------- POST ORDER --------------------
        case POST_ROUTE_ORDER_LOADING: return {
            ...state,
            postOrder: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_ROUTE_ORDER_SUCCESS: return {
            ...state,
            postOrder: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_ROUTE_ORDER_REJECT: return {
            ...state,
            postOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_ROUTE_ORDER_RESET: return {
            ...state,
            postOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        // ------------- PUT ORDER --------------------
        case PUT_ROUTE_ORDER_LOADING: return {
            ...state,
            putOrder: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_ROUTE_ORDER_SUCCESS: return {
            ...state,
            putOrder: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_ROUTE_ORDER_REJECT: return {
            ...state,
            putOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_ROUTE_ORDER_RESET: return {
            ...state,
            putOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- POST COST --------------------
        case POST_ROUTE_COST_LOADING: return {
            ...state,
            postCost: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_ROUTE_COST_SUCCESS: return {
            ...state,
            postCost: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_ROUTE_COST_REJECT: return {
            ...state,
            postCost: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_ROUTE_COST_RESET: return {
            ...state,
            postCost: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        // ------------- PUT COST --------------------
        case PUT_ROUTE_COST_LOADING: return {
            ...state,
            putCost: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_ROUTE_COST_SUCCESS: return {
            ...state,
            putCost: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_ROUTE_COST_REJECT: return {
            ...state,
            putCost: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_ROUTE_COST_RESET: return {
            ...state,
            putCost: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- POST --------------------
        case POST_ROUTE_LOADING: return {
            ...state,
            post: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_ROUTE_SUCCESS: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_ROUTE_REJECT: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_ROUTE_RESET: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT --------------------
        case PUT_ROUTE_LOADING: return {
            ...state,
            put: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_ROUTE_SUCCESS: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_ROUTE_REJECT: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_ROUTE_RESET: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        // ------------- PUT_LIQ_ORDER_ROUTE --------------------
        case PUT_LIQ_ORDER_ROUTE_LOADING: return {
            ...state,
            putLiquidateOrder: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_LIQ_ORDER_ROUTE_SUCCESS: return {
            ...state,
            putLiquidateOrder: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_LIQ_ORDER_ROUTE_REJECT: return {
            ...state,
            putLiquidateOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_LIQ_ORDER_ROUTE_RESET: return {
            ...state,
            putLiquidateOrder: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE --------------------
        case DELETE_ROUTE_LOADING: return {
            ...state,
            delete: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_ROUTE_SUCCESS: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_ROUTE_REJECT: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_ROUTE_RESET: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        default: return state;
    }
}

export default routeReducer