import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_OPERATORS_LOADING = 'GET_OPERATORS_LOADING';
export const GET_OPERATORS_SUCCESS = 'GET_OPERATORS_SUCCESS';
export const GET_OPERATORS_REJECT = 'GET_OPERATORS_REJECT';

//* ACTIONS ------------------------------------------------
export const getOperatorsLoading = () => ({ type: GET_OPERATORS_LOADING });
export const getOperatorsSuccess = (payload) => ({ type: GET_OPERATORS_SUCCESS, payload });
export const getOperatorsReject = (payload) => ({ type: GET_OPERATORS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOperatorsRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Users/GetListOperators`, options);
};