import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_TRANSPORT_SUPPLIER_LOADING = 'GET_TRANSPORT_SUPPLIER_LOADING';
export const GET_TRANSPORT_SUPPLIER_SUCCESS = 'GET_TRANSPORT_SUPPLIER_SUCCESS';
export const GET_TRANSPORT_SUPPLIER_REJECT = 'GET_TRANSPORT_SUPPLIER_REJECT';

//* ACTIONS ------------------------------------------------
export const getTransportSupplierLoading = () => ({ type: GET_TRANSPORT_SUPPLIER_LOADING });
export const getTransportSupplierSuccess = (payload) => ({ type: GET_TRANSPORT_SUPPLIER_SUCCESS, payload });
export const getTransportSupplierReject = (payload) => ({ type: GET_TRANSPORT_SUPPLIER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getTransportSupplierRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TransportSuppliers`, options);
};

