/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import isEmpty from "lodash/isEmpty";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import validator from "./validator"

import { postClient } from "../../../store/masters/thunk/client/post"

const NewEdit = () => {
    const formikRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("client");
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });

    const post = useSelector(state => state.masters.client.post);
    const contact = useSelector(state => state.masters.contact.data);
    const postContact = useSelector(state => state.masters.contact.post);
    const putContact = useSelector(state => state.masters.contact.put);


    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                isDefault: true,
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/masters/clients/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(postContact, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); }
        if (get(postContact, "isReject", false)) { setError(get(postContact, "error", {}), "post") }
        if (get(putContact, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(putContact, "isReject", false)) { setError(get(putContact, "error", {}), "put") }
    }, [post, postContact, putContact])


    const onSubmit = (values) => {
        const whitOutNulls = pickBy(values, identity);
        dispatch(postClient(whitOutNulls))
    }

    const initialValues = {
        isActive: true,
        idClient: 0,
        name: "",
        idContact: "",
        phone: "",
        state: "",
        city: "",
        address: "",
    }

    return (
        <Layout
            propsToolbar={{
                title: __(`head.new.name`),
                srute: __(`head.new.code`)
            }}
        >
            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                {formik => (
                    <form id="form-client" onSubmit={get(formik, "handleSubmit")}>
                        <Paper className='p-4'>
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <LoadingButton variant="contained" color="secondary" type="submit" loading={get(post, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                <Button variant='outlined' color="secondary" onClick={() => navigate("/masters/clients")} disabled={get(post, "isLoading")} >{__(`action.cancel`)}</Button>
                            </Stack>
                        </Paper>
                        <Paper className='mt-4'>
                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                <Switch formik={formik} name="isActive" __={__} />
                            </Stack>
                            <Divider />
                            <Box className='p-4' >
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={6} >
                                        <InputText
                                            formik={formik}
                                            name="name"
                                            label={__(`form.name.label`)}
                                            placeholder={__(`form.name.placeholder`)}
                                            disabled={get(post, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.3`)}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <InputText
                                            formik={formik}
                                            name="state"
                                            label={__(`form.state.label`)}
                                            placeholder={__(`form.state.placeholder`)}
                                            disabled={get(post, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <InputText
                                            formik={formik}
                                            name="city"
                                            label={__(`form.city.label`)}
                                            placeholder={__(`form.city.placeholder`)}
                                            disabled={get(post, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputText
                                            formik={formik}
                                            name="address"
                                            label={__(`form.address.label`)}
                                            placeholder={__(`form.address.placeholder`)}
                                            disabled={get(post, "isLoading", false)}
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </form>
                )}
            </Formik>
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                __={__}
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </Layout>
    )
}

export default NewEdit
