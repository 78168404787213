import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_LIQ_ORDER_ROUTE_RESET = 'PUT_LIQ_ORDER_ROUTE_RESET';
export const PUT_LIQ_ORDER_ROUTE_LOADING = 'PUT_LIQ_ORDER_ROUTE_LOADING';
export const PUT_LIQ_ORDER_ROUTE_SUCCESS = 'PUT_LIQ_ORDER_ROUTE_SUCCESS';
export const PUT_LIQ_ORDER_ROUTE_REJECT = 'PUT_LIQ_ORDER_ROUTE_REJECT';

//* ACTIONS ------------------------------------------------
export const putLiqOrderRouteReset = () => ({ type: PUT_LIQ_ORDER_ROUTE_RESET });
export const putLiqOrderRouteLoading = () => ({ type: PUT_LIQ_ORDER_ROUTE_LOADING });
export const putLiqOrderRouteSuccess = (payload) => ({ type: PUT_LIQ_ORDER_ROUTE_SUCCESS, payload });
export const putLiqOrderRouteReject = (payload) => ({ type: PUT_LIQ_ORDER_ROUTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putLiqOrderRouteRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data: {},
    }, getState);
    return request(`/api/Order/LiquidateOrderStatus/${data.idOrder}/${data.idRoute}`, options);
};
