import React from 'react'
import {
    Paper,
    Grid,
    Skeleton,
    Stack
} from '@mui/material';

const SkeletonComponent = () => {
    return (
        <>
            <Paper className='p-4 mb-4'>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Skeleton className='mb-2' variant="rounded" width={100} height={40} />
                    <Skeleton variant="rounded" width={100} height={40} />
                </Stack>
            </Paper>
            <Paper className='p-4'>
                <Skeleton className='mb-8' variant="rounded" width={300} height={30} />
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton className='mb-2' variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"100%"} height={30} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default SkeletonComponent