import {
    putInvoiceLoading,
    putInvoiceRequest,
    putInvoiceSuccess,
    putInvoiceReject,
    putInvoiceReset
} from "../../actions/invoice/put";

export const putInvoice = (formData) => async (dispatch, getState) => {
    dispatch(putInvoiceLoading());
    try {
        const { data } = await putInvoiceRequest(formData, getState);
        dispatch(putInvoiceSuccess(data))
    } catch (error) {
        dispatch(putInvoiceReject(error))
    } finally {
        setTimeout(() => { dispatch(putInvoiceReset()) }, 3000);
    }
    return Promise.resolve();
};