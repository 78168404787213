import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_REJECT,
    UPDATE_DATA
} from "../../actions/login";

import {
    GET_VALIDATE_EMAIL_LOADING,
    GET_VALIDATE_EMAIL_SUCCESS,
    GET_VALIDATE_EMAIL_REJECT,
} from "../../actions/getValidate";

import {
    GET_USER_401
} from "../../actions/register";

import {
    GET_PROFILE_LOADING,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_REJECT,
    // GET_PROFILE_RESET,
} from "../../actions/getProfile";

import {
    LOGOUT_SUCCESS,
    LOGOUT_REDIRECT
} from "../../actions/logout"

import {
    PUT_PASS_ADMIN_LOADING,
    PUT_PASS_ADMIN_SUCCESS,
    PUT_PASS_ADMIN_REJECT,
    PUT_PASS_ADMIN_RESET,
} from "../../actions/putPass";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    failUser: false,
    isLogged: false,
    forbidden: false,
    token: null,
    dataUser: {},
    allResp: {},
    validateFail: false,
    putPass: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}


const loginReducer = (state = stateInit, action) => {
    switch (action.type) {
        case LOGIN_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            failUser: false,
            isLogged: false,
            forbidden: false,
            token: null,
            dataUser: {},
            allResp: {}
        }
        case LOGIN_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            failUser: false,
            isLogged: true,
            forbidden: false,
            token: null,
            dataUser: action.payload.data,
            allResp: action.payload
        }
        case LOGIN_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            failUser: false,
            isLogged: false,
            forbidden: false,
            token: null,
            dataUser: {},
            allResp: action.payload
        }

        // ------------- UPDATE DATA USER --------------------
        case UPDATE_DATA: return {
            ...state,
            ...action.payload,
        }

        // ------------- Validate user --------------------
        case GET_VALIDATE_EMAIL_LOADING: return {
            ...state,
            validateFail: false,
        }
        case GET_VALIDATE_EMAIL_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                validateFail: false,
            }
        }
        case GET_VALIDATE_EMAIL_REJECT: return {
            ...state,
            validateFail: true,
        }

        // ------------- Profile --------------------
        case GET_PROFILE_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            failUser: false,
            dataUser: {
                ...state.dataUser,
            }
        }
        case GET_PROFILE_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            forbidden: false,
            failUser: false,
            dataUser: {
                ...state.dataUser,
                ...action.payload.data
            }
        }
        case GET_PROFILE_REJECT: return {
            ...state,
            isLoading: false,
            forbidden: false,
            isSuccess: false,
            isReject: true,
            failUser: false,
            dataUser: {
                ...state.dataUser,
            }
        }
        // ------------- Register --------------------
        case GET_USER_401: return {
            ...state,
            isLoading: false,
            forbidden: false,
            isSuccess: false,
            isReject: false,
            failUser: true,
            isLogged: false,
            token: null,
            dataUser: {},
            allResp: {}
        }
        case LOGOUT_REDIRECT: return {
            ...stateInit,
            forbidden: true,
        }

        // ------------- PUT PASS --------------------
        case PUT_PASS_ADMIN_LOADING: return {
            ...state,
            putPass: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_PASS_ADMIN_SUCCESS: return {
            ...state,
            putPass: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_PASS_ADMIN_REJECT: return {
            ...state,
            putPass: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_PASS_ADMIN_RESET: return {
            ...state,
            putPass: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- LOGOUT --------------------

        case LOGOUT_SUCCESS: return stateInit

        default: return state;
    }
}

export default loginReducer
