import { Navigate } from "react-router-dom";

const routesError = [
    {
        key: "404",
        path: "*",
        element: <Navigate to="/" />,
    },
]

export default routesError