/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import moment from "moment";

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import DatePickerUi from "../../../components/form/DatePickerUi";
import validator from "./validator"
import Skeleton from "../../../components/form/Skeleton"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import AutoComplete from "../../../components/form/AutoComplete";
import PhotoModal from "./PhotoModal";
import { PageSize } from "../../../config/const"

import { getOrderItem } from "../../../store/transactions/thunk/order/getItem";
import { getOrderId } from "../../../store/transactions/thunk/order/getId"
import { postOrder } from "../../../store/transactions/thunk/order/post"
import { putOrder } from "../../../store/transactions/thunk/order/put"
import { getClient } from "../../../store/masters/thunk/client/getAll";
import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";

const NewEdit = ({ setBtns }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("order");
    const [searchParams] = useSearchParams();
    const { id } = useParams();

    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [isMount, setIsMount] = useState(false);
    const [showGalery, setShowGalery] = useState(false);

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.order.detail);
    const put = useSelector(state => state.transactions.order.put);
    const post = useSelector(state => state.transactions.order.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const client = useSelector(state => state.masters.client);
    const item = useSelector(state => state.transactions.order.getItem.data);

    const orderOperativeStatus = __(`orderOperativeStatus`, { returnObjects: true });
    const orderStatus = __(`orderStatus`, { returnObjects: true });
    const clients = map(client?.data, ({ idClient, name }) => ({ value: idClient, label: name }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}` }));

    const isActive = "isActive==true";

    const getDataClient = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getClient(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `deliveryName@=${search},${isActive}`
        dispatch(getDeliveryPoint(params))
    }

    const getItems = ({ page }) => {
        const Filters = encodeURI(`idOrder==${id}`)
        const parmas = { page, Filters, PageSize }
        dispatch(getOrderItem(parmas))
    }

    useEffect(() => {
        if ((searchParams.get('from') !== "new")) {
            if (!!id) {
                dispatch(getOrderId({ id }))
            }
            getDataClient()
            getDataDelivery()
        } else {
            setIsMount(true)
        }
        getItems({ page: 1 })
    }, [dispatch, id, searchParams.get('from')])

    useEffect(() => {
        if ((get(current, "data.idOrderOperativeStatus", 1) !== 1) && isMount) {
            navigate("/")
        }
    }, [current, isMount])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
    }, [put, post])

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                <Button className='w-[100px] min-w-[100px]' variant='text' color="secondary" onClick={() => setShowGalery(true)} disabled={get(put, "isLoading")} ><div className='whitespace-nowrap'>{__(`action.showGalery`)}</div></Button>
                <LoadingButton variant="contained" color="secondary" type="submit" form="form-order" loading={get(put, "isLoading")}>{id ? __(`action.save`) : __(`action.new`)}</LoadingButton>
                <Button variant='outlined' color="secondary" onClick={() => navigate(-1)} disabled={get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [get(put, "isLoading")])

    const submitPut = (values) => {
        const body = {
            ...values,
            creationDate: get(values, "creationDate"),
            deliveryComments: get(values, "deliveryComments"),
            deliveryDate: get(values, "deliveryDate"),
            docNum: get(values, "docNum"),
            idClient: Number(get(values, "idClient")),
            idDeliveryPoint: Number(get(values, "idDeliveryPoint")),
            idOrder: get(values, "idOrder"),
            idOrderOperativeStatus: Number(get(values, "idOrderOperativeStatus")),
            idOrderStatus: Number(get(values, "idOrderStatus")),
            idUser: get(values, "idUser"),
            isActive: get(values, "isActive"),
        }
        const whitOutNulls = pickBy(body, identity);
        dispatch(putOrder(whitOutNulls))
    }

    const onSubmit = (values) => {
        const idOrder = id;
        if (idOrder) { submitPut({ ...values, deliveryDate: moment(values.deliveryDate).format() }) }
        else { dispatch(postOrder({ ...values, deliveryDate: moment(values.deliveryDate).format() })) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,
        docNum: id ? get(current, "data.docNum", "") : "",
        deliveryDate: moment(get(current, "data.deliveryDate")),
        idOrderStatus: id ? get(current, "data.idOrderStatus", 1) : 1,
        idOrderOperativeStatus: id ? get(current, "data.idOrderOperativeStatus", 1) : 1,
        idClient: id ? get(current, "data.idClient", "") : "",
        idDeliveryPoint: id ? get(current, "data.idDeliveryPoint", "") : "",

        creationDate: get(current, "data.creationDate", ""),
        deliveryComments: id ? get(current, "data.deliveryComments", "") : "",

        idUser: get(user, "idUser"),
        idOrder: id ? get(current, "data.idOrder", "") : "",
    }



    return (
        <div>
            {get(current, "isLoading") || (get(deliveryPoint, "isLoading") && !isMount) || (get(client, "isLoading") && !isMount)
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form id="form-order" onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.2`)}</Typography>
                                        <Switch formik={formik} name="isActive" __={__} />
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="docNum"
                                                    label={__(`form.docNum.label`)}
                                                    placeholder={__(`form.docNum.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <DatePickerUi
                                                    formik={formik}
                                                    name="deliveryDate"
                                                    label={__(`form.deliveryDate.label`)}
                                                    placeholder={__(`form.deliveryDate.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idOrderStatus"}
                                                    formik={formik}
                                                    label={__(`form.idOrderStatus.label`)}
                                                    placeholder={__(`form.idOrderStatus.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={orderStatus}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idOrderOperativeStatus"}
                                                    formik={formik}
                                                    label={__(`form.idOrderOperativeStatus.label`)}
                                                    placeholder={__(`form.idOrderOperativeStatus.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={orderOperativeStatus}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idClient"}
                                                    formik={formik}
                                                    label={__(`form.idClient.label`)}
                                                    placeholder={__(`form.idClient.placeholder`)}
                                                    disabled={!isEmpty(item) || get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={clients}
                                                    reload={getDataClient}
                                                    loading={get(client, "isLoading")}
                                                    setIsMount={setIsMount}
                                                    {...(isEmpty(item) ? {} : { inputProps: { helperText: __("labels.3") } })}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idDeliveryPoint"}
                                                    formik={formik}
                                                    label={__(`form.idDeliveryPoint.label`)}
                                                    placeholder={__(`form.idDeliveryPoint.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={deliveryPoints}
                                                    reload={getDataDelivery}
                                                    loading={get(deliveryPoint, "isLoading")}
                                                    setIsMount={setIsMount}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} >
                                                <InputText
                                                    formik={formik}
                                                    name="deliveryComments"
                                                    label={__(`form.deliveryComments.label`)}
                                                    placeholder={__(`form.deliveryComments.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
            <PhotoModal
                __={__}
                open={showGalery}
                close={() => setShowGalery(false)}
            />
        </div>
    )
}

export default NewEdit