/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Paper,
    Grid,
    Box,
    Tabs,
    Tab,
} from '@mui/material';

import Layout from "../../../components/layout/Layout";
import Form from "./Form"
import Items from "./Items"
import Contact from "./Contact"

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </div>
    );
}

const NewEdit = () => {
    const [__] = useTranslation("client");
    const [value, setValue] = React.useState(0);
    const [btns, setBtns] = useState(<></>)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout
            propsToolbar={{
                title: __(`head.edit.name`),
                srute: __(`head.edit.code`),
            }}
        >
            <Box display="block">
                <Box className="ml-4 mr-0">
                    <Grid className="py-2" container component={Paper} spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label={__(`tabs.orders`)} />
                                <Tab label={__(`tabs.contacts`)} />
                                <Tab label={__(`tabs.items`)} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {btns}
                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value={value} index={0}>
                    <Form setBtns={setBtns} __={__} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Contact setBtns={setBtns} __={__} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Items setBtns={setBtns} __={__} />
                </TabPanel>
            </Box>
        </Layout >
    )
}

export default NewEdit