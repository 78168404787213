import {
    getTransportBrandIdLoading,
    getTransportBrandIdRequest,
    getTransportBrandIdSuccess,
    getTransportBrandIdReject,
} from "../../actions/transportBrand/getId";

export const getTransportBrandId = (formData) => async (dispatch, getState) => {
    dispatch(getTransportBrandIdLoading());
    try {
        const { data } = await getTransportBrandIdRequest(formData, getState);
        dispatch(getTransportBrandIdSuccess(data))
    } catch (error) {
        dispatch(getTransportBrandIdReject(error))
    }
    return Promise.resolve();
};