import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ORDERS_TO_INVOICE_LOADING = 'GET_ORDERS_TO_INVOICE_LOADING';
export const GET_ORDERS_TO_INVOICE_SUCCESS = 'GET_ORDERS_TO_INVOICE_SUCCESS';
export const GET_ORDERS_TO_INVOICE_REJECT = 'GET_ORDERS_TO_INVOICE_REJECT';

//* ACTIONS ------------------------------------------------
export const getOrdersToInvoiceLoading = () => ({ type: GET_ORDERS_TO_INVOICE_LOADING });
export const getOrdersToInvoiceSuccess = (payload) => ({ type: GET_ORDERS_TO_INVOICE_SUCCESS, payload });
export const getOrdersToInvoiceReject = (payload) => ({ type: GET_ORDERS_TO_INVOICE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOrdersToInvoiceRequest = async ({ idClient, ...params }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Invoices/GetOrdersToInvoice/${idClient}`, options);
};