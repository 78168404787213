import Login from "../../../../pages/Auth/Login";
import Register from "../../../../pages/Auth/Register";
import RegisterSuccess from "../../../../pages/Auth/Register/RegisterSuccess";
import ForgotPass from "../../../../pages/Auth/ForgotPass";
// import RecoveryPassRequest from "../../../../pages/Auth/RecoveryPassRequest";
// import RecoveryPassReceive from "../../../../pages/Auth/RecoveryPassReceive";

const routesLogout = [
    /* ---------- Auth ---------- */
    {
        key: "login",
        path: "/login",
        element: <Login />,
    },
    {
        key: "register",
        path: "/register",
        element: <Register />,
    },
    {
        key: "register/success",
        path: "/register/success",
        element: <RegisterSuccess />,
    },
    {
        key: "forgot-pass",
        path: "/forgot-pass",
        element: <ForgotPass />,
    },
    // {
    //     key: "recovery-pass-request",
    //     path: "/recovery-pass-request",
    //     element: <RecoveryPassRequest />,
    // },
    // {
    //     key: "recovery-pass-receive",
    //     path: "/recovery-pass-receive",
    //     element: <RecoveryPassReceive />,
    // }
]

export default routesLogout