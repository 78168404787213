import Home from "../../../../pages/Home";
import Profile from "../../../../pages/Auth/Profile";
import Logout from "../../../../pages/Auth/Logout";

const routesAuth = [
    {
        key: "home",
        path: "/",
        element: <Home />,
    },
    {
        key: "profile",
        path: "/profile",
        element: <Profile />,
    },
    {
        key: "logout",
        path: "/logout",
        element: <Logout />,
    },
]

export default routesAuth