import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_CLIENT_ITEM_RESET = 'PUT_CLIENT_ITEM_RESET';
export const PUT_CLIENT_ITEM_LOADING = 'PUT_CLIENT_ITEM_LOADING';
export const PUT_CLIENT_ITEM_SUCCESS = 'PUT_CLIENT_ITEM_SUCCESS';
export const PUT_CLIENT_ITEM_REJECT = 'PUT_CLIENT_ITEM_REJECT';

//* ACTIONS ------------------------------------------------
export const putClientItemReset = () => ({ type: PUT_CLIENT_ITEM_RESET });
export const putClientItemLoading = () => ({ type: PUT_CLIENT_ITEM_LOADING });
export const putClientItemSuccess = (payload) => ({ type: PUT_CLIENT_ITEM_SUCCESS, payload });
export const putClientItemReject = (payload) => ({ type: PUT_CLIENT_ITEM_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putClientItemRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Items/UpdateClientItem`, options);
};
