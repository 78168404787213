import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_OPERATORS_RESET = 'POST_OPERATORS_RESET';
export const POST_OPERATORS_LOADING = 'POST_OPERATORS_LOADING';
export const POST_OPERATORS_SUCCESS = 'POST_OPERATORS_SUCCESS';
export const POST_OPERATORS_REJECT = 'POST_OPERATORS_REJECT';

//* ACTIONS ------------------------------------------------
export const postOperatorsReset = () => ({ type: POST_OPERATORS_RESET });
export const postOperatorsLoading = () => ({ type: POST_OPERATORS_LOADING });
export const postOperatorsSuccess = (payload) => ({ type: POST_OPERATORS_SUCCESS, payload });
export const postOperatorsReject = (payload) => ({ type: POST_OPERATORS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postOperatorsRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Users/InsertOperator`, options);
};
