import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_ORDER_RESET = 'POST_ORDER_RESET';
export const POST_ORDER_LOADING = 'POST_ORDER_LOADING';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_REJECT = 'POST_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const postOrderReset = () => ({ type: POST_ORDER_RESET });
export const postOrderLoading = () => ({ type: POST_ORDER_LOADING });
export const postOrderSuccess = (payload) => ({ type: POST_ORDER_SUCCESS, payload });
export const postOrderReject = (payload) => ({ type: POST_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postOrderRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Order`, options);
};


