/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import isEmpty from "lodash/isEmpty";

import AutoComplete from "../../../components/form/AutoComplete";
import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import validator from "./validator"
import { PageSize } from "../../../config/const"
import DatePickerUi from "../../../components/form/DatePickerUi";

import { postInvoice } from "../../../store/transactions/thunk/invoice/post"
import { getClient } from "../../../store/masters/thunk/client/getAll";

const NewEdit = () => {
    const formikRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("invoice");
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });

    const invoice = useSelector(state => state.transactions.invoice.data);
    const post = useSelector(state => state.transactions.invoice.post);
    const put = useSelector(state => state.transactions.invoice.put);
    const client = useSelector(state => state.masters.client);

    const clients = map(client?.data, ({ idClient, name }) => ({ value: idClient, label: name }));

    const isActive = "isActive==true";

    const getDataClient = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getClient(params))
    }

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                isDefault: true,
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/transactions/invoices/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    }, [post, post, put])


    const onSubmit = (values) => {
        const whitOutNulls = pickBy(values, identity);
        dispatch(postInvoice(whitOutNulls))
    }

    const initialValues = {
        idInvoice: 0,
        idClient: "",
        comments: "",
        dateProcess: null,
    }

    return (
        <Layout
            propsToolbar={{
                title: __(`head.new.name`),
                srute: __(`head.new.code`)
            }}
        >
            <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                {formik => (
                    <form id="form-invoice" onSubmit={get(formik, "handleSubmit")}>
                        <Paper className='p-4'>
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <LoadingButton variant="contained" color="secondary" type="submit" loading={get(post, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                <Button variant='outlined' color="secondary" onClick={() => navigate("/transactions/invoices")} disabled={get(post, "isLoading")} >{__(`action.cancel`)}</Button>
                            </Stack>
                        </Paper>
                        <Paper className='mt-4'>
                            <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                            </Stack>
                            <Divider />
                            <Box className='p-4' >
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={12} sm={6}>
                                        <AutoComplete
                                            name={"idClient"}
                                            formik={formik}
                                            label={__(`form.idClient.label`)}
                                            placeholder={__(`form.idClient.placeholder`)}
                                            disabled={get(post, "isLoading", false) || get(put, "isLoading", false)}
                                            options={clients}
                                            reload={getDataClient}
                                            loading={get(client, "isLoading")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePickerUi
                                            formik={formik}
                                            name="dateProcess"
                                            label={__(`form.dateProcess.label`)}
                                            placeholder={__(`form.dateProcess.placeholder`)}
                                            disabled={get(put, "isLoading", false) || get(put, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <InputText
                                            formik={formik}
                                            name="comments"
                                            label={__(`form.comments.label`)}
                                            placeholder={__(`form.comments.placeholder`)}
                                            disabled={get(post, "isLoading", false)}
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </form>
                )}
            </Formik>
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                __={__}
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </Layout>
    )
}

export default NewEdit
