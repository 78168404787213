import {
    deleteOrderItemLoading,
    deleteOrderItemRequest,
    deleteOrderItemSuccess,
    deleteOrderItemReject,
    deleteOrderItemReset
} from "../../actions/order/deleteItem";

export const deleteOrderItem = (formData) => async (dispatch, getState) => {
    dispatch(deleteOrderItemLoading());
    try {
        const { data } = await deleteOrderItemRequest(formData, getState);
        dispatch(deleteOrderItemSuccess(data))
    } catch (error) {
        dispatch(deleteOrderItemReject(error))
    } finally {
        setTimeout(() => { dispatch(deleteOrderItemReset()) }, 3000);
    }
    return Promise.resolve();
};