/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, isEmpty } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Box,
  Button
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import ItemModal from "./ItemModal"

import { getClientItem } from "../../../store/masters/thunk/client/getItem";
import { deleteClientItem } from "../../../store/masters/thunk/client/deleteItem";

const Item = ({ setBtns }) => {
  const [__] = useTranslation("client");
  const dispatch = useDispatch();
  const { id } = useParams();
  const PageSize = 5

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });
  const [openModal, setOpenModal] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);

  const item = useSelector(state => state.masters.client.item);
  const delet = useSelector(state => state.masters.client.deleteItem);

  const getData = ({ page }) => {
    const Filters = encodeURI(`idClient==${id}`)
    const parmas = { page, Filters, PageSize }
    dispatch(getClientItem(parmas))
  }

  const reload = () => getData({ page: 1 })

  useEffect(() => {
    setAnchorEl(false)
    getData({ page: 1 })
  }, [dispatch])

  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.btn`)}</Button>
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [])

  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  const onDelete = () => {
    dispatch(deleteClientItem({ id: get(selected, "idClientItem") }))
    setSelected({})
  }
  const handleDelete = (row) => () => {
    setAnchorEl(null)
    setOpenQuestion(true)
    setSelected(row)
  }

  //  --------- Delete -------------

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { setOpenQuestion(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delete" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delete") }
  }, [delet])

  const headTable = [
    {
      key: "code",
      label: __(`table.colsItems.erpCode`),
      align: "left",
    },
    {
      key: "name",
      label: __(`table.colsItems.name`),
      align: "left",
    },
    {
      key: "value",
      label: __(`table.colsItems.price`),
      align: "center"
    },
    {
      key: "weight",
      label: __(`table.colsItems.weight`),
      align: "center"
    },
    {
      key: "volume",
      label: __(`table.colsItems.volume`),
      align: "center"
    },
    {
      key: "status",
      label: __(`table.cols.isActive`),
      align: "center"
    },
    {
      key: "options",
      label: __(`table.colsItems.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(item, "data", []), (row, i) => ({
    ...row,
    code: <Box onClick={() => { setSelected(row); setOpenModal(true); setAnchorEl(null) }} sx={{ textDecoration: "underline", color: "primary.main", cursor: "pointer" }} >{get(row, "erpCode")}</Box>,
    status: <Chip label={__(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
    value: `$ ${get(row, "price", 0)?.toFixed(2)}`,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page })

  return (
    <div className='mt-4'>
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(item, "isLoading", false)}
        empty="items"
      />
      {get(item, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignItems="flex-end">
          <Pagination
            count={(Math.trunc((get(item, "totalRecords", 1) + PageSize) / PageSize))}
            page={get(item, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={() => { setOpenModal(true); setAnchorEl(null) }}>{__(`table.menu.edit`)}</MenuItem>
        <MenuItem onClick={handleDelete(selected)}>{__(`table.menu.delete`)}</MenuItem>
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <ItemModal
        __={__}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        selected={selected}
        setSelected={setSelected}
        idClient={id}
      />
      <Question
        title={__("alert.deleteitem.title")}
        detail={__("alert.deleteitem.detail")}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(delet, "isLoading")}
      />
    </div>
  )
}

export default Item;
