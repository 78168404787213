import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_INVOICE_LOADING = 'GET_INVOICE_LOADING';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_REJECT = 'GET_INVOICE_REJECT';

//* ACTIONS ------------------------------------------------
export const getInvoiceLoading = () => ({ type: GET_INVOICE_LOADING });
export const getInvoiceSuccess = (payload) => ({ type: GET_INVOICE_SUCCESS, payload });
export const getInvoiceReject = (payload) => ({ type: GET_INVOICE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getInvoiceRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Invoices`, options);
};

