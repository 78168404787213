import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_TRANSPORT_RESET = 'POST_TRANSPORT_RESET';
export const POST_TRANSPORT_LOADING = 'POST_TRANSPORT_LOADING';
export const POST_TRANSPORT_SUCCESS = 'POST_TRANSPORT_SUCCESS';
export const POST_TRANSPORT_REJECT = 'POST_TRANSPORT_REJECT';

//* ACTIONS ------------------------------------------------
export const postTransportReset = () => ({ type: POST_TRANSPORT_RESET });
export const postTransportLoading = () => ({ type: POST_TRANSPORT_LOADING });
export const postTransportSuccess = (payload) => ({ type: POST_TRANSPORT_SUCCESS, payload });
export const postTransportReject = (payload) => ({ type: POST_TRANSPORT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postTransportRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Transport`, options);
};


