import {
    putLiqOrderRouteLoading,
    putLiqOrderRouteRequest,
    putLiqOrderRouteSuccess,
    putLiqOrderRouteReject,
    putLiqOrderRouteReset
} from "../../actions/route/putLiquidate";

export const putLiqOrderRoute = (formData) => async (dispatch, getState) => {
    dispatch(putLiqOrderRouteLoading());
    try {
        const { data } = await putLiqOrderRouteRequest(formData, getState);
        dispatch(putLiqOrderRouteSuccess(data))
    } catch (error) {
        dispatch(putLiqOrderRouteReject(error))
    } finally {
        setTimeout(() => { dispatch(putLiqOrderRouteReset()) }, 3000);
    }
    return Promise.resolve();
};