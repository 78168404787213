 import { neutral } from "./colors";

const typography = {
    fontFamily: "Poppins-Medium",
    color: neutral[700],
    // ------------------ Dispays ---------------
    d1: {
        fontFamily: "Poppins-Bold",
        fontWeight: "bold",
        fontSize: "72px",
        lineHeight: "80px",
    },
    d2: {
        fontFamily: "Poppins-Bold",
        fontWeight: "bold",
        fontSize: "56px",
        lineHeight: "64px",
    },
    d3: {
        fontFamily: "Poppins-Bold",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "56px",
    },
    d4: {
        fontFamily: "Poppins-Bold",
        fontWeight: "bold",
        fontSize: "42px",
        lineHeight: "48px",
    },
    // ------------------ Headings ---------------
    h1: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "38px",
        lineHeight: "46px",
    },
    h2: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "30px",
        lineHeight: "40px",
    },
    h3: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "24px",
        lineHeight: "32px",
    },
    h4: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "-5%",
    },
    h5: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "16px",
        lineHeight: "24px",
    },
    // ------------------ Bodys ---------------
    blb: {
        fontFamily: "Poppins-Light",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "22px",
    },
    blm: {
        fontFamily: "Poppins-Light",
        fontWeight: "medium",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "-4%",
    },
    bml: {
        fontFamily: "Poppins-Light",
        fontWeight: "light",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "-2%",
    },
    bmm: {
        fontFamily: "Poppins-Light",
        fontWeight: "light",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "-2%",
    },
    // ------------------ Buttons ---------------
    button1: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "16",
        lineHeight: "22px",
    },
    button2: {
        fontFamily: "Poppins-Medium",
        fontWeight: "medium",
        fontSize: "12px",
        lineHeight: "20px",
    },
    // ------------------ Caption ---------------
    caption: {
        fontFamily: "Poppins-Light",
        fontWeight: "regular",
        fontSize: "12px",
        lineHeight: "20px",
    },

};

export default typography;