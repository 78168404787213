import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_ROUTE_ORDER_RESET = 'PUT_ROUTE_ORDER_RESET';
export const PUT_ROUTE_ORDER_LOADING = 'PUT_ROUTE_ORDER_LOADING';
export const PUT_ROUTE_ORDER_SUCCESS = 'PUT_ROUTE_ORDER_SUCCESS';
export const PUT_ROUTE_ORDER_REJECT = 'PUT_ROUTE_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const putRouteOrderReset = () => ({ type: PUT_ROUTE_ORDER_RESET });
export const putRouteOrderLoading = () => ({ type: PUT_ROUTE_ORDER_LOADING });
export const putRouteOrderSuccess = (payload) => ({ type: PUT_ROUTE_ORDER_SUCCESS, payload });
export const putRouteOrderReject = (payload) => ({ type: PUT_ROUTE_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putRouteOrderRequest = async ({ idRoute, orders }, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: orders,
    }, getState);
    return request(`/api/Route/UpdateOrderListByRoute/${idRoute}`, options);
};