import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ORDER_NO_IN_ROUTE_LOADING = 'GET_ORDER_NO_IN_ROUTE_LOADING';
export const GET_ORDER_NO_IN_ROUTE_SUCCESS = 'GET_ORDER_NO_IN_ROUTE_SUCCESS';
export const GET_ORDER_NO_IN_ROUTE_REJECT = 'GET_ORDER_NO_IN_ROUTE_REJECT';

//* ACTIONS ------------------------------------------------
export const getOrderNoInRouteLoading = () => ({ type: GET_ORDER_NO_IN_ROUTE_LOADING });
export const getOrderNoInRouteSuccess = (payload) => ({ type: GET_ORDER_NO_IN_ROUTE_SUCCESS, payload });
export const getOrderNoInRouteReject = (payload) => ({ type: GET_ORDER_NO_IN_ROUTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOrderNoInRouteRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Order/GetOrdersNotInRoute`, options);
};