import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_QUOTE_RESET = 'POST_QUOTE_RESET';
export const POST_QUOTE_LOADING = 'POST_QUOTE_LOADING';
export const POST_QUOTE_SUCCESS = 'POST_QUOTE_SUCCESS';
export const POST_QUOTE_REJECT = 'POST_QUOTE_REJECT';

//* ACTIONS ------------------------------------------------
export const postQuoteReset = () => ({ type: POST_QUOTE_RESET });
export const postQuoteLoading = () => ({ type: POST_QUOTE_LOADING });
export const postQuoteSuccess = (payload) => ({ type: POST_QUOTE_SUCCESS, payload });
export const postQuoteReject = (payload) => ({ type: POST_QUOTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postQuoteRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Quote`, options);
};


