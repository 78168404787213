import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_CONTACT_RESET = 'POST_CONTACT_RESET';
export const POST_CONTACT_LOADING = 'POST_CONTACT_LOADING';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_REJECT = 'POST_CONTACT_REJECT';

//* ACTIONS ------------------------------------------------
export const postContactReset = () => ({ type: POST_CONTACT_RESET });
export const postContactLoading = () => ({ type: POST_CONTACT_LOADING });
export const postContactSuccess = (payload) => ({ type: POST_CONTACT_SUCCESS, payload });
export const postContactReject = (payload) => ({ type: POST_CONTACT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postContactRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Contact`, options);
};


