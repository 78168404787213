import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_ALL_COORDS_LOADING = 'GET_ROUTE_ALL_COORDS_LOADING';
export const GET_ROUTE_ALL_COORDS_SUCCESS = 'GET_ROUTE_ALL_COORDS_SUCCESS';
export const GET_ROUTE_ALL_COORDS_REJECT = 'GET_ROUTE_ALL_COORDS_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteAllCoordsLoading = () => ({ type: GET_ROUTE_ALL_COORDS_LOADING });
export const getRouteAllCoordsSuccess = (payload) => ({ type: GET_ROUTE_ALL_COORDS_SUCCESS, payload });
export const getRouteAllCoordsReject = (payload) => ({ type: GET_ROUTE_ALL_COORDS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteAllCoordsRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Route/GetAllCoordinatesByRoute/${params.id}`, options);
};
