import {
    getDeliveryPointIdLoading,
    getDeliveryPointIdRequest,
    getDeliveryPointIdSuccess,
    getDeliveryPointIdReject,
} from "../../actions/deliveryPoints/getId";

export const getDeliveryPointId = (formData) => async (dispatch, getState) => {
    dispatch(getDeliveryPointIdLoading());
    try {
        const { data } = await getDeliveryPointIdRequest(formData, getState);
        dispatch(getDeliveryPointIdSuccess(data))
    } catch (error) {
        dispatch(getDeliveryPointIdReject(error))
    }
    return Promise.resolve();
};