import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_DRIVER_RESET = 'PUT_DRIVER_RESET';
export const PUT_DRIVER_LOADING = 'PUT_DRIVER_LOADING';
export const PUT_DRIVER_SUCCESS = 'PUT_DRIVER_SUCCESS';
export const PUT_DRIVER_REJECT = 'PUT_DRIVER_REJECT';

//* ACTIONS ------------------------------------------------
export const putDriverReset = () => ({ type: PUT_DRIVER_RESET });
export const putDriverLoading = () => ({ type: PUT_DRIVER_LOADING });
export const putDriverSuccess = (payload) => ({ type: PUT_DRIVER_SUCCESS, payload });
export const putDriverReject = (payload) => ({ type: PUT_DRIVER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putDriverRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Driver`, options);
};


