import {
    getRouteAllCoordsRouteLoading,
    getRouteAllCoordsRouteReject,
    getRouteAllCoordsRouteRequest,
    getRouteAllCoordsRouteSuccess,
} from "../../actions/route/getAllCoordsRoute";

export const getRouteAllCoordsRoute = (formData) => async (dispatch, getState) => {
    dispatch(getRouteAllCoordsRouteLoading());
    try {
        const { data } = await getRouteAllCoordsRouteRequest(formData, getState);
        dispatch(getRouteAllCoordsRouteSuccess(data))
    } catch (error) {
        dispatch(getRouteAllCoordsRouteReject(error))
    }
    return Promise.resolve();
};
