import {
    postDriverLoading,
    postDriverRequest,
    postDriverSuccess,
    postDriverReject,
    postDriverReset
} from "../../actions/driver/post";

export const postDriver = (formData) => async (dispatch, getState) => {
    dispatch(postDriverLoading());
    try {
        const { data } = await postDriverRequest(formData, getState);
        dispatch(postDriverSuccess(data))
    } catch (error) {
        dispatch(postDriverReject(error))
    } finally {
        setTimeout(() => { dispatch(postDriverReset()) }, 3000);
    }
    return Promise.resolve();
};