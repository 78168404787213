import {
    getTransportSupplierIdLoading,
    getTransportSupplierIdRequest,
    getTransportSupplierIdSuccess,
    getTransportSupplierIdReject,
} from "../../actions/transportSupplier/getId";

export const getTransportSupplierId = (formData) => async (dispatch, getState) => {
    dispatch(getTransportSupplierIdLoading());
    try {
        const { data } = await getTransportSupplierIdRequest(formData, getState);
        dispatch(getTransportSupplierIdSuccess(data))
    } catch (error) {
        dispatch(getTransportSupplierIdReject(error))
    }
    return Promise.resolve();
};