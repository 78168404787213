import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_TRANSPORT_RESET = 'PUT_TRANSPORT_RESET';
export const PUT_TRANSPORT_LOADING = 'PUT_TRANSPORT_LOADING';
export const PUT_TRANSPORT_SUCCESS = 'PUT_TRANSPORT_SUCCESS';
export const PUT_TRANSPORT_REJECT = 'PUT_TRANSPORT_REJECT';

//* ACTIONS ------------------------------------------------
export const putTransportReset = () => ({ type: PUT_TRANSPORT_RESET });
export const putTransportLoading = () => ({ type: PUT_TRANSPORT_LOADING });
export const putTransportSuccess = (payload) => ({ type: PUT_TRANSPORT_SUCCESS, payload });
export const putTransportReject = (payload) => ({ type: PUT_TRANSPORT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putTransportRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Transport`, options);
};


