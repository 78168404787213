import ValidatEmail from "../../../../pages/Auth/ValidatEmail";
import Tracking from "../../../../pages/Home/Tracking";

const routesPublic = [
    {
        key: "validate",
        path: "/validate-email",
        element: <ValidatEmail />,
    },
    {
        key: "tracking",
        path: "/tracking/:id",
        element: <Tracking isPublic />,
    },
]

export default routesPublic