import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_DELIVERY_POINT_RESET = 'PUT_DELIVERY_POINT_RESET';
export const PUT_DELIVERY_POINT_LOADING = 'PUT_DELIVERY_POINT_LOADING';
export const PUT_DELIVERY_POINT_SUCCESS = 'PUT_DELIVERY_POINT_SUCCESS';
export const PUT_DELIVERY_POINT_REJECT = 'PUT_DELIVERY_POINT_REJECT';

//* ACTIONS ------------------------------------------------
export const putDeliveryPointReset = () => ({ type: PUT_DELIVERY_POINT_RESET });
export const putDeliveryPointLoading = () => ({ type: PUT_DELIVERY_POINT_LOADING });
export const putDeliveryPointSuccess = (payload) => ({ type: PUT_DELIVERY_POINT_SUCCESS, payload });
export const putDeliveryPointReject = (payload) => ({ type: PUT_DELIVERY_POINT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putDeliveryPointRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/DeliveryPoints`, options);
};


