import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { API_URL } from "../../../../assets/util/request"

import {
    loginLoading,
    loginReject,
    loginRequest,
    loginSuccess,
} from "../../actions/login";

export const login = (formData) => async (dispatch, getState) => {
    dispatch(loginLoading());
    try {
        const { data } = await loginRequest(formData, getState);
        if (!isEmpty(get(data, "data", {}))) {
            const { tokenGenerated, idUser } = get(data, "data")
            const user = await axios(`/api/Users/${idUser}`, {
                baseURL: API_URL,
                headers: { Authorization: `Bearer ${tokenGenerated}` }
            })
            data.data = { ...data.data, ...user.data.data }
            dispatch(loginSuccess(data))
        } else {
            dispatch(loginReject(data))
        }
    } catch (error) {
        dispatch(loginReject(get(error, "response.data")))
    }
    return Promise.resolve();
};