import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_DRIVER_ID_LOADING = 'GET_DRIVER_ID_LOADING';
export const GET_DRIVER_ID_SUCCESS = 'GET_DRIVER_ID_SUCCESS';
export const GET_DRIVER_ID_REJECT = 'GET_DRIVER_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getDriverIdLoading = () => ({ type: GET_DRIVER_ID_LOADING });
export const getDriverIdSuccess = (payload) => ({ type: GET_DRIVER_ID_SUCCESS, payload });
export const getDriverIdReject = (payload) => ({ type: GET_DRIVER_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getDriverIdRequest = async ({ params = {}, id }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Driver/${id}`, options);
};