import { blue, neutral, success, error } from "./colors";

const components = {
    MuiTextField: {
        styleOverrides: {
            root: {
                borderRadius: 8,
            }
        }
    },
    // MuiDatePicker: {
    //     styleOverrides: {
    //         root: {
    //             height: 40
    //         }
    //     }
    // },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 8,
                textTransform: "none",
            },
            sizeSmall: {
                height: 32
            },
            sizeMedium: {
                height: 40
            },
            sizeLarge: {
                height: 48
            },
            containedSecondary: {
                "&:hover, &:focus": {
                    backgroundColor: blue[100]
                },
                "&:active": {
                    backgroundColor: blue[600]
                },
                "&:disabled": {
                    backgroundColor: neutral[100]
                }
            },
            textSecondary: {
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: 8
            },
            colorSuccess: {
                backgroundColor: success[0],
                color: success[300],
            },
            colorError: {
                backgroundColor: error[0],
                color: error[300],
            },
        }
    }
}

export default components;