import {
    getOrderIdLoading,
    getOrderIdRequest,
    getOrderIdSuccess,
    getOrderIdReject,
} from "../../actions/order/getId";

export const getOrderId = (formData) => async (dispatch, getState) => {
    dispatch(getOrderIdLoading());
    try {
        const { data } = await getOrderIdRequest(formData, getState);
        dispatch(getOrderIdSuccess(data))
    } catch (error) {
        dispatch(getOrderIdReject(error))
    }
    return Promise.resolve();
};