import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_CLIENT_RESET = 'DELETE_CLIENT_RESET';
export const DELETE_CLIENT_LOADING = 'DELETE_CLIENT_LOADING';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_REJECT = 'DELETE_CLIENT_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteClientReset = () => ({ type: DELETE_CLIENT_RESET });
export const deleteClientLoading = () => ({ type: DELETE_CLIENT_LOADING });
export const deleteClientSuccess = (payload) => ({ type: DELETE_CLIENT_SUCCESS, payload });
export const deleteClientReject = (payload) => ({ type: DELETE_CLIENT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteClientRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Clients/${data.id}`, options);
};