import { combineReducers } from "redux";

import invoices from "./invoices";
import orders from "./orders";
import routeCost from "./routeCost";
import routes from "./routes";

export default combineReducers({
  invoices,
  orders,
  routeCost,
  routes,
});
