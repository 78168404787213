import React, { useState, useEffect } from 'react'
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import find from "lodash/find";
import filter from "lodash/filter";
import isArray from "lodash/isArray";
import replace from "lodash/replace";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Typography,
  Box,
  Stack
} from "@mui/material"

import CircularProgress from "../../components/form/CircularProgress";
import Layout from "../../components/layout/Layout";
import Map from "../../components/map/MapMany2";
import MapToNew from "../../components/map/MapToNew";
import MapOne from "../../components/map/Map";
import { getRouteHome } from "../../store/transactions/thunk/route/getDashboard";
import { PageSize, countPage } from "../../config/const"
import Empty from "../../assets/icons/Empty";

const IsEmpty = ({ loading, empty, textEmpty, textDetail }) => {
  return (
    <div className={`flex flex-col ${empty ? "flex-1" : "flex-0"}`}>
      {loading &&
        <Box sx={{
          display: 'flex',
          height: "100%",
          width: '100%',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress />
        </Box>
      }
      {(empty && !loading) &&
        <Box sx={{
          display: 'flex',
          flexDirection: "column",
          height: "100%",
          width: '100%',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Empty />

          <Stack justifyContent="center" alignItems="center" spacing={1} pt={2} >
            <Typography variant="bml">{textEmpty}</Typography>
            <Typography variant="caption">{textDetail}</Typography>
          </Stack>
        </Box>
      }
    </div>
  )
}

const Home = () => {
  const [__] = useTranslation("global");
  const dispatch = useDispatch();

  const [r__] = useTranslation("order");
  const orderStatus = r__(`orderStatus`, { returnObjects: true });
  const [countRute, setCountRute] = useState("");
  const [countOrder, setCountOrder] = useState("");

  const [active, setActive] = useState({});
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState([])
  const [mapLoad, setMapLoad] = useState(false);
  const [km, setKm] = useState("")
  const [duration, setDuration] = useState("")

  const dashboard = useSelector(state => state.transactions.route.dashboard);
  // const dashboard = {
  //   data: {
  //     listRoutes: {
  //       "points": [
  //         {
  //           "order": 0,
  //           "deliveryName": "STYBA MERLIOT",
  //           "latitude": "13.673366",
  //           "longitude": "-89.260682"
  //         },
  //         {
  //           "order": 1,
  //           "deliveryName": "PLANTA ICI",
  //           "latitude": "13.782547693689931",
  //           "longitude": "-89.21358642275392"
  //         },
  //         {
  //           "order": 2, "deliveryName": "PAMPA  LAGO COATEPEQUE", "latitude": "13.891417004639063",
  //           "longitude": "-89.53473726255359"
  //         }
  //       ] 
  //     }
  //   }
  // }

  useEffect(() => {
    if (isArray(dashboard?.data?.listRoutes)) {
      const truta = dashboard?.data?.listRoutes?.length
      let ruta = 0

      // Se dejó quemado "En ruta" a petición de Horacio
      for (let i = 0; i < dashboard?.data?.listRoutes?.length; i++) {
        for (let j = 0; j < dashboard?.data?.listRoutes[i].orders.length; j++) {
          if (dashboard?.data?.listRoutes[i].orders[j].status === "EN RUTA") {
            ruta++
          }
        }
      }
      setCountRute(`${ruta}/${truta}`)
    }

  }, [dashboard?.data?.listRoutes?.length])

  const getData = ({ page }) => {
    dispatch(getRouteHome({ page, PageSize }))
  }

  useEffect(() => {
    getData({ page: 1 })
  }, [dispatch])


  const getMapRoutes = async () => {
    setMapLoad(true)

    const routesOrder = await map(active.orders, (order, i) => {
      if (!!Number(get(order, "latitude")) && !!Number(get(order, "longitude"))) {
        return ({
          location: {
            id: i + 1,
            lat: Number(get(order, "latitude")),
            lng: Number(get(order, "longitude"))
          }
        })
      }
      return 0;
    })

    await setRoutes(routesOrder)
    setMapLoad(false)
  }

  useEffect(() => {
    getMapRoutes()

    if (!!active?.routeId) {
      const deliverys = filter(get(active, "orders", []), ({ status }) => status === "ENTREGADO")
      setCountOrder(deliverys.length)
    }
  }, [active?.routeId])


  const setNewActive = (e) => {
    setActive(e)
  }


  const getKm = (e) => {
    setKm(get(e, "distance.text", ""))
    try {
      const time = get(e, "time.value", 0) * 1000
      var d = moment.duration(time, 'milliseconds');
      var hours = Math.floor(d.asHours());
      var mins = Math.floor(d.asMinutes()) - hours * 60;
      setDuration(replace(__("dashboard.time"), "[time]", `${hours}:${mins}`))
    } catch (error) {
      setDuration("")
    }
  }

  const getStatus = (s) => {
    const status = find(orderStatus, ({ label, color }) => lowerCase(label) === lowerCase(s))

    return ({
      svg: status.color,
      label: <Box component="span" sx={{ color: status.color, borderWidth: 1, borderColor: status.color, bgcolor: `${status.color}26` }} className={`absolute top-0 right-0 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium`}>{s}</Box>
    })
  }

  return (
    <Layout disableHead={true}>
      <div>
        <div className="absolute z-[1] h-full flex flex-row max-h-screen" onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
          <div id="main-menu" className='relative w-[300px] border-r-gray-400 border-r-[1px] min-h-screen h-full bg-white overflow-y-auto flex flex-col'>
            <div className='sticky top-0 z-[1] flex flex-row justify-between border-b-gray-400 border-b-[1px] p-[20px] bg-white'>
              <div className='uppercase'>{__("dashboard.title")}</div>
              <div>{countRute}</div>
            </div>
            <IsEmpty loading={get(dashboard, "isLoading", false)} empty={isEmpty(get(dashboard, "data.listRoutes", []))} textEmpty={__("dashboard.empty.main")} textDetail={__("dashboard.empty.detail")} />
            <ul role="list" className={`divide-y divide-gray-400 border-b-gray-400 border-b-[1px] flex flex-col ${isEmpty(active?.orders) ? "flex-0" : "flex-1"}`}>
              {map(get(dashboard, "data.listRoutes", []), (route) => (
                <li
                  key={route.routeId}
                  role="button"
                  onClick={() => setNewActive(route)}
                  className={`flex justify-between items-center gap-x-6 py-5 ${active.routeId === route.routeId ? "bg-gray-100" : "bg-[transparent]"} hover:bg-gray-100 active:bg-[#0000004a] p-[20px] duration-300 cursor-pointer `}
                >
                  <div className="flex min-w-0 w-full max-w-full">
                    <svg className='w-12 h-12 flex-none rounded-full bg-gray-50' viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect className={`${active.routeId === route.routeId ? "fill-[#B0E2FA]" : "fill-[#DFE7F3]"} h-[44px] w-[44px]`} rx="22" fill="#B0E2FA" />
                      <g clipPath="url(#clip0_824_89124)">
                        <path className={`${active.routeId === route.routeId ? "fill-[#0C709F]" : "fill-[#556C8F]"}`} d="M28.75 13.9395L14.07 20.0795C13.24 20.4295 13.26 21.6095 14.09 21.9295L19.43 23.9995C19.69 24.0995 19.9 24.3095 20 24.5695L22.06 29.8995C22.38 30.7395 23.57 30.7595 23.92 29.9295L30.07 15.2595C30.4 14.4295 29.57 13.5995 28.75 13.9395Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_824_89124">
                          <rect width="24" height="24" fill="white" transform="translate(10 10)" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="min-w-0 flex-auto pl-[10px]">
                      <p className="text-sm font-semibold leading-6 text-gray-900">ID:&nbsp;{route.routeId}</p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{route.routeName}</p>
                    </div>
                    <div className={`flex items-center justify-end pl-[10px]`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[15px] h-[15px] ${active.routeId === route.routeId ? "-rotate-180" : "rotate-0"} duration-500`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                      </svg>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div id="detail-menu" className={`relative ${open ? "w-[300px] border-r-[1px]" : "w-0 border-r-[0px]"} duration-300 border-r-gray-400  min-h-screen h-screen overflow-y-auto bg-white flex flex-1 flex-col`}>
            <div className={`sticky top-0 z-[1] bg-gray-100 p-[20px] pb-[10px] border-b-gray-400 border-b-[1px] min-w-full`}>
              <div className='flex flex-row justify-between pb-[10px]'>
                <div className='uppercase'>{__("dashboard.detail")}</div>
                <div>{countOrder}/{get(active, "orders", [])?.length}</div>
              </div>
              <div className="flex flex-row items-center min-w-0 w-full max-w-full pb-[10px]">
                <svg className='w-12 h-12 flex-none rounded-full bg-gray-50' viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect className={`fill-[#B0E2FA] h-[44px] w-[44px]`} rx="22" fill="#B0E2FA" />
                  <g clipPath="url(#clip0_824_89124)">
                    <path className={`fill-[#0C709F]`} d="M28.75 13.9395L14.07 20.0795C13.24 20.4295 13.26 21.6095 14.09 21.9295L19.43 23.9995C19.69 24.0995 19.9 24.3095 20 24.5695L22.06 29.8995C22.38 30.7395 23.57 30.7595 23.92 29.9295L30.07 15.2595C30.4 14.4295 29.57 13.5995 28.75 13.9395Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_824_89124">
                      <rect width="24" height="24" fill="white" transform="translate(10 10)" />
                    </clipPath>
                  </defs>
                </svg>

                <div className="min-w-0 flex-auto pl-[10px]">
                  <p className="text-sm font-semibold leading-6 text-gray-900">ID:&nbsp;{active.routeId}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{active.routeName}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{active.driver}</p>
                </div>
              </div>
              <div className='pt-[10px] border-t-gray-400 border-t-[1px] flex flex-row flex-2 divide-x divide-gray-400'>
                <div className='flex flex-row gap-x-2 items-center justify-start mr-auto flex-1'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_824_89207)">
                      <path d="M13.5333 6.46602C12.5583 5.49102 11.2833 4.99935 9.99996 4.99935V9.99935L6.46663 13.5327C8.41663 15.4827 11.5833 15.4827 13.5416 13.5327C15.4916 11.5827 15.4916 8.41602 13.5333 6.46602ZM9.99996 1.66602C5.39996 1.66602 1.66663 5.39935 1.66663 9.99935C1.66663 14.5993 5.39996 18.3327 9.99996 18.3327C14.6 18.3327 18.3333 14.5993 18.3333 9.99935C18.3333 5.39935 14.6 1.66602 9.99996 1.66602ZM9.99996 16.666C6.31663 16.666 3.33329 13.6827 3.33329 9.99935C3.33329 6.31602 6.31663 3.33268 9.99996 3.33268C13.6833 3.33268 16.6666 6.31602 16.6666 9.99935C16.6666 13.6827 13.6833 16.666 9.99996 16.666Z" fill="#3B405E" />
                    </g>
                    <defs>
                      <clipPath id="clip0_824_89207">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{duration}</span>
                </div>
                <div className='flex flex-row gap-x-2 items-center justify-end ml-auto flex-1'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_824_89212)">
                      <path d="M23 8C23 9.1 22.1 10 21 10C20.82 10 20.65 9.98 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.02 8.35 19 8.18 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8ZM23 8C23 9.1 22.1 10 21 10C20.82 10 20.65 9.98 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.02 8.35 19 8.18 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8Z" fill="#3B405E" />
                    </g>
                    <defs>
                      <clipPath id="clip0_824_89212">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{km}</span>
                </div>
              </div>
            </div>

            <IsEmpty loading={get(dashboard, "isLoading", false)} empty={isEmpty(active?.orders)} textEmpty={__("dashboard.empty.main")} textDetail={__("dashboard.empty.detail")} />
            <ul role="list" className={`divide-y divide-gray-400 border-b-gray-400 border-b-[1px] flex flex-col ${isEmpty(active?.orders) ? "flex-0" : "flex-1"}`}>
              {active?.orders?.map((order) => (
                <li key={order.orderId} className="flex justify-between gap-x-6 py-5 hover:bg-gray-100 active:bg-[#0000004a] p-[20px] duration-300">
                  <div className="flex min-w-0 w-full max-w-full">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_824_89217)">
                        <path fill={getStatus(order.status)?.svg} d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
                        <path fill={getStatus(order.status)?.svg} d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_824_89217">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="min-w-0 flex-auto pl-[10px]">
                      <div className='relative flex flex-row justify-between'>
                        <p className="text-sm font-semibold leading-6 text-gray-900 truncate">ID:&nbsp;{order.orderId}</p>
                        {getStatus(order.status)?.label}
                      </div>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{order.docNum}</p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{order.deliveryPoint}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='h-screen w-full pl-[300px]'>
          {routes?.length < 2 && <MapOne position={routes[0]?.location} __={__} disableInput />}
          {routes?.length === 2 && <MapToNew coords={map(routes, ({ location: { lat, lng } }) => ({ lat, lng }))} getKm={getKm} __={__} />}
          {routes?.length > 2 && <Map loading={mapLoad} routes={routes} setKm={getKm} __={__} />}
        </div>
      </div>
    </Layout >
  )
}

export default Home

