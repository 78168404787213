import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_LOADING = 'GET_ROUTE_LOADING';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const GET_ROUTE_REJECT = 'GET_ROUTE_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteLoading = () => ({ type: GET_ROUTE_LOADING });
export const getRouteSuccess = (payload) => ({ type: GET_ROUTE_SUCCESS, payload });
export const getRouteReject = (payload) => ({ type: GET_ROUTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Route`, options);
};

