import {
    getOrderLoading,
    getOrderReject,
    getOrderRequest,
    getOrderSuccess
} from "../../actions/order/getAll";

export const getOrder = (formData) => async (dispatch, getState) => {
    dispatch(getOrderLoading());
    try {
        const { data } = await getOrderRequest(formData, getState);
        dispatch(getOrderSuccess(data))
    } catch (error) {
        dispatch(getOrderReject(error))
    }
    return Promise.resolve();
};