import {
    putClientItemLoading,
    putClientItemRequest,
    putClientItemSuccess,
    putClientItemReject,
    putClientItemReset
} from "../../actions/client/putItem";

export const putClientItem = (formData) => async (dispatch, getState) => {
    dispatch(putClientItemLoading());
    try {
        const { data } = await putClientItemRequest(formData, getState);
        dispatch(putClientItemSuccess(data))
    } catch (error) {
        dispatch(putClientItemReject(error))
    } finally {
        setTimeout(() => { dispatch(putClientItemReset()) }, 3000);
    }
    return Promise.resolve();
};