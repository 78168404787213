import * as yup from 'yup';
import { get } from "lodash";

export const validator_user = (__) => () => {
    return (
        yup.object({
            name: yup.string().required(__("form_user.name.error")),
            phone: yup.string().required(__("form_user.phone.error")),
            email: yup.string().email(__("form_user.email.error2")).required(__("form_user.email.error")),
        })
    )
}
export const validator_company = (__) => () => {
    return (
        yup.object({
            name: yup.string().required(__("form_company.name.error")),
            address: yup.string().required(__("form_company.address.error")),
            contactPhone: yup.string().required(__("form_company.contactPhone.error")),
        })
    )
}

