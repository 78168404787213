import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_ORDER_RESET = 'DELETE_ORDER_RESET';
export const DELETE_ORDER_LOADING = 'DELETE_ORDER_LOADING';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_REJECT = 'DELETE_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteOrderReset = () => ({ type: DELETE_ORDER_RESET });
export const deleteOrderLoading = () => ({ type: DELETE_ORDER_LOADING });
export const deleteOrderSuccess = (payload) => ({ type: DELETE_ORDER_SUCCESS, payload });
export const deleteOrderReject = (payload) => ({ type: DELETE_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteOrderRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Order/${data.id}`, options);
};