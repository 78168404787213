import Orders from "../../../../pages/Transactions/Orders";
import OrdersNewEdit from "../../../../pages/Transactions/Orders/NewEdit";
import OrdersDetail from "../../../../pages/Transactions/Orders/Detail";

const routerOrders = [
    {
        key: "orders",
        path: "/transactions/orders",
        element: <Orders />,
    },
    {
        key: "orders",
        path: "/transactions/orders/new",
        element: <OrdersNewEdit />,
    },
    {
        key: "orders",
        path: "/transactions/orders/detail/:id",
        element: <OrdersDetail />,
    },
    {
        key: "orders",
        path: "/transactions/orders/:id",
        element: <OrdersDetail />,
    },
]

export default routerOrders
