import {
    getTransportSupplierLoading,
    getTransportSupplierReject,
    getTransportSupplierRequest,
    getTransportSupplierSuccess
} from "../../actions/transportSupplier/getAll";

export const getTransportSupplier = (formData) => async (dispatch, getState) => {
    dispatch(getTransportSupplierLoading());
    try {
        const { data } = await getTransportSupplierRequest(formData, getState);
        dispatch(getTransportSupplierSuccess(data))
    } catch (error) {
        dispatch(getTransportSupplierReject(error))
    }
    return Promise.resolve();
};