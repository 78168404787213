import {
    GET_CLIENT_LOADING,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_REJECT,
} from "../../actions/client/getAll";

import {
    GET_CLIENT_ID_LOADING,
    GET_CLIENT_ID_SUCCESS,
    GET_CLIENT_ID_REJECT,
} from "../../actions/client/getId";

import {
    POST_CLIENT_LOADING,
    POST_CLIENT_SUCCESS,
    POST_CLIENT_REJECT,
    POST_CLIENT_RESET,
} from "../../actions/client/post";

import {
    PUT_CLIENT_LOADING,
    PUT_CLIENT_SUCCESS,
    PUT_CLIENT_REJECT,
    PUT_CLIENT_RESET,
} from "../../actions/client/put";

import {
    DELETE_CLIENT_LOADING,
    DELETE_CLIENT_SUCCESS,
    DELETE_CLIENT_REJECT,
    DELETE_CLIENT_RESET,
} from "../../actions/client/delete";

import {
    GET_CLIENT_ITEM_LOADING,
    GET_CLIENT_ITEM_SUCCESS,
    GET_CLIENT_ITEM_REJECT,
} from "../../actions/client/getItem";

import {
    POST_CLIENT_ITEM_LOADING,
    POST_CLIENT_ITEM_SUCCESS,
    POST_CLIENT_ITEM_REJECT,
    POST_CLIENT_ITEM_RESET,
} from "../../actions/client/postItem";

import {
    PUT_CLIENT_ITEM_LOADING,
    PUT_CLIENT_ITEM_SUCCESS,
    PUT_CLIENT_ITEM_REJECT,
    PUT_CLIENT_ITEM_RESET,
} from "../../actions/client/putItem";

import {
    DELETE_CLIENT_ITEM_LOADING,
    DELETE_CLIENT_ITEM_SUCCESS,
    DELETE_CLIENT_ITEM_REJECT,
    DELETE_CLIENT_ITEM_RESET,
} from "../../actions/client/deleteItem";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
    detail: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    post: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    put: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    delete: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    item: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    postItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    putItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    deleteItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}

const clientReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_CLIENT_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_CLIENT_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_CLIENT_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        // ------------- GET ID ID --------------------
        case GET_CLIENT_ID_LOADING: return {
            ...state,
            detail: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_CLIENT_ID_SUCCESS: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_CLIENT_ID_REJECT: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }

        // ------------- POST --------------------
        case POST_CLIENT_LOADING: return {
            ...state,
            post: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_CLIENT_SUCCESS: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_CLIENT_REJECT: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_CLIENT_RESET: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT --------------------
        case PUT_CLIENT_LOADING: return {
            ...state,
            put: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_CLIENT_SUCCESS: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_CLIENT_REJECT: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_CLIENT_RESET: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE --------------------
        case DELETE_CLIENT_LOADING: return {
            ...state,
            delete: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_CLIENT_SUCCESS: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_CLIENT_REJECT: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_CLIENT_RESET: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- GET ITEM ALL --------------------
        case GET_CLIENT_ITEM_LOADING: return {
            ...state,
            item: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_CLIENT_ITEM_SUCCESS: return {
            ...state,
            item: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_CLIENT_ITEM_REJECT: return {
            ...state,
            item: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        // ------------- POST ITEM --------------------
        case POST_CLIENT_ITEM_LOADING: return {
            ...state,
            postItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_CLIENT_ITEM_SUCCESS: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_CLIENT_ITEM_REJECT: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_CLIENT_ITEM_RESET: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT ITEM --------------------
        case PUT_CLIENT_ITEM_LOADING: return {
            ...state,
            putItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_CLIENT_ITEM_SUCCESS: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_CLIENT_ITEM_REJECT: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_CLIENT_ITEM_RESET: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE ITEM --------------------
        case DELETE_CLIENT_ITEM_LOADING: return {
            ...state,
            deleteItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_CLIENT_ITEM_SUCCESS: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_CLIENT_ITEM_REJECT: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_CLIENT_ITEM_RESET: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        default: return state;
    }
}

export default clientReducer