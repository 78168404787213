/*global google*/
import React, { useState } from "react";
import { GoogleMap, useLoadScript, MarkerF, InfoWindowF } from "@react-google-maps/api";
import map from "lodash/map"
import get from "lodash/get"
import sum from "lodash/sum"
import isEmpty from "lodash/isEmpty"
import moment from "moment"
import { Backdrop } from "@mui/material"
import CircularProgress from "../form/CircularProgress"

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const defaultLocation = {
    lat: 13.984363243687213,
    lng: -89.54855754989579
};

const Map = (props) => {
    const { setKm, __g, loading } = props;
    const [open, setOpen] = useState(null)

    const { isLoaded, loadError } = useLoadScript({
        id: `google-map-many`,
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        language: props.__("lang.current.lang"),
        libraries: ["places"]
    });

    const sumDistance = (list = []) => {
        const sDate = moment(get(list, "[0].location.date"))
        const eDate = moment(get(list, `[${list.length - 1}].location.date`))
        const distanceMt = sum(map(list, ({ location: { id, lat, lng } }, i) => (calculateDistance({ lat1: lat, lng1: lng, lat2: list[i + 1]?.location.lat, lng2: list[i + 1]?.location.lng }))))
        const distanceKm = distanceMt / 1000;


        return { distance: { label: `${distanceKm.toFixed(2)} Km`, value: distanceKm }, duration: calculateTime({ date1: sDate, date2: eDate }) }
    }

    const calculateDistance = ({ lat1, lng1, lat2, lng2 }) => {
        const R = 6371e3; // metres
        const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lng2 - lng1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // in metres
        if (d > 0) {
            return d
        }
        return 0
    }

    const calculateTime = ({ date1, date2 }) => {
        var tiempoPasado = date2?.diff(date1)
        var segs = 1000;
        var mins = segs * 60;
        var hours = mins * 60;
        var days = hours * 24;

        var day = Math.floor(tiempoPasado / days)

        tiempoPasado = tiempoPasado - (day * days);
        var hour = Math.floor(tiempoPasado / hours)

        tiempoPasado = tiempoPasado - (hour * hours);
        var min = Math.floor(tiempoPasado / mins)

        return { text: `${day ? days + " " + __g("und.days") + ", " : ""}${hour ? hour + " " + __g("und.hours") + ", " : ""}${min} ${__g("und.mins")}`, value: tiempoPasado }
    }

    const onLoad = (map) => {
        const list = props.routes
        setKm(sumDistance(list))
    }

    const renderMap = () => {
        return (
            <GoogleMap
                id="mapa-modal-content"
                name="mapa-modal-content"
                center={defaultLocation}
                zoom={9}
                onLoad={onLoad}
                mapContainerStyle={{ flex: 1 }}
            >

                {map(props.routes, ({ location, i }) => (
                    <MarkerF
                        key={i}
                        icon={i === 0 ? {} : {
                            url: "/dot_blue.png",
                            scale: 0.5
                        }}
                        label={i === 0 ? "A" : i === (props.routes.length - 1) ? "B" : null}
                        position={{ lat: get(location, "lat"), lng: get(location, "lng") }}
                    >
                    </MarkerF>
                ))
                }

                {map(props.mainRoutes, ({ location }, i, dat) => (
                    <MarkerF
                        key={i}
                        label={location.id}
                        style={{ zIndex: i + 1, }}
                        position={{ lat: get(location, "lat"), lng: get(location, "lng") }}
                        onClick={() => setOpen(location.id)}
                    >
                        {open === location.id &&
                            <InfoWindowF
                                position={{ lat: get(location, "lat"), lng: get(location, "lng") }}
                                onCloseClick={() => setOpen(null)}
                            >
                                <div>
                                    {location.id === "1" && <strong>{__g("map.labels.start")}</strong>}
                                    {location.id === `${dat.length}` && <strong>{__g("map.labels.end")}</strong>}
                                    <div>{location.deliveryName}</div>
                                </div>
                            </InfoWindowF>
                        }
                    </MarkerF>
                ))
                }


            </GoogleMap>
        )
    }

    if (loadError) {
        return <div>Lo sentimos, el mapa no puede ser cargado por el momento</div>;
    }
    return (
        <div className="h-full w-full relative">
            {isLoaded && <div style={{ flexDirection: "column", display: "flex", height: "100%", width: "100%" }}>{renderMap()}</div>}
            <Backdrop
                className='!absolute'
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!isLoaded || loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Map

