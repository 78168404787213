import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Stack,
    OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import find from "lodash/find";
import includes from "lodash/includes";
import trim from "lodash/trim";

import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"

import Table from "../../../components/form/Table";

import { getOrderNoInRoute } from "../../../store/transactions/thunk/order/getNoInRoute";
import { postRouteOrder } from "../../../store/transactions/thunk/route/postOrder";
import { putRouteOrder } from "../../../store/transactions/thunk/route/putOrder";

const Alert = ({
    __,
    open,
    close,
    __o,
    // selected,
    // setSelected
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [selected, setSelected] = useState([])
    const [searchValue, setSearchValue] = useState("");
    const [orderFilterd, setOrderFilterd] = useState([]);

    const actualOrders = useSelector(state => state.transactions.route.orderAll);
    const order = useSelector(state => state.transactions.order.noInRoute);
    const put = useSelector(state => state.transactions.route.putOrder);
    const post = useSelector(state => state.transactions.route.postOrder);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);

    const orderOperativeStatus = __o(`orderOperativeStatus`, { returnObjects: true });

    const orderAdded = []
    const orderAll = get(order, "data", [])
    const orderFilterdPre = filter(orderAll, ({ orderId }) => !(find(orderAdded, (id) => id === orderId)))

    useEffect(() => {
        if (!!searchValue) {
            setOrderFilterd(
                filter(orderFilterdPre, ({ deliveryPoint }) =>
                    includes(get(deliveryPoint, "contactName")?.toLowerCase(), searchValue)
                    || includes(get(deliveryPoint, "contactPhone")?.toLowerCase(), searchValue)
                    || includes(get(deliveryPoint, "contactEmail")?.toLowerCase(), searchValue)
                    || includes(get(deliveryPoint, "deliveryCode")?.toLowerCase(), searchValue)
                    || includes(get(deliveryPoint, "deliveryName")?.toLowerCase(), searchValue)
                )
            )
        } else {
            setOrderFilterd(orderFilterdPre)
        }
    }, [searchValue, get(orderFilterdPre, "length")])


    useEffect(() => {
        dispatch(getOrderNoInRoute({}))
    }, [])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = map(orderFilterd, (n) => n.idOrder);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1
    }

    const handleChecked = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const headTable = [
        {
            key: "checkbox",
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < orderFilterd.length}
                    checked={orderFilterd.length > 0 && selected.length === orderFilterd.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            align: "center",
            width: 70,
        },
        {
            key: "idOrder",
            label: __(`table.colsOrders.idOrder`),
            align: "left"
          },
          {
            key: "docNum",
            label: __(`table.colsOrders.docNum`),
            align: "left"
          },
          {
            key: "deliveryPoint.contactName",
            label: __(`table.colsOrders.contactoDelivery`),
            align: "left"
          },
          {
            key: "deliveryPoint.contactPhone",
            label: __(`table.colsOrders.phoneDelivery`),
            align: "left",
          },
          {
            key: "operativeStatus",
            label: __(`table.colsOrders.operativeStatus`),
            align: "left"
          },
          {
            key: "delivery",
            label: __(`table.colsOrders.deliveryPoint`),
            align: "left"
          },
    ]

    const dataTable = map(orderFilterd, (row, i) => {
        const isItemSelected = isSelected(get(row, "idOrder"));
        const labelId = `enhanced-table-checkbox-${i}`;

        const getObjectDelivery = () => {
            return find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === row.idDeliveryPoint)
        }

        return ({
            ...row,
            checkbox: (
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e, v) => handleChecked(e, get(row, "idOrder"))}
                    inputProps={{ 'aria-labelledby': labelId, }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            create: get(row, "creationDate", null) && moment(get(row, "creationDate")).format("L hh:mm a"),
            operativeStatus: get(find(orderOperativeStatus, ({ value }) => value === get(row, "idOrderOperativeStatus")), "label"),
            rotueDate: get(row, "startDate", null) && moment(get(row, "startDate")).format("L hh:mm a"),
            name: get(row, "contactName", null),
            telePhone: get(row, "contactPhone", null) && <Stack direction="row" alignItems="center" spacing={1} ><PhoneIcon fontSize="small" /><div>{get(getObjectDelivery(), "contactPhone")}</div></Stack>,
            delivery: `${get(getObjectDelivery(), "deliveryCode")} | ${get(getObjectDelivery(), "deliveryName")}`,
        })
    })

    const postOrderToRoute = () => {
        const body = {
            orders: map(selected, (item) => ({ idOrder: item })),
            idRoute: id
        }
        if (get(actualOrders, "data.length") > 1) {
            dispatch(putRouteOrder(body))
        } else {
            dispatch(postRouteOrder(body))
        }
        setSelected([])
    }

    const onChange = (e) => {
        setSearchValue(trim(e.target.value))
    }

    return (
        <div>
            <Dialog onClose={close} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {__("labels.6")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div className='px-8 pyt-2'>
                    <OutlinedInput
                        id="search-btn"
                        endAdornment={
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        size="small"
                        fullWidth
                        onChange={onChange}
                        placeholder={__("action.search")}
                    />
                </div>
                <Table
                    headTable={headTable}
                    dataTable={dataTable}
                    __={__}
                    sizeFilters={125}
                    loading={get(order, "isLoading", false)}
                    empty="orders"
                    propsTable={{
                        size: "small"
                    }}
                />
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={postOrderToRoute} disabled={get(post, "isLoading") || get(put, "isLoading") || isEmpty(selected)} loading={get(post, "isLoading") || get(put, "isLoading")}>{__("action.save")}</LoadingButton>
                    <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={close} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default Alert