import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_OPERATORS_RESET = 'PUT_OPERATORS_RESET';
export const PUT_OPERATORS_LOADING = 'PUT_OPERATORS_LOADING';
export const PUT_OPERATORS_SUCCESS = 'PUT_OPERATORS_SUCCESS';
export const PUT_OPERATORS_REJECT = 'PUT_OPERATORS_REJECT';

//* ACTIONS ------------------------------------------------
export const putOperatorsReset = () => ({ type: PUT_OPERATORS_RESET });
export const putOperatorsLoading = () => ({ type: PUT_OPERATORS_LOADING });
export const putOperatorsSuccess = (payload) => ({ type: PUT_OPERATORS_SUCCESS, payload });
export const putOperatorsReject = (payload) => ({ type: PUT_OPERATORS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putOperatorsRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Users/UpdateOperator`, options);
};

