import {
    postInvoiceDocumentLoading,
    postInvoiceDocumentRequest,
    postInvoiceDocumentSuccess,
    postInvoiceDocumentReject,
    postInvoiceDocumentReset

} from "../../actions/invoice/postInvoiceDocument";

export const postInvoiceDocument = (formData) => async (dispatch, getState) => {
    dispatch(postInvoiceDocumentLoading());
    try {
        const { data } = await postInvoiceDocumentRequest(formData, getState);
        dispatch(postInvoiceDocumentSuccess(data))
    } catch (error) {
        dispatch(postInvoiceDocumentReject(error))
    } finally {
        setTimeout(() => { dispatch(postInvoiceDocumentReset()) }, 3000);
    }
    return Promise.resolve();
};