import { concat } from "lodash";

import routerDriver from "./driver";
import routerClients from "./clients";
import routerDeliveryPoints from "./delivery_points";
import routerTransport from "./transport";
import routerOperators from "./operators";

import Masters from "../../../../pages/Masters";

const routesMasters = [{ key: "masters", path: "/masters", element: <Masters />, }]

const routes = concat(
    routesMasters,
    routerDriver,
    routerClients,
    routerDeliveryPoints,
    routerTransport,
    routerOperators
)

export default routes