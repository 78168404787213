import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_ROUTE_ORDER_RESET = 'POST_ROUTE_ORDER_RESET';
export const POST_ROUTE_ORDER_LOADING = 'POST_ROUTE_ORDER_LOADING';
export const POST_ROUTE_ORDER_SUCCESS = 'POST_ROUTE_ORDER_SUCCESS';
export const POST_ROUTE_ORDER_REJECT = 'POST_ROUTE_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const postRouteOrderReset = () => ({ type: POST_ROUTE_ORDER_RESET });
export const postRouteOrderLoading = () => ({ type: POST_ROUTE_ORDER_LOADING });
export const postRouteOrderSuccess = (payload) => ({ type: POST_ROUTE_ORDER_SUCCESS, payload });
export const postRouteOrderReject = (payload) => ({ type: POST_ROUTE_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postRouteOrderRequest = async ({ idRoute, orders }, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: orders,
    }, getState);
    return request(`/api/Route/UploadOrderListByRoute/${idRoute}`, options);
};

