import {
    putContactLoading,
    putContactRequest,
    putContactSuccess,
    putContactReject,
    putContactReset
} from "../../actions/contact/put";

export const putContact = (formData) => async (dispatch, getState) => {
    dispatch(putContactLoading());
    try {
        const { data } = await putContactRequest(formData, getState);
        dispatch(putContactSuccess(data))
    } catch (error) {
        dispatch(putContactReject(error))
    } finally {
        setTimeout(() => { dispatch(putContactReset()) }, 3000);
    }
    return Promise.resolve();
};