import {
    postCostLoading,
    postCostRequest,
    postCostSuccess,
    postCostReject,
    postCostReset
} from "../../actions/cost/post";

export const postCost = (formData) => async (dispatch, getState) => {
    dispatch(postCostLoading());
    try {
        const { data } = await postCostRequest(formData, getState);
        dispatch(postCostSuccess(data))
    } catch (error) {
        dispatch(postCostReject(error))
    } finally {
        setTimeout(() => { dispatch(postCostReset()) }, 3000);
    }
    return Promise.resolve();
};