import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_INVOICE_RESET = 'PUT_INVOICE_RESET';
export const PUT_INVOICE_LOADING = 'PUT_INVOICE_LOADING';
export const PUT_INVOICE_SUCCESS = 'PUT_INVOICE_SUCCESS';
export const PUT_INVOICE_REJECT = 'PUT_INVOICE_REJECT';

//* ACTIONS ------------------------------------------------
export const putInvoiceReset = () => ({ type: PUT_INVOICE_RESET });
export const putInvoiceLoading = () => ({ type: PUT_INVOICE_LOADING });
export const putInvoiceSuccess = (payload) => ({ type: PUT_INVOICE_SUCCESS, payload });
export const putInvoiceReject = (payload) => ({ type: PUT_INVOICE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putInvoiceRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Invoices`, options);
};


