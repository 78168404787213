import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_DELIVERY_POINT_ID_LOADING = 'GET_DELIVERY_POINT_ID_LOADING';
export const GET_DELIVERY_POINT_ID_SUCCESS = 'GET_DELIVERY_POINT_ID_SUCCESS';
export const GET_DELIVERY_POINT_ID_REJECT = 'GET_DELIVERY_POINT_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getDeliveryPointIdLoading = () => ({ type: GET_DELIVERY_POINT_ID_LOADING });
export const getDeliveryPointIdSuccess = (payload) => ({ type: GET_DELIVERY_POINT_ID_SUCCESS, payload });
export const getDeliveryPointIdReject = (payload) => ({ type: GET_DELIVERY_POINT_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getDeliveryPointIdRequest = async ({ params = {}, id }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/DeliveryPoints/${id}`, options);
};