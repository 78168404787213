import {
    putPassAdminLoading,
    putPassAdminRequest,
    putPassAdminSuccess,
    putPassAdminReject,
    putPassAdminReset
} from "../../actions/putPass";

export const putPassAdmin = (formData) => async (dispatch, getState) => {
    dispatch(putPassAdminLoading());
    try {
        const { data } = await putPassAdminRequest(formData, getState);
        dispatch(putPassAdminSuccess(data))
    } catch (error) {
        dispatch(putPassAdminReject(error))
    } finally {
        setTimeout(() => { dispatch(putPassAdminReset()) }, 3000);
    }
    return Promise.resolve();
};