import TransportBrand from "../../../../pages/Masters/TransportBrand";
import TransportBrandNewEdit from "../../../../pages/Masters/TransportBrand/NewEdit";

const routerTransportBrand = [
    {
        key: "transportBrand",
        path: "/config/transportBrand",
        element: <TransportBrand />,
    },
    {
        key: "transportBrand",
        path: "/config/transportBrand/new",
        element: <TransportBrandNewEdit />,
    },
    {
        key: "transportBrand",
        path: "/config/transportBrand/:id",
        element: <TransportBrandNewEdit />,
    },
]

export default routerTransportBrand
