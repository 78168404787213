import {
    putOrderLoading,
    putOrderRequest,
    putOrderSuccess,
    putOrderReject,
    putOrderReset
} from "../../actions/order/put";

export const putOrder = (formData) => async (dispatch, getState) => {
    dispatch(putOrderLoading());
    try {
        const { data } = await putOrderRequest(formData, getState);
        dispatch(putOrderSuccess(data))
    } catch (error) {
        dispatch(putOrderReject(error))
    } finally {
        setTimeout(() => { dispatch(putOrderReset()) }, 3000);
    }
    return Promise.resolve();
};