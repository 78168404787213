import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Stack,
    Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import CloseIcon from '@mui/icons-material/Close';

import InputTextPass from "../../../components/form/InputTextPass";
import validatorPass from "./validatorPass"
import { putPassAdmin } from "../../../store/auth/thunk/profile/putPass";

const ChangePass = ({
    __,
    open,
    onClose,
    current,
}) => {
    const dispatch = useDispatch();

    const putPass = useSelector(state => state.auth.login.putPass);

    const onSubmit = (values) => {
        const body = {
            idUser: get(current, "idUser"),
            oldPass: get(values, "oldPass"),
            newPass: get(values, "pass"),
        }
        dispatch(putPassAdmin(body))
    }

    const initialValues = {
        oldPass: "",
        pass: "",
        confirmation: "",
    }

    return (
        <div>
            <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className='px-6'>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                        {__(`changePass.labels.1`)}
                    </Typography>
                </Box>
                <Box className='flex flex-col justify-center m-6'>
                    <Formik initialValues={initialValues} validationSchema={validatorPass(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    {/* <Grid item xs={12}>
                                        <Typography component="p" variant="bmr" >{__(`changePass.labels.2`)}</Typography>
                                    </Grid> */}
                                    <Grid item xs={12} md={6}>
                                        <InputTextPass
                                            formik={formik}
                                            name="oldPass"
                                            label={__(`changePass.oldPass.label`)}
                                            placeholder={__(`changePass.oldPass.placeholder`)}
                                            disabled={get(putPass, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} />
                                    {/* <Grid item xs={12}>
                                        <Typography component="p" variant="bmr" >{__(`changePass.labels.3`)}</Typography>
                                    </Grid> */}
                                    <Grid item xs={12} md={6}>
                                        <InputTextPass
                                            formik={formik}
                                            name="pass"
                                            label={__(`changePass.pass.label`)}
                                            placeholder={__(`changePass.pass.placeholder`)}
                                            disabled={get(putPass, "isLoading", false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputTextPass
                                            formik={formik}
                                            name="confirmation"
                                            label={__(`changePass.confirmation.label`)}
                                            placeholder={__(`changePass.confirmation.placeholder`)}
                                            disabled={get(putPass, "isLoading", false)}
                                        />
                                    </Grid>
                                </Grid>
                                <Stack className='mt-6' direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                                    <LoadingButton autoFocus loading={get(putPass, "isLoading", false)} type="submit" variant="contained" color="secondary" fullWidth >
                                        {__(`changePass.btn.acept`)}
                                    </LoadingButton>
                                    <Button variant="outlined" color="secondary" onClick={onClose} fullWidth>
                                        {__(`changePass.btn.close`)}
                                    </Button>
                                </Stack>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Dialog>
        </div >
    );
}

export default ChangePass