import { createTheme } from '@mui/material/styles';

import palette from "./palette";
import breakpoints from "./breakpoints";
import typography from "./typography";
import shadows from "./shadows";
import components from "./components";

export default createTheme({
    palette,
    breakpoints,
    typography,
    shadows,
    components
});

