const shadows = [
    "none",
    "0px 1px 2px rgba(16, 24, 40, 0.1)",
    "0px 1px 2px rgba(16, 24, 40, 0.06), 0px 2px 4px rgba(16, 24, 40, 0.1)",
    "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.12)",
    "0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1)",
    "0px 8px 8px -4px rgba(16, 24, 40, 0.05), 0px 20px 20px -4px rgba(16, 24, 40, 0.09)",
    "0px 8px 15px -4px rgba(16, 24, 40, 0.05), 0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
    ...Array.from({ length: 18 }, (v, i) => "0px 8px 15px -4px rgba(16, 24, 40, 0.05), 0px 24px 48px -12px rgba(16, 24, 40, 0.18)")
    // Esta última linea es solo para evitar el warning de que no hay sombra 24
];

export default shadows;



