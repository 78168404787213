import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            erpCode: yup.string().required(__(`formItems.erpCode.error`)),
            name: yup.string().required(__(`formItems.name.error`)),
            price: yup.string().required(__(`formItems.price.error`)),
            weight: yup.string().required(__(`formItems.weight.error`)),
            volume: yup.string().required(__(`formItems.volume.error`)),
        })
    )
}

export default validators
