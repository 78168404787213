import {
    putClientLoading,
    putClientRequest,
    putClientSuccess,
    putClientReject,
    putClientReset
} from "../../actions/client/put";

export const putClient = (formData) => async (dispatch, getState) => {
    dispatch(putClientLoading());
    try {
        const { data } = await putClientRequest(formData, getState);
        dispatch(putClientSuccess(data))
    } catch (error) {
        dispatch(putClientReject(error))
    } finally {
        setTimeout(() => { dispatch(putClientReset()) }, 3000);
    }
    return Promise.resolve();
};