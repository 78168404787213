import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_CLIENT_RESET = 'POST_CLIENT_RESET';
export const POST_CLIENT_LOADING = 'POST_CLIENT_LOADING';
export const POST_CLIENT_SUCCESS = 'POST_CLIENT_SUCCESS';
export const POST_CLIENT_REJECT = 'POST_CLIENT_REJECT';

//* ACTIONS ------------------------------------------------
export const postClientReset = () => ({ type: POST_CLIENT_RESET });
export const postClientLoading = () => ({ type: POST_CLIENT_LOADING });
export const postClientSuccess = (payload) => ({ type: POST_CLIENT_SUCCESS, payload });
export const postClientReject = (payload) => ({ type: POST_CLIENT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postClientRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Clients`, options);
};


