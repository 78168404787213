/*global google*/
import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, DirectionsRenderer, MarkerF } from "@react-google-maps/api";
import get from "lodash/get"
import map from "lodash/map"
import sumBy from "lodash/sumBy"
import { Backdrop } from "@mui/material"
import CircularProgress from "../form/CircularProgress"

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const defaultLocation = {
    lat: 13.984363243687213,
    lng: -89.54855754989579
};
let directionsService;

const Map = (props) => {
    const { setKm, loading, routes } = props;
    const [directions, setDirections] = useState();

    useEffect(() => {
        const sum = sumBy(get(directions, "routes[0].legs"), ({ distance }) => distance.value)
        const tim = sumBy(get(directions, "routes[0].legs"), ({ duration }) => duration.value)
        setKm({
            distance:
                { value: sum, text: `${(sum / 1000).toFixed(1)} Km` },
            time: { value: tim }
        })
    }, [get(directions, "routes[0].legs.length")])

    const { isLoaded, loadError } = useLoadScript({
        id: `google-map-many`,
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        language: props.__("lang.current.lang"),
        libraries: ["places"]
    });

    const onMapLoad = async map => {
        directionsService = new google.maps.DirectionsService();

        const routesCopy = await props.routes.map(route => {
            return {
                location: { lat: route?.location.lat, lng: route?.location.lng },
                stopover: true
            };
        });

        const origin =
            await routesCopy.length === 1
                ? new google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.shift().location;
        const destination =
            await routesCopy.length === 1
                ? new google.maps.LatLng(
                    routesCopy[0].location.lat,
                    routesCopy[0].location.lng
                )
                : routesCopy.pop().location;
        //put all the remaining coordinates in waypoints after(pop and shift)
        const waypoints = await routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    //function that is calling the directions service
    const getDirection = async (origin, destination, waypoints) => {
        //this will check if there is a waypoint meaning the array  has 3 or more coordinates
        waypoints.length >= 1
            ? directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        //changing the state of directions to the result of direction service
                        setDirections(result)
                    } else {
                        // console.error(`error fetching directions ${result}`);
                    }
                }
            )
            : directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        //changing the state of directions to the result of direction service
                        setDirections(result)
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }
            );
    };

    const onLoad = (map) => {
        onMapLoad(map)
    }

    const renderMap = () => {
        return (
            <GoogleMap
                id="mapa-modal-content"
                name="mapa-modal-content"
                center={defaultLocation}
                zoom={9}
                onLoad={onLoad}
                mapContainerStyle={{ flex: 1 }}
            >
                {!!directions && (<DirectionsRenderer
                    // directions={directions}
                    options={{
                        directions: directions,
                        suppressMarkers: true
                    }}
                />)}

                {map(routes, ({ location: { id, lat, lng } }, i) =>
                    <MarkerF
                        key={i}
                        label={`${id}`}
                        position={{ lat, lng }}
                        style={{ zIndex: id, }}
                        options={{
                            zIndex: i + 1
                        }}
                    />
                )}
            </GoogleMap>
        )
    }

    if (loadError) {
        return <div>Lo sentimos, el mapa no puede ser cargado por el momento</div>;
    }
    return (
        <div className="h-full w-full relative">
            {isLoaded && <div style={{ flexDirection: "column", display: "flex", height: "100%", width: "100%" }}>{renderMap()}</div>}
            <Backdrop
                className='!absolute'
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!isLoaded || loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )

}

export default Map

