import Routes from "../../../../pages/Transactions/Routes";
import RoutesNew from "../../../../pages/Transactions/Routes/New";
import RoutesDetail from "../../../../pages/Transactions/Routes/Detail";

const routerRoutes = [
    {
        key: "routes",
        path: "/transactions/routes",
        element: <Routes />,
    },
    {
        key: "routes",
        path: "/transactions/routes/new",
        element: <RoutesNew />,
    },
    {
        key: "routes",
        path: "/transactions/routes/detail/:id",
        element: <RoutesDetail />,
    },
    {
        key: "routes",
        path: "/transactions/routes/:id",
        element: <RoutesDetail />,
    },
]

export default routerRoutes
