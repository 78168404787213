import React from 'react';
import { SvgIcon } from '@mui/material'

const Padlock = (props) => (
    <SvgIcon viewBox="0 0 126 228" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "215px", height: "auto" }}>
        <path d="M80.4031 225.612C79.8429 225.928 79.8429 226.44 80.4031 226.78C80.9633 227.097 81.8645 227.097 82.4247 226.78C82.9849 226.464 82.9849 225.953 82.4247 225.612C81.8645 225.295 80.9633 225.295 80.4031 225.612Z" fill="#00283B" />
        <path d="M75.1421 222.569C74.5819 222.885 74.5819 223.397 75.1421 223.738C75.7023 224.054 76.6035 224.054 77.1637 223.738C77.7239 223.421 77.7239 222.91 77.1637 222.569C76.6035 222.252 75.7023 222.252 75.1421 222.569Z" fill="#00283B" />
        <path d="M69.8811 219.55C69.3209 219.866 69.3209 220.378 69.8811 220.718C70.4413 221.035 71.3425 221.035 71.9027 220.718C72.4629 220.402 72.4629 219.891 71.9027 219.55C71.3425 219.233 70.4413 219.233 69.8811 219.55Z" fill="#00283B" />
        <path d="M64.6199 216.507C64.0597 216.823 64.0597 217.335 64.6199 217.676C65.1801 217.992 66.0813 217.992 66.6415 217.676C67.2017 217.359 67.2017 216.848 66.6415 216.507C66.0813 216.19 65.1801 216.19 64.6199 216.507Z" fill="#00283B" />
        <path d="M59.3345 213.488C58.7743 213.804 58.7743 214.316 59.3345 214.656C59.8947 214.973 60.7959 214.973 61.3561 214.656C61.9163 214.34 61.9163 213.829 61.3561 213.488C60.7959 213.171 59.8947 213.171 59.3345 213.488Z" fill="#00283B" />
        <path d="M54.0735 210.445C53.5133 210.761 53.5133 211.273 54.0735 211.613C54.6337 211.93 55.5349 211.93 56.0951 211.613C56.6553 211.297 56.6553 210.786 56.0951 210.445C55.5349 210.128 54.6337 210.128 54.0735 210.445Z" fill="#00283B" />
        <path d="M48.8125 207.426C48.2523 207.742 48.2523 208.254 48.8125 208.594C49.3727 208.911 50.2739 208.911 50.8341 208.594C51.3943 208.278 51.3943 207.767 50.8341 207.426C50.2739 207.109 49.3727 207.109 48.8125 207.426Z" fill="#00283B" />
        <path d="M43.5515 204.382C42.9913 204.699 42.9913 205.21 43.5515 205.551C44.1117 205.867 45.0129 205.867 45.5731 205.551C46.1333 205.235 46.1333 204.723 45.5731 204.382C45.0129 204.066 44.1117 204.066 43.5515 204.382Z" fill="#00283B" />
        <path d="M38.2903 201.364C37.7301 201.68 37.7301 202.192 38.2903 202.532C38.8505 202.873 39.7517 202.849 40.3119 202.532C40.8721 202.216 40.8721 201.705 40.3119 201.364C39.7517 201.047 38.8505 201.047 38.2903 201.364Z" fill="#00283B" />
        <path d="M33.0293 198.32C32.4691 198.637 32.4691 199.148 33.0293 199.489C33.5895 199.805 34.4907 199.805 35.0509 199.489C35.6111 199.172 35.6111 198.661 35.0509 198.32C34.4907 198.004 33.5895 198.004 33.0293 198.32Z" fill="#00283B" />
        <path d="M27.7683 195.302C27.2081 195.618 27.2081 196.13 27.7683 196.47C28.3285 196.787 29.2297 196.787 29.7899 196.47C30.3501 196.154 30.3501 195.643 29.7899 195.302C29.2297 194.985 28.3285 194.985 27.7683 195.302Z" fill="#00283B" />
        <path d="M22.4829 192.258C21.9227 192.575 21.9227 193.086 22.4829 193.427C23.0431 193.743 23.9443 193.743 24.5045 193.427C25.0647 193.11 25.0647 192.599 24.5045 192.258C23.9443 191.942 23.0431 191.942 22.4829 192.258Z" fill="#00283B" />
        <path d="M17.2219 189.239C16.6617 189.556 16.6617 190.067 17.2219 190.408C17.7821 190.724 18.6833 190.724 19.2435 190.408C19.8037 190.091 19.8037 189.58 19.2435 189.239C18.6833 188.923 17.7821 188.923 17.2219 189.239Z" fill="#00283B" />
        <path d="M11.9607 186.196C11.4005 186.513 11.4005 187.024 11.9607 187.365C12.5209 187.681 13.4221 187.681 13.9823 187.365C14.5425 187.048 14.5425 186.537 13.9823 186.196C13.4221 185.855 12.5209 185.88 11.9607 186.196Z" fill="#00283B" />
        <path d="M90.9253 225.612C90.3651 225.928 90.3651 226.44 90.9253 226.78C91.4855 227.097 92.3867 227.097 92.9469 226.78C93.5071 226.464 93.5071 225.953 92.9469 225.612C92.3867 225.295 91.4855 225.295 90.9253 225.612Z" fill="#00283B" />
        <path d="M85.664 222.569C85.1038 222.885 85.1038 223.397 85.664 223.738C86.2243 224.054 87.1255 224.054 87.6857 223.738C88.2459 223.421 88.2459 222.91 87.6857 222.569C87.1255 222.252 86.2243 222.252 85.664 222.569Z" fill="#00283B" />
        <path d="M80.4031 219.55C79.8429 219.866 79.8429 220.378 80.4031 220.718C80.9633 221.035 81.8645 221.035 82.4247 220.718C82.9849 220.402 82.9849 219.891 82.4247 219.55C81.8645 219.233 80.9633 219.233 80.4031 219.55Z" fill="#00283B" />
        <path d="M75.1421 216.507C74.5819 216.823 74.5819 217.335 75.1421 217.676C75.7023 217.992 76.6035 217.992 77.1637 217.676C77.7239 217.359 77.7239 216.848 77.1637 216.507C76.6035 216.19 75.7023 216.19 75.1421 216.507Z" fill="#00283B" />
        <path d="M69.8811 213.488C69.3209 213.804 69.3209 214.316 69.8811 214.656C70.4413 214.973 71.3425 214.973 71.9027 214.656C72.4629 214.34 72.4629 213.829 71.9027 213.488C71.3425 213.171 70.4413 213.171 69.8811 213.488Z" fill="#00283B" />
        <path d="M64.6199 210.445C64.0597 210.761 64.0597 211.273 64.6199 211.613C65.1801 211.93 66.0813 211.93 66.6415 211.613C67.2017 211.297 67.2017 210.786 66.6415 210.445C66.0813 210.128 65.1801 210.128 64.6199 210.445Z" fill="#00283B" />
        <path d="M59.3345 207.426C58.7743 207.742 58.7743 208.254 59.3345 208.594C59.8947 208.911 60.7959 208.911 61.3561 208.594C61.9163 208.278 61.9163 207.767 61.3561 207.426C60.7959 207.109 59.8947 207.109 59.3345 207.426Z" fill="#00283B" />
        <path d="M54.0735 204.382C53.5133 204.699 53.5133 205.21 54.0735 205.551C54.6337 205.867 55.5349 205.867 56.0951 205.551C56.6553 205.235 56.6553 204.723 56.0951 204.382C55.5349 204.066 54.6337 204.066 54.0735 204.382Z" fill="#00283B" />
        <path d="M48.8125 201.364C48.2523 201.68 48.2523 202.192 48.8125 202.532C49.3727 202.849 50.2739 202.849 50.8341 202.532C51.3943 202.216 51.3943 201.705 50.8341 201.364C50.2739 201.047 49.3727 201.047 48.8125 201.364Z" fill="#00283B" />
        <path d="M43.5515 198.32C42.9913 198.637 42.9913 199.148 43.5515 199.489C44.1117 199.805 45.0129 199.805 45.5731 199.489C46.1333 199.172 46.1333 198.661 45.5731 198.32C45.0129 198.004 44.1117 198.004 43.5515 198.32Z" fill="#00283B" />
        <path d="M38.2903 195.302C37.7301 195.618 37.7301 196.13 38.2903 196.47C38.8505 196.787 39.7517 196.787 40.3119 196.47C40.8721 196.154 40.8721 195.643 40.3119 195.302C39.7517 194.985 38.8505 194.985 38.2903 195.302Z" fill="#00283B" />
        <path d="M33.0293 192.258C32.4691 192.575 32.4691 193.086 33.0293 193.427C33.5895 193.743 34.4907 193.743 35.0509 193.427C35.6111 193.11 35.6111 192.599 35.0509 192.258C34.4907 191.942 33.5895 191.942 33.0293 192.258Z" fill="#00283B" />
        <path d="M27.7683 189.239C27.2081 189.556 27.2081 190.067 27.7683 190.408C28.3285 190.724 29.2297 190.724 29.7899 190.408C30.3501 190.091 30.3501 189.58 29.7899 189.239C29.2297 188.923 28.3285 188.923 27.7683 189.239Z" fill="#00283B" />
        <path d="M22.4829 186.196C21.9227 186.513 21.9227 187.024 22.4829 187.365C23.0431 187.681 23.9443 187.681 24.5045 187.365C25.0647 187.048 25.0647 186.537 24.5045 186.196C23.9443 185.88 23.0431 185.88 22.4829 186.196Z" fill="#00283B" />
        <path d="M17.2219 183.177C16.6617 183.494 16.6617 184.005 17.2219 184.346C17.7821 184.662 18.6833 184.662 19.2435 184.346C19.8037 184.029 19.8037 183.518 19.2435 183.177C18.6833 182.861 17.7821 182.861 17.2219 183.177Z" fill="#00283B" />
        <path d="M101.472 225.612C100.911 225.928 100.911 226.44 101.472 226.78C102.032 227.097 102.933 227.097 103.493 226.78C104.053 226.464 104.053 225.953 103.493 225.612C102.933 225.295 102.032 225.295 101.472 225.612Z" fill="#00283B" />
        <path d="M96.2107 222.569C95.6505 222.885 95.6505 223.397 96.2107 223.738C96.7709 224.054 97.6721 224.054 98.2323 223.738C98.7925 223.421 98.7925 222.91 98.2323 222.569C97.6721 222.252 96.7709 222.252 96.2107 222.569Z" fill="#00283B" />
        <path d="M90.9253 219.55C90.3651 219.866 90.3651 220.378 90.9253 220.718C91.4855 221.035 92.3867 221.035 92.9469 220.718C93.5071 220.402 93.5071 219.891 92.9469 219.55C92.3867 219.233 91.4855 219.233 90.9253 219.55Z" fill="#00283B" />
        <path d="M85.664 216.507C85.1038 216.823 85.1038 217.335 85.664 217.676C86.2243 217.992 87.1255 217.992 87.6857 217.676C88.2459 217.359 88.2459 216.848 87.6857 216.507C87.1255 216.19 86.2243 216.19 85.664 216.507Z" fill="#00283B" />
        <path d="M80.4031 213.488C79.8429 213.804 79.8429 214.316 80.4031 214.656C80.9633 214.973 81.8645 214.973 82.4247 214.656C82.9849 214.34 82.9849 213.829 82.4247 213.488C81.8645 213.171 80.9633 213.171 80.4031 213.488Z" fill="#00283B" />
        <path d="M75.1421 210.445C74.5819 210.761 74.5819 211.273 75.1421 211.613C75.7023 211.93 76.6035 211.93 77.1637 211.613C77.7239 211.297 77.7239 210.786 77.1637 210.445C76.6035 210.128 75.7023 210.128 75.1421 210.445Z" fill="#00283B" />
        <path d="M71.8783 207.426C71.3181 207.109 70.4169 207.109 69.8567 207.426C69.2965 207.742 69.2965 208.254 69.8567 208.594C70.4169 208.911 71.3181 208.911 71.8783 208.594C72.4385 208.278 72.4385 207.767 71.8783 207.426C72.4385 207.742 71.3181 207.109 71.8783 207.426Z" fill="#00283B" />
        <path d="M64.6199 204.382C64.0597 204.699 64.0597 205.21 64.6199 205.551C65.1801 205.867 66.0813 205.867 66.6415 205.551C67.2017 205.235 67.2017 204.723 66.6415 204.382C66.0813 204.066 65.1801 204.066 64.6199 204.382Z" fill="#00283B" />
        <path d="M59.3345 201.364C58.7743 201.68 58.7743 202.192 59.3345 202.532C59.8947 202.849 60.7959 202.849 61.3561 202.532C61.9163 202.216 61.9163 201.705 61.3561 201.364C60.7959 201.047 59.8947 201.047 59.3345 201.364Z" fill="#00283B" />
        <path d="M54.0735 198.32C53.5133 198.637 53.5133 199.148 54.0735 199.489C54.6337 199.805 55.5349 199.805 56.0951 199.489C56.6553 199.172 56.6553 198.661 56.0951 198.32C55.5349 198.004 54.6337 198.004 54.0735 198.32Z" fill="#00283B" />
        <path d="M48.8125 195.302C48.2523 195.618 48.2523 196.13 48.8125 196.47C49.3727 196.787 50.2739 196.787 50.8341 196.47C51.3943 196.154 51.3943 195.643 50.8341 195.302C50.2739 194.985 49.3727 194.985 48.8125 195.302Z" fill="#00283B" />
        <path d="M43.5515 192.258C42.9913 192.575 42.9913 193.086 43.5515 193.427C44.1117 193.743 45.0129 193.743 45.5731 193.427C46.1333 193.11 46.1333 192.599 45.5731 192.258C45.0129 191.942 44.1117 191.942 43.5515 192.258Z" fill="#00283B" />
        <path d="M38.2903 189.239C37.7301 189.556 37.7301 190.067 38.2903 190.408C38.8505 190.724 39.7517 190.724 40.3119 190.408C40.8721 190.091 40.8721 189.58 40.3119 189.239C39.7517 188.923 38.8505 188.923 38.2903 189.239Z" fill="#00283B" />
        <path d="M33.0293 186.196C32.4691 186.513 32.4691 187.024 33.0293 187.365C33.5895 187.681 34.4907 187.681 35.0509 187.365C35.6111 187.048 35.6111 186.537 35.0509 186.196C34.4907 185.88 33.5895 185.88 33.0293 186.196Z" fill="#00283B" />
        <path d="M27.7683 183.177C27.2081 183.494 27.2081 184.005 27.7683 184.346C28.3285 184.662 29.2297 184.662 29.7899 184.346C30.3501 184.029 30.3501 183.518 29.7899 183.177C29.2297 182.861 28.3285 182.861 27.7683 183.177Z" fill="#00283B" />
        <path d="M22.4829 180.134C21.9227 180.451 21.9227 180.962 22.4829 181.303C23.0431 181.619 23.9443 181.619 24.5045 181.303C25.0647 180.986 25.0647 180.475 24.5045 180.134C23.9443 179.818 23.0431 179.818 22.4829 180.134Z" fill="#00283B" />
        <path d="M106.733 222.569C106.172 222.885 106.172 223.397 106.733 223.738C107.293 224.054 108.194 224.054 108.754 223.738C109.314 223.421 109.314 222.91 108.754 222.569C108.194 222.252 107.293 222.252 106.733 222.569Z" fill="#00283B" />
        <path d="M101.472 219.55C100.911 219.866 100.911 220.378 101.472 220.718C102.032 221.035 102.933 221.035 103.493 220.718C104.053 220.402 104.053 219.891 103.493 219.55C102.933 219.233 102.032 219.233 101.472 219.55Z" fill="#00283B" />
        <path d="M96.2107 216.507C95.6505 216.823 95.6505 217.335 96.2107 217.676C96.7709 217.992 97.6721 217.992 98.2323 217.676C98.7925 217.359 98.7925 216.848 98.2323 216.507C97.6721 216.19 96.7709 216.19 96.2107 216.507Z" fill="#00283B" />
        <path d="M90.9253 213.488C90.3651 213.804 90.3651 214.316 90.9253 214.656C91.4855 214.973 92.3867 214.973 92.9469 214.656C93.5071 214.34 93.5071 213.829 92.9469 213.488C92.3867 213.171 91.4855 213.171 90.9253 213.488Z" fill="#00283B" />
        <path d="M111.994 219.55C111.433 219.866 111.433 220.378 111.994 220.718C112.554 221.035 113.455 221.035 114.015 220.718C114.575 220.402 114.575 219.891 114.015 219.55C113.455 219.233 112.554 219.233 111.994 219.55Z" fill="#00283B" />
        <path d="M106.733 216.507C106.172 216.823 106.172 217.335 106.733 217.676C107.293 217.992 108.194 217.992 108.754 217.676C109.314 217.359 109.314 216.848 108.754 216.507C108.194 216.19 107.293 216.19 106.733 216.507Z" fill="#00283B" />
        <path d="M101.472 213.488C100.911 213.804 100.911 214.316 101.472 214.656C102.032 214.973 102.933 214.973 103.493 214.656C104.053 214.34 104.053 213.829 103.493 213.488C102.933 213.171 102.032 213.171 101.472 213.488Z" fill="#00283B" />
        <path d="M117.255 216.507C116.695 216.823 116.695 217.335 117.255 217.676C117.815 217.992 118.716 217.992 119.276 217.676C119.837 217.359 119.837 216.848 119.276 216.507C118.716 216.19 117.815 216.19 117.255 216.507Z" fill="#00283B" />
        <path d="M111.994 213.488C111.433 213.804 111.433 214.316 111.994 214.656C112.554 214.973 113.455 214.973 114.015 214.656C114.575 214.34 114.575 213.829 114.015 213.488C113.455 213.171 112.554 213.171 111.994 213.488Z" fill="#00283B" />
        <path d="M106.733 210.445C106.172 210.761 106.172 211.273 106.733 211.613C107.293 211.93 108.194 211.93 108.754 211.613C109.314 211.297 109.314 210.786 108.754 210.445C108.194 210.128 107.293 210.128 106.733 210.445Z" fill="#00283B" />
        <path d="M122.516 213.488C121.956 213.804 121.956 214.316 122.516 214.656C123.076 214.973 123.977 214.973 124.537 214.656C125.098 214.34 125.098 213.829 124.537 213.488C123.977 213.171 123.076 213.171 122.516 213.488Z" fill="#00283B" />
        <path d="M117.255 210.445C116.695 210.761 116.695 211.273 117.255 211.613C117.815 211.93 118.716 211.93 119.276 211.613C119.837 211.297 119.837 210.786 119.276 210.445C118.716 210.128 117.815 210.128 117.255 210.445Z" fill="#00283B" />
        <path d="M111.994 207.426C111.433 207.742 111.433 208.254 111.994 208.594C112.554 208.911 113.455 208.911 114.015 208.594C114.575 208.278 114.575 207.767 114.015 207.426C113.455 207.109 112.554 207.109 111.994 207.426Z" fill="#00283B" />
        <path d="M122.564 207.377C122.004 207.694 122.004 208.205 122.564 208.546C123.125 208.862 124.026 208.862 124.586 208.546C125.146 208.229 125.146 207.718 124.586 207.377C124.026 207.061 123.125 207.061 122.564 207.377Z" fill="#00283B" />
        <path d="M117.303 204.358C116.743 204.674 116.743 205.186 117.303 205.527C117.864 205.843 118.765 205.843 119.325 205.527C119.885 205.21 119.885 204.699 119.325 204.358C118.765 204.041 117.864 204.041 117.303 204.358Z" fill="#00283B" />
        <path d="M122.564 201.315C122.004 201.632 122.004 202.143 122.564 202.484C123.125 202.8 124.026 202.8 124.586 202.484C125.146 202.167 125.146 201.656 124.586 201.315C124.026 200.999 123.125 200.999 122.564 201.315Z" fill="#00283B" />
        <path d="M117.303 198.296C116.743 198.612 116.743 199.124 117.303 199.465C117.864 199.781 118.765 199.781 119.325 199.465C119.885 199.148 119.885 198.637 119.325 198.296C118.765 197.979 117.864 197.979 117.303 198.296Z" fill="#00283B" />
        <path d="M8.69689 183.226C8.13668 182.91 7.23548 182.91 6.67528 183.226C6.11508 183.543 6.11508 184.054 6.67528 184.395C7.23548 184.711 8.13668 184.711 8.69689 184.395C9.25709 184.078 9.25709 183.567 8.69689 183.226Z" fill="#00283B" />
        <path d="M1.41429 180.183C0.85409 180.499 0.85409 181.01 1.41429 181.351C1.9745 181.668 2.8757 181.668 3.4359 181.351C3.9961 181.035 3.9961 180.524 3.4359 180.183C2.8757 179.866 1.9745 179.866 1.41429 180.183Z" fill="#00283B" />
        <path d="M13.9581 180.183C13.3979 179.866 12.4967 179.866 11.9365 180.183C11.3763 180.499 11.3763 181.01 11.9365 181.351C12.4967 181.668 13.3979 181.668 13.9581 181.351C14.5183 181.035 14.5183 180.524 13.9581 180.183Z" fill="#00283B" />
        <path d="M6.67528 177.164C6.11508 177.481 6.11508 177.992 6.67528 178.333C7.23548 178.649 8.13668 178.649 8.69689 178.333C9.25709 178.016 9.25709 177.505 8.69689 177.164C8.13668 176.848 7.23548 176.848 6.67528 177.164Z" fill="#00283B" />
        <path d="M1.41429 174.121C0.85409 174.437 0.85409 174.948 1.41429 175.289C1.9745 175.606 2.8757 175.606 3.4359 175.289C3.9961 174.973 3.9961 174.462 3.4359 174.121C2.8757 173.804 1.9745 173.804 1.41429 174.121Z" fill="#00283B" />
        <path d="M19.2435 177.164C18.6833 176.848 17.7821 176.848 17.2219 177.164C16.6617 177.481 16.6617 177.992 17.2219 178.333C17.7821 178.674 18.6833 178.649 19.2435 178.333C19.8037 178.016 19.8037 177.505 19.2435 177.164Z" fill="#00283B" />
        <path d="M11.9365 174.121C11.3763 174.437 11.3763 174.948 11.9365 175.289C12.4967 175.606 13.3979 175.606 13.9581 175.289C14.5183 174.973 14.5183 174.462 13.9581 174.121C13.3979 173.804 12.4967 173.804 11.9365 174.121Z" fill="#00283B" />
        <path d="M6.67528 171.102C6.11508 171.419 6.11508 171.93 6.67528 172.271C7.23548 172.587 8.13668 172.587 8.69689 172.271C9.25709 171.954 9.25709 171.443 8.69689 171.102C8.13668 170.786 7.23548 170.786 6.67528 171.102Z" fill="#00283B" />
        <path d="M1.41429 168.059C0.85409 168.375 0.85409 168.886 1.41429 169.227C1.9745 169.544 2.8757 169.544 3.4359 169.227C3.9961 168.911 3.9961 168.4 3.4359 168.059C2.8757 167.742 1.9745 167.742 1.41429 168.059Z" fill="#00283B" />
        <path d="M116.816 197.736C116.816 197.395 116.841 197.055 116.816 196.714V97.4317L14.4694 57.0664L3.28964 58.5272V158.832C3.1435 161.729 4.79976 164.504 8.35584 166.549L80.1351 208.083C87.6856 212.441 100.911 211.906 109.631 206.866C114.161 204.237 116.573 200.926 116.816 197.736Z" fill="#B0E2FA" />
        <path d="M92.9468 212.246C87.9293 212.246 83.1067 211.224 79.5263 209.154L7.72268 167.62C3.89868 165.405 1.87707 162.264 2.04757 158.783V57.4559L14.6156 55.8247L14.9079 55.9464L118.034 96.6039V196.714C118.059 197.006 118.059 197.42 118.034 197.809C117.742 201.632 114.965 205.21 110.24 207.937C105.296 210.786 98.9629 212.246 92.9468 212.246ZM4.48324 59.5983V158.832C4.36145 161.437 5.94464 163.774 8.94052 165.502L80.7197 207.036C87.9293 211.199 100.619 210.639 108.998 205.819C112.968 203.531 115.355 200.56 115.574 197.663C115.574 197.371 115.574 197.103 115.574 196.811V98.2594L14.299 58.3323L4.48324 59.5983Z" fill="#00283B" />
        <path d="M80.1353 107.803L8.35604 66.245C0.805462 61.8871 1.75537 54.2668 10.4751 49.2273C19.1948 44.1877 32.4205 43.6521 39.971 48.01L111.75 89.5439C119.301 93.9018 118.351 101.522 109.631 106.562C100.912 111.601 87.6858 112.137 80.1353 107.779V107.803Z" fill="#B0E2FA" />
        <path d="M80.7441 115.569L8.94051 74.0111C1.38993 69.6532 2.33985 62.033 11.0595 56.9934C19.7792 51.9538 33.0049 51.4182 40.5555 55.7761L112.335 97.31C119.885 101.668 118.935 109.288 110.216 114.328C101.496 119.367 88.2703 119.903 80.7197 115.545L80.7441 115.569Z" fill="#F5FCFF" />
        <path d="M92.9468 111.942C87.9293 111.942 83.1067 110.919 79.5262 108.85L7.72266 67.316C4.0448 65.1979 2.02319 62.2034 2.02319 58.868C2.02319 54.9484 4.87293 51.053 9.8417 48.1802C18.878 42.9459 32.6639 42.4103 40.5555 46.9629L112.335 88.4968C116.013 90.6149 118.034 93.6338 118.034 96.9448C118.034 100.864 115.184 104.76 110.216 107.633C105.271 110.481 98.9385 111.942 92.9224 111.942H92.9468ZM80.7441 106.756C87.9537 110.919 100.644 110.384 109.022 105.539C113.212 103.104 115.623 99.988 115.623 96.9692C115.623 94.5589 114.015 92.2948 111.117 90.6393L39.3377 49.0567C32.1281 44.8936 19.4382 45.4535 11.0595 50.274C6.84582 52.7085 4.45886 55.8248 4.45886 58.8437C4.45886 61.2539 6.06641 63.5181 8.96486 65.1736L80.7441 106.707V106.756Z" fill="#00283B" />
        <path d="M92.0212 97.5047H86.5166C83.2041 97.5047 80.5005 94.8267 80.5005 91.4913V63.1772C80.5005 48.0828 69.4426 28.9713 56.3874 21.4241C51.7352 18.7461 47.4728 17.9914 44.9884 19.4278C42.504 20.8642 41.0183 24.9299 41.0183 30.286V58.6001C41.0183 61.9112 38.339 64.6136 35.0022 64.6136H29.4976C26.185 64.6136 23.4814 61.9355 23.4814 58.6001V30.286C23.4814 18.4053 28.1092 8.93476 36.22 4.26037C44.3064 -0.414014 54.8529 0.316359 65.1314 6.25673C83.5694 16.8958 98.013 41.8989 98.013 63.1772V91.4913C98.013 94.8023 95.3337 97.5047 91.9969 97.5047H92.0212Z" fill="#B0E2FA" />
        <path d="M52.6119 135.095C52.6119 129.861 48.934 123.482 44.3793 120.853C39.8489 118.223 36.1467 120.366 36.1467 125.6C36.1467 129.763 38.485 134.632 41.7244 137.773V156.227L46.6932 159.1V140.767C50.1031 141.571 52.5875 139.404 52.5875 135.119L52.6119 135.095Z" fill="#F5FCFF" />
        <path d="M57.8975 132.977C57.8975 127.742 54.2196 121.364 49.6649 118.734C45.1346 116.105 41.4324 118.248 41.4324 123.482C41.4324 127.645 43.7706 132.514 47.0101 135.655V154.109L51.9788 156.982V138.649C55.3888 139.453 57.8732 137.286 57.8732 133.001L57.8975 132.977Z" fill="#B0E2FA" />
        <path d="M47.9355 161.193L40.5311 156.933V138.284C37.1698 134.827 34.9534 129.812 34.9534 125.6C34.9534 122.605 36.0251 120.341 37.9736 119.221C39.9221 118.101 42.4065 118.296 45.0127 119.806C49.9571 122.654 53.8542 129.374 53.8542 135.095C53.8542 137.675 53.0017 139.794 51.4672 140.986C50.4929 141.766 49.2508 142.106 47.9355 142.106V161.169V161.193ZM42.9667 155.521L45.4998 156.982V139.209L46.9856 139.55C47.8137 139.745 48.9828 139.818 49.9327 139.063C50.8583 138.308 51.3941 136.896 51.3941 135.07C51.3941 130.226 47.9842 124.31 43.7705 121.899C41.9681 120.853 40.3362 120.658 39.1671 121.315C37.998 121.997 37.3647 123.506 37.3647 125.576C37.3647 129.301 39.4594 133.853 42.577 136.872L42.9424 137.237V155.497L42.9667 155.521Z" fill="#00283B" />
        <path d="M31.6897 64.4674V34.0838C31.6897 30.9676 32.5665 27.9244 34.2228 25.295C35.9278 22.5683 38.5096 20.4989 41.5054 19.379L45.6217 17.8452L42.5041 21.6918L41.1401 27.3157L40.8478 59.7199L39.2159 63.5179L31.7141 64.443L31.6897 64.4674Z" fill="#00283B" />
        <path d="M88.6113 97.1881V62.6901C88.6113 60.4503 89.0985 58.2592 90.024 56.2141C91.3149 53.39 93.434 51.0285 96.0888 49.4217L96.1863 49.3486L98.0374 55.8246V92.7571L95.6261 96.2629L88.6113 97.1637V97.1881Z" fill="#00283B" />
        <path d="M88.6112 110.749V192.867C88.6112 194.888 87.9048 196.836 86.6139 198.394C84.568 200.877 81.3042 202.021 78.1378 201.364H77.9673C70.5385 199.782 63.402 197.03 56.8257 193.233L68.4682 201.218L82.4733 208.936L93.312 211.054L105.393 208.716L114.332 203.239L116.792 196.714L118.01 97.6997L110.873 105.661L104.273 108.947L94.6517 110.749H88.6112Z" fill="#00283B" />
        <path d="M93.3365 42.0937C93.507 42.0207 93.6288 41.8989 93.775 41.7772C88.1729 27.0967 77.5778 13.4144 65.1802 6.25673C54.8773 0.316359 44.3308 -0.414014 36.2444 4.26037C28.158 8.93476 23.5059 18.4053 23.5059 30.286V34.9847L23.6033 35.1065C23.6033 35.1065 26.5017 17.1393 42.3336 11.8319C58.1655 6.52453 70.0272 21.4241 76.2868 31.3085C82.5222 41.1929 87.3935 45.1613 93.3365 42.1181V42.0937Z" fill="#F5FCFF" />
        <path d="M92.0213 98.7221H86.5167C82.5222 98.7221 79.2827 95.4841 79.2827 91.4914V63.1773C79.2827 48.4724 68.5171 29.8235 55.7785 22.4711C51.5648 20.0365 47.6677 19.2818 45.5974 20.4748C43.5271 21.6677 42.2362 25.417 42.2362 30.2861V58.6003C42.2362 62.593 38.9967 65.8309 35.0022 65.8309H29.4976C25.5031 65.8309 22.2637 62.593 22.2637 58.6003V30.2861C22.2637 17.9672 27.135 8.10712 35.6111 3.21361C44.0873 -1.67989 55.0722 -0.949512 65.7404 5.20997C84.5194 16.0438 99.2309 41.5339 99.2309 63.2016V91.5157C99.2309 95.5084 95.9914 98.7464 91.9969 98.7464L92.0213 98.7221ZM48.0574 17.4802C50.6149 17.4802 53.7082 18.4541 56.9963 20.353C70.3925 28.095 81.7184 47.6933 81.7184 63.1773V91.4914C81.7184 94.1207 83.8618 96.2875 86.5167 96.2875H92.0213C94.6518 96.2875 96.8196 94.1451 96.8196 91.4914V63.1773C96.8196 42.2886 82.644 17.748 64.5469 7.3037C54.6337 1.58245 44.5501 0.852075 36.8533 5.30735C29.1566 9.76263 24.7237 18.8436 24.7237 30.2861V58.6003C24.7237 61.2296 26.8671 63.3964 29.522 63.3964H35.0266C37.6571 63.3964 39.8249 61.2539 39.8249 58.6003V30.2861C39.8249 24.3944 41.4811 20.0609 44.4039 18.381C45.4269 17.7967 46.6691 17.5046 48.0818 17.5046L48.0574 17.4802Z" fill="#00283B" />
    </SvgIcon>
)

export default Padlock