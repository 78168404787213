import {
    getTransportBrandLoading,
    getTransportBrandReject,
    getTransportBrandRequest,
    getTransportBrandSuccess
} from "../../actions/transportBrand/getAll";

export const getTransportBrand = (formData) => async (dispatch, getState) => {
    dispatch(getTransportBrandLoading());
    try {
        const { data } = await getTransportBrandRequest(formData, getState);
        dispatch(getTransportBrandSuccess(data))
    } catch (error) {
        dispatch(getTransportBrandReject(error))
    }
    return Promise.resolve();
};