import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_INVOICE_DOCUMENT_RESET = 'POST_INVOICE_DOCUMENT_RESET';
export const POST_INVOICE_DOCUMENT_LOADING = 'POST_INVOICE_DOCUMENT_LOADING';
export const POST_INVOICE_DOCUMENT_SUCCESS = 'POST_INVOICE_DOCUMENT_SUCCESS';
export const POST_INVOICE_DOCUMENT_REJECT = 'POST_INVOICE_DOCUMENT_REJECT';

//* ACTIONS ------------------------------------------------
export const postInvoiceDocumentReset = () => ({ type: POST_INVOICE_DOCUMENT_RESET });
export const postInvoiceDocumentLoading = () => ({ type: POST_INVOICE_DOCUMENT_LOADING });
export const postInvoiceDocumentSuccess = (payload) => ({ type: POST_INVOICE_DOCUMENT_SUCCESS, payload });
export const postInvoiceDocumentReject = (payload) => ({ type: POST_INVOICE_DOCUMENT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postInvoiceDocumentRequest = async ({idInvoice}, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: {},
    }, getState);
    return request(`/api/Invoices/InvoiceDocument/${idInvoice}`, options);
};