import {
    GET_ORDER_LOADING,
    GET_ORDER_SUCCESS,
    GET_ORDER_REJECT,
} from "../../actions/order/getAll";

import {
    GET_ORDER_HISTORY_LOADING,
    GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_HISTORY_REJECT,
} from "../../actions/order/getAllHistory";

import {
    GET_ORDER_NO_IN_ROUTE_LOADING,
    GET_ORDER_NO_IN_ROUTE_SUCCESS,
    GET_ORDER_NO_IN_ROUTE_REJECT,
} from "../../actions/order/getNoInRoute";

import {
    GET_ORDER_ID_LOADING,
    GET_ORDER_ID_SUCCESS,
    GET_ORDER_ID_REJECT,
} from "../../actions/order/getId";

import {
    GET_ORDER_IMAGES_LOADING,
    GET_ORDER_IMAGES_SUCCESS,
    GET_ORDER_IMAGES_REJECT,
} from "../../actions/order/getOrderImages";

import {
    POST_ORDER_LOADING,
    POST_ORDER_SUCCESS,
    POST_ORDER_REJECT,
    POST_ORDER_RESET,
} from "../../actions/order/post";

import {
    PUT_ORDER_LOADING,
    PUT_ORDER_SUCCESS,
    PUT_ORDER_REJECT,
    PUT_ORDER_RESET,
} from "../../actions/order/put";

import {
    DELETE_ORDER_LOADING,
    DELETE_ORDER_SUCCESS,
    DELETE_ORDER_REJECT,
    DELETE_ORDER_RESET,
} from "../../actions/order/delete";

import {
    GET_ORDER_ITEM_LOADING,
    GET_ORDER_ITEM_SUCCESS,
    GET_ORDER_ITEM_REJECT,
} from "../../actions/order/getItem";

import {
    POST_ORDER_ITEM_LOADING,
    POST_ORDER_ITEM_SUCCESS,
    POST_ORDER_ITEM_REJECT,
    POST_ORDER_ITEM_RESET,
} from "../../actions/order/postItem";

import {
    PUT_ORDER_ITEM_LOADING,
    PUT_ORDER_ITEM_SUCCESS,
    PUT_ORDER_ITEM_REJECT,
    PUT_ORDER_ITEM_RESET,
} from "../../actions/order/putItem";

import {
    DELETE_ORDER_ITEM_LOADING,
    DELETE_ORDER_ITEM_SUCCESS,
    DELETE_ORDER_ITEM_REJECT,
    DELETE_ORDER_ITEM_RESET,
} from "../../actions/order/deleteItem";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
    detail: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    images: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    history: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    noInRoute: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    post: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    put: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    delete: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    getItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    postItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    putItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    deleteItem: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}

const orderReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_ORDER_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_ORDER_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_ORDER_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        // ------------- GET ALL HISTORY --------------------
        case GET_ORDER_HISTORY_LOADING: return {
            ...state,
            history: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_ORDER_HISTORY_SUCCESS: return {
            ...state,
            history: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDER_HISTORY_REJECT: return {
            ...state,
            history: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }

        // ------------- GET NO IN ROUTE --------------------
        case GET_ORDER_NO_IN_ROUTE_LOADING: return {
            ...state,
            noInRoute: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ORDER_NO_IN_ROUTE_SUCCESS: return {
            ...state,
            noInRoute: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDER_NO_IN_ROUTE_REJECT: return {
            ...state,
            noInRoute: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- GET ID ID --------------------
        case GET_ORDER_ID_LOADING: return {
            ...state,
            detail: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_ORDER_ID_SUCCESS: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDER_ID_REJECT: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        // ------------- GET IMAGES --------------------
        case GET_ORDER_IMAGES_LOADING: return {
            ...state,
            images: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ORDER_IMAGES_SUCCESS: return {
            ...state,
            images: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDER_IMAGES_REJECT: return {
            ...state,
            images: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- POST --------------------
        case POST_ORDER_LOADING: return {
            ...state,
            post: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_ORDER_SUCCESS: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_ORDER_REJECT: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_ORDER_RESET: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT --------------------
        case PUT_ORDER_LOADING: return {
            ...state,
            put: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_ORDER_SUCCESS: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_ORDER_REJECT: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_ORDER_RESET: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE --------------------
        case DELETE_ORDER_LOADING: return {
            ...state,
            delete: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_ORDER_SUCCESS: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_ORDER_REJECT: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_ORDER_RESET: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }


        // ------------- GET ITEM --------------------
        case GET_ORDER_ITEM_LOADING: return {
            ...state,
            getItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ORDER_ITEM_SUCCESS: return {
            ...state,
            getItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDER_ITEM_REJECT: return {
            ...state,
            getItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- POST ITEM--------------------
        case POST_ORDER_ITEM_LOADING: return {
            ...state,
            postItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_ORDER_ITEM_SUCCESS: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_ORDER_ITEM_REJECT: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_ORDER_ITEM_RESET: return {
            ...state,
            postItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT ITEM--------------------
        case PUT_ORDER_ITEM_LOADING: return {
            ...state,
            putItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_ORDER_ITEM_SUCCESS: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_ORDER_ITEM_REJECT: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_ORDER_ITEM_RESET: return {
            ...state,
            putItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE ITEM --------------------
        case DELETE_ORDER_ITEM_LOADING: return {
            ...state,
            deleteItem: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_ORDER_ITEM_SUCCESS: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_ORDER_ITEM_REJECT: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_ORDER_ITEM_RESET: return {
            ...state,
            deleteItem: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        default: return state;
    }
}

export default orderReducer