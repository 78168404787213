import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_HOME_LOADING = 'GET_ROUTE_HOME_LOADING';
export const GET_ROUTE_HOME_SUCCESS = 'GET_ROUTE_HOME_SUCCESS';
export const GET_ROUTE_HOME_REJECT = 'GET_ROUTE_HOME_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteHomeLoading = () => ({ type: GET_ROUTE_HOME_LOADING });
export const getRouteHomeSuccess = (payload) => ({ type: GET_ROUTE_HOME_SUCCESS, payload });
export const getRouteHomeReject = (payload) => ({ type: GET_ROUTE_HOME_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteHomeRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Route/GetMainDashboardInfo`, options);
};
