import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_DRIVER_RESET = 'DELETE_DRIVER_RESET';
export const DELETE_DRIVER_LOADING = 'DELETE_DRIVER_LOADING';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_REJECT = 'DELETE_DRIVER_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteDriverReset = () => ({ type: DELETE_DRIVER_RESET });
export const deleteDriverLoading = () => ({ type: DELETE_DRIVER_LOADING });
export const deleteDriverSuccess = (payload) => ({ type: DELETE_DRIVER_SUCCESS, payload });
export const deleteDriverReject = (payload) => ({ type: DELETE_DRIVER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteDriverRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Driver/${data.id}`, options);
};