import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_TRANSPORT_SUPPLIER_RESET = 'POST_TRANSPORT_SUPPLIER_RESET';
export const POST_TRANSPORT_SUPPLIER_LOADING = 'POST_TRANSPORT_SUPPLIER_LOADING';
export const POST_TRANSPORT_SUPPLIER_SUCCESS = 'POST_TRANSPORT_SUPPLIER_SUCCESS';
export const POST_TRANSPORT_SUPPLIER_REJECT = 'POST_TRANSPORT_SUPPLIER_REJECT';

//* ACTIONS ------------------------------------------------
export const postTransportSupplierReset = () => ({ type: POST_TRANSPORT_SUPPLIER_RESET });
export const postTransportSupplierLoading = () => ({ type: POST_TRANSPORT_SUPPLIER_LOADING });
export const postTransportSupplierSuccess = (payload) => ({ type: POST_TRANSPORT_SUPPLIER_SUCCESS, payload });
export const postTransportSupplierReject = (payload) => ({ type: POST_TRANSPORT_SUPPLIER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postTransportSupplierRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/TransportSuppliers`, options);
};


