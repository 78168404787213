import React from 'react';
import { Toolbar, Grid, Stack, Button, IconButton, Paper, Box } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { Link } from "react-router-dom"
import { useFormik } from 'formik';
import get from "lodash/get";

// import InputSearch from "../../../components/form/InputSearch";

const ToolbarComponent = ({ setFilter, __, reload, isLoading }) => {
    const onSubmit = (values) => setFilter(values)

    const formik = useFormik({
        initialValues: {
            // search: "",
        },
        onSubmit,
    });

    return (
        <Toolbar component={Paper} className='p-2 mb-4' sx={{ borderBottomWidth: "1px", borderBottomColor: "text.sslite", borderBottomStyle: "solid" }}>
            <Stack component="form" onSubmit={get(formik, "handleSubmit")} direction={{ sx: "column", xl: "row" }} alignItems={{ sx: "flex-start", xl: "center" }} justifyContent={{ sx: "center", xl: "space-between" }} spacing={{ sx: 1, xl: 1 }} className='w-full'>
                {/* <Box className="pb-2 xl:pb-0" >
                    <InputSearch formik={formik} onSubmit={onSubmit} color="secondary" size="small" sx={{ width: 320, maxWidth: "100%" }} />
                </Box> */}
                <Grid container spacing={1} justifyContent={{ sx: "flex-start", md: "flex-end" }}>
                    <Grid item xs={12} md={4} lg={3} xl={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                            <Button className='w-[100px] min-w-[100px]' component={Link} variant="contained" color="secondary" to="new">{__(`toolbar.btn`)}</Button>
                            <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Toolbar>
    )
}

export default ToolbarComponent