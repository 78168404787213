import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Stack
} from "@mui/material"

import { get, map, isEmpty } from "lodash";

import CircularProgress from "./CircularProgress"
import Empty from "../../assets/icons/Empty";

const TableComponent = ({
    headTable,
    dataTable,
    __,
    loading,
    toolbar,
    propsTable = {},
    propsContainer = {},
    propsTableCell = {},
    action = <></>,
    empty = ""
}) => {

    return (
        <Paper>
            {toolbar}
            <TableContainer {...propsContainer} >
                <Table aria-label="table" {...propsTable}>
                    <TableHead>
                        <TableRow>
                            {map(headTable, ({ key, label, align, sx = {} }, i) => (
                                <TableCell key={i} align={align} {...propsTableCell} sx={sx} >
                                    <Typography variant="buttonSmall" component="div" >{label}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {!loading &&
                        <TableBody sx={{ '& .sticky': { bgcolor: "red" } }}>
                            {dataTable.map((row, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, }}
                                    >
                                        {map(headTable, ({ key, align, sx = {} }, i) => (
                                            <TableCell key={i} align={align} {...propsTableCell} sx={sx} >
                                                <>
                                                    {!loading &&
                                                        <Typography variant="bodySmall" component="div" >{get(row, `${[key]}`)}</Typography>
                                                    }
                                                </>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    }
                </Table>
                {loading &&
                    <Box sx={{
                        display: 'flex',
                        height: 300,
                        width: '100%',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <CircularProgress />
                    </Box>
                }
                {(isEmpty(dataTable) && !loading) &&
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        height: 500,
                        width: '100%',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Empty />
                        {!!empty
                            ? (
                                <Stack justifyContent="center" alignItems="center" spacing={1} pt={2} >
                                    <Typography variant="bml">{__(`table.empty.${empty}.name`)}</Typography>
                                    <Typography variant="caption">{__(`table.empty.${empty}.info`)}</Typography>
                                    <Box pt={2}>
                                        {action}
                                    </Box>
                                </Stack>
                            ) : (
                                <Stack justifyContent="center" alignItems="center" spacing={1} pt={2} >
                                    <Typography variant="bml">{__(`table.empty.name`)}</Typography>
                                    <Typography variant="caption">{__(`table.empty.info`)}</Typography>
                                    <Box pt={2}>
                                        {action}
                                    </Box>
                                </Stack>
                            )}
                    </Box>
                }
            </TableContainer>
        </Paper >
    );
}
export default TableComponent
