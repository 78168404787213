import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_REDIRECT = 'LOGOUT_REDIRECT';

//* ACTIONS ------------------------------------------------
export const logoutSuccess = () => { return ({ type: LOGOUT_SUCCESS }) };
export const logoutRedirect = () => ({ type: LOGOUT_REDIRECT });

//* REQUEST SERVICE -----------------------------------1----
export const logoutRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);

    return request(`/api/Auth/LogOutUser`, options);
};
