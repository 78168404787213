import {
    GET_INVOICE_LOADING,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_REJECT,
} from "../../actions/invoice/getAll";

import {
    GET_ORDERS_TO_INVOICE_LOADING,
    GET_ORDERS_TO_INVOICE_SUCCESS,
    GET_ORDERS_TO_INVOICE_REJECT,
} from "../../actions/invoice/getOrdersToInvoiceAll";

import {
    GET_INVOICE_ID_LOADING,
    GET_INVOICE_ID_SUCCESS,
    GET_INVOICE_ID_REJECT,
} from "../../actions/invoice/getId";

import {
    POST_INVOICE_LOADING,
    POST_INVOICE_SUCCESS,
    POST_INVOICE_REJECT,
    POST_INVOICE_RESET,
} from "../../actions/invoice/post";

import {
    POST_INVOICE_DOCUMENT_LOADING,
    POST_INVOICE_DOCUMENT_SUCCESS,
    POST_INVOICE_DOCUMENT_REJECT,
    POST_INVOICE_DOCUMENT_RESET,
} from "../../actions/invoice/postInvoiceDocument";

import {
    POST_UPLOAD_INVOICE_DETAILS_LOADING,
    POST_UPLOAD_INVOICE_DETAILS_SUCCESS,
    POST_UPLOAD_INVOICE_DETAILS_REJECT,
    POST_UPLOAD_INVOICE_DETAILS_RESET,
} from "../../actions/invoice/postUploadInvoiceDetails";

import {
    PUT_INVOICE_LOADING,
    PUT_INVOICE_SUCCESS,
    PUT_INVOICE_REJECT,
    PUT_INVOICE_RESET,
} from "../../actions/invoice/put";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
    ordersToInvoice: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    detail: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: [],
    },
    post: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    document: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    invoiceDetails: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    put: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}

const invoiceReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_INVOICE_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_INVOICE_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_INVOICE_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        // ------------- GET ORDERS_TO_INVOICE ALL --------------------
        case GET_ORDERS_TO_INVOICE_LOADING: return {
            ...state,
            ordersToInvoice: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: [],
                error: {},
            }
        }
        case GET_ORDERS_TO_INVOICE_SUCCESS: return {
            ...state,
            ordersToInvoice: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_ORDERS_TO_INVOICE_REJECT: return {
            ...state,
            ordersToInvoice: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: [],
                error: action.payload?.response?.data
            }
        }

        // ------------- GET ID ID --------------------
        case GET_INVOICE_ID_LOADING: return {
            ...state,
            detail: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_INVOICE_ID_SUCCESS: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_INVOICE_ID_REJECT: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }

        // ------------- POST INVOICE_DOCUMENT --------------------
        case POST_INVOICE_DOCUMENT_LOADING: return {
            ...state,
            document: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_INVOICE_DOCUMENT_SUCCESS: return {
            ...state,
            document: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_INVOICE_DOCUMENT_REJECT: return {
            ...state,
            document: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_INVOICE_DOCUMENT_RESET: return {
            ...state,
            document: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- POST UPLOAD_INVOICE_DETAILS --------------------
        case POST_UPLOAD_INVOICE_DETAILS_LOADING: return {
            ...state,
            invoiceDetails: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_UPLOAD_INVOICE_DETAILS_SUCCESS: return {
            ...state,
            invoiceDetails: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_UPLOAD_INVOICE_DETAILS_REJECT: return {
            ...state,
            invoiceDetails: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_UPLOAD_INVOICE_DETAILS_RESET: return {
            ...state,
            invoiceDetails: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- POST --------------------
        case POST_INVOICE_LOADING: return {
            ...state,
            post: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_INVOICE_SUCCESS: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_INVOICE_REJECT: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_INVOICE_RESET: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT --------------------
        case PUT_INVOICE_LOADING: return {
            ...state,
            put: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_INVOICE_SUCCESS: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_INVOICE_REJECT: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_INVOICE_RESET: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        default: return state;
    }
}

export default invoiceReducer