import React from 'react';
import { SvgIcon } from '@mui/material'

const TruckOpen = (props) => (
    <SvgIcon viewBox="0 0 290 273" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "491px", height: "auto" }} >
        <path d="M18.8871 257.036C19.5353 257.426 19.5353 258.01 18.8871 258.399C18.2389 258.789 17.2017 258.789 16.5535 258.399C15.9053 258.01 15.9053 257.426 16.5535 257.036C17.2017 256.647 18.2389 256.647 18.8871 257.036Z" fill="#00283B" />
        <path d="M25.0453 253.498C25.6935 253.887 25.6935 254.472 25.0453 254.861C24.3971 255.251 23.3599 255.251 22.7117 254.861C22.0635 254.472 22.0635 253.887 22.7117 253.498C23.3599 253.108 24.3971 253.108 25.0453 253.498Z" fill="#00283B" />
        <path d="M31.1707 249.96C31.8189 250.35 31.8189 250.934 31.1707 251.323C30.5225 251.713 29.4854 251.713 28.8372 251.323C28.189 250.934 28.189 250.35 28.8372 249.96C29.4854 249.571 30.5225 249.571 31.1707 249.96Z" fill="#00283B" />
        <path d="M37.3285 246.39C37.9767 246.779 37.9767 247.364 37.3285 247.753C36.6803 248.143 35.6431 248.143 34.9949 247.753C34.3467 247.364 34.3467 246.779 34.9949 246.39C35.6431 246 36.6803 246 37.3285 246.39Z" fill="#00283B" />
        <path d="M43.4539 242.852C44.1021 243.241 44.1021 243.825 43.4539 244.215C42.8057 244.604 41.7686 244.604 41.1204 244.215C40.4722 243.825 40.4722 243.241 41.1204 242.852C41.7686 242.462 42.8057 242.462 43.4539 242.852Z" fill="#00283B" />
        <path d="M49.6117 239.281C50.2599 239.671 50.2599 240.255 49.6117 240.645C48.9635 241.034 47.9263 241.034 47.2781 240.645C46.6299 240.255 46.6299 239.671 47.2781 239.281C47.9263 238.892 48.9635 238.892 49.6117 239.281Z" fill="#00283B" />
        <path d="M55.7371 235.743C56.3853 236.133 56.3853 236.717 55.7371 237.107C55.0889 237.496 54.0518 237.496 53.4036 237.107C52.7554 236.717 52.7554 236.133 53.4036 235.743C54.0518 235.354 55.0889 235.354 55.7371 235.743Z" fill="#00283B" />
        <path d="M61.8954 232.173C62.5436 232.562 62.5436 233.147 61.8954 233.536C61.2472 233.926 60.21 233.926 59.5618 233.536C58.9136 233.147 58.9136 232.562 59.5618 232.173C60.21 231.783 61.2472 231.783 61.8954 232.173Z" fill="#00283B" />
        <path d="M68.0208 228.635C68.669 229.024 68.669 229.609 68.0208 229.998C67.3726 230.388 66.3355 230.388 65.6873 229.998C65.0391 229.609 65.0391 229.024 65.6873 228.635C66.3355 228.245 67.3726 228.245 68.0208 228.635Z" fill="#00283B" />
        <path d="M74.1786 225.097C74.8268 225.487 74.8268 226.071 74.1786 226.46C73.5304 226.85 72.4932 226.85 71.845 226.46C71.1968 226.071 71.1968 225.487 71.845 225.097C72.4932 224.708 73.5304 224.708 74.1786 225.097Z" fill="#00283B" />
        <path d="M80.304 221.527C80.9522 221.916 80.9522 222.5 80.304 222.89C79.6558 223.279 78.6187 223.279 77.9705 222.89C77.3223 222.5 77.3223 221.916 77.9705 221.527C78.6187 221.137 79.6558 221.137 80.304 221.527Z" fill="#00283B" />
        <path d="M86.4618 217.989C87.11 218.378 87.11 218.962 86.4618 219.352C85.8136 219.741 84.7764 219.741 84.1282 219.352C83.48 218.962 83.48 218.378 84.1282 217.989C84.7764 217.599 85.8136 217.599 86.4618 217.989Z" fill="#00283B" />
        <path d="M92.5872 214.418C93.2354 214.808 93.2354 215.392 92.5872 215.782C91.939 216.171 90.9019 216.171 90.2537 215.782C89.6055 215.392 89.6055 214.808 90.2537 214.418C90.9019 214.029 91.939 214.029 92.5872 214.418Z" fill="#00283B" />
        <path d="M98.7454 210.88C99.3936 211.27 99.3936 211.854 98.7454 212.243C98.0972 212.633 97.0601 212.633 96.4119 212.243C95.7637 211.854 95.7637 211.27 96.4119 210.88C97.0601 210.491 98.0972 210.491 98.7454 210.88Z" fill="#00283B" />
        <path d="M104.871 207.342C105.519 207.732 105.519 208.316 104.871 208.706C104.223 209.095 103.186 209.095 102.537 208.706C101.889 208.316 101.889 207.732 102.537 207.342C103.186 206.953 104.223 206.953 104.871 207.342Z" fill="#00283B" />
        <path d="M111.029 203.772C111.677 204.161 111.677 204.746 111.029 205.135C110.38 205.525 109.343 205.525 108.695 205.135C108.047 204.746 108.047 204.161 108.695 203.772C109.343 203.382 110.38 203.382 111.029 203.772Z" fill="#00283B" />
        <path d="M12.7616 253.498C13.4098 253.887 13.4098 254.472 12.7616 254.861C12.1134 255.251 11.0762 255.251 10.428 254.861C9.77984 254.472 9.77984 253.887 10.428 253.498C11.0762 253.108 12.1134 253.108 12.7616 253.498Z" fill="#00283B" />
        <path d="M18.8871 249.96C19.5353 250.35 19.5353 250.934 18.8871 251.323C18.2389 251.713 17.2017 251.713 16.5535 251.323C15.9053 250.934 15.9053 250.35 16.5535 249.96C17.2017 249.571 18.2389 249.571 18.8871 249.96Z" fill="#00283B" />
        <path d="M25.0453 246.39C25.6935 246.779 25.6935 247.364 25.0453 247.753C24.3971 248.143 23.3599 248.143 22.7117 247.753C22.0635 247.364 22.0635 246.779 22.7117 246.39C23.3599 246 24.3971 246 25.0453 246.39Z" fill="#00283B" />
        <path d="M31.1707 242.852C31.8189 243.241 31.8189 243.825 31.1707 244.215C30.5225 244.604 29.4854 244.604 28.8372 244.215C28.189 243.825 28.189 243.241 28.8372 242.852C29.4854 242.462 30.5225 242.462 31.1707 242.852Z" fill="#00283B" />
        <path d="M37.3285 239.281C37.9767 239.671 37.9767 240.255 37.3285 240.645C36.6803 241.034 35.6431 241.034 34.9949 240.645C34.3467 240.255 34.3467 239.671 34.9949 239.281C35.6431 238.892 36.6803 238.892 37.3285 239.281Z" fill="#00283B" />
        <path d="M43.4539 235.743C44.1021 236.133 44.1021 236.717 43.4539 237.107C42.8057 237.496 41.7686 237.496 41.1204 237.107C40.4722 236.717 40.4722 236.133 41.1204 235.743C41.7686 235.354 42.8057 235.354 43.4539 235.743Z" fill="#00283B" />
        <path d="M49.6117 232.173C50.2599 232.562 50.2599 233.147 49.6117 233.536C48.9635 233.926 47.9263 233.926 47.2781 233.536C46.6299 233.147 46.6299 232.562 47.2781 232.173C47.9263 231.783 48.9635 231.783 49.6117 232.173Z" fill="#00283B" />
        <path d="M55.7371 228.635C56.3853 229.024 56.3853 229.609 55.7371 229.998C55.0889 230.388 54.0518 230.388 53.4036 229.998C52.7554 229.609 52.7554 229.024 53.4036 228.635C54.0518 228.245 55.0889 228.245 55.7371 228.635Z" fill="#00283B" />
        <path d="M61.8954 225.097C62.5436 225.487 62.5436 226.071 61.8954 226.46C61.2472 226.85 60.21 226.85 59.5618 226.46C58.9136 226.071 58.9136 225.487 59.5618 225.097C60.21 224.708 61.2472 224.708 61.8954 225.097Z" fill="#00283B" />
        <path d="M68.0208 221.527C68.669 221.916 68.669 222.5 68.0208 222.89C67.3726 223.279 66.3355 223.279 65.6873 222.89C65.0391 222.5 65.0391 221.916 65.6873 221.527C66.3355 221.137 67.3726 221.137 68.0208 221.527Z" fill="#00283B" />
        <path d="M74.1786 217.989C74.8268 218.378 74.8268 218.962 74.1786 219.352C73.5304 219.741 72.4932 219.741 71.845 219.352C71.1968 218.962 71.1968 218.378 71.845 217.989C72.4932 217.599 73.5304 217.599 74.1786 217.989Z" fill="#00283B" />
        <path d="M80.304 214.418C80.9522 214.808 80.9522 215.392 80.304 215.782C79.6558 216.171 78.6187 216.171 77.9705 215.782C77.3223 215.392 77.3223 214.808 77.9705 214.418C78.6187 214.029 79.6558 214.029 80.304 214.418Z" fill="#00283B" />
        <path d="M86.4618 210.88C87.11 211.27 87.11 211.854 86.4618 212.243C85.8136 212.633 84.7764 212.633 84.1282 212.243C83.48 211.854 83.48 211.27 84.1282 210.88C84.7764 210.491 85.8136 210.491 86.4618 210.88Z" fill="#00283B" />
        <path d="M92.5872 207.342C93.2354 207.732 93.2354 208.316 92.5872 208.706C91.939 209.095 90.9019 209.095 90.2537 208.706C89.6055 208.316 89.6055 207.732 90.2537 207.342C90.9019 206.953 91.939 206.953 92.5872 207.342Z" fill="#00283B" />
        <path d="M98.7454 203.772C99.3936 204.161 99.3936 204.746 98.7454 205.135C98.0972 205.525 97.0601 205.525 96.4119 205.135C95.7637 204.746 95.7637 204.161 96.4119 203.772C97.0601 203.382 98.0972 203.382 98.7454 203.772Z" fill="#00283B" />
        <path d="M104.871 200.234C105.519 200.624 105.519 201.208 104.871 201.597C104.223 201.987 103.186 201.987 102.537 201.597C101.889 201.208 101.889 200.624 102.537 200.234C103.186 199.845 104.223 199.845 104.871 200.234Z" fill="#00283B" />
        <path d="M6.60385 249.96C7.25205 250.35 7.25205 250.934 6.60385 251.323C5.95565 251.713 4.91853 251.713 4.27033 251.323C3.62213 250.934 3.62213 250.35 4.27033 249.96C4.91853 249.571 5.95565 249.571 6.60385 249.96Z" fill="#00283B" />
        <path d="M12.7616 246.39C13.4098 246.779 13.4098 247.364 12.7616 247.753C12.1134 248.143 11.0762 248.143 10.428 247.753C9.77984 247.364 9.77984 246.779 10.428 246.39C11.0762 246 12.1134 246 12.7616 246.39Z" fill="#00283B" />
        <path d="M18.8871 242.852C19.5353 243.241 19.5353 243.825 18.8871 244.215C18.2389 244.604 17.2017 244.604 16.5535 244.215C15.9053 243.825 15.9053 243.241 16.5535 242.852C17.2017 242.462 18.2389 242.462 18.8871 242.852Z" fill="#00283B" />
        <path d="M25.0453 239.281C25.6935 239.671 25.6935 240.255 25.0453 240.645C24.3971 241.034 23.3599 241.034 22.7117 240.645C22.0635 240.255 22.0635 239.671 22.7117 239.281C23.3599 238.892 24.3971 238.892 25.0453 239.281Z" fill="#00283B" />
        <path d="M31.1707 235.743C31.8189 236.133 31.8189 236.717 31.1707 237.107C30.5225 237.496 29.4854 237.496 28.8372 237.107C28.189 236.717 28.189 236.133 28.8372 235.743C29.4854 235.354 30.5225 235.354 31.1707 235.743Z" fill="#00283B" />
        <path d="M37.3285 232.173C37.9767 232.562 37.9767 233.147 37.3285 233.536C36.6803 233.926 35.6431 233.926 34.9949 233.536C34.3467 233.147 34.3467 232.562 34.9949 232.173C35.6431 231.783 36.6803 231.783 37.3285 232.173Z" fill="#00283B" />
        <path d="M43.4539 228.635C44.1021 229.024 44.1021 229.609 43.4539 229.998C42.8057 230.388 41.7686 230.388 41.1204 229.998C40.4722 229.609 40.4722 229.024 41.1204 228.635C41.7686 228.245 42.8057 228.245 43.4539 228.635Z" fill="#00283B" />
        <path d="M49.6117 225.097C50.2599 225.487 50.2599 226.071 49.6117 226.46C48.9635 226.85 47.9263 226.85 47.2781 226.46C46.6299 226.071 46.6299 225.487 47.2781 225.097C47.9263 224.708 48.9635 224.708 49.6117 225.097Z" fill="#00283B" />
        <path d="M53.4036 221.527C54.0518 221.137 55.0889 221.137 55.7371 221.527C56.3853 221.916 56.3853 222.5 55.7371 222.89C55.0889 223.279 54.0518 223.279 53.4036 222.89C52.7554 222.5 52.7554 221.916 53.4036 221.527C54.0518 221.137 52.7554 221.916 53.4036 221.527Z" fill="#00283B" />
        <path d="M61.8954 217.989C62.5436 218.378 62.5436 218.962 61.8954 219.352C61.2472 219.741 60.21 219.741 59.5618 219.352C58.9136 218.962 58.9136 218.378 59.5618 217.989C60.21 217.599 61.2472 217.599 61.8954 217.989Z" fill="#00283B" />
        <path d="M68.0208 214.418C68.669 214.808 68.669 215.392 68.0208 215.782C67.3726 216.171 66.3355 216.171 65.6873 215.782C65.0391 215.392 65.0391 214.808 65.6873 214.418C66.3355 214.029 67.3726 214.029 68.0208 214.418Z" fill="#00283B" />
        <path d="M74.1786 210.88C74.8268 211.27 74.8268 211.854 74.1786 212.243C73.5304 212.633 72.4932 212.633 71.845 212.243C71.1968 211.854 71.1968 211.27 71.845 210.88C72.4932 210.491 73.5304 210.491 74.1786 210.88Z" fill="#00283B" />
        <path d="M80.304 207.342C80.9522 207.732 80.9522 208.316 80.304 208.706C79.6558 209.095 78.6187 209.095 77.9705 208.706C77.3223 208.316 77.3223 207.732 77.9705 207.342C78.6187 206.953 79.6558 206.953 80.304 207.342Z" fill="#00283B" />
        <path d="M86.4618 203.772C87.11 204.161 87.11 204.746 86.4618 205.135C85.8136 205.525 84.7764 205.525 84.1282 205.135C83.48 204.746 83.48 204.161 84.1282 203.772C84.7764 203.382 85.8136 203.382 86.4618 203.772Z" fill="#00283B" />
        <path d="M92.5872 200.234C93.2354 200.624 93.2354 201.208 92.5872 201.597C91.939 201.987 90.9019 201.987 90.2537 201.597C89.6055 201.208 89.6055 200.624 90.2537 200.234C90.9019 199.845 91.939 199.845 92.5872 200.234Z" fill="#00283B" />
        <path d="M98.7454 196.664C99.3936 197.053 99.3936 197.637 98.7454 198.027C98.0972 198.416 97.0601 198.416 96.4119 198.027C95.7637 197.637 95.7637 197.053 96.4119 196.664C97.0601 196.274 98.0972 196.274 98.7454 196.664Z" fill="#00283B" />
        <path d="M6.60385 242.852C7.25205 243.241 7.25205 243.825 6.60385 244.215C5.95565 244.604 4.91853 244.604 4.27033 244.215C3.62213 243.825 3.62213 243.241 4.27033 242.852C4.91853 242.462 5.95565 242.462 6.60385 242.852Z" fill="#00283B" />
        <path d="M12.7616 239.281C13.4098 239.671 13.4098 240.255 12.7616 240.645C12.1134 241.034 11.0762 241.034 10.428 240.645C9.77984 240.255 9.77984 239.671 10.428 239.281C11.0762 238.892 12.1134 238.892 12.7616 239.281Z" fill="#00283B" />
        <path d="M18.8871 235.743C19.5353 236.133 19.5353 236.717 18.8871 237.107C18.2389 237.496 17.2017 237.496 16.5535 237.107C15.9053 236.717 15.9053 236.133 16.5535 235.743C17.2017 235.354 18.2389 235.354 18.8871 235.743Z" fill="#00283B" />
        <path d="M25.0453 232.173C25.6935 232.562 25.6935 233.147 25.0453 233.536C24.3971 233.926 23.3599 233.926 22.7117 233.536C22.0635 233.147 22.0635 232.562 22.7117 232.173C23.3599 231.783 24.3971 231.783 25.0453 232.173Z" fill="#00283B" />
        <path d="M31.1707 228.635C31.8189 229.024 31.8189 229.609 31.1707 229.998C30.5225 230.388 29.4854 230.388 28.8372 229.998C28.189 229.609 28.189 229.024 28.8372 228.635C29.4854 228.245 30.5225 228.245 31.1707 228.635Z" fill="#00283B" />
        <path d="M77.9705 200.234C78.6187 199.845 79.6558 199.845 80.304 200.234C80.9522 200.624 80.9522 201.208 80.304 201.597C79.6558 201.987 78.6187 201.987 77.9705 201.597C77.3223 201.208 77.3223 200.624 77.9705 200.234C78.6187 199.845 77.3223 200.624 77.9705 200.234Z" fill="#00283B" />
        <path d="M86.4618 196.664C87.11 197.053 87.11 197.637 86.4618 198.027C85.8136 198.416 84.7764 198.416 84.1282 198.027C83.48 197.637 83.48 197.053 84.1282 196.664C84.7764 196.274 85.8136 196.274 86.4618 196.664Z" fill="#00283B" />
        <path d="M92.5872 193.126C93.2354 193.515 93.2354 194.099 92.5872 194.489C91.939 194.878 90.9019 194.878 90.2537 194.489C89.6055 194.099 89.6055 193.515 90.2537 193.126C90.9019 192.736 91.939 192.736 92.5872 193.126Z" fill="#00283B" />
        <path d="M6.60385 235.743C7.25205 236.133 7.25205 236.717 6.60385 237.107C5.95565 237.496 4.91853 237.496 4.27033 237.107C3.62213 236.717 3.62213 236.133 4.27033 235.743C4.91853 235.354 5.95565 235.354 6.60385 235.743Z" fill="#00283B" />
        <path d="M12.7616 232.173C13.4098 232.562 13.4098 233.147 12.7616 233.536C12.1134 233.926 11.0762 233.926 10.428 233.536C9.77984 233.147 9.77984 232.562 10.428 232.173C11.0762 231.783 12.1134 231.783 12.7616 232.173Z" fill="#00283B" />
        <path d="M18.8871 228.635C19.5353 229.024 19.5353 229.609 18.8871 229.998C18.2389 230.388 17.2017 230.388 16.5535 229.998C15.9053 229.609 15.9053 229.024 16.5535 228.635C17.2017 228.245 18.2389 228.245 18.8871 228.635Z" fill="#00283B" />
        <path d="M84.096 189.555C84.7442 189.166 85.7813 189.166 86.4295 189.555C87.0777 189.945 87.0777 190.529 86.4295 190.919C85.7813 191.308 84.7442 191.308 84.096 190.919C83.4478 190.529 83.4478 189.945 84.096 189.555C84.7442 189.166 83.4478 189.945 84.096 189.555Z" fill="#00283B" />
        <path d="M6.53891 228.603C7.18711 228.992 7.18711 229.576 6.53891 229.966C5.89071 230.355 4.85359 230.355 4.20539 229.966C3.55719 229.576 3.55719 228.992 4.20539 228.603C4.85359 228.213 5.89071 228.213 6.53891 228.603Z" fill="#00283B" />
        <path d="M56.0291 270.733C56.6773 271.123 56.6773 271.707 56.0291 272.097C55.3809 272.486 54.3438 272.486 53.6956 272.097C53.0474 271.707 53.0474 271.123 53.6956 270.733C54.3438 270.344 55.3809 270.344 56.0291 270.733Z" fill="#00283B" />
        <path d="M62.1869 267.163C62.8351 267.552 62.8351 268.137 62.1869 268.526C61.5387 268.916 60.5015 268.916 59.8533 268.526C59.2051 268.137 59.2051 267.552 59.8533 267.163C60.5015 266.773 61.5387 266.773 62.1869 267.163Z" fill="#00283B" />
        <path d="M68.3124 263.625C68.9606 264.014 68.9606 264.599 68.3124 264.988C67.6641 265.378 66.627 265.378 65.9788 264.988C65.3306 264.599 65.3306 264.014 65.9788 263.625C66.627 263.235 67.6641 263.235 68.3124 263.625Z" fill="#00283B" />
        <path d="M74.4701 260.055C75.1183 260.444 75.1183 261.028 74.4701 261.418C73.8219 261.807 72.7847 261.807 72.1365 261.418C71.4883 261.028 71.4883 260.444 72.1365 260.055C72.7847 259.665 73.8219 259.665 74.4701 260.055Z" fill="#00283B" />
        <path d="M80.5955 256.517C81.2437 256.906 81.2437 257.49 80.5955 257.88C79.9473 258.269 78.9102 258.269 78.262 257.88C77.6138 257.49 77.6138 256.906 78.262 256.517C78.9102 256.127 79.9473 256.127 80.5955 256.517Z" fill="#00283B" />
        <path d="M86.7538 252.979C87.402 253.368 87.402 253.952 86.7538 254.342C86.1056 254.731 85.0684 254.731 84.4202 254.342C83.772 253.952 83.772 253.368 84.4202 252.979C85.0684 252.589 86.1056 252.589 86.7538 252.979Z" fill="#00283B" />
        <path d="M92.8792 249.408C93.5274 249.798 93.5274 250.382 92.8792 250.772C92.231 251.161 91.1939 251.161 90.5457 250.772C89.8975 250.382 89.8975 249.798 90.5457 249.408C91.1939 249.019 92.231 249.019 92.8792 249.408Z" fill="#00283B" />
        <path d="M99.037 245.87C99.6852 246.26 99.6852 246.844 99.037 247.233C98.3888 247.623 97.3516 247.623 96.7034 247.233C96.0552 246.844 96.0552 246.26 96.7034 245.87C97.3516 245.481 98.3888 245.481 99.037 245.87Z" fill="#00283B" />
        <path d="M105.162 242.3C105.811 242.689 105.811 243.274 105.162 243.663C104.514 244.053 103.477 244.053 102.829 243.663C102.181 243.274 102.181 242.689 102.829 242.3C103.477 241.91 104.514 241.91 105.162 242.3Z" fill="#00283B" />
        <path d="M111.321 238.762C111.969 239.151 111.969 239.736 111.321 240.125C110.672 240.515 109.635 240.515 108.987 240.125C108.339 239.736 108.339 239.151 108.987 238.762C109.635 238.372 110.672 238.372 111.321 238.762Z" fill="#00283B" />
        <path d="M117.446 235.224C118.094 235.614 118.094 236.198 117.446 236.587C116.798 236.977 115.761 236.977 115.113 236.587C114.464 236.198 114.464 235.614 115.113 235.224C115.761 234.835 116.798 234.835 117.446 235.224Z" fill="#00283B" />
        <path d="M123.604 231.654C124.252 232.043 124.252 232.627 123.604 233.017C122.956 233.406 121.919 233.406 121.27 233.017C120.622 232.627 120.622 232.043 121.27 231.654C121.919 231.264 122.956 231.264 123.604 231.654Z" fill="#00283B" />
        <path d="M129.762 228.116C130.41 228.505 130.41 229.089 129.762 229.479C129.113 229.868 128.076 229.868 127.428 229.479C126.78 229.089 126.78 228.505 127.428 228.116C128.076 227.726 129.113 227.726 129.762 228.116Z" fill="#00283B" />
        <path d="M135.887 224.545C136.535 224.935 136.535 225.519 135.887 225.909C135.239 226.298 134.202 226.298 133.554 225.909C132.905 225.519 132.905 224.935 133.554 224.545C134.202 224.156 135.239 224.156 135.887 224.545Z" fill="#00283B" />
        <path d="M142.045 221.007C142.693 221.397 142.693 221.981 142.045 222.37C141.397 222.76 140.36 222.76 139.712 222.37C139.064 221.981 139.064 221.397 139.712 221.007C140.36 220.618 141.397 220.618 142.045 221.007Z" fill="#00283B" />
        <path d="M43.7132 270.701C44.3614 271.09 44.3614 271.675 43.7132 272.064C43.065 272.454 42.0279 272.454 41.3797 272.064C40.7315 271.675 40.7315 271.09 41.3797 270.701C42.0279 270.311 43.065 270.311 43.7132 270.701Z" fill="#00283B" />
        <path d="M49.8387 267.131C50.4869 267.52 50.4869 268.104 49.8387 268.494C49.1905 268.883 48.1534 268.883 47.5052 268.494C46.857 268.104 46.857 267.52 47.5052 267.131C48.1534 266.741 49.1905 266.741 49.8387 267.131Z" fill="#00283B" />
        <path d="M55.9964 263.592C56.6446 263.982 56.6446 264.566 55.9964 264.956C55.3482 265.345 54.3111 265.345 53.6629 264.956C53.0147 264.566 53.0147 263.982 53.6629 263.592C54.3111 263.203 55.3482 263.203 55.9964 263.592Z" fill="#00283B" />
        <path d="M62.1219 260.022C62.7701 260.412 62.7701 260.996 62.1219 261.385C61.4737 261.775 60.4366 261.775 59.7884 261.385C59.1402 260.996 59.1402 260.412 59.7884 260.022C60.4366 259.633 61.4737 259.633 62.1219 260.022Z" fill="#00283B" />
        <path d="M68.2801 256.484C68.9283 256.874 68.9283 257.458 68.2801 257.848C67.6319 258.237 66.5948 258.237 65.9466 257.848C65.2984 257.458 65.2984 256.874 65.9466 256.484C66.5948 256.095 67.6319 256.095 68.2801 256.484Z" fill="#00283B" />
        <path d="M74.4056 252.914C75.0538 253.303 75.0538 253.887 74.4056 254.277C73.7574 254.666 72.7203 254.666 72.0721 254.277C71.4239 253.887 71.4239 253.303 72.0721 252.914C72.7203 252.524 73.7574 252.524 74.4056 252.914Z" fill="#00283B" />
        <path d="M80.5633 249.376C81.2115 249.765 81.2115 250.35 80.5633 250.739C79.9151 251.129 78.878 251.129 78.2298 250.739C77.5816 250.35 77.5816 249.765 78.2298 249.376C78.878 248.986 79.9151 248.986 80.5633 249.376Z" fill="#00283B" />
        <path d="M86.6888 245.838C87.337 246.227 87.337 246.812 86.6888 247.201C86.0406 247.591 85.0035 247.591 84.3553 247.201C83.7071 246.812 83.7071 246.227 84.3553 245.838C85.0035 245.448 86.0406 245.448 86.6888 245.838Z" fill="#00283B" />
        <path d="M92.8465 242.267C93.4947 242.657 93.4947 243.241 92.8465 243.631C92.1983 244.02 91.1612 244.02 90.513 243.631C89.8648 243.241 89.8648 242.657 90.513 242.267C91.1612 241.878 92.1983 241.878 92.8465 242.267Z" fill="#00283B" />
        <path d="M98.972 238.73C99.6202 239.119 99.6202 239.703 98.972 240.093C98.3238 240.482 97.2867 240.482 96.6385 240.093C95.9903 239.703 95.9903 239.119 96.6385 238.73C97.2867 238.34 98.3238 238.34 98.972 238.73Z" fill="#00283B" />
        <path d="M105.13 235.159C105.778 235.549 105.778 236.133 105.13 236.522C104.482 236.912 103.445 236.912 102.797 236.522C102.148 236.133 102.148 235.549 102.797 235.159C103.445 234.77 104.482 234.77 105.13 235.159Z" fill="#00283B" />
        <path d="M111.256 231.621C111.904 232.011 111.904 232.595 111.256 232.984C110.608 233.374 109.57 233.374 108.922 232.984C108.274 232.595 108.274 232.011 108.922 231.621C109.57 231.232 110.608 231.232 111.256 231.621Z" fill="#00283B" />
        <path d="M117.413 228.083C118.062 228.473 118.062 229.057 117.413 229.446C116.765 229.836 115.728 229.836 115.08 229.446C114.432 229.057 114.432 228.473 115.08 228.083C115.728 227.694 116.765 227.694 117.413 228.083Z" fill="#00283B" />
        <path d="M123.539 224.513C124.187 224.902 124.187 225.487 123.539 225.876C122.891 226.266 121.854 226.266 121.205 225.876C120.557 225.487 120.557 224.902 121.205 224.513C121.854 224.123 122.891 224.123 123.539 224.513Z" fill="#00283B" />
        <path d="M129.697 220.975C130.345 221.364 130.345 221.948 129.697 222.338C129.049 222.727 128.012 222.727 127.364 222.338C126.715 221.948 126.715 221.364 127.364 220.975C128.012 220.585 129.049 220.585 129.697 220.975Z" fill="#00283B" />
        <path d="M135.823 217.404C136.471 217.794 136.471 218.378 135.823 218.768C135.174 219.157 134.137 219.157 133.489 218.768C132.841 218.378 132.841 217.794 133.489 217.404C134.137 217.015 135.174 217.015 135.823 217.404Z" fill="#00283B" />
        <path d="M37.5555 267.131C38.2037 267.52 38.2037 268.104 37.5555 268.494C36.9073 268.883 35.8702 268.883 35.222 268.494C34.5738 268.104 34.5738 267.52 35.222 267.131C35.8702 266.741 36.9073 266.741 37.5555 267.131Z" fill="#00283B" />
        <path d="M43.7132 263.592C44.3614 263.982 44.3614 264.566 43.7132 264.956C43.065 265.345 42.0279 265.345 41.3797 264.956C40.7315 264.566 40.7315 263.982 41.3797 263.592C42.0279 263.203 43.065 263.203 43.7132 263.592Z" fill="#00283B" />
        <path d="M49.8387 260.022C50.4869 260.412 50.4869 260.996 49.8387 261.385C49.1905 261.775 48.1534 261.775 47.5052 261.385C46.857 260.996 46.857 260.412 47.5052 260.022C48.1534 259.633 49.1905 259.633 49.8387 260.022Z" fill="#00283B" />
        <path d="M55.9964 256.484C56.6446 256.874 56.6446 257.458 55.9964 257.848C55.3482 258.237 54.3111 258.237 53.6629 257.848C53.0147 257.458 53.0147 256.874 53.6629 256.484C54.3111 256.095 55.3482 256.095 55.9964 256.484Z" fill="#00283B" />
        <path d="M62.1219 252.914C62.7701 253.303 62.7701 253.887 62.1219 254.277C61.4737 254.666 60.4366 254.666 59.7884 254.277C59.1402 253.887 59.1402 253.303 59.7884 252.914C60.4366 252.524 61.4737 252.524 62.1219 252.914Z" fill="#00283B" />
        <path d="M68.2801 249.376C68.9283 249.765 68.9283 250.35 68.2801 250.739C67.6319 251.129 66.5948 251.129 65.9466 250.739C65.2984 250.35 65.2984 249.765 65.9466 249.376C66.5948 248.986 67.6319 248.986 68.2801 249.376Z" fill="#00283B" />
        <path d="M74.4056 245.838C75.0538 246.227 75.0538 246.812 74.4056 247.201C73.7574 247.591 72.7203 247.591 72.0721 247.201C71.4239 246.812 71.4239 246.227 72.0721 245.838C72.7203 245.448 73.7574 245.448 74.4056 245.838Z" fill="#00283B" />
        <path d="M80.5633 242.267C81.2115 242.657 81.2115 243.241 80.5633 243.631C79.9151 244.02 78.878 244.02 78.2298 243.631C77.5816 243.241 77.5816 242.657 78.2298 242.267C78.878 241.878 79.9151 241.878 80.5633 242.267Z" fill="#00283B" />
        <path d="M86.6888 238.73C87.337 239.119 87.337 239.703 86.6888 240.093C86.0406 240.482 85.0035 240.482 84.3553 240.093C83.7071 239.703 83.7071 239.119 84.3553 238.73C85.0035 238.34 86.0406 238.34 86.6888 238.73Z" fill="#00283B" />
        <path d="M92.8465 235.159C93.4947 235.549 93.4947 236.133 92.8465 236.522C92.1983 236.912 91.1612 236.912 90.513 236.522C89.8648 236.133 89.8648 235.549 90.513 235.159C91.1612 234.77 92.1983 234.77 92.8465 235.159Z" fill="#00283B" />
        <path d="M98.972 231.621C99.6202 232.011 99.6202 232.595 98.972 232.984C98.3238 233.374 97.2867 233.374 96.6385 232.984C95.9903 232.595 95.9903 232.011 96.6385 231.621C97.2867 231.232 98.3238 231.232 98.972 231.621Z" fill="#00283B" />
        <path d="M105.13 228.083C105.778 228.473 105.778 229.057 105.13 229.446C104.482 229.836 103.445 229.836 102.797 229.446C102.148 229.057 102.148 228.473 102.797 228.083C103.445 227.694 104.482 227.694 105.13 228.083Z" fill="#00283B" />
        <path d="M111.256 224.513C111.904 224.902 111.904 225.487 111.256 225.876C110.608 226.266 109.57 226.266 108.922 225.876C108.274 225.487 108.274 224.902 108.922 224.513C109.57 224.123 110.608 224.123 111.256 224.513Z" fill="#00283B" />
        <path d="M117.413 220.975C118.062 221.364 118.062 221.948 117.413 222.338C116.765 222.727 115.728 222.727 115.08 222.338C114.432 221.948 114.432 221.364 115.08 220.975C115.728 220.585 116.765 220.585 117.413 220.975Z" fill="#00283B" />
        <path d="M123.539 217.404C124.187 217.794 124.187 218.378 123.539 218.768C122.891 219.157 121.854 219.157 121.205 218.768C120.557 218.378 120.557 217.794 121.205 217.404C121.854 217.015 122.891 217.015 123.539 217.404Z" fill="#00283B" />
        <path d="M129.697 213.867C130.345 214.256 130.345 214.84 129.697 215.23C129.049 215.619 128.012 215.619 127.364 215.23C126.715 214.84 126.715 214.256 127.364 213.867C128.012 213.477 129.049 213.477 129.697 213.867Z" fill="#00283B" />
        <path d="M31.43 263.592C32.0782 263.982 32.0782 264.566 31.43 264.956C30.7818 265.345 29.7447 265.345 29.0965 264.956C28.4483 264.566 28.4483 263.982 29.0965 263.592C29.7447 263.203 30.7818 263.203 31.43 263.592Z" fill="#00283B" />
        <path d="M37.5555 260.022C38.2037 260.412 38.2037 260.996 37.5555 261.385C36.9073 261.775 35.8702 261.775 35.222 261.385C34.5738 260.996 34.5738 260.412 35.222 260.022C35.8702 259.633 36.9073 259.633 37.5555 260.022Z" fill="#00283B" />
        <path d="M43.7132 256.484C44.3614 256.874 44.3614 257.458 43.7132 257.848C43.065 258.237 42.0279 258.237 41.3797 257.848C40.7315 257.458 40.7315 256.874 41.3797 256.484C42.0279 256.095 43.065 256.095 43.7132 256.484Z" fill="#00283B" />
        <path d="M49.8387 252.914C50.4869 253.303 50.4869 253.887 49.8387 254.277C49.1905 254.666 48.1534 254.666 47.5052 254.277C46.857 253.887 46.857 253.303 47.5052 252.914C48.1534 252.524 49.1905 252.524 49.8387 252.914Z" fill="#00283B" />
        <path d="M55.9964 249.376C56.6446 249.765 56.6446 250.35 55.9964 250.739C55.3482 251.129 54.3111 251.129 53.6629 250.739C53.0147 250.35 53.0147 249.765 53.6629 249.376C54.3111 248.986 55.3482 248.986 55.9964 249.376Z" fill="#00283B" />
        <path d="M62.1219 245.838C62.7701 246.227 62.7701 246.812 62.1219 247.201C61.4737 247.591 60.4366 247.591 59.7884 247.201C59.1402 246.812 59.1402 246.227 59.7884 245.838C60.4366 245.448 61.4737 245.448 62.1219 245.838Z" fill="#00283B" />
        <path d="M68.2801 242.267C68.9283 242.657 68.9283 243.241 68.2801 243.631C67.6319 244.02 66.5948 244.02 65.9466 243.631C65.2984 243.241 65.2984 242.657 65.9466 242.267C66.5948 241.878 67.6319 241.878 68.2801 242.267Z" fill="#00283B" />
        <path d="M74.4056 238.73C75.0538 239.119 75.0538 239.703 74.4056 240.093C73.7574 240.482 72.7203 240.482 72.0721 240.093C71.4239 239.703 71.4239 239.119 72.0721 238.73C72.7203 238.34 73.7574 238.34 74.4056 238.73Z" fill="#00283B" />
        <path d="M80.5633 235.159C81.2115 235.549 81.2115 236.133 80.5633 236.522C79.9151 236.912 78.878 236.912 78.2298 236.522C77.5816 236.133 77.5816 235.549 78.2298 235.159C78.878 234.77 79.9151 234.77 80.5633 235.159Z" fill="#00283B" />
        <path d="M86.6888 231.621C87.337 232.011 87.337 232.595 86.6888 232.984C86.0406 233.374 85.0035 233.374 84.3553 232.984C83.7071 232.595 83.7071 232.011 84.3553 231.621C85.0035 231.232 86.0406 231.232 86.6888 231.621Z" fill="#00283B" />
        <path d="M92.8465 228.083C93.4947 228.473 93.4947 229.057 92.8465 229.446C92.1983 229.836 91.1612 229.836 90.513 229.446C89.8648 229.057 89.8648 228.473 90.513 228.083C91.1612 227.694 92.1983 227.694 92.8465 228.083Z" fill="#00283B" />
        <path d="M98.972 224.513C99.6202 224.902 99.6202 225.487 98.972 225.876C98.3238 226.266 97.2867 226.266 96.6385 225.876C95.9903 225.487 95.9903 224.902 96.6385 224.513C97.2867 224.123 98.3238 224.123 98.972 224.513Z" fill="#00283B" />
        <path d="M105.13 220.975C105.778 221.364 105.778 221.948 105.13 222.338C104.482 222.727 103.445 222.727 102.797 222.338C102.148 221.948 102.148 221.364 102.797 220.975C103.445 220.585 104.482 220.585 105.13 220.975Z" fill="#00283B" />
        <path d="M111.256 217.404C111.904 217.794 111.904 218.378 111.256 218.768C110.608 219.157 109.57 219.157 108.922 218.768C108.274 218.378 108.274 217.794 108.922 217.404C109.57 217.015 110.608 217.015 111.256 217.404Z" fill="#00283B" />
        <path d="M117.413 213.867C118.062 214.256 118.062 214.84 117.413 215.23C116.765 215.619 115.728 215.619 115.08 215.23C114.432 214.84 114.432 214.256 115.08 213.867C115.728 213.477 116.765 213.477 117.413 213.867Z" fill="#00283B" />
        <path d="M123.539 210.296C124.187 210.686 124.187 211.27 123.539 211.659C122.891 212.049 121.854 212.049 121.205 211.659C120.557 211.27 120.557 210.686 121.205 210.296C121.854 209.907 122.891 209.907 123.539 210.296Z" fill="#00283B" />
        <path d="M25.2718 260.022C25.92 260.412 25.92 260.996 25.2718 261.385C24.6236 261.775 23.5865 261.775 22.9383 261.385C22.2901 260.996 22.2901 260.412 22.9383 260.022C23.5865 259.633 24.6236 259.633 25.2718 260.022Z" fill="#00283B" />
        <path d="M31.43 256.484C32.0782 256.874 32.0782 257.458 31.43 257.848C30.7818 258.237 29.7447 258.237 29.0965 257.848C28.4483 257.458 28.4483 256.874 29.0965 256.484C29.7447 256.095 30.7818 256.095 31.43 256.484Z" fill="#00283B" />
        <path d="M37.5555 252.914C38.2037 253.303 38.2037 253.887 37.5555 254.277C36.9073 254.666 35.8702 254.666 35.222 254.277C34.5738 253.887 34.5738 253.303 35.222 252.914C35.8702 252.524 36.9073 252.524 37.5555 252.914Z" fill="#00283B" />
        <path d="M43.7132 249.376C44.3614 249.765 44.3614 250.35 43.7132 250.739C43.065 251.129 42.0279 251.129 41.3797 250.739C40.7315 250.35 40.7315 249.765 41.3797 249.376C42.0279 248.986 43.065 248.986 43.7132 249.376Z" fill="#00283B" />
        <path d="M49.8387 245.838C50.4869 246.227 50.4869 246.812 49.8387 247.201C49.1905 247.591 48.1534 247.591 47.5052 247.201C46.857 246.812 46.857 246.227 47.5052 245.838C48.1534 245.448 49.1905 245.448 49.8387 245.838Z" fill="#00283B" />
        <path d="M55.9964 242.267C56.6446 242.657 56.6446 243.241 55.9964 243.631C55.3482 244.02 54.3111 244.02 53.6629 243.631C53.0147 243.241 53.0147 242.657 53.6629 242.267C54.3111 241.878 55.3482 241.878 55.9964 242.267Z" fill="#00283B" />
        <path d="M62.1219 238.73C62.7701 239.119 62.7701 239.703 62.1219 240.093C61.4737 240.482 60.4366 240.482 59.7884 240.093C59.1402 239.703 59.1402 239.119 59.7884 238.73C60.4366 238.34 61.4737 238.34 62.1219 238.73Z" fill="#00283B" />
        <path d="M68.2801 235.159C68.9283 235.549 68.9283 236.133 68.2801 236.522C67.6319 236.912 66.5948 236.912 65.9466 236.522C65.2984 236.133 65.2984 235.549 65.9466 235.159C66.5948 234.77 67.6319 234.77 68.2801 235.159Z" fill="#00283B" />
        <path d="M74.4056 231.621C75.0538 232.011 75.0538 232.595 74.4056 232.984C73.7574 233.374 72.7203 233.374 72.0721 232.984C71.4239 232.595 71.4239 232.011 72.0721 231.621C72.7203 231.232 73.7574 231.232 74.4056 231.621Z" fill="#00283B" />
        <path d="M80.5633 228.083C81.2115 228.473 81.2115 229.057 80.5633 229.446C79.9151 229.836 78.878 229.836 78.2298 229.446C77.5816 229.057 77.5816 228.473 78.2298 228.083C78.878 227.694 79.9151 227.694 80.5633 228.083Z" fill="#00283B" />
        <path d="M86.6888 224.513C87.337 224.902 87.337 225.487 86.6888 225.876C86.0406 226.266 85.0035 226.266 84.3553 225.876C83.7071 225.487 83.7071 224.902 84.3553 224.513C85.0035 224.123 86.0406 224.123 86.6888 224.513Z" fill="#00283B" />
        <path d="M92.8465 220.975C93.4947 221.364 93.4947 221.948 92.8465 222.338C92.1983 222.727 91.1612 222.727 90.513 222.338C89.8648 221.948 89.8648 221.364 90.513 220.975C91.1612 220.585 92.1983 220.585 92.8465 220.975Z" fill="#00283B" />
        <path d="M98.972 217.404C99.6202 217.794 99.6202 218.378 98.972 218.768C98.3238 219.157 97.2867 219.157 96.6385 218.768C95.9903 218.378 95.9903 217.794 96.6385 217.404C97.2867 217.015 98.3238 217.015 98.972 217.404Z" fill="#00283B" />
        <path d="M105.13 213.867C105.778 214.256 105.778 214.84 105.13 215.23C104.482 215.619 103.445 215.619 102.797 215.23C102.148 214.84 102.148 214.256 102.797 213.867C103.445 213.477 104.482 213.477 105.13 213.867Z" fill="#00283B" />
        <path d="M111.256 210.296C111.904 210.686 111.904 211.27 111.256 211.659C110.608 212.049 109.57 212.049 108.922 211.659C108.274 211.27 108.274 210.686 108.922 210.296C109.57 209.907 110.608 209.907 111.256 210.296Z" fill="#00283B" />
        <path d="M117.413 206.758C118.062 207.148 118.062 207.732 117.413 208.121C116.765 208.511 115.728 208.511 115.08 208.121C114.432 207.732 114.432 207.148 115.08 206.758C115.728 206.369 116.765 206.369 117.413 206.758Z" fill="#00283B" />
        <path d="M117.187 200.266C117.835 200.656 117.835 201.24 117.187 201.63C116.539 202.019 115.502 202.019 114.853 201.63C114.205 201.24 114.205 200.656 114.853 200.266C115.502 199.877 116.539 199.877 117.187 200.266Z" fill="#00283B" />
        <path d="M123.345 196.729C123.993 197.118 123.993 197.702 123.345 198.092C122.696 198.481 121.659 198.481 121.011 198.092C120.363 197.702 120.363 197.118 121.011 196.729C121.659 196.339 122.696 196.339 123.345 196.729Z" fill="#00283B" />
        <path d="M129.47 193.158C130.118 193.548 130.118 194.132 129.47 194.521C128.822 194.911 127.785 194.911 127.137 194.521C126.488 194.132 126.488 193.548 127.137 193.158C127.785 192.769 128.822 192.769 129.47 193.158Z" fill="#00283B" />
        <path d="M135.628 189.62C136.276 190.01 136.276 190.594 135.628 190.983C134.98 191.373 133.942 191.373 133.294 190.983C132.646 190.594 132.646 190.01 133.294 189.62C133.942 189.231 134.98 189.231 135.628 189.62Z" fill="#00283B" />
        <path d="M141.753 186.05C142.401 186.439 142.401 187.023 141.753 187.413C141.105 187.802 140.068 187.802 139.42 187.413C138.772 187.023 138.772 186.439 139.42 186.05C140.068 185.66 141.105 185.66 141.753 186.05Z" fill="#00283B" />
        <path d="M147.911 182.512C148.56 182.901 148.56 183.486 147.911 183.875C147.263 184.265 146.226 184.265 145.578 183.875C144.93 183.486 144.93 182.901 145.578 182.512C146.226 182.122 147.263 182.122 147.911 182.512Z" fill="#00283B" />
        <path d="M154.037 178.974C154.685 179.363 154.685 179.948 154.037 180.337C153.389 180.727 152.352 180.727 151.703 180.337C151.055 179.948 151.055 179.363 151.703 178.974C152.352 178.584 153.389 178.584 154.037 178.974Z" fill="#00283B" />
        <path d="M160.195 175.403C160.843 175.793 160.843 176.377 160.195 176.767C159.546 177.156 158.509 177.156 157.861 176.767C157.213 176.377 157.213 175.793 157.861 175.403C158.509 175.014 159.546 175.014 160.195 175.403Z" fill="#00283B" />
        <path d="M166.32 171.866C166.968 172.255 166.968 172.839 166.32 173.229C165.672 173.618 164.635 173.618 163.987 173.229C163.338 172.839 163.338 172.255 163.987 171.866C164.635 171.476 165.672 171.476 166.32 171.866Z" fill="#00283B" />
        <path d="M172.478 168.295C173.126 168.685 173.126 169.269 172.478 169.658C171.83 170.048 170.793 170.048 170.144 169.658C169.496 169.269 169.496 168.685 170.144 168.295C170.793 167.906 171.83 167.906 172.478 168.295Z" fill="#00283B" />
        <path d="M178.604 164.757C179.252 165.147 179.252 165.731 178.604 166.12C177.956 166.51 176.919 166.51 176.27 166.12C175.622 165.731 175.622 165.147 176.27 164.757C176.919 164.368 177.956 164.368 178.604 164.757Z" fill="#00283B" />
        <path d="M184.762 161.219C185.41 161.609 185.41 162.193 184.762 162.582C184.113 162.972 183.076 162.972 182.428 162.582C181.78 162.193 181.78 161.609 182.428 161.219C183.076 160.83 184.113 160.83 184.762 161.219Z" fill="#00283B" />
        <path d="M190.887 157.649C191.535 158.038 191.535 158.623 190.887 159.012C190.239 159.402 189.202 159.402 188.554 159.012C187.905 158.623 187.905 158.038 188.554 157.649C189.202 157.259 190.239 157.259 190.887 157.649Z" fill="#00283B" />
        <path d="M197.045 154.111C197.693 154.5 197.693 155.085 197.045 155.474C196.397 155.864 195.359 155.864 194.711 155.474C194.063 155.085 194.063 154.5 194.711 154.111C195.359 153.721 196.397 153.721 197.045 154.111Z" fill="#00283B" />
        <path d="M203.17 150.54C203.818 150.93 203.818 151.514 203.17 151.904C202.522 152.293 201.485 152.293 200.837 151.904C200.189 151.514 200.189 150.93 200.837 150.54C201.485 150.151 202.522 150.151 203.17 150.54Z" fill="#00283B" />
        <path d="M209.328 147.003C209.977 147.392 209.977 147.976 209.328 148.366C208.68 148.755 207.643 148.755 206.995 148.366C206.347 147.976 206.347 147.392 206.995 147.003C207.643 146.613 208.68 146.613 209.328 147.003Z" fill="#00283B" />
        <path d="M215.454 143.432C216.102 143.822 216.102 144.406 215.454 144.795C214.806 145.185 213.769 145.185 213.12 144.795C212.472 144.406 212.472 143.822 213.12 143.432C213.769 143.043 214.806 143.043 215.454 143.432Z" fill="#00283B" />
        <path d="M108.695 196.729C109.343 196.339 110.38 196.339 111.029 196.729C111.677 197.118 111.677 197.702 111.029 198.092C110.38 198.481 109.343 198.481 108.695 198.092C108.047 197.702 108.047 197.118 108.695 196.729Z" fill="#00283B" />
        <path d="M117.187 193.158C117.835 193.548 117.835 194.132 117.187 194.521C116.539 194.911 115.502 194.911 114.853 194.521C114.205 194.132 114.205 193.548 114.853 193.158C115.502 192.769 116.539 192.769 117.187 193.158Z" fill="#00283B" />
        <path d="M123.345 189.62C123.993 190.01 123.993 190.594 123.345 190.983C122.696 191.373 121.659 191.373 121.011 190.983C120.363 190.594 120.363 190.01 121.011 189.62C121.659 189.231 122.696 189.231 123.345 189.62Z" fill="#00283B" />
        <path d="M129.47 186.05C130.118 186.439 130.118 187.023 129.47 187.413C128.822 187.802 127.785 187.802 127.137 187.413C126.488 187.023 126.488 186.439 127.137 186.05C127.785 185.66 128.822 185.66 129.47 186.05Z" fill="#00283B" />
        <path d="M135.628 182.512C136.276 182.901 136.276 183.486 135.628 183.875C134.98 184.265 133.942 184.265 133.294 183.875C132.646 183.486 132.646 182.901 133.294 182.512C133.942 182.122 134.98 182.122 135.628 182.512Z" fill="#00283B" />
        <path d="M141.753 178.974C142.401 179.363 142.401 179.948 141.753 180.337C141.105 180.727 140.068 180.727 139.42 180.337C138.772 179.948 138.772 179.363 139.42 178.974C140.068 178.584 141.105 178.584 141.753 178.974Z" fill="#00283B" />
        <path d="M147.911 175.403C148.56 175.793 148.56 176.377 147.911 176.767C147.263 177.156 146.226 177.156 145.578 176.767C144.93 176.377 144.93 175.793 145.578 175.403C146.226 175.014 147.263 175.014 147.911 175.403Z" fill="#00283B" />
        <path d="M154.037 171.866C154.685 172.255 154.685 172.839 154.037 173.229C153.389 173.618 152.352 173.618 151.703 173.229C151.055 172.839 151.055 172.255 151.703 171.866C152.352 171.476 153.389 171.476 154.037 171.866Z" fill="#00283B" />
        <path d="M160.195 168.295C160.843 168.685 160.843 169.269 160.195 169.658C159.546 170.048 158.509 170.048 157.861 169.658C157.213 169.269 157.213 168.685 157.861 168.295C158.509 167.906 159.546 167.906 160.195 168.295Z" fill="#00283B" />
        <path d="M166.32 164.757C166.968 165.147 166.968 165.731 166.32 166.12C165.672 166.51 164.635 166.51 163.987 166.12C163.338 165.731 163.338 165.147 163.987 164.757C164.635 164.368 165.672 164.368 166.32 164.757Z" fill="#00283B" />
        <path d="M172.478 161.219C173.126 161.609 173.126 162.193 172.478 162.582C171.83 162.972 170.793 162.972 170.144 162.582C169.496 162.193 169.496 161.609 170.144 161.219C170.793 160.83 171.83 160.83 172.478 161.219Z" fill="#00283B" />
        <path d="M178.604 157.649C179.252 158.038 179.252 158.623 178.604 159.012C177.956 159.402 176.919 159.402 176.27 159.012C175.622 158.623 175.622 158.038 176.27 157.649C176.919 157.259 177.956 157.259 178.604 157.649Z" fill="#00283B" />
        <path d="M184.762 154.111C185.41 154.5 185.41 155.085 184.762 155.474C184.113 155.864 183.076 155.864 182.428 155.474C181.78 155.085 181.78 154.5 182.428 154.111C183.076 153.721 184.113 153.721 184.762 154.111Z" fill="#00283B" />
        <path d="M190.887 150.54C191.535 150.93 191.535 151.514 190.887 151.904C190.239 152.293 189.202 152.293 188.554 151.904C187.905 151.514 187.905 150.93 188.554 150.54C189.202 150.151 190.239 150.151 190.887 150.54Z" fill="#00283B" />
        <path d="M197.045 147.003C197.693 147.392 197.693 147.976 197.045 148.366C196.397 148.755 195.359 148.755 194.711 148.366C194.063 147.976 194.063 147.392 194.711 147.003C195.359 146.613 196.397 146.613 197.045 147.003Z" fill="#00283B" />
        <path d="M203.17 143.432C203.818 143.822 203.818 144.406 203.17 144.795C202.522 145.185 201.485 145.185 200.837 144.795C200.189 144.406 200.189 143.822 200.837 143.432C201.485 143.043 202.522 143.043 203.17 143.432Z" fill="#00283B" />
        <path d="M209.328 139.894C209.977 140.284 209.977 140.868 209.328 141.257C208.68 141.647 207.643 141.647 206.995 141.257C206.347 140.868 206.347 140.284 206.995 139.894C207.643 139.505 208.68 139.505 209.328 139.894Z" fill="#00283B" />
        <path d="M102.537 193.158C103.186 192.769 104.223 192.769 104.871 193.158C105.519 193.548 105.519 194.132 104.871 194.521C104.223 194.911 103.186 194.911 102.537 194.521C101.889 194.132 101.889 193.548 102.537 193.158Z" fill="#00283B" />
        <path d="M111.029 189.62C111.677 190.01 111.677 190.594 111.029 190.983C110.38 191.373 109.343 191.373 108.695 190.983C108.047 190.594 108.047 190.01 108.695 189.62C109.343 189.231 110.38 189.231 111.029 189.62Z" fill="#00283B" />
        <path d="M117.187 186.05C117.835 186.439 117.835 187.023 117.187 187.413C116.539 187.802 115.502 187.802 114.853 187.413C114.205 187.023 114.205 186.439 114.853 186.05C115.502 185.66 116.539 185.66 117.187 186.05Z" fill="#00283B" />
        <path d="M123.345 182.512C123.993 182.901 123.993 183.486 123.345 183.875C122.696 184.265 121.659 184.265 121.011 183.875C120.363 183.486 120.363 182.901 121.011 182.512C121.659 182.122 122.696 182.122 123.345 182.512Z" fill="#00283B" />
        <path d="M129.47 178.974C130.118 179.363 130.118 179.948 129.47 180.337C128.822 180.727 127.785 180.727 127.137 180.337C126.488 179.948 126.488 179.363 127.137 178.974C127.785 178.584 128.822 178.584 129.47 178.974Z" fill="#00283B" />
        <path d="M135.628 175.403C136.276 175.793 136.276 176.377 135.628 176.767C134.98 177.156 133.942 177.156 133.294 176.767C132.646 176.377 132.646 175.793 133.294 175.403C133.942 175.014 134.98 175.014 135.628 175.403Z" fill="#00283B" />
        <path d="M141.753 171.866C142.401 172.255 142.401 172.839 141.753 173.229C141.105 173.618 140.068 173.618 139.42 173.229C138.772 172.839 138.772 172.255 139.42 171.866C140.068 171.476 141.105 171.476 141.753 171.866Z" fill="#00283B" />
        <path d="M147.911 168.295C148.56 168.685 148.56 169.269 147.911 169.658C147.263 170.048 146.226 170.048 145.578 169.658C144.93 169.269 144.93 168.685 145.578 168.295C146.226 167.906 147.263 167.906 147.911 168.295Z" fill="#00283B" />
        <path d="M154.037 164.757C154.685 165.147 154.685 165.731 154.037 166.12C153.389 166.51 152.352 166.51 151.703 166.12C151.055 165.731 151.055 165.147 151.703 164.757C152.352 164.368 153.389 164.368 154.037 164.757Z" fill="#00283B" />
        <path d="M160.195 161.219C160.843 161.609 160.843 162.193 160.195 162.582C159.546 162.972 158.509 162.972 157.861 162.582C157.213 162.193 157.213 161.609 157.861 161.219C158.509 160.83 159.546 160.83 160.195 161.219Z" fill="#00283B" />
        <path d="M166.32 157.649C166.968 158.038 166.968 158.623 166.32 159.012C165.672 159.402 164.635 159.402 163.987 159.012C163.338 158.623 163.338 158.038 163.987 157.649C164.635 157.259 165.672 157.259 166.32 157.649Z" fill="#00283B" />
        <path d="M172.478 154.111C173.126 154.5 173.126 155.085 172.478 155.474C171.83 155.864 170.793 155.864 170.144 155.474C169.496 155.085 169.496 154.5 170.144 154.111C170.793 153.721 171.83 153.721 172.478 154.111Z" fill="#00283B" />
        <path d="M178.604 150.54C179.252 150.93 179.252 151.514 178.604 151.904C177.956 152.293 176.919 152.293 176.27 151.904C175.622 151.514 175.622 150.93 176.27 150.54C176.919 150.151 177.956 150.151 178.604 150.54Z" fill="#00283B" />
        <path d="M184.762 147.003C185.41 147.392 185.41 147.976 184.762 148.366C184.113 148.755 183.076 148.755 182.428 148.366C181.78 147.976 181.78 147.392 182.428 147.003C183.076 146.613 184.113 146.613 184.762 147.003Z" fill="#00283B" />
        <path d="M190.887 143.432C191.535 143.822 191.535 144.406 190.887 144.795C190.239 145.185 189.202 145.185 188.554 144.795C187.905 144.406 187.905 143.822 188.554 143.432C189.202 143.043 190.239 143.043 190.887 143.432Z" fill="#00283B" />
        <path d="M197.045 139.894C197.693 140.284 197.693 140.868 197.045 141.257C196.397 141.647 195.359 141.647 194.711 141.257C194.063 140.868 194.063 140.284 194.711 139.894C195.359 139.505 196.397 139.505 197.045 139.894Z" fill="#00283B" />
        <path d="M203.17 136.356C203.818 136.746 203.818 137.33 203.17 137.72C202.522 138.109 201.485 138.109 200.837 137.72C200.189 137.33 200.189 136.746 200.837 136.356C201.485 135.967 202.522 135.967 203.17 136.356Z" fill="#00283B" />
        <path d="M96.4119 189.62C97.0601 189.231 98.0972 189.231 98.7454 189.62C99.3936 190.01 99.3936 190.594 98.7454 190.983C98.0972 191.373 97.0601 191.373 96.4119 190.983C95.7637 190.594 95.7637 190.01 96.4119 189.62Z" fill="#00283B" />
        <path d="M104.903 186.05C105.551 186.439 105.551 187.023 104.903 187.413C104.255 187.802 103.218 187.802 102.57 187.413C101.921 187.023 101.921 186.439 102.57 186.05C103.218 185.66 104.255 185.66 104.903 186.05Z" fill="#00283B" />
        <path d="M111.029 182.512C111.677 182.901 111.677 183.486 111.029 183.875C110.38 184.265 109.343 184.265 108.695 183.875C108.047 183.486 108.047 182.901 108.695 182.512C109.343 182.122 110.38 182.122 111.029 182.512Z" fill="#00283B" />
        <path d="M117.187 178.974C117.835 179.363 117.835 179.948 117.187 180.337C116.539 180.727 115.502 180.727 114.853 180.337C114.205 179.948 114.205 179.363 114.853 178.974C115.502 178.584 116.539 178.584 117.187 178.974Z" fill="#00283B" />
        <path d="M123.345 175.403C123.993 175.793 123.993 176.377 123.345 176.767C122.696 177.156 121.659 177.156 121.011 176.767C120.363 176.377 120.363 175.793 121.011 175.403C121.659 175.014 122.696 175.014 123.345 175.403Z" fill="#00283B" />
        <path d="M129.47 171.866C130.118 172.255 130.118 172.839 129.47 173.229C128.822 173.618 127.785 173.618 127.137 173.229C126.488 172.839 126.488 172.255 127.137 171.866C127.785 171.476 128.822 171.476 129.47 171.866Z" fill="#00283B" />
        <path d="M135.628 168.295C136.276 168.685 136.276 169.269 135.628 169.658C134.98 170.048 133.942 170.048 133.294 169.658C132.646 169.269 132.646 168.685 133.294 168.295C133.942 167.906 134.98 167.906 135.628 168.295Z" fill="#00283B" />
        <path d="M141.753 164.757C142.401 165.147 142.401 165.731 141.753 166.12C141.105 166.51 140.068 166.51 139.42 166.12C138.772 165.731 138.772 165.147 139.42 164.757C140.068 164.368 141.105 164.368 141.753 164.757Z" fill="#00283B" />
        <path d="M147.911 161.219C148.56 161.609 148.56 162.193 147.911 162.582C147.263 162.972 146.226 162.972 145.578 162.582C144.93 162.193 144.93 161.609 145.578 161.219C146.226 160.83 147.263 160.83 147.911 161.219Z" fill="#00283B" />
        <path d="M154.037 157.649C154.685 158.038 154.685 158.623 154.037 159.012C153.389 159.402 152.352 159.402 151.703 159.012C151.055 158.623 151.055 158.038 151.703 157.649C152.352 157.259 153.389 157.259 154.037 157.649Z" fill="#00283B" />
        <path d="M160.195 154.111C160.843 154.5 160.843 155.085 160.195 155.474C159.546 155.864 158.509 155.864 157.861 155.474C157.213 155.085 157.213 154.5 157.861 154.111C158.509 153.721 159.546 153.721 160.195 154.111Z" fill="#00283B" />
        <path d="M166.32 150.54C166.968 150.93 166.968 151.514 166.32 151.904C165.672 152.293 164.635 152.293 163.987 151.904C163.338 151.514 163.338 150.93 163.987 150.54C164.635 150.151 165.672 150.151 166.32 150.54Z" fill="#00283B" />
        <path d="M172.478 147.003C173.126 147.392 173.126 147.976 172.478 148.366C171.83 148.755 170.793 148.755 170.144 148.366C169.496 147.976 169.496 147.392 170.144 147.003C170.793 146.613 171.83 146.613 172.478 147.003Z" fill="#00283B" />
        <path d="M178.604 143.432C179.252 143.822 179.252 144.406 178.604 144.795C177.956 145.185 176.919 145.185 176.27 144.795C175.622 144.406 175.622 143.822 176.27 143.432C176.919 143.043 177.956 143.043 178.604 143.432Z" fill="#00283B" />
        <path d="M184.762 139.894C185.41 140.284 185.41 140.868 184.762 141.257C184.113 141.647 183.076 141.647 182.428 141.257C181.78 140.868 181.78 140.284 182.428 139.894C183.076 139.505 184.113 139.505 184.762 139.894Z" fill="#00283B" />
        <path d="M190.887 136.356C191.535 136.746 191.535 137.33 190.887 137.72C190.239 138.109 189.202 138.109 188.554 137.72C187.905 137.33 187.905 136.746 188.554 136.356C189.202 135.967 190.239 135.967 190.887 136.356Z" fill="#00283B" />
        <path d="M197.045 132.786C197.693 133.175 197.693 133.76 197.045 134.149C196.397 134.539 195.359 134.539 194.711 134.149C194.063 133.76 194.063 133.175 194.711 132.786C195.359 132.396 196.397 132.396 197.045 132.786Z" fill="#00283B" />
        <path d="M90.2537 186.082C90.9019 185.693 91.939 185.693 92.5872 186.082C93.2354 186.472 93.2354 187.056 92.5872 187.445C91.939 187.835 90.9019 187.835 90.2537 187.445C89.6055 187.056 89.6055 186.472 90.2537 186.082Z" fill="#00283B" />
        <path d="M98.7454 182.512C99.3936 182.901 99.3936 183.486 98.7454 183.875C98.0972 184.265 97.0601 184.265 96.4119 183.875C95.7637 183.486 95.7637 182.901 96.4119 182.512C97.0601 182.122 98.0972 182.122 98.7454 182.512Z" fill="#00283B" />
        <path d="M104.903 178.974C105.551 179.363 105.551 179.948 104.903 180.337C104.255 180.727 103.218 180.727 102.57 180.337C101.921 179.948 101.921 179.363 102.57 178.974C103.218 178.584 104.255 178.584 104.903 178.974Z" fill="#00283B" />
        <path d="M111.029 175.403C111.677 175.793 111.677 176.377 111.029 176.767C110.38 177.156 109.343 177.156 108.695 176.767C108.047 176.377 108.047 175.793 108.695 175.403C109.343 175.014 110.38 175.014 111.029 175.403Z" fill="#00283B" />
        <path d="M117.187 171.866C117.835 172.255 117.835 172.839 117.187 173.229C116.539 173.618 115.502 173.618 114.853 173.229C114.205 172.839 114.205 172.255 114.853 171.866C115.502 171.476 116.539 171.476 117.187 171.866Z" fill="#00283B" />
        <path d="M123.345 168.295C123.993 168.685 123.993 169.269 123.345 169.658C122.696 170.048 121.659 170.048 121.011 169.658C120.363 169.269 120.363 168.685 121.011 168.295C121.659 167.906 122.696 167.906 123.345 168.295Z" fill="#00283B" />
        <path d="M129.47 164.757C130.118 165.147 130.118 165.731 129.47 166.12C128.822 166.51 127.785 166.51 127.137 166.12C126.488 165.731 126.488 165.147 127.137 164.757C127.785 164.368 128.822 164.368 129.47 164.757Z" fill="#00283B" />
        <path d="M135.628 161.219C136.276 161.609 136.276 162.193 135.628 162.582C134.98 162.972 133.942 162.972 133.294 162.582C132.646 162.193 132.646 161.609 133.294 161.219C133.942 160.83 134.98 160.83 135.628 161.219Z" fill="#00283B" />
        <path d="M141.753 157.649C142.401 158.038 142.401 158.623 141.753 159.012C141.105 159.402 140.068 159.402 139.42 159.012C138.772 158.623 138.772 158.038 139.42 157.649C140.068 157.259 141.105 157.259 141.753 157.649Z" fill="#00283B" />
        <path d="M147.911 154.111C148.56 154.5 148.56 155.085 147.911 155.474C147.263 155.864 146.226 155.864 145.578 155.474C144.93 155.085 144.93 154.5 145.578 154.111C146.226 153.721 147.263 153.721 147.911 154.111Z" fill="#00283B" />
        <path d="M154.037 150.54C154.685 150.93 154.685 151.514 154.037 151.904C153.389 152.293 152.352 152.293 151.703 151.904C151.055 151.514 151.055 150.93 151.703 150.54C152.352 150.151 153.389 150.151 154.037 150.54Z" fill="#00283B" />
        <path d="M160.195 147.003C160.843 147.392 160.843 147.976 160.195 148.366C159.546 148.755 158.509 148.755 157.861 148.366C157.213 147.976 157.213 147.392 157.861 147.003C158.509 146.613 159.546 146.613 160.195 147.003Z" fill="#00283B" />
        <path d="M166.32 143.432C166.968 143.822 166.968 144.406 166.32 144.795C165.672 145.185 164.635 145.185 163.987 144.795C163.338 144.406 163.338 143.822 163.987 143.432C164.635 143.043 165.672 143.043 166.32 143.432Z" fill="#00283B" />
        <path d="M172.478 139.894C173.126 140.284 173.126 140.868 172.478 141.257C171.83 141.647 170.793 141.647 170.144 141.257C169.496 140.868 169.496 140.284 170.144 139.894C170.793 139.505 171.83 139.505 172.478 139.894Z" fill="#00283B" />
        <path d="M178.604 136.356C179.252 136.746 179.252 137.33 178.604 137.72C177.956 138.109 176.919 138.109 176.27 137.72C175.622 137.33 175.622 136.746 176.27 136.356C176.919 135.967 177.956 135.967 178.604 136.356Z" fill="#00283B" />
        <path d="M184.762 132.786C185.41 133.175 185.41 133.76 184.762 134.149C184.113 134.539 183.076 134.539 182.428 134.149C181.78 133.76 181.78 133.175 182.428 132.786C183.076 132.396 184.113 132.396 184.762 132.786Z" fill="#00283B" />
        <path d="M190.887 129.248C191.535 129.637 191.535 130.222 190.887 130.611C190.239 131.001 189.202 131.001 188.554 130.611C187.905 130.222 187.905 129.637 188.554 129.248C189.202 128.858 190.239 128.858 190.887 129.248Z" fill="#00283B" />
        <path d="M92.555 178.909C93.2032 179.298 93.2032 179.883 92.555 180.272C91.9068 180.662 90.8697 180.662 90.2215 180.272C89.5733 179.883 91.9068 178.552 92.555 178.909Z" fill="#00283B" />
        <path d="M96.347 175.371C96.9952 174.981 98.0323 174.981 98.6805 175.371C99.3287 175.76 99.3287 176.345 98.6805 176.734C98.0323 177.124 96.9952 177.124 96.347 176.734C95.6988 176.345 95.6988 175.76 96.347 175.371Z" fill="#00283B" />
        <path d="M104.838 171.833C105.486 172.223 105.486 172.807 104.838 173.196C104.19 173.586 103.153 173.586 102.505 173.196C101.857 172.807 101.857 172.223 102.505 171.833C103.153 171.444 104.19 171.444 104.838 171.833Z" fill="#00283B" />
        <path d="M110.996 168.263C111.645 168.652 111.645 169.236 110.996 169.626C110.348 170.015 109.311 170.015 108.663 169.626C108.015 169.236 108.015 168.652 108.663 168.263C109.311 167.873 110.348 167.873 110.996 168.263Z" fill="#00283B" />
        <path d="M117.122 164.725C117.77 165.114 117.77 165.698 117.122 166.088C116.474 166.477 115.437 166.477 114.788 166.088C114.14 165.698 114.14 165.114 114.788 164.725C115.437 164.335 116.474 164.335 117.122 164.725Z" fill="#00283B" />
        <path d="M123.28 161.154C123.928 161.544 123.928 162.128 123.28 162.518C122.631 162.907 121.594 162.907 120.946 162.518C120.298 162.128 120.298 161.544 120.946 161.154C121.594 160.765 122.631 160.765 123.28 161.154Z" fill="#00283B" />
        <path d="M129.405 157.616C130.053 158.006 130.053 158.59 129.405 158.98C128.757 159.369 127.72 159.369 127.072 158.98C126.423 158.59 126.423 158.006 127.072 157.616C127.72 157.227 128.757 157.227 129.405 157.616Z" fill="#00283B" />
        <path d="M135.563 154.079C136.212 154.468 136.212 155.052 135.563 155.442C134.915 155.831 133.878 155.831 133.23 155.442C132.582 155.052 132.582 154.468 133.23 154.079C133.878 153.689 134.915 153.689 135.563 154.079Z" fill="#00283B" />
        <path d="M141.689 150.508C142.337 150.897 142.337 151.482 141.689 151.871C141.041 152.261 140.003 152.261 139.355 151.871C138.707 151.482 138.707 150.897 139.355 150.508C140.003 150.118 141.041 150.118 141.689 150.508Z" fill="#00283B" />
        <path d="M147.847 146.97C148.495 147.36 148.495 147.944 147.847 148.333C147.198 148.723 146.161 148.723 145.513 148.333C144.865 147.944 144.865 147.36 145.513 146.97C146.161 146.581 147.198 146.581 147.847 146.97Z" fill="#00283B" />
        <path d="M153.972 143.4C154.62 143.789 154.62 144.373 153.972 144.763C153.324 145.152 152.287 145.152 151.638 144.763C150.99 144.373 150.99 143.789 151.638 143.4C152.287 143.01 153.324 143.01 153.972 143.4Z" fill="#00283B" />
        <path d="M160.13 139.862C160.778 140.251 160.778 140.835 160.13 141.225C159.482 141.614 158.444 141.614 157.796 141.225C157.148 140.835 157.148 140.251 157.796 139.862C158.444 139.472 159.482 139.472 160.13 139.862Z" fill="#00283B" />
        <path d="M166.288 136.291C166.936 136.681 166.936 137.265 166.288 137.655C165.64 138.044 164.603 138.044 163.954 137.655C163.306 137.265 163.306 136.681 163.954 136.291C164.603 135.902 165.64 135.902 166.288 136.291Z" fill="#00283B" />
        <path d="M172.413 132.753C173.062 133.143 173.062 133.727 172.413 134.117C171.765 134.506 170.728 134.506 170.08 134.117C169.432 133.727 169.432 133.143 170.08 132.753C170.728 132.364 171.765 132.364 172.413 132.753Z" fill="#00283B" />
        <path d="M178.571 129.215C179.219 129.605 179.219 130.189 178.571 130.579C177.923 130.968 176.886 130.968 176.238 130.579C175.589 130.189 175.589 129.605 176.238 129.215C176.886 128.826 177.923 128.826 178.571 129.215Z" fill="#00283B" />
        <path d="M184.697 125.645C185.345 126.034 185.345 126.619 184.697 127.008C184.048 127.398 183.011 127.398 182.363 127.008C181.715 126.619 181.715 126.034 182.363 125.645C183.011 125.255 184.048 125.255 184.697 125.645Z" fill="#00283B" />
        <path d="M123.28 154.079C123.928 154.468 123.928 155.052 123.28 155.442C122.631 155.831 121.594 155.831 120.946 155.442C120.298 155.052 122.631 153.721 123.28 154.079Z" fill="#00283B" />
        <path d="M129.405 150.508C130.053 150.897 130.053 151.482 129.405 151.871C128.757 152.261 127.72 152.261 127.072 151.871C126.423 151.482 128.757 150.151 129.405 150.508Z" fill="#00283B" />
        <path d="M135.563 146.97C136.212 147.36 136.212 147.944 135.563 148.333C134.915 148.723 133.878 148.723 133.23 148.333C132.582 147.944 134.915 146.613 135.563 146.97Z" fill="#00283B" />
        <path d="M141.689 143.4C142.337 143.789 142.337 144.373 141.689 144.763C141.041 145.152 140.003 145.152 139.355 144.763C138.707 144.373 141.041 143.043 141.689 143.4Z" fill="#00283B" />
        <path d="M153.972 136.291C154.62 136.681 154.62 137.265 153.972 137.654C153.324 138.044 152.287 138.044 151.638 137.654C150.99 137.265 153.324 135.934 153.972 136.291Z" fill="#00283B" />
        <path d="M160.13 132.753C160.778 133.143 160.778 133.727 160.13 134.117C159.482 134.506 158.444 134.506 157.796 134.117C157.148 133.727 159.482 132.396 160.13 132.753Z" fill="#00283B" />
        <path d="M145.837 217.502C146.485 217.112 147.523 217.112 148.171 217.502C148.819 217.891 148.819 218.476 148.171 218.865C147.523 219.255 146.485 219.255 145.837 218.865C145.189 218.476 145.189 217.891 145.837 217.502Z" fill="#00283B" />
        <path d="M154.328 213.932C154.977 214.321 154.977 214.905 154.328 215.295C153.68 215.684 152.643 215.684 151.995 215.295C151.347 214.905 151.347 214.321 151.995 213.932C152.643 213.542 153.68 213.542 154.328 213.932Z" fill="#00283B" />
        <path d="M160.487 210.393C161.135 210.783 161.135 211.367 160.487 211.757C159.838 212.146 158.801 212.146 158.153 211.757C157.505 211.367 157.505 210.783 158.153 210.393C158.801 210.004 159.838 210.004 160.487 210.393Z" fill="#00283B" />
        <path d="M166.612 206.856C167.26 207.245 167.26 207.829 166.612 208.219C165.964 208.608 164.927 208.608 164.279 208.219C163.63 207.829 163.63 207.245 164.279 206.856C164.927 206.466 165.964 206.466 166.612 206.856Z" fill="#00283B" />
        <path d="M172.77 203.285C173.418 203.675 173.418 204.259 172.77 204.648C172.122 205.038 171.085 205.038 170.436 204.648C169.788 204.259 169.788 203.675 170.436 203.285C171.085 202.896 172.122 202.896 172.77 203.285Z" fill="#00283B" />
        <path d="M178.895 199.747C179.544 200.137 179.544 200.721 178.895 201.11C178.247 201.5 177.21 201.5 176.562 201.11C175.914 200.721 175.914 200.137 176.562 199.747C177.21 199.358 178.247 199.358 178.895 199.747Z" fill="#00283B" />
        <path d="M185.053 196.177C185.701 196.566 185.701 197.15 185.053 197.54C184.405 197.929 183.368 197.929 182.72 197.54C182.071 197.15 182.071 196.566 182.72 196.177C183.368 195.787 184.405 195.787 185.053 196.177Z" fill="#00283B" />
        <path d="M191.179 192.639C191.827 193.028 191.827 193.613 191.179 194.002C190.53 194.392 189.493 194.392 188.845 194.002C188.197 193.613 188.197 193.028 188.845 192.639C189.493 192.249 190.53 192.249 191.179 192.639Z" fill="#00283B" />
        <path d="M197.337 189.068C197.985 189.458 197.985 190.042 197.337 190.432C196.689 190.821 195.651 190.821 195.003 190.432C194.355 190.042 194.355 189.458 195.003 189.068C195.651 188.679 196.689 188.679 197.337 189.068Z" fill="#00283B" />
        <path d="M203.462 185.53C204.11 185.92 204.11 186.504 203.462 186.894C202.814 187.283 201.777 187.283 201.129 186.894C200.481 186.504 200.481 185.92 201.129 185.53C201.777 185.141 202.814 185.141 203.462 185.53Z" fill="#00283B" />
        <path d="M209.62 181.993C210.268 182.382 210.268 182.966 209.62 183.356C208.972 183.745 207.935 183.745 207.286 183.356C206.638 182.966 206.638 182.382 207.286 181.993C207.935 181.603 208.972 181.603 209.62 181.993Z" fill="#00283B" />
        <path d="M215.745 178.422C216.394 178.812 216.394 179.396 215.745 179.785C215.097 180.175 214.06 180.175 213.412 179.785C212.764 179.396 212.764 178.812 213.412 178.422C214.06 178.033 215.097 178.033 215.745 178.422Z" fill="#00283B" />
        <path d="M234.187 167.776C234.835 168.165 234.835 168.75 234.187 169.139C233.539 169.529 232.502 169.529 231.853 169.139C231.205 168.75 231.205 168.165 231.853 167.776C232.502 167.386 233.539 167.386 234.187 167.776Z" fill="#00283B" />
        <path d="M240.312 164.238C240.961 164.627 240.961 165.212 240.312 165.601C239.664 165.991 238.627 165.991 237.979 165.601C237.331 165.212 237.331 164.627 237.979 164.238C238.627 163.848 239.664 163.848 240.312 164.238Z" fill="#00283B" />
        <path d="M246.47 160.667C247.118 161.057 247.118 161.641 246.47 162.031C245.822 162.42 244.785 162.42 244.137 162.031C243.488 161.641 243.488 161.057 244.137 160.667C244.785 160.278 245.822 160.278 246.47 160.667Z" fill="#00283B" />
        <path d="M221.903 174.884C222.551 175.274 222.551 175.858 221.903 176.247C221.255 176.637 220.218 176.637 219.57 176.247C218.921 175.858 218.921 175.274 219.57 174.884C220.218 174.495 221.255 174.495 221.903 174.884Z" fill="#00283B" />
        <path d="M228.029 171.314C228.677 171.703 228.677 172.287 228.029 172.677C227.38 173.066 226.343 173.066 225.695 172.677C225.047 172.287 225.047 171.703 225.695 171.314C226.343 170.924 227.38 170.924 228.029 171.314Z" fill="#00283B" />
        <path d="M139.647 213.899C140.295 213.51 141.332 213.51 141.98 213.899C142.629 214.289 142.629 214.873 141.98 215.262C141.332 215.652 140.295 215.652 139.647 215.262C138.999 214.873 138.999 214.289 139.647 213.899Z" fill="#00283B" />
        <path d="M148.138 210.361C148.786 210.75 148.786 211.335 148.138 211.724C147.49 212.114 146.453 212.114 145.805 211.724C145.156 211.335 145.156 210.75 145.805 210.361C146.453 209.971 147.49 209.971 148.138 210.361Z" fill="#00283B" />
        <path d="M154.296 206.791C154.944 207.18 154.944 207.764 154.296 208.154C153.648 208.543 152.611 208.543 151.963 208.154C151.315 207.764 151.315 207.18 151.963 206.791C152.611 206.401 153.648 206.401 154.296 206.791Z" fill="#00283B" />
        <path d="M160.422 203.253C161.07 203.642 161.07 204.226 160.422 204.616C159.774 205.005 158.736 205.005 158.088 204.616C157.44 204.226 157.44 203.642 158.088 203.253C158.736 202.863 159.774 202.863 160.422 203.253Z" fill="#00283B" />
        <path d="M166.579 199.715C167.228 200.104 167.228 200.688 166.579 201.078C165.931 201.467 164.894 201.467 164.246 201.078C163.598 200.688 163.598 200.104 164.246 199.715C164.894 199.325 165.931 199.325 166.579 199.715Z" fill="#00283B" />
        <path d="M172.705 196.144C173.353 196.534 173.353 197.118 172.705 197.507C172.057 197.897 171.02 197.897 170.371 197.507C169.723 197.118 169.723 196.534 170.371 196.144C171.02 195.755 172.057 195.755 172.705 196.144Z" fill="#00283B" />
        <path d="M178.863 192.606C179.511 192.996 179.511 193.58 178.863 193.97C178.215 194.359 177.178 194.359 176.53 193.97C175.881 193.58 175.881 192.996 176.53 192.606C177.178 192.217 178.215 192.217 178.863 192.606Z" fill="#00283B" />
        <path d="M184.989 189.036C185.637 189.426 185.637 190.01 184.989 190.399C184.34 190.789 183.303 190.789 182.655 190.399C182.007 190.01 182.007 189.426 182.655 189.036C183.303 188.647 184.34 188.647 184.989 189.036Z" fill="#00283B" />
        <path d="M191.146 185.498C191.795 185.887 191.795 186.472 191.146 186.861C190.498 187.251 189.461 187.251 188.813 186.861C188.165 186.472 188.165 185.887 188.813 185.498C189.461 185.108 190.498 185.108 191.146 185.498Z" fill="#00283B" />
        <path d="M197.272 181.928C197.92 182.317 197.92 182.901 197.272 183.291C196.624 183.68 195.586 183.68 194.938 183.291C194.29 182.901 194.29 182.317 194.938 181.928C195.586 181.538 196.624 181.538 197.272 181.928Z" fill="#00283B" />
        <path d="M203.43 178.39C204.078 178.779 204.078 179.363 203.43 179.753C202.781 180.142 201.744 180.142 201.096 179.753C200.448 179.363 200.448 178.779 201.096 178.39C201.744 178 202.781 178 203.43 178.39Z" fill="#00283B" />
        <path d="M209.555 174.852C210.203 175.241 210.203 175.825 209.555 176.215C208.907 176.604 207.87 176.604 207.222 176.215C206.573 175.825 206.573 175.241 207.222 174.852C207.87 174.462 208.907 174.462 209.555 174.852Z" fill="#00283B" />
        <path d="M215.713 171.281C216.361 171.671 216.361 172.255 215.713 172.645C215.065 173.034 214.028 173.034 213.38 172.645C212.732 172.255 212.732 171.671 213.38 171.281C214.028 170.892 215.065 170.892 215.713 171.281Z" fill="#00283B" />
        <path d="M234.122 160.635C234.77 161.024 234.77 161.609 234.122 161.998C233.474 162.388 232.437 162.388 231.788 161.998C231.14 161.609 231.14 161.024 231.788 160.635C232.437 160.245 233.474 160.245 234.122 160.635Z" fill="#00283B" />
        <path d="M240.28 157.097C240.928 157.487 240.928 158.071 240.28 158.46C239.631 158.85 238.594 158.85 237.946 158.46C237.298 158.071 237.298 157.487 237.946 157.097C238.594 156.708 239.631 156.708 240.28 157.097Z" fill="#00283B" />
        <path d="M221.839 167.743C222.487 168.133 222.487 168.717 221.839 169.107C221.191 169.496 220.153 169.496 219.505 169.107C218.857 168.717 218.857 168.133 219.505 167.743C220.153 167.354 221.191 167.354 221.839 167.743Z" fill="#00283B" />
        <path d="M227.996 164.173C228.645 164.562 228.645 165.147 227.996 165.536C227.348 165.926 226.311 165.926 225.663 165.536C225.015 165.147 225.015 164.562 225.663 164.173C226.311 163.783 227.348 163.783 227.996 164.173Z" fill="#00283B" />
        <path d="M133.489 210.361C134.137 209.971 135.174 209.971 135.823 210.361C136.471 210.75 136.471 211.335 135.823 211.724C135.174 212.114 134.137 212.114 133.489 211.724C132.841 211.335 132.841 210.75 133.489 210.361Z" fill="#00283B" />
        <path d="M141.98 206.791C142.629 207.18 142.629 207.764 141.98 208.154C141.332 208.543 140.295 208.543 139.647 208.154C138.999 207.764 138.999 207.18 139.647 206.791C140.295 206.401 141.332 206.401 141.98 206.791Z" fill="#00283B" />
        <path d="M148.138 203.253C148.786 203.642 148.786 204.226 148.138 204.616C147.49 205.005 146.453 205.005 145.805 204.616C145.156 204.226 145.156 203.642 145.805 203.253C146.453 202.863 147.49 202.863 148.138 203.253Z" fill="#00283B" />
        <path d="M154.296 199.715C154.944 200.104 154.944 200.688 154.296 201.078C153.648 201.467 152.611 201.467 151.963 201.078C151.315 200.688 151.315 200.104 151.963 199.715C152.611 199.325 153.648 199.325 154.296 199.715Z" fill="#00283B" />
        <path d="M160.422 196.144C161.07 196.534 161.07 197.118 160.422 197.507C159.774 197.897 158.736 197.897 158.088 197.507C157.44 197.118 157.44 196.534 158.088 196.144C158.736 195.755 159.774 195.755 160.422 196.144Z" fill="#00283B" />
        <path d="M166.579 192.606C167.228 192.996 167.228 193.58 166.579 193.97C165.931 194.359 164.894 194.359 164.246 193.97C163.598 193.58 163.598 192.996 164.246 192.606C164.894 192.217 165.931 192.217 166.579 192.606Z" fill="#00283B" />
        <path d="M172.705 189.036C173.353 189.426 173.353 190.01 172.705 190.399C172.057 190.789 171.02 190.789 170.371 190.399C169.723 190.01 169.723 189.426 170.371 189.036C171.02 188.647 172.057 188.647 172.705 189.036Z" fill="#00283B" />
        <path d="M178.863 185.498C179.511 185.887 179.511 186.472 178.863 186.861C178.215 187.251 177.178 187.251 176.53 186.861C175.881 186.472 175.881 185.887 176.53 185.498C177.178 185.108 178.215 185.108 178.863 185.498Z" fill="#00283B" />
        <path d="M184.989 181.928C185.637 182.317 185.637 182.901 184.989 183.291C184.34 183.68 183.303 183.68 182.655 183.291C182.007 182.901 182.007 182.317 182.655 181.928C183.303 181.538 184.34 181.538 184.989 181.928Z" fill="#00283B" />
        <path d="M191.146 178.39C191.795 178.779 191.795 179.363 191.146 179.753C190.498 180.142 189.461 180.142 188.813 179.753C188.165 179.363 188.165 178.779 188.813 178.39C189.461 178 190.498 178 191.146 178.39Z" fill="#00283B" />
        <path d="M197.272 174.852C197.92 175.241 197.92 175.825 197.272 176.215C196.624 176.604 195.586 176.604 194.938 176.215C194.29 175.825 194.29 175.241 194.938 174.852C195.586 174.462 196.624 174.462 197.272 174.852Z" fill="#00283B" />
        <path d="M203.43 171.281C204.078 171.671 204.078 172.255 203.43 172.645C202.781 173.034 201.744 173.034 201.096 172.645C200.448 172.255 200.448 171.671 201.096 171.281C201.744 170.892 202.781 170.892 203.43 171.281Z" fill="#00283B" />
        <path d="M209.555 167.743C210.203 168.133 210.203 168.717 209.555 169.107C208.907 169.496 207.87 169.496 207.222 169.107C206.573 168.717 206.573 168.133 207.222 167.743C207.87 167.354 208.907 167.354 209.555 167.743Z" fill="#00283B" />
        <path d="M215.713 164.173C216.361 164.562 216.361 165.147 215.713 165.536C215.065 165.926 214.028 165.926 213.38 165.536C212.732 165.147 212.732 164.562 213.38 164.173C214.028 163.783 215.065 163.783 215.713 164.173Z" fill="#00283B" />
        <path d="M221.839 160.635C222.487 161.024 222.487 161.609 221.839 161.998C221.191 162.388 220.153 162.388 219.505 161.998C218.857 161.609 218.857 161.024 219.505 160.635C220.153 160.245 221.191 160.245 221.839 160.635Z" fill="#00283B" />
        <path d="M227.996 157.097C228.645 157.487 228.645 158.071 227.996 158.46C227.348 158.85 226.311 158.85 225.663 158.46C225.015 158.071 225.015 157.487 225.663 157.097C226.311 156.708 227.348 156.708 227.996 157.097Z" fill="#00283B" />
        <path d="M234.122 153.526C234.77 153.916 234.77 154.5 234.122 154.89C233.474 155.279 232.437 155.279 231.788 154.89C231.14 154.5 231.14 153.916 231.788 153.526C232.437 153.137 233.474 153.137 234.122 153.526Z" fill="#00283B" />
        <path d="M127.364 206.823C128.012 206.434 129.049 206.434 129.697 206.823C130.345 207.213 130.345 207.797 129.697 208.186C129.049 208.576 128.012 208.576 127.364 208.186C126.715 207.797 126.715 207.213 127.364 206.823Z" fill="#00283B" />
        <path d="M135.855 203.253C136.503 203.642 136.503 204.226 135.855 204.616C135.207 205.005 134.17 205.005 133.521 204.616C132.873 204.226 132.873 203.642 133.521 203.253C134.17 202.863 135.207 202.863 135.855 203.253Z" fill="#00283B" />
        <path d="M141.98 199.715C142.629 200.104 142.629 200.688 141.98 201.078C141.332 201.467 140.295 201.467 139.647 201.078C138.999 200.688 138.999 200.104 139.647 199.715C140.295 199.325 141.332 199.325 141.98 199.715Z" fill="#00283B" />
        <path d="M148.138 196.144C148.786 196.534 148.786 197.118 148.138 197.507C147.49 197.897 146.453 197.897 145.805 197.507C145.156 197.118 145.156 196.534 145.805 196.144C146.453 195.755 147.49 195.755 148.138 196.144Z" fill="#00283B" />
        <path d="M154.296 192.606C154.944 192.996 154.944 193.58 154.296 193.97C153.648 194.359 152.611 194.359 151.963 193.97C151.315 193.58 151.315 192.996 151.963 192.606C152.611 192.217 153.648 192.217 154.296 192.606Z" fill="#00283B" />
        <path d="M160.422 189.036C161.07 189.426 161.07 190.01 160.422 190.399C159.774 190.789 158.736 190.789 158.088 190.399C157.44 190.01 157.44 189.426 158.088 189.036C158.736 188.647 159.774 188.647 160.422 189.036Z" fill="#00283B" />
        <path d="M166.579 185.498C167.228 185.887 167.228 186.472 166.579 186.861C165.931 187.251 164.894 187.251 164.246 186.861C163.598 186.472 163.598 185.887 164.246 185.498C164.894 185.108 165.931 185.108 166.579 185.498Z" fill="#00283B" />
        <path d="M172.705 181.928C173.353 182.317 173.353 182.901 172.705 183.291C172.057 183.68 171.02 183.68 170.371 183.291C169.723 182.901 169.723 182.317 170.371 181.928C171.02 181.538 172.057 181.538 172.705 181.928Z" fill="#00283B" />
        <path d="M178.863 178.39C179.511 178.779 179.511 179.363 178.863 179.753C178.215 180.142 177.178 180.142 176.53 179.753C175.881 179.363 175.881 178.779 176.53 178.39C177.178 178 178.215 178 178.863 178.39Z" fill="#00283B" />
        <path d="M184.989 174.852C185.637 175.241 185.637 175.825 184.989 176.215C184.34 176.604 183.303 176.604 182.655 176.215C182.007 175.825 182.007 175.241 182.655 174.852C183.303 174.462 184.34 174.462 184.989 174.852Z" fill="#00283B" />
        <path d="M191.146 171.281C191.795 171.671 191.795 172.255 191.146 172.645C190.498 173.034 189.461 173.034 188.813 172.645C188.165 172.255 188.165 171.671 188.813 171.281C189.461 170.892 190.498 170.892 191.146 171.281Z" fill="#00283B" />
        <path d="M197.272 167.743C197.92 168.133 197.92 168.717 197.272 169.107C196.624 169.496 195.586 169.496 194.938 169.107C194.29 168.717 194.29 168.133 194.938 167.743C195.586 167.354 196.624 167.354 197.272 167.743Z" fill="#00283B" />
        <path d="M203.43 164.173C204.078 164.562 204.078 165.147 203.43 165.536C202.781 165.926 201.744 165.926 201.096 165.536C200.448 165.147 200.448 164.562 201.096 164.173C201.744 163.783 202.781 163.783 203.43 164.173Z" fill="#00283B" />
        <path d="M209.555 160.635C210.203 161.024 210.203 161.609 209.555 161.998C208.907 162.388 207.87 162.388 207.222 161.998C206.573 161.609 206.573 161.024 207.222 160.635C207.87 160.245 208.907 160.245 209.555 160.635Z" fill="#00283B" />
        <path d="M215.713 157.097C216.361 157.487 216.361 158.071 215.713 158.46C215.065 158.85 214.028 158.85 213.38 158.46C212.732 158.071 212.732 157.487 213.38 157.097C214.028 156.708 215.065 156.708 215.713 157.097Z" fill="#00283B" />
        <path d="M221.839 153.526C222.487 153.916 222.487 154.5 221.839 154.89C221.191 155.279 220.153 155.279 219.505 154.89C218.857 154.5 218.857 153.916 219.505 153.526C220.153 153.137 221.191 153.137 221.839 153.526Z" fill="#00283B" />
        <path d="M227.996 149.989C228.645 150.378 228.645 150.962 227.996 151.352C227.348 151.741 226.311 151.741 225.663 151.352C225.015 150.962 225.015 150.378 225.663 149.989C226.311 149.599 227.348 149.599 227.996 149.989Z" fill="#00283B" />
        <path d="M121.205 203.253C121.854 202.863 122.891 202.863 123.539 203.253C124.187 203.642 124.187 204.226 123.539 204.616C122.891 205.005 121.854 205.005 121.205 204.616C120.557 204.226 120.557 203.642 121.205 203.253Z" fill="#00283B" />
        <path d="M129.697 199.715C130.345 200.104 130.345 200.688 129.697 201.078C129.049 201.467 128.012 201.467 127.364 201.078C126.715 200.688 126.715 200.104 127.364 199.715C128.012 199.325 129.049 199.325 129.697 199.715Z" fill="#00283B" />
        <path d="M135.855 196.144C136.503 196.534 136.503 197.118 135.855 197.507C135.207 197.897 134.17 197.897 133.521 197.507C132.873 197.118 132.873 196.534 133.521 196.144C134.17 195.755 135.207 195.755 135.855 196.144Z" fill="#00283B" />
        <path d="M141.98 192.606C142.629 192.996 142.629 193.58 141.98 193.97C141.332 194.359 140.295 194.359 139.647 193.97C138.999 193.58 138.999 192.996 139.647 192.606C140.295 192.217 141.332 192.217 141.98 192.606Z" fill="#00283B" />
        <path d="M148.138 189.036C148.786 189.426 148.786 190.01 148.138 190.399C147.49 190.789 146.453 190.789 145.805 190.399C145.156 190.01 145.156 189.426 145.805 189.036C146.453 188.647 147.49 188.647 148.138 189.036Z" fill="#00283B" />
        <path d="M154.296 185.498C154.944 185.887 154.944 186.472 154.296 186.861C153.648 187.251 152.611 187.251 151.963 186.861C151.315 186.472 151.315 185.887 151.963 185.498C152.611 185.108 153.648 185.108 154.296 185.498Z" fill="#00283B" />
        <path d="M160.422 181.928C161.07 182.317 161.07 182.901 160.422 183.291C159.774 183.68 158.736 183.68 158.088 183.291C157.44 182.901 157.44 182.317 158.088 181.928C158.736 181.538 159.774 181.538 160.422 181.928Z" fill="#00283B" />
        <path d="M166.579 178.39C167.228 178.779 167.228 179.363 166.579 179.753C165.931 180.142 164.894 180.142 164.246 179.753C163.598 179.363 163.598 178.779 164.246 178.39C164.894 178 165.931 178 166.579 178.39Z" fill="#00283B" />
        <path d="M172.705 174.852C173.353 175.241 173.353 175.825 172.705 176.215C172.057 176.604 171.02 176.604 170.371 176.215C169.723 175.825 169.723 175.241 170.371 174.852C171.02 174.462 172.057 174.462 172.705 174.852Z" fill="#00283B" />
        <path d="M178.863 171.281C179.511 171.671 179.511 172.255 178.863 172.645C178.215 173.034 177.178 173.034 176.53 172.645C175.881 172.255 175.881 171.671 176.53 171.281C177.178 170.892 178.215 170.892 178.863 171.281Z" fill="#00283B" />
        <path d="M184.989 167.743C185.637 168.133 185.637 168.717 184.989 169.107C184.34 169.496 183.303 169.496 182.655 169.107C182.007 168.717 182.007 168.133 182.655 167.743C183.303 167.354 184.34 167.354 184.989 167.743Z" fill="#00283B" />
        <path d="M191.146 164.173C191.795 164.562 191.795 165.147 191.146 165.536C190.498 165.926 189.461 165.926 188.813 165.536C188.165 165.147 188.165 164.562 188.813 164.173C189.461 163.783 190.498 163.783 191.146 164.173Z" fill="#00283B" />
        <path d="M197.272 160.635C197.92 161.024 197.92 161.609 197.272 161.998C196.624 162.388 195.586 162.388 194.938 161.998C194.29 161.609 194.29 161.024 194.938 160.635C195.586 160.245 196.624 160.245 197.272 160.635Z" fill="#00283B" />
        <path d="M203.43 157.097C204.078 157.487 204.078 158.071 203.43 158.46C202.781 158.85 201.744 158.85 201.096 158.46C200.448 158.071 200.448 157.487 201.096 157.097C201.744 156.708 202.781 156.708 203.43 157.097Z" fill="#00283B" />
        <path d="M209.555 153.526C210.203 153.916 210.203 154.5 209.555 154.89C208.907 155.279 207.87 155.279 207.222 154.89C206.573 154.5 206.573 153.916 207.222 153.526C207.87 153.137 208.907 153.137 209.555 153.526Z" fill="#00283B" />
        <path d="M215.713 149.989C216.361 150.378 216.361 150.962 215.713 151.352C215.065 151.741 214.028 151.741 213.38 151.352C212.732 150.962 212.732 150.378 213.38 149.989C214.028 149.599 215.065 149.599 215.713 149.989Z" fill="#00283B" />
        <path d="M221.839 146.418C222.487 146.808 222.487 147.392 221.839 147.782C221.191 148.171 220.153 148.171 219.505 147.782C218.857 147.392 218.857 146.808 219.505 146.418C220.153 146.029 221.191 146.029 221.839 146.418Z" fill="#00283B" />
        <path d="M221.644 139.927C222.292 140.316 222.292 140.9 221.644 141.29C220.996 141.679 219.959 141.679 219.31 141.29C218.662 140.9 218.662 140.316 219.31 139.927C219.959 139.537 220.996 139.537 221.644 139.927Z" fill="#00283B" />
        <path d="M227.802 136.356C228.45 136.746 228.45 137.33 227.802 137.72C227.154 138.109 226.117 138.109 225.469 137.72C224.82 137.33 224.82 136.746 225.469 136.356C226.117 135.967 227.154 135.967 227.802 136.356Z" fill="#00283B" />
        <path d="M233.928 132.818C234.576 133.208 234.576 133.792 233.928 134.181C233.279 134.571 232.242 134.571 231.594 134.181C230.946 133.792 230.946 133.208 231.594 132.818C232.242 132.429 233.279 132.429 233.928 132.818Z" fill="#00283B" />
        <path d="M240.085 129.28C240.733 129.67 240.733 130.254 240.085 130.644C239.437 131.033 238.4 131.033 237.752 130.644C237.104 130.254 237.104 129.67 237.752 129.28C238.4 128.891 239.437 128.891 240.085 129.28Z" fill="#00283B" />
        <path d="M258.494 118.602C259.143 118.991 259.143 119.575 258.494 119.965C257.846 120.354 256.809 120.354 256.161 119.965C255.513 119.575 255.513 118.991 256.161 118.602C256.809 118.212 257.846 118.212 258.494 118.602Z" fill="#00283B" />
        <path d="M264.652 115.064C265.3 115.453 265.3 116.037 264.652 116.427C264.004 116.816 262.967 116.816 262.319 116.427C261.67 116.037 261.67 115.453 262.319 115.064C262.967 114.674 264.004 114.674 264.652 115.064Z" fill="#00283B" />
        <path d="M270.81 111.526C271.458 111.915 271.458 112.5 270.81 112.889C270.162 113.279 269.125 113.279 268.476 112.889C267.828 112.5 267.828 111.915 268.476 111.526C269.125 111.136 270.162 111.136 270.81 111.526C271.458 111.915 270.162 111.136 270.81 111.526Z" fill="#00283B" />
        <path d="M246.211 125.71C246.859 126.099 246.859 126.684 246.211 127.073C245.563 127.463 244.525 127.463 243.877 127.073C243.229 126.684 243.229 126.099 243.877 125.71C244.525 125.32 245.563 125.32 246.211 125.71Z" fill="#00283B" />
        <path d="M252.369 122.172C253.017 122.562 253.017 123.146 252.369 123.535C251.721 123.925 250.684 123.925 250.035 123.535C249.387 123.146 249.387 122.562 250.035 122.172C250.684 121.783 251.721 121.783 252.369 122.172Z" fill="#00283B" />
        <path d="M213.153 136.389C213.801 135.999 214.838 135.999 215.486 136.389C216.134 136.778 216.134 137.363 215.486 137.752C214.838 138.142 213.801 138.142 213.153 137.752C212.504 137.363 212.504 136.778 213.153 136.389Z" fill="#00283B" />
        <path d="M221.644 132.818C222.292 133.208 222.292 133.792 221.644 134.181C220.996 134.571 219.959 134.571 219.31 134.181C218.662 133.792 218.662 133.208 219.31 132.818C219.959 132.429 220.996 132.429 221.644 132.818Z" fill="#00283B" />
        <path d="M227.802 129.28C228.45 129.67 228.45 130.254 227.802 130.644C227.154 131.033 226.117 131.033 225.469 130.644C224.82 130.254 224.82 129.67 225.469 129.28C226.117 128.891 227.154 128.891 227.802 129.28Z" fill="#00283B" />
        <path d="M233.928 125.71C234.576 126.099 234.576 126.684 233.928 127.073C233.279 127.463 232.242 127.463 231.594 127.073C230.946 126.684 230.946 126.099 231.594 125.71C232.242 125.32 233.279 125.32 233.928 125.71Z" fill="#00283B" />
        <path d="M240.085 122.172C240.733 122.562 240.733 123.146 240.085 123.535C239.437 123.925 238.4 123.925 237.752 123.535C237.104 123.146 237.104 122.562 237.752 122.172C238.4 121.783 239.437 121.783 240.085 122.172Z" fill="#00283B" />
        <path d="M246.211 118.602C246.859 118.991 246.859 119.575 246.211 119.965C245.563 120.354 244.525 120.354 243.877 119.965C243.229 119.575 243.229 118.991 243.877 118.602C244.525 118.212 245.563 118.212 246.211 118.602Z" fill="#00283B" />
        <path d="M252.369 115.064C253.017 115.453 253.017 116.037 252.369 116.427C251.721 116.816 250.684 116.816 250.035 116.427C249.387 116.037 249.387 115.453 250.035 115.064C250.684 114.674 251.721 114.674 252.369 115.064Z" fill="#00283B" />
        <path d="M258.494 111.526C259.143 111.915 259.143 112.5 258.494 112.889C257.846 113.279 256.809 113.279 256.161 112.889C255.513 112.5 255.513 111.915 256.161 111.526C256.809 111.136 257.846 111.136 258.494 111.526Z" fill="#00283B" />
        <path d="M264.652 107.955C265.3 108.345 265.3 108.929 264.652 109.318C264.004 109.708 262.967 109.708 262.319 109.318C261.67 108.929 261.67 108.345 262.319 107.955C262.967 107.566 264.004 107.566 264.652 107.955Z" fill="#00283B" />
        <path d="M207.027 132.818C207.675 132.429 208.712 132.429 209.361 132.818C210.009 133.208 210.009 133.792 209.361 134.181C208.712 134.571 207.675 134.571 207.027 134.181C206.379 133.792 206.379 133.208 207.027 132.818Z" fill="#00283B" />
        <path d="M215.518 129.28C216.167 129.67 216.167 130.254 215.518 130.644C214.87 131.033 213.833 131.033 213.185 130.644C212.537 130.254 212.537 129.67 213.185 129.28C213.833 128.891 214.87 128.891 215.518 129.28Z" fill="#00283B" />
        <path d="M221.644 125.71C222.292 126.099 222.292 126.684 221.644 127.073C220.996 127.463 219.959 127.463 219.31 127.073C218.662 126.684 218.662 126.099 219.31 125.71C219.959 125.32 220.996 125.32 221.644 125.71Z" fill="#00283B" />
        <path d="M227.802 122.172C228.45 122.562 228.45 123.146 227.802 123.535C227.154 123.925 226.117 123.925 225.469 123.535C224.82 123.146 224.82 122.562 225.469 122.172C226.117 121.783 227.154 121.783 227.802 122.172Z" fill="#00283B" />
        <path d="M233.928 118.602C234.576 118.991 234.576 119.575 233.928 119.965C233.279 120.354 232.242 120.354 231.594 119.965C230.946 119.575 230.946 118.991 231.594 118.602C232.242 118.212 233.279 118.212 233.928 118.602Z" fill="#00283B" />
        <path d="M240.085 115.064C240.733 115.453 240.733 116.037 240.085 116.427C239.437 116.816 238.4 116.816 237.752 116.427C237.104 116.037 237.104 115.453 237.752 115.064C238.4 114.674 239.437 114.674 240.085 115.064Z" fill="#00283B" />
        <path d="M246.211 111.526C246.859 111.915 246.859 112.5 246.211 112.889C245.563 113.279 244.525 113.279 243.877 112.889C243.229 112.5 243.229 111.915 243.877 111.526C244.525 111.136 245.563 111.136 246.211 111.526Z" fill="#00283B" />
        <path d="M252.369 107.955C253.017 108.345 253.017 108.929 252.369 109.318C251.721 109.708 250.684 109.708 250.035 109.318C249.387 108.929 249.387 108.345 250.035 107.955C250.684 107.566 251.721 107.566 252.369 107.955Z" fill="#00283B" />
        <path d="M258.494 104.417C259.143 104.807 259.143 105.391 258.494 105.781C257.846 106.17 256.809 106.17 256.161 105.781C255.513 105.391 255.513 104.807 256.161 104.417C256.809 104.028 257.846 104.028 258.494 104.417Z" fill="#00283B" />
        <path d="M200.869 129.28C201.518 128.891 202.555 128.891 203.203 129.28C203.851 129.67 203.851 130.254 203.203 130.644C202.555 131.033 201.518 131.033 200.869 130.644C200.221 130.254 200.221 129.67 200.869 129.28Z" fill="#00283B" />
        <path d="M209.361 125.71C210.009 126.099 210.009 126.684 209.361 127.073C208.712 127.463 207.675 127.463 207.027 127.073C206.379 126.684 206.379 126.099 207.027 125.71C207.675 125.32 208.712 125.32 209.361 125.71Z" fill="#00283B" />
        <path d="M215.518 122.172C216.167 122.562 216.167 123.146 215.518 123.535C214.87 123.925 213.833 123.925 213.185 123.535C212.537 123.146 212.537 122.562 213.185 122.172C213.833 121.783 214.87 121.783 215.518 122.172Z" fill="#00283B" />
        <path d="M221.644 118.602C222.292 118.991 222.292 119.575 221.644 119.965C220.996 120.354 219.959 120.354 219.31 119.965C218.662 119.575 218.662 118.991 219.31 118.602C219.959 118.212 220.996 118.212 221.644 118.602Z" fill="#00283B" />
        <path d="M227.802 115.064C228.45 115.453 228.45 116.037 227.802 116.427C227.154 116.816 226.117 116.816 225.469 116.427C224.82 116.037 224.82 115.453 225.469 115.064C226.117 114.674 227.154 114.674 227.802 115.064Z" fill="#00283B" />
        <path d="M233.928 111.526C234.576 111.915 234.576 112.5 233.928 112.889C233.279 113.279 232.242 113.279 231.594 112.889C230.946 112.5 230.946 111.915 231.594 111.526C232.242 111.136 233.279 111.136 233.928 111.526Z" fill="#00283B" />
        <path d="M240.085 107.955C240.733 108.345 240.733 108.929 240.085 109.318C239.437 109.708 238.4 109.708 237.752 109.318C237.104 108.929 237.104 108.345 237.752 107.955C238.4 107.566 239.437 107.566 240.085 107.955Z" fill="#00283B" />
        <path d="M246.211 104.417C246.859 104.807 246.859 105.391 246.211 105.781C245.563 106.17 244.525 106.17 243.877 105.781C243.229 105.391 243.229 104.807 243.877 104.417C244.525 104.028 245.563 104.028 246.211 104.417Z" fill="#00283B" />
        <path d="M252.369 100.847C253.017 101.236 253.017 101.821 252.369 102.21C251.721 102.6 250.684 102.6 250.035 102.21C249.387 101.821 249.387 101.236 250.035 100.847C250.684 100.457 251.721 100.457 252.369 100.847Z" fill="#00283B" />
        <path d="M194.744 125.71C195.392 125.32 196.429 125.32 197.077 125.71C197.726 126.099 197.726 126.684 197.077 127.073C196.429 127.463 195.392 127.463 194.744 127.073C194.096 126.684 194.096 126.099 194.744 125.71Z" fill="#00283B" />
        <path d="M203.235 122.172C203.883 122.562 203.883 123.146 203.235 123.535C202.587 123.925 201.55 123.925 200.902 123.535C200.253 123.146 200.253 122.562 200.902 122.172C201.55 121.783 202.587 121.783 203.235 122.172Z" fill="#00283B" />
        <path d="M209.361 118.602C210.009 118.991 210.009 119.575 209.361 119.965C208.712 120.354 207.675 120.354 207.027 119.965C206.379 119.575 206.379 118.991 207.027 118.602C207.675 118.212 208.712 118.212 209.361 118.602Z" fill="#00283B" />
        <path d="M215.518 115.064C216.167 115.453 216.167 116.037 215.518 116.427C214.87 116.816 213.833 116.816 213.185 116.427C212.537 116.037 212.537 115.453 213.185 115.064C213.833 114.674 214.87 114.674 215.518 115.064Z" fill="#00283B" />
        <path d="M221.644 111.526C222.292 111.915 222.292 112.5 221.644 112.889C220.996 113.279 219.959 113.279 219.31 112.889C218.662 112.5 218.662 111.915 219.31 111.526C219.959 111.136 220.996 111.136 221.644 111.526Z" fill="#00283B" />
        <path d="M227.802 107.955C228.45 108.345 228.45 108.929 227.802 109.318C227.154 109.708 226.117 109.708 225.469 109.318C224.82 108.929 224.82 108.345 225.469 107.955C226.117 107.566 227.154 107.566 227.802 107.955Z" fill="#00283B" />
        <path d="M233.928 104.417C234.576 104.807 234.576 105.391 233.928 105.781C233.279 106.17 232.242 106.17 231.594 105.781C230.946 105.391 230.946 104.807 231.594 104.417C232.242 104.028 233.279 104.028 233.928 104.417Z" fill="#00283B" />
        <path d="M237.72 100.847C238.368 100.457 239.405 100.457 240.053 100.847C240.701 101.236 240.701 101.821 240.053 102.21C239.405 102.6 238.368 102.6 237.72 102.21C237.071 101.821 237.071 101.236 237.72 100.847C238.368 100.457 237.071 101.236 237.72 100.847Z" fill="#00283B" />
        <path d="M243.877 97.309C244.525 96.9195 245.563 96.9195 246.211 97.309C246.859 97.6985 246.859 98.2827 246.211 98.6722C245.563 99.0617 244.525 99.0617 243.877 98.6722C243.229 98.2827 243.229 97.6985 243.877 97.309C244.525 96.9195 243.229 97.6985 243.877 97.309Z" fill="#00283B" />
        <path d="M188.521 122.14C189.17 121.75 190.207 121.75 190.855 122.14C191.503 122.529 191.503 123.113 190.855 123.503C190.207 123.892 189.17 123.892 188.521 123.503C187.873 123.113 187.873 122.529 188.521 122.14Z" fill="#00283B" />
        <path d="M197.045 118.569C197.693 118.958 197.693 119.543 197.045 119.932C196.397 120.322 195.359 120.322 194.711 119.932C194.063 119.543 194.063 118.958 194.711 118.569C195.359 118.179 196.397 118.179 197.045 118.569Z" fill="#00283B" />
        <path d="M200.837 115.031C201.485 114.642 202.522 114.642 203.17 115.031C203.818 115.421 203.818 116.005 203.17 116.394C202.522 116.784 201.485 116.784 200.837 116.394C200.189 116.005 200.189 115.421 200.837 115.031C201.485 114.642 200.189 115.421 200.837 115.031Z" fill="#00283B" />
        <path d="M209.328 111.461C209.977 111.85 209.977 112.435 209.328 112.824C208.68 113.214 207.643 113.214 206.995 112.824C206.347 112.435 208.68 111.104 209.328 111.461Z" fill="#00283B" />
        <path d="M250.295 157.162C250.943 156.772 251.98 156.772 252.628 157.162C253.276 157.551 253.276 158.136 252.628 158.525C251.98 158.915 250.943 158.915 250.295 158.525C249.647 158.136 249.647 157.551 250.295 157.162Z" fill="#00283B" />
        <path d="M258.818 153.591C259.466 153.981 259.466 154.565 258.818 154.955C258.17 155.344 257.133 155.344 256.485 154.955C255.836 154.565 255.836 153.981 256.485 153.591C257.133 153.202 258.17 153.202 258.818 153.591Z" fill="#00283B" />
        <path d="M264.944 150.054C265.592 150.443 265.592 151.027 264.944 151.417C264.295 151.806 263.258 151.806 262.61 151.417C261.962 151.027 261.962 150.443 262.61 150.054C263.258 149.664 264.295 149.664 264.944 150.054Z" fill="#00283B" />
        <path d="M271.102 146.483C271.75 146.873 271.75 147.457 271.102 147.847C270.454 148.236 269.417 148.236 268.768 147.847C268.12 147.457 268.12 146.873 268.768 146.483C269.417 146.094 270.454 146.094 271.102 146.483Z" fill="#00283B" />
        <path d="M277.227 142.945C277.876 143.335 277.876 143.919 277.227 144.308C276.579 144.698 275.542 144.698 274.894 144.308C274.246 143.919 274.246 143.335 274.894 142.945C275.542 142.556 276.579 142.556 277.227 142.945Z" fill="#00283B" />
        <path d="M283.385 139.407C284.033 139.797 284.033 140.381 283.385 140.771C282.737 141.16 281.7 141.16 281.052 140.771C280.403 140.381 280.403 139.797 281.052 139.407C281.7 139.018 282.737 139.018 283.385 139.407Z" fill="#00283B" />
        <path d="M289.511 135.837C290.159 136.226 290.159 136.811 289.511 137.2C288.862 137.59 287.825 137.59 287.177 137.2C286.529 136.811 286.529 136.226 287.177 135.837C287.825 135.447 288.862 135.447 289.511 135.837Z" fill="#00283B" />
        <path d="M244.104 153.559C244.753 153.17 245.79 153.17 246.438 153.559C247.086 153.949 247.086 154.533 246.438 154.922C245.79 155.312 244.753 155.312 244.104 154.922C243.456 154.533 243.456 153.949 244.104 153.559Z" fill="#00283B" />
        <path d="M252.596 150.021C253.244 150.411 253.244 150.995 252.596 151.384C251.947 151.774 250.91 151.774 250.262 151.384C249.614 150.995 249.614 150.411 250.262 150.021C250.91 149.632 251.947 149.632 252.596 150.021Z" fill="#00283B" />
        <path d="M258.754 146.451C259.402 146.84 259.402 147.425 258.754 147.814C258.106 148.204 257.068 148.204 256.42 147.814C255.772 147.425 255.772 146.84 256.42 146.451C257.068 146.061 258.106 146.061 258.754 146.451Z" fill="#00283B" />
        <path d="M264.879 142.913C265.527 143.302 265.527 143.886 264.879 144.276C264.231 144.665 263.194 144.665 262.546 144.276C261.898 143.886 261.898 143.302 262.546 142.913C263.194 142.523 264.231 142.523 264.879 142.913Z" fill="#00283B" />
        <path d="M271.037 139.342C271.685 139.732 271.685 140.316 271.037 140.706C270.389 141.095 269.352 141.095 268.703 140.706C268.055 140.316 268.055 139.732 268.703 139.342C269.352 138.953 270.389 138.953 271.037 139.342Z" fill="#00283B" />
        <path d="M277.162 135.804C277.811 136.194 277.811 136.778 277.162 137.168C276.514 137.557 275.477 137.557 274.829 137.168C274.181 136.778 274.181 136.194 274.829 135.804C275.477 135.415 276.514 135.415 277.162 135.804Z" fill="#00283B" />
        <path d="M283.32 132.266C283.968 132.656 283.968 133.24 283.32 133.63C282.672 134.019 281.635 134.019 280.987 133.63C280.338 133.24 280.338 132.656 280.987 132.266C281.635 131.877 282.672 131.877 283.32 132.266Z" fill="#00283B" />
        <path d="M289.478 128.696C290.127 129.086 290.127 129.67 289.478 130.059C288.83 130.449 287.793 130.449 287.145 130.059C286.497 129.67 286.497 129.086 287.145 128.696C287.793 128.307 288.83 128.307 289.478 128.696Z" fill="#00283B" />
        <path d="M237.979 150.021C238.627 149.632 239.664 149.632 240.312 150.021C240.961 150.411 240.961 150.995 240.312 151.384C239.664 151.774 238.627 151.774 237.979 151.384C237.331 150.995 237.331 150.411 237.979 150.021Z" fill="#00283B" />
        <path d="M246.47 146.451C247.118 146.84 247.118 147.425 246.47 147.814C245.822 148.204 244.785 148.204 244.137 147.814C243.488 147.425 243.488 146.84 244.137 146.451C244.785 146.061 245.822 146.061 246.47 146.451Z" fill="#00283B" />
        <path d="M252.596 142.913C253.244 143.302 253.244 143.886 252.596 144.276C251.947 144.665 250.91 144.665 250.262 144.276C249.614 143.886 249.614 143.302 250.262 142.913C250.91 142.523 251.947 142.523 252.596 142.913Z" fill="#00283B" />
        <path d="M258.754 139.342C259.402 139.732 259.402 140.316 258.754 140.706C258.106 141.095 257.068 141.095 256.42 140.706C255.772 140.316 255.772 139.732 256.42 139.342C257.068 138.953 258.106 138.953 258.754 139.342Z" fill="#00283B" />
        <path d="M264.879 135.804C265.527 136.194 265.527 136.778 264.879 137.168C264.231 137.557 263.194 137.557 262.546 137.168C261.898 136.778 261.898 136.194 262.546 135.804C263.194 135.415 264.231 135.415 264.879 135.804Z" fill="#00283B" />
        <path d="M271.037 132.266C271.685 132.656 271.685 133.24 271.037 133.63C270.389 134.019 269.352 134.019 268.703 133.63C268.055 133.24 268.055 132.656 268.703 132.266C269.352 131.877 270.389 131.877 271.037 132.266Z" fill="#00283B" />
        <path d="M277.162 128.696C277.811 129.086 277.811 129.67 277.162 130.059C276.514 130.449 275.477 130.449 274.829 130.059C274.181 129.67 274.181 129.086 274.829 128.696C275.477 128.307 276.514 128.307 277.162 128.696Z" fill="#00283B" />
        <path d="M283.32 125.158C283.968 125.548 283.968 126.132 283.32 126.521C282.672 126.911 281.635 126.911 280.987 126.521C280.338 126.132 280.338 125.548 280.987 125.158C281.635 124.769 282.672 124.769 283.32 125.158Z" fill="#00283B" />
        <path d="M246.47 139.342C247.118 139.732 247.118 140.316 246.47 140.706C245.822 141.095 244.785 141.095 244.137 140.706C243.488 140.316 243.488 139.732 244.137 139.342C244.785 138.953 245.822 138.953 246.47 139.342Z" fill="#00283B" />
        <path d="M252.596 135.804C253.244 136.194 253.244 136.778 252.596 137.168C251.947 137.557 250.91 137.557 250.262 137.168C249.614 136.778 249.614 136.194 250.262 135.804C250.91 135.415 251.947 135.415 252.596 135.804Z" fill="#00283B" />
        <path d="M258.754 132.266C259.402 132.656 259.402 133.24 258.754 133.63C258.106 134.019 257.068 134.019 256.42 133.63C255.772 133.24 255.772 132.656 256.42 132.266C257.068 131.877 258.106 131.877 258.754 132.266Z" fill="#00283B" />
        <path d="M264.879 128.696C265.527 129.086 265.527 129.67 264.879 130.059C264.231 130.449 263.194 130.449 262.546 130.059C261.898 129.67 261.898 129.086 262.546 128.696C263.194 128.307 264.231 128.307 264.879 128.696Z" fill="#00283B" />
        <path d="M271.037 125.158C271.685 125.548 271.685 126.132 271.037 126.521C270.389 126.911 269.352 126.911 268.703 126.521C268.055 126.132 268.055 125.548 268.703 125.158C269.352 124.769 270.389 124.769 271.037 125.158Z" fill="#00283B" />
        <path d="M277.162 121.588C277.811 121.977 277.811 122.562 277.162 122.951C276.514 123.341 275.477 123.341 274.829 122.951C274.181 122.562 274.181 121.977 274.829 121.588C275.477 121.198 276.514 121.198 277.162 121.588Z" fill="#00283B" />
        <path d="M231.821 146.451C232.469 146.061 233.506 146.061 234.154 146.451C234.802 146.84 234.802 147.425 234.154 147.814C233.506 148.204 232.469 148.204 231.821 147.814C231.172 147.425 231.172 146.84 231.821 146.451Z" fill="#00283B" />
        <path d="M240.312 142.913C240.961 143.302 240.961 143.886 240.312 144.276C239.664 144.665 238.627 144.665 237.979 144.276C237.331 143.886 237.331 143.302 237.979 142.913C238.627 142.523 239.664 142.523 240.312 142.913Z" fill="#00283B" />
        <path d="M225.695 142.913C226.343 142.523 227.38 142.523 228.029 142.913C228.677 143.302 228.677 143.886 228.029 144.276C227.38 144.665 226.343 144.665 225.695 144.276C225.047 143.886 225.047 143.302 225.695 142.913Z" fill="#00283B" />
        <path d="M234.187 139.342C234.835 139.732 234.835 140.316 234.187 140.706C233.539 141.095 232.502 141.095 231.853 140.706C231.205 140.316 231.205 139.732 231.853 139.342C232.502 138.953 233.539 138.953 234.187 139.342Z" fill="#00283B" />
        <path d="M252.596 128.696C253.244 129.086 253.244 129.67 252.596 130.059C251.947 130.449 250.91 130.449 250.262 130.059C249.614 129.67 249.614 129.086 250.262 128.696C250.91 128.307 251.947 128.307 252.596 128.696Z" fill="#00283B" />
        <path d="M258.754 125.158C259.402 125.548 259.402 126.132 258.754 126.521C258.106 126.911 257.068 126.911 256.42 126.521C255.772 126.132 255.772 125.548 256.42 125.158C257.068 124.769 258.106 124.769 258.754 125.158Z" fill="#00283B" />
        <path d="M264.879 121.588C265.527 121.977 265.527 122.562 264.879 122.951C264.231 123.341 263.194 123.341 262.546 122.951C261.898 122.562 261.898 121.977 262.546 121.588C263.194 121.198 264.231 121.198 264.879 121.588Z" fill="#00283B" />
        <path d="M271.037 118.05C271.685 118.439 271.685 119.023 271.037 119.413C270.389 119.802 269.352 119.802 268.703 119.413C268.055 119.023 268.055 118.439 268.703 118.05C269.352 117.66 270.389 117.66 271.037 118.05Z" fill="#00283B" />
        <path d="M277.162 115.843C276.514 116.232 275.477 116.232 274.829 115.843C274.181 115.453 274.181 114.869 274.829 114.479C275.477 114.09 276.514 114.09 277.162 114.479C277.811 114.869 277.811 115.453 277.162 115.843Z" fill="#00283B" />
        <path d="M240.312 135.804C240.961 136.194 240.961 136.778 240.312 137.168C239.664 137.557 238.627 137.557 237.979 137.168C237.331 136.778 237.331 136.194 237.979 135.804C238.627 135.415 239.664 135.415 240.312 135.804Z" fill="#00283B" />
        <path d="M246.47 132.266C247.118 132.656 247.118 133.24 246.47 133.63C245.822 134.019 244.785 134.019 244.137 133.63C243.488 133.24 243.488 132.656 244.137 132.266C244.785 131.877 245.822 131.877 246.47 132.266Z" fill="#00283B" />
        <path d="M98.1944 224.513L98.0972 228.44L98.4213 228.603L121.627 214.613L121.756 214.548L122.34 210.134L98.1944 224.513Z" fill="#00283B" />
        <path d="M52.3989 197.994L124.349 156.383L169.269 182.739L98.5184 224.707L52.3989 197.994Z" fill="#B0E2FA" />
        <path d="M98.5185 225.032L98.3565 224.935L52.1074 197.832L124.349 156.026L124.511 156.123L169.594 182.577L169.464 182.999L98.551 225.065L98.5185 225.032ZM53.0149 197.994L98.5185 224.35L168.686 182.739L124.349 156.74L53.0149 198.027V197.994Z" fill="#00283B" />
        <path d="M124.349 156.383L52.3989 197.994L98.5184 224.708L119.001 212.568L150.212 193.58L158.671 189.036L165.153 185.173L169.302 182.707L124.382 156.351L124.349 156.383ZM98.3239 211.627L74.6646 197.637L84.5497 191.892L128.076 166.64L149.694 179.266L98.2915 211.659L98.3239 211.627Z" fill="#B0E2FA" />
        <path d="M124.349 157.876L52.3989 199.487L98.5184 224.675L119.001 212.536L150.212 195.073L160.357 188.127L165.153 186.699L169.302 184.232L124.382 157.876H124.349ZM98.3239 213.12L74.6646 199.13L84.5497 193.385L128.076 168.133L149.694 180.759L98.2915 213.152L98.3239 213.12Z" fill="#F5FCFF" />
        <path d="M98.5185 225.032L98.3565 224.935L52.1074 198.157V197.832L124.349 156.026L124.511 156.123L169.594 182.577V182.934L158.834 189.328L150.375 193.872L119.196 212.86L98.5834 225.097L98.5185 225.032ZM53.0149 197.994L98.5185 224.35L118.84 212.308L150.05 193.32L158.509 188.776L168.686 182.739L124.349 156.74L53.0149 198.027V197.994ZM98.3565 211.984L98.1944 211.886L74.1138 197.637L128.109 166.283L128.271 166.38L150.31 179.266L98.3241 212.016L98.3565 211.984ZM75.313 197.637L98.3565 211.27L149.175 179.234L128.141 166.964L75.313 197.637Z" fill="#00283B" />
        <path d="M160.94 190.886L152.384 185.174L138.188 197.995L128.79 197.118L152.87 182.447L161.556 187.705L160.94 190.886Z" fill="#B0E2FA" />
        <path d="M138.124 198.287L129.114 197.443C128.725 197.41 128.595 196.891 128.952 196.696L152.676 182.22C152.676 182.22 152.968 182.155 153.097 182.22L161.653 187.413C161.653 187.413 161.88 187.673 161.848 187.835L161.297 190.789C161.232 191.081 160.908 191.211 160.681 191.048L152.708 185.725C152.708 185.725 152.352 185.628 152.222 185.758L138.48 198.157C138.48 198.157 138.286 198.254 138.156 198.254L138.124 198.287ZM130.961 197.021L137.929 197.67C137.929 197.67 138.156 197.67 138.253 197.573L152.157 185.011C152.157 185.011 152.514 184.881 152.643 184.979L160.26 190.042C160.519 190.204 160.843 190.042 160.876 189.783L161.167 188.127C161.167 187.965 161.135 187.803 160.973 187.705L153.065 182.934C153.065 182.934 152.773 182.869 152.643 182.934L130.767 196.274C130.443 196.469 130.54 196.988 130.929 197.021H130.961Z" fill="#00283B" />
        <path d="M152.709 193.126L155.561 186.375L149.824 182.869C149.597 182.707 149.338 182.545 149.079 182.415C148.625 182.188 148.139 182.025 147.588 181.928C143.342 181.214 138.383 185.596 137.249 191.568C136.374 196.145 138.416 200.169 139.939 201.857L140.555 201.955C140.555 201.955 140.846 201.955 140.879 201.987L146.551 205.428C146.907 205.655 147.361 205.493 147.555 205.103L150.343 198.611C151.38 197.118 152.222 195.301 152.644 193.321C152.644 193.256 152.676 193.191 152.709 193.126Z" fill="#B0E2FA" />
        <path d="M146.907 205.817C146.745 205.817 146.55 205.785 146.421 205.687L140.781 202.279H140.717C140.619 202.279 140.555 202.279 140.522 202.279L139.679 202.149V202.019C138.318 200.429 136.082 196.339 136.989 191.568C138.124 185.531 143.115 180.922 147.652 181.701C148.203 181.798 148.722 181.96 149.208 182.22C149.467 182.35 149.727 182.512 150.018 182.707L155.917 186.342L152.935 193.483C152.546 195.365 151.736 197.248 150.569 198.871L147.814 205.33C147.717 205.59 147.49 205.785 147.231 205.882C147.134 205.882 147.037 205.914 146.939 205.914L146.907 205.817ZM140.652 201.695C140.652 201.695 140.717 201.695 140.749 201.695C140.879 201.695 140.943 201.695 141.008 201.76L146.68 205.2C146.68 205.2 146.907 205.265 147.004 205.233C147.134 205.2 147.199 205.103 147.263 205.006L150.051 198.514C151.185 196.891 151.963 195.106 152.352 193.256L152.417 192.996L155.171 186.472L149.662 183.096C149.402 182.901 149.176 182.772 148.949 182.642C148.495 182.415 148.009 182.252 147.523 182.187C143.342 181.473 138.578 185.953 137.508 191.6C136.633 196.209 138.772 200.104 140.068 201.597L140.652 201.695Z" fill="#00283B" />
        <path d="M157.764 188.16C156.759 186.634 155.366 185.693 153.745 185.401C149.37 184.654 144.703 189.166 143.568 195.268C142.92 198.741 143.471 201.987 145.124 204.226C146.194 205.687 147.62 206.531 149.24 206.726C149.467 206.726 149.662 206.758 149.888 206.758C153.81 206.758 157.732 202.831 158.963 197.443C159.773 193.97 159.32 190.497 157.764 188.127V188.16Z" fill="#00283B" />
        <path d="M156.305 197.28C155.495 200.916 152.805 203.545 150.31 203.253C147.782 202.961 146.258 199.715 147.004 195.982C147.749 192.152 150.536 189.328 153.162 189.75C155.754 190.172 157.116 193.548 156.273 197.28H156.305Z" fill="#B0E2FA" />
        <path d="M150.699 203.545C150.699 203.545 150.407 203.545 150.277 203.545C149.273 203.415 148.365 202.863 147.717 201.954C146.647 200.494 146.291 198.254 146.745 195.95C147.555 191.957 150.439 189.036 153.227 189.49C154.231 189.653 155.106 190.237 155.754 191.178C156.792 192.704 157.083 195.008 156.565 197.345C155.754 200.948 153.227 203.545 150.699 203.545ZM152.676 189.977C150.374 189.977 147.976 192.606 147.295 196.015C146.874 198.157 147.198 200.234 148.171 201.597C148.754 202.376 149.499 202.863 150.342 202.961C152.708 203.253 155.236 200.656 156.046 197.216C156.532 195.041 156.273 192.899 155.301 191.503C154.75 190.691 154.004 190.172 153.129 190.042C152.967 190.042 152.805 190.01 152.676 190.01V189.977Z" fill="#00283B" />
        <path d="M150.277 200.981C150.277 200.981 150.083 200.981 149.986 200.981C149.337 200.916 148.754 200.559 148.333 199.942C147.652 199.001 147.425 197.572 147.717 196.144C148.235 193.613 150.083 191.762 151.865 192.055C152.514 192.152 153.097 192.541 153.518 193.158C154.166 194.132 154.361 195.592 154.037 197.053C153.518 199.325 151.898 200.981 150.277 200.981ZM151.509 192.574C150.115 192.574 148.689 194.164 148.268 196.242C148.009 197.54 148.203 198.806 148.786 199.617C149.11 200.072 149.564 200.364 150.083 200.429C151.509 200.591 153.032 199.033 153.486 196.923C153.777 195.592 153.615 194.294 153.032 193.45C152.708 192.963 152.254 192.671 151.768 192.574C151.671 192.574 151.574 192.574 151.476 192.574H151.509Z" fill="#00283B" />
        <path d="M153.972 197.313C153.551 198.904 152.352 200.04 151.25 199.845C150.148 199.683 149.532 198.222 149.888 196.599C150.277 194.911 151.541 193.71 152.708 193.938C153.842 194.165 154.393 195.658 153.972 197.281V197.313Z" fill="#B0E2FA" />
        <path d="M153.227 196.924C152.773 198.676 151.444 199.91 150.213 199.715C148.981 199.52 148.3 197.93 148.722 196.145C149.143 194.294 150.537 192.964 151.801 193.223C153.065 193.451 153.648 195.138 153.194 196.924H153.227Z" fill="#F5FCFF" />
        <path d="M151.477 200.137C151.477 200.137 151.315 200.137 151.217 200.137C150.731 200.072 150.31 199.78 150.018 199.325C149.532 198.644 149.402 197.605 149.662 196.534C150.083 194.716 151.509 193.418 152.805 193.678C153.292 193.775 153.713 194.067 154.005 194.522C154.458 195.236 154.556 196.307 154.296 197.378C153.875 199.001 152.676 200.137 151.509 200.137H151.477ZM152.449 194.197C151.509 194.197 150.504 195.268 150.18 196.664C149.986 197.573 150.083 198.449 150.472 199.001C150.699 199.325 150.99 199.52 151.315 199.553C152.254 199.682 153.356 198.644 153.745 197.216C153.972 196.307 153.907 195.398 153.518 194.814C153.324 194.489 153.032 194.262 152.676 194.197C152.611 194.197 152.514 194.197 152.449 194.197Z" fill="#00283B" />
        <path d="M135.207 204.096L138.059 197.345L132.323 193.84C132.096 193.677 131.836 193.515 131.577 193.385C131.123 193.158 130.637 192.996 130.086 192.898C125.841 192.184 120.882 196.566 119.747 202.538C118.872 207.115 120.914 211.14 122.438 212.828L123.053 212.925C123.053 212.925 123.345 212.925 123.377 212.958L129.049 216.398C129.406 216.625 129.859 216.463 130.054 216.073L132.841 209.582C133.878 208.089 134.721 206.271 135.142 204.291C135.142 204.226 135.175 204.161 135.207 204.096Z" fill="#B0E2FA" />
        <path d="M129.405 216.788C129.243 216.788 129.049 216.755 128.919 216.658L123.28 213.25L122.34 212.665C120.979 211.075 118.581 207.277 119.488 202.474C120.622 196.436 125.613 191.827 130.151 192.606C130.702 192.704 131.22 192.866 131.707 193.126C131.966 193.255 132.225 193.418 132.517 193.613L138.415 197.248L135.434 204.389C135.045 206.271 134.235 208.154 133.068 209.777L130.313 216.236C130.216 216.496 129.989 216.69 129.73 216.788C129.632 216.788 129.535 216.82 129.438 216.82L129.405 216.788ZM123.507 212.73L129.179 216.171C129.179 216.171 129.405 216.236 129.503 216.203C129.632 216.171 129.697 216.074 129.762 215.976L132.549 209.485C133.684 207.862 134.461 206.076 134.85 204.226L134.915 203.967L137.67 197.443L132.16 194.067C131.901 193.872 131.674 193.742 131.447 193.613C130.994 193.385 130.507 193.223 130.021 193.158C125.84 192.444 121.076 196.923 120.007 202.571C119.132 207.18 121.433 210.815 122.761 212.308C122.761 212.308 123.442 212.698 123.507 212.73Z" fill="#00283B" />
        <path d="M140.263 199.13C139.258 197.605 137.865 196.664 136.244 196.371C131.869 195.625 127.202 200.137 126.067 206.239C125.419 209.712 125.97 212.958 127.623 215.197C128.693 216.658 130.119 217.502 131.739 217.696C131.966 217.696 132.161 217.729 132.387 217.729C136.309 217.729 140.231 213.801 141.462 208.413C142.272 204.94 141.819 201.467 140.263 199.098V199.13Z" fill="#00283B" />
        <path d="M138.804 208.251C137.994 211.886 135.304 214.515 132.809 214.223C130.281 213.931 128.757 210.685 129.503 206.953C130.248 203.123 133.035 200.299 135.661 200.721C138.253 201.143 139.615 204.518 138.772 208.251H138.804Z" fill="#B0E2FA" />
        <path d="M133.198 214.516C133.198 214.516 132.906 214.516 132.776 214.516C131.772 214.386 130.864 213.834 130.216 212.925C129.146 211.464 128.79 209.225 129.244 206.92C130.054 202.928 132.938 200.007 135.726 200.461C136.73 200.623 137.605 201.208 138.254 202.149C139.291 203.675 139.582 205.979 139.064 208.316C138.254 211.919 135.726 214.516 133.198 214.516ZM135.175 200.948C132.873 200.948 130.475 203.577 129.795 206.985C129.373 209.128 129.697 211.205 130.67 212.568C131.253 213.347 131.998 213.834 132.841 213.931C135.207 214.223 137.735 211.627 138.513 208.186C138.999 206.012 138.74 203.869 137.767 202.474C137.216 201.662 136.471 201.143 135.596 201.013C135.434 201.013 135.272 200.98 135.142 200.98L135.175 200.948Z" fill="#00283B" />
        <path d="M132.776 211.951C132.776 211.951 132.582 211.951 132.485 211.951C131.836 211.886 131.253 211.529 130.832 210.913C130.151 209.971 129.924 208.543 130.216 207.115C130.734 204.583 132.582 202.733 134.364 203.025C135.013 203.123 135.596 203.512 136.017 204.129C136.665 205.103 136.86 206.563 136.536 208.024C136.017 210.296 134.397 211.951 132.776 211.951ZM134.008 203.545C132.614 203.545 131.188 205.135 130.767 207.212C130.508 208.511 130.702 209.777 131.285 210.588C131.609 211.042 132.063 211.335 132.582 211.399C134.008 211.562 135.531 210.004 135.985 207.894C136.277 206.563 136.114 205.265 135.531 204.421C135.207 203.934 134.753 203.642 134.267 203.545C134.17 203.545 134.073 203.545 133.975 203.545H134.008Z" fill="#00283B" />
        <path d="M136.471 208.284C136.049 209.874 134.85 211.01 133.748 210.815C132.646 210.653 132.03 209.192 132.387 207.57C132.776 205.882 134.04 204.681 135.207 204.908C136.341 205.135 136.892 206.628 136.471 208.251V208.284Z" fill="#B0E2FA" />
        <path d="M135.725 207.894C135.271 209.647 133.943 210.88 132.711 210.685C131.479 210.491 130.799 208.9 131.22 207.115C131.642 205.265 133.035 203.934 134.299 204.194C135.563 204.421 136.147 206.109 135.693 207.894H135.725Z" fill="#F5FCFF" />
        <path d="M133.975 211.108C133.975 211.108 133.813 211.108 133.716 211.108C133.229 211.043 132.808 210.75 132.516 210.296C132.063 209.614 131.901 208.576 132.16 207.505C132.581 205.687 134.007 204.389 135.304 204.648C135.79 204.746 136.211 205.038 136.503 205.492C136.957 206.206 137.054 207.277 136.795 208.349C136.373 209.971 135.174 211.108 134.007 211.108H133.975ZM134.947 205.168C134.007 205.168 133.003 206.239 132.678 207.634C132.484 208.543 132.581 209.42 132.97 209.971C133.197 210.296 133.489 210.491 133.813 210.523C134.753 210.653 135.855 209.614 136.244 208.186C136.47 207.277 136.406 206.369 136.017 205.784C135.822 205.46 135.531 205.233 135.174 205.168C135.109 205.168 135.012 205.168 134.947 205.168Z" fill="#00283B" />
        <path d="M84.5824 191.892L90.8376 176.215L80.0126 170.048L73.9844 185.498L84.5824 191.892Z" fill="#B0E2FA" />
        <path d="M118.483 159.434L90.8371 176.215L80.0122 170.048L107.885 153.429L118.483 159.434Z" fill="#B0E2FA" />
        <path d="M118.872 161.966L91.2263 178.747L80.4014 172.58L108.306 155.961L118.872 161.966Z" fill="#F5FCFF" />
        <path d="M119.066 159.434L107.885 153.105L79.7531 169.853L73.5952 185.628L84.7118 192.314L91.0642 176.41L119.066 159.402V159.434ZM80.6282 170.048L107.917 153.786L117.9 159.434L90.8373 175.858L81.2764 170.405L80.6282 170.048ZM84.4526 191.438L74.3731 185.368L80.1744 170.47L80.5958 170.73L90.4484 176.345L84.4202 191.438H84.4526Z" fill="#00283B" />
        <path d="M74.665 197.637L128.077 166.607L127.979 168.977L76.8041 198.903L74.665 197.637Z" fill="#00283B" />
        <path d="M141.364 174.365L128.076 166.607L127.979 168.977L140.749 176.637L141.364 174.365Z" fill="#B0E2FA" />
        <path d="M140.943 177.124L127.688 169.139L127.817 166.121L141.689 174.235L140.911 177.124H140.943ZM128.303 168.815L140.554 176.215L141.008 174.527L128.368 167.159L128.303 168.815Z" fill="#00283B" />
        <path d="M169.27 182.382L160.325 187.673L160.681 191.048L160.94 191.243L169.691 186.277L169.562 182.544L169.27 182.382Z" fill="#00283B" />
        <path d="M52.3994 197.994L52.5615 201.467L98.4216 228.278L98.5189 224.708L52.3994 197.994Z" fill="#B0E2FA" />
        <path d="M98.7132 228.44L98.4215 228.635L52.2697 201.662L52.0752 197.865L52.3669 197.67L98.778 224.545L98.6808 228.473L98.7132 228.44ZM52.853 201.273L98.1298 227.726L98.1946 224.837L52.7234 198.514L52.853 201.273Z" fill="#00283B" />
        <path d="M79.5591 213.704L90.6757 207.115L93.8195 208.965L112.779 197.216L116.02 190.205L79.5591 211.627V213.704Z" fill="#00283B" />
        <path d="M143.147 174.3L150.602 169.918V171.801L144.833 175.241L143.147 174.3Z" fill="#00283B" />
        <path d="M79.559 213.704L68.5396 207.342V205.233L79.559 211.627V213.704Z" fill="#B0E2FA" />
        <path d="M68.5391 205.233L139.971 163.783L150.601 169.918L79.5585 211.627L68.5391 205.233Z" fill="#B0E2FA" />
        <path d="M68.5391 206.271L139.971 164.822L149.791 170.405L79.5585 211.627V212.666L68.5391 206.271Z" fill="#F5FCFF" />
        <path d="M150.925 169.723L139.971 163.427L68.2476 205.038V207.505L79.5586 214.061L90.7077 207.472L93.8515 209.322L112.973 197.475H113.038L116.279 190.432L143.179 174.625L144.832 175.566L150.925 171.963V169.723ZM79.267 213.185L68.8634 207.148V205.72L79.267 211.789V213.185ZM112.552 196.988L93.819 208.608L90.7077 206.758L79.8503 213.185V211.789L115.339 190.951L112.52 196.988H112.552ZM116.733 189.458L80.6282 210.653L79.5586 211.27L69.155 205.2L139.971 164.108L150.018 169.886L142.564 174.268L116.701 189.458H116.733ZM150.342 171.606L144.832 174.884L143.763 174.268L149.921 170.665L150.342 170.437V171.606Z" fill="#00283B" />
        <path d="M152.87 179.818L125.224 196.566L114.399 190.399L142.304 173.781L152.87 179.818Z" fill="#B0E2FA" />
        <path d="M118.97 212.568L125.225 196.566L114.4 190.399L108.177 206.141L118.97 212.568Z" fill="#B0E2FA" />
        <path d="M152.871 182.35L125.225 199.098L114.4 192.931L142.305 176.312L152.871 182.35Z" fill="#F5FCFF" />
        <path d="M153.454 179.818L142.272 173.456L114.14 190.205L107.788 206.239L119.099 212.99L125.452 196.761L153.454 179.785V179.818ZM142.305 174.17L152.287 179.85L125.225 196.242L115.016 190.432L142.305 174.17ZM118.84 212.146L108.566 206.044L114.562 190.886L124.868 196.761L118.84 212.179V212.146Z" fill="#00283B" />
        <path d="M143.342 171.638L82.3136 206.953C80.8876 207.764 78.4244 207.635 76.8039 206.693C75.1834 205.752 75.0214 204.291 76.415 203.48L137.443 168.165C138.869 167.354 141.332 167.484 142.953 168.425C144.573 169.366 144.768 170.827 143.342 171.638Z" fill="#B0E2FA" />
        <path d="M79.9476 207.797C78.7808 207.797 77.5816 207.505 76.6417 206.953C75.7019 206.401 75.1509 205.622 75.1509 204.876C75.1509 204.226 75.5398 203.642 76.2528 203.22L137.281 167.906C138.772 167.062 141.397 167.159 143.115 168.165C144.055 168.717 144.606 169.496 144.606 170.243C144.606 170.892 144.217 171.476 143.504 171.898L82.4755 207.213C81.7949 207.602 80.8875 207.797 79.98 207.797H79.9476ZM139.776 167.938C138.934 167.938 138.156 168.1 137.573 168.425L76.5445 203.74C76.0259 204.032 75.7343 204.421 75.7343 204.876C75.7343 205.427 76.1556 205.979 76.9334 206.434C78.4891 207.342 80.8226 207.44 82.1514 206.693L143.179 171.379C143.698 171.087 143.99 170.697 143.99 170.243C143.99 169.691 143.568 169.139 142.791 168.685C141.915 168.165 140.814 167.906 139.776 167.906V167.938Z" fill="#00283B" />
        <path d="M76.1233 206.142L137.151 170.827C138.577 170.016 141.041 170.145 142.661 171.087C142.953 171.249 143.147 171.444 143.342 171.606C144.735 170.795 144.573 169.366 142.953 168.425C141.332 167.484 138.837 167.354 137.443 168.165L76.415 203.48C75.2482 204.162 75.1834 205.233 76.0909 206.142H76.1233Z" fill="#00283B" />
        <path d="M76.1235 206.498L75.8967 206.369C75.3457 205.849 75.0864 205.233 75.1836 204.681C75.2484 204.291 75.4753 203.707 76.2856 203.253L137.314 167.938C138.804 167.094 141.43 167.192 143.147 168.198C144.087 168.75 144.638 169.496 144.638 170.275C144.638 170.924 144.249 171.508 143.536 171.898L143.342 171.995L143.18 171.833C142.985 171.638 142.791 171.476 142.532 171.346C140.976 170.437 138.642 170.308 137.314 171.087L76.2532 206.401L76.1235 206.466V206.498ZM139.809 167.938C138.967 167.938 138.189 168.1 137.605 168.425L76.5773 203.74C76.0911 203.999 75.8318 204.356 75.767 204.746C75.7346 205.07 75.8642 205.427 76.1559 205.784L136.99 170.567C138.48 169.723 141.106 169.821 142.823 170.827C143.018 170.957 143.212 171.087 143.407 171.249C143.796 170.957 144.022 170.632 144.022 170.243C144.022 169.691 143.601 169.139 142.823 168.685C141.948 168.165 140.846 167.906 139.809 167.906V167.938Z" fill="#00283B" />
        <path d="M110.511 213.931L102.635 218.703C101.209 219.514 98.7457 219.384 97.1252 218.443C95.5047 217.502 95.3427 216.041 96.7363 215.23L104.612 210.458C106.038 209.647 108.501 209.777 110.122 210.718C111.742 211.659 111.904 213.12 110.511 213.931Z" fill="#B0E2FA" />
        <path d="M100.269 219.514C99.1021 219.514 97.9029 219.222 96.963 218.67C96.0231 218.118 95.4722 217.339 95.4722 216.593C95.4722 215.944 95.8611 215.36 96.5741 214.938L104.417 210.166C105.941 209.29 108.533 209.42 110.251 210.426C111.191 210.978 111.742 211.757 111.742 212.503C111.742 213.152 111.353 213.737 110.64 214.159L102.797 218.93C102.116 219.319 101.209 219.514 100.269 219.514ZM106.945 210.199C106.103 210.199 105.325 210.361 104.741 210.686L96.8982 215.457C96.3472 215.749 96.088 216.139 96.088 216.593C96.088 217.145 96.5093 217.696 97.2871 218.151C98.8428 219.06 101.176 219.19 102.505 218.411L110.381 213.639C110.899 213.347 111.191 212.958 111.191 212.503C111.191 211.951 110.77 211.4 109.992 210.945C109.117 210.426 108.015 210.166 106.978 210.166L106.945 210.199Z" fill="#00283B" />
        <path d="M96.4441 217.891L104.32 213.12C105.746 212.309 108.209 212.438 109.829 213.38C110.121 213.542 110.316 213.737 110.51 213.899C111.904 213.088 111.742 211.659 110.121 210.718C108.501 209.777 106.005 209.647 104.611 210.458L96.7358 215.23C95.5691 215.911 95.4718 216.982 96.4117 217.891H96.4441Z" fill="#00283B" />
        <path d="M96.4443 218.248L96.2174 218.118C95.6665 217.599 95.4072 216.982 95.5044 216.398C95.5693 216.009 95.7961 215.424 96.6064 214.97L104.45 210.199C105.94 209.322 108.566 209.452 110.283 210.458C111.223 211.01 111.774 211.757 111.774 212.536C111.774 213.185 111.385 213.769 110.672 214.159L110.478 214.288L110.316 214.126C110.121 213.931 109.894 213.769 109.668 213.639C108.112 212.73 105.778 212.601 104.45 213.38L96.5415 218.183L96.4119 218.248H96.4443ZM106.978 210.231C106.135 210.231 105.357 210.393 104.774 210.718L96.9305 215.489C96.4443 215.749 96.185 216.106 96.1202 216.496C96.0878 216.82 96.2174 217.177 96.5091 217.534L104.19 212.86C105.714 212.016 108.306 212.114 110.024 213.12C110.219 213.25 110.413 213.38 110.607 213.542C110.996 213.25 111.223 212.925 111.223 212.536C111.223 211.984 110.802 211.432 110.024 210.978C109.149 210.458 108.047 210.199 107.01 210.199L106.978 210.231Z" fill="#00283B" />
        <path d="M76.1233 194.359L68.2477 199.131C66.8217 199.942 64.3585 199.812 62.738 198.871C61.1175 197.93 60.9555 196.469 62.3491 195.657L70.2247 190.886C71.6508 190.075 74.1139 190.204 75.7344 191.146C77.3549 192.087 77.517 193.548 76.1233 194.359Z" fill="#B0E2FA" />
        <path d="M65.8816 199.942C64.7149 199.942 63.5157 199.65 62.5758 199.098C61.6359 198.546 61.085 197.767 61.085 197.021C61.085 196.372 61.4739 195.787 62.1869 195.365L70.0301 190.594C71.5534 189.75 74.1462 189.848 75.8639 190.854C76.8038 191.406 77.3548 192.185 77.3548 192.931C77.3548 193.58 76.9659 194.165 76.2528 194.586L68.4096 199.358C67.729 199.747 66.8215 199.942 65.8816 199.942ZM72.5581 190.627C71.7154 190.627 70.9376 190.789 70.3542 191.113L62.511 195.885C61.96 196.177 61.7007 196.566 61.7007 197.021C61.7007 197.573 62.1221 198.124 62.8999 198.579C64.4556 199.488 66.7891 199.617 68.1179 198.838L75.9936 194.067C76.5121 193.775 76.8038 193.386 76.8038 192.931C76.8038 192.379 76.3825 191.828 75.6046 191.373C74.7296 190.854 73.6276 190.594 72.5905 190.594L72.5581 190.627Z" fill="#00283B" />
        <path d="M62.0574 198.319L69.9331 193.548C71.3591 192.736 73.8222 192.866 75.4427 193.807C75.7344 193.97 75.9289 194.164 76.1234 194.327C77.517 193.515 77.3549 192.087 75.7344 191.146C74.1139 190.204 71.6184 190.075 70.2247 190.886L62.3491 195.657C61.1823 196.339 61.0851 197.41 62.025 198.319H62.0574Z" fill="#00283B" />
        <path d="M61.9928 198.708L61.8307 198.546C61.2798 198.027 61.0205 197.41 61.1177 196.858C61.1825 196.469 61.4094 195.885 62.2197 195.43L70.0629 190.659C71.5861 189.815 74.1789 189.912 75.8967 190.918C76.8366 191.47 77.3875 192.217 77.3875 192.996C77.3875 193.645 76.9986 194.229 76.2856 194.619L76.0911 194.716L75.9291 194.554C75.7346 194.359 75.5078 194.197 75.2809 194.067C73.7252 193.158 71.3917 193.028 70.0629 193.807L61.9928 198.708ZM72.5909 190.659C71.7482 190.659 70.9704 190.821 70.387 191.146L62.5438 195.917C62.0576 196.177 61.7983 196.534 61.7335 196.923C61.7011 197.248 61.8307 197.605 62.1224 197.962L69.8036 193.288C71.2945 192.412 73.9197 192.541 75.6374 193.548C75.8319 193.677 76.0263 193.807 76.2208 193.97C76.6097 193.677 76.8366 193.353 76.8366 192.963C76.8366 192.412 76.4152 191.86 75.6374 191.405C74.7623 190.886 73.6604 190.626 72.6233 190.626L72.5909 190.659Z" fill="#00283B" />
        <path d="M162.107 183.388L160.487 184.265C159.061 185.076 156.598 184.946 154.977 184.005C153.357 183.064 153.195 181.603 154.588 180.792L156.209 179.915C157.635 179.104 160.098 179.234 161.719 180.175C163.339 181.116 163.501 182.577 162.107 183.388Z" fill="#B0E2FA" />
        <path d="M158.121 185.076C156.954 185.076 155.755 184.784 154.815 184.232C153.875 183.68 153.324 182.901 153.324 182.155C153.324 181.506 153.713 180.921 154.426 180.5L156.047 179.623C157.538 178.779 160.163 178.877 161.88 179.883C162.82 180.435 163.371 181.214 163.371 181.96C163.371 182.609 162.982 183.194 162.269 183.616L160.649 184.492C159.968 184.881 159.093 185.076 158.153 185.076H158.121ZM158.542 179.688C157.7 179.688 156.922 179.85 156.338 180.175L154.718 181.051C154.199 181.343 153.908 181.733 153.908 182.155C153.908 182.707 154.329 183.258 155.107 183.713C156.662 184.622 158.996 184.752 160.325 183.973L161.945 183.096C162.464 182.804 162.756 182.415 162.756 181.96C162.756 181.408 162.334 180.857 161.556 180.402C160.681 179.883 159.579 179.623 158.542 179.623V179.688Z" fill="#00283B" />
        <path d="M154.296 183.453L155.917 182.577C157.343 181.766 159.806 181.895 161.426 182.837C161.718 182.999 161.913 183.194 162.107 183.356C163.501 182.545 163.339 181.116 161.718 180.175C160.098 179.234 157.602 179.104 156.208 179.915L154.588 180.792C153.421 181.473 153.356 182.545 154.264 183.453H154.296Z" fill="#00283B" />
        <path d="M154.264 183.81L154.07 183.68C153.519 183.161 153.259 182.544 153.356 181.993C153.421 181.603 153.648 181.019 154.458 180.564L156.079 179.688C157.57 178.844 160.195 178.942 161.913 179.948C162.853 180.5 163.404 181.246 163.404 182.025C163.404 182.674 163.015 183.259 162.302 183.648L162.107 183.745L161.945 183.583C161.751 183.388 161.524 183.226 161.297 183.096C159.741 182.187 157.408 182.058 156.079 182.837L154.458 183.713L154.296 183.81H154.264ZM158.542 179.688C157.699 179.688 156.922 179.85 156.338 180.175L154.718 181.051C154.264 181.311 153.972 181.668 153.94 182.058C153.907 182.382 154.037 182.739 154.329 183.096L155.755 182.317C157.246 181.473 159.871 181.571 161.589 182.577C161.783 182.707 161.978 182.837 162.172 182.999C162.561 182.707 162.788 182.382 162.788 181.993C162.788 181.441 162.366 180.889 161.589 180.435C160.714 179.915 159.612 179.656 158.574 179.656L158.542 179.688Z" fill="#00283B" />
        <path d="M129.276 163.816L127.655 164.692C126.229 165.504 123.766 165.374 122.146 164.433C120.525 163.491 120.363 162.031 121.757 161.219L123.377 160.343C124.803 159.531 127.266 159.661 128.887 160.603C130.507 161.544 130.67 163.004 129.276 163.816Z" fill="#B0E2FA" />
        <path d="M125.289 165.504C124.123 165.504 122.923 165.212 121.984 164.66C121.044 164.108 120.493 163.329 120.493 162.583C120.493 161.933 120.882 161.349 121.595 160.927L123.215 160.051C124.706 159.207 127.331 159.304 129.049 160.311C129.989 160.862 130.54 161.641 130.54 162.388C130.54 163.037 130.151 163.621 129.438 164.043L127.817 164.92C127.137 165.309 126.262 165.504 125.322 165.504H125.289ZM125.711 160.116C124.868 160.116 124.09 160.278 123.507 160.603L121.886 161.479C121.368 161.771 121.076 162.161 121.076 162.583C121.076 163.134 121.497 163.686 122.275 164.141C123.831 165.049 126.164 165.179 127.493 164.4L129.114 163.524C129.632 163.232 129.924 162.842 129.924 162.388C129.924 161.836 129.503 161.284 128.725 160.83C127.85 160.311 126.748 160.051 125.711 160.051V160.116Z" fill="#00283B" />
        <path d="M121.465 163.881L123.085 163.005C124.511 162.193 126.974 162.323 128.595 163.264C128.887 163.427 129.081 163.621 129.275 163.784C130.669 162.972 130.507 161.544 128.887 160.603C127.266 159.662 124.77 159.532 123.377 160.343L121.756 161.22C120.59 161.901 120.492 162.972 121.432 163.881H121.465Z" fill="#00283B" />
        <path d="M121.4 164.238L121.238 164.076C120.687 163.556 120.428 162.94 120.525 162.388C120.59 161.998 120.817 161.414 121.627 160.96L123.247 160.083C124.738 159.239 127.363 159.337 129.081 160.343C130.021 160.895 130.572 161.641 130.572 162.42C130.572 163.07 130.183 163.654 129.47 164.043L129.276 164.141L129.114 163.978C128.919 163.784 128.692 163.621 128.465 163.491C126.91 162.583 124.576 162.453 123.247 163.232L121.432 164.206L121.4 164.238ZM125.743 160.116C124.9 160.116 124.122 160.278 123.539 160.603L121.919 161.479C121.465 161.739 121.173 162.096 121.141 162.485C121.108 162.81 121.238 163.167 121.53 163.524L122.956 162.745C124.447 161.901 127.072 161.998 128.789 163.005C128.984 163.134 129.178 163.264 129.373 163.427C129.762 163.134 129.989 162.81 129.989 162.42C129.989 161.869 129.567 161.317 128.789 160.862C127.914 160.343 126.812 160.083 125.775 160.083L125.743 160.116Z" fill="#00283B" />
        <path d="M84.5825 191.892L90.8376 176.215L118.483 159.434L128.077 166.607L84.5825 191.892Z" fill="#B0E2FA" />
        <path d="M128.401 166.478L118.483 159.077L90.6755 175.955L90.5782 176.02L84.3231 191.698L74.0815 197.638L76.804 199.26L128.141 169.237L128.271 169.139L128.368 166.478H128.401ZM85.1982 191.178L91.0968 176.41L118.483 159.791L127.59 166.575L127.007 166.932L85.2306 191.178H85.1982ZM127.72 168.782L76.8364 198.546L75.2807 197.638L127.785 167.159L127.72 168.782Z" fill="#00283B" />
        <path d="M152.87 179.461L124.998 196.372L118.581 212.731L121.66 213.802L126.975 199.001L152.838 183.031L161.686 189.62L162.658 186.796L152.87 179.461Z" fill="#00283B" />
        <path d="M104.515 195.982L104.255 192.931L100.301 192.996C96.5418 191.438 91.4535 191.989 87.9856 193.645H84.4205V197.118V196.761C84.3881 198.481 85.7169 200.007 88.2773 201.175C92.6202 203.123 99.0374 202.668 102.376 200.266C104.061 199.065 104.515 197.442 104.515 196.014V195.982Z" fill="#B0E2FA" />
        <path d="M94.4998 202.668C92.2635 202.668 90.0272 202.246 88.1474 201.402C85.6519 200.266 84.2582 198.773 84.1286 197.086H84.0962L84.1286 193.32H87.9206C91.0968 191.827 96.2824 191.016 100.366 192.671L104.547 192.606L104.806 195.982C104.806 197.897 104.061 199.39 102.537 200.494C100.528 201.954 97.5139 202.701 94.4998 202.701V202.668ZM84.6796 196.728C84.6796 198.319 85.9112 199.747 88.3743 200.851C92.5552 202.733 98.8752 202.344 102.181 199.974C103.542 199.001 104.19 197.702 104.19 195.982L103.964 193.223L100.204 193.288H100.139C96.2175 191.633 91.1292 192.444 88.0826 193.905H87.953H84.6796V196.728Z" fill="#00283B" />
        <path d="M100.398 188.419C104.741 190.367 105.551 193.937 102.213 196.339C98.875 198.741 92.6199 199.13 88.2769 197.183C83.934 195.235 83.1237 191.665 86.462 189.263C89.8002 186.861 96.0553 186.472 100.398 188.419Z" fill="#B0E2FA" />
        <path d="M100.333 190.107C104.547 192.022 105.357 195.463 102.084 197.8C98.8426 200.137 92.7495 200.526 88.5362 198.611C84.3229 196.696 83.5127 193.255 86.7861 190.918C90.0271 188.581 96.1202 188.192 100.333 190.107Z" fill="#F5FCFF" />
        <path d="M94.5645 198.741C92.3282 198.741 90.0595 198.319 88.1473 197.475C85.749 196.404 84.2905 194.781 84.1285 193.028C83.9664 191.568 84.7443 190.139 86.3 189.036C89.7354 186.569 96.1202 186.18 100.528 188.16C102.926 189.231 104.385 190.854 104.547 192.606C104.709 194.067 103.931 195.495 102.375 196.599C100.431 197.994 97.5138 198.741 94.5645 198.741ZM94.1108 187.478C91.2911 187.478 88.5038 188.16 86.6565 189.523C85.2952 190.497 84.6146 191.73 84.7443 192.963C84.9063 194.489 86.2351 195.917 88.439 196.923C92.6847 198.838 98.8102 198.481 102.084 196.112C103.445 195.138 104.125 193.905 103.996 192.671C103.834 191.146 102.505 189.718 100.301 188.711C98.4537 187.867 96.2822 187.478 94.1108 187.478Z" fill="#00283B" />
        <path d="M98.5513 194.521L96.7039 195.495C95.0834 196.436 92.2637 196.274 90.3839 195.203C88.5042 194.099 88.3421 192.477 89.9626 191.535L91.81 190.562C93.4305 189.62 96.2502 189.783 98.1299 190.854C100.01 191.957 100.172 193.58 98.5513 194.521Z" fill="#B0E2FA" />
        <path d="M94.0137 196.437C92.6849 196.437 91.3237 196.112 90.2542 195.495C89.1846 194.879 88.5688 194.002 88.5688 193.158C88.5688 192.444 89.0226 191.763 89.8328 191.308L91.6802 190.302C93.3655 189.328 96.3472 189.458 98.2918 190.594C99.3614 191.211 99.9772 192.087 99.9772 192.931C99.9772 193.645 99.5234 194.327 98.7132 194.781L96.8658 195.787C96.1204 196.209 95.0833 196.437 94.0137 196.437ZM94.4999 190.269C93.5276 190.269 92.6201 190.464 91.9395 190.854L90.0921 191.86C89.4763 192.217 89.1522 192.671 89.1522 193.191C89.1522 193.84 89.6384 194.489 90.5459 195.008C92.3284 196.047 95.0184 196.177 96.5741 195.301L98.4215 194.294C99.0373 193.937 99.3614 193.483 99.3614 192.964C99.3614 192.314 98.8752 191.665 97.9677 191.146C96.963 190.562 95.699 190.269 94.4999 190.269Z" fill="#00283B" />
        <path d="M89.6056 194.586L91.4529 193.613C93.0734 192.671 95.8931 192.834 97.7729 193.905C98.097 194.099 98.3563 194.294 98.5507 194.521C100.139 193.58 99.9443 191.957 98.097 190.886C96.2172 189.783 93.3975 189.653 91.777 190.594L89.9297 191.568C88.5684 192.347 88.5036 193.58 89.5731 194.619L89.6056 194.586Z" fill="#00283B" />
        <path d="M89.6058 194.943L89.3789 194.814C88.7631 194.229 88.4714 193.548 88.5687 192.899C88.6659 192.249 89.0872 191.698 89.8002 191.276L91.6476 190.269C93.3329 189.296 96.3146 189.426 98.2592 190.562C99.3288 191.178 99.9446 192.055 99.9446 192.899C99.9446 193.613 99.4908 194.262 98.713 194.749L98.5185 194.846L98.3565 194.684C98.1296 194.457 97.8703 194.262 97.611 194.132C95.8285 193.093 93.1385 192.964 91.5828 193.84L89.7354 194.846L89.5734 194.943H89.6058ZM94.4997 190.237C93.5274 190.237 92.6199 190.432 91.9393 190.821L90.0919 191.827C89.541 192.152 89.2169 192.542 89.152 192.996C89.0872 193.385 89.2817 193.84 89.6382 194.229L91.2911 193.353C92.9764 192.379 95.9581 192.509 97.9027 193.645C98.1296 193.775 98.3565 193.937 98.5833 194.132C99.0695 193.807 99.3288 193.385 99.3288 192.931C99.3288 192.314 98.8102 191.633 97.9351 191.113C96.9304 190.529 95.6664 190.237 94.4673 190.237H94.4997Z" fill="#00283B" />
        <path d="M106.524 223.214C106.362 223.085 106.265 222.825 106.265 222.5C106.265 221.819 106.686 221.007 107.205 220.715L109.635 219.319C109.862 219.19 110.089 219.19 110.251 219.287C110.251 219.287 110.283 219.287 110.316 219.319L111.223 220.001L109.959 221.689L107.367 223.864L106.524 223.247V223.214Z" fill="#B0E2FA" />
        <path d="M107.237 224.091L110.154 221.884L111.45 219.774L110.219 218.897C109.992 218.833 109.7 218.897 109.473 219.06L107.042 220.455C106.427 220.812 105.94 221.721 105.94 222.5C105.94 222.922 106.07 223.279 106.329 223.474L107.237 224.123V224.091ZM110.802 220.033L109.732 221.462L107.366 223.442L106.686 222.955C106.686 222.955 106.556 222.695 106.556 222.5C106.556 221.916 106.913 221.202 107.334 220.975L109.765 219.579C109.765 219.579 109.992 219.482 110.089 219.547H110.121L110.802 220.066V220.033Z" fill="#00283B" />
        <path d="M108.08 223.864L110.51 222.468C111.029 222.176 111.45 221.364 111.45 220.683C111.45 220.001 111.029 219.676 110.51 219.969L108.08 221.364C107.561 221.656 107.14 222.468 107.14 223.149C107.14 223.831 107.561 224.156 108.08 223.864Z" fill="#F5FCFF" />
        <path d="M107.723 224.285C107.885 224.285 108.08 224.221 108.242 224.123L110.673 222.728C111.288 222.37 111.774 221.462 111.774 220.683C111.774 220.196 111.612 219.839 111.288 219.676C111.029 219.514 110.705 219.547 110.381 219.741L107.95 221.137C107.334 221.494 106.848 222.403 106.848 223.182C106.848 223.669 107.01 224.026 107.334 224.188C107.464 224.253 107.594 224.285 107.723 224.285ZM110.899 220.163C110.899 220.163 110.964 220.163 110.997 220.163C111.094 220.196 111.159 220.358 111.159 220.65C111.159 221.234 110.802 221.948 110.381 222.176L107.95 223.571C107.95 223.571 107.723 223.669 107.626 223.636C107.529 223.604 107.464 223.442 107.464 223.149C107.464 222.565 107.82 221.851 108.242 221.624L110.673 220.228C110.673 220.228 110.802 220.163 110.899 220.163Z" fill="#00283B" />
        <path d="M114.303 218.573C114.141 218.443 114.043 218.184 114.043 217.859C114.043 217.177 114.465 216.366 114.983 216.074L117.414 214.678C117.641 214.548 117.868 214.548 118.03 214.646C118.03 214.646 118.062 214.646 118.095 214.678L119.002 215.36L117.738 217.048L115.145 219.222L114.303 218.605V218.573Z" fill="#B0E2FA" />
        <path d="M115.016 219.449L117.933 217.242L119.229 215.132L117.997 214.256C117.77 214.191 117.479 214.256 117.252 214.418L114.821 215.814C114.205 216.171 113.719 217.08 113.719 217.859C113.719 218.281 113.849 218.638 114.108 218.833L115.016 219.482V219.449ZM118.581 215.392L117.511 216.82L115.145 218.8L114.465 218.313C114.465 218.313 114.335 218.054 114.335 217.859C114.335 217.275 114.692 216.561 115.113 216.333L117.544 214.938C117.544 214.938 117.77 214.84 117.868 214.905H117.9L118.581 215.425V215.392Z" fill="#00283B" />
        <path d="M115.891 219.222L118.322 217.827C118.84 217.534 119.262 216.723 119.262 216.041C119.262 215.36 118.84 215.035 118.322 215.327L115.891 216.723C115.373 217.015 114.951 217.827 114.951 218.508C114.951 219.19 115.373 219.514 115.891 219.222Z" fill="#F5FCFF" />
        <path d="M115.502 219.644C115.664 219.644 115.859 219.579 116.021 219.482L118.451 218.086C119.067 217.729 119.553 216.82 119.553 216.041C119.553 215.554 119.391 215.197 119.067 215.035C118.808 214.873 118.484 214.905 118.16 215.1L115.729 216.496C115.113 216.853 114.627 217.762 114.627 218.541C114.627 219.027 114.789 219.384 115.113 219.547C115.243 219.612 115.372 219.644 115.502 219.644ZM118.678 215.522C118.678 215.522 118.743 215.522 118.775 215.522C118.873 215.554 118.937 215.717 118.937 216.009C118.937 216.593 118.581 217.307 118.16 217.534L115.729 218.93C115.729 218.93 115.502 219.027 115.405 218.995C115.308 218.963 115.243 218.8 115.243 218.508C115.243 217.924 115.599 217.21 116.021 216.983L118.451 215.587C118.451 215.587 118.581 215.522 118.678 215.522Z" fill="#00283B" />
        <path d="M163.566 189.426C163.404 189.296 163.307 189.036 163.307 188.711C163.307 188.03 163.728 187.218 164.247 186.926L166.677 185.531C166.904 185.401 167.131 185.401 167.293 185.498C167.293 185.498 167.325 185.498 167.358 185.531L168.265 186.212L167.001 187.9L164.409 190.075L163.566 189.458V189.426Z" fill="#B0E2FA" />
        <path d="M164.279 190.302L167.196 188.095L168.492 185.985L167.261 185.109C167.034 185.044 166.742 185.109 166.515 185.271L164.084 186.667C163.469 187.024 162.982 187.932 162.982 188.711C162.982 189.133 163.112 189.49 163.371 189.685L164.279 190.334V190.302ZM167.844 186.245L166.774 187.673L164.408 189.653L163.728 189.166C163.728 189.166 163.598 188.906 163.598 188.711C163.598 188.127 163.955 187.413 164.376 187.186L166.807 185.79C166.807 185.79 167.034 185.693 167.131 185.758H167.163L167.844 186.277V186.245Z" fill="#00283B" />
        <path d="M165.154 190.107L167.585 188.712C168.104 188.419 168.525 187.608 168.525 186.926C168.525 186.245 168.104 185.92 167.585 186.212L165.154 187.608C164.636 187.9 164.214 188.712 164.214 189.393C164.214 190.075 164.636 190.399 165.154 190.107Z" fill="#F5FCFF" />
        <path d="M164.765 190.497C164.927 190.497 165.122 190.432 165.284 190.334L167.715 188.939C168.33 188.582 168.816 187.673 168.816 186.894C168.816 186.407 168.654 186.05 168.33 185.888C168.071 185.725 167.747 185.758 167.423 185.953L164.992 187.348C164.376 187.705 163.89 188.614 163.89 189.393C163.89 189.88 164.052 190.237 164.376 190.399C164.506 190.464 164.636 190.497 164.765 190.497ZM167.941 186.374C167.941 186.374 168.006 186.374 168.039 186.374C168.136 186.407 168.201 186.569 168.201 186.861C168.201 187.446 167.844 188.16 167.423 188.387L164.992 189.783C164.992 189.783 164.765 189.88 164.668 189.848C164.571 189.815 164.506 189.653 164.506 189.361C164.506 188.776 164.862 188.062 165.284 187.835L167.715 186.439C167.715 186.439 167.844 186.374 167.941 186.374Z" fill="#00283B" />
        <path d="M214.546 157.844L214.449 161.771L214.773 161.933L237.979 147.944L238.109 147.879L238.692 143.465L214.546 157.844Z" fill="#00283B" />
        <path d="M168.751 131.325L240.701 89.7136L285.621 116.07L214.87 158.006L168.751 131.325Z" fill="#B0E2FA" />
        <path d="M214.871 158.363L214.709 158.266L168.459 131.13L240.701 89.3242L240.863 89.4216L285.946 115.875L285.816 116.297L214.903 158.363H214.871ZM169.367 131.325L214.871 157.681L285.038 116.07L240.701 90.0708L169.367 131.358V131.325Z" fill="#00283B" />
        <path d="M240.701 89.7136L168.751 131.325L214.87 158.038L235.354 145.899L266.564 126.911L275.023 122.367L281.505 118.504L285.654 116.037L240.734 89.6812L240.701 89.7136ZM214.676 144.958L191.017 130.968L200.902 125.223L244.428 99.9704L266.046 112.597L214.644 144.99L214.676 144.958Z" fill="#B0E2FA" />
        <path d="M240.701 91.2068L168.751 132.818L214.87 158.006L235.354 145.866L266.564 128.404L276.709 121.458L281.505 120.03L285.654 117.563L240.734 91.2068H240.701ZM214.676 146.451L191.017 132.461L200.902 126.716L244.428 101.464L266.046 114.09L214.644 146.483L214.676 146.451Z" fill="#F5FCFF" />
        <path d="M214.871 158.363L214.709 158.266L168.459 131.487V131.163L240.701 89.3567L240.863 89.4541L285.946 115.908V116.265L275.186 122.659L266.727 127.203L235.548 146.191L214.935 158.428L214.871 158.363ZM169.367 131.325L214.871 157.681L235.192 145.639L266.403 126.651L274.862 122.107L285.038 116.07L240.701 90.0708L169.367 131.358V131.325ZM214.709 145.315L214.547 145.217L190.466 130.968L244.461 99.6135L244.623 99.7109L266.662 112.597L214.676 145.347L214.709 145.315ZM191.665 130.968L214.709 144.601L265.527 112.564L244.493 100.295L191.665 130.968Z" fill="#00283B" />
        <path d="M277.292 124.217L268.736 118.504L254.541 131.325L245.142 130.449L269.222 115.778L277.876 121.036L277.292 124.217Z" fill="#B0E2FA" />
        <path d="M254.476 131.617L245.466 130.773C245.077 130.741 244.947 130.222 245.304 130.027L269.028 115.551C269.028 115.551 269.32 115.486 269.449 115.551L278.005 120.744C278.005 120.744 278.232 121.004 278.2 121.166L277.649 124.12C277.584 124.412 277.26 124.542 277.033 124.379L269.06 119.056C269.06 119.056 268.704 118.959 268.574 119.089L254.832 131.488C254.832 131.488 254.638 131.585 254.508 131.585L254.476 131.617ZM247.313 130.352L254.281 131.001C254.281 131.001 254.508 131.001 254.605 130.903L268.509 118.342C268.509 118.342 268.866 118.212 268.995 118.31L276.612 123.373C276.871 123.535 277.195 123.373 277.228 123.113L277.519 121.458C277.519 121.296 277.487 121.133 277.325 121.036L269.417 116.265C269.417 116.265 269.125 116.2 268.995 116.265L247.119 129.605C246.795 129.8 246.892 130.319 247.281 130.352H247.313Z" fill="#00283B" />
        <path d="M269.061 126.457L271.913 119.705L266.176 116.2C265.949 116.037 265.69 115.875 265.431 115.745C264.977 115.518 264.491 115.356 263.94 115.258C259.694 114.544 254.735 118.926 253.601 124.899C252.726 129.475 254.768 133.5 256.291 135.188L256.907 135.285C256.907 135.285 257.198 135.285 257.231 135.318L262.903 138.758C263.259 138.985 263.713 138.823 263.907 138.434L266.695 131.942C267.732 130.449 268.574 128.631 268.996 126.651C268.996 126.586 269.028 126.521 269.061 126.457Z" fill="#B0E2FA" />
        <path d="M263.259 139.148C263.097 139.148 262.902 139.115 262.773 139.018L257.133 135.61H257.069C256.971 135.61 256.907 135.61 256.874 135.61L256.032 135.48V135.35C254.67 133.76 252.434 129.67 253.341 124.899C254.476 118.861 259.467 114.252 264.004 115.031C264.555 115.129 265.074 115.291 265.56 115.551C265.819 115.681 266.079 115.843 266.37 116.038L272.269 119.673L269.287 126.814C268.898 128.696 268.088 130.579 266.921 132.202L264.166 138.661C264.069 138.921 263.842 139.115 263.583 139.213C263.486 139.213 263.389 139.245 263.291 139.245L263.259 139.148ZM257.004 135.026C257.004 135.026 257.069 135.026 257.101 135.026C257.231 135.026 257.295 135.026 257.36 135.091L263.032 138.531C263.032 138.531 263.259 138.596 263.356 138.564C263.486 138.531 263.551 138.434 263.615 138.336L266.403 131.845C267.537 130.222 268.315 128.437 268.704 126.586L268.769 126.327L271.523 119.803L266.014 116.427C265.754 116.232 265.528 116.102 265.301 115.973C264.847 115.745 264.361 115.583 263.875 115.518C259.694 114.804 254.93 119.283 253.86 124.931C252.985 129.54 255.124 133.435 256.42 134.928L257.004 135.026Z" fill="#00283B" />
        <path d="M274.116 121.49C273.111 119.965 271.718 119.024 270.097 118.731C265.722 117.985 261.055 122.497 259.92 128.599C259.272 132.072 259.823 135.318 261.476 137.557C262.546 139.018 263.972 139.862 265.592 140.057C265.819 140.057 266.014 140.089 266.24 140.089C270.162 140.089 274.084 136.162 275.315 130.773C276.125 127.3 275.672 123.827 274.116 121.458V121.49Z" fill="#00283B" />
        <path d="M272.657 130.579C271.847 134.214 269.157 136.843 266.662 136.551C264.134 136.259 262.61 133.013 263.356 129.28C264.101 125.45 266.888 122.626 269.514 123.048C272.106 123.47 273.468 126.846 272.625 130.579H272.657Z" fill="#B0E2FA" />
        <path d="M267.051 136.876C267.051 136.876 266.759 136.876 266.629 136.876C265.625 136.746 264.717 136.194 264.069 135.285C262.999 133.825 262.643 131.585 263.097 129.28C263.907 125.288 266.791 122.367 269.579 122.821C270.583 122.984 271.458 123.568 272.107 124.509C273.144 126.035 273.435 128.339 272.917 130.676C272.107 134.279 269.579 136.876 267.051 136.876ZM269.028 123.308C266.726 123.308 264.328 125.937 263.648 129.345C263.226 131.488 263.55 133.565 264.523 134.928C265.106 135.707 265.851 136.194 266.694 136.291C269.06 136.584 271.588 133.987 272.398 130.546C272.884 128.372 272.625 126.229 271.653 124.834C271.102 124.022 270.356 123.503 269.481 123.373C269.319 123.373 269.157 123.341 269.028 123.341V123.308Z" fill="#00283B" />
        <path d="M266.629 134.279C266.629 134.279 266.435 134.279 266.338 134.279C265.689 134.214 265.106 133.857 264.685 133.24C264.004 132.299 263.777 130.871 264.069 129.443C264.587 126.911 266.435 125.061 268.217 125.353C268.866 125.45 269.449 125.84 269.87 126.456C270.518 127.43 270.713 128.891 270.389 130.351C269.87 132.624 268.25 134.279 266.629 134.279ZM267.861 125.872C266.467 125.872 265.041 127.463 264.62 129.54C264.361 130.838 264.555 132.104 265.138 132.916C265.463 133.37 265.916 133.662 266.435 133.727C267.861 133.889 269.384 132.331 269.838 130.222C270.13 128.891 269.967 127.592 269.384 126.749C269.06 126.262 268.606 125.97 268.12 125.872C268.023 125.872 267.926 125.872 267.828 125.872H267.861Z" fill="#00283B" />
        <path d="M270.324 130.643C269.903 132.234 268.704 133.37 267.602 133.175C266.5 133.013 265.884 131.552 266.24 129.929C266.629 128.242 267.893 127.041 269.06 127.268C270.194 127.495 270.745 128.988 270.324 130.611V130.643Z" fill="#B0E2FA" />
        <path d="M269.579 130.254C269.125 132.007 267.796 133.24 266.565 133.046C265.333 132.851 264.652 131.26 265.074 129.475C265.495 127.625 266.889 126.294 268.153 126.554C269.384 126.781 270 128.469 269.546 130.254H269.579Z" fill="#F5FCFF" />
        <path d="M267.828 133.467C267.828 133.467 267.666 133.467 267.569 133.467C267.083 133.402 266.662 133.11 266.37 132.656C265.916 131.974 265.754 130.936 266.013 129.865C266.435 128.047 267.861 126.749 269.157 127.008C269.643 127.106 270.065 127.398 270.356 127.852C270.81 128.566 270.907 129.637 270.648 130.708C270.227 132.331 269.028 133.467 267.861 133.467H267.828ZM268.801 127.528C267.861 127.528 266.856 128.599 266.532 129.994C266.338 130.903 266.435 131.78 266.824 132.331C267.051 132.656 267.342 132.851 267.666 132.883C268.606 133.013 269.708 131.974 270.097 130.546C270.324 129.637 270.259 128.728 269.87 128.144C269.676 127.82 269.384 127.592 269.028 127.528C268.963 127.528 268.865 127.528 268.801 127.528Z" fill="#00283B" />
        <path d="M251.559 137.427L254.411 130.676L248.675 127.17C248.448 127.008 248.188 126.846 247.929 126.716C247.475 126.489 246.989 126.327 246.438 126.229C242.193 125.515 237.234 129.897 236.1 135.869C235.224 140.446 237.266 144.471 238.79 146.158L239.405 146.256C239.405 146.256 239.697 146.256 239.729 146.288L245.401 149.729C245.758 149.956 246.211 149.794 246.406 149.404L249.193 142.913C250.23 141.42 251.073 139.602 251.494 137.622C251.494 137.557 251.527 137.492 251.559 137.427Z" fill="#B0E2FA" />
        <path d="M245.758 150.118C245.595 150.118 245.401 150.086 245.271 149.989L239.632 146.581L238.692 145.996C237.331 144.406 234.933 140.608 235.84 135.804C236.974 129.767 241.966 125.158 246.503 125.937C247.054 126.034 247.573 126.197 248.059 126.456C248.318 126.586 248.577 126.749 248.869 126.943L254.768 130.579L251.786 137.719C251.397 139.602 250.587 141.485 249.42 143.108L246.665 149.567C246.568 149.826 246.341 150.021 246.082 150.118C245.984 150.118 245.887 150.151 245.79 150.151L245.758 150.118ZM239.859 146.061L245.531 149.502C245.531 149.502 245.758 149.567 245.855 149.534C245.984 149.502 246.049 149.404 246.114 149.307L248.901 142.815C250.036 141.192 250.813 139.407 251.202 137.557L251.267 137.297L254.022 130.773L248.512 127.398C248.253 127.203 248.026 127.073 247.799 126.943C247.346 126.716 246.859 126.554 246.373 126.489C242.192 125.775 237.428 130.254 236.359 135.902C235.484 140.511 237.785 144.146 239.113 145.639C239.113 145.639 239.794 146.029 239.859 146.061Z" fill="#00283B" />
        <path d="M256.615 132.461C255.61 130.936 254.217 129.994 252.596 129.702C248.221 128.956 243.554 133.467 242.42 139.569C241.771 143.043 242.322 146.288 243.975 148.528C245.045 149.989 246.471 150.833 248.091 151.027C248.318 151.027 248.513 151.06 248.739 151.06C252.661 151.06 256.583 147.132 257.814 141.744C258.625 138.271 258.171 134.798 256.615 132.429V132.461Z" fill="#00283B" />
        <path d="M255.156 141.549C254.346 145.185 251.656 147.814 249.161 147.522C246.633 147.23 245.109 143.984 245.855 140.251C246.6 136.421 249.387 133.597 252.013 134.019C254.605 134.441 255.967 137.817 255.124 141.549H255.156Z" fill="#B0E2FA" />
        <path d="M249.55 147.846C249.55 147.846 249.258 147.846 249.128 147.846C248.124 147.717 247.216 147.165 246.568 146.256C245.498 144.795 245.142 142.556 245.596 140.251C246.406 136.259 249.29 133.338 252.078 133.792C253.082 133.954 253.957 134.539 254.606 135.48C255.643 137.005 255.934 139.31 255.416 141.647C254.606 145.25 252.078 147.846 249.55 147.846ZM251.527 134.279C249.226 134.279 246.827 136.908 246.147 140.316C245.725 142.458 246.049 144.536 247.022 145.899C247.605 146.678 248.35 147.165 249.193 147.262C251.559 147.554 254.087 144.958 254.897 141.517C255.383 139.342 255.124 137.2 254.152 135.804C253.601 134.993 252.855 134.474 251.98 134.344C251.818 134.344 251.656 134.311 251.527 134.311V134.279Z" fill="#00283B" />
        <path d="M249.128 145.282C249.128 145.282 248.934 145.282 248.837 145.282C248.189 145.217 247.605 144.86 247.151 144.243C246.471 143.302 246.244 141.874 246.536 140.446C247.054 137.914 248.902 136.064 250.684 136.356C251.332 136.453 251.916 136.843 252.337 137.46C252.985 138.433 253.18 139.894 252.856 141.355C252.337 143.627 250.717 145.282 249.096 145.282H249.128ZM250.36 136.875C248.966 136.875 247.54 138.466 247.119 140.543C246.86 141.841 247.054 143.107 247.638 143.919C247.962 144.373 248.415 144.665 248.934 144.73C250.36 144.893 251.883 143.335 252.337 141.225C252.629 139.894 252.467 138.596 251.883 137.752C251.559 137.265 251.105 136.973 250.619 136.875C250.522 136.875 250.425 136.875 250.328 136.875H250.36Z" fill="#00283B" />
        <path d="M252.823 141.614C252.402 143.205 251.203 144.341 250.101 144.146C248.999 143.984 248.383 142.523 248.739 140.9C249.128 139.213 250.392 138.012 251.559 138.239C252.693 138.466 253.244 139.959 252.823 141.582V141.614Z" fill="#B0E2FA" />
        <path d="M252.078 141.225C251.624 142.978 250.295 144.211 249.064 144.016C247.832 143.821 247.151 142.231 247.573 140.446C247.994 138.596 249.388 137.265 250.652 137.525C251.883 137.752 252.499 139.44 252.045 141.225H252.078Z" fill="#F5FCFF" />
        <path d="M250.328 144.438C250.328 144.438 250.166 144.438 250.068 144.438C249.582 144.373 249.161 144.081 248.869 143.627C248.383 142.945 248.253 141.906 248.513 140.835C248.934 139.018 250.36 137.719 251.656 137.979C252.143 138.076 252.564 138.368 252.856 138.823C253.309 139.537 253.407 140.608 253.147 141.679C252.726 143.302 251.527 144.438 250.36 144.438H250.328ZM251.3 138.498C250.36 138.498 249.355 139.569 249.031 140.965C248.837 141.874 248.934 142.75 249.323 143.302C249.55 143.627 249.841 143.821 250.166 143.854C251.105 143.984 252.207 142.945 252.596 141.517C252.823 140.608 252.758 139.699 252.369 139.115C252.175 138.79 251.883 138.563 251.527 138.498C251.462 138.498 251.365 138.498 251.3 138.498Z" fill="#00283B" />
        <path d="M200.934 125.191L207.19 109.546L196.365 103.379L190.336 118.829L200.934 125.191Z" fill="#B0E2FA" />
        <path d="M234.803 92.765L207.189 109.546L196.364 103.379L224.237 86.7603L234.803 92.765Z" fill="#B0E2FA" />
        <path d="M235.224 95.2643L207.578 112.045L196.753 105.911L224.658 89.292L235.224 95.2643Z" fill="#F5FCFF" />
        <path d="M235.419 92.7324L224.237 86.4031L196.105 103.152L189.947 118.926L201.064 125.613L207.416 109.708L235.419 92.7V92.7324ZM196.98 103.346L224.269 87.0847L234.252 92.7324L207.189 109.156L197.628 103.703L196.98 103.346ZM200.805 124.736L190.725 118.667L196.527 103.768L196.948 104.028L206.8 109.643L200.772 124.736H200.805Z" fill="#00283B" />
        <path d="M191.017 130.968L244.429 99.9382L244.332 102.275L193.156 132.234L191.017 130.968Z" fill="#00283B" />
        <path d="M257.684 107.696L244.428 99.9382L244.331 102.275L257.101 109.968L257.684 107.696Z" fill="#B0E2FA" />
        <path d="M257.295 110.422L244.04 102.438L244.169 99.4189L258.041 107.534L257.263 110.422H257.295ZM244.655 102.113L256.906 109.513L257.36 107.826L244.72 100.458L244.655 102.113Z" fill="#00283B" />
        <path d="M285.622 115.713L276.677 121.004L277.033 124.379L277.293 124.542L286.043 119.575L285.914 115.875L285.622 115.713Z" fill="#00283B" />
        <path d="M168.751 131.325L168.914 134.766L214.774 161.576L214.871 158.006L168.751 131.325Z" fill="#B0E2FA" />
        <path d="M215.065 161.771L214.774 161.966L168.622 134.993L168.427 131.195L168.751 131.001L215.162 157.876L215.065 161.804V161.771ZM169.205 134.604L214.482 161.057L214.547 158.168L169.075 131.845L169.205 134.604Z" fill="#00283B" />
        <path d="M195.878 147.035L207.027 140.446L210.171 142.296L229.131 130.546L232.372 123.503L195.878 144.958V147.035Z" fill="#00283B" />
        <path d="M259.5 107.598L266.954 103.249V105.132L261.185 108.572L259.5 107.598Z" fill="#00283B" />
        <path d="M195.879 147.035L184.892 140.673V138.531L195.879 144.958V147.035Z" fill="#B0E2FA" />
        <path d="M184.891 138.531L256.323 97.1143L266.953 103.249L195.878 144.958L184.891 138.531Z" fill="#B0E2FA" />
        <path d="M184.891 139.602L256.323 98.1531L266.143 103.703L195.878 144.958V145.997L184.891 139.602Z" fill="#F5FCFF" />
        <path d="M267.278 103.054L256.323 96.7573L184.6 138.369V140.836L195.911 147.392L207.06 140.803L210.204 142.653L229.325 130.806H229.39L232.631 123.763L259.532 107.955L261.184 108.897L267.278 105.294V103.054ZM195.619 146.516L185.215 140.479V139.05L195.619 145.12V146.516ZM228.904 130.319L210.171 141.939L207.06 140.089L196.202 146.516V145.12L231.691 124.282L228.872 130.319H228.904ZM233.085 122.789L196.98 143.984L195.911 144.601L185.507 138.531L256.323 97.4389L266.37 103.217L258.916 107.598L233.053 122.789H233.085ZM266.694 104.937L261.184 108.215L260.115 107.598L266.273 103.996L266.694 103.768V104.937Z" fill="#00283B" />
        <path d="M269.222 113.149L241.576 129.897L230.751 123.73L258.656 107.111L269.222 113.149Z" fill="#B0E2FA" />
        <path d="M235.322 145.867L241.577 129.897L230.752 123.73L224.529 139.44L235.322 145.867Z" fill="#B0E2FA" />
        <path d="M269.223 115.648L241.577 132.429L230.752 126.262L258.657 109.643L269.223 115.648Z" fill="#F5FCFF" />
        <path d="M269.806 113.116L258.624 106.754L230.492 123.503L224.14 139.537L235.451 146.289L241.804 130.059L269.806 113.084V113.116ZM258.657 107.468L268.639 113.149L241.577 129.54L231.368 123.73L258.657 107.468ZM235.192 145.445L224.918 139.342L230.914 124.184L241.22 130.059L235.192 145.477V145.445Z" fill="#00283B" />
        <path d="M259.694 104.969L198.666 140.284C197.24 141.095 194.776 140.965 193.156 140.024C191.535 139.083 191.373 137.622 192.767 136.811L253.795 101.496C255.221 100.685 257.684 100.814 259.305 101.756C260.925 102.697 261.087 104.158 259.694 104.969Z" fill="#B0E2FA" />
        <path d="M196.3 141.095C195.133 141.095 193.934 140.803 192.994 140.251C192.054 139.699 191.503 138.92 191.503 138.174C191.503 137.525 191.892 136.94 192.605 136.519L253.633 101.204C255.124 100.36 257.749 100.457 259.467 101.464C260.407 102.015 260.958 102.794 260.958 103.541C260.958 104.19 260.569 104.774 259.856 105.196L198.828 140.511C198.147 140.9 197.24 141.095 196.332 141.095H196.3ZM256.128 101.236C255.286 101.236 254.508 101.399 253.925 101.723L192.897 137.038C192.378 137.33 192.086 137.719 192.086 138.174C192.086 138.726 192.508 139.277 193.285 139.732C194.841 140.641 197.175 140.738 198.503 139.992L259.532 104.677C260.05 104.385 260.342 103.995 260.342 103.541C260.342 102.989 259.92 102.437 259.143 101.983C258.268 101.464 257.166 101.204 256.128 101.204V101.236Z" fill="#00283B" />
        <path d="M192.475 139.472L253.503 104.158C254.929 103.346 257.393 103.476 259.013 104.417C259.305 104.58 259.499 104.775 259.694 104.937C261.087 104.125 260.925 102.697 259.305 101.756C257.684 100.815 255.189 100.685 253.795 101.496L192.767 136.811C191.6 137.492 191.503 138.564 192.443 139.472H192.475Z" fill="#00283B" />
        <path d="M192.476 139.829L192.249 139.699C191.698 139.18 191.438 138.563 191.536 138.012C191.601 137.622 191.827 137.038 192.638 136.583L253.666 101.269C255.157 100.425 257.782 100.522 259.499 101.529C260.439 102.08 260.99 102.827 260.99 103.606C260.99 104.255 260.601 104.839 259.888 105.229L259.694 105.359L259.532 105.196C259.337 105.002 259.111 104.839 258.884 104.709C257.328 103.801 254.994 103.671 253.666 104.45L192.605 139.764L192.476 139.829ZM256.161 101.269C255.319 101.269 254.541 101.431 253.957 101.756L192.929 137.07C192.443 137.33 192.184 137.687 192.119 138.077C192.087 138.401 192.216 138.758 192.508 139.115L253.342 103.898C254.832 103.054 257.458 103.151 259.175 104.158C259.37 104.288 259.564 104.417 259.759 104.58C260.148 104.288 260.375 103.963 260.375 103.573C260.375 103.022 259.953 102.47 259.175 102.015C258.3 101.496 257.198 101.236 256.161 101.236V101.269Z" fill="#00283B" />
        <path d="M226.863 147.23L218.987 152.001C217.561 152.813 215.098 152.683 213.477 151.741C211.857 150.8 211.695 149.34 213.088 148.528L220.964 143.757C222.39 142.945 224.853 143.075 226.474 144.016C228.094 144.958 228.256 146.418 226.863 147.23Z" fill="#B0E2FA" />
        <path d="M216.621 152.845C215.454 152.845 214.255 152.553 213.315 152.001C212.375 151.449 211.824 150.67 211.824 149.924C211.824 149.275 212.213 148.69 212.926 148.268L220.769 143.497C222.293 142.621 224.885 142.75 226.603 143.757C227.543 144.308 228.094 145.087 228.094 145.834C228.094 146.483 227.705 147.067 226.992 147.489L219.149 152.261C218.468 152.65 217.561 152.845 216.621 152.845ZM223.297 143.529C222.455 143.529 221.677 143.692 221.093 144.016L213.25 148.788C212.699 149.08 212.44 149.469 212.44 149.924C212.44 150.476 212.861 151.027 213.639 151.482C215.195 152.391 217.528 152.52 218.857 151.741L226.733 146.97C227.251 146.678 227.543 146.288 227.543 145.834C227.543 145.282 227.122 144.73 226.344 144.276C225.469 143.757 224.367 143.497 223.33 143.497L223.297 143.529Z" fill="#00283B" />
        <path d="M212.796 151.222L220.672 146.451C222.098 145.639 224.561 145.769 226.182 146.71C226.473 146.873 226.668 147.067 226.862 147.23C228.256 146.418 228.094 144.99 226.473 144.049C224.853 143.108 222.357 142.978 220.963 143.789L213.088 148.561C211.921 149.242 211.856 150.313 212.764 151.222H212.796Z" fill="#00283B" />
        <path d="M212.796 151.547L212.57 151.417C212.019 150.897 211.759 150.281 211.856 149.697C211.921 149.307 212.148 148.723 212.958 148.268L220.802 143.497C222.293 142.621 224.918 142.75 226.635 143.757C227.575 144.308 228.126 145.055 228.126 145.834C228.126 146.483 227.737 147.067 227.024 147.457L226.83 147.554L226.668 147.392C226.473 147.197 226.247 147.035 226.02 146.905C224.464 145.996 222.13 145.866 220.802 146.645L212.894 151.449L212.764 151.514L212.796 151.547ZM223.33 143.529C222.487 143.529 221.709 143.692 221.126 144.016L213.283 148.788C212.796 149.047 212.537 149.404 212.472 149.794C212.44 150.118 212.57 150.476 212.861 150.833L220.542 146.159C222.066 145.282 224.658 145.412 226.376 146.418C226.571 146.548 226.765 146.678 226.96 146.84C227.348 146.548 227.575 146.223 227.575 145.834C227.575 145.282 227.154 144.73 226.376 144.276C225.501 143.757 224.399 143.497 223.362 143.497L223.33 143.529Z" fill="#00283B" />
        <path d="M192.475 127.657L184.6 132.429C183.174 133.24 180.711 133.11 179.09 132.169C177.47 131.228 177.308 129.767 178.701 128.956L186.577 124.184C188.003 123.373 190.466 123.503 192.086 124.444C193.707 125.385 193.869 126.846 192.475 127.657Z" fill="#B0E2FA" />
        <path d="M182.234 133.273C181.067 133.273 179.868 132.981 178.928 132.429C177.988 131.877 177.437 131.098 177.437 130.351C177.437 129.702 177.826 129.118 178.539 128.696L186.382 123.925C187.905 123.048 190.498 123.178 192.216 124.184C193.156 124.736 193.707 125.515 193.707 126.262C193.707 126.911 193.318 127.495 192.605 127.917L184.762 132.688C184.081 133.078 183.174 133.273 182.234 133.273ZM188.91 123.957C188.067 123.957 187.29 124.119 186.706 124.444L178.863 129.215C178.312 129.508 178.053 129.897 178.053 130.351C178.053 130.903 178.474 131.455 179.252 131.909C180.808 132.818 183.141 132.948 184.47 132.169L192.346 127.398C192.864 127.106 193.156 126.716 193.156 126.262C193.156 125.71 192.735 125.158 191.957 124.704C191.082 124.184 189.98 123.925 188.943 123.925L188.91 123.957Z" fill="#00283B" />
        <path d="M178.409 131.65L186.285 126.879C187.711 126.067 190.174 126.197 191.795 127.138C192.086 127.3 192.281 127.495 192.475 127.658C193.869 126.846 193.707 125.418 192.086 124.477C190.466 123.535 187.97 123.405 186.577 124.217L178.701 128.988C177.534 129.67 177.437 130.741 178.377 131.65H178.409Z" fill="#00283B" />
        <path d="M178.312 132.007L178.15 131.845C177.599 131.325 177.34 130.709 177.437 130.157C177.502 129.767 177.729 129.183 178.539 128.729L186.382 123.957C187.905 123.081 190.498 123.211 192.216 124.217C193.156 124.769 193.707 125.515 193.707 126.294C193.707 126.943 193.318 127.528 192.605 127.917L192.41 128.047L192.248 127.885C192.054 127.69 191.827 127.528 191.6 127.398C190.045 126.489 187.711 126.359 186.382 127.138L178.312 132.039V132.007ZM188.91 123.957C188.068 123.957 187.29 124.119 186.706 124.444L178.863 129.215C178.377 129.475 178.118 129.832 178.053 130.222C178.02 130.546 178.15 130.903 178.442 131.26L186.123 126.586C187.614 125.71 190.239 125.84 191.957 126.846C192.151 126.976 192.346 127.106 192.54 127.268C192.929 126.976 193.156 126.651 193.156 126.262C193.156 125.71 192.735 125.158 191.957 124.704C191.082 124.184 189.98 123.925 188.943 123.925L188.91 123.957Z" fill="#00283B" />
        <path d="M278.46 116.719L276.839 117.595C275.413 118.407 272.95 118.277 271.329 117.336C269.709 116.394 269.547 114.934 270.94 114.122L272.561 113.246C273.987 112.435 276.45 112.564 278.071 113.506C279.691 114.447 279.853 115.908 278.46 116.719Z" fill="#B0E2FA" />
        <path d="M274.473 118.407C273.306 118.407 272.107 118.115 271.167 117.563C270.227 117.011 269.676 116.232 269.676 115.486C269.676 114.837 270.065 114.252 270.778 113.83L272.399 112.954C273.89 112.11 276.515 112.207 278.2 113.214C279.14 113.765 279.691 114.544 279.691 115.291C279.691 115.94 279.302 116.524 278.589 116.946L276.969 117.823C276.288 118.212 275.413 118.407 274.473 118.407ZM274.894 113.019C274.052 113.019 273.274 113.181 272.69 113.506L271.07 114.382C270.551 114.674 270.26 115.064 270.26 115.486C270.26 116.038 270.681 116.589 271.459 117.044C273.014 117.953 275.348 118.082 276.677 117.303L278.297 116.427C278.816 116.135 279.108 115.745 279.108 115.291C279.108 114.739 278.686 114.187 277.908 113.733C277.033 113.214 275.931 112.954 274.894 112.954V113.019Z" fill="#00283B" />
        <path d="M270.648 116.784L272.269 115.908C273.695 115.096 276.158 115.226 277.778 116.167C278.07 116.33 278.265 116.524 278.459 116.687C279.853 115.875 279.691 114.447 278.07 113.506C276.45 112.564 273.954 112.435 272.56 113.246L270.94 114.122C269.773 114.804 269.676 115.875 270.616 116.784H270.648Z" fill="#00283B" />
        <path d="M270.616 117.109L270.422 116.979C269.871 116.459 269.611 115.843 269.709 115.291C269.773 114.901 270 114.317 270.81 113.863L272.431 112.986C273.922 112.143 276.547 112.24 278.232 113.246C279.172 113.798 279.723 114.544 279.723 115.323C279.723 115.973 279.334 116.557 278.621 116.946L278.427 117.076L278.265 116.914C278.07 116.719 277.843 116.557 277.617 116.427C276.061 115.518 273.727 115.388 272.399 116.167L270.778 117.044L270.616 117.141V117.109ZM274.894 112.986C274.051 112.986 273.274 113.149 272.69 113.473L271.07 114.35C270.616 114.609 270.324 114.966 270.292 115.356C270.259 115.68 270.389 116.038 270.681 116.395L272.107 115.616C273.598 114.772 276.223 114.869 277.941 115.875C278.135 116.005 278.33 116.135 278.524 116.297C278.913 116.005 279.14 115.68 279.14 115.291C279.14 114.739 278.718 114.187 277.941 113.733C277.066 113.214 275.964 112.954 274.927 112.954L274.894 112.986Z" fill="#00283B" />
        <path d="M245.628 97.1468L244.007 98.0231C242.581 98.8346 240.118 98.7047 238.498 97.7635C236.877 96.8222 236.715 95.3616 238.109 94.5501L239.729 93.6737C241.155 92.8623 243.619 92.9921 245.239 93.9334C246.86 94.8747 247.022 96.3353 245.628 97.1468Z" fill="#B0E2FA" />
        <path d="M241.641 98.8347C240.475 98.8347 239.275 98.5426 238.336 97.9908C237.396 97.439 236.845 96.66 236.845 95.9134C236.845 95.2643 237.234 94.68 237.947 94.2581L239.567 93.3817C241.058 92.5378 243.683 92.6352 245.401 93.6414C246.341 94.1932 246.892 94.9721 246.892 95.7187C246.892 96.3679 246.503 96.9521 245.79 97.3741L244.169 98.2504C243.489 98.6399 242.614 98.8347 241.674 98.8347H241.641ZM242.063 93.4466C241.22 93.4466 240.442 93.6089 239.859 93.9335L238.238 94.8099C237.72 95.102 237.428 95.4915 237.428 95.9134C237.428 96.4652 237.849 97.017 238.627 97.4714C240.183 98.3803 242.516 98.5101 243.845 97.7311L245.466 96.8547C245.984 96.5626 246.276 96.1731 246.276 95.7187C246.276 95.1669 245.855 94.6151 245.077 94.1607C244.202 93.6414 243.1 93.3817 242.063 93.3817V93.4466Z" fill="#00283B" />
        <path d="M237.817 97.2117L239.438 96.3353C240.864 95.5238 243.327 95.6537 244.947 96.595C245.239 96.7573 245.434 96.952 245.628 97.1143C247.022 96.3028 246.86 94.8747 245.239 93.9334C243.619 92.9921 241.123 92.8623 239.729 93.6737L238.109 94.5501C236.942 95.2317 236.877 96.3028 237.785 97.2117H237.817Z" fill="#00283B" />
        <path d="M237.72 97.5688L237.558 97.4065C237.007 96.8872 236.748 96.2705 236.845 95.7187C236.91 95.3292 237.136 94.745 237.947 94.2905L239.567 93.4142C241.058 92.5703 243.683 92.6676 245.401 93.6738C246.341 94.2256 246.892 94.9722 246.892 95.7512C246.892 96.4003 246.503 96.9846 245.79 97.3741L245.595 97.4714L245.433 97.3092C245.239 97.1144 245.012 96.9521 244.785 96.8223C243.23 95.9134 240.896 95.7836 239.567 96.5626L237.752 97.5364L237.72 97.5688ZM242.063 93.4466C241.22 93.4466 240.442 93.6089 239.859 93.9335L238.238 94.8099C237.785 95.0695 237.493 95.4266 237.461 95.8161C237.428 96.1407 237.558 96.4977 237.849 96.8547L239.276 96.0757C240.766 95.2318 243.392 95.3292 245.109 96.3354C245.304 96.4652 245.498 96.5951 245.693 96.7574C246.082 96.4652 246.309 96.1407 246.309 95.7512C246.309 95.1994 245.887 94.6476 245.109 94.1932C244.234 93.6738 243.132 93.4142 242.095 93.4142L242.063 93.4466Z" fill="#00283B" />
        <path d="M200.935 125.191L207.19 109.546L234.803 92.7649L244.429 99.9382L200.935 125.191Z" fill="#B0E2FA" />
        <path d="M244.753 99.8084L234.835 92.408L207.028 109.286L206.93 109.351L200.675 125.028L190.434 130.968L193.156 132.591L244.493 102.567L244.623 102.47L244.72 99.8084H244.753ZM201.55 124.509L207.449 109.741L234.835 93.122L243.943 99.9058L243.359 100.263L201.583 124.509H201.55ZM244.072 102.113L193.188 131.877L191.633 130.968L244.137 100.49L244.072 102.113Z" fill="#00283B" />
        <path d="M269.222 112.759L241.317 129.702L234.933 146.061L238.012 147.1L243.327 132.332L269.19 116.362L278.038 122.951L279.01 120.127L269.222 112.759Z" fill="#00283B" />
        <path d="M220.834 129.28L220.575 126.229L216.621 126.294C212.861 124.736 207.773 125.288 204.305 126.943H200.74V130.416V130.059C200.707 131.78 202.036 133.305 204.597 134.474C208.94 136.421 215.357 135.967 218.695 133.565C220.38 132.364 220.834 130.741 220.834 129.313V129.28Z" fill="#B0E2FA" />
        <path d="M210.852 135.999C208.616 135.999 206.379 135.577 204.499 134.733C202.004 133.597 200.61 132.104 200.481 130.416H200.448L200.481 126.651H204.273C207.449 125.158 212.634 124.347 216.718 126.002L220.899 125.937L221.158 129.313C221.158 131.228 220.413 132.721 218.89 133.824C216.88 135.285 213.866 136.032 210.852 136.032V135.999ZM201.032 130.059C201.032 131.65 202.263 133.078 204.726 134.182C208.907 136.064 215.227 135.675 218.533 133.305C219.894 132.331 220.542 131.033 220.542 129.313L220.316 126.554L216.556 126.619H216.491C212.57 124.963 207.481 125.775 204.435 127.235H204.305H201.032V130.059Z" fill="#00283B" />
        <path d="M216.75 121.75C221.093 123.698 221.903 127.268 218.565 129.67C215.227 132.072 208.972 132.461 204.629 130.514C200.286 128.566 199.476 124.996 202.814 122.594C206.152 120.192 212.407 119.803 216.75 121.75Z" fill="#B0E2FA" />
        <path d="M216.686 123.438C220.899 125.353 221.709 128.793 218.436 131.13C215.195 133.467 209.102 133.857 204.888 131.942C200.675 130.027 199.865 126.586 203.138 124.249C206.379 121.912 212.472 121.523 216.686 123.438Z" fill="#F5FCFF" />
        <path d="M210.917 132.072C208.68 132.072 206.412 131.65 204.499 130.806C202.101 129.735 200.643 128.112 200.481 126.359C200.318 124.898 201.096 123.47 202.652 122.367C206.087 119.9 212.472 119.51 216.88 121.49C219.278 122.561 220.737 124.184 220.899 125.937C221.061 127.398 220.283 128.826 218.727 129.929C216.783 131.325 213.866 132.072 210.917 132.072ZM210.463 120.809C207.643 120.809 204.856 121.49 203.009 122.854C201.647 123.827 200.967 125.061 201.096 126.294C201.258 127.82 202.587 129.248 204.791 130.254C209.037 132.169 215.162 131.812 218.436 129.443C219.797 128.469 220.478 127.235 220.348 126.002C220.186 124.476 218.857 123.048 216.653 122.042C214.806 121.198 212.634 120.809 210.463 120.809Z" fill="#00283B" />
        <path d="M214.903 127.852L213.056 128.826C211.435 129.767 208.616 129.605 206.736 128.534C204.856 127.43 204.694 125.807 206.315 124.866L208.162 123.892C209.783 122.951 212.602 123.113 214.482 124.184C216.362 125.288 216.524 126.911 214.903 127.852Z" fill="#B0E2FA" />
        <path d="M210.366 129.767C209.037 129.767 207.676 129.443 206.606 128.826C205.537 128.209 204.921 127.333 204.921 126.489C204.921 125.775 205.375 125.093 206.185 124.639L208.032 123.633C209.718 122.659 212.699 122.789 214.644 123.925C215.713 124.542 216.329 125.418 216.329 126.262C216.329 126.976 215.875 127.658 215.065 128.112L213.218 129.118C212.472 129.54 211.435 129.767 210.366 129.767ZM210.852 123.6C209.88 123.6 208.972 123.795 208.292 124.185L206.444 125.191C205.828 125.548 205.504 126.002 205.504 126.522C205.504 127.171 205.99 127.82 206.898 128.339C208.68 129.378 211.37 129.508 212.926 128.631L214.774 127.625C215.389 127.268 215.713 126.814 215.713 126.294C215.713 125.645 215.227 124.996 214.32 124.477C213.315 123.892 212.051 123.6 210.852 123.6Z" fill="#00283B" />
        <path d="M205.958 127.917L207.805 126.943C209.425 126.002 212.245 126.164 214.125 127.236C214.449 127.43 214.708 127.625 214.903 127.852C216.491 126.911 216.296 125.288 214.449 124.217C212.569 123.113 209.75 122.984 208.129 123.925L206.282 124.899C204.92 125.678 204.856 126.911 205.925 127.95L205.958 127.917Z" fill="#00283B" />
        <path d="M205.958 128.274L205.731 128.144C205.115 127.56 204.823 126.879 204.921 126.229C205.018 125.58 205.439 125.028 206.152 124.606L208 123.6C209.685 122.627 212.667 122.756 214.611 123.892C215.681 124.509 216.297 125.385 216.297 126.229C216.297 126.943 215.843 127.593 215.065 128.079L214.871 128.177L214.709 128.015C214.482 127.787 214.222 127.593 213.963 127.463C212.181 126.424 209.491 126.294 207.935 127.171L206.087 128.177L205.925 128.274H205.958ZM210.852 123.568C209.879 123.568 208.972 123.763 208.291 124.152L206.444 125.158C205.893 125.483 205.569 125.872 205.504 126.327C205.439 126.716 205.634 127.171 205.99 127.56L207.643 126.684C209.328 125.71 212.31 125.84 214.255 126.976C214.482 127.106 214.709 127.268 214.935 127.463C215.422 127.138 215.681 126.716 215.681 126.262C215.681 125.645 215.162 124.964 214.287 124.444C213.282 123.86 212.018 123.568 210.819 123.568H210.852Z" fill="#00283B" />
        <path d="M222.876 156.545C222.714 156.416 222.617 156.156 222.617 155.831C222.617 155.15 223.038 154.338 223.557 154.046L225.987 152.65C226.214 152.521 226.441 152.521 226.603 152.618C226.603 152.618 226.636 152.618 226.668 152.65L227.575 153.332L226.311 155.02L223.719 157.195L222.876 156.578V156.545Z" fill="#B0E2FA" />
        <path d="M223.589 157.422L226.506 155.215L227.802 153.105L226.571 152.228C226.344 152.164 226.052 152.228 225.825 152.391L223.394 153.786C222.779 154.144 222.292 155.052 222.292 155.831C222.292 156.253 222.422 156.61 222.681 156.805L223.589 157.454V157.422ZM227.154 153.365L226.052 154.793L223.686 156.773L223.006 156.286C223.006 156.286 222.876 156.026 222.876 155.799C222.876 155.215 223.232 154.501 223.654 154.273L226.084 152.878C226.084 152.878 226.311 152.78 226.409 152.845H226.441L227.122 153.365H227.154Z" fill="#00283B" />
        <path d="M224.432 157.195L226.862 155.799C227.381 155.507 227.802 154.695 227.802 154.014C227.802 153.332 227.381 153.008 226.862 153.3L224.432 154.695C223.913 154.987 223.492 155.799 223.492 156.481C223.492 157.162 223.913 157.487 224.432 157.195Z" fill="#F5FCFF" />
        <path d="M224.075 157.617C224.237 157.617 224.432 157.552 224.594 157.454L227.025 156.059C227.64 155.702 228.127 154.793 228.127 154.014C228.127 153.527 227.964 153.17 227.64 153.007C227.381 152.845 227.057 152.878 226.733 153.072L224.302 154.468C223.686 154.825 223.2 155.734 223.2 156.513C223.2 157 223.362 157.357 223.686 157.519C223.816 157.584 223.946 157.617 224.075 157.617ZM227.251 153.494C227.251 153.494 227.316 153.494 227.349 153.494C227.446 153.527 227.511 153.689 227.511 153.981C227.511 154.565 227.154 155.28 226.733 155.507L224.302 156.902C224.302 156.902 224.075 157 223.978 156.967C223.881 156.935 223.816 156.773 223.816 156.481C223.816 155.896 224.172 155.182 224.594 154.955L227.025 153.559C227.025 153.559 227.154 153.494 227.251 153.494Z" fill="#00283B" />
        <path d="M230.655 151.871C230.493 151.742 230.396 151.482 230.396 151.157C230.396 150.476 230.817 149.664 231.335 149.372L233.766 147.976C233.993 147.847 234.22 147.847 234.382 147.944C234.382 147.944 234.414 147.944 234.447 147.976L235.354 148.658L234.09 150.346L231.497 152.521L230.655 151.904V151.871Z" fill="#B0E2FA" />
        <path d="M231.368 152.78L234.285 150.573L235.581 148.463L234.349 147.587C234.123 147.522 233.831 147.587 233.604 147.749L231.173 149.145C230.557 149.502 230.071 150.411 230.071 151.19C230.071 151.612 230.201 151.969 230.46 152.163L231.368 152.813V152.78ZM234.933 148.723L233.863 150.151L231.497 152.131L230.817 151.644C230.817 151.644 230.687 151.384 230.687 151.157C230.687 150.573 231.044 149.859 231.465 149.632L233.896 148.236C233.896 148.236 234.123 148.139 234.22 148.204H234.252L234.933 148.723Z" fill="#00283B" />
        <path d="M232.243 152.553L234.674 151.157C235.192 150.865 235.614 150.054 235.614 149.372C235.614 148.691 235.192 148.366 234.674 148.658L232.243 150.054C231.725 150.346 231.303 151.157 231.303 151.839C231.303 152.521 231.725 152.845 232.243 152.553Z" fill="#F5FCFF" />
        <path d="M231.854 152.942C232.016 152.942 232.211 152.878 232.373 152.78L234.803 151.384C235.419 151.027 235.905 150.119 235.905 149.34C235.905 148.853 235.743 148.496 235.419 148.333C235.16 148.171 234.836 148.204 234.512 148.398L232.081 149.794C231.465 150.151 230.979 151.06 230.979 151.839C230.979 152.326 231.141 152.683 231.465 152.845C231.595 152.91 231.724 152.942 231.854 152.942ZM235.03 148.82C235.03 148.82 235.095 148.82 235.127 148.82C235.225 148.853 235.29 149.015 235.29 149.307C235.29 149.891 234.933 150.605 234.512 150.833L232.081 152.228C232.081 152.228 231.854 152.326 231.757 152.293C231.66 152.261 231.595 152.099 231.595 151.806C231.595 151.222 231.951 150.508 232.373 150.281L234.803 148.885C234.803 148.885 234.933 148.82 235.03 148.82Z" fill="#00283B" />
        <path d="M279.918 122.756C279.756 122.627 279.659 122.367 279.659 122.042C279.659 121.361 280.08 120.549 280.599 120.257L283.029 118.861C283.256 118.732 283.483 118.732 283.645 118.829C283.645 118.829 283.678 118.829 283.71 118.861L284.617 119.543L283.353 121.231L280.761 123.406L279.918 122.789V122.756Z" fill="#B0E2FA" />
        <path d="M280.631 123.633L283.548 121.426L284.844 119.316L283.613 118.439C283.386 118.375 283.094 118.439 282.867 118.602L280.436 119.997C279.821 120.354 279.334 121.263 279.334 122.042C279.334 122.464 279.464 122.821 279.723 123.016L280.631 123.665V123.633ZM284.196 119.575L283.126 121.004L280.761 122.984L280.08 122.497C280.08 122.497 279.95 122.237 279.95 122.042C279.95 121.458 280.307 120.744 280.728 120.517L283.159 119.121C283.159 119.121 283.386 119.024 283.483 119.089H283.515L284.196 119.608V119.575Z" fill="#00283B" />
        <path d="M281.506 123.406L283.937 122.01C284.456 121.718 284.877 120.906 284.877 120.225C284.877 119.543 284.456 119.218 283.937 119.511L281.506 120.906C280.988 121.198 280.566 122.01 280.566 122.691C280.566 123.373 280.988 123.698 281.506 123.406Z" fill="#F5FCFF" />
        <path d="M281.117 123.828C281.279 123.828 281.474 123.763 281.636 123.665L284.067 122.27C284.682 121.913 285.168 121.004 285.168 120.225C285.168 119.738 285.006 119.381 284.682 119.219C284.423 119.056 284.099 119.089 283.775 119.251L281.344 120.647C280.728 121.004 280.242 121.913 280.242 122.692C280.242 123.178 280.404 123.535 280.728 123.698C280.858 123.763 280.988 123.795 281.117 123.795V123.828ZM284.293 119.705C284.293 119.705 284.358 119.705 284.391 119.705C284.488 119.738 284.553 119.9 284.553 120.192C284.553 120.777 284.196 121.491 283.775 121.718L281.344 123.114C281.344 123.114 281.117 123.211 281.02 123.178C280.923 123.146 280.858 122.984 280.858 122.692C280.858 122.107 281.214 121.393 281.636 121.166L284.067 119.77C284.067 119.77 284.196 119.705 284.293 119.705Z" fill="#00283B" />
        <path d="M287.113 100.295L128.239 190.659V118.147L287.113 26.55V100.295Z" fill="#00283B" />
        <path d="M280.533 96.5948L134.721 180.272V121.588L280.631 37.5857L280.533 96.5948Z" fill="#B0E2FA" />
        <path d="M135.045 147.197L243.262 85.0073L266.986 97.9582L134.916 173.91L135.045 147.197Z" fill="#F5FCFF" />
        <path d="M134.429 180.759V121.393L280.955 37.0664L280.858 96.7246L280.696 96.822L134.429 180.759ZM135.013 121.75V179.753L280.242 96.4325L280.339 38.1375L135.013 121.782V121.75Z" fill="#00283B" />
        <path d="M83.6753 92.5375L128.239 118.147L287.113 26.5499L242.808 0.713135L83.6753 92.5375Z" fill="#B0E2FA" />
        <path d="M128.239 190.659L83.6748 165.309V92.5376L128.239 118.147V190.659Z" fill="#B0E2FA" />
        <path d="M83.4805 96.4325L128.044 122.074L128.53 118.342L283.612 28.5623L242.614 4.60815L83.4805 96.4325Z" fill="#F5FCFF" />
        <path d="M287.404 26.3877L242.97 0.453576L242.808 0.356201L83.3833 92.3753V165.471L128.239 191.016L287.404 100.457V26.3877ZM242.808 1.03782L286.497 26.55L128.239 117.823L84.2908 92.5376L242.808 1.03782ZM83.9667 165.114V93.0245L127.915 118.309V190.139L83.9667 165.114ZM286.788 100.1L128.53 190.139V118.309L193.513 80.8526L286.821 27.0693V100.1H286.788Z" fill="#00283B" />
        <path d="M116.798 183.551L116.766 120.906L107.01 134.766L106.978 189.491L116.798 183.551Z" fill="#00283B" />
        <path d="M92.0367 106.43L116.765 120.906L107.01 134.766L82.7998 120.906L92.0367 106.43Z" fill="#B0E2FA" />
        <path d="M106.978 189.491L82.7998 175.274V120.906L107.01 134.766L106.978 189.491Z" fill="#B0E2FA" />
        <path d="M92.0691 111.201L114.367 124.282L107.01 134.766L107.042 139.537L82.7998 125.678L92.0691 111.201Z" fill="#F5FCFF" />
        <path d="M117.057 120.744L91.972 106.04L82.5083 120.841V175.468L106.978 189.847L117.122 183.745L117.057 120.776V120.744ZM92.1341 106.819L116.312 121.004L106.913 134.376L83.1889 120.809L92.1341 106.819ZM83.0917 121.425L106.719 134.928V153.299L106.686 188.971L83.0917 175.111V121.425ZM107.302 134.863L116.442 121.847L116.506 183.388L107.27 188.971V134.863H107.302Z" fill="#00283B" />
        <path d="M150.051 85.9809L130.961 97.0167L111.872 85.9809L130.961 74.9451L150.051 85.9809Z" fill="#B0E2FA" />
        <path d="M130.961 97.0168V101.334L111.872 90.1681V85.981L130.961 97.0168Z" fill="#B0E2FA" />
        <path d="M150.051 87.9608L130.961 98.9966L111.872 87.9608L130.961 76.925L150.051 87.9608Z" fill="#F5FCFF" />
        <path d="M130.961 74.5881L111.58 85.7862V90.3304L130.961 101.658L150.342 90.5576V85.7862L130.961 74.5881ZM130.67 100.782L112.163 89.9733V86.4678L123.086 92.7972L130.67 97.1791V100.814V100.782ZM112.488 85.9485L130.994 75.2698L149.5 85.9485L130.994 96.6273L112.488 85.9485Z" fill="#00283B" />
        <path d="M280.534 96.5948L229.131 67.2201L280.631 37.5857L280.534 96.5948Z" fill="#B0E2FA" />
        <path d="M280.825 97.0817L228.548 67.2201L280.922 37.0664L280.825 97.0817ZM229.747 67.2201L280.242 96.0755L280.339 38.1051L229.747 67.2201Z" fill="#00283B" />
        <path d="M272.431 83.9687L268.542 81.7291C268.153 81.5019 267.796 80.8852 267.796 80.3334V70.6609C267.796 70.1091 268.12 69.8819 268.542 70.1091L272.431 72.3487C272.82 72.5759 273.176 73.1926 273.176 73.7444V83.417C273.176 83.9687 272.852 84.196 272.431 83.9687Z" fill="#F5FCFF" />
        <path d="M271.523 82.6379L267.731 80.4632C267.18 80.1386 266.694 79.3271 266.694 78.678V68.8431C266.694 68.194 267.148 67.9343 267.731 68.2589L271.523 70.4336C272.074 70.7582 272.561 71.5696 272.561 72.2188V82.0536C272.561 82.7028 272.107 82.9625 271.523 82.6379ZM267.731 68.8107C267.44 68.6484 267.18 68.8107 267.18 69.1353V78.9701C267.18 79.3271 267.44 79.7491 267.731 79.9114L271.523 82.0861C271.815 82.2484 272.074 82.1185 272.074 81.7615V71.9267C272.074 71.5696 271.815 71.1477 271.523 70.9854L267.731 68.8107Z" fill="#00283B" />
        <path d="M269.838 74.6534L269.417 74.3937C268.671 73.9717 268.088 72.8357 268.088 71.8295C268.088 70.8558 268.671 70.4013 269.417 70.8233L269.838 71.083C270.583 71.5049 271.167 72.641 271.167 73.6472C271.167 74.6209 270.583 75.0753 269.838 74.6534Z" fill="#B0E2FA" />
        <path d="M269.643 74.8155C268.671 74.2313 267.861 72.8356 267.861 71.6995C267.861 70.5635 268.671 70.1091 269.643 70.6609C270.648 71.2451 271.458 72.6408 271.458 73.7769C271.458 74.9129 270.648 75.3673 269.676 74.8155H269.643ZM269.643 71.2451C268.898 70.8232 268.315 71.1478 268.315 71.9917C268.315 72.8356 268.898 73.8418 269.611 74.2313C270.324 74.6532 270.907 74.3287 270.907 73.4848C270.907 72.6408 270.324 71.6346 269.611 71.2451H269.643Z" fill="#00283B" />
        <path d="M270.875 80.2359L268.347 78.7753C268.185 78.6779 268.055 78.4183 268.055 78.2235V75.5944C268.055 75.3672 268.185 75.2698 268.347 75.3672L270.875 76.8278C270.875 76.8278 271.167 77.1848 271.167 77.3796V80.0087C271.167 80.2359 271.037 80.3333 270.875 80.2359Z" fill="#B0E2FA" />
        <path d="M270.875 80.4957L268.412 79.0675C268.12 78.9053 267.861 78.4508 267.861 78.0938V75.3998C267.861 75.0427 268.12 74.9129 268.412 75.0752L270.875 76.5033C271.167 76.6656 271.426 77.1201 271.426 77.4771V80.1711C271.426 80.5282 271.167 80.658 270.875 80.4957ZM268.412 75.6594C268.412 75.6594 268.347 75.6594 268.347 75.6919V78.3859C268.347 78.3859 268.379 78.4833 268.412 78.5158L270.875 79.9439C270.875 79.9439 270.94 79.9439 270.94 79.9115V77.2174C270.94 77.2174 270.907 77.12 270.875 77.0876L268.412 75.6594Z" fill="#00283B" />
        <path d="M230.46 40.8317L211.37 51.8675L192.281 40.8317L211.37 29.7959L230.46 40.8317Z" fill="#B0E2FA" />
        <path d="M211.37 51.8673V56.1843L192.281 45.0187V40.8315L211.37 51.8673Z" fill="#B0E2FA" />
        <path d="M230.46 42.8114L211.37 53.8472L192.281 42.8114L211.37 31.7756L230.46 42.8114Z" fill="#F5FCFF" />
        <path d="M211.37 29.4712L191.989 40.6693V45.2134L211.37 56.5414L230.752 45.4406V40.6693L211.37 29.4712ZM211.079 55.665L192.573 44.8564V41.3509L203.495 47.6478L211.079 52.0297V55.665ZM192.897 40.8316L211.403 30.1528L229.909 40.8316L211.403 51.5103L192.897 40.8316Z" fill="#00283B" />
        <path d="M257.523 24.6025L238.433 35.6383L219.376 24.6025L238.433 13.5991L257.523 24.6025Z" fill="#B0E2FA" />
        <path d="M238.433 35.6383V39.9553L219.376 28.8221V24.6025L238.433 35.6383Z" fill="#B0E2FA" />
        <path d="M257.523 26.6149L238.433 37.6182L219.376 26.6149L238.433 15.5791L257.523 26.6149Z" fill="#F5FCFF" />
        <path d="M238.433 13.2422L219.052 24.4403V28.9844L238.433 40.3124L257.814 29.2116V24.4403L238.433 13.2422ZM238.141 39.436L219.635 28.6274V25.1219L230.557 31.4513L238.141 35.8331V39.4684V39.436ZM219.959 24.6026L238.465 13.9238L256.971 24.6026L238.465 35.2813L219.959 24.6026Z" fill="#00283B" />
        <path d="M134.98 136.486L238.562 76.5356L238.692 61.7346L135.012 121.62L134.98 136.486Z" fill="#00283B" />
        <path d="M150.634 112.435L150.342 111.915L216.783 74.1663L217.074 74.7181L150.634 112.435Z" fill="#B0E2FA" />
        <path d="M134.721 125.645H134.105V121.231L136.406 119.932L136.698 120.452L134.721 121.588V125.645Z" fill="#B0E2FA" />
        <mask id="mask0_43_17450" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="135" y="37" width="147" height="144">
            <path d="M281.02 96.4651L135.207 180.11L135.013 121.62L281.117 37.4561L281.02 96.4651Z" fill="white" />
        </mask>
        <g mask="url(#mask0_43_17450)">
            <path d="M145.222 126.684V128.761L182.688 150.378V148.366L145.222 126.684Z" fill="#B0E2FA" />
            <path d="M182.688 148.366L145.222 126.684L149.695 124.087L187.161 145.77L182.688 148.366Z" fill="#B0E2FA" />
            <path d="M182.688 148.366L145.967 127.106L149.694 124.964L186.512 146.256L182.688 148.366Z" fill="#F5FCFF" />
            <path d="M187.452 145.932V145.607L187.193 145.445L149.857 123.86L149.727 123.795L145.254 126.392L144.995 126.554V128.956L182.461 150.573L182.72 150.736L182.98 150.573L187.452 147.977V145.964V145.932ZM186.642 145.769L185.572 146.386L182.72 148.041L169.4 140.349L145.773 126.684L149.695 124.412L186.642 145.769ZM145.514 128.599V127.138L182.429 148.496V149.892L145.514 128.599Z" fill="#00283B" />
            <path d="M139.097 129.962V132.039L176.563 153.657V151.644L139.097 129.962Z" fill="#B0E2FA" />
            <path d="M176.563 151.644L139.097 129.962L143.569 127.366L181.035 149.048L176.563 151.644Z" fill="#B0E2FA" />
            <path d="M176.562 151.644L139.842 130.417L143.569 128.242L180.419 149.535L176.562 151.644Z" fill="#F5FCFF" />
            <path d="M181.327 149.21V148.885L181.067 148.723L143.731 127.138L143.601 127.073L139.129 129.67L138.87 129.832V132.234L176.336 153.852L176.595 154.014L176.854 153.852L181.327 151.255V149.242V149.21ZM180.516 149.048L179.447 149.664L176.595 151.32L163.274 143.627L139.647 129.962L143.569 127.69L180.516 149.048ZM139.388 131.877V130.417L176.303 151.774V153.17L139.388 131.877Z" fill="#00283B" />
            <path d="M132.971 133.5V135.61L170.437 157.195V155.182L132.971 133.5Z" fill="#B0E2FA" />
            <path d="M170.437 155.182L132.971 133.5L137.444 130.936L174.942 152.586L170.437 155.182Z" fill="#B0E2FA" />
            <path d="M170.437 155.182L133.716 133.954L137.443 131.78L174.294 153.072L170.437 155.182Z" fill="#F5FCFF" />
            <path d="M175.201 152.748V152.423L174.942 152.261L137.606 130.676L137.476 130.611L133.003 133.208L132.744 133.37V135.772L170.21 157.389L170.469 157.552L170.729 157.389L175.201 154.793V152.78V152.748ZM174.391 152.586L173.321 153.202L170.469 154.858L157.149 147.165L133.522 133.5L137.444 131.228L174.391 152.586ZM133.263 135.415V133.955L170.178 155.312V156.708L133.263 135.415Z" fill="#00283B" />
            <path d="M126.846 137.071V139.148L164.344 160.765V158.753L126.846 137.071Z" fill="#B0E2FA" />
            <path d="M164.344 158.753L126.846 137.071L131.318 134.474L168.817 156.156L164.344 158.753Z" fill="#B0E2FA" />
            <path d="M164.344 158.753L127.591 137.493L131.318 135.35L168.168 156.643L164.344 158.753Z" fill="#F5FCFF" />
            <path d="M169.076 156.318V155.994L168.816 155.832L131.48 134.247L131.351 134.182L126.878 136.779L126.619 136.941V139.343L164.085 160.96L164.344 161.122L164.603 160.96L169.076 158.363V156.351V156.318ZM168.266 156.156L167.196 156.773L164.344 158.428L151.023 150.736L127.396 137.071L131.318 134.799L168.266 156.156ZM127.137 138.986V137.525L164.052 158.883V160.278L127.137 138.986Z" fill="#00283B" />
            <path d="M145.222 120.127V122.237L182.688 143.822V141.81L145.222 120.127Z" fill="#B0E2FA" />
            <path d="M182.688 141.809L145.222 120.127L149.695 117.563L187.161 139.213L182.688 141.809Z" fill="#B0E2FA" />
            <path d="M182.688 141.809L145.967 120.582L149.694 118.407L186.512 139.7L182.688 141.809Z" fill="#F5FCFF" />
            <path d="M187.452 139.375V139.05L187.193 138.888L149.857 117.303L149.727 117.239L145.254 119.835L144.995 119.997V122.399L182.461 144.017L182.72 144.179L182.98 144.017L187.452 141.42V139.408V139.375ZM186.642 139.213L185.572 139.829L182.72 141.485L169.4 133.792L145.773 120.127L149.695 117.855L186.642 139.213ZM145.514 122.042V120.582L182.429 141.939V143.335L145.514 122.042Z" fill="#00283B" />
            <path d="M139.097 123.438V125.515L176.563 147.133V145.088L139.097 123.438Z" fill="#B0E2FA" />
            <path d="M176.563 145.088L139.097 123.438L143.569 120.841L181.035 142.523L176.563 145.088Z" fill="#B0E2FA" />
            <path d="M176.562 145.088L139.842 123.86L143.569 121.685L180.419 143.01L176.562 145.088Z" fill="#F5FCFF" />
            <path d="M181.327 142.653V142.329L181.067 142.166L143.731 120.582L143.601 120.517L139.129 123.114L138.87 123.276V125.678L176.336 147.295L176.595 147.457L176.854 147.295L181.327 144.698V142.686V142.653ZM180.516 142.491L179.447 143.108L176.595 144.763L163.274 137.071L139.647 123.406L143.569 121.134L180.516 142.491ZM139.388 125.321V123.86L176.303 145.218V146.613L139.388 125.321Z" fill="#00283B" />
            <path d="M132.971 126.976V129.053L170.437 150.671V148.658L132.971 126.976Z" fill="#B0E2FA" />
            <path d="M170.437 148.658L132.971 126.976L137.444 124.379L174.942 146.061L170.437 148.658Z" fill="#B0E2FA" />
            <path d="M170.437 148.658L133.716 127.398L137.443 125.256L174.294 146.548L170.437 148.658Z" fill="#F5FCFF" />
            <path d="M175.201 146.224V145.899L174.942 145.737L137.606 124.152L137.476 124.087L133.003 126.684L132.744 126.846V129.248L170.21 150.865L170.469 151.027L170.729 150.865L175.201 148.269V146.256V146.224ZM174.391 146.061L173.321 146.678L170.469 148.333L157.149 140.641L133.522 126.976L137.444 124.704L174.391 146.061ZM133.263 128.891V127.43L170.178 148.788V150.184L133.263 128.891Z" fill="#00283B" />
            <path d="M126.846 130.514V132.624L164.344 154.208V152.196L126.846 130.514Z" fill="#B0E2FA" />
            <path d="M164.344 152.196L126.846 130.514L131.318 127.95L168.817 149.599L164.344 152.196Z" fill="#B0E2FA" />
            <path d="M164.344 152.196L127.591 130.968L131.318 128.794L168.168 150.086L164.344 152.196Z" fill="#F5FCFF" />
            <path d="M169.076 149.762V149.437L168.816 149.275L131.48 127.69L131.351 127.625L126.878 130.222L126.619 130.384V132.786L164.085 154.403L164.344 154.566L164.603 154.403L169.076 151.807V149.794V149.762ZM168.266 149.599L167.196 150.216L164.344 151.872L151.023 144.179L127.396 130.514L131.318 128.242L168.266 149.599ZM127.137 132.429V130.968L164.052 152.326V153.722L127.137 132.429Z" fill="#00283B" />
            <path d="M154.2 152.91V148.366L159.871 151.644V156.156L154.2 152.91Z" fill="#B0E2FA" />
            <path d="M163.793 153.884L160.163 151.774V151.45L153.94 147.847V153.04L160.163 156.61V155.994L163.793 153.884ZM159.612 155.669L154.491 152.748V148.82L159.612 151.774V155.669Z" fill="#00283B" />
            <path d="M130.832 139.343V134.896L136.504 138.206V142.653L130.832 139.343Z" fill="#B0E2FA" />
            <path d="M140.425 140.381L136.795 138.271V138.044L130.573 134.409V139.472L136.795 143.108V142.459L140.425 140.349V140.381ZM136.245 142.166L131.124 139.18V135.383L136.245 138.369V142.166Z" fill="#00283B" />
            <path d="M142.24 145.932V141.485L147.912 144.796V149.242L142.24 145.932Z" fill="#B0E2FA" />
            <path d="M151.834 146.97L148.204 144.861V144.633L141.981 140.998V146.062L148.204 149.697V149.048L151.834 146.938V146.97ZM147.653 148.756L142.532 145.769V141.972L147.653 144.958V148.756Z" fill="#00283B" />
            <path d="M147.62 135.415L137.216 129.378V117.368L147.62 123.373V135.415Z" fill="#B0E2FA" />
            <path d="M158.024 117.368L147.62 123.373L137.216 117.368L147.62 111.331L158.024 117.368Z" fill="#B0E2FA" />
            <path d="M158.024 119.446L149.533 124.314L139.129 118.31L147.62 113.408L158.024 119.446Z" fill="#F5FCFF" />
            <path d="M147.62 110.974L137.216 116.979L136.925 117.174V129.54L147.328 135.545L147.62 135.707L147.912 135.545L158.315 129.54V117.141L147.62 110.942V110.974ZM147.62 111.688L157.408 117.336L152.482 120.192L147.588 123.016L146.064 122.14L137.8 117.368L147.588 111.721L147.62 111.688ZM137.508 129.216V117.888L147.296 123.535V134.863L137.508 129.216Z" fill="#00283B" />
            <path d="M180.549 119.64L163.631 109.87L146.745 119.64V139.18L163.631 148.95V129.475L180.549 119.64Z" fill="#F5FCFF" />
            <path d="M146.745 139.115L163.631 148.885V129.41L146.745 119.575V139.115Z" fill="#B0E2FA" />
            <path d="M180.873 119.446L163.631 109.481L146.453 119.413H146.389V119.835V139.375L163.274 149.145L163.598 149.34L163.922 149.145L180.646 139.472L180.808 139.375V119.835V119.446H180.873ZM163.631 110.26L171.571 114.837L155.334 124.217L147.393 119.64L163.631 110.26ZM163.307 148.366L147.069 138.985V120.225L163.307 129.605V148.366ZM170.534 125.028L163.663 129.021L155.723 124.444L171.96 115.064L179.901 119.64L170.567 125.028H170.534Z" fill="#00283B" />
            <path d="M156.922 129.443L153.778 127.625C153.421 127.43 153.065 127.398 152.806 127.56C152.482 127.755 152.287 128.144 152.287 128.664C152.287 129.508 152.806 130.481 153.454 130.871L156.598 132.689C156.792 132.786 156.987 132.851 157.149 132.851C157.311 132.851 157.44 132.818 157.57 132.754C157.894 132.559 158.089 132.169 158.089 131.65C158.089 130.806 157.57 129.832 156.922 129.443ZM157.246 132.137C157.246 132.137 157.019 132.137 156.922 132.072L153.778 130.254C153.324 129.995 152.968 129.248 152.968 128.631C152.968 128.339 153.065 128.177 153.13 128.112C153.227 128.047 153.357 128.112 153.454 128.177L153.681 128.307C153.746 129.053 154.199 129.8 154.75 130.124L157.44 131.682C157.44 131.942 157.343 132.072 157.278 132.137H157.246Z" fill="#00283B" />
            <path d="M252.467 68.908L235.16 58.9109L217.885 68.908V88.9023L235.16 98.8994V78.9701L252.467 68.908Z" fill="#F5FCFF" />
            <path d="M217.885 88.8375L235.16 98.8347V78.9053L217.885 68.8433V88.8375Z" fill="#B0E2FA" />
            <path d="M252.791 68.7134L235.16 58.5215L217.594 68.6809H217.529V69.1029V89.0971L234.803 99.0943L235.16 99.289L235.516 99.0943L252.629 89.1945L252.791 89.0971V69.1029V68.7134ZM235.16 59.3329L243.295 64.0394L226.701 73.647L218.566 68.9406L235.16 59.3329ZM234.803 98.3153L218.209 88.7076V69.5248L234.803 79.1325V98.3153ZM242.193 74.4585L235.16 78.5158L227.025 73.8093L243.619 64.2017L251.754 68.9081L242.193 74.426V74.4585Z" fill="#00283B" />
            <path d="M228.289 78.9376L225.08 77.0875C224.724 76.8928 224.367 76.8603 224.076 77.0226C223.752 77.2174 223.557 77.6393 223.557 78.1586C223.557 79.035 224.076 80.0088 224.756 80.3983L227.965 82.2484C228.159 82.3458 228.354 82.4107 228.548 82.4107C228.71 82.4107 228.84 82.3782 228.97 82.2808C229.294 82.0861 229.488 81.6641 229.488 81.1448C229.488 80.2684 228.97 79.2947 228.289 78.9052V78.9376ZM228.613 81.6966C228.613 81.6966 228.386 81.6966 228.289 81.6317L225.08 79.7816C224.627 79.5219 224.238 78.7754 224.238 78.1262C224.238 77.8016 224.335 77.6393 224.4 77.6069C224.497 77.5419 224.627 77.6069 224.724 77.6718L224.951 77.8016C225.016 78.5482 225.469 79.3271 226.02 79.6517L228.743 81.2422C228.743 81.5018 228.646 81.6317 228.581 81.6966H228.613Z" fill="#00283B" />
            <path d="M213.639 102.957L202.977 96.7897V84.488L213.639 90.6551V102.957Z" fill="#B0E2FA" />
            <path d="M224.27 84.4879L213.639 90.6549L202.977 84.4879L213.639 78.3533L224.27 84.4879Z" fill="#B0E2FA" />
            <path d="M224.27 86.6302L215.584 91.5963L204.921 85.4617L213.64 80.4631L224.27 86.6302Z" fill="#F5FCFF" />
            <path d="M213.64 77.9639L203.009 84.1309L202.685 84.3257V96.9844L213.316 103.151L213.64 103.346L213.964 103.151L224.594 96.9844V84.3257L213.64 77.9963V77.9639ZM213.64 78.6779L223.654 84.4555L218.631 87.3768L213.64 90.2655L212.084 89.3567L203.625 84.4555L213.64 78.6779ZM203.301 96.5949V85.0073L213.316 90.7849V102.372L203.301 96.5949Z" fill="#00283B" />
            <path d="M211.079 99.2564L209.459 98.3151C209.2 98.1528 208.973 97.7633 208.973 97.4063V95.686C208.973 95.3289 209.167 95.1666 209.459 95.3289L211.079 96.2702C211.339 96.4325 211.565 96.822 211.565 97.179V98.8993C211.565 99.2564 211.371 99.4187 211.079 99.2564Z" fill="#00283B" />
            <path d="M257.782 103.963L247.151 97.7961V85.4944L257.782 91.6614V103.963Z" fill="#B0E2FA" />
            <path d="M268.412 85.4942L257.782 91.6613L247.151 85.4942L257.782 79.3596L268.412 85.4942Z" fill="#B0E2FA" />
            <path d="M268.412 87.6365L259.726 92.6351L249.063 86.4681L257.782 81.4695L268.412 87.6365Z" fill="#F5FCFF" />
            <path d="M257.782 79.0027L247.151 85.1697L246.827 85.3645V98.0232L257.458 104.19L257.782 104.385L258.106 104.19L268.736 98.0232V85.3645L257.782 79.0351V79.0027ZM257.782 79.7168L267.796 85.4943L262.773 88.4156L257.782 91.3044L256.226 90.3955L247.767 85.4943L257.782 79.7168ZM247.443 97.6337V86.0461L257.458 91.8237V103.411L247.443 97.6337Z" fill="#00283B" />
            <path d="M255.221 100.295L253.601 99.3539C253.342 99.1916 253.115 98.8021 253.115 98.4451V96.7248C253.115 96.3677 253.309 96.2055 253.601 96.3677L255.221 97.309C255.481 97.4713 255.708 97.8608 255.708 98.2179V99.9382C255.708 100.295 255.481 100.457 255.221 100.295Z" fill="#00283B" />
        </g>
        <path d="M37.9444 179.655L37.7824 157.162L34.9951 158.817V179.655C34.9951 179.655 35.0275 179.915 35.0275 179.98C35.0275 180.369 35.6757 180.694 36.3887 180.694C37.1018 180.694 37.8796 180.369 37.8796 179.98C37.8796 179.98 37.912 179.818 37.912 179.785V179.623L37.9444 179.655Z" fill="#B0E2FA" />
        <path d="M36.3888 180.986C35.5786 180.986 34.7035 180.597 34.7035 179.98C34.7035 179.98 34.7035 179.98 34.7035 179.915C34.6711 179.818 34.6387 179.753 34.6387 179.688V158.687L38.0741 156.675L38.2362 179.688V179.85C38.2362 179.85 38.2038 179.915 38.1714 179.98C38.1714 180.597 37.2315 180.986 36.3564 180.986H36.3888ZM35.2545 179.688C35.2545 179.688 35.2545 179.688 35.2545 179.753C35.2869 179.85 35.3193 179.915 35.3193 180.013C35.3193 180.142 35.7406 180.402 36.3888 180.402C37.037 180.402 37.5556 180.11 37.588 180.013C37.588 179.948 37.588 179.915 37.6204 179.85V179.688L37.4908 157.746L35.2869 159.044V179.72H35.2545V179.688Z" fill="#00283B" />
        <path d="M31.689 143.042V170.47C31.689 171.8 33.7308 172.904 36.2588 172.904C38.7868 172.904 40.8286 171.833 40.8286 170.47V143.042H31.7214H31.689Z" fill="#B0E2FA" />
        <path d="M36.259 173.164C33.5365 173.164 31.3975 171.963 31.3975 170.437V142.718H41.1205V170.437C41.1205 171.963 38.9814 173.164 36.259 173.164ZM32.0132 143.335V170.437C32.0132 171.573 33.9579 172.547 36.259 172.547C38.5601 172.547 40.5047 171.573 40.5047 170.437V143.335H31.9808H32.0132Z" fill="#00283B" />
        <path d="M40.7964 143.042C40.7964 144.373 38.7545 145.477 36.2265 145.477C33.6986 145.477 31.6567 144.406 31.6567 143.042C31.6567 141.679 33.6662 139.18 36.1941 139.18C38.7221 139.18 40.764 141.712 40.764 143.042H40.7964Z" fill="#B0E2FA" />
        <path d="M36.259 145.737C33.5365 145.737 31.3975 144.536 31.3975 143.01C31.3975 141.485 33.6662 138.823 36.2266 138.823C38.7869 138.823 41.0881 141.42 41.0881 143.01C41.0881 144.601 38.949 145.737 36.2266 145.737H36.259ZM36.259 139.44C33.9254 139.44 32.0132 141.809 32.0132 143.01C32.0132 144.211 33.9579 145.12 36.259 145.12C38.5601 145.12 40.5047 144.146 40.5047 143.01C40.5047 141.809 38.5601 139.44 36.2266 139.44H36.259Z" fill="#00283B" />
        <path d="M39.2081 140.738C39.2081 141.614 37.8793 142.328 36.2263 142.328C34.5734 142.328 33.2446 141.614 33.2446 140.738C33.2446 139.862 34.5734 139.147 36.2263 139.147C37.8793 139.147 39.2081 139.862 39.2081 140.738Z" fill="#B0E2FA" />
        <path d="M39.824 142.004C39.824 143.043 38.236 143.919 36.2589 143.919C34.2819 143.919 32.6938 143.075 32.6938 142.004C32.6938 140.933 34.2819 140.089 36.2589 140.089C38.236 140.089 39.824 140.933 39.824 142.004Z" fill="#F5FCFF" />
        <path d="M36.2265 142.621C34.3792 142.621 32.9531 141.809 32.9531 140.738C32.9531 139.667 34.3792 138.855 36.2265 138.855C38.0739 138.855 39.4999 139.667 39.4999 140.738C39.4999 141.809 38.0739 142.621 36.2265 142.621ZM36.2265 139.472C34.6384 139.472 33.5365 140.154 33.5365 140.738C33.5365 141.322 34.6384 142.004 36.2265 142.004C37.8146 142.004 38.9166 141.322 38.9166 140.738C38.9166 140.154 37.8146 139.472 36.2265 139.472Z" fill="#00283B" />
        <path d="M37.7495 139.992V117.628C37.7495 117.628 37.5874 117.401 37.4578 117.466L34.7354 119.218V139.992C34.7354 139.992 34.8002 140.251 34.8002 140.316C34.8002 140.706 35.4484 141.03 36.1614 141.03C36.8744 141.03 37.6523 140.706 37.6523 140.316C37.6523 140.316 37.6847 140.154 37.6847 140.121V139.959L37.7495 139.992Z" fill="#B0E2FA" />
        <path d="M36.194 141.322C35.3837 141.322 34.5087 140.933 34.5087 140.316C34.5087 140.316 34.5087 140.284 34.5087 140.251C34.4763 140.154 34.4438 140.089 34.4438 140.024V119.088L37.2959 117.238C37.458 117.141 37.62 117.141 37.7821 117.238C37.9441 117.336 38.0414 117.498 38.0414 117.66V140.186C38.0414 140.186 38.0089 140.251 37.9765 140.316C37.9765 140.933 37.0367 141.322 36.1616 141.322H36.194ZM35.0596 140.024C35.0596 140.024 35.0596 140.024 35.0596 140.089C35.092 140.186 35.1245 140.284 35.1245 140.349C35.1245 140.478 35.5458 140.738 36.194 140.738C36.8422 140.738 37.3607 140.446 37.3932 140.349C37.3932 140.284 37.3932 140.251 37.4256 140.154V139.992V117.887L35.0272 119.445V140.056L35.0596 140.024Z" fill="#00283B" />
        <path d="M37.5555 116.524C38.0092 116.914 37.7823 117.887 37.0693 118.731C36.3563 119.575 35.4164 119.965 34.9627 119.575C34.5089 119.186 34.7358 118.212 35.4488 117.368C36.1618 116.524 37.1017 116.135 37.5555 116.524Z" fill="#B0E2FA" />
        <path d="M37.5227 117.368C37.9764 117.758 37.393 118.147 36.68 118.991C35.967 119.835 35.6753 120.127 35.2215 119.77C34.7678 119.381 34.703 119.088 35.416 118.245C36.129 117.401 37.0689 117.011 37.5227 117.401V117.368Z" fill="#F5FCFF" />
        <path d="M35.4162 120.062C35.1569 120.062 34.9625 119.997 34.8004 119.835C34.5087 119.575 34.3791 119.153 34.5087 118.602C34.606 118.147 34.8653 117.628 35.2542 117.173C36.0968 116.167 37.1988 115.778 37.7822 116.297C38.0738 116.557 38.2035 116.979 38.0738 117.53C37.9766 117.985 37.7173 118.504 37.3284 118.959C36.9395 119.413 36.5182 119.738 36.0644 119.932C35.8376 120.03 35.6431 120.062 35.4486 120.062H35.4162ZM37.1015 116.687C36.745 116.687 36.1617 117.011 35.6755 117.563C35.3838 117.92 35.1569 118.342 35.0597 118.731C34.9949 119.024 35.0597 119.251 35.1569 119.381C35.2866 119.478 35.5135 119.478 35.8051 119.381C36.1617 119.251 36.5182 118.959 36.8423 118.569C37.134 118.212 37.3608 117.79 37.4581 117.401C37.5229 117.108 37.4581 116.881 37.3608 116.751C37.3608 116.751 37.1988 116.687 37.1015 116.687Z" fill="#00283B" />
        <path d="M36.0321 119.9L35.9673 140.738L37.523 140.608L37.685 139.667V117.952L36.0321 119.9Z" fill="#00283B" />
        <path d="M36.2588 173.164V180.824L37.9117 179.98V172.547L36.2588 173.164Z" fill="#00283B" />
        <path d="M34.9626 145.347V172.58L38.1388 172.872L40.5371 171.314L40.8288 170.47V143.757C40.8288 143.757 38.5277 145.509 38.2684 145.477C38.0091 145.444 36.259 145.477 36.259 145.477L34.6709 145.412H34.9626V145.347Z" fill="#00283B" />
        <path d="M36.2588 173.164V172.547C36.2588 172.547 37.2311 172.515 38.0413 172.255L38.2034 172.839C37.3283 173.099 36.2912 173.164 36.2588 173.164Z" fill="#B0E2FA" />
        <path d="M76.9661 238.275L60.437 228.667C60.437 228.667 68.0534 224.448 69.0905 224.058C70.1276 223.669 75.4428 221.851 76.2531 223.409L77.0633 224.967L76.9985 238.275H76.9661Z" fill="#B0E2FA" />
        <path d="M77.2579 238.47L76.9662 238.632L59.8213 228.7L60.275 228.44C60.5991 228.278 67.9238 224.188 68.9933 223.799C70.0953 223.377 75.5725 221.462 76.5124 223.312L77.3551 224.935L77.2903 238.47H77.2579ZM61.0205 228.7L76.6421 237.756L76.7069 225.032L75.9615 223.539C75.4429 222.533 71.6834 223.377 69.1878 224.318C68.3127 224.643 62.7058 227.759 61.0205 228.668V228.7Z" fill="#00283B" />
        <path d="M69.3822 240.677L72.4612 233.341L66.2709 229.544C66.0116 229.349 65.7523 229.187 65.4606 229.057C64.9745 228.797 64.4235 228.635 63.8725 228.537C59.2703 227.758 53.8903 232.53 52.6587 238.989C51.7188 243.955 53.9227 248.305 55.5756 250.155L56.2562 250.252C56.2562 250.252 56.5803 250.285 56.5803 250.317L62.7382 254.05C63.1271 254.277 63.6457 254.115 63.8077 253.693L66.8218 246.649C67.9562 245.026 68.8637 243.079 69.3174 240.904C69.3174 240.839 69.3498 240.774 69.3822 240.677Z" fill="#B0E2FA" />
        <path d="M63.0946 254.439C62.9001 254.439 62.7056 254.374 62.5436 254.277L56.4181 250.577C56.4181 250.577 56.3533 250.577 56.3209 250.577C56.2236 250.577 56.1588 250.577 56.1264 250.577L55.2189 250.447V250.285C53.7281 248.564 51.3297 244.15 52.302 238.957C53.5336 232.433 58.9461 227.434 63.8724 228.245C64.4558 228.343 65.0392 228.538 65.5577 228.797C65.8494 228.96 66.1411 229.122 66.4328 229.317L72.85 233.244L69.609 241.002C69.1876 243.046 68.3126 245.059 67.0486 246.844L64.0669 253.823C63.9372 254.115 63.7103 254.309 63.4187 254.407C63.3214 254.439 63.1918 254.472 63.0946 254.472V254.439ZM56.3209 249.96C56.3209 249.96 56.3857 249.96 56.4181 249.96C56.5801 249.96 56.645 249.96 56.7098 250.025L62.8677 253.758C62.8677 253.758 63.127 253.855 63.2242 253.79C63.3538 253.758 63.4511 253.66 63.4835 253.53L66.4976 246.487C67.7292 244.734 68.5718 242.787 68.9932 240.807L69.0904 240.547L72.0721 233.471L66.0763 229.803C65.7846 229.609 65.5577 229.446 65.2984 229.317C64.7799 229.057 64.2937 228.895 63.7428 228.797C59.2054 228.018 54.0522 232.887 52.8854 239.022C51.9455 243.988 54.2466 248.24 55.6727 249.863L56.2885 249.96H56.3209Z" fill="#00283B" />
        <path d="M74.8594 235.289C73.7575 233.634 72.2666 232.595 70.4841 232.303C65.7522 231.491 60.6962 236.393 59.4322 243.014C58.7192 246.779 59.335 250.317 61.1176 252.719C62.2843 254.277 63.84 255.218 65.5901 255.413C65.817 255.413 66.0439 255.445 66.2708 255.445C70.5165 255.445 74.7622 251.161 76.1234 245.351C76.9985 241.586 76.5123 237.821 74.827 235.256L74.8594 235.289Z" fill="#00283B" />
        <path d="M73.2712 245.156C72.3638 249.084 69.4793 251.972 66.7892 251.615C64.0344 251.291 62.4139 247.785 63.2241 243.728C64.0344 239.573 67.0809 236.522 69.9006 236.977C72.6879 237.431 74.1787 241.099 73.2712 245.124V245.156Z" fill="#B0E2FA" />
        <path d="M67.2106 251.972C67.2106 251.972 66.8865 251.972 66.7569 251.972C65.6549 251.843 64.6826 251.258 63.9696 250.252C62.8353 248.662 62.4463 246.227 62.9325 243.76C63.8076 239.443 66.9513 236.263 69.9655 236.782C71.0674 236.944 72.0073 237.593 72.7203 238.632C73.8546 240.287 74.1788 242.787 73.5954 245.318C72.7203 249.213 69.9655 252.037 67.243 252.037L67.2106 251.972ZM69.3497 237.269C66.8541 237.269 64.2613 240.125 63.5159 243.825C63.0621 246.162 63.4186 248.402 64.4557 249.863C65.0715 250.707 65.8818 251.226 66.8217 251.356C69.3821 251.68 72.1369 248.856 72.9796 245.124C73.4981 242.754 73.2064 240.45 72.2017 238.924C71.6184 238.048 70.8081 237.496 69.8682 237.334C69.7062 237.334 69.5441 237.301 69.3497 237.301V237.269Z" fill="#00283B" />
        <path d="M66.7243 249.181C66.7243 249.181 66.5298 249.181 66.4326 249.181C65.7196 249.084 65.1038 248.694 64.6176 248.077C63.8722 247.071 63.6453 245.513 63.937 243.955C64.488 241.196 66.4974 239.216 68.442 239.541C69.155 239.638 69.7708 240.06 70.2246 240.742C70.9376 241.813 71.1645 243.371 70.7755 244.961C70.2246 247.428 68.4744 249.246 66.7243 249.246V249.181ZM68.0531 240.06C66.5622 240.06 65.0066 241.781 64.5528 244.02C64.2611 245.448 64.488 246.812 65.1038 247.688C65.4603 248.207 65.9464 248.499 66.4974 248.564C68.0207 248.759 69.6736 247.039 70.1922 244.767C70.5163 243.339 70.3542 241.943 69.706 241.002C69.3495 240.482 68.8633 240.158 68.3448 240.06C68.2476 240.06 68.1503 240.06 68.0531 240.06Z" fill="#00283B" />
        <path d="M70.7435 245.221C70.2897 246.941 68.9933 248.175 67.7941 247.98C66.595 247.785 65.9144 246.227 66.3357 244.475C66.757 242.657 68.1182 241.359 69.3822 241.586C70.6138 241.813 71.1972 243.468 70.7435 245.221Z" fill="#B0E2FA" />
        <path d="M69.9332 244.799C69.4471 246.682 67.9886 248.045 66.6922 247.85C65.3634 247.656 64.618 245.935 65.0717 243.988C65.5255 241.975 67.0488 240.547 68.41 240.807C69.7712 241.067 70.4194 242.884 69.9332 244.799Z" fill="#F5FCFF" />
        <path d="M68.0536 248.305C68.0536 248.305 67.8592 248.305 67.7619 248.305C67.2434 248.207 66.7896 247.915 66.4331 247.428C65.9146 246.682 65.7849 245.546 66.0442 244.41C66.4979 242.43 68.0212 241.034 69.4472 241.294C69.9658 241.391 70.4195 241.716 70.7436 242.235C71.2298 243.014 71.3594 244.183 71.0677 245.319C70.614 247.071 69.3176 248.305 68.0536 248.305ZM69.0907 241.846C68.086 241.846 66.9841 243.014 66.6276 244.507C66.4007 245.481 66.5303 246.422 66.9193 247.039C67.1461 247.396 67.4702 247.591 67.8267 247.656C68.8639 247.818 70.0306 246.682 70.452 245.124C70.7112 244.15 70.614 243.176 70.2251 242.527C69.9982 242.17 69.6741 241.943 69.3176 241.878C69.2528 241.878 69.1556 241.878 69.0583 241.878L69.0907 241.846Z" fill="#00283B" />
        <path d="M6.4095 186.537L5.40479 185.985C5.40479 185.271 6.99288 184.557 7.7059 184.979L9.39122 185.985C10.1042 186.407 10.6876 187.316 10.6876 188.03V189.068C10.6876 189.782 9.3264 190.886 8.61338 190.464L7.7059 188.614C6.99288 188.192 6.4095 187.283 6.4095 186.569V186.537Z" fill="#B0E2FA" />
        <path d="M9.0023 190.854C8.80784 190.854 8.64579 190.821 8.48374 190.724H8.41892L7.51144 188.809C6.79842 188.354 6.21504 187.478 6.15022 186.731L5.14551 186.147V185.985C5.14551 185.595 5.4372 185.206 5.95576 184.914C6.50673 184.589 7.34939 184.394 7.90036 184.719L9.58568 185.725C10.3959 186.18 11.0441 187.218 11.0441 188.03V189.068C11.0441 189.653 10.4932 190.269 9.90978 190.594C9.61809 190.756 9.29399 190.854 9.03471 190.854H9.0023ZM8.87266 190.237C9.06712 190.302 9.3264 190.237 9.58568 190.075C10.0718 189.783 10.4283 189.328 10.4283 189.068V188.03C10.4283 187.413 9.90978 186.602 9.26158 186.245L7.57626 185.238C7.28457 185.076 7.25216 185.303 6.76601 185.563C6.53914 185.693 5.82612 185.725 5.7613 185.855L6.7336 186.407V186.569C6.7336 187.186 7.25216 187.997 7.90036 188.354H7.96518L8.87266 190.237Z" fill="#00283B" />
        <path d="M6.4095 182.187L5.40479 174.137C5.40479 173.423 6.99288 172.774 7.7059 173.196L9.39122 174.202C10.1042 174.624 10.6876 175.533 10.6876 176.247V184.686C10.6876 185.4 9.48845 186.537 8.77543 186.115L7.73831 184.232C7.02529 183.81 6.44191 182.901 6.44191 182.187H6.4095Z" fill="#F5FCFF" />
        <path d="M9.13194 186.537C8.93748 186.537 8.77543 186.504 8.64579 186.407H8.58097L7.54385 184.492C6.76601 184.005 6.18263 183.031 6.15022 182.252L5.14551 174.202C5.14551 173.781 5.40479 173.423 5.89094 173.164C6.47432 172.839 7.34939 172.644 7.90036 172.969L9.58568 173.975C10.3959 174.43 11.0441 175.468 11.0441 176.28V184.719C11.0441 185.336 10.4607 185.985 9.94219 186.309C9.68291 186.472 9.42363 186.537 9.16435 186.537H9.13194ZM9.0023 185.92C9.22917 185.985 9.48845 185.855 9.58568 185.79C10.0718 185.498 10.3959 185.011 10.3959 184.719V176.28C10.3959 175.663 9.87737 174.852 9.22917 174.495L7.54385 173.488C7.25216 173.326 6.60396 173.391 6.15022 173.683C5.89094 173.845 5.69648 174.04 5.69648 174.17L6.70119 182.22C6.70119 182.836 7.21975 183.648 7.86795 184.005H7.93277L8.96989 185.92H9.0023Z" fill="#00283B" />
        <path d="M5.24268 187.283V186.18C5.24268 185.465 5.82606 185.206 6.53908 185.628L8.2244 186.634C8.93742 187.056 9.5208 187.965 9.5208 188.679V189.782C9.5208 190.497 8.93742 190.756 8.2244 190.334L6.53908 189.328C5.82606 188.906 5.24268 187.997 5.24268 187.283Z" fill="#B0E2FA" />
        <path d="M8.87248 190.854C8.6132 190.854 8.35392 190.756 8.06223 190.626L6.37691 189.62C5.56666 189.166 4.91846 188.127 4.91846 187.316V186.212C4.91846 185.79 5.08051 185.465 5.3722 185.271C5.72871 185.076 6.18245 185.108 6.6686 185.401L8.35392 186.407C9.16417 186.861 9.81237 187.9 9.81237 188.711V189.815C9.81237 190.237 9.65032 190.561 9.35863 190.756C9.22899 190.854 9.06694 190.886 8.87248 190.886V190.854ZM5.53425 187.283C5.53425 187.9 6.05281 188.711 6.70101 189.068L8.38633 190.075C8.64561 190.237 8.90489 190.269 9.06694 190.204C9.19658 190.107 9.22899 189.912 9.22899 189.782V188.679C9.22899 188.062 8.71043 187.251 8.06223 186.894L6.37691 185.887C6.11763 185.725 5.85835 185.693 5.6963 185.758C5.56666 185.855 5.53425 186.05 5.53425 186.18V187.283Z" fill="#00283B" />
        <path d="M5.24268 182.869V174.43C5.24268 173.716 5.82606 173.456 6.53908 173.878L8.2244 174.884C8.93742 175.306 9.5208 176.215 9.5208 176.929V185.368C9.5208 186.082 8.93742 186.342 8.2244 185.92L6.53908 184.914C5.82606 184.492 5.24268 183.583 5.24268 182.869Z" fill="#B0E2FA" />
        <path d="M8.87248 186.439C8.6132 186.439 8.35392 186.342 8.06223 186.212L6.37691 185.206C5.56666 184.751 4.91846 183.713 4.91846 182.901V174.462C4.91846 174.04 5.08051 173.716 5.3722 173.521C5.72871 173.326 6.18245 173.359 6.6686 173.651L8.35392 174.657C9.16417 175.111 9.81237 176.15 9.81237 176.962V185.401C9.81237 185.823 9.65032 186.147 9.35863 186.342C9.22899 186.439 9.06694 186.472 8.87248 186.472V186.439ZM5.53425 182.869C5.53425 183.486 6.05281 184.297 6.70101 184.654L8.38633 185.66C8.64561 185.823 8.90489 185.855 9.06694 185.79C9.19658 185.693 9.22899 185.498 9.22899 185.368V176.929C9.22899 176.312 8.71043 175.501 8.06223 175.144L6.37691 174.138C6.11763 173.975 5.85835 173.943 5.6963 174.008C5.56666 174.105 5.53425 174.3 5.53425 174.43V182.869Z" fill="#00283B" />
        <path d="M84.647 163.621L72.3636 177.611L47.8292 189.782L47.6672 189.847L45.3013 224.35L49.7414 254.439L56.4503 250.609V250.414C56.4503 250.414 55.8993 238.535 61.8952 232.303C64.488 229.609 69.9329 226.298 73.4332 227.694C75.5398 228.538 76.6093 230.972 76.6093 234.899V237.821L76.6742 238.437L76.9658 238.6L90.967 230.68V160.051L90.6753 159.888L84.6794 163.589L84.647 163.621Z" fill="#00283B" />
        <path d="M9.13199 167.062L6.08545 201.889V228.31L50.1306 253.823L50.4872 226.266L50.1955 191.081L9.13199 167.062Z" fill="#B0E2FA" />
        <path d="M50.4222 254.342L49.9685 254.082L5.76123 228.505V201.889L8.80777 166.899L9.09946 166.737L50.487 190.886V191.048L50.7463 226.233L50.3898 254.309L50.4222 254.342ZM6.37702 228.148L49.8388 253.303L50.1953 226.265L49.9361 191.243L9.39115 167.581L6.37702 201.922V228.148Z" fill="#00283B" />
        <path d="M14.026 229.609L48.6074 249.895C48.6074 249.895 52.1077 251.226 54.0199 249.895C55.9321 248.564 57.4554 249.538 57.423 251.421C57.3581 253.271 56.9368 254.407 54.7005 255.413C52.4642 256.419 50.3576 256.646 48.2185 255.413C46.1119 254.18 3.58995 229.252 3.58995 229.252C3.58995 229.252 0.34895 227.077 1.58053 224.318C2.81211 221.559 6.08552 220.812 6.08552 220.812V224.967L14.026 229.641V229.609Z" fill="#00283B" />
        <path d="M51.2326 250.479C49.839 250.382 48.6074 249.928 48.6074 249.928L14.026 229.641L6.08551 224.967V220.812C6.08551 220.812 4.46501 221.202 3.07138 222.403C3.00656 222.533 2.94174 222.63 2.87692 222.76C1.64534 225.519 4.88634 227.694 4.88634 227.694C4.88634 227.694 41.8337 249.7 43.9404 250.934C46.047 252.167 48.1861 251.94 50.4224 250.934C50.7141 250.804 51.0058 250.642 51.2326 250.512V250.479Z" fill="#F5FCFF" />
        <path d="M51.2325 256.549C50.1306 256.549 49.061 256.257 48.0563 255.673C45.9497 254.472 3.84909 229.771 3.42776 229.511C3.36294 229.479 -0.00770146 227.142 1.2887 224.221C2.55269 221.364 5.85851 220.553 5.98815 220.52L6.34466 220.423V224.805L48.7369 249.668C48.7369 249.668 52.0752 250.901 53.8253 249.668C54.83 248.986 55.8347 248.824 56.6126 249.246C57.3256 249.635 57.7145 250.447 57.6821 251.453C57.6173 253.401 57.1635 254.634 54.7652 255.705C53.4688 256.289 52.302 256.582 51.2001 256.582L51.2325 256.549ZM5.76128 221.202C4.88621 221.494 2.74715 222.403 1.83967 224.448C0.737729 226.915 3.62222 228.927 3.75186 228.992C4.17319 229.219 46.2738 253.92 48.348 255.121C50.1954 256.192 52.1724 256.192 54.5383 255.121C56.645 254.18 57.0339 253.173 57.0987 251.388C57.0987 250.609 56.8394 249.993 56.3209 249.733C55.7699 249.441 54.9597 249.571 54.1494 250.122C52.14 251.518 48.6397 250.22 48.4777 250.155L5.72887 225.097V221.17L5.76128 221.202Z" fill="#00283B" />
        <path d="M50.1953 191.081L73.0444 177.676L31.5596 154.273L9.13184 167.062L50.1953 191.081Z" fill="#B0E2FA" />
        <path d="M49.936 193.548L49.9684 190.984L70.7108 179.039L31.5596 156.967L9.13184 169.756L49.936 193.548Z" fill="#F5FCFF" />
        <path d="M31.5596 154.273L42.3197 139.375L85.2954 163.718L73.0444 177.675L31.5596 154.273Z" fill="#B0E2FA" />
        <path d="M47.9916 136.324L42.3198 139.375L85.2955 163.718L90.6431 160.278L47.9916 136.324Z" fill="#B0E2FA" />
        <path d="M47.9916 139.148L42.3198 142.199L82.7675 165.082L88.1152 161.641L47.9916 139.148Z" fill="#F5FCFF" />
        <path d="M73.5307 177.611L85.5224 163.946L90.9673 160.44V160.083L47.9916 135.967L42.1254 139.148L31.3653 154.046L8.84033 166.899V167.257L50.0659 191.341L50.2279 191.438L73.077 178.065H73.1418L73.2714 177.968L73.6927 177.741L73.5631 177.676L73.5307 177.611ZM42.9357 139.407L47.9916 136.681L90.0922 160.31L85.3279 163.394L71.5213 155.604L42.9681 139.44L42.9357 139.407ZM57.2933 148.204L84.8418 163.784L72.9797 177.286L31.9811 154.143L42.3847 139.764L57.2609 148.171L57.2933 148.204ZM50.2279 190.724L9.74781 167.094L31.5922 154.63L72.4936 177.708L50.2603 190.724H50.2279Z" fill="#00283B" />
        <path d="M99.9446 229.479V223.15C99.9446 221.851 98.713 220.196 97.4166 219.449L93.9164 217.632C92.782 217.015 92.231 217.145 91.518 217.502L90.9346 217.859L78.8457 225.454L84.874 239.768C85.0036 240.028 85.3277 240.158 85.5546 239.995L98.7454 232.205C98.7454 232.205 99.4585 231.784 99.4909 231.751L99.8798 230.875V229.479H99.9446Z" fill="#B0E2FA" />
        <path d="M99.8797 231.167V224.837C99.8797 223.539 98.6481 221.884 97.3517 221.137L93.8514 219.319C92.7171 218.703 92.1661 218.833 91.4531 219.19L90.8697 219.547L78.7808 227.142L84.5173 239.736C84.647 239.995 84.9711 240.125 85.1979 239.963L97.8378 232.335C97.8378 232.335 98.5509 231.913 98.5833 231.881L98.9722 231.004L99.3287 231.264C99.3287 231.037 99.8473 231.654 99.8473 231.134L99.8797 231.167Z" fill="#F5FCFF" />
        <path d="M85.3277 240.352C85.3277 240.352 85.1656 240.352 85.0684 240.32C84.8415 240.255 84.6795 240.093 84.5822 239.866L78.4243 225.324L91.3235 217.242C92.1662 216.82 92.8144 216.723 94.0135 217.372L97.4814 219.19C98.7778 219.936 100.171 221.656 100.171 223.15V230.972L99.6853 231.946L99.426 232.108C98.9074 232.433 98.875 232.465 98.8426 232.465L85.6842 240.255C85.6842 240.255 85.4249 240.352 85.2952 240.352H85.3277ZM79.2022 225.551L85.1332 239.638L85.2304 239.736C85.2304 239.736 85.3277 239.736 85.3925 239.736L98.5833 231.946C98.5833 231.946 98.9399 231.718 99.1343 231.621C99.1667 231.621 99.2315 231.556 99.264 231.556L99.5881 230.842V223.214C99.5881 222.111 98.4861 220.52 97.2221 219.774L93.7543 217.956C92.6847 217.404 92.231 217.534 91.6476 217.826L91.0642 218.183L79.1698 225.616L79.2022 225.551Z" fill="#00283B" />
        <path d="M78.1978 234.413V226.623C78.1978 225.324 79.2349 224.87 80.5313 225.616L83.5454 227.434C84.8418 228.181 85.8789 229.836 85.8789 231.134V238.924C85.8789 240.223 84.8418 240.677 83.5454 239.93L80.5313 238.113C79.2349 237.366 78.1978 235.711 78.1978 234.413Z" fill="#B0E2FA" />
        <path d="M84.712 240.58C84.2907 240.58 83.8694 240.45 83.3832 240.19L80.3691 238.372C78.9755 237.561 77.8735 235.841 77.8735 234.413V226.623C77.8735 225.941 78.1328 225.422 78.5866 225.162C79.1375 224.837 79.883 224.935 80.6608 225.357L83.6749 227.174C85.0686 227.986 86.1705 229.706 86.1705 231.134V238.924C86.1705 239.606 85.9112 240.125 85.4575 240.385C85.2306 240.515 84.9713 240.58 84.712 240.58ZM78.4893 234.413C78.4893 235.614 79.4616 237.139 80.6932 237.853L83.7073 239.671C84.2907 239.995 84.8093 240.093 85.1658 239.866C85.5223 239.671 85.5871 239.249 85.5871 238.924V231.134C85.5871 229.933 84.6148 228.408 83.3832 227.694L80.3691 225.876C79.7857 225.551 79.2672 225.454 78.9107 225.681C78.5542 225.876 78.4893 226.298 78.4893 226.623V234.413Z" fill="#00283B" />
        <path d="M68.5069 221.721C68.2152 221.721 67.9559 221.656 67.729 221.559C67.2753 221.364 66.9836 220.975 66.854 220.455C66.4975 219.06 67.8587 218.086 69.2199 217.307C70.2246 216.755 71.3265 216.658 72.0396 217.015C72.4609 217.242 72.7202 217.632 72.785 218.118C72.8822 219.19 72.234 220.228 70.97 220.91C69.9977 221.429 69.1551 221.721 68.5069 221.721ZM71.0673 217.404C70.6135 217.404 70.0626 217.567 69.544 217.826C67.8911 218.768 67.2429 219.482 67.4698 220.293C67.567 220.65 67.729 220.877 67.9883 220.975C68.5393 221.234 69.5116 220.975 70.6783 220.358C71.2941 220.033 72.2988 219.287 72.1692 218.151C72.1692 217.859 72.0072 217.664 71.7479 217.534C71.5534 217.437 71.2941 217.372 71.0349 217.372L71.0673 217.404Z" fill="#B0E2FA" />
        <path d="M68.9931 217.826C68.9931 217.826 68.3125 219.644 68.9931 220.098C69.6737 220.553 73.3684 218.67 73.7249 217.794C74.0815 216.917 74.6972 216.56 74.5352 217.794C74.3731 219.06 71.4238 221.007 69.8033 221.527C67.1133 222.403 65.428 220.91 66.6271 219.417C67.8263 217.924 68.9607 217.761 68.9607 217.761L68.9931 217.826Z" fill="#00283B" />
        <path d="M68.3126 222.143C67.2755 222.143 66.53 221.754 66.2059 221.267C65.817 220.683 65.9142 219.969 66.4328 219.319C67.6968 217.761 68.9284 217.567 68.9608 217.567L69.4469 217.502L69.2849 217.989C69.0256 218.67 68.8636 219.709 69.1877 219.904C69.2525 219.904 69.5118 219.969 70.2248 219.709C71.6508 219.222 73.3037 218.151 73.4982 217.729C73.7251 217.177 74.114 216.658 74.5353 216.755C75.0215 216.885 74.9242 217.632 74.8918 217.891C74.7298 219.352 71.5536 221.364 69.9655 221.884C69.3821 222.078 68.8311 222.143 68.3774 222.143H68.3126ZM68.5395 218.313C68.1181 218.508 67.5347 218.897 66.8865 219.676C66.53 220.131 66.4652 220.553 66.6921 220.91C67.0486 221.462 68.1505 221.786 69.7062 221.299C71.2619 220.813 74.0816 218.865 74.2112 217.794C74.2112 217.632 74.2112 217.534 74.2112 217.469C74.1464 217.567 74.0492 217.729 73.9519 217.924C73.6278 218.735 71.3915 219.936 70.0951 220.326C69.4469 220.52 69.0256 220.52 68.7663 220.326C68.1829 219.936 68.3126 218.93 68.4746 218.281L68.5395 218.313Z" fill="#B0E2FA" />
        <path d="M51.8809 217.859V192.899L70.5166 182.447L70.7111 207.537L51.9133 217.859H51.8809ZM52.4967 193.256V216.853L70.0953 207.18L69.9332 183.486L52.4967 193.256Z" fill="#B0E2FA" />
        <path d="M51.8809 217.859V214.418L52.0429 214.321L67.2756 205.914L67.1136 184.33L70.5166 182.415L70.7111 207.505L51.9133 217.826L51.8809 217.859ZM52.4967 214.775V216.853L70.0953 207.18L69.9332 183.486L67.7294 184.719L67.8914 206.304L52.4967 214.775Z" fill="#B0E2FA" />
        <path d="M55.5755 213.769L54.5708 213.217C54.5708 212.503 56.1589 211.789 56.8719 212.211L58.5572 213.217C59.2702 213.639 59.8536 214.548 59.8536 215.262V216.301C59.8536 217.015 58.4924 218.118 57.7794 217.696L56.8719 215.846C56.1589 215.424 55.5755 214.516 55.5755 213.802V213.769Z" fill="#00283B" />
        <path d="M58.1361 218.086C57.9416 218.086 57.7796 218.054 57.6175 217.956H57.5527L56.6452 216.041C55.9322 215.587 55.3488 214.71 55.284 213.964L54.2793 213.38V213.217C54.2793 212.828 54.571 212.438 55.0895 212.146C55.6405 211.822 56.4832 211.627 57.0341 211.951L58.7195 212.958C59.5297 213.412 60.1779 214.451 60.1779 215.262V216.301C60.1779 216.885 59.6269 217.502 59.0436 217.826C58.7519 218.021 58.4278 218.086 58.1685 218.086H58.1361ZM58.0064 217.469C58.2009 217.534 58.4602 217.469 58.7195 217.307C59.2056 217.015 59.5621 216.561 59.5621 216.301V215.262C59.5621 214.646 59.0436 213.834 58.3954 213.477L56.71 212.471C56.4184 212.309 55.835 212.406 55.3488 212.666C55.122 212.795 54.9599 212.925 54.8951 213.055L55.8674 213.607V213.769C55.8674 214.386 56.3859 215.197 57.0341 215.554H57.099L58.0064 217.437V217.469Z" fill="#B0E2FA" />
        <path d="M55.4783 209.744L54.4736 201.695C54.4736 200.981 56.0617 200.331 56.7747 200.753L58.4601 201.76C59.1731 202.181 59.7565 203.09 59.7565 203.804V212.244C59.7565 212.958 58.5573 214.094 57.8443 213.672L56.8072 211.789C56.0941 211.367 55.5108 210.458 55.5108 209.744H55.4783Z" fill="#00283B" />
        <path d="M58.2008 214.094C58.0063 214.094 57.8443 214.061 57.7146 213.964H57.6498L56.6127 212.049C55.8349 211.562 55.2515 210.588 55.2191 209.809L54.2144 201.76C54.2144 201.338 54.4736 200.981 54.9598 200.721C55.5432 200.396 56.4182 200.202 56.9692 200.526L58.6545 201.532C59.4648 201.987 60.113 203.025 60.113 203.837V212.276C60.113 212.893 59.5296 213.542 59.011 213.867C58.7518 214.029 58.4925 214.094 58.2332 214.094H58.2008ZM58.0711 213.477C58.2656 213.542 58.5249 213.445 58.6545 213.347C59.1407 213.055 59.4648 212.568 59.4648 212.276V203.837C59.4648 203.22 58.9462 202.409 58.298 202.052L56.6127 201.046C56.321 200.883 55.6728 200.948 55.2191 201.24C54.9598 201.403 54.7653 201.597 54.7653 201.727L55.77 209.777C55.77 210.393 56.2886 211.205 56.9368 211.562H57.0016L58.0387 213.477H58.0711Z" fill="#B0E2FA" />
        <path d="M55.2515 208.024C55.2515 208.024 54.9274 207.992 54.7977 207.894L52.3022 206.271C51.9133 206.012 51.816 205.492 52.0429 205.103C52.1725 204.908 52.367 204.778 52.5939 204.746C52.8207 204.713 53.0476 204.746 53.2421 204.876L55.7376 206.499C55.9321 206.628 56.0617 206.823 56.0941 207.018C56.1266 207.245 56.0941 207.472 55.9645 207.667C55.8025 207.927 55.5432 208.057 55.2515 208.057V208.024ZM55.1219 207.375C55.1219 207.375 55.3811 207.407 55.4459 207.31C55.4784 207.245 55.4784 207.18 55.4784 207.115C55.4784 207.05 55.4135 206.985 55.3811 206.953L52.8856 205.33C52.8856 205.33 52.7559 205.265 52.6911 205.298C52.6263 205.298 52.5615 205.363 52.529 205.395C52.4642 205.492 52.4966 205.655 52.5939 205.72L55.0894 207.342L55.1219 207.375Z" fill="#B0E2FA" />
        <path d="M54.4092 214.516V213.412C54.4092 212.698 54.9926 212.438 55.7056 212.86L57.3909 213.867C58.1039 214.289 58.6873 215.197 58.6873 215.912V217.015C58.6873 217.729 58.1039 217.989 57.3909 217.567L55.7056 216.561C54.9926 216.139 54.4092 215.23 54.4092 214.516Z" fill="#00283B" />
        <path d="M58.039 218.086C57.7797 218.086 57.5204 217.989 57.2287 217.859L55.5434 216.853C54.7332 216.398 54.085 215.36 54.085 214.548V213.445C54.085 213.023 54.247 212.698 54.5387 212.503C54.8952 212.309 55.349 212.341 55.8351 212.633L57.5204 213.639C58.3307 214.094 58.9789 215.132 58.9789 215.944V217.048C58.9789 217.469 58.8168 217.794 58.5251 217.989C58.3955 218.086 58.2334 218.119 58.039 218.119V218.086ZM54.7008 214.516C54.7008 215.132 55.2193 215.944 55.8675 216.301L57.5528 217.307C57.8121 217.469 58.0714 217.502 58.2334 217.437C58.3631 217.34 58.3955 217.145 58.3955 217.015V215.911C58.3955 215.295 57.8769 214.483 57.2287 214.126L55.5434 213.12C55.2841 212.958 55.0249 212.925 54.8628 212.99C54.7332 213.088 54.7008 213.282 54.7008 213.412V214.516Z" fill="#B0E2FA" />
        <path d="M54.3115 210.426V201.987C54.3115 201.273 54.8949 201.013 55.6079 201.435L57.2932 202.441C58.0063 202.863 58.5896 203.772 58.5896 204.486V212.925C58.5896 213.639 58.0063 213.899 57.2932 213.477L55.6079 212.471C54.8949 212.049 54.3115 211.14 54.3115 210.426Z" fill="#00283B" />
        <path d="M57.9413 213.996C57.682 213.996 57.4228 213.899 57.1311 213.769L55.4458 212.763C54.6355 212.309 53.9873 211.27 53.9873 210.459V202.019C53.9873 201.597 54.1494 201.273 54.441 201.078C54.7976 200.883 55.2513 200.916 55.7374 201.208L57.4228 202.214C58.233 202.669 58.8812 203.707 58.8812 204.519V212.958C58.8812 213.38 58.7192 213.704 58.4275 213.899C58.2978 213.996 58.1358 214.029 57.9413 214.029V213.996ZM54.6031 210.426C54.6031 211.043 55.1217 211.854 55.7699 212.211L57.4552 213.217C57.7145 213.38 57.9737 213.412 58.1358 213.347C58.2654 213.25 58.2978 213.055 58.2978 212.925V204.486C58.2978 203.869 57.7793 203.058 57.1311 202.701L55.4458 201.695C55.1865 201.532 54.9272 201.5 54.7651 201.565C54.6355 201.662 54.6031 201.857 54.6031 201.987V210.426Z" fill="#B0E2FA" />
        <path d="M47.5378 219.19L9.97461 196.761L11.6599 172.19L12.0813 172.45L47.5378 193.483V219.19ZM10.6228 196.436L46.9544 218.151V193.84L12.2109 173.229L10.6228 196.436Z" fill="#00283B" />
        <path d="M39.4678 208.316C36.1296 206.498 33.7637 209.063 33.7637 209.063L13.1833 196.729L10.2988 198.416L47.2462 220.748V212.73C47.2462 212.73 42.8061 210.101 39.4678 208.284V208.316Z" fill="#F5FCFF" />
        <path d="M47.5378 219.449L9.97461 196.729L11.6599 172.158L14.6417 173.943L13.4749 194.878L33.7311 206.823C34.3793 206.271 36.648 204.583 39.5974 206.206C42.8708 207.992 47.3433 210.621 47.3758 210.653L47.5378 210.751V219.449ZM10.6228 196.404L46.9544 218.378V211.075C46.1442 210.588 42.2874 208.316 39.3381 206.726C36.2591 205.038 34.0228 207.375 33.9904 207.407L33.8284 207.57L33.6339 207.44L12.8915 195.203L14.0583 174.235L12.2433 173.131L10.6552 196.339L10.6228 196.404Z" fill="#00283B" />
        <path d="M86.1706 224.415V223.474H83.6426V224.383C83.6426 224.578 83.7722 224.74 83.9991 224.902C84.4852 225.194 85.2955 225.194 85.7816 224.902C86.0085 224.772 86.1381 224.578 86.1381 224.383L86.1706 224.415Z" fill="#B0E2FA" />
        <path d="M84.9068 225.454C84.5178 225.454 84.1613 225.357 83.8696 225.194C83.5455 225 83.3511 224.74 83.3511 224.448V223.214H86.4948V224.448C86.4948 224.772 86.3004 225.032 85.9763 225.227C85.6846 225.389 85.2957 225.486 84.9392 225.486L84.9068 225.454ZM83.9345 223.799V224.415C83.9345 224.415 84.0317 224.61 84.1613 224.675C84.5502 224.902 85.2309 224.902 85.6522 224.675C85.7818 224.61 85.8791 224.513 85.8791 224.415V223.799H83.9669H83.9345Z" fill="#00283B" />
        <path d="M86.7538 222.857V221.494H83.0591V222.825C83.0591 223.085 83.2535 223.377 83.6101 223.571C84.3231 223.993 85.5222 223.993 86.2353 223.571C86.5918 223.377 86.7538 223.085 86.7862 222.825L86.7538 222.857Z" fill="#B0E2FA" />
        <path d="M84.9066 224.221C84.3881 224.221 83.8695 224.091 83.4482 223.864C83.1241 223.669 82.8648 223.442 82.7676 223.15V221.527C82.7676 221.332 82.8972 221.202 83.0917 221.202H86.754C86.9485 221.202 87.0781 221.332 87.0781 221.527V223.215C86.9809 223.474 86.7216 223.669 86.3975 223.864C85.9762 224.091 85.4576 224.221 84.939 224.221H84.9066ZM83.351 222.793V222.858C83.351 223.02 83.513 223.215 83.7399 223.344C84.3557 223.701 85.4252 223.701 86.0734 223.344C86.3003 223.215 86.4623 223.02 86.4623 222.858V221.786H83.351V222.76V222.793Z" fill="#00283B" />
        <path d="M86.2029 220.78C86.916 221.202 86.916 221.884 86.2029 222.306C85.4899 222.728 84.2908 222.728 83.5777 222.306C82.8647 221.884 82.8647 221.202 83.5777 220.78C84.2908 220.358 85.4899 220.358 86.2029 220.78Z" fill="#B0E2FA" />
        <path d="M86.203 221.397C86.916 221.819 86.5919 222.014 85.8465 222.435C85.1334 222.857 84.5177 222.922 83.8046 222.5C83.0916 222.078 82.8323 221.819 83.5778 221.429C84.2908 221.007 85.49 221.007 86.203 221.429V221.397Z" fill="#F5FCFF" />
        <path d="M84.9066 222.89C84.3881 222.89 83.8695 222.76 83.4482 222.533C82.9945 222.273 82.7676 221.916 82.7676 221.527C82.7676 221.137 83.0269 220.78 83.4482 220.52C84.2584 220.034 85.5548 220.034 86.3651 220.52C86.8188 220.78 87.0457 221.137 87.0457 221.527C87.0457 221.916 86.7864 222.273 86.3651 222.533C85.9438 222.76 85.4252 222.89 84.9066 222.89ZM84.9066 220.748C84.4853 220.748 84.064 220.845 83.7399 221.007C83.4806 221.137 83.351 221.332 83.351 221.494C83.351 221.656 83.4806 221.851 83.7399 221.981C84.3557 222.338 85.4252 222.338 86.0734 221.981C86.3327 221.851 86.4623 221.656 86.4623 221.494C86.4623 221.332 86.3327 221.137 86.0734 221.007C85.7493 220.813 85.328 220.748 84.9066 220.748Z" fill="#00283B" />
        <path d="M55.5756 228.375L54.5709 227.629C54.344 227.434 54.2144 227.109 54.2144 226.687C54.2144 225.876 54.7005 225 55.2839 224.643L56.4506 223.928C56.7099 223.799 56.9368 223.734 57.1637 223.799L57.3905 223.896C57.3905 223.896 57.4554 223.929 57.4878 223.961L58.3952 224.61L57.1961 226.687L55.5756 228.343V228.375Z" fill="#B0E2FA" />
        <path d="M56.3212 228.051L57.488 227.337C58.0066 227.044 58.4279 226.233 58.4279 225.551C58.4279 224.87 58.0066 224.545 57.488 224.837L56.3212 225.551C55.8027 225.843 55.3813 226.655 55.3813 227.337C55.3813 228.018 55.8027 228.343 56.3212 228.051Z" fill="#00283B" />
        <path d="M55.9322 228.473C55.9322 228.473 55.6729 228.44 55.5433 228.375C55.2516 228.213 55.0571 227.823 55.0571 227.369C55.0571 226.557 55.5433 225.681 56.1267 225.324L57.2934 224.61C57.6175 224.415 57.9416 224.415 58.2009 224.545C58.4926 224.707 58.687 225.097 58.687 225.551C58.687 226.363 58.2009 227.239 57.6175 227.596L56.4508 228.31C56.2887 228.408 56.0943 228.473 55.9322 228.473ZM57.8444 225.032C57.8444 225.032 57.6823 225.064 57.6175 225.097L56.4508 225.811C56.0294 226.071 55.6729 226.752 55.6729 227.336C55.6729 227.629 55.7701 227.791 55.835 227.823C55.9322 227.888 56.0618 227.823 56.1267 227.758L57.2934 227.044C57.7148 226.785 58.0713 226.103 58.0713 225.519C58.0713 225.227 57.974 225.064 57.9092 225.032C57.9092 225.032 57.8444 225.032 57.812 225.032H57.8444Z" fill="#B0E2FA" />
        <path d="M70.4514 173.131C70.6135 173.002 70.7107 172.742 70.7107 172.417C70.7107 171.736 70.2894 170.924 69.7708 170.632L67.34 169.236C67.1132 169.107 66.8863 169.107 66.7243 169.204C66.7243 169.204 66.6918 169.204 66.6594 169.236L65.752 169.918L67.0159 171.606L69.5763 173.781L70.419 173.164L70.4514 173.131Z" fill="#B0E2FA" />
        <path d="M69.7385 174.008L66.854 171.801L65.5576 169.691L66.7568 168.814C66.9837 168.75 67.2429 168.814 67.5022 168.977L69.933 170.372C70.5488 170.729 71.0349 171.638 71.0349 172.417C71.0349 172.839 70.9053 173.196 70.646 173.391L69.7709 174.04L69.7385 174.008ZM66.2058 169.95L67.2753 171.379L69.6089 173.359L70.2895 172.872C70.2895 172.872 70.4191 172.612 70.4191 172.417C70.4191 171.833 70.0626 171.119 69.6413 170.892L67.2105 169.496C67.2105 169.496 66.9837 169.399 66.8864 169.464H66.854L66.2058 169.983V169.95Z" fill="#00283B" />
        <path d="M68.8633 173.781L66.4326 172.385C65.914 172.093 65.4927 171.281 65.4927 170.6C65.4927 169.918 65.914 169.593 66.4326 169.886L68.8633 171.281C69.3819 171.573 69.8032 172.385 69.8032 173.066C69.8032 173.748 69.3819 174.073 68.8633 173.781Z" fill="#F5FCFF" />
        <path d="M69.2524 174.202C69.0904 174.202 68.8959 174.138 68.7339 174.04L66.3031 172.644C65.6873 172.287 65.2012 171.379 65.2012 170.6C65.2012 170.113 65.3632 169.756 65.6873 169.593C65.9466 169.431 66.2707 169.464 66.5948 169.658L69.0256 171.054C69.6413 171.411 70.0951 172.32 70.0951 173.099C70.0951 173.586 69.933 173.943 69.6089 174.105C69.4793 174.17 69.3497 174.202 69.22 174.202H69.2524ZM66.1087 170.08C66.1087 170.08 66.0438 170.08 66.0114 170.08C65.9142 170.113 65.8494 170.275 65.8494 170.567C65.8494 171.151 66.2059 171.865 66.6272 172.093L69.058 173.488C69.058 173.488 69.2848 173.586 69.3821 173.553C69.4793 173.521 69.5441 173.359 69.5441 173.066C69.5441 172.482 69.1876 171.768 68.7663 171.541L66.3355 170.145C66.3355 170.145 66.2059 170.08 66.1087 170.08Z" fill="#00283B" />
        <path d="M40.3103 156.188C40.4723 156.058 40.5696 155.799 40.5696 155.474C40.5696 154.793 40.1482 153.981 39.6297 153.689L37.1989 152.293C36.9721 152.163 36.7452 152.163 36.5831 152.261C36.5831 152.261 36.5507 152.261 36.5183 152.293L35.6108 152.975L36.8748 154.663L39.4352 156.837L40.2779 156.221L40.3103 156.188Z" fill="#B0E2FA" />
        <path d="M39.5974 157.065L36.7129 154.857L35.4165 152.748L36.6157 151.871C36.8425 151.806 37.1018 151.871 37.3611 152.034L39.7919 153.429C40.4076 153.786 40.8938 154.695 40.8938 155.474C40.8938 155.896 40.7642 156.253 40.5049 156.448L39.6298 157.097L39.5974 157.065ZM36.0647 153.007L37.1342 154.436L39.4678 156.415L40.1484 155.929C40.1484 155.929 40.278 155.669 40.278 155.442C40.278 154.857 39.9215 154.143 39.5002 153.916L37.0694 152.52C37.0694 152.52 36.8425 152.423 36.7453 152.488H36.7129L36.0647 153.007Z" fill="#00283B" />
        <path d="M38.7222 156.837L36.2915 155.442C35.7729 155.15 35.3516 154.338 35.3516 153.657C35.3516 152.975 35.7729 152.65 36.2915 152.942L38.7222 154.338C39.2408 154.63 39.6621 155.442 39.6621 156.123C39.6621 156.805 39.2408 157.13 38.7222 156.837Z" fill="#F5FCFF" />
        <path d="M39.1113 157.259C38.9493 157.259 38.7548 157.194 38.5927 157.097L36.162 155.701C35.5462 155.344 35.0601 154.436 35.0601 153.657C35.0601 153.17 35.2221 152.813 35.5462 152.65C35.8055 152.488 36.1296 152.52 36.4537 152.715L38.8844 154.111C39.5002 154.468 39.954 155.377 39.954 156.156C39.954 156.643 39.7919 157 39.4678 157.162C39.3382 157.227 39.2085 157.259 39.0789 157.259H39.1113ZM35.9675 153.137C35.9675 153.137 35.9027 153.137 35.8703 153.137C35.7731 153.17 35.7083 153.332 35.7083 153.624C35.7083 154.208 36.0648 154.922 36.4861 155.15L38.9168 156.545C38.9168 156.545 39.1437 156.643 39.2409 156.61C39.3382 156.578 39.403 156.415 39.403 156.123C39.403 155.539 39.0465 154.825 38.6252 154.598L36.1944 153.202C36.1944 153.202 36.0648 153.137 35.9675 153.137Z" fill="#00283B" />
        <path d="M9.71556 232.53C9.87761 232.4 9.97484 232.141 9.97484 231.816C9.97484 231.134 9.55351 230.323 9.03495 230.031L6.6042 228.635C6.37733 228.505 6.15046 228.505 5.98841 228.603C5.98841 228.603 5.956 228.603 5.92359 228.635L5.01611 229.317L6.2801 231.005L8.84049 233.179L9.68315 232.563L9.71556 232.53Z" fill="#B0E2FA" />
        <path d="M9.03489 233.406L6.1504 231.199L4.854 229.089L6.05317 228.213C6.28004 228.148 6.53932 228.213 6.7986 228.375L9.22935 229.771C9.84514 230.128 10.3313 231.037 10.3313 231.816C10.3313 232.238 10.2017 232.595 9.94237 232.79L9.0673 233.439L9.03489 233.406ZM5.5022 229.349L6.57173 230.777L8.90525 232.757L9.58586 232.27C9.58586 232.27 9.7155 232.011 9.7155 231.816C9.7155 231.232 9.35899 230.518 8.93766 230.29L6.50691 228.895C6.50691 228.895 6.28004 228.797 6.18281 228.862H6.1504L5.5022 229.382V229.349Z" fill="#00283B" />
        <path d="M8.1597 233.179L5.72895 231.784C5.21039 231.491 4.78906 230.68 4.78906 229.998C4.78906 229.317 5.21039 228.992 5.72895 229.284L8.1597 230.68C8.67826 230.972 9.09959 231.784 9.09959 232.465C9.09959 233.147 8.67826 233.471 8.1597 233.179Z" fill="#F5FCFF" />
        <path d="M8.51609 233.601C8.35404 233.601 8.15958 233.536 7.99753 233.439L5.56678 232.043C4.95099 231.686 4.46484 230.777 4.46484 229.998C4.46484 229.511 4.62689 229.154 4.95099 228.992C5.21027 228.83 5.53437 228.862 5.85847 229.057L8.28922 230.453C8.90501 230.81 9.35875 231.719 9.35875 232.498C9.35875 232.984 9.1967 233.342 8.8726 233.504C8.74296 233.569 8.61332 233.601 8.48368 233.601H8.51609ZM5.37232 229.479C5.37232 229.479 5.3075 229.479 5.27509 229.479C5.17786 229.511 5.11304 229.674 5.11304 229.966C5.11304 230.55 5.46955 231.264 5.89088 231.491L8.32163 232.887C8.32163 232.887 8.5485 232.984 8.64573 232.952C8.74296 232.92 8.80778 232.757 8.80778 232.465C8.80778 231.881 8.45127 231.167 8.02994 230.94L5.59919 229.544C5.59919 229.544 5.46955 229.479 5.37232 229.479Z" fill="#00283B" />
        <path d="M43.9729 252.946C44.1349 252.816 44.2322 252.557 44.2322 252.232C44.2322 251.551 43.8108 250.739 43.2923 250.447L40.8615 249.051C40.6347 248.922 40.4078 248.921 40.2457 249.019C40.2457 249.019 40.2133 249.019 40.1809 249.051L39.2734 249.733L40.5374 251.421L43.0978 253.595L43.9405 252.979L43.9729 252.946Z" fill="#B0E2FA" />
        <path d="M43.2922 253.823L40.4077 251.616L39.1113 249.506L40.3105 248.629C40.5374 248.564 40.7966 248.629 41.0559 248.792L43.4867 250.187C44.1025 250.544 44.5886 251.453 44.5886 252.232C44.5886 252.654 44.459 253.011 44.1997 253.206L43.3246 253.855L43.2922 253.823ZM39.7595 249.765L40.8291 251.194L43.1626 253.174L43.8432 252.687C43.8432 252.687 43.9728 252.427 43.9728 252.2C43.9728 251.616 43.6163 250.901 43.195 250.674L40.7642 249.279C40.7642 249.279 40.5374 249.181 40.4401 249.246H40.4077L39.7595 249.765Z" fill="#00283B" />
        <path d="M42.417 253.596L39.9863 252.2C39.4677 251.908 39.0464 251.096 39.0464 250.415C39.0464 249.733 39.4677 249.408 39.9863 249.701L42.417 251.096C42.9356 251.388 43.3569 252.2 43.3569 252.881C43.3569 253.563 42.9356 253.888 42.417 253.596Z" fill="#F5FCFF" />
        <path d="M42.7734 254.017C42.6114 254.017 42.4169 253.952 42.2549 253.855L39.8241 252.459C39.2083 252.102 38.7222 251.194 38.7222 250.415C38.7222 249.928 38.8842 249.571 39.2083 249.408C39.4676 249.246 39.7917 249.279 40.1158 249.473L42.5465 250.869C43.1623 251.226 43.6161 252.135 43.6161 252.914C43.6161 253.401 43.454 253.758 43.1299 253.92C43.0003 253.985 42.8706 254.017 42.741 254.017H42.7734ZM39.6296 249.895C39.6296 249.895 39.5648 249.895 39.5324 249.895C39.4352 249.928 39.3704 250.09 39.3704 250.382C39.3704 250.966 39.7269 251.68 40.1482 251.908L42.579 253.303C42.579 253.303 42.8058 253.401 42.9031 253.368C43.0003 253.336 43.0651 253.174 43.0651 252.881C43.0651 252.297 42.7086 251.583 42.2873 251.356L39.8565 249.96C39.8565 249.96 39.7269 249.895 39.6296 249.895Z" fill="#00283B" />
        <path d="M9.13184 220.813V204.973C9.13184 204.259 9.71522 203.999 10.4282 204.421L12.1136 205.427C12.8266 205.849 13.41 206.758 13.41 207.472V223.312C13.41 224.026 12.8266 224.286 12.1136 223.864L10.4282 222.858C9.71522 222.436 9.13184 221.527 9.13184 220.813Z" fill="#B0E2FA" />
        <path d="M12.7616 224.383C12.5024 224.383 12.2431 224.318 11.9514 224.156L10.2661 223.15C9.45582 222.695 8.80762 221.657 8.80762 220.845V205.005C8.80762 204.583 8.96967 204.259 9.26136 204.064C9.61787 203.869 10.0716 203.902 10.5578 204.194L12.2431 205.2C13.0533 205.655 13.7015 206.693 13.7015 207.505V223.344C13.7015 223.766 13.5395 224.091 13.2478 224.286C13.1181 224.383 12.9561 224.415 12.7616 224.415V224.383ZM9.42341 220.813C9.42341 221.429 9.94197 222.241 10.5902 222.598L12.2755 223.604C12.5348 223.766 12.794 223.799 12.9561 223.734C13.0857 223.636 13.1181 223.442 13.1181 223.312V207.472C13.1181 206.856 12.5996 206.044 11.9514 205.687L10.2661 204.681C10.0068 204.519 9.74751 204.486 9.58546 204.551C9.45582 204.648 9.42341 204.843 9.42341 204.973V220.813Z" fill="#00283B" />
        <path d="M13.2803 223.215L11.595 222.208C10.882 221.786 10.2986 220.878 10.2986 220.164V204.356C9.6504 204.064 9.13184 204.292 9.13184 204.973V220.813C9.13184 221.527 9.71522 222.468 10.4282 222.858L12.1136 223.864C12.8266 224.286 13.41 224.026 13.41 223.312C13.41 223.312 13.3127 223.247 13.2803 223.215Z" fill="#00283B" />
        <path d="M12.7616 224.35C12.5024 224.35 12.2431 224.286 11.9514 224.123L10.2661 223.117C9.45582 222.663 8.80762 221.624 8.80762 220.812V204.973C8.80762 204.583 8.96967 204.259 9.22895 204.097C9.52064 203.902 9.94197 203.902 10.3957 204.097L10.5578 204.161V220.163C10.5578 220.78 11.0763 221.591 11.7245 221.949L13.7015 223.085V223.279C13.7015 223.734 13.5395 224.091 13.2478 224.253C13.1181 224.35 12.9561 224.383 12.7616 224.383V224.35ZM9.77992 204.518C9.77992 204.518 9.65028 204.518 9.58546 204.551C9.45582 204.616 9.42341 204.811 9.42341 204.94V220.78C9.42341 221.397 9.94197 222.208 10.5902 222.565L12.2755 223.571C12.5348 223.734 12.794 223.766 12.9561 223.701C13.0533 223.636 13.0857 223.539 13.1181 223.442L11.4652 222.435C10.655 221.981 10.0068 220.942 10.0068 220.131V204.551C10.0068 204.551 9.84474 204.518 9.77992 204.518Z" fill="#00283B" />
        <path d="M12.9562 210.783C13.1507 210.621 13.2479 210.361 13.2479 209.972C13.2479 209.063 12.5997 207.959 11.8219 207.505C11.4005 207.278 11.044 207.245 10.7847 207.407L9.65039 208.251L11.8219 211.627L12.8266 210.88L12.9562 210.783Z" fill="#00283B" />
        <path d="M11.7246 212.081L9.229 208.186L10.8171 207.083C11.1736 206.953 11.5625 207.018 11.9839 207.245C12.8589 207.764 13.5719 208.965 13.5719 209.972C13.5719 210.394 13.4423 210.718 13.183 210.978L13.0534 211.108L12.9886 211.043V211.108L11.757 212.049L11.7246 212.081ZM10.0393 208.349L11.8866 211.237L12.7293 210.588C12.7293 210.588 12.9562 210.264 12.9562 210.004C12.9562 209.193 12.3728 208.186 11.6922 207.797C11.4005 207.635 11.1412 207.602 10.9791 207.699H10.9143L10.0717 208.349H10.0393Z" fill="#00283B" />
        <path d="M12.1783 210.751C12.1783 211.659 11.5301 212.049 10.7522 211.562C9.97437 211.108 9.32617 210.004 9.32617 209.095C9.32617 208.186 9.97437 207.797 10.7522 208.284C11.5301 208.738 12.1783 209.842 12.1783 210.751Z" fill="#F5FCFF" />
        <path d="M11.4006 212.081C11.1413 212.081 10.882 212.016 10.6228 211.854C9.74769 211.335 9.03467 210.134 9.03467 209.128C9.03467 208.576 9.22913 208.154 9.61805 207.927C9.97456 207.7 10.4607 207.764 10.9144 208.024C11.7895 208.543 12.5025 209.744 12.5025 210.751C12.5025 211.302 12.3081 211.724 11.9192 211.952C11.7571 212.049 11.5951 212.081 11.4006 212.081ZM10.1366 208.381C10.1366 208.381 9.97456 208.381 9.90974 208.446C9.74769 208.543 9.65046 208.803 9.65046 209.095C9.65046 209.907 10.2338 210.913 10.9144 211.302C11.2061 211.465 11.4654 211.497 11.6275 211.4C11.7895 211.302 11.8867 211.043 11.8867 210.718C11.8867 209.907 11.3034 208.9 10.6228 208.511C10.4607 208.414 10.2662 208.349 10.1366 208.349V208.381Z" fill="#00283B" />
        <path d="M12.9562 215.49C13.1507 215.327 13.2479 215.068 13.2479 214.678C13.2479 213.769 12.5997 212.666 11.8219 212.211C11.4005 211.984 11.044 211.952 10.7847 212.114L9.65039 212.958L11.8219 216.333L12.8266 215.587L12.9562 215.49Z" fill="#00283B" />
        <path d="M11.7246 216.788L9.229 212.893L10.7523 211.757H10.7847C11.1412 211.659 11.5301 211.692 11.9514 211.952C12.8265 212.471 13.5395 213.672 13.5395 214.678C13.5395 215.1 13.4099 215.425 13.1506 215.684L13.021 215.814L11.757 216.755L11.7246 216.788ZM10.0393 213.055L11.8866 215.944L12.7293 215.295C12.7293 215.295 12.9562 214.97 12.9562 214.71C12.9562 213.899 12.3728 212.893 11.6922 212.503C11.4005 212.341 11.1412 212.309 10.9791 212.406H10.9143L10.0717 213.055H10.0393Z" fill="#00283B" />
        <path d="M12.1783 215.457C12.1783 216.366 11.5301 216.755 10.7522 216.269C9.97437 215.814 9.32617 214.711 9.32617 213.802C9.32617 212.893 9.97437 212.503 10.7522 212.99C11.5301 213.445 12.1783 214.548 12.1783 215.457Z" fill="#F5FCFF" />
        <path d="M11.4006 216.788C11.1413 216.788 10.882 216.723 10.6228 216.561C9.74769 216.041 9.03467 214.84 9.03467 213.834C9.03467 213.282 9.22913 212.861 9.61805 212.633C9.97456 212.406 10.4607 212.471 10.9144 212.731C11.7895 213.25 12.5025 214.451 12.5025 215.457C12.5025 216.009 12.3081 216.431 11.9192 216.658C11.7571 216.755 11.5951 216.788 11.4006 216.788ZM10.1366 213.088C10.1366 213.088 9.97456 213.088 9.90974 213.153C9.74769 213.25 9.65046 213.51 9.65046 213.834C9.65046 214.646 10.2338 215.652 10.9144 216.041C11.2061 216.204 11.4654 216.236 11.6275 216.139C11.7895 216.041 11.8867 215.782 11.8867 215.457C11.8867 214.646 11.3034 213.639 10.6228 213.25C10.4607 213.153 10.2662 213.088 10.1366 213.088Z" fill="#00283B" />
        <path d="M12.9562 220.131C13.1507 219.969 13.2479 219.709 13.2479 219.32C13.2479 218.411 12.5997 217.307 11.8219 216.853C11.4005 216.626 11.044 216.593 10.7847 216.755L9.65039 217.664L11.8219 221.04L12.8266 220.229L12.9562 220.131Z" fill="#00283B" />
        <path d="M11.4653 221.072L9.229 217.599L10.7523 216.398H10.7847C11.1412 216.301 11.5301 216.333 11.9514 216.593C12.8265 217.112 13.5395 218.313 13.5395 219.32C13.5395 219.742 13.4099 220.066 13.1506 220.326L13.021 220.456L12.1783 221.137C11.9514 221.3 11.6598 221.267 11.4977 221.04L11.4653 221.072ZM10.0393 217.729L11.8866 220.618L12.7293 219.904C12.7293 219.904 12.9562 219.579 12.9562 219.32C12.9562 218.508 12.3728 217.502 11.6922 217.112C11.4005 216.95 11.1412 216.918 10.9791 217.015H10.9467L10.0717 217.729H10.0393Z" fill="#00283B" />
        <path d="M12.1783 220.099C12.1783 221.008 11.5301 221.397 10.7522 220.91C9.97437 220.456 9.32617 219.352 9.32617 218.443C9.32617 217.535 9.97437 217.145 10.7522 217.632C11.5301 218.086 12.1783 219.19 12.1783 220.099Z" fill="#F5FCFF" />
        <path d="M11.4006 221.43C11.1413 221.43 10.882 221.365 10.6228 221.202C9.74769 220.683 9.03467 219.482 9.03467 218.476C9.03467 217.924 9.22913 217.502 9.61805 217.275C9.97456 217.048 10.4607 217.113 10.9144 217.372C11.7895 217.892 12.5025 219.093 12.5025 220.099C12.5025 220.651 12.3081 221.073 11.9192 221.3C11.7571 221.397 11.5951 221.43 11.4006 221.43ZM10.1366 217.729C10.1366 217.729 9.97456 217.729 9.90974 217.794C9.74769 217.892 9.65046 218.151 9.65046 218.476C9.65046 219.287 10.2338 220.293 10.9144 220.683C11.2061 220.845 11.4654 220.878 11.6275 220.78C11.7895 220.683 11.8867 220.423 11.8867 220.099C11.8867 219.287 11.3034 218.281 10.6228 217.892C10.4607 217.794 10.2662 217.729 10.1366 217.729Z" fill="#00283B" />
        <path d="M42.0928 239.606V223.766C42.0928 223.052 42.6762 222.793 43.3892 223.214L45.0745 224.221C45.7875 224.643 46.3709 225.551 46.3709 226.266V242.105C46.3709 242.819 45.7875 243.079 45.0745 242.657L43.3892 241.651C42.6762 241.229 42.0928 240.32 42.0928 239.606Z" fill="#B0E2FA" />
        <path d="M45.7226 243.176C45.4633 243.176 45.204 243.111 44.9123 242.949L43.227 241.943C42.4168 241.488 41.7686 240.45 41.7686 239.638V223.799C41.7686 223.377 41.9306 223.052 42.2223 222.857C42.5788 222.663 43.0325 222.695 43.5187 222.987L45.204 223.993C46.0143 224.448 46.6625 225.486 46.6625 226.298V242.138C46.6625 242.56 46.5004 242.884 46.2087 243.079C46.0791 243.176 45.917 243.209 45.7226 243.209V243.176ZM42.3843 239.606C42.3843 240.223 42.9029 241.034 43.5511 241.391L45.2364 242.397C45.4957 242.56 45.755 242.592 45.917 242.527C46.0467 242.43 46.0791 242.235 46.0791 242.105V226.265C46.0791 225.649 45.5605 224.837 44.9123 224.48L43.227 223.474C42.9677 223.312 42.7084 223.279 42.5464 223.344C42.4168 223.442 42.3843 223.636 42.3843 223.766V239.606Z" fill="#00283B" />
        <path d="M46.2413 242.008L44.5559 241.002C43.8429 240.58 43.2595 239.671 43.2595 238.957V223.15C42.6113 222.858 42.0928 223.085 42.0928 223.766V239.606C42.0928 240.32 42.6762 241.261 43.3892 241.651L45.0745 242.657C45.7875 243.079 46.3709 242.819 46.3709 242.105C46.3709 242.105 46.2737 242.04 46.2413 242.008Z" fill="#00283B" />
        <path d="M45.7226 243.144C45.4633 243.144 45.204 243.046 44.9123 242.916L43.227 241.91C42.4168 241.456 41.7686 240.417 41.7686 239.606V223.766C41.7686 223.377 41.9306 223.052 42.1899 222.89C42.4816 222.695 42.9029 222.695 43.3566 222.89L43.5187 222.955V238.957C43.5187 239.573 44.0373 240.385 44.6855 240.742L46.6625 241.878V242.073C46.6625 242.527 46.5004 242.884 46.2087 243.046C46.0791 243.144 45.917 243.176 45.7226 243.176V243.144ZM42.7409 223.312C42.7409 223.312 42.6112 223.312 42.5464 223.344C42.4168 223.409 42.3843 223.604 42.3843 223.734V239.573C42.3843 240.19 42.9029 241.001 43.5511 241.358L45.2364 242.365C45.4957 242.527 45.755 242.559 45.917 242.494C46.0143 242.43 46.0467 242.332 46.0791 242.235L44.4262 241.229C43.6159 240.774 42.9677 239.736 42.9677 238.924V223.344C42.9677 223.344 42.8057 223.312 42.7409 223.312Z" fill="#00283B" />
        <path d="M45.9171 229.576C46.1116 229.414 46.2088 229.154 46.2088 228.765C46.2088 227.856 45.5606 226.752 44.7828 226.298C44.3939 226.071 44.005 226.038 43.7457 226.201L42.6113 227.044L44.7828 230.42L45.7875 229.674L45.9171 229.576Z" fill="#00283B" />
        <path d="M44.6855 230.875L42.1899 226.98L43.778 225.876C44.1345 225.746 44.5235 225.811 44.9448 226.038C45.8199 226.558 46.5329 227.759 46.5329 228.765C46.5329 229.187 46.4032 229.511 46.144 229.771L46.0143 229.901L45.9495 229.836V229.901L44.7179 230.842L44.6855 230.875ZM43.0002 227.142L44.8476 230.031L45.6902 229.381C45.6902 229.381 45.9171 229.057 45.9171 228.797C45.9171 227.986 45.3337 226.98 44.6531 226.59C44.3614 226.428 44.1021 226.395 43.9401 226.493H43.8753L43.0326 227.142H43.0002Z" fill="#00283B" />
        <path d="M45.1392 229.544C45.1392 230.453 44.491 230.842 43.7132 230.355C42.9353 229.901 42.2871 228.797 42.2871 227.888C42.2871 226.98 42.9353 226.59 43.7132 227.077C44.491 227.531 45.1392 228.635 45.1392 229.544Z" fill="#F5FCFF" />
        <path d="M44.3615 230.875C44.1023 230.875 43.843 230.81 43.5837 230.647C42.7086 230.128 41.9956 228.927 41.9956 227.921C41.9956 227.369 42.1901 226.947 42.579 226.72C42.9355 226.493 43.4216 226.558 43.8754 226.817C44.7505 227.337 45.4635 228.538 45.4635 229.544C45.4635 230.096 45.269 230.518 44.8801 230.745C44.718 230.842 44.556 230.875 44.3615 230.875ZM43.0975 227.174C43.0975 227.174 42.9355 227.174 42.8707 227.239C42.7086 227.337 42.6114 227.596 42.6114 227.921C42.6114 228.732 43.1948 229.739 43.8754 230.128C44.1671 230.29 44.4264 230.323 44.5884 230.225C44.7505 230.128 44.8477 229.868 44.8477 229.544C44.8477 228.732 44.2643 227.726 43.5837 227.337C43.4216 227.239 43.2272 227.174 43.0975 227.174Z" fill="#00283B" />
        <path d="M45.9171 234.283C46.1116 234.12 46.2088 233.861 46.2088 233.471C46.2088 232.562 45.5606 231.459 44.7828 231.004C44.3939 230.777 44.005 230.745 43.7457 230.907L42.6113 231.751L44.7828 235.127L45.7875 234.38L45.9171 234.283Z" fill="#00283B" />
        <path d="M44.6855 235.581L42.1899 231.686L43.7132 230.55H43.7456C44.1021 230.453 44.4911 230.485 44.9124 230.745C45.7875 231.264 46.5005 232.465 46.5005 233.471C46.5005 233.893 46.3708 234.218 46.1116 234.477L45.9819 234.607L44.7179 235.549L44.6855 235.581ZM43.0002 231.848L44.8476 234.737L45.6902 234.088C45.6902 234.088 45.9171 233.763 45.9171 233.504C45.9171 232.692 45.3337 231.686 44.6531 231.297C44.3614 231.134 44.1021 231.102 43.9401 231.199H43.8753L43.0326 231.848H43.0002Z" fill="#00283B" />
        <path d="M45.1392 234.25C45.1392 235.159 44.491 235.549 43.7132 235.062C42.9353 234.607 42.2871 233.504 42.2871 232.595C42.2871 231.686 42.9353 231.297 43.7132 231.784C44.491 232.238 45.1392 233.341 45.1392 234.25Z" fill="#F5FCFF" />
        <path d="M44.3615 235.581C44.1023 235.581 43.843 235.516 43.5837 235.354C42.7086 234.835 41.9956 233.634 41.9956 232.627C41.9956 232.076 42.1901 231.654 42.579 231.427C42.9355 231.199 43.4216 231.264 43.8754 231.524C44.7505 232.043 45.4635 233.244 45.4635 234.25C45.4635 234.802 45.269 235.224 44.8801 235.451C44.718 235.549 44.556 235.581 44.3615 235.581ZM43.0975 231.881C43.0975 231.881 42.9355 231.881 42.8707 231.946C42.7086 232.043 42.6114 232.303 42.6114 232.627C42.6114 233.439 43.1948 234.445 43.8754 234.835C44.1671 234.997 44.4264 235.029 44.5884 234.932C44.7505 234.835 44.8477 234.575 44.8477 234.25C44.8477 233.439 44.2643 232.433 43.5837 232.043C43.4216 231.946 43.2272 231.881 43.0975 231.881Z" fill="#00283B" />
        <path d="M45.9171 238.892C46.1116 238.729 46.2088 238.47 46.2088 238.08C46.2088 237.171 45.5606 236.068 44.7828 235.613C44.3939 235.386 44.005 235.354 43.7457 235.516L42.6113 236.425L44.7828 239.8L45.7875 238.989L45.9171 238.892Z" fill="#00283B" />
        <path d="M44.4262 239.833L42.1899 236.36L43.7132 235.159H43.7456C44.1021 235.062 44.4911 235.094 44.9124 235.354C45.7875 235.873 46.5005 237.074 46.5005 238.08C46.5005 238.502 46.3708 238.827 46.1116 239.086L45.9819 239.216L45.1393 239.898C44.9124 240.06 44.6207 240.028 44.4586 239.8L44.4262 239.833ZM43.0002 236.49L44.8476 239.378L45.6902 238.664C45.6902 238.664 45.9171 238.34 45.9171 238.08C45.9171 237.269 45.3337 236.262 44.6531 235.873C44.3614 235.711 44.1021 235.678 43.9401 235.776H43.9077L43.0326 236.49H43.0002Z" fill="#00283B" />
        <path d="M45.1392 238.892C45.1392 239.801 44.491 240.19 43.7132 239.703C42.9353 239.249 42.2871 238.145 42.2871 237.236C42.2871 236.327 42.9353 235.938 43.7132 236.425C44.491 236.879 45.1392 237.983 45.1392 238.892Z" fill="#F5FCFF" />
        <path d="M44.3615 240.223C44.1023 240.223 43.843 240.158 43.5837 239.995C42.7086 239.476 41.9956 238.275 41.9956 237.269C41.9956 236.717 42.1901 236.295 42.579 236.068C42.9355 235.841 43.4216 235.906 43.8754 236.165C44.7505 236.685 45.4635 237.886 45.4635 238.892C45.4635 239.444 45.269 239.865 44.8801 240.093C44.718 240.19 44.556 240.223 44.3615 240.223ZM43.0975 236.522C43.0975 236.522 42.9355 236.522 42.8707 236.587C42.7086 236.685 42.6114 236.944 42.6114 237.269C42.6114 238.08 43.1948 239.086 43.8754 239.476C44.1671 239.638 44.4264 239.671 44.5884 239.573C44.7505 239.476 44.8477 239.249 44.8477 238.892C44.8477 238.08 44.2643 237.074 43.5837 236.685C43.4216 236.587 43.2272 236.522 43.0975 236.522Z" fill="#00283B" />
        <path d="M85.879 230.42L99.7829 222.306L100.107 225.259L99.945 230.842L99.0051 232.108L85.6846 239.768L85.879 238.275V230.42Z" fill="#00283B" />
        <path d="M79.6886 203.642L79.5265 181.148L76.7393 182.804V203.642C76.7393 203.642 76.7717 203.902 76.7717 203.967C76.7717 204.356 77.4199 204.681 78.1329 204.681C78.8459 204.681 79.6237 204.356 79.6237 203.967C79.6237 203.967 79.6562 203.804 79.6562 203.772V203.61L79.6886 203.642Z" fill="#B0E2FA" />
        <path d="M78.1329 204.973C77.3227 204.973 76.4476 204.583 76.4476 203.967C76.4476 203.967 76.4476 203.967 76.4476 203.902C76.4476 203.804 76.3828 203.739 76.3828 203.675V182.674L79.8183 180.662L79.9803 203.675V203.837C79.9803 203.837 79.9479 203.902 79.9155 203.967C79.9155 204.583 78.9756 204.973 78.1005 204.973H78.1329ZM76.9986 203.675C76.9986 203.675 76.9986 203.707 76.9986 203.739C77.031 203.837 77.0634 203.902 77.0634 203.999C77.0634 204.129 77.4847 204.389 78.1329 204.389C78.7811 204.389 79.2997 204.096 79.3321 203.999C79.3321 203.934 79.3321 203.902 79.3645 203.837V203.675L79.2349 181.733L77.031 183.031V203.707H76.9986V203.675Z" fill="#B0E2FA" />
        <path d="M73.4336 167.029V194.457C73.4336 195.787 75.4754 196.891 78.0034 196.891C80.5314 196.891 82.5732 195.82 82.5732 194.457V167.029H73.466H73.4336Z" fill="#B0E2FA" />
        <path d="M77.9709 197.15C75.2484 197.15 73.1094 195.949 73.1094 194.424V166.705H82.8324V194.424C82.8324 195.949 80.6933 197.15 77.9709 197.15ZM73.7252 167.321V194.424C73.7252 195.56 75.6698 196.534 77.9709 196.534C80.272 196.534 82.2166 195.56 82.2166 194.424V167.321H73.6928H73.7252Z" fill="#00283B" />
        <path d="M82.5405 167.029C82.5405 168.36 80.4987 169.463 77.9707 169.463C75.4427 169.463 73.4009 168.392 73.4009 167.029C73.4009 165.666 75.4103 163.167 77.9383 163.167C80.4663 163.167 82.5081 165.698 82.5081 167.029H82.5405Z" fill="#B0E2FA" />
        <path d="M77.9709 169.756C75.2484 169.756 73.1094 168.555 73.1094 167.029C73.1094 165.504 75.3781 162.842 77.9385 162.842C80.4989 162.842 82.8 165.439 82.8 167.029C82.8 168.62 80.6609 169.756 77.9385 169.756H77.9709ZM77.9709 163.459C75.6374 163.459 73.7252 165.828 73.7252 167.029C73.7252 168.23 75.6698 169.139 77.9709 169.139C80.272 169.139 82.2166 168.165 82.2166 167.029C82.2166 165.828 80.272 163.459 77.9385 163.459H77.9709Z" fill="#00283B" />
        <path d="M80.9527 164.725C80.9527 165.601 79.6239 166.315 77.971 166.315C76.3181 166.315 74.9893 165.601 74.9893 164.725C74.9893 163.848 76.3181 163.134 77.971 163.134C79.6239 163.134 80.9527 163.848 80.9527 164.725Z" fill="#B0E2FA" />
        <path d="M81.536 166.023C81.536 167.062 79.9479 167.938 77.9709 167.938C75.9939 167.938 74.4058 167.094 74.4058 166.023C74.4058 164.952 75.9939 164.108 77.9709 164.108C79.9479 164.108 81.536 164.952 81.536 166.023Z" fill="#F5FCFF" />
        <path d="M77.9707 166.607C76.1233 166.607 74.6973 165.796 74.6973 164.725C74.6973 163.653 76.1233 162.842 77.9707 162.842C79.818 162.842 81.2441 163.653 81.2441 164.725C81.2441 165.796 79.818 166.607 77.9707 166.607ZM77.9707 163.459C76.3826 163.459 75.2806 164.14 75.2806 164.725C75.2806 165.309 76.3826 165.99 77.9707 165.99C79.5588 165.99 80.6607 165.309 80.6607 164.725C80.6607 164.14 79.5588 163.459 77.9707 163.459Z" fill="#00283B" />
        <path d="M79.4941 163.978V141.614C79.4941 141.614 79.3321 141.387 79.2024 141.452L76.48 143.205V163.978C76.48 163.978 76.5448 164.238 76.5448 164.303C76.5448 164.692 77.193 165.017 77.906 165.017C78.619 165.017 79.3969 164.692 79.3969 164.303C79.3969 164.303 79.4293 164.14 79.4293 164.108V163.946L79.4941 163.978Z" fill="#B0E2FA" />
        <path d="M77.9386 165.309C77.1284 165.309 76.2533 164.919 76.2533 164.303C76.2533 164.303 76.2533 164.27 76.2533 164.238C76.2209 164.14 76.1885 164.075 76.1885 164.011V143.075L79.0406 141.225C79.2026 141.128 79.3647 141.128 79.5267 141.225C79.6888 141.322 79.786 141.485 79.786 141.647V164.173C79.786 164.173 79.786 164.238 79.7212 164.303C79.7212 164.919 78.7813 165.309 77.9062 165.309H77.9386ZM76.8043 164.011C76.8043 164.011 76.8043 164.011 76.8043 164.075C76.8367 164.173 76.8691 164.27 76.8691 164.335C76.8691 164.465 77.2904 164.725 77.9386 164.725C78.5868 164.725 79.1054 164.433 79.1378 164.335C79.1378 164.27 79.1378 164.238 79.1702 164.14V163.978V141.874L76.7719 143.432V164.043L76.8043 164.011Z" fill="#00283B" />
        <path d="M79.2674 140.543C79.7211 140.933 79.4943 141.907 78.7812 142.751C78.0682 143.594 77.1283 143.984 76.6746 143.595C76.2208 143.205 76.4477 142.231 77.1607 141.387C77.8738 140.543 78.8136 140.154 79.2674 140.543Z" fill="#B0E2FA" />
        <path d="M79.2673 141.355C79.721 141.744 79.1376 142.134 78.4246 142.978C77.7116 143.822 77.4199 144.114 76.9662 143.757C76.5124 143.367 76.4476 143.075 77.1606 142.231C77.8737 141.387 78.8135 140.998 79.2673 141.387V141.355Z" fill="#F5FCFF" />
        <path d="M77.1281 144.049C76.8689 144.049 76.6744 143.984 76.5124 143.822C76.2207 143.562 76.091 143.14 76.2207 142.588C76.3179 142.134 76.5772 141.614 76.9661 141.16C77.8088 140.186 78.9107 139.764 79.4941 140.284C79.7858 140.543 79.9154 140.965 79.7858 141.517C79.6885 141.972 79.4293 142.491 79.0403 142.945C78.6514 143.4 78.2301 143.724 77.7763 143.919C77.5495 144.016 77.355 144.049 77.1606 144.049H77.1281ZM78.8135 140.673C78.457 140.673 77.8736 140.998 77.3874 141.55C77.0957 141.907 76.8689 142.329 76.7716 142.718C76.7068 143.01 76.7716 143.237 76.8689 143.367C76.9985 143.465 77.2254 143.465 77.5171 143.367C77.8736 143.237 78.2301 142.945 78.5542 142.556C78.8783 142.199 79.0727 141.777 79.17 141.387C79.2348 141.095 79.17 140.868 79.0727 140.738C79.0727 140.738 78.9107 140.673 78.8135 140.673Z" fill="#00283B" />
        <path d="M77.7762 143.919L77.7114 164.725L79.2671 164.627L79.3967 163.686V141.971L77.7762 143.919Z" fill="#00283B" />
        <path d="M76.6745 169.334V196.566L79.8507 196.858L82.249 195.3L82.5407 194.457V167.743C82.5407 167.743 80.2396 169.496 79.9803 169.464C79.721 169.431 77.9709 169.464 77.9709 169.464L76.3828 169.399H76.6745V169.334Z" fill="#00283B" />
        <path d="M82.8323 194.457V167.094C82.8323 167.094 82.735 167.321 82.7026 167.451C82.3785 168.23 82.2489 169.074 82.2489 169.918V194.489C82.2489 195.56 80.5312 196.469 78.3921 196.599V197.215C80.9201 197.118 82.8323 195.95 82.8323 194.521V194.457Z" fill="#B0E2FA" />
        <path d="M77.7438 204.356C77.7438 204.356 77.8411 204.356 77.9059 204.356C78.6189 204.356 79.2671 204.097 79.2671 203.707C79.2671 203.707 79.2995 203.545 79.2995 203.512V203.35V196.956H79.4292L77.7114 197.086V204.291L77.7438 204.356Z" fill="#00283B" />
        <path d="M9.97461 196.729L13.5073 194.911L14.1555 173.748L11.6599 172.677L9.97461 196.729Z" fill="#00283B" />
        <path d="M13.2156 173.456L12.7295 180.207L47.2461 200.623V193.645L13.2156 173.456Z" fill="#B0E2FA" />
        <path d="M14.7387 176.052L14.1553 182.771L46.9542 202.149V195.17L14.7387 176.052Z" fill="#F5FCFF" />
        <path d="M47.5377 201.143L12.4053 180.369L12.9562 172.937L47.5377 193.45V201.11V201.143ZM13.0535 180.045L46.9543 200.104V193.84L13.5072 173.975L13.0535 180.045Z" fill="#00283B" />
        <path d="M37.5882 239.606C37.3289 239.606 37.0696 239.541 36.8428 239.411L17.3967 228.018C16.1976 227.304 15.4521 226.006 15.4521 224.61L15.5494 208.803C15.5494 208.251 15.8411 207.764 16.3272 207.505C16.8134 207.245 17.3643 207.245 17.8505 207.505L37.2641 219.19C38.4308 219.904 39.1763 221.202 39.1763 222.565V238.113C39.1763 238.664 38.8846 239.151 38.4308 239.411C38.204 239.541 37.9447 239.606 37.6854 239.606H37.5882ZM17.0078 207.862C17.0078 207.862 16.7161 207.894 16.5541 207.991C16.2624 208.154 16.1003 208.446 16.1003 208.77L16.0031 224.578C16.0031 225.746 16.6189 226.882 17.656 227.466L37.102 238.859C37.3937 239.022 37.7178 239.022 38.0095 238.859C38.3012 238.697 38.4633 238.405 38.4633 238.08V222.533C38.4633 221.364 37.8475 220.293 36.8428 219.676L17.4292 207.991C17.4292 207.991 17.1375 207.862 16.9754 207.862H17.0078Z" fill="#00283B" />
        <path d="M19.4705 226.915C18.3686 226.266 17.6555 225.065 17.6879 223.766L17.7852 207.959C17.7852 207.959 17.7852 207.862 17.7852 207.829L17.6231 207.732C16.8129 207.245 15.8082 207.829 15.8082 208.771L15.7109 224.578C15.7109 225.876 16.3915 227.077 17.4935 227.726L36.9395 239.119C37.6849 239.573 38.6248 239.054 38.722 238.21L19.4381 226.915H19.4705Z" fill="#00283B" />
        <path d="M37.5555 239.606C37.2962 239.606 37.0369 239.541 36.81 239.411L17.364 228.018C16.1649 227.304 15.4194 226.006 15.4194 224.61L15.5167 208.803C15.5167 208.251 15.8084 207.764 16.2945 207.505C16.7807 207.245 17.3316 207.245 17.8178 207.505L18.1419 207.699V207.894L17.7854 207.991H18.0771L17.9798 223.799C17.9798 224.967 18.5956 226.103 19.6327 226.687L39.0787 238.08V238.275C38.9815 238.794 38.6898 239.216 38.2361 239.443C38.0416 239.541 37.8147 239.606 37.5879 239.606H37.5555ZM16.9751 207.862C16.9751 207.862 16.6834 207.894 16.5214 207.991C16.2297 208.154 16.0676 208.446 16.0676 208.77L15.9704 224.578C15.9704 225.746 16.5862 226.882 17.6233 227.466L37.0693 238.859C37.3286 239.022 37.6527 239.022 37.912 238.859C38.1388 238.762 38.3009 238.567 38.3657 238.34L19.2762 227.142C18.0771 226.428 17.3316 225.129 17.3316 223.734L17.4289 207.959C17.4289 207.959 17.1372 207.829 16.9751 207.829V207.862Z" fill="#00283B" />
        <path d="M36.5507 226.395C36.6155 223.182 33.9903 221.916 33.9903 221.916C33.9903 221.916 22.8413 215.165 20.9939 214.029C18.9845 212.795 19.1465 215.23 19.1465 215.23L19.0493 231.718L20.3781 232.822V228.213C20.3781 228.213 20.4754 226.493 20.8643 225.973C21.0911 225.649 22.1607 226.33 22.1607 226.33C22.1607 226.33 30.5225 231.621 32.5967 232.854C34.6709 234.088 34.4441 235.776 34.4441 235.776L34.3792 241.683L36.4211 243.046C36.4211 243.046 36.4535 229.868 36.5507 226.395ZM20.8643 223.214C20.8643 223.214 20.605 223.085 20.4754 223.052V219.06C20.4754 219.06 20.3781 217.275 20.767 216.755C20.9939 216.431 22.0634 217.112 22.0634 217.112C22.0634 217.112 30.5873 222.468 32.6615 223.701C34.7358 224.935 34.5089 226.622 34.5089 226.622L34.4441 231.491C34.0876 231.232 33.8283 231.102 33.8283 231.102C33.8283 231.102 22.6792 224.35 20.8319 223.214H20.8643Z" fill="#F5FCFF" />
        <path d="M36.7128 243.598L34.0552 241.845L34.12 235.776C34.12 235.776 34.2821 234.25 32.4023 233.114C30.3605 231.881 22.0311 226.655 21.9663 226.59C21.6098 226.363 21.156 226.168 21.0264 226.201C20.8319 226.493 20.7023 227.596 20.7023 228.213L20.6375 233.439L18.6929 231.848L18.7901 215.197C18.7901 215.197 18.7253 213.931 19.4383 213.509C19.8596 213.25 20.4106 213.347 21.1236 213.769C22.971 214.905 34.12 221.656 34.12 221.656C34.2173 221.689 36.9073 223.02 36.8425 226.395C36.7777 229.836 36.7128 242.884 36.7128 243.014V243.566V243.598ZM34.671 241.521L36.1295 242.462C36.1295 239.93 36.1943 229.414 36.2591 226.363C36.3239 223.409 33.958 222.208 33.8608 222.176C33.8608 222.176 22.6793 215.392 20.8319 214.288C20.5078 214.094 20.0541 213.866 19.7948 214.029C19.4707 214.224 19.4383 214.938 19.4383 215.197L19.3411 231.556L20.0865 232.173V228.213C20.1513 227.888 20.2161 226.363 20.6375 225.811C20.994 225.357 21.8366 225.811 22.3228 226.103C22.3876 226.168 30.717 231.394 32.7588 232.627C34.9951 233.958 34.7682 235.743 34.7682 235.841L34.7034 241.553L34.671 241.521ZM34.7682 232.076L34.2821 231.719C33.958 231.491 33.7311 231.361 33.7311 231.361C33.7311 231.361 22.5497 224.61 20.7023 223.474C20.6051 223.409 20.5402 223.377 20.4754 223.377L20.1837 223.247V219.06C20.1837 218.768 20.1189 217.145 20.5402 216.593C20.8968 216.139 21.7394 216.593 22.2256 216.885C22.3228 216.95 30.7818 222.241 32.8236 223.474C35.0599 224.805 34.8331 226.59 34.8331 226.688L34.7682 232.108V232.076ZM34.0228 230.81C34.0228 230.81 34.0876 230.81 34.1849 230.907L34.2497 226.59C34.2497 226.59 34.4117 225.065 32.5319 223.961C30.4901 222.728 21.9987 217.437 21.9015 217.372C21.545 217.145 21.0912 216.95 20.9616 216.982C20.7995 217.242 20.7671 218.346 20.7995 219.027V222.825C20.7995 222.825 20.9292 222.89 21.0264 222.955C22.8738 224.091 34.0228 230.842 34.0228 230.842V230.81Z" fill="#00283B" />
        <path d="M39.7271 246.26L13.7667 230.16C13.3778 229.901 13.2481 229.349 13.5398 228.927C13.7991 228.537 14.3501 228.408 14.7714 228.7L40.7318 244.799C41.1207 245.059 41.2504 245.61 40.9587 246.032C40.6994 246.422 40.1484 246.552 39.7271 246.26Z" fill="#B0E2FA" />
        <path d="M39.7271 247.039L13.7667 230.939C13.3778 230.68 13.2481 230.128 13.5398 229.706C13.7991 229.316 14.3501 229.187 14.7714 229.479L40.7318 245.578C41.1207 245.838 41.2504 246.39 40.9587 246.811C40.6994 247.201 40.1484 247.331 39.7271 247.039Z" fill="#F5FCFF" />
        <path d="M40.2132 246.714C39.9863 246.714 39.7594 246.649 39.565 246.519L13.6046 230.453C13.0536 230.096 12.9239 229.349 13.2805 228.797C13.637 228.245 14.3824 228.116 14.9334 228.473L40.8938 244.539C41.4447 244.897 41.5744 245.643 41.2179 246.195C40.991 246.519 40.6021 246.714 40.2456 246.714H40.2132ZM14.2528 228.862C14.0583 228.862 13.8962 228.96 13.7666 229.122C13.5721 229.381 13.6694 229.739 13.9287 229.933L39.8891 246.033C40.1483 246.227 40.5049 246.13 40.6993 245.87C40.8938 245.611 40.7965 245.254 40.5373 245.059L14.5769 228.992C14.5769 228.992 14.35 228.895 14.2528 228.895V228.862Z" fill="#00283B" />
        <path d="M29.7771 216.561L26.763 214.808C26.1148 214.451 25.5962 213.445 25.5962 212.568C25.5962 211.724 26.1148 211.302 26.763 211.692L29.7771 213.445C30.4253 213.802 30.9438 214.808 30.9438 215.684C30.9438 216.528 30.4253 216.95 29.7771 216.561Z" fill="#B0E2FA" />
        <path d="M30.2629 217.567L27.2488 215.814C26.6006 215.457 26.082 214.451 26.082 213.575C26.082 212.731 26.6006 212.309 27.2488 212.698L30.2629 214.451C30.9111 214.808 31.4297 215.814 31.4297 216.691C31.4297 217.534 30.9111 217.956 30.2629 217.567Z" fill="#F5FCFF" />
        <path d="M30.2307 217.015C30.0362 217.015 29.8094 216.95 29.6149 216.82L26.6008 215.068C25.8554 214.646 25.272 213.542 25.272 212.568C25.272 212.016 25.4664 211.562 25.8229 211.367C26.147 211.205 26.5036 211.205 26.8925 211.432L29.9066 213.185C30.652 213.607 31.2354 214.71 31.2354 215.684C31.2354 216.236 31.041 216.69 30.6844 216.885C30.5548 216.95 30.3928 217.015 30.2307 217.015ZM26.3091 211.887C26.3091 211.887 26.2119 211.887 26.147 211.919C25.985 212.016 25.8878 212.276 25.8878 212.601C25.8878 213.347 26.3415 214.256 26.8925 214.581L29.9066 216.333C29.9066 216.333 30.1983 216.496 30.3603 216.398C30.5224 216.301 30.6196 216.041 30.6196 215.717C30.6196 214.97 30.1659 214.061 29.6149 213.737L26.6008 211.984C26.6008 211.984 26.4063 211.887 26.2767 211.887H26.3091Z" fill="#00283B" />
        <path d="M53.8579 249.636C53.3717 249.993 52.7235 250.122 52.1077 250.155C51.9781 250.35 51.7836 250.577 51.6216 250.772C52.5291 250.772 53.469 250.642 54.2144 250.122C54.7978 249.7 55.3811 249.538 55.8997 249.571C55.8997 249.408 55.8997 249.214 55.8997 248.986C55.2839 248.954 54.6033 249.149 53.9227 249.636H53.8579Z" fill="#B0E2FA" />
        <path d="M100.074 227.174L99.5557 227.531V224.318L100.074 223.961V227.174Z" fill="#B0E2FA" />
    </SvgIcon>
)

export default TruckOpen