import {
    getClientLoading,
    getClientReject,
    getClientRequest,
    getClientSuccess
} from "../../actions/client/getAll";

export const getClient = (formData) => async (dispatch, getState) => {
    dispatch(getClientLoading());
    try {
        const { data } = await getClientRequest(formData, getState);
        dispatch(getClientSuccess(data))
    } catch (error) {
        dispatch(getClientReject(error))
    }
    return Promise.resolve();
};