import {
    getRouteCostAllLoading,
    getRouteCostAllReject,
    getRouteCostAllRequest,
    getRouteCostAllSuccess
} from "../../actions/route/getCostAll";

export const getRouteCostAll = (formData) => async (dispatch, getState) => {
    dispatch(getRouteCostAllLoading());
    try {
        const { data } = await getRouteCostAllRequest(formData, getState);
        dispatch(getRouteCostAllSuccess(data))
    } catch (error) {
        dispatch(getRouteCostAllReject(error))
    }
    return Promise.resolve();
};