import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/es';

import theme from "./config/theme";
import RouterApp from "./config/routes/Provider";
import ProviderI18n from "./config/i18n";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ProviderI18n>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
          <RouterApp />
        </LocalizationProvider>
      </ProviderI18n>
    </ThemeProvider>
  );
}

export default App;
