import {
    postRouteOrderLoading,
    postRouteOrderRequest,
    postRouteOrderSuccess,
    postRouteOrderReject,
    postRouteOrderReset
} from "../../actions/route/postOrders";

export const postRouteOrder = (formData) => async (dispatch, getState) => {
    dispatch(postRouteOrderLoading());
    try {
        const { data } = await postRouteOrderRequest(formData, getState);
        dispatch(postRouteOrderSuccess(data))
    } catch (error) {
        dispatch(postRouteOrderReject(error))
    } finally {
        setTimeout(() => { dispatch(postRouteOrderReset()) }, 3000);
    }
    return Promise.resolve();
};