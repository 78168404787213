import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_COST_RESET = 'POST_COST_RESET';
export const POST_COST_LOADING = 'POST_COST_LOADING';
export const POST_COST_SUCCESS = 'POST_COST_SUCCESS';
export const POST_COST_REJECT = 'POST_COST_REJECT';

//* ACTIONS ------------------------------------------------
export const postCostReset = () => ({ type: POST_COST_RESET });
export const postCostLoading = () => ({ type: POST_COST_LOADING });
export const postCostSuccess = (payload) => ({ type: POST_COST_SUCCESS, payload });
export const postCostReject = (payload) => ({ type: POST_COST_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postCostRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/TypeCosts`, options);
};


