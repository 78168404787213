/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Link as LinkUi,
    FormControl,
    InputAdornment,
    IconButton,
    Paper,
    Stack,
    Button,
    Alert,
    AlertTitle,
    Collapse,
    OutlinedInput,
    FormHelperText
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { get, isEmpty, map } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff, Error as ErrorIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";

import LayoutAuth from "../../../components/layout/LayoutAuth";
import validator from "./validator";

import { login } from "../../../store/auth/thunk/login";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginState = useSelector(state => state.auth.login);
    const [showPass, setShowPass] = useState(false);
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error" })

    const [__, i18n] = useTranslation("login");
    const inputs = __('input', { returnObjects: true })
    const links = __('link', { returnObjects: true })

    const handleClickShowPassword = () => {
        setShowPass(state => !state)
    }

    const formik = useFormik({
        initialValues: {
            login: '',
            pass: '',
            idLanguage: i18n.resolvedLanguage,
            deviceToken: process.env.REACT_APP_DEVICE_TOKEN,
        },
        validationSchema: validator(inputs),
        onSubmit: (values) => dispatch(login(values))
    });

    useEffect(() => {
        let timer = null;
        if (get(loginState, "isLoged")) {
            navigate("/")
        } else if (get(loginState, "isReject") && !isEmpty(get(formik, "touched"))) {
            const msg = get(loginState, "allResp")
            setShowNoti({ open: true, msg, variant: "error" })
            timer = setTimeout(() => {
                localStorage.clear()
            }, 3000);
        } else {
            setShowNoti({ open: false, msg: undefined, variant: "" })
        }
        return () => {
            clearTimeout(timer)
        }
    }, [loginState, navigate, isEmpty(get(formik, "touched"))])

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("401"))) {
            const msg = { Message: __("expire") };
            setShowNoti({ open: true, msg, variant: "error" })
            setTimeout(() => { localStorage.removeItem("401") }, 500);
        }
    }, [])


    return (
        <LayoutAuth title={__('name')} type="login">
            <Box className='flex flex-col justify-center items-center h-full'>
                <Paper className='py-8 px-4 sm:px-12 w-full relative' elevation={3} style={{ minWidth: 360, maxWidth: "calc(100vw - 40px)" }} >
                    <Box className='mb-2 text-center' ><Typography variant='h3'>{__("title")}</Typography></Box>
                    <Box className='mb-8 text-center' ><Typography variant='caption'>{__("subtitle")}</Typography></Box>
                    <Collapse in={get(showNoti, "open")}>
                        <Alert severity="error" sx={{ mb: 2 }} icon={<ErrorIcon sx={{ color: "error.dark" }} />}>
                            <AlertTitle><Typography variant="body1" color="error.dark">{get(showNoti, "msg.Message") || `Code: ${get(showNoti, "msg.ErrorCode")}`}</Typography></AlertTitle>
                            {isEmpty(get(showNoti, "msg.ValidationError", []))
                                ? (
                                    <Typography variant="caption">{get(showNoti, "msg.ErrorMessage")}</Typography>
                                ) : (
                                    <ul>
                                        {map(get(showNoti, "msg.ValidationError", []), (err, i) => (
                                            <Typography key={i} component="li" variant="caption">- {err}</Typography>
                                        ))}
                                    </ul>
                                )
                            }
                        </Alert>
                    </Collapse>
                    <Box component="form" onSubmit={get(formik, "handleSubmit")}>
                        <Box className='mb-8'>
                            <FormControl fullWidth >
                                <Typography className='pb-2 px-2' component="label" htmlFor="email" >
                                    {get(inputs, "email.name")} <Typography component="span" color="error">*</Typography>
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="login"
                                    size='small'
                                    placeholder={get(inputs, "email.placeholder")}
                                    value={get(formik, "values.login")}
                                    onChange={get(formik, "handleChange")}
                                    error={get(formik, "touched.login") && Boolean(get(formik, "errors.login"))}
                                    helperText={get(formik, "touched.login") && get(formik, "errors.login")}
                                />
                            </FormControl>
                        </Box>
                        <Box className='mb-4'>
                            <FormControl fullWidth >
                                <Typography className='pb-2 px-2' component="label" htmlFor="password" >
                                    {get(inputs, "pass.name")} <Typography component="span" color="error">*</Typography>
                                </Typography>
                                <OutlinedInput
                                    id="password"
                                    name="pass"
                                    placeholder={get(inputs, "pass.placeholder")}
                                    type={showPass ? 'text' : 'password'}
                                    value={get(formik, "values.pass")}
                                    onChange={get(formik, "handleChange")}
                                    error={get(formik, "touched.pass") && Boolean(get(formik, "errors.pass"))}
                                    size='small'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPass ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="helper-password" error={get(formik, "touched.pass") && Boolean(get(formik, "errors.pass"))} >
                                    {get(formik, "touched.pass") && get(formik, "errors.pass")}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box className='mb-8 text-right'>
                            <LinkUi className='pointer-events-none' component={Link} to={get(links, "forgot.href")} underline="always" color="secondary" >
                                <Typography component="span" variant='body' >{get(links, "forgot.name")}</Typography>
                            </LinkUi>
                        </Box>
                        <Stack spacing={3}>
                            <LoadingButton
                                color="secondary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size='large'
                                loading={get(loginState, "isLoading")}
                                disabled={!isEmpty(get(formik, "errors")) && !isEmpty(get(formik, "touched"))}
                            >
                                {__("button.submit")}
                            </LoadingButton>

                            <Button component={Link} to={get(links, "register.href")} fullWidth variant="text" size='large' color="secondary">
                                {get(links, "register.name")}
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </LayoutAuth>
    )
}

export default Login