import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_COST_RESET = 'PUT_COST_RESET';
export const PUT_COST_LOADING = 'PUT_COST_LOADING';
export const PUT_COST_SUCCESS = 'PUT_COST_SUCCESS';
export const PUT_COST_REJECT = 'PUT_COST_REJECT';

//* ACTIONS ------------------------------------------------
export const putCostReset = () => ({ type: PUT_COST_RESET });
export const putCostLoading = () => ({ type: PUT_COST_LOADING });
export const putCostSuccess = (payload) => ({ type: PUT_COST_SUCCESS, payload });
export const putCostReject = (payload) => ({ type: PUT_COST_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putCostRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/TypeCosts`, options);
};


