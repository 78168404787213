/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
    MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import validator from "./validator"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import ChangePass from "./ChangePass"

import { getOperatorsId } from "../../../store/masters/thunk/operators/getId"
import { postOperators } from "../../../store/masters/thunk/operators/post"
import { putOperators } from "../../../store/masters/thunk/operators/put"

const NewEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__, i18n] = useTranslation("operators");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [openChangePass, setOpenChangePass] = useState(false)

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.masters.operators.detail);
    const put = useSelector(state => state.masters.operators.put);
    const putPass = useSelector(state => state.masters.operators.putPass);
    const post = useSelector(state => state.masters.operators.post);

    const closeChangePass = () => {
        setOpenChangePass(false)
    }

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/masters/operators/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
        if (get(putPass, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); closeChangePass(); }
        if (get(putPass, "isReject", false)) { setError(get(putPass, "error", {}), "put") }
    }, [put, putPass, post])

    useEffect(() => {
        if (!!id) {
            dispatch(getOperatorsId({ id }))
        }
    }, [dispatch, id])

    const onSubmit = (values) => {
        if (id) { dispatch(putOperators(values)) }
        else { dispatch(postOperators(values)) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,
        name: id ? get(current, "data.name", "") : "",
        phone: id ? get(current, "data.phone", "") : "",
        email: id ? get(current, "data.email", "") : "",
        idUser: id ? get(current, "data.idUser", "") : 0,
        pass: "",

        idCompany: get(user, "idCompany"),
        idUserType: 2,
        idLanguage: i18n.resolvedLanguage,
    }

    return (
        <Layout
            propsToolbar={{
                title: !id ? __(`head.new.name`) : __(`head.edit.name`),
                srute: !id ? __(`head.new.code`) : id
            }}
        >
            {get(current, "isLoading")
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__, id)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='p-4'>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" loading={get(post, "isLoading") || get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                        <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/masters/operators")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                                    </Stack>
                                </Paper>
                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                        <Stack direction="row" spacing={2}>
                                            {id &&
                                                <Button size="small" variant="contained" color="secondary" onClick={() => setOpenChangePass(true)} disabled={get(post, "isLoading") || get(put, "isLoading")} >{"Cambiar contraseña"}</Button>
                                            }
                                            <Switch formik={formik} name="isActive" __={__} />
                                        </Stack>
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="name"
                                                    label={__(`form.name.label`)}
                                                    placeholder={__(`form.name.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={12}>
                                                <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.2`)}</Typography>
                                            </Grid> */}
                                            <Grid item xs={12} sm={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="phone"
                                                    label={__(`form.phone.label`)}
                                                    placeholder={__(`form.phone.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.3`)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="email"
                                                    label={__(`form.email.label`)}
                                                    placeholder={__(`form.email.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            {!id &&
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <InputText
                                                        name="pass"
                                                        formik={formik}
                                                        type="password"
                                                        label={__(`form.pass.label`)}
                                                        placeholder={__(`form.pass.placeholder`)}
                                                        disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
            <ChangePass
                __={__}
                open={openChangePass}
                onClose={closeChangePass}
                current={current}
            />
        </Layout >
    )
}

export default NewEdit