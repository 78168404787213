import {
    postTransportSupplierLoading,
    postTransportSupplierRequest,
    postTransportSupplierSuccess,
    postTransportSupplierReject,
    postTransportSupplierReset
} from "../../actions/transportSupplier/post";

export const postTransportSupplier = (formData) => async (dispatch, getState) => {
    dispatch(postTransportSupplierLoading());
    try {
        const { data } = await postTransportSupplierRequest(formData, getState);
        dispatch(postTransportSupplierSuccess(data))
    } catch (error) {
        dispatch(postTransportSupplierReject(error))
    } finally {
        setTimeout(() => { dispatch(postTransportSupplierReset()) }, 3000);
    }
    return Promise.resolve();
};