export const skyblue = {
    0: '#F5FCFF',
    100: '#B0E2FA',
    200: '#71C7EF',
    300: '#40ACDE',
    400: '#2090C4;',
    500: '#0C709F',
    600: '#034D6F',
    700: '#00283B',
};
export const navy = {
    0: '#F5F9FF',
    100: '#EDF3FB',
    200: '#DFE7F3',
    300: '#C8D4E5',
    400: '#A7B8D1',
    500: '#7F94B4',
    600: '#556C8F',
    700: '#324766',
};
export const blue = {
    0: '#E9EBFF',
    100: '#B0BBFA',
    200: '#7083EF',
    300: '#4058DD',
    400: '#1F38C2',
    500: '#0C229C',
    600: '#03126B',
    700: '#000836',
};
export const neutral = {
    0: '#F7FAFF',
    100: '#E9EFFB',
    200: '#D3DCEF',
    300: '#B8C2D8',
    400: '#97A1B6',
    500: '#71798B',
    600: '#4B515D',
    700: '#272A30',
};
export const error = {
    0: '#FFF5F5',
    100: '#FE6767',
    200: '#F51919',
    300: '#AD0000',
};
export const warning = {
    0: '#FFF8E8',
    100: '#FED261',
    200: '#F5B617',
    300: '#AB7A00',
};
export const success = {
    0: '#E2FFDE',
    100: '#6EF359',
    200: '#2CCF13',
    300: '#138C00',
};
export const info = {
    0: '#F5FDFF',
    100: '#75DEFE',
    200: '#2EC3F0',
    300: '#0E6B87',
};
