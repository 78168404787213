import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_ALL_COORDS_ROUTE_LOADING = 'GET_ROUTE_ALL_COORDS_ROUTE_LOADING';
export const GET_ROUTE_ALL_COORDS_ROUTE_SUCCESS = 'GET_ROUTE_ALL_COORDS_ROUTE_SUCCESS';
export const GET_ROUTE_ALL_COORDS_ROUTE_REJECT = 'GET_ROUTE_ALL_COORDS_ROUTE_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteAllCoordsRouteLoading = () => ({ type: GET_ROUTE_ALL_COORDS_ROUTE_LOADING });
export const getRouteAllCoordsRouteSuccess = (payload) => ({ type: GET_ROUTE_ALL_COORDS_ROUTE_SUCCESS, payload });
export const getRouteAllCoordsRouteReject = (payload) => ({ type: GET_ROUTE_ALL_COORDS_ROUTE_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteAllCoordsRouteRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Route/GetRoutePointsById/${params.id}`, options);
};
