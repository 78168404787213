import {
    putTransportSupplierLoading,
    putTransportSupplierRequest,
    putTransportSupplierSuccess,
    putTransportSupplierReject,
    putTransportSupplierReset
} from "../../actions/transportSupplier/put";

export const putTransportSupplier = (formData) => async (dispatch, getState) => {
    dispatch(putTransportSupplierLoading());
    try {
        const { data } = await putTransportSupplierRequest(formData, getState);
        dispatch(putTransportSupplierSuccess(data))
    } catch (error) {
        dispatch(putTransportSupplierReject(error))
    } finally {
        setTimeout(() => { dispatch(putTransportSupplierReset()) }, 3000);
    }
    return Promise.resolve();
};