import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Checkbox,
    Stack,
    OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import find from "lodash/find";
import includes from "lodash/includes";
import trim from "lodash/trim";
import InputText from "../../../components/form/InputText";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import FormatMoney from "../../../components/form/FormatMoney"
import Table from "../../../components/form/Table";

import { getCost } from "../../../store/transactions/thunk/cost/getAll";
import { postRouteCost } from "../../../store/transactions/thunk/route/postCost";
import { putRouteCost } from "../../../store/transactions/thunk/route/putCost";

const Alert = ({
    __,
    open,
    close,
    selected,
    setSelected
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [searchValue, setSearchValue] = useState("");
    const [costFilterd, setCostFilterd] = useState([]);
    const [totalCost, setTotalCost] = useState([])

    const actualCosts = useSelector(state => state.transactions.route.costAll);
    const cost = useSelector(state => state.transactions.cost);
    const put = useSelector(state => state.transactions.route.putCost);
    const post = useSelector(state => state.transactions.route.postCost);

    const costAdded = []
    const costAll = get(cost, "data", [])
    const costFilterdPre = filter(costAll, ({ costId }) => !(find(costAdded, (id) => id === costId)))

    useEffect(() => {
        if (!!searchValue) {
            setCostFilterd(
                filter(costFilterdPre, ({ nameCost, amount }) =>
                    includes(nameCost?.toLowerCase(), searchValue)
                )
            )
        } else {
            setCostFilterd(costFilterdPre)
        }
    }, [searchValue, get(costFilterdPre, "length")])


    useEffect(() => {
        dispatch(getCost({}))
    }, [])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = map(costFilterd, (n) => n.idTypeCost);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => {
        return selected.indexOf(id) !== -1
    }

    const handleChecked = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const headTable = [
        {
            key: "checkbox",
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < costFilterd.length}
                    checked={costFilterd.length > 0 && selected.length === costFilterd.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            align: "center",
            width: 70,
        },
        {
            key: "totalCost",
            label: __(`table.colsCosts.amount`),
            align: "left",
        },
        {
            key: "nameCost",
            label: __(`table.colsCosts.costName`),
            align: "left"
        },
    ]

    const dataTable = map(costFilterd, (row, i) => {
        const isItemSelected = isSelected(get(row, "idTypeCost"));
        const labelId = `enhanced-table-checkbox-${i}`;

        // const getObjectDelivery = () => {
        //     return find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === row.idDeliveryPoint)
        // }
        // console.log(getObjectDelivery())
        // console.log(deliveryPoint.data)

        return ({
            ...row,
            checkbox: (
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(e, v) => handleChecked(e, get(row, "idTypeCost"))}
                    inputProps={{ 'aria-labelledby': labelId, }}
                    disabled={get(post, "isLoading") || get(put, "isLoading")}
                />
            ),
            totalCost: find(selected, (item) => item === row.idTypeCost) ? <Box sx={{ ".MuiInputBase-root": { height: 25 } }}><InputText name="totalCost" size="small" defaultValue={get(find(actualCosts.data, ({ idTypeCost }) => idTypeCost === row.idTypeCost), "totalCost", 0)} sx={{ width: 75 }} onChange={(e) => setTotalCost(state => ({ ...state, [row.idTypeCost]: e.target.value }))} InputProps={{ inputComponent: FormatMoney }} /> </Box> : <div className='w-[75px]' />,
            // name: get(getObjectDelivery(), "contactName", ""),
        })
    })

    const postCostToRoute = () => {
        const body = {
            costs: map(selected, (item) => ({ idTypeCost: item, idRoute: id, totalCost: totalCost[item] })),
            idRoute: id,
        }
        if (get(actualCosts, "data.length") > 1) {
            dispatch(putRouteCost(body))
        } else {
            dispatch(postRouteCost(body))
        }
        setSelected([])
    }

    const onChange = (e) => {
        setSearchValue(trim(e.target.value))
    }

    return (
        <div>
            <Dialog onClose={close} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {__("labels.6")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div className='px-8 pyt-2'>
                    <OutlinedInput
                        id="search-btn"
                        endAdornment={
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        size="small"
                        fullWidth
                        onChange={onChange}
                        placeholder={__("action.search")}
                    />
                </div>
                <Table
                    headTable={headTable}
                    dataTable={dataTable}
                    __={__}
                    sizeFilters={125}
                    loading={get(cost, "isLoading", false)}
                    empty="costs"
                    propsTable={{
                        size: "small"
                    }}
                />
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton variant="contained" color="secondary" onClick={postCostToRoute} disabled={get(post, "isLoading") || get(put, "isLoading") || isEmpty(selected)} loading={get(post, "isLoading") || get(put, "isLoading")}>{__("action.save")}</LoadingButton>
                    <Button variant='outlined' color="secondary" onClick={close} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default Alert