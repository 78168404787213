import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
    Dialog,
    Box,
    Typography,
    IconButton,
    Stack,
    Divider,
    Avatar,
    CardMedia,
    ButtonBase
} from '@mui/material';
import { useTranslation } from "react-i18next";

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Empty from "../../../assets/icons/Empty"

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import { getOrderImages } from "../../../store/transactions/thunk/order/getOrderImages";

const RoutesModal = ({
    __,
    open,
    close,
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [imgSelect, setImgSelect] = useState({})
    const [__g] = useTranslation("global");

    useEffect(() => {
        dispatch(getOrderImages({ idOrder: id }))
    }, [])

    const images = useSelector(state => state.transactions.order.images);

    useEffect(() => {
        setImgSelect(get(images, "data.[0]"))
    }, [get(images, "isSuccess", false), open])

    const onClick = (item) => () => {
        setImgSelect(item)
    }

    const onClose = ()=> {
        setImgSelect({})
        close()
    }

    return (
        <div>
            <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                    {__("labels.4")}
                </Typography>
                <Divider />
                {open &&
                    <Stack direction={{ xs: "column-reverse", md: "row" }} className='p-4'>
                        <Box className="max-w-auto md:max-w-[300px] max-h-[300px] md:max-h-[400px] overflow-scroll overflow-x-hidden">
                            {
                                map(get(images, "data"), (pict, i) => (
                                    <ButtonBase className="flex flex-row" a onClick={onClick(pict)} >
                                        <Box>
                                            <Avatar className='border border-black rounded' variant="square" alt={`pict-${i + 1}}`} src={pict.url} />
                                        </Box>
                                        <Box className="p-2 text-left">
                                            <Typography className='!leading-[1.2]' comopnent="div" variant="h6">{i + 1}</Typography>
                                            <Typography className='!leading-[1.2] w-[250px] !truncate' component="div" variant="caption">{pict.localPath}</Typography>
                                        </Box>
                                    </ButtonBase>
                                ))
                            }
                        </Box>
                        <Box className='flex-1 max-h-[300px] md:max-h-[400px] h-[400px] mb-4 sm:mb-0 w-full rounded overflow-hidden pl-4'>
                            {!isEmpty(get(images, "data"))
                                ? (
                                    <div className='flex justify-center items-center w-full h-[300px] md:h-full max-h-full max-w-full bg-gray-200'>
                                        <CardMedia
                                            component="img"
                                            image={get(imgSelect, "url")}
                                            alt="green iguana"
                                            sx={{ objectFit: "contain", height: "100%", width: "100%" }}
                                        />
                                    </div>
                                ) : (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        height: 400,
                                        width: '100%',
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Empty />
                                        <Stack justifyContent="center" alignItems="center" spacing={1} pt={2} >
                                            <Typography variant="bml">{__(`table.empty.images.name`)}</Typography>
                                            <Typography variant="caption">{__(`table.empty.images.info`)}</Typography>
                                        </Stack>
                                    </Box>
                                )
                            }
                        </Box>
                    </Stack>
                }
            </Dialog>
        </div >
    );
}

export default RoutesModal