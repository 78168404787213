import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Stack,
    Grid,
} from '@mui/material';
import {
    Timeline,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useTranslation } from "react-i18next";

import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FlagIcon from '@mui/icons-material/Flag';

import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import MapMany from "../../../components/map/MapMany3"
import Empty from "../../../assets/icons/Empty"

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"

import { getRouteAllCoords } from "../../../store/transactions/thunk/route/getAllCoords";

const RoutesModal = ({
    __,
    open,
    close,
    selected,
    setSelected
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [km, setKm] = useState(null)
    const [formatedData, setFormatedData] = useState([]);
    const [formatedDataMain, setFormatedDataMain] = useState([]);
    const [__g] = useTranslation("global");

    useEffect(() => {
        dispatch(getRouteAllCoords({ id }))
    }, [])

    const coordsAll = useSelector(state => state.transactions.route.coordsAll);
    const coordsAllRoute = useSelector(state => state.transactions.route.coordsAllRoute);

    useEffect(() => {
        setFormatedData(
            map(get(coordsAll, "data"), (item, i) => ({
                "location": {
                    "id": i + 1,
                    "lat": Number(item.latitude),
                    "lng": Number(item.longitude),
                    "date": item.dateCreated
                }
            }))
        )
        setFormatedDataMain(
            map(get(coordsAllRoute, "data"), (item, i) => ({
                "location": {
                    "id": `${item.order + 1}`,
                    "lat": Number(item.latitude),
                    "lng": Number(item.longitude),
                    "deliveryName": item.deliveryName
                }
            }))
        )
    }, [get(coordsAll, "isSuccess", false)])

    return (
        <div>
            <Dialog onClose={close} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {open &&
                    <Grid container>
                        <Grid item sx={12} md={6} xl={8}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                                {__("labels.7")}
                            </Typography>
                            <div className='h-[400px] w-full rounded overflow-hidden pl-4'>
                                {!isEmpty(formatedData)
                                    ? (
                                        <MapMany
                                            __={__}
                                            __g={__g}
                                            routes={formatedData}
                                            setKm={setKm}
                                            loading={get(coordsAll, "isLoading")}
                                            mainRoutes={formatedDataMain}
                                        />
                                    ) : (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: "column",
                                            height: 400,
                                            width: '100%',
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <Empty />
                                            <Stack justifyContent="center" alignItems="center" spacing={1} pt={2} >
                                                <Typography variant="bml">{__(`table.empty.name`)}</Typography>
                                                <Typography variant="caption">{__(`table.empty.info`)}</Typography>
                                            </Stack>
                                        </Box>
                                    )
                                }
                            </div>
                        </Grid>
                        <Grid item sx={12} md={6} xl={4}>
                            <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                                {__("labels.8")}
                            </Typography>
                            <Box>
                                <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <TimelineItem >
                                        <TimelineSeparator>
                                            <TimelineDot>
                                                <GpsFixedIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant="h6" component="span">
                                                {__g("map.labels.start")}
                                            </Typography>
                                            {!isEmpty(formatedData) && <Typography>{moment(get(formatedData, "[0].location.date")).format("L hh:mm a")}</Typography>}
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem >
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FlagIcon />
                                            </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant="h6" component="span">
                                                {__g("map.labels.end")}
                                            </Typography>
                                            {!isEmpty(formatedData) && <Typography>{moment(get(formatedData, `[${formatedData.length - 1}].location.date`)).format("L hh:mm a")}</Typography>}
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                                {get(km, "distance.value", null) &&
                                    <Stack className='px-8' direction="row" justifyContent="space-between">
                                        <Typography className='font-light' component="p" >
                                            {__("labels.9")}
                                        </Typography>
                                        <Typography className='font-light' component="p" >
                                            {get(km, "distance.label")}
                                        </Typography>
                                    </Stack>
                                }
                                {get(km, "duration.text", null) &&
                                    <Stack className='px-8' direction="row" justifyContent="space-between">
                                        <Typography className='font-light ' component="pre"  >
                                            {replace(__("labels.10"), "[time]", get(km, "duration.text"))}
                                        </Typography>
                                    </Stack>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                }
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={close}  >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default RoutesModal