import RouteCost from "../../../../pages/Report/RouteCost";

const routerRouteCost = [
    {
        key: "route-cost",
        path: "/report/route-cost",
        element: <RouteCost />,
    },
]

export default routerRouteCost
