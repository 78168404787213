import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  FormControl,
  Paper,
  Avatar,
  Grid,
  Stack,
  CircularProgress
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { get, words, map, join, upperCase, slice, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from '@mui/lab';
import LoginIcon from '@mui/icons-material/Login';
import UploadIcon from '@mui/icons-material/Upload';

// import TruckBoxes from "../../../assets/icons/TruckBoxes"
import Layout from "../../../components/layout/Layout";
import { validator_company, validator_user } from "./validator";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import ChangePass from "./ChangePass"

import { logout } from "../../../store/auth/thunk/logout";
import { getProfile } from "../../../store/auth/thunk/profile";

import { putProfileRequest, putCompanyRequest, postUserPhotoRequest, postCompanyPhotoRequest } from "../../../store/auth/actions/putProfile";
import { putOperatorsRequest } from "../../../store/masters/actions/operators/put";
import { putDriverRequest } from "../../../store/masters/actions/driver/put";


import { useEffect } from 'react';

const Profile = () => {
  const dispatch = useDispatch();
  const [__, i18n] = useTranslation("profile");

  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadPhotoUser, setLoadPhotoUser] = useState(false);
  const [loadPhotoCompany, setLoadPhotoCompany] = useState(false);
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "" });
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
  const [openChangePass, setOpenChangePass] = useState(false)

  const dataUser = useSelector(state => state.auth.login.dataUser);
  const login = useSelector(state => state.auth.login);
  const getState = useSelector(state => state);
  const putPass = useSelector(state => state.auth.login.putPass);

  const getData = async () => {
    await dispatch(getProfile({ id: get(dataUser, "idUser") }))
  }
  useEffect(() => {
    if (get(dataUser, "idUser")) {
      getData()
    }
  }, [get(dataUser, "idUser"), dispatch])

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }


  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
  }

  useEffect(() => {
    if (get(putPass, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); closeChangePass(); }
    if (get(putPass, "isReject", false)) { setError(get(putPass, "error", {}), "put") }
  }, [putPass])

  const onLogout = () => {
    const body = {
      idUser: get(dataUser, "idUser"),
      deviceToken: process.env.REACT_APP_DEVICE_TOKEN
    }
    dispatch(logout(body))
  }

  const short = (text) => {
    return (upperCase(join(slice(map(words(text), (w) => get(w, "[0]")), 0, 2), "")))
  }

  const closeChangePass = () => {
    setOpenChangePass(false)
  }

  const formik_user = useFormik({
    initialValues: {
      name: get(dataUser, "name"),
      phone: get(dataUser, "phone"),
      email: get(dataUser, "email"),
    },
    validationSchema: validator_user(__),
    onSubmit: async (values) => {
      const body = {
        ...dataUser,
        name: get(values, "name"),
        phone: get(values, "phone"),
        email: get(values, "email"),
      }

      // idUserType: 2, Operario
      // idUserType: 3, Conductor
      setLoadingUser(true)
      if (get(dataUser, "idUserType") === 1) {
        await putProfileRequest(body, () => getState)
          .then(({ data }) => { setLoadingUser(false); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
          .catch((err) => { setError(err.response.data); setLoadingUser(false); })
      } else if (get(dataUser, "idUserType") === 2) {
        await putOperatorsRequest(body, () => getState)
          .then(({ data }) => { setLoadingUser(false); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
          .catch((err) => { setError(err.response.data); setLoadingUser(false); })
      } else {
        await putDriverRequest(body, () => getState)
          .then(({ data }) => { setLoadingUser(false); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
          .catch((err) => { setError(err.response.data); setLoadingUser(false); })
      }
      getData()
    }
  });

  const formik_company = useFormik({
    initialValues: {
      name: get(dataUser, "companyData.name"),
      address: get(dataUser, "companyData.address"),
      contactPhone: get(dataUser, "companyData.contactPhone"),
    },
    validationSchema: validator_company(__),
    onSubmit: async (values) => {
      const body = {
        ...(get(dataUser, "companyData")),
        name: get(values, "name"),
        address: get(values, "address"),
        contactPhone: get(values, "contactPhone"),
      }

      setLoadingCompany(true)
      await putCompanyRequest(body, () => getState)
        .then(({ data }) => { setLoadingCompany(false); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
        .catch((err) => { setError(err.response.data); setLoadingCompany(false); })
      getData()
    }
  });

  const changeUserPhoto = async (e) => {
    const file = e.target.files[0]

    if (isEmpty(file)) {
      const body = {
        File: file,
        CompanyId: get(dataUser, "idCompany"),
        FileName: get(file, "name"),
        IdUserToUpload: get(dataUser, "idUser")
      }

      const formData = new FormData();
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          if (!!body[key]) {
            formData.append(`${key}`, body[key])
          }
        }
      }
      setLoadPhotoUser(true)
      await postUserPhotoRequest(body, () => getState)
        .then(({ data }) => { setLoadPhotoUser(false); getData(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
        .catch((err) => { setError(err.response.data); setLoadPhotoUser(false) })
    }
  }

  const changeCompanyPhoto = async (e) => {
    const file = e.target.files[0]
    if (isEmpty(file)) {
      const body = {
        File: file,
        CompanyId: get(dataUser, "idCompany"),
        FileName: get(file, "name")
      }

      const formData = new FormData();
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          if (!!body[key]) {
            formData.append(`${key}`, body[key])
          }
        }
      }
      setLoadPhotoCompany(true)
      await postCompanyPhotoRequest(body, () => getState)
        .then(async ({ data }) => { await getData(); setLoadPhotoCompany(false); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) })
        .catch((err) => { setError(err.response.data); setLoadPhotoCompany(false) })
    }
  }

  return (
    <Layout propsToolbar={{ title: "Mi Perfil" }} >
      <Box>
        <Paper className='flex justify-between items-center py-6 px-8 w-full mb-6 relative' sx={{ bgcolor: theme => theme.palette.color.navy[700] }} elevation={1} >
          <Stack className='w-full' direction={{ xs: "column", md: "row" }} justifyContent="space-between">
            <Box className='flex'>
              <div className='relative h-[80px] w-[80px] bg-[lightgray] rounded-full'>
                <input type="file" id="photo-user" hidden onChange={changeUserPhoto} />
                <Avatar
                  alt={short(get(dataUser, "name"))}
                  sx={{ height: 80, width: 80 }}
                  src={loadPhotoUser ? "/" : get(dataUser, "profileImageUrl")}
                >
                  {short(get(dataUser, "name"))}
                </Avatar>
                {loadPhotoUser &&
                  <div className='opacity-100 absolute top-0 right-0 left-0 bottom-0 z-[10] flex justify-center items-center rounded-full bg-[rgba(255,255,255,0.5)]'>
                    <CircularProgress size={30} />
                  </div>
                }
                {!loadPhotoUser &&
                  <label for="photo-user" className='opacity-0 hover:opacity-100 transition duration-300 cursor-pointer absolute top-0 right-0 left-0 bottom-0 z-[10] flex justify-center items-center rounded-full bg-[rgba(255,255,255,0.5)]'>
                    <UploadIcon fontSize="large" />
                  </label>
                }
              </div>
              <Stack className='pl-4' justifyContent="center" color="common.white" >
                <Typography variant="buttonXtra">{get(dataUser, "name", "- -")}</Typography>
                <Typography variant="bodyLarge">{get(dataUser, "email", "- -")}</Typography>
              </Stack>
            </Box>
            <div className='relative h-[80px] w-[80px] bg-[lightgray] rounded-full mt-4 md:mt-0'>
              <input type="file" id="photo-company" hidden onChange={changeCompanyPhoto} />
              <Avatar
                alt={short(get(dataUser, "companyData.name"))}
                sx={{ height: 80, width: 80 }}
                src={loadPhotoCompany ? "/" : get(dataUser, "companyData.profileUrl")}
              >
                {short(get(dataUser, "companyData.name"))}
              </Avatar>
              {loadPhotoCompany &&
                <div className='opacity-100 absolute top-0 right-0 left-0 bottom-0 z-[10] flex justify-center items-center rounded-full bg-[rgba(255,255,255,0.5)]'>
                  <CircularProgress size={30} />
                </div>
              }
              {!loadPhotoCompany &&
                <label for="photo-company" className='opacity-0 hover:opacity-100 transition duration-300 cursor-pointer absolute top-0 right-0 left-0 bottom-0 z-[10] flex justify-center items-center rounded-full bg-[rgba(255,255,255,0.5)]'>
                  <UploadIcon fontSize="large" />
                </label>
              }
            </div>
          </Stack>
          {/*
          <Hidden mdDown><Box className="absolute right-0 top-0 h-full overflow-hidden">
            <TruckBoxes style={{ width: "auto", height: "100%" }} />
          </Box>
          </Hidden>
            */}
        </Paper>

        <Paper className='w-full mb-8' elevation={3} >
          <Box component="form" onSubmit={get(formik_user, "handleSubmit")}>
            <Box className="w-full" >
              <Stack className='py-6 px-8' direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                <Typography className='py-3' component="h4" variant="h4">{__("section.1")}</Typography>
                <Stack direction={{ xs: "column-reverse", md: "row" }} alignItems={{ xs: "flex-end", md: "center" }}>
                  <Button disabled={get(login, "isLoading")} size="small" variant="contained" color="secondary" onClick={() => setOpenChangePass(true)} >{"Cambiar contraseña"}</Button>
                  <LoadingButton
                    sx={{ color: "error.dark" }}
                    startIcon={<LoginIcon />}
                    onClick={onLogout}
                    disabled={get(login, "isLoading")}
                  >
                    <Typography variant="body" >{__(`button.logout`)}</Typography>
                  </LoadingButton>
                </Stack>
              </Stack>
              <Divider />
              <Grid className='py-6 px-8' container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth >
                      <Typography className='pb-2' component="label" htmlFor="email" >
                        {__("form_user.email.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder={__("form_user.email.placeholder")}
                        size="small"
                        value={get(formik_user, "values.email")}
                        onChange={get(formik_user, "handleChange")}
                        error={get(formik_user, "touched.email") && Boolean(get(formik_user, "errors.email"))}
                        helperText={get(formik_user, "touched.email") && get(formik_user, "errors.email")}
                        disabled={true}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth  >
                      <Typography className='pb-2' component="label" htmlFor="name" >
                        {__("form_user.name.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        placeholder={__("form_user.name.placeholder")}
                        size="small"
                        value={get(formik_user, "values.name")}
                        onChange={get(formik_user, "handleChange")}
                        error={get(formik_user, "touched.name") && Boolean(get(formik_user, "errors.name"))}
                        helperText={get(formik_user, "touched.name") && get(formik_user, "errors.name")}
                        disabled={get(login, "isLoading")}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth  >
                      <Typography className='pb-2' component="label" htmlFor="phone" >
                        {__("form_user.phone.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        placeholder={__("form_user.phone.placeholder")}
                        size="small"
                        value={get(formik_user, "values.phone")}
                        onChange={get(formik_user, "handleChange")}
                        error={get(formik_user, "touched.phone") && Boolean(get(formik_user, "errors.phone"))}
                        helperText={get(formik_user, "touched.phone") && get(formik_user, "errors.phone")}
                        disabled={get(login, "isLoading")}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Stack className='px-8' direction="row" justifyContent="flex-end">
                <LoadingButton
                  className='w-[125px]'
                  color={"primary"}
                  variant="contained"
                  type="submit"
                  size='small'
                  loading={get(login, "isLoading") || loadingUser}
                >
                  {__(`button.profile`)}
                </LoadingButton>
              </Stack>

            </Box>
          </Box>


          <Box component="form" onSubmit={get(formik_company, "handleSubmit")}>
            <Box className="w-full" >
              <Box className='py-6 px-8'>
                <Typography className='py-3' component="h4" variant="h4">{__("section.2")}</Typography>
              </Box>
              <Divider />
              <Grid className='py-6 px-8' container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth >
                      <Typography className='pb-2' component="label" htmlFor="name" >
                        {__("form_company.name.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        placeholder={__("form_company.name.placeholder")}
                        size="small"
                        value={get(formik_company, "values.name")}
                        onChange={get(formik_company, "handleChange")}
                        error={get(formik_company, "touched.name") && Boolean(get(formik_company, "errors.name"))}
                        helperText={get(formik_company, "touched.name") && get(formik_company, "errors.name")}
                        disabled={get(login, "isLoading")}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth  >
                      <Typography className='pb-2' component="label" htmlFor="address" >
                        {__("form_company.address.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        placeholder={__("form_company.address.placeholder")}
                        size="small"
                        value={get(formik_company, "values.address")}
                        onChange={get(formik_company, "handleChange")}
                        error={get(formik_company, "touched.address") && Boolean(get(formik_company, "errors.address"))}
                        helperText={get(formik_company, "touched.address") && get(formik_company, "errors.address")}
                        disabled={get(login, "isLoading")}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box className='mb-8'>
                    <FormControl fullWidth  >
                      <Typography className='pb-2' component="label" htmlFor="contactPhone" >
                        {__("form_company.contactPhone.label")}
                      </Typography>
                      <TextField
                        fullWidth
                        id="contactPhone"
                        name="contactPhone"
                        placeholder={__("form_company.contactPhone.placeholder")}
                        size="small"
                        value={get(formik_company, "values.contactPhone")}
                        onChange={get(formik_company, "handleChange")}
                        error={get(formik_company, "touched.contactPhone") && Boolean(get(formik_company, "errors.contactPhone"))}
                        helperText={get(formik_company, "touched.contactPhone") && get(formik_company, "errors.contactPhone")}
                        disabled={get(login, "isLoading")}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Stack className='pb-6 px-8' direction="row" justifyContent="flex-end">
                <LoadingButton
                  className='w-[125px]'
                  color={"primary"}
                  variant="contained"
                  type="submit"
                  size='small'
                  loading={get(login, "isLoading") || loadingCompany}
                >
                  {__(`button.company`)}
                </LoadingButton>
              </Stack>
            </Box>
          </Box>
        </Paper>

        <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
        <Alert
          title={get(alert, "title")}
          subtitle={get(alert, "subtitle")}
          btn1={{ label: get(alert, "btn"), func: get(alert, "func") }}
          btn2={{}}
          type={get(alert, "type")}
          openAlert={get(alert, "open")}
          closeAlert={closeAlert}
        />
      </Box >
      <ChangePass
        __={__}
        open={openChangePass}
        onClose={closeChangePass}
        current={dataUser}
      />
    </Layout >
  )
}

export default Profile