import {
    putDriverLoading,
    putDriverRequest,
    putDriverSuccess,
    putDriverReject,
    putDriverReset
} from "../../actions/driver/put";

export const putDriver = (formData) => async (dispatch, getState) => {
    dispatch(putDriverLoading());
    try {
        const { data } = await putDriverRequest(formData, getState);
        dispatch(putDriverSuccess(data))
    } catch (error) {
        dispatch(putDriverReject(error))
    } finally {
        setTimeout(() => { dispatch(putDriverReset()) }, 3000);
    }
    return Promise.resolve();
};