import DeliveryPoints from "../../../../pages/Masters/DeliveryPoints";
import DeliveryPointsNewEdit from "../../../../pages/Masters/DeliveryPoints/NewEdit";

const routerDeliveryPoints = [
    {
        key: "delivery_points",
        path: "/masters/delivery_points",
        element: <DeliveryPoints />,
    },
    {
        key: "delivery_points",
        path: "/masters/delivery_points/new",
        element: <DeliveryPointsNewEdit />,
    },
    {
        key: "delivery_points",
        path: "/masters/delivery_points/:id",
        element: <DeliveryPointsNewEdit />,
    },
]

export default routerDeliveryPoints
