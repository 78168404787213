import {
    getReportInvoicesLoading,
    getReportInvoicesReject,
    getReportInvoicesRequest,
    getReportInvoicesSuccess
} from "../../actions/invoices/getAll";

export const getReportInvoices = (formData) => async (dispatch, getState) => {
    dispatch(getReportInvoicesLoading());
    try {
        const { data } = await getReportInvoicesRequest(formData, getState);
        dispatch(getReportInvoicesSuccess(data))
    } catch (error) {
        dispatch(getReportInvoicesReject(error))
    }
    return Promise.resolve();
};