import {
    getRouteHomeLoading,
    getRouteHomeReject,
    getRouteHomeRequest,
    getRouteHomeSuccess
} from "../../actions/route/getDashboard";

export const getRouteHome = (formData) => async (dispatch, getState) => {
    dispatch(getRouteHomeLoading());
    try {
        const { data } = await getRouteHomeRequest(formData, getState);
        dispatch(getRouteHomeSuccess(data))
    } catch (error) {
        dispatch(getRouteHomeReject(error))
    }
    return Promise.resolve();
};