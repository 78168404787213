import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_COST_ALL_LOADING = 'GET_ROUTE_COST_ALL_LOADING';
export const GET_ROUTE_COST_ALL_SUCCESS = 'GET_ROUTE_COST_ALL_SUCCESS';
export const GET_ROUTE_COST_ALL_REJECT = 'GET_ROUTE_COST_ALL_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteCostAllLoading = () => ({ type: GET_ROUTE_COST_ALL_LOADING });
export const getRouteCostAllSuccess = (payload) => ({ type: GET_ROUTE_COST_ALL_SUCCESS, payload });
export const getRouteCostAllReject = (payload) => ({ type: GET_ROUTE_COST_ALL_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteCostAllRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TypeCostsRoute/GetAllTypeCostsRoute/${params.id}`, options);
};