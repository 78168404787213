/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
    MenuItem,
    Tabs,
    Tab,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from "moment";

import get from "lodash/get";
import includes from "lodash/includes";

import Layout from "../../../components/layout/Layout";
import Form from "./Form"
import DetailForm from "./FormDetail"
import History from "./History"
import Items from "./Items"
import ChargeCostumers from "./ChargeCostumers"

import { getOrderId } from "../../../store/transactions/thunk/order/getId"
import { getClient } from "../../../store/masters/thunk/client/getAll";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>{children}</Box>
            )}
        </div>
    );
}

const NewEdit = () => {
    const location = useLocation()
    const [__] = useTranslation("order");
    const [value, setValue] = useState(0);
    const isEdit = !includes(get(location, "pathname"), "/detail/")
    const [btns, setBtns] = useState(<></>)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout
            propsToolbar={{
                title: isEdit ? __(`head.edit.name`) : __(`head.detail.name`),
                srute: isEdit ? __(`head.edit.code`) : __(`head.detail.code`),
            }}
        >
            <Box display="block">
                <Box className="ml-4 mr-0">
                    <Grid className="py-2" container component={Paper} spacing={2}>
                        <Grid item xs={12} lg={8}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label={__(`tabs.orders`)} />
                                <Tab label={__(`tabs.items`)} />
                                {/* <Tab label={__(`tabs.payment`)} /> */}
                                {/* <Tab label={__(`tabs.history`)} /> */}
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {btns}
                        </Grid>
                    </Grid>
                </Box>
                <TabPanel value={value} index={0}>
                    {isEdit ? <Form setBtns={setBtns} /> : <DetailForm setBtns={setBtns} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Items setBtns={setBtns} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ChargeCostumers setBtns={setBtns} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <History setBtns={setBtns} />
                </TabPanel>
            </Box>
        </Layout >
    )
}

export default NewEdit