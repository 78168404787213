import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_ROUTE_COST_RESET = 'POST_ROUTE_COST_RESET';
export const POST_ROUTE_COST_LOADING = 'POST_ROUTE_COST_LOADING';
export const POST_ROUTE_COST_SUCCESS = 'POST_ROUTE_COST_SUCCESS';
export const POST_ROUTE_COST_REJECT = 'POST_ROUTE_COST_REJECT';

//* ACTIONS ------------------------------------------------
export const postRouteCostReset = () => ({ type: POST_ROUTE_COST_RESET });
export const postRouteCostLoading = () => ({ type: POST_ROUTE_COST_LOADING });
export const postRouteCostSuccess = (payload) => ({ type: POST_ROUTE_COST_SUCCESS, payload });
export const postRouteCostReject = (payload) => ({ type: POST_ROUTE_COST_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postRouteCostRequest = async ({ idRoute, costs }, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: costs,
    }, getState);
    return request(`/api/TypeCostsRoute/UploadTypeCostsListByRoute/${idRoute}`, options);
};
