/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, pickBy, identity, isEmpty } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Link as LinkMui,
  CircularProgress,
  Box,
  Fade,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from "react-router-dom"
import { PageSize, countPage } from "../../../config/const"

import Layout from "../../../components/layout/Layout"
import Table from "../../../components/form/Table";
import Toolbar from "./Toolbar";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";

import { getOperators } from "../../../store/masters/thunk/operators/getAll";
// import { deleteOperators } from "../../../store/masters/thunk/operators/delete"
import { putOperators } from "../../../store/masters/thunk/operators/put"

const Operators = () => {
  const [__] = useTranslation("operators");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

  const operato = useSelector(state => state.masters);

  const operators = useSelector(state => state.masters.operators);
  const put = useSelector(state => state.masters.operators.put);
  const delet = useSelector(state => state.masters.operators.delete);

  const getData = ({ page, filter }) => {
    dispatch(getOperators({ page, PageSize, Filters: filter }))
  }

  const reload = () => getData({ page: 1, filter })

  useEffect(() => {
    setAnchorEl(false)
    getData({ page: 1, filter })
  }, [dispatch, JSON.stringify(filter)])


  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  // const handleDelete = (id) => () => {
  //   dispatch(deleteOperators({ id }))
  // }
  const handleDisabled = (values) => () => {
    dispatch(putOperators({ ...values, isActive: false }))
  }

  //  --------- Delete -------------

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
    })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delet" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delet") }
    if (get(put, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
  }, [put, delet])

  const headTable = [
    {
      key: "code",
      label: __(`table.cols.name`),
      align: "left",
      sx: { minWidth: 100 }
    },
    {
      key: "status",
      label: __(`table.cols.isActive`),
      align: "center"
    },
    {
      key: "telePhone",
      label: __(`table.cols.phone`),
      align: "left"
    },
    {
      key: "email",
      label: __(`table.cols.email`),
      align: "left"
    },
    {
      key: "options",
      label: __(`table.cols.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(operators, "data", []), (row, i) => ({
    ...row,
    code: <LinkMui component={Link} to={toString(get(row, "idUser"))} >{get(row, "name")}</LinkMui>,
    status: <Chip label={__(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
    telePhone: get(row, "phone") && <Stack direction="row" alignItems="center" spacing={1}><PhoneIcon fontSize="small" /><div>{get(row, "phone")}</div></Stack>,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <Layout
      propsToolbar={{
        title: __(`head.name`),
        code: null,
        btnLabel: null
      }}
    >
      <Toolbar setFilter={setFilter} __={__} getData={getData} reload={reload} isLoading={get(operators, "isLoading", false)} />
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(operators, "isLoading", false)}
      />
      {get(operators, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignItems="flex-end">
          <Pagination
            count={countPage(get(operators, "totalRecords", 1))}
            page={get(operators, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <Link to={toString(get(selected, "idUser"))}><MenuItem >{__(`table.menu.edit`)}</MenuItem></Link>
        {/* <MenuItem onClick={handleDisabled(selected)} disabled={get(put, "isLoading")} >
          <Box height={28} position="relative">
            <Fade in={get(put, "isLoading")}>
              <Box position="absolute" width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress color="primary" size={20} />
              </Box>
            </Fade>
            <Typography className='text-center w-full ' variant="bms" color={get(put, "isLoading") ? "text.slite" : "text.main"} >{__(`table.menu.deactivate`)}</Typography>
          </Box >
        </MenuItem> */}
        {/* <MenuItem onClick={handleDelete(get(selected, "idUser"))}>{__(`table.menu.delete`)}</MenuItem> */}
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
    </Layout>
  )
}

export default Operators;


