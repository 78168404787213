import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            docNum: yup.string().required(__(`form.docNum.error`)),
            deliveryDate: yup.string().required(__(`form.deliveryDate.error`)),
            idOrderStatus: yup.string().required(__(`form.idOrderStatus.error`)),
            idOrderOperativeStatus: yup.string().required(__(`form.idOrderOperativeStatus.error`)),
            idClient: yup.string().required(__(`form.idClient.error`)),
            idDeliveryPoint: yup.string().required(__(`form.idDeliveryPoint.error`)),
            // deliveryComments: yup.string().required(__(`form.deliveryComments.error`)),
        })
    )
}

export default validators
