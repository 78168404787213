import {
    postInvoiceLoading,
    postInvoiceRequest,
    postInvoiceSuccess,
    postInvoiceReject,
    postInvoiceReset
} from "../../actions/invoice/post";

import {
    getInvoiceIdRequest,
    getInvoiceIdSuccess
} from "../../actions/invoice/getId";

export const postInvoice = (formData) => async (dispatch, getState) => {
    dispatch(postInvoiceLoading());
    try {
        const { data } = await postInvoiceRequest(formData, getState);
        const id = data?.id
        const getId = await getInvoiceIdRequest({id}, getState);
        
        await dispatch(getInvoiceIdSuccess(getId?.data))
        dispatch(postInvoiceSuccess(data))
    } catch (error) {
        dispatch(postInvoiceReject(error))
    } finally {
        setTimeout(() => { dispatch(postInvoiceReset()) }, 3000);
    }
    return Promise.resolve();
};