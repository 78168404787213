import { concat } from "lodash";

import routerInvoices from "./invoices";
import routerOrders from "./orders";
import routerRouteCost from "./routeCost";
import routerRoutes from "./routes";

import Report from "../../../../pages/Report";

const routesReport = [{ key: "report", path: "/report", element: <Report />, }]

const routes = concat(
    routerInvoices,
    routerOrders,
    routerRouteCost,
    routerRoutes,
    routesReport
)

export default routes