import {
    putOperatorsLoading,
    putOperatorsRequest,
    putOperatorsSuccess,
    putOperatorsReject,
    putOperatorsReset
} from "../../actions/operators/put";

export const putOperators = (formData) => async (dispatch, getState) => {
    dispatch(putOperatorsLoading());
    try {
        const { data } = await putOperatorsRequest(formData, getState);
        dispatch(putOperatorsSuccess(data))
    } catch (error) {
        dispatch(putOperatorsReject(error))
    } finally {
        setTimeout(() => { dispatch(putOperatorsReset()) }, 3000);
    }
    return Promise.resolve();
};