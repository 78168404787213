import * as yup from 'yup';
import { get } from "lodash";

const validators = (__) => () => {
    return (
        yup.object({
            idClient: yup.string().required(__(`form.idClient.error`)),
            dateProcess: yup.string().required(__(`form.dateProcess.error`)),
            comments: yup.string(),
        })
    )
}

export default validators
