import {
    getClientItemLoading,
    getClientItemReject,
    getClientItemRequest,
    getClientItemSuccess
} from "../../actions/client/getItem";

export const getClientItem = (formData) => async (dispatch, getState) => {
    dispatch(getClientItemLoading());
    try {
        const { data } = await getClientItemRequest(formData, getState);
        dispatch(getClientItemSuccess(data))
    } catch (error) {
        dispatch(getClientItemReject(error))
    }
    return Promise.resolve();
};