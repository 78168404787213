import {
    putTransportBrandLoading,
    putTransportBrandRequest,
    putTransportBrandSuccess,
    putTransportBrandReject,
    putTransportBrandReset
} from "../../actions/transportBrand/put";

export const putTransportBrand = (formData) => async (dispatch, getState) => {
    dispatch(putTransportBrandLoading());
    try {
        const { data } = await putTransportBrandRequest(formData, getState);
        dispatch(putTransportBrandSuccess(data))
    } catch (error) {
        dispatch(putTransportBrandReject(error))
    } finally {
        setTimeout(() => { dispatch(putTransportBrandReset()) }, 3000);
    }
    return Promise.resolve();
};