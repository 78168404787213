import * as yup from 'yup';
import { get } from "lodash";

const validators = (inputs, step) => (value) => {
    return (
        yup.object({
            userName: step >= 0 && yup.string().required(get(inputs, "user.error")),
            companyName: step >= 0 && yup.string().required(get(inputs, "company.error")),
            email: step >= 0 && yup.string().email(get(inputs, "email.error2")).required(get(inputs, "email.error")),
            passWord: step >= 1 && yup.string()
                .matches(/^(?=.*[a-z])/, get(inputs, "pass.error2"))
                .matches(/^(?=.*[A-Z])/, get(inputs, "pass.error3"))
                .matches(/^(?=.*[0-9])/, get(inputs, "pass.error4"))
                // .matches(/^(?=.*\W)/, 'Ingrese al menos 1 caracter especial')
                .min(8, get(inputs, "pass.error5"))
                .required(get(inputs, "pass.error")),
            confirmation: step >= 1 && yup.string()
                // .matches(/^(?=.*[a-z])/, 'Ingrese al menos 1 minuscula')
                // .matches(/^(?=.*[A-Z])/, 'Ingrese al menos 1 mayúscula')
                // .matches(/^(?=.*[0-9])/, 'Ingrese al menos 1 número')
                .required(get(inputs, "confirm.error"))
                .oneOf([yup.ref('passWord'), null], get(inputs, "confirm.error2"))
        })
    )
}

export default validators
