import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_CONTACT_RESET = 'PUT_CONTACT_RESET';
export const PUT_CONTACT_LOADING = 'PUT_CONTACT_LOADING';
export const PUT_CONTACT_SUCCESS = 'PUT_CONTACT_SUCCESS';
export const PUT_CONTACT_REJECT = 'PUT_CONTACT_REJECT';

//* ACTIONS ------------------------------------------------
export const putContactReset = () => ({ type: PUT_CONTACT_RESET });
export const putContactLoading = () => ({ type: PUT_CONTACT_LOADING });
export const putContactSuccess = (payload) => ({ type: PUT_CONTACT_SUCCESS, payload });
export const putContactReject = (payload) => ({ type: PUT_CONTACT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putContactRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Contact`, options);
};


