import TransportSupplier from "../../../../pages/Masters/TransportSupplier";
import TransportSupplierNewEdit from "../../../../pages/Masters/TransportSupplier/NewEdit";

const routerTransportSupplier = [
    {
        key: "transportSupplier",
        path: "/config/transportSupplier",
        element: <TransportSupplier />,
    },
    {
        key: "transportSupplier",
        path: "/config/transportSupplier/new",
        element: <TransportSupplierNewEdit />,
    },
    {
        key: "transportSupplier",
        path: "/config/transportSupplier/:id",
        element: <TransportSupplierNewEdit />,
    },
]

export default routerTransportSupplier
