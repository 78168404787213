import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import get from "lodash/get";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from "./components/Toolbar";
import Drawer from "./components/Drawer";

export const drawerWidth = 272;
export const toolbarHeight = 120;

const Layout = ({ children, propsToolbar = { title: "TMS Status Software", srute: null }, goBack, disableLayout, disableHead }) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [currentPath, setCurrentPath] = useState()

    return (
        <Box className="bg-[#edf3fb]">
            <Helmet><title>{get(propsToolbar, "title")}</title></Helmet>
            <CssBaseline />
            {!disableLayout &&
                <Drawer currentPath={currentPath} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            }
            {disableHead
                ? disableLayout
                    ? <div className="min-h-screen h-full">{children}</div>
                    : <div className="pl-14 sm:pl-16 min-h-screen h-full">{children}</div>
                : <Box className="pl-14 sm:pl-16 min-h-screen h-full">
                    {!disableLayout &&
                        <AppBar
                            position="sticky"
                            elevation={0}
                            style={{ padding: "0 !important" }}
                        >
                            <Toolbar currentPath={currentPath} setCurrentPath={setCurrentPath} toolbarHeight={toolbarHeight} propsToolbar={propsToolbar} goBack={goBack} />
                        </AppBar>
                    }
                    <Box className='relative flex px-6 pt-2 pb-6 flex-col' component="main" sx={{ bgcolor: theme => theme.palette.color.navy[100], minHeight: "calc(100vh - 120px)" }}>
                        {children}
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default Layout;
