import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_TRANSPORT_BRAND_ID_LOADING = 'GET_TRANSPORT_BRAND_ID_LOADING';
export const GET_TRANSPORT_BRAND_ID_SUCCESS = 'GET_TRANSPORT_BRAND_ID_SUCCESS';
export const GET_TRANSPORT_BRAND_ID_REJECT = 'GET_TRANSPORT_BRAND_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getTransportBrandIdLoading = () => ({ type: GET_TRANSPORT_BRAND_ID_LOADING });
export const getTransportBrandIdSuccess = (payload) => ({ type: GET_TRANSPORT_BRAND_ID_SUCCESS, payload });
export const getTransportBrandIdReject = (payload) => ({ type: GET_TRANSPORT_BRAND_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getTransportBrandIdRequest = async ({ params = {}, id }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TransportBrand/${id}`, options);
};