/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import moment from "moment"
import find from "lodash/find"

import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import validator from "./validator"
import { PageSize } from "../../../config/const"

import { getInvoiceId } from "../../../store/transactions/thunk/invoice/getId"
import { postInvoice } from "../../../store/transactions/thunk/invoice/post"
import { putInvoice } from "../../../store/transactions/thunk/invoice/put"
import { getClient } from "../../../store/masters/thunk/client/getAll";

const NewEdit = ({ setBtns, isEdit }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("invoice");
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [isMount, setIsMount] = useState(false);
    // const item = useSelector(state => state.transactions.order.getItem.data);

    const current = useSelector(state => state.transactions.invoice.detail);
    const post = useSelector(state => state.transactions.invoice.post);
    const put = useSelector(state => state.transactions.invoice.put);
    const client = useSelector(state => state.masters.client);

    const clients = map(client?.data, ({ idClient, name }) => ({ value: idClient, label: name }));

    const isActive = "isActive==true";

    const getDataClient = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getClient(params))
    }

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }) }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
    }, [post, put])

    useEffect(() => {
        if ((searchParams.get('from') !== "new")) {
            if (!!id) {
                dispatch(getInvoiceId({ id }))
            }
        }
    }, [dispatch, id, searchParams.get('from')])

    const onSubmit = (values) => {
        const idInvoice = Number(id);
        const idClient = Number(values.idClient)

        const body = {
            idInvoice: Number(id),
            idClient: Number(get(values, "idClient")),
            dateProcess: moment(get(values, "dateProcess")).format(),
            comments: get(values, "comments")
        }

        const whitOutNulls = pickBy(body, identity);
        if (idInvoice) { dispatch(putInvoice(whitOutNulls)) }
        else { dispatch(postInvoice(whitOutNulls)) }
    }

    const initialValues = {
        comments: id ? get(current, "data.comments", "") : "",
        dateProcess: moment(get(current, "data.dateProcess")).format("L"),
        idClient: get(find(clients, ({ value }) => value === get(current, "data.idClient", 1)), "label"),

    }

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                {isEdit && <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" form="form-invoice" loading={get(put, "isLoading")}>{id ? __(`action.save`) : __(`action.new`)}</LoadingButton>}
                <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/transactions/invoices")} disabled={get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [get(put, "isLoading")])

    return (
        <div>
            {(get(current, "isLoading")) && !isMount
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form id="form-invoice" onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <InputText
                                                    name={"idClient"}
                                                    formik={formik}
                                                    label={__(`form.idClient.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="dateProcess"
                                                    label={__(`form.dateProcess.label`)}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <InputText
                                                    formik={formik}
                                                    name="comments"
                                                    label={__(`form.comments.label`)}
                                                    placeholder={__(`form.comments.placeholder`)}
                                                    disabled={get(current, "isLoading", false)}
                                                    multiline
                                                    rows={4}
                                                    onChange={() => { }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </div>
    )
}

export default NewEdit
