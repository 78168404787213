import React from 'react';
import { Box, Button, CardMedia, Stack, Hidden } from '@mui/material';
import { get } from "lodash";
import { Helmet } from "react-helmet";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TruckDron from "../../../assets/icons/TruckDron"
import TruckOpen from "../../../assets/icons/TruckOpen"
import Padlock from "../../../assets/icons/Padlock"
import Shield from "../../../assets/icons/Shield"
import PoweredBy from "../../../assets/icons/PoweredBy"
import BoxSuccess from "../../../assets/icons/BoxSuccess"

const LayoutAuth = ({ title, type, children, onBack, ...restProps }) => {

    const getType = (key) => {
        switch (key) {
            case "login": return { name: "", ico: TruckDron, };
            case "forgot_pass": return { name: "", ico: "/images/auth/forgot.png", };
            case "forgot_pass_reject": return { name: "", ico: "/images/auth/reject_recover.png", };
            case "recover_pass_request": return { name: "", ico: "/images/auth/check_email.png", };
            case "register": return { name: "", ico: TruckOpen, };
            case "register_success": return { name: "", ico: BoxSuccess, };
            case "pass": return { name: "", ico: Padlock, };
            case "shield": return { name: "", ico: Shield, };
            case "recover_pass_receive": return { name: "", ico: "/images/auth/recover_pass.png", };
            default: return { name: "", ico: "/images/auth/login.png", };
        }
    }

    return (
        <Box {...restProps}>
            <Helmet><title>{title}</title></Helmet>
            <Stack className="w-full min-h-screen p-4" bgcolor={({ palette }) => palette.primary.main} alignItems="center" justifyContent="space-between">
                {!!onBack &&
                    <Box className="absolute z-10 top-10 left-10">
                        <Button startIcon={<ArrowBackIcon />} color="primary" variant="contained" disableElevation onClick={onBack} >
                            Regresar
                        </Button>
                    </Box>
                }
                <Stack className="w-auto md:w-full flex-1" direction="row" justifyContent="space-evenly" >
                    <Hidden lgDown>
                        <Box className="flex items-center justify-center" width="400px">
                            <CardMedia
                                id="ico-auth"
                                component={get(getType(type), "ico")}
                                alt=""
                            />
                        </Box>
                    </Hidden>
                    <Box id="children-auth" className='flex-col py-4 md:py-8' minHeight="calc(100vh - 100px)" maxWidth="500px" sx={{ overflowY: "auto" }}>
                        {children}
                    </Box>
                </Stack>
                <Stack>
                    <PoweredBy style={{ width: "229px", height: "47px" }} />
                </Stack>
            </Stack>
        </Box>
    )
}

export default LayoutAuth