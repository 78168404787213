import {
    getDriverLoading,
    getDriverReject,
    getDriverRequest,
    getDriverSuccess
} from "../../actions/driver/getAll";

export const getDriver = (formData) => async (dispatch, getState) => {
    dispatch(getDriverLoading());
    try {
        const { data } = await getDriverRequest(formData, getState);
        dispatch(getDriverSuccess(data))
    } catch (error) {
        dispatch(getDriverReject(error))
    }
    return Promise.resolve();
};