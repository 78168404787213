import {
    putPassDriverLoading,
    putPassDriverRequest,
    putPassDriverSuccess,
    putPassDriverReject,
    putPassDriverReset
} from "../../actions/driver/putPass";

export const putPassDriver = (formData) => async (dispatch, getState) => {
    dispatch(putPassDriverLoading());
    try {
        const { data } = await putPassDriverRequest(formData, getState);
        dispatch(putPassDriverSuccess(data))
    } catch (error) {
        dispatch(putPassDriverReject(error))
    } finally {
        setTimeout(() => { dispatch(putPassDriverReset()) }, 3000);
    }
    return Promise.resolve();
};