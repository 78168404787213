/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { get, find, isEmpty, includes } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography, Toolbar as ToolbarUi, Stack, Link as LinkMui, Breadcrumbs } from '@mui/material';

import { all_options } from "./options"

const StyledToolbar = styled(ToolbarUi)(({ theme }) => ({
    alignItems: 'center',
    '@media all': {
        padding: theme.spacing(2, 5),
        backgroundColor: theme.palette.color.navy[100]
    },
}));

const Toolbar = ({
    toolbarHeight,
    propsToolbar = {
        title: "TMS Status Software",
        srute: null
    },
    currentPath,
    setCurrentPath
}) => {
    const [__, i18n] = useTranslation("global");
    const location = useLocation()

    useEffect(() => {
        let getCurrent = find(all_options(__), ({ path }) => path === location.pathname)
        if (isEmpty(getCurrent) && !!get(propsToolbar, "srute")) {
            getCurrent = find(all_options(__), ({ path }) => includes(location.pathname, path))
        }
        setCurrentPath(getCurrent)
    }, [location.pathname, i18n.language])

    return (
        <StyledToolbar sx={{ height: toolbarHeight }} >
            <Box className='flex flex-col' sx={{ flexGrow: 1 }}>
                <Stack direction="row" spacing={3} alignItems="center">
                    <Typography component="h2" variant="h2" color="text.main" >
                        {get(propsToolbar, "title")}
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" color="primary.light" sx={{ minHeight: 24 }}>
                    {get(currentPath, "parent") &&
                        <LinkMui component={Link} underline="hover" color="primary" to={get(currentPath, "parentPath")}>
                            <Typography comopnent="p" variant="bmr" >{get(currentPath, "parent")}</Typography>
                        </LinkMui>
                    }
                    {get(propsToolbar, "srute") && get(currentPath, "path") &&
                        <LinkMui component={Link} underline="hover" color="primary" to={get(currentPath, "path")}>
                            <Typography comopnent="p" variant="bmr" >{get(currentPath, "name")}</Typography>
                        </LinkMui>
                    }
                    {!get(propsToolbar, "srute") && get(currentPath, "path") && <Typography comopnent="p" variant="bmr" >{get(currentPath, "name")}</Typography>}
                    {get(propsToolbar, "srute") && <Typography comopnent="p" variant="bmr" >{get(propsToolbar, "srute")}</Typography>}
                </Breadcrumbs>
            </Box>
        </StyledToolbar >
    )
}

export default Toolbar