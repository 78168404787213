import {
    getRouteAllCoordsLoading,
    getRouteAllCoordsReject,
    getRouteAllCoordsRequest,
    getRouteAllCoordsSuccess,
} from "../../actions/route/getAllCoords";

export const getRouteAllCoords = (formData) => async (dispatch, getState) => {
    dispatch(getRouteAllCoordsLoading());
    try {
        const { data } = await getRouteAllCoordsRequest(formData, getState);
        dispatch(getRouteAllCoordsSuccess(data))
    } catch (error) {
        dispatch(getRouteAllCoordsReject(error))
    }
    return Promise.resolve();
};
