import {
    putOrderItemLoading,
    putOrderItemRequest,
    putOrderItemSuccess,
    putOrderItemReject,
    putOrderItemReset
} from "../../actions/order/putItem";

export const putOrderItem = (formData) => async (dispatch, getState) => {
    dispatch(putOrderItemLoading());
    try {
        const { data } = await putOrderItemRequest(formData, getState);
        dispatch(putOrderItemSuccess(data))
    } catch (error) {
        dispatch(putOrderItemReject(error))
    } finally {
        setTimeout(() => { dispatch(putOrderItemReset()) }, 3000);
    }
    return Promise.resolve();
};