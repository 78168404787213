import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_DELIVERY_POINT_RESET = 'DELETE_DELIVERY_POINT_RESET';
export const DELETE_DELIVERY_POINT_LOADING = 'DELETE_DELIVERY_POINT_LOADING';
export const DELETE_DELIVERY_POINT_SUCCESS = 'DELETE_DELIVERY_POINT_SUCCESS';
export const DELETE_DELIVERY_POINT_REJECT = 'DELETE_DELIVERY_POINT_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteDeliveryPointReset = () => ({ type: DELETE_DELIVERY_POINT_RESET });
export const deleteDeliveryPointLoading = () => ({ type: DELETE_DELIVERY_POINT_LOADING });
export const deleteDeliveryPointSuccess = (payload) => ({ type: DELETE_DELIVERY_POINT_SUCCESS, payload });
export const deleteDeliveryPointReject = (payload) => ({ type: DELETE_DELIVERY_POINT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteDeliveryPointRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/DeliveryPoints/${data.id}`, options);
};