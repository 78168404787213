import {
    getRouteLoading,
    getRouteReject,
    getRouteRequest,
    getRouteSuccess
} from "../../actions/route/getAll";

export const getRoute = (formData) => async (dispatch, getState) => {
    dispatch(getRouteLoading());
    try {
        const { data } = await getRouteRequest(formData, getState);
        dispatch(getRouteSuccess(data))
    } catch (error) {
        dispatch(getRouteReject(error))
    }
    return Promise.resolve();
};