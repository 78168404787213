import React from 'react'
import {
  Grid,
  Paper,
  Stack,
  CardActionArea
} from "@mui/material"
import _, { get, map } from "lodash";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

import Layout from "../../components/layout/Layout";
import { main } from "../../components/layout/Layout/components/options"

const Masters = () => {
  const [__] = useTranslation("global");

  return (
    <Layout propsToolbar={{ title: __("menu.main.name") }}>
      <Stack className='flex-1 pb-2 md:pb-12' alignItems="center" justifyContent="center" >
        <Grid container spacing={3} maxWidth={"lg"}>
          {map(get(main(__), "[0].children"), (item) => (
            <Grid item xs={12} sm={6} md={4} >
              <CardActionArea LinkComponent={Link} to={get(item, "path", "/")}>
                <Paper className='py-5 px-3 text-center rounded h-18 md:h-24 flex items-center justify-center'>
                  {get(item, "name")}
                </Paper>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Layout>
  )
}

export default Masters