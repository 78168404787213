import {
    getRouteIdLoading,
    getRouteIdRequest,
    getRouteIdSuccess,
    getRouteIdReject,
} from "../../actions/route/getId";

export const getRouteId = (formData) => async (dispatch, getState) => {
    dispatch(getRouteIdLoading());
    try {
        const { data } = await getRouteIdRequest(formData, getState);
        dispatch(getRouteIdSuccess(data))
    } catch (error) {
        dispatch(getRouteIdReject(error))
    }
    return Promise.resolve();
};