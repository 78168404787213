/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { get, map, pickBy, identity, isEmpty, find, filter } from "lodash";
import {
  IconButton,
  Stack,
  Chip,
  Menu,
  Button,
  MenuItem,
  Link as LinkMui,
  CircularProgress,
  Box,
  Fade,
  Typography,
  Popper
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import CostsModal from "./CostsModal"
import InputText from "../../../components/form/InputText";

import { getRouteCostAll } from "../../../store/transactions/thunk/route/getCostAll";
import { putRoute } from "../../../store/transactions/thunk/route/put"
import { putRouteCost } from "../../../store/transactions/thunk/route/putCost";
import { getCost } from "../../../store/transactions/thunk/cost/getAll";


const Costs = ({ setBtns, isEdit }) => {
  const [__] = useTranslation("route");
  const [__c] = useTranslation("cost");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
  const [openModal, setOpenModal] = useState(false);
  const [costsSelected, setCostsSelected] = useState([])
  const [openQuestion, setOpenQuestion] = useState(null);
  const [toSelected, setToSelected] = useState({});
  const [toEdit, setToEdit] = useState({});
  const [valueAmount, setValueAmount] = useState();


  const route = useSelector(state => state.transactions.route.costAll);
  const actualCosts = useSelector(state => state.transactions.route.costAll);
  const put = useSelector(state => state.transactions.route.putCost);
  const post = useSelector(state => state.transactions.route.postCost);

  const getData = async () => {
    await dispatch(getRouteCostAll({ id }))
  }
  const getCosts = () => {
    dispatch(getCost({}))
  }

  const reload = async () => {
    await getData()
    getCosts()
  }

  useEffect(() => {
    setAnchorEl(false)
    getData()
  }, [])

  useEffect(() => {
    setCostsSelected(map(get(route, "data", []), ({ idTypeCost }) => idTypeCost))
  }, [get(route, "data.length")])


  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        {isEdit ? <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.add`)}</Button> : <div className='flex-1' />}
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [isEdit])

  const handleOpenMenu = (row) => (event) => {
    setToSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setToSelected({})
    setAnchorEl(null);
  };

  const onCancel = () => {
    setValueAmount(undefined)
    setToEdit({})
    setToSelected({})
    setOpenQuestion(false)
  }

  const onDelete = () => {
    const newsCosts = filter(actualCosts.data, ({ idTypeCost }) => idTypeCost !== get(toSelected, "idTypeCost"));
    const body = {
      costs: map(newsCosts, ({ idTypeCost, idRoute, totalCost }) => ({ idTypeCost, idRoute, totalCost })),
      idRoute: id
    }
    dispatch(putRouteCost(body))
  }

  const onEdit = (row) => () => {
    if (!!valueAmount) {
      const body = {
        costs: map(actualCosts.data, ({ idTypeCost, idRoute, totalCost }) => ({ idTypeCost, idRoute, totalCost: row.idTypeCost === idTypeCost ? valueAmount : totalCost })),
        idRoute: id
      }
      dispatch(putRouteCost(body))
    } else {
      onCancel()
    }
  }

  const handleDelete = (row) => () => {
    setAnchorEl(null)
    setOpenQuestion(true)
    setToSelected(row)
  }

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
    })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(put, "isSuccess", false)) { onCancel(); setOpenModal(false); setOpenQuestion(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    if (get(post, "isSuccess", false)) { setOpenModal(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(post, "isReject", false)) { setError(get(post, "error", {}), "put") }
  }, [put, post])

  const headTable = [
    {
      key: "typeCosts.nameCost",
      label: __(`table.colsCosts.costName`),
      align: "left"
    },
    {
      key: "amount",
      label: __(`table.colsCosts.amount`),
      align: "center",
      sx: { maxWidth: 200, width: 200 }
    },
    {
      ... (!isEdit ? {} :
        {
          key: "options",
          label: __(`table.colsCosts.options`),
          align: "center",
          sx: { position: "sticky", width: 100, right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
        }
      )
    },
  ]

  const dataTable = map(get(route, "data", []), (row, i) => ({
    ...row,
    amount: toEdit.idTypeCost === row.idTypeCost ? (
      <Box className='flex justify-center items-center' sx={{ ".MuiInputBase-root": { height: 25 } }}>
        <InputText name="totalCost" size="small" defaultValue={row.totalCost} sx={{ width: 75, }} fullWidth={false} onChange={(e) => setValueAmount(e.target.value)} />
        <div className='pl-4'>
          <IconButton aria-label="edit" size="small" onClick={onEdit(row)}>
            {get(put, "isLoading", false)
              ? <CircularProgress size={20} />
              : <EditIcon fontSize="inherit" />
            }
          </IconButton>
        </div>
        <div className='pl-2'>
          <IconButton aria-label="delete" size="small" onClick={onCancel}>
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Box>
    ) : `$ ${row.totalCost}`,

    // delete: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)} ><DeleteIcon fontSize="inherit" /></IconButton>
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))


  return (
    <div>
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(route, "isLoading", false)}
        empty="costs"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={() => { setToEdit(toSelected); setAnchorEl(null) }}>{__(`table.menu.edit`)}</MenuItem>
        <MenuItem onClick={handleDelete(toSelected)}>{__(`table.menu.delete`)}</MenuItem>
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <CostsModal
        __={__}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        selected={costsSelected}
        setSelected={setCostsSelected}
      />
      <Question
        title={__("alert.deleteCost")}
        detail={""}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(post, "isLoading") || get(put, "isLoading")}
      />
    </div >
  )
}

export default Costs;
