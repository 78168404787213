/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import validator from "./validator"

import { getClientId } from "../../../store/masters/thunk/client/getId"
import { postClient } from "../../../store/masters/thunk/client/post"
import { putClient } from "../../../store/masters/thunk/client/put"

const NewEdit = ({ setBtns }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("client");
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [isMount, setIsMount] = useState(false);

    const current = useSelector(state => state.masters.client.detail);
    const put = useSelector(state => state.masters.client.put);
    const post = useSelector(state => state.masters.client.post);
   
    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }) }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
    }, [post, put])

    useEffect(() => {
        if ((searchParams.get('from') !== "new")) {
            if (!!id) {
                dispatch(getClientId({ id }))
            }
        }
    }, [dispatch, id, searchParams.get('from')])



    const onSubmit = (values) => {
        const idClient = id;
        const whitOutNulls = pickBy(values, identity);
        if (idClient) { dispatch(putClient({ idClient, ...whitOutNulls })) }
        else { dispatch(postClient(whitOutNulls)) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,
        idClient: id ? get(current, "data.idClient", 0) : 0,
        name: id ? get(current, "data.name", "") : "",

        state: id ? get(current, "data.state", "") : "",
        city: id ? get(current, "data.city", "") : "",

        address: id ? get(current, "data.address", "") : "",
    }

    useEffect(() => {
        setBtns(
            <Stack className='mr-6' direction="row" spacing={2} justifyContent="flex-end">
                <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" form="form-client" loading={get(put, "isLoading")}>{id ? __(`action.save`) : __(`action.new`)}</LoadingButton>
                <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/masters/clients")} disabled={get(put, "isLoading")} >{__(`action.cancel`)}</Button>
            </Stack>
        )
    }, [get(put, "isLoading")])


    return (
        <div>
            {(get(current, "isLoading")) && !isMount
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form id="form-client" onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                        <Switch formik={formik} name="isActive" __={__} />
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} >
                                                <InputText
                                                    formik={formik}
                                                    name="name"
                                                    label={__(`form.name.label`)}
                                                    placeholder={__(`form.name.placeholder`)}
                                                    disabled={get(current, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.3`)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <InputText
                                                    formik={formik}
                                                    name="state"
                                                    label={__(`form.state.label`)}
                                                    placeholder={__(`form.state.placeholder`)}
                                                    disabled={get(current, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <InputText
                                                    formik={formik}
                                                    name="city"
                                                    label={__(`form.city.label`)}
                                                    placeholder={__(`form.city.placeholder`)}
                                                    disabled={get(current, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <InputText
                                                    formik={formik}
                                                    name="address"
                                                    label={__(`form.address.label`)}
                                                    placeholder={__(`form.address.placeholder`)}
                                                    disabled={get(current, "isLoading", false)}
                                                    multiline
                                                    rows={4}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </div>
    )
}

export default NewEdit
