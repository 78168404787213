import Invoice from "../../../../pages/Transactions/Invoice";
import InvoiceNewEdit from "../../../../pages/Transactions/Invoice/NewEdit";
import Detail from "../../../../pages/Transactions/Invoice/Detail";

const routerInvoice = [
    {
        key: "invoices",
        path: "/transactions/invoices",
        element: <Invoice />,
    },
    {
        key: "invoices",
        path: "/transactions/invoices/new",
        element: <InvoiceNewEdit />,
    },
    {
        key: "invoices",
        path: "/transactions/invoices/detail/:id",
        element: <Detail />,
    },
    {
        key: "invoices",
        path: "/transactions/invoices/:id",
        element: <Detail />,
    },
]

export default routerInvoice
