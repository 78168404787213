import {
    getOrdersToInvoiceLoading,
    getOrdersToInvoiceReject,
    getOrdersToInvoiceRequest,
    getOrdersToInvoiceSuccess
} from "../../actions/invoice/getOrdersToInvoiceAll";

export const getOrdersToInvoice = (formData) => async (dispatch, getState) => {
    dispatch(getOrdersToInvoiceLoading());
    try {
        const { data } = await getOrdersToInvoiceRequest(formData, getState);
        dispatch(getOrdersToInvoiceSuccess(data))
    } catch (error) {
        dispatch(getOrdersToInvoiceReject(error))
    }
    return Promise.resolve();
};