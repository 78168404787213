/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import validator from "./validator"
import Map from "../../../components/map/Map"

import { getDeliveryPointId } from "../../../store/masters/thunk/deliveryPoint/getId"
import { postDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/post"
import { putDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/put"

const NewEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("delivery");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })
    const [showMap, setShowMap] = useState(false)
    const [position, setPosition] = useState({
        lat: null,
        lng: null
    });

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.masters.deliveryPoints.detail);
    const put = useSelector(state => state.masters.deliveryPoints.put);
    const post = useSelector(state => state.masters.deliveryPoints.post);

    useEffect(() => {
        setShowMap(false)
        setTimeout(() => {
            setShowMap(true)
        }, 1000);
    }, [__("lang.current.lang")])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/masters/delivery_points/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    }, [put, post])

    useEffect(() => {
        if (!!id) {
            dispatch(getDeliveryPointId({ id }))
        }
    }, [dispatch, id])

    const onSubmit = (values) => {
        const idDeliveryPoint = id;
        const whitOutNulls = pickBy(values, identity);

        if (idDeliveryPoint) { dispatch(putDeliveryPoint({ idDeliveryPoint, ...whitOutNulls })) }
        else { dispatch(postDeliveryPoint(whitOutNulls)) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,
        deliveryName: id ? get(current, "data.deliveryName", "") : "",
        latitude: id ? get(current, "data.latitude", "") : "",
        longitude: id ? get(current, "data.longitude", "") : "",
        deliveryCode: id ? get(current, "data.deliveryCode", "") : "",
        description: id ? get(current, "data.description", "") : "",

        contactName: id ? get(current, "data.contactName", "") : "",
        contactPhone: id ? get(current, "data.contactPhone", "") : "",
        contactEmail: id ? get(current, "data.contactEmail", "") : "",

        state: id ? get(current, "data.state", "") : "",
        city: id ? get(current, "data.city", "") : "",
        address: id ? get(current, "data.address", "") : "",
        addressReference: id ? get(current, "data.addressReference", "") : "",

        idUser: get(user, "idUser"),
    }

    const changePosition = (coord, formik) => {
        setPosition(coord)
    }


    return (
        <Layout
            propsToolbar={{
                title: !id ? __(`head.new.name`) : __(`head.edit.name`),
                srute: !id ? __(`head.new.code`) : id
            }}
        >
            {get(current, "isLoading")
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='p-4'>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" loading={get(post, "isLoading") || get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                        <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/masters/delivery_points")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                                    </Stack>
                                </Paper>

                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                        <Switch formik={formik} name="isActive" __={__} />
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="deliveryName"
                                                    label={__(`form.deliveryName.label`)}
                                                    placeholder={__(`form.deliveryName.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="deliveryCode"
                                                    label={__(`form.deliveryCode.label`)}
                                                    placeholder={__(`form.deliveryCode.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="description"
                                                    label={__(`form.description.label`)}
                                                    placeholder={__(`form.description.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.2`)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="contactName"
                                                    label={__(`form.contactName.label`)}
                                                    placeholder={__(`form.contactName.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="contactPhone"
                                                    label={__(`form.contactPhone.label`)}
                                                    placeholder={__(`form.contactPhone.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="contactEmail"
                                                    label={__(`form.contactEmail.label`)}
                                                    placeholder={__(`form.contactEmail.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className='pt-4' component="p" variant="bmr" color="text.lite" >{__(`labels.3`)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="state"
                                                    label={__(`form.state.label`)}
                                                    placeholder={__(`form.state.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <InputText
                                                    formik={formik}
                                                    name="city"
                                                    label={__(`form.city.label`)}
                                                    placeholder={__(`form.city.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <InputText
                                                    formik={formik}
                                                    name="address"
                                                    label={__(`form.address.label`)}
                                                    placeholder={__(`form.address.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <InputText
                                                    formik={formik}
                                                    name="addressReference"
                                                    label={__(`form.addressReference.label`)}
                                                    placeholder={__(`form.addressReference.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>

                                <Paper className='mt-4 p-4'>
                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                        <Grid item xs={12}>
                                            <Typography component="h4" variant="h4">{__(`labels.4`)}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box height={500} width="100%" display="flex">
                                                {showMap &&
                                                    <Map
                                                        position={position}
                                                        setPositions={(e) => changePosition(e, formik)}
                                                        draggable={true}
                                                        formik={formik}
                                                    />
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </Layout>
    )
}

export default NewEdit