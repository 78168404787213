import {
    putRouteOrderLoading,
    putRouteOrderRequest,
    putRouteOrderSuccess,
    putRouteOrderReject,
    putRouteOrderReset
} from "../../actions/route/putOrders";

export const putRouteOrder = (formData) => async (dispatch, getState) => {
    dispatch(putRouteOrderLoading());
    try {
        const { data } = await putRouteOrderRequest(formData, getState);
        dispatch(putRouteOrderSuccess(data))
    } catch (error) {
        dispatch(putRouteOrderReject(error))
    } finally {
        setTimeout(() => { dispatch(putRouteOrderReset()) }, 3000);
    }
    return Promise.resolve();
};