import {
    postRouteCostLoading,
    postRouteCostRequest,
    postRouteCostSuccess,
    postRouteCostReject,
    postRouteCostReset
} from "../../actions/route/postCost";

export const postRouteCost = (formData) => async (dispatch, getState) => {
    dispatch(postRouteCostLoading());
    try {
        const { data } = await postRouteCostRequest(formData, getState);
        dispatch(postRouteCostSuccess(data))
    } catch (error) {
        dispatch(postRouteCostReject(error))
    } finally {
        setTimeout(() => { dispatch(postRouteCostReset()) }, 3000);
    }
    return Promise.resolve();
};