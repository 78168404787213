import {
    putDeliveryPointLoading,
    putDeliveryPointRequest,
    putDeliveryPointSuccess,
    putDeliveryPointReject,
    putDeliveryPointReset
} from "../../actions/deliveryPoints/put";

export const putDeliveryPoint = (formData) => async (dispatch, getState) => {
    dispatch(putDeliveryPointLoading());
    try {
        const { data } = await putDeliveryPointRequest(formData, getState);
        dispatch(putDeliveryPointSuccess(data))
    } catch (error) {
        dispatch(putDeliveryPointReject(error))
    } finally {
        setTimeout(() => { dispatch(putDeliveryPointReset()) }, 3000);
    }
    return Promise.resolve();
};