import {
    putRouteLoading,
    putRouteRequest,
    putRouteSuccess,
    putRouteReject,
    putRouteReset
} from "../../actions/route/put";

export const putRoute = (formData) => async (dispatch, getState) => {
    dispatch(putRouteLoading());
    try {
        const { data } = await putRouteRequest(formData, getState);
        dispatch(putRouteSuccess(data))
    } catch (error) {
        dispatch(putRouteReject(error))
    } finally {
        setTimeout(() => { dispatch(putRouteReset()) }, 3000);
    }
    return Promise.resolve();
};