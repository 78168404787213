import {
    getRouteOrderAllLoading,
    getRouteOrderAllReject,
    getRouteOrderAllRequest,
    getRouteOrderAllSuccess,
} from "../../actions/route/getOrderAll";

export const getRouteOrderAll = (formData) => async (dispatch, getState) => {
    dispatch(getRouteOrderAllLoading());
    try {
        const { data } = await getRouteOrderAllRequest(formData, getState);
        dispatch(getRouteOrderAllSuccess(data))
    } catch (error) {
        dispatch(getRouteOrderAllReject(error))
    }
    return Promise.resolve();
};
