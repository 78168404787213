import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_CLIENT_LOADING = 'GET_CLIENT_LOADING';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_REJECT = 'GET_CLIENT_REJECT';

//* ACTIONS ------------------------------------------------
export const getClientLoading = () => ({ type: GET_CLIENT_LOADING });
export const getClientSuccess = (payload) => ({ type: GET_CLIENT_SUCCESS, payload });
export const getClientReject = (payload) => ({ type: GET_CLIENT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getClientRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Clients`, options);
};

