import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            oldPass: yup.string().required("Es requerido"),
            pass: yup.string()
                .matches(/^(?=.*[a-z])/, __(`changePass.errors.1`))
                .matches(/^(?=.*[A-Z])/, __(`changePass.errors.2`))
                .matches(/^(?=.*[0-9])/, __(`changePass.errors.3`))
                .min(8, __(`changePass.errors.4`))
                .required(__(`changePass.pass.error`))
                .oneOf([yup.ref('confirmation'), null], __(`changePass.errors.5`)),
            confirmation: yup.string()
                .matches(/^(?=.*[a-z])/, 'Ingrese al menos 1 minuscula')
                .matches(/^(?=.*[A-Z])/, 'Ingrese al menos 1 mayúscula')
                .matches(/^(?=.*[0-9])/, 'Ingrese al menos 1 número')
                .oneOf([yup.ref('pass'), null], __(`changePass.errors.5`))
        })
    )
}

export default validators
