import React from 'react';
import { SvgIcon } from '@mui/material'

const Shield = (props) => (
    <SvgIcon viewBox="0 0 133 214" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "234px", height: "auto" }}>
        <path d="M104.218 180.075C103.61 180.418 103.61 180.973 104.218 181.343C104.825 181.687 105.802 181.687 106.41 181.343C107.017 181 107.017 180.445 106.41 180.075C105.802 179.732 104.825 179.732 104.218 180.075Z" fill="#00283B" />
        <path d="M98.5127 176.799C97.9053 177.143 97.9053 177.697 98.5127 178.067C99.1202 178.411 100.097 178.411 100.705 178.067C101.312 177.724 101.312 177.169 100.705 176.799C100.097 176.456 99.1202 176.456 98.5127 176.799Z" fill="#00283B" />
        <path d="M92.8074 173.496C92.1999 173.84 92.1999 174.395 92.8074 174.765C93.4149 175.108 94.3921 175.108 94.9995 174.765C95.607 174.421 95.607 173.866 94.9995 173.496C94.3921 173.153 93.4149 173.153 92.8074 173.496Z" fill="#00283B" />
        <path d="M87.0769 170.22C86.4695 170.563 86.4695 171.118 87.0769 171.488C87.6844 171.832 88.6616 171.832 89.269 171.488C89.8765 171.145 89.8765 170.59 89.269 170.22C88.6616 169.877 87.6844 169.877 87.0769 170.22Z" fill="#00283B" />
        <path d="M81.3719 166.918C80.7644 167.261 80.7644 167.816 81.3719 168.186C81.9793 168.529 82.9565 168.529 83.564 168.186C84.1714 167.843 84.1714 167.288 83.564 166.918C82.9565 166.574 81.9793 166.574 81.3719 166.918Z" fill="#00283B" />
        <path d="M75.6677 163.641C75.0603 163.985 75.0603 164.54 75.6677 164.91C76.2752 165.253 77.2524 165.253 77.8599 164.91C78.4673 164.566 78.4673 164.011 77.8599 163.641C77.2524 163.298 76.2752 163.298 75.6677 163.641Z" fill="#00283B" />
        <path d="M104.218 173.496C103.61 173.84 103.61 174.395 104.218 174.765C104.825 175.108 105.802 175.108 106.41 174.765C107.017 174.421 107.017 173.866 106.41 173.496C105.802 173.153 104.825 173.153 104.218 173.496Z" fill="#00283B" />
        <path d="M98.5127 170.22C97.9053 170.563 97.9053 171.118 98.5127 171.488C99.1202 171.832 100.097 171.832 100.705 171.488C101.312 171.145 101.312 170.59 100.705 170.22C100.097 169.877 99.1202 169.877 98.5127 170.22Z" fill="#00283B" />
        <path d="M92.8074 166.918C92.1999 167.261 92.1999 167.816 92.8074 168.186C93.4149 168.529 94.3921 168.529 94.9995 168.186C95.607 167.843 95.607 167.288 94.9995 166.918C94.3921 166.574 93.4149 166.574 92.8074 166.918Z" fill="#00283B" />
        <path d="M87.0769 163.641C86.4695 163.985 86.4695 164.54 87.0769 164.91C87.6844 165.253 88.6616 165.253 89.269 164.91C89.8765 164.566 89.8765 164.011 89.269 163.641C88.6616 163.298 87.6844 163.298 87.0769 163.641Z" fill="#00283B" />
        <path d="M81.3719 160.339C80.7644 160.682 80.7644 161.237 81.3719 161.607C81.9793 161.95 82.9565 161.95 83.564 161.607C84.1714 161.263 84.1714 160.709 83.564 160.339C82.9565 159.995 81.9793 159.995 81.3719 160.339Z" fill="#00283B" />
        <path d="M24.0073 192.731C23.3999 193.075 23.3999 193.63 24.0073 193.999C24.6148 194.343 25.592 194.343 26.1995 193.999C26.8069 193.656 26.8069 193.101 26.1995 192.731C25.592 192.388 24.6148 192.388 24.0073 192.731Z" fill="#00283B" />
        <path d="M29.7388 189.455C29.1313 189.799 29.1313 190.354 29.7388 190.724C30.3462 191.067 31.3235 191.067 31.9309 190.724C32.5384 190.38 32.5384 189.825 31.9309 189.455C31.3235 189.112 30.3462 189.112 29.7388 189.455Z" fill="#00283B" />
        <path d="M35.4429 186.152C34.8354 186.496 34.8354 187.05 35.4429 187.42C36.0503 187.764 37.0276 187.764 37.635 187.42C38.2425 187.077 38.2425 186.522 37.635 186.152C37.0276 185.809 36.0503 185.809 35.4429 186.152Z" fill="#00283B" />
        <path d="M46.8523 186.152C46.2449 186.496 46.2449 187.05 46.8523 187.42C47.4598 187.764 48.437 187.764 49.0444 187.42C49.6519 187.077 49.6519 186.522 49.0444 186.152C48.437 185.809 47.4598 185.809 46.8523 186.152Z" fill="#00283B" />
        <path d="M41.1482 182.876C40.5408 183.219 40.5408 183.774 41.1482 184.144C41.7557 184.487 42.7329 184.487 43.3403 184.144C43.9478 183.801 43.9478 183.246 43.3403 182.876C42.7329 182.532 41.7557 182.532 41.1482 182.876Z" fill="#00283B" />
        <path d="M58.2627 186.152C57.6553 186.496 57.6553 187.05 58.2627 187.42C58.8702 187.764 59.8474 187.764 60.4548 187.42C61.0623 187.077 61.0623 186.522 60.4548 186.152C59.8474 185.809 58.8702 185.809 58.2627 186.152Z" fill="#00283B" />
        <path d="M52.5574 182.876C51.9499 183.219 51.9499 183.774 52.5574 184.144C53.1649 184.487 54.1421 184.487 54.7495 184.144C55.357 183.801 55.357 183.246 54.7495 182.876C54.1421 182.532 53.1649 182.532 52.5574 182.876Z" fill="#00283B" />
        <path d="M46.8523 179.599C46.2449 179.943 46.2449 180.498 46.8523 180.868C47.4598 181.211 48.437 181.211 49.0444 180.868C49.6519 180.524 49.6519 179.969 49.0444 179.599C48.437 179.256 47.4598 179.256 46.8523 179.599Z" fill="#00283B" />
        <path d="M69.7249 186.126C69.1174 186.469 69.1174 187.024 69.7249 187.394C70.3323 187.737 71.3095 187.737 71.917 187.394C72.5244 187.051 72.5244 186.496 71.917 186.126C71.3095 185.782 70.3323 185.782 69.7249 186.126Z" fill="#00283B" />
        <path d="M64.0196 182.849C63.4121 183.193 63.4121 183.748 64.0196 184.118C64.627 184.461 65.6042 184.461 66.2117 184.118C66.8191 183.774 66.8191 183.219 66.2117 182.849C65.6042 182.506 64.627 182.506 64.0196 182.849Z" fill="#00283B" />
        <path d="M58.3155 179.547C57.708 179.89 57.708 180.445 58.3155 180.815C58.9229 181.158 59.9001 181.158 60.5076 180.815C61.115 180.471 61.115 179.917 60.5076 179.547C59.9001 179.203 58.9229 179.203 58.3155 179.547Z" fill="#00283B" />
        <path d="M52.6104 176.271C52.0029 176.614 52.0029 177.169 52.6104 177.539C53.2178 177.882 54.195 177.882 54.8025 177.539C55.4099 177.196 55.4099 176.641 54.8025 176.271C54.195 175.927 53.2178 175.927 52.6104 176.271Z" fill="#00283B" />
        <path d="M81.1604 186.126C80.553 186.469 80.553 187.024 81.1604 187.394C81.7679 187.737 82.7451 187.737 83.3525 187.394C83.96 187.051 83.96 186.496 83.3525 186.126C82.7451 185.782 81.7679 185.782 81.1604 186.126Z" fill="#00283B" />
        <path d="M75.4563 182.849C74.8489 183.193 74.8489 183.748 75.4563 184.118C76.0638 184.461 77.041 184.461 77.6484 184.118C78.2559 183.774 78.2559 183.219 77.6484 182.849C77.041 182.506 76.0638 182.506 75.4563 182.849Z" fill="#00283B" />
        <path d="M69.7249 179.547C69.1174 179.89 69.1174 180.445 69.7249 180.815C70.3323 181.158 71.3095 181.158 71.917 180.815C72.5244 180.471 72.5244 179.917 71.917 179.547C71.3095 179.203 70.3323 179.203 69.7249 179.547Z" fill="#00283B" />
        <path d="M64.0196 176.271C63.4121 176.614 63.4121 177.169 64.0196 177.539C64.627 177.882 65.6042 177.882 66.2117 177.539C66.8191 177.196 66.8191 176.641 66.2117 176.271C65.6042 175.927 64.627 175.927 64.0196 176.271Z" fill="#00283B" />
        <path d="M58.3155 172.968C57.708 173.312 57.708 173.866 58.3155 174.236C58.9229 174.58 59.9001 174.58 60.5076 174.236C61.115 173.893 61.115 173.338 60.5076 172.968C59.9001 172.625 58.9229 172.625 58.3155 172.968Z" fill="#00283B" />
        <path d="M98.2749 189.429C97.6675 189.772 97.6675 190.327 98.2749 190.697C98.8824 191.041 99.8596 191.041 100.467 190.697C101.075 190.354 101.075 189.799 100.467 189.429C99.8596 189.086 98.8824 189.086 98.2749 189.429Z" fill="#00283B" />
        <path d="M92.5708 186.126C91.9634 186.469 91.9634 187.024 92.5708 187.394C93.1783 187.737 94.1555 187.737 94.7629 187.394C95.3704 187.051 95.3704 186.496 94.7629 186.126C94.1555 185.782 93.1783 185.782 92.5708 186.126Z" fill="#00283B" />
        <path d="M86.8657 182.849C86.2583 183.193 86.2583 183.748 86.8657 184.118C87.4732 184.461 88.4504 184.461 89.0579 184.118C89.6653 183.774 89.6653 183.219 89.0579 182.849C88.4504 182.506 87.4732 182.506 86.8657 182.849Z" fill="#00283B" />
        <path d="M81.1604 179.547C80.553 179.89 80.553 180.445 81.1604 180.815C81.7679 181.158 82.7451 181.158 83.3525 180.815C83.96 180.471 83.96 179.917 83.3525 179.547C82.7451 179.203 81.7679 179.203 81.1604 179.547Z" fill="#00283B" />
        <path d="M75.4563 176.271C74.8489 176.614 74.8489 177.169 75.4563 177.539C76.0638 177.882 77.041 177.882 77.6484 177.539C78.2559 177.196 78.2559 176.641 77.6484 176.271C77.041 175.927 76.0638 175.927 75.4563 176.271Z" fill="#00283B" />
        <path d="M69.7249 172.968C69.1174 173.312 69.1174 173.866 69.7249 174.236C70.3323 174.58 71.3095 174.58 71.917 174.236C72.5244 173.893 72.5244 173.338 71.917 172.968C71.3095 172.625 70.3323 172.625 69.7249 172.968Z" fill="#00283B" />
        <path d="M64.0196 169.692C63.4121 170.035 63.4121 170.59 64.0196 170.96C64.627 171.303 65.6042 171.303 66.2117 170.96C66.8191 170.616 66.8191 170.062 66.2117 169.692C65.6042 169.348 64.627 169.348 64.0196 169.692Z" fill="#00283B" />
        <path d="M103.98 186.126C103.373 186.469 103.373 187.024 103.98 187.394C104.588 187.737 105.565 187.737 106.172 187.394C106.78 187.051 106.78 186.496 106.172 186.126C105.565 185.782 104.588 185.782 103.98 186.126Z" fill="#00283B" />
        <path d="M98.2749 182.849C97.6675 183.193 97.6675 183.748 98.2749 184.118C98.8824 184.461 99.8596 184.461 100.467 184.118C101.075 183.774 101.075 183.219 100.467 182.849C99.8596 182.506 98.8824 182.506 98.2749 182.849Z" fill="#00283B" />
        <path d="M92.5708 179.547C91.9634 179.89 91.9634 180.445 92.5708 180.815C93.1783 181.158 94.1555 181.158 94.7629 180.815C95.3704 180.471 95.3704 179.917 94.7629 179.547C94.1555 179.203 93.1783 179.203 92.5708 179.547Z" fill="#00283B" />
        <path d="M86.8657 176.271C86.2583 176.614 86.2583 177.169 86.8657 177.539C87.4732 177.882 88.4504 177.882 89.0579 177.539C89.6653 177.196 89.6653 176.641 89.0579 176.271C88.4504 175.927 87.4732 175.927 86.8657 176.271Z" fill="#00283B" />
        <path d="M81.1604 172.968C80.553 173.312 80.553 173.866 81.1604 174.236C81.7679 174.58 82.7451 174.58 83.3525 174.236C83.96 173.893 83.96 173.338 83.3525 172.968C82.7451 172.625 81.7679 172.625 81.1604 172.968Z" fill="#00283B" />
        <path d="M75.4563 169.692C74.8489 170.035 74.8489 170.59 75.4563 170.96C76.0638 171.303 77.041 171.303 77.6484 170.96C78.2559 170.616 78.2559 170.062 77.6484 169.692C77.041 169.348 76.0638 169.348 75.4563 169.692Z" fill="#00283B" />
        <path d="M69.7249 166.389C69.1174 166.732 69.1174 167.287 69.7249 167.657C70.3323 168.001 71.3095 168.001 71.917 167.657C72.5244 167.314 72.5244 166.759 71.917 166.389C71.3095 166.045 70.3323 166.045 69.7249 166.389Z" fill="#00283B" />
        <path d="M104.218 180.075C103.61 180.418 103.61 180.973 104.218 181.343C104.825 181.687 105.802 181.687 106.41 181.343C107.017 181 107.017 180.445 106.41 180.075C105.802 179.732 104.825 179.732 104.218 180.075Z" fill="#00283B" />
        <path d="M98.5127 176.799C97.9053 177.143 97.9053 177.697 98.5127 178.067C99.1202 178.411 100.097 178.411 100.705 178.067C101.312 177.724 101.312 177.169 100.705 176.799C100.097 176.456 99.1202 176.456 98.5127 176.799Z" fill="#00283B" />
        <path d="M92.8074 173.496C92.1999 173.84 92.1999 174.395 92.8074 174.765C93.4149 175.108 94.3921 175.108 94.9995 174.765C95.607 174.421 95.607 173.866 94.9995 173.496C94.3921 173.153 93.4149 173.153 92.8074 173.496Z" fill="#00283B" />
        <path d="M87.0769 170.22C86.4695 170.563 86.4695 171.118 87.0769 171.488C87.6844 171.832 88.6616 171.832 89.269 171.488C89.8765 171.145 89.8765 170.59 89.269 170.22C88.6616 169.877 87.6844 169.877 87.0769 170.22Z" fill="#00283B" />
        <path d="M81.3719 166.918C80.7644 167.261 80.7644 167.816 81.3719 168.186C81.9793 168.529 82.9565 168.529 83.564 168.186C84.1714 167.843 84.1714 167.288 83.564 166.918C82.9565 166.574 81.9793 166.574 81.3719 166.918Z" fill="#00283B" />
        <path d="M75.6677 163.641C75.0603 163.985 75.0603 164.54 75.6677 164.91C76.2752 165.253 77.2524 165.253 77.8599 164.91C78.4673 164.566 78.4673 164.011 77.8599 163.641C77.2524 163.298 76.2752 163.298 75.6677 163.641Z" fill="#00283B" />
        <path d="M104.218 173.496C103.61 173.84 103.61 174.395 104.218 174.765C104.825 175.108 105.802 175.108 106.41 174.765C107.017 174.421 107.017 173.866 106.41 173.496C105.802 173.153 104.825 173.153 104.218 173.496Z" fill="#00283B" />
        <path d="M98.5127 170.22C97.9053 170.563 97.9053 171.118 98.5127 171.488C99.1202 171.832 100.097 171.832 100.705 171.488C101.312 171.145 101.312 170.59 100.705 170.22C100.097 169.877 99.1202 169.877 98.5127 170.22Z" fill="#00283B" />
        <path d="M92.8074 166.918C92.1999 167.261 92.1999 167.816 92.8074 168.186C93.4149 168.529 94.3921 168.529 94.9995 168.186C95.607 167.843 95.607 167.288 94.9995 166.918C94.3921 166.574 93.4149 166.574 92.8074 166.918Z" fill="#00283B" />
        <path d="M87.0769 163.641C86.4695 163.985 86.4695 164.54 87.0769 164.91C87.6844 165.253 88.6616 165.253 89.269 164.91C89.8765 164.566 89.8765 164.011 89.269 163.641C88.6616 163.298 87.6844 163.298 87.0769 163.641Z" fill="#00283B" />
        <path d="M81.3719 160.339C80.7644 160.682 80.7644 161.237 81.3719 161.607C81.9793 161.95 82.9565 161.95 83.564 161.607C84.1714 161.263 84.1714 160.709 83.564 160.339C82.9565 159.995 81.9793 159.995 81.3719 160.339Z" fill="#00283B" />
        <path d="M35.4429 212.468C34.8354 212.812 34.8354 213.366 35.4429 213.736C36.0503 214.08 37.0276 214.08 37.635 213.736C38.2425 213.393 38.2425 212.838 37.635 212.468C37.0276 212.125 36.0503 212.125 35.4429 212.468Z" fill="#00283B" />
        <path d="M29.7388 209.192C29.1313 209.535 29.1313 210.09 29.7388 210.46C30.3462 210.803 31.3235 210.803 31.9309 210.46C32.5384 210.116 32.5384 209.562 31.9309 209.192C31.3235 208.848 30.3462 208.848 29.7388 209.192Z" fill="#00283B" />
        <path d="M24.0073 205.889C23.3999 206.232 23.3999 206.787 24.0073 207.157C24.6148 207.501 25.592 207.501 26.1995 207.157C26.8069 206.814 26.8069 206.259 26.1995 205.889C25.592 205.545 24.6148 205.545 24.0073 205.889Z" fill="#00283B" />
        <path d="M46.8523 212.468C46.2449 212.812 46.2449 213.366 46.8523 213.736C47.4598 214.08 48.437 214.08 49.0444 213.736C49.6519 213.393 49.6519 212.838 49.0444 212.468C48.437 212.125 47.4598 212.125 46.8523 212.468Z" fill="#00283B" />
        <path d="M41.1482 209.192C40.5408 209.535 40.5408 210.09 41.1482 210.46C41.7557 210.803 42.7329 210.803 43.3403 210.46C43.9478 210.116 43.9478 209.562 43.3403 209.192C42.7329 208.848 41.7557 208.848 41.1482 209.192Z" fill="#00283B" />
        <path d="M35.4429 205.889C34.8354 206.232 34.8354 206.787 35.4429 207.157C36.0503 207.501 37.0276 207.501 37.635 207.157C38.2425 206.814 38.2425 206.259 37.635 205.889C37.0276 205.545 36.0503 205.545 35.4429 205.889Z" fill="#00283B" />
        <path d="M29.7388 202.613C29.1313 202.957 29.1313 203.511 29.7388 203.881C30.3462 204.225 31.3235 204.225 31.9309 203.881C32.5384 203.538 32.5384 202.983 31.9309 202.613C31.3235 202.27 30.3462 202.27 29.7388 202.613Z" fill="#00283B" />
        <path d="M24.0073 199.31C23.3999 199.654 23.3999 200.209 24.0073 200.579C24.6148 200.922 25.592 200.922 26.1995 200.579C26.8069 200.235 26.8069 199.68 26.1995 199.31C25.592 198.967 24.6148 198.967 24.0073 199.31Z" fill="#00283B" />
        <path d="M58.2627 212.468C57.6553 212.812 57.6553 213.366 58.2627 213.736C58.8702 214.08 59.8474 214.08 60.4548 213.736C61.0623 213.393 61.0623 212.838 60.4548 212.468C59.8474 212.125 58.8702 212.125 58.2627 212.468Z" fill="#00283B" />
        <path d="M52.5574 209.192C51.9499 209.535 51.9499 210.09 52.5574 210.46C53.1649 210.803 54.1421 210.803 54.7495 210.46C55.357 210.116 55.357 209.562 54.7495 209.192C54.1421 208.848 53.1649 208.848 52.5574 209.192Z" fill="#00283B" />
        <path d="M46.8523 205.889C46.2449 206.232 46.2449 206.787 46.8523 207.157C47.4598 207.5 48.437 207.5 49.0444 207.157C49.6519 206.813 49.6519 206.259 49.0444 205.889C48.437 205.519 47.4598 205.545 46.8523 205.889Z" fill="#00283B" />
        <path d="M41.1482 202.613C40.5408 202.957 40.5408 203.511 41.1482 203.881C41.7557 204.225 42.7329 204.225 43.3403 203.881C43.9478 203.538 43.9478 202.983 43.3403 202.613C42.7329 202.27 41.7557 202.27 41.1482 202.613Z" fill="#00283B" />
        <path d="M35.4429 199.31C34.8354 199.654 34.8354 200.209 35.4429 200.579C36.0503 200.922 37.0276 200.922 37.635 200.579C38.2425 200.235 38.2425 199.68 37.635 199.31C37.0276 198.967 36.0503 198.967 35.4429 199.31Z" fill="#00283B" />
        <path d="M29.7388 196.034C29.1313 196.377 29.1313 196.932 29.7388 197.302C30.3462 197.646 31.3235 197.646 31.9309 197.302C32.5384 196.959 32.5384 196.404 31.9309 196.034C31.3235 195.69 30.3462 195.69 29.7388 196.034Z" fill="#00283B" />
        <path d="M24.0073 192.731C23.3999 193.075 23.3999 193.63 24.0073 193.999C24.6148 194.343 25.592 194.343 26.1995 193.999C26.8069 193.656 26.8069 193.101 26.1995 192.731C25.592 192.388 24.6148 192.388 24.0073 192.731Z" fill="#00283B" />
        <path d="M63.9668 209.192C63.3594 209.535 63.3594 210.09 63.9668 210.46C64.5743 210.803 65.5515 210.803 66.1589 210.46C66.7664 210.116 66.7664 209.562 66.1589 209.192C65.5515 208.848 64.5743 208.848 63.9668 209.192Z" fill="#00283B" />
        <path d="M58.2627 205.889C57.6553 206.232 57.6553 206.787 58.2627 207.157C58.8702 207.501 59.8474 207.501 60.4548 207.157C61.0623 206.814 61.0623 206.259 60.4548 205.889C59.8474 205.545 58.8702 205.545 58.2627 205.889Z" fill="#00283B" />
        <path d="M52.5574 202.613C51.9499 202.957 51.9499 203.511 52.5574 203.881C53.1649 204.225 54.1421 204.225 54.7495 203.881C55.357 203.538 55.357 202.983 54.7495 202.613C54.1421 202.27 53.1649 202.27 52.5574 202.613Z" fill="#00283B" />
        <path d="M46.8523 199.31C46.2449 199.654 46.2449 200.209 46.8523 200.579C47.4598 200.922 48.437 200.922 49.0444 200.579C49.6519 200.235 49.6519 199.68 49.0444 199.31C48.437 198.967 47.4598 198.967 46.8523 199.31Z" fill="#00283B" />
        <path d="M41.1482 196.034C40.5408 196.377 40.5408 196.932 41.1482 197.302C41.7557 197.646 42.7329 197.646 43.3403 197.302C43.9478 196.959 43.9478 196.404 43.3403 196.034C42.7329 195.69 41.7557 195.69 41.1482 196.034Z" fill="#00283B" />
        <path d="M35.4429 192.731C34.8354 193.075 34.8354 193.63 35.4429 193.999C36.0503 194.343 37.0276 194.343 37.635 193.999C38.2425 193.656 38.2425 193.101 37.635 192.731C37.0276 192.388 36.0503 192.388 35.4429 192.731Z" fill="#00283B" />
        <path d="M29.7388 189.455C29.1313 189.799 29.1313 190.354 29.7388 190.724C30.3462 191.067 31.3235 191.067 31.9309 190.724C32.5384 190.38 32.5384 189.825 31.9309 189.455C31.3235 189.112 30.3462 189.112 29.7388 189.455Z" fill="#00283B" />
        <path d="M69.6983 205.889C69.0908 206.232 69.0908 206.787 69.6983 207.157C70.3057 207.501 71.2829 207.501 71.8904 207.157C72.4978 206.814 72.4978 206.259 71.8904 205.889C71.2829 205.545 70.3057 205.545 69.6983 205.889Z" fill="#00283B" />
        <path d="M63.9668 202.613C63.3594 202.957 63.3594 203.511 63.9668 203.881C64.5743 204.225 65.5515 204.225 66.1589 203.881C66.7664 203.538 66.7664 202.983 66.1589 202.613C65.5515 202.27 64.5743 202.27 63.9668 202.613Z" fill="#00283B" />
        <path d="M58.2627 199.31C57.6553 199.654 57.6553 200.209 58.2627 200.579C58.8702 200.922 59.8474 200.922 60.4548 200.579C61.0623 200.235 61.0623 199.68 60.4548 199.31C59.8474 198.967 58.8702 198.967 58.2627 199.31Z" fill="#00283B" />
        <path d="M52.5574 196.034C51.9499 196.377 51.9499 196.932 52.5574 197.302C53.1649 197.646 54.1421 197.646 54.7495 197.302C55.357 196.959 55.357 196.404 54.7495 196.034C54.1421 195.69 53.1649 195.69 52.5574 196.034Z" fill="#00283B" />
        <path d="M46.8523 192.731C46.2449 193.075 46.2449 193.63 46.8523 193.999C47.4598 194.343 48.437 194.343 49.0444 193.999C49.6519 193.656 49.6519 193.101 49.0444 192.731C48.437 192.388 47.4598 192.388 46.8523 192.731Z" fill="#00283B" />
        <path d="M41.1482 189.455C40.5408 189.799 40.5408 190.354 41.1482 190.724C41.7557 191.067 42.7329 191.067 43.3403 190.724C43.9478 190.38 43.9478 189.825 43.3403 189.455C42.7329 189.112 41.7557 189.112 41.1482 189.455Z" fill="#00283B" />
        <path d="M35.4429 186.152C34.8354 186.496 34.8354 187.05 35.4429 187.42C36.0503 187.764 37.0276 187.764 37.635 187.42C38.2425 187.077 38.2425 186.522 37.635 186.152C37.0276 185.809 36.0503 185.809 35.4429 186.152Z" fill="#00283B" />
        <path d="M75.4036 202.613C74.7961 202.957 74.7961 203.511 75.4036 203.881C76.011 204.225 76.9883 204.225 77.5957 203.881C78.2032 203.538 78.2032 202.983 77.5957 202.613C76.9883 202.27 76.011 202.27 75.4036 202.613Z" fill="#00283B" />
        <path d="M69.6983 199.31C69.0908 199.654 69.0908 200.209 69.6983 200.579C70.3057 200.922 71.2829 200.922 71.8904 200.579C72.4978 200.235 72.4978 199.68 71.8904 199.31C71.2829 198.967 70.3057 198.967 69.6983 199.31Z" fill="#00283B" />
        <path d="M63.9668 196.034C63.3594 196.377 63.3594 196.932 63.9668 197.302C64.5743 197.646 65.5515 197.646 66.1589 197.302C66.7664 196.959 66.7664 196.404 66.1589 196.034C65.5515 195.69 64.5743 195.69 63.9668 196.034Z" fill="#00283B" />
        <path d="M58.2627 192.731C57.6553 193.075 57.6553 193.63 58.2627 193.999C58.8702 194.343 59.8474 194.343 60.4548 193.999C61.0623 193.656 61.0623 193.101 60.4548 192.731C59.8474 192.388 58.8702 192.388 58.2627 192.731Z" fill="#00283B" />
        <path d="M52.5574 189.455C51.9499 189.799 51.9499 190.354 52.5574 190.724C53.1649 191.067 54.1421 191.067 54.7495 190.724C55.357 190.38 55.357 189.825 54.7495 189.455C54.1421 189.112 53.1649 189.112 52.5574 189.455Z" fill="#00283B" />
        <path d="M46.8523 186.152C46.2449 186.496 46.2449 187.05 46.8523 187.42C47.4598 187.764 48.437 187.764 49.0444 187.42C49.6519 187.077 49.6519 186.522 49.0444 186.152C48.437 185.809 47.4598 185.809 46.8523 186.152Z" fill="#00283B" />
        <path d="M41.1482 182.876C40.5408 183.219 40.5408 183.774 41.1482 184.144C41.7557 184.487 42.7329 184.487 43.3403 184.144C43.9478 183.801 43.9478 183.246 43.3403 182.876C42.7329 182.532 41.7557 182.532 41.1482 182.876Z" fill="#00283B" />
        <path d="M81.1077 199.31C80.5002 199.654 80.5002 200.208 81.1077 200.578C81.7151 200.922 82.6924 200.922 83.2998 200.578C83.9073 200.235 83.9073 199.68 83.2998 199.31C82.6924 198.94 81.7151 198.967 81.1077 199.31Z" fill="#00283B" />
        <path d="M75.4036 196.034C74.7961 196.377 74.7961 196.932 75.4036 197.302C76.011 197.672 76.9883 197.646 77.5957 197.302C78.2032 196.959 78.2032 196.404 77.5957 196.034C76.9883 195.69 76.011 195.69 75.4036 196.034Z" fill="#00283B" />
        <path d="M69.6983 192.731C69.0908 193.075 69.0908 193.63 69.6983 193.999C70.3057 194.343 71.2829 194.343 71.8904 193.999C72.4978 193.656 72.4978 193.101 71.8904 192.731C71.2829 192.388 70.3057 192.388 69.6983 192.731Z" fill="#00283B" />
        <path d="M63.9668 189.455C63.3594 189.799 63.3594 190.354 63.9668 190.724C64.5743 191.067 65.5515 191.067 66.1589 190.724C66.7664 190.38 66.7664 189.825 66.1589 189.455C65.5515 189.112 64.5743 189.112 63.9668 189.455Z" fill="#00283B" />
        <path d="M58.2627 186.152C57.6553 186.496 57.6553 187.05 58.2627 187.42C58.8702 187.764 59.8474 187.764 60.4548 187.42C61.0623 187.077 61.0623 186.522 60.4548 186.152C59.8474 185.809 58.8702 185.809 58.2627 186.152Z" fill="#00283B" />
        <path d="M52.5574 182.876C51.9499 183.219 51.9499 183.774 52.5574 184.144C53.1649 184.487 54.1421 184.487 54.7495 184.144C55.357 183.801 55.357 183.246 54.7495 182.876C54.1421 182.532 53.1649 182.532 52.5574 182.876Z" fill="#00283B" />
        <path d="M46.8523 179.599C46.2449 179.943 46.2449 180.498 46.8523 180.868C47.4598 181.211 48.437 181.211 49.0444 180.868C49.6519 180.524 49.6519 179.969 49.0444 179.599C48.437 179.256 47.4598 179.256 46.8523 179.599Z" fill="#00283B" />
        <path d="M86.8657 196.008C86.2583 196.351 86.2583 196.906 86.8657 197.276C87.4732 197.619 88.4504 197.619 89.0579 197.276C89.6653 196.932 89.6653 196.378 89.0579 196.008C88.4504 195.664 87.4732 195.664 86.8657 196.008Z" fill="#00283B" />
        <path d="M81.1604 192.704C80.553 193.048 80.553 193.603 81.1604 193.973C81.7679 194.316 82.7451 194.316 83.3525 193.973C83.96 193.629 83.96 193.074 83.3525 192.704C82.7451 192.361 81.7679 192.361 81.1604 192.704Z" fill="#00283B" />
        <path d="M75.4563 189.429C74.8489 189.772 74.8489 190.327 75.4563 190.697C76.0638 191.041 77.041 191.041 77.6484 190.697C78.2559 190.354 78.2559 189.799 77.6484 189.429C77.041 189.086 76.0638 189.086 75.4563 189.429Z" fill="#00283B" />
        <path d="M69.7249 186.126C69.1174 186.469 69.1174 187.024 69.7249 187.394C70.3323 187.737 71.3095 187.737 71.917 187.394C72.5244 187.051 72.5244 186.496 71.917 186.126C71.3095 185.782 70.3323 185.782 69.7249 186.126Z" fill="#00283B" />
        <path d="M64.0196 182.849C63.4121 183.193 63.4121 183.748 64.0196 184.118C64.627 184.461 65.6042 184.461 66.2117 184.118C66.8191 183.774 66.8191 183.219 66.2117 182.849C65.6042 182.506 64.627 182.506 64.0196 182.849Z" fill="#00283B" />
        <path d="M58.3155 179.547C57.708 179.89 57.708 180.445 58.3155 180.815C58.9229 181.158 59.9001 181.158 60.5076 180.815C61.115 180.471 61.115 179.917 60.5076 179.547C59.9001 179.203 58.9229 179.203 58.3155 179.547Z" fill="#00283B" />
        <path d="M52.6104 176.271C52.0029 176.614 52.0029 177.169 52.6104 177.539C53.2178 177.882 54.195 177.882 54.8025 177.539C55.4099 177.196 55.4099 176.641 54.8025 176.271C54.195 175.927 53.2178 175.927 52.6104 176.271Z" fill="#00283B" />
        <path d="M92.5708 192.704C91.9634 193.048 91.9634 193.603 92.5708 193.973C93.1783 194.316 94.1555 194.316 94.7629 193.973C95.3704 193.629 95.3704 193.074 94.7629 192.704C94.1555 192.361 93.1783 192.361 92.5708 192.704Z" fill="#00283B" />
        <path d="M86.8657 189.429C86.2583 189.772 86.2583 190.327 86.8657 190.697C87.4732 191.041 88.4504 191.041 89.0579 190.697C89.6653 190.354 89.6653 189.799 89.0579 189.429C88.4504 189.086 87.4732 189.086 86.8657 189.429Z" fill="#00283B" />
        <path d="M81.1604 186.126C80.553 186.469 80.553 187.024 81.1604 187.394C81.7679 187.737 82.7451 187.737 83.3525 187.394C83.96 187.051 83.96 186.496 83.3525 186.126C82.7451 185.782 81.7679 185.782 81.1604 186.126Z" fill="#00283B" />
        <path d="M75.4563 182.849C74.8489 183.193 74.8489 183.748 75.4563 184.118C76.0638 184.461 77.041 184.461 77.6484 184.118C78.2559 183.774 78.2559 183.219 77.6484 182.849C77.041 182.506 76.0638 182.506 75.4563 182.849Z" fill="#00283B" />
        <path d="M69.7249 179.547C69.1174 179.89 69.1174 180.445 69.7249 180.815C70.3323 181.158 71.3095 181.158 71.917 180.815C72.5244 180.471 72.5244 179.917 71.917 179.547C71.3095 179.203 70.3323 179.203 69.7249 179.547Z" fill="#00283B" />
        <path d="M64.0196 176.271C63.4121 176.614 63.4121 177.169 64.0196 177.539C64.627 177.882 65.6042 177.882 66.2117 177.539C66.8191 177.196 66.8191 176.641 66.2117 176.271C65.6042 175.927 64.627 175.927 64.0196 176.271Z" fill="#00283B" />
        <path d="M58.3155 172.968C57.708 173.312 57.708 173.866 58.3155 174.236C58.9229 174.58 59.9001 174.58 60.5076 174.236C61.115 173.893 61.115 173.338 60.5076 172.968C59.9001 172.625 58.9229 172.625 58.3155 172.968Z" fill="#00283B" />
        <path d="M98.2749 189.429C97.6675 189.772 97.6675 190.327 98.2749 190.697C98.8824 191.041 99.8596 191.041 100.467 190.697C101.075 190.354 101.075 189.799 100.467 189.429C99.8596 189.086 98.8824 189.086 98.2749 189.429Z" fill="#00283B" />
        <path d="M92.5708 186.126C91.9634 186.469 91.9634 187.024 92.5708 187.394C93.1783 187.737 94.1555 187.737 94.7629 187.394C95.3704 187.051 95.3704 186.496 94.7629 186.126C94.1555 185.782 93.1783 185.782 92.5708 186.126Z" fill="#00283B" />
        <path d="M86.8657 182.849C86.2583 183.193 86.2583 183.748 86.8657 184.118C87.4732 184.461 88.4504 184.461 89.0579 184.118C89.6653 183.774 89.6653 183.219 89.0579 182.849C88.4504 182.506 87.4732 182.506 86.8657 182.849Z" fill="#00283B" />
        <path d="M81.1604 179.547C80.553 179.89 80.553 180.445 81.1604 180.815C81.7679 181.158 82.7451 181.158 83.3525 180.815C83.96 180.471 83.96 179.917 83.3525 179.547C82.7451 179.203 81.7679 179.203 81.1604 179.547Z" fill="#00283B" />
        <path d="M75.4563 176.271C74.8489 176.614 74.8489 177.169 75.4563 177.539C76.0638 177.882 77.041 177.882 77.6484 177.539C78.2559 177.196 78.2559 176.641 77.6484 176.271C77.041 175.927 76.0638 175.927 75.4563 176.271Z" fill="#00283B" />
        <path d="M69.7249 172.968C69.1174 173.312 69.1174 173.866 69.7249 174.236C70.3323 174.58 71.3095 174.58 71.917 174.236C72.5244 173.893 72.5244 173.338 71.917 172.968C71.3095 172.625 70.3323 172.625 69.7249 172.968Z" fill="#00283B" />
        <path d="M64.0196 169.692C63.4121 170.035 63.4121 170.59 64.0196 170.96C64.627 171.303 65.6042 171.303 66.2117 170.96C66.8191 170.616 66.8191 170.062 66.2117 169.692C65.6042 169.348 64.627 169.348 64.0196 169.692Z" fill="#00283B" />
        <path d="M103.98 186.126C103.373 186.469 103.373 187.024 103.98 187.394C104.588 187.737 105.565 187.737 106.172 187.394C106.78 187.051 106.78 186.496 106.172 186.126C105.565 185.782 104.588 185.782 103.98 186.126Z" fill="#00283B" />
        <path d="M98.2749 182.849C97.6675 183.193 97.6675 183.748 98.2749 184.118C98.8824 184.461 99.8596 184.461 100.467 184.118C101.075 183.774 101.075 183.219 100.467 182.849C99.8596 182.506 98.8824 182.506 98.2749 182.849Z" fill="#00283B" />
        <path d="M92.5708 179.547C91.9634 179.89 91.9634 180.445 92.5708 180.815C93.1783 181.158 94.1555 181.158 94.7629 180.815C95.3704 180.471 95.3704 179.917 94.7629 179.547C94.1555 179.203 93.1783 179.203 92.5708 179.547Z" fill="#00283B" />
        <path d="M86.8657 176.271C86.2583 176.614 86.2583 177.169 86.8657 177.539C87.4732 177.882 88.4504 177.882 89.0579 177.539C89.6653 177.196 89.6653 176.641 89.0579 176.271C88.4504 175.927 87.4732 175.927 86.8657 176.271Z" fill="#00283B" />
        <path d="M81.1604 172.968C80.553 173.312 80.553 173.866 81.1604 174.236C81.7679 174.58 82.7451 174.58 83.3525 174.236C83.96 173.893 83.96 173.338 83.3525 172.968C82.7451 172.625 81.7679 172.625 81.1604 172.968Z" fill="#00283B" />
        <path d="M75.4563 169.692C74.8489 170.035 74.8489 170.59 75.4563 170.96C76.0638 171.303 77.041 171.303 77.6484 170.96C78.2559 170.616 78.2559 170.062 77.6484 169.692C77.041 169.348 76.0638 169.348 75.4563 169.692Z" fill="#00283B" />
        <path d="M69.7249 166.389C69.1174 166.732 69.1174 167.287 69.7249 167.657C70.3323 168.001 71.3095 168.001 71.917 167.657C72.5244 167.314 72.5244 166.759 71.917 166.389C71.3095 166.045 70.3323 166.045 69.7249 166.389Z" fill="#00283B" />
        <path d="M10.116 59.3833L59.6895 9.18285L109.289 2.63037C111.561 2.02268 113.542 2.28689 115.047 3.26448L128.147 10.821L118.111 32.0637V65.4866C118.111 65.4866 112.723 109.055 85.4931 151.118C64.0209 184.33 59.7952 187.051 59.7952 187.051C59.7952 187.051 57.2333 188.346 45.9558 184.911L43.0242 186.258L31.6146 179.653C29.581 178.702 27.7058 177.592 25.9362 176.377L25.7249 176.245C5.49409 162.03 1.34756 132.333 1.34756 132.333V77.0856C1.32115 71.2201 4.91305 63.9806 10.116 59.3833Z" fill="#B0E2FA" />
        <path d="M128.358 15.7087L115.258 8.15218C113.753 7.17459 111.798 6.91038 109.501 7.51807L59.9007 14.0705L10.3272 64.271C5.12427 68.8683 1.50596 76.1078 1.53237 81.9733V91.2472L54.8034 122.292C57.0748 121.685 59.0556 121.949 60.561 122.926L63.0965 124.38L43.1562 104.326L73.6609 130.483L63.0965 124.38L89.1114 150.537C113.383 110.244 118.348 70.3479 118.348 70.3479V36.925L128.358 15.6822V15.7087Z" fill="#F5FCFF" />
        <path d="M57.8402 188.556C55.7273 188.556 52.109 188.081 46.0344 186.284L42.9443 187.685L30.9537 180.736C29.0521 179.837 27.1505 178.728 25.1961 177.407L24.5358 176.958C4.43705 162.531 0.211288 133.732 0.0528221 132.517L0 77.085C0 70.7967 3.69754 63.3195 9.21745 58.4315L59.0815 7.94042L109.13 1.30867C111.56 0.648142 113.911 0.938776 115.786 2.12774L129.889 10.2655L119.457 32.3273V65.4596C119.404 66.0673 113.699 109.953 86.6282 151.804C65.605 184.303 60.9831 187.843 60.5341 188.134C60.27 188.266 59.5305 188.556 57.8666 188.556H57.8402ZM45.8496 183.457L46.325 183.589C55.3575 186.337 58.5268 185.941 59.1607 185.809C60.0323 185.042 65.4201 179.679 84.3833 150.351C111.111 109.028 116.763 65.6974 116.816 65.2747V31.7196L126.43 11.3223L114.386 4.37355C113.118 3.55449 111.481 3.39596 109.632 3.87154L60.3228 10.3976L10.987 60.3603C6.07454 64.6934 2.61469 71.5629 2.6411 77.0586V132.306C2.6411 132.306 6.97251 161.422 26.4903 175.082L26.7016 175.214C28.5239 176.482 30.3199 177.512 32.195 178.384L43.1292 184.699L45.9024 183.431L45.8496 183.457Z" fill="#00283B" />
        <path d="M59.6877 9.18236L109.288 2.62988C111.559 2.02219 113.54 2.2864 115.045 3.26399L128.145 10.8205L122.916 21.891L73.2366 17.0031L59.6877 9.20879V9.18236Z" fill="#B0E2FA" />
        <path d="M59.9255 14.071L109.525 7.51855C111.797 6.91086 113.778 7.17508 115.283 8.15266L128.383 15.7092L123.154 26.7797L73.4744 21.8917L59.9255 14.071Z" fill="#F5FCFF" />
        <path d="M123.709 23.2917L72.5769 18.1395L57.5491 9.47335L59.0545 7.94091L109.13 1.30916C111.56 0.64863 113.91 0.939265 115.785 2.12822L129.889 10.266L123.735 23.2653L123.709 23.2917ZM73.6334 15.7088L122.098 20.491L126.403 11.3757L114.359 4.42688C113.091 3.60782 111.454 3.44929 109.605 3.92487L63.7028 10.0018L73.6334 15.7352V15.7088Z" fill="#00283B" />
        <path d="M23.6655 67.1772L73.239 16.9767L122.839 10.4243C128.068 9.05036 131.66 12.1681 131.66 18.0336V73.2806C131.66 73.2806 126.272 116.849 99.069 158.912C77.5969 192.124 73.3711 194.845 73.3711 194.845C73.3711 194.845 69.1189 196.985 47.5147 188.451C20.1265 177.645 14.9235 140.153 14.9235 140.153V84.9059C14.8971 79.0404 18.489 71.801 23.6919 67.2037L23.6655 67.1772Z" fill="#00283B" />
        <path d="M71.3897 196.377C68.0883 196.377 61.1422 195.241 47.0123 189.666C19.2279 178.701 13.8137 141.87 13.6024 140.311L13.5496 84.8794C13.5496 78.5912 17.2471 71.1139 22.767 66.226L72.6046 15.7349L122.654 9.10313C125.321 8.38976 127.83 8.78608 129.758 10.2657C131.818 11.8509 132.954 14.6252 132.954 18.0335V73.2805C132.927 73.8882 127.196 117.774 100.151 159.625C79.1281 192.124 74.5062 195.664 74.0572 195.955C73.7403 196.113 73.0008 196.377 71.3633 196.377H71.3897ZM73.8724 18.2449L24.5365 68.1812C19.6241 72.5143 16.1643 79.3838 16.1907 84.8794V140.126C16.2699 140.311 21.6313 176.799 47.9631 187.209C66.5037 194.528 71.8387 193.867 72.7103 193.656C73.5554 192.916 78.9433 187.579 97.9328 158.199C124.661 116.876 130.313 73.5447 130.366 73.1219L130.313 18.0335C130.313 15.4707 129.547 13.4362 128.121 12.353C126.879 11.4018 125.163 11.164 123.129 11.6924L73.8195 18.2185L73.8724 18.2449Z" fill="#00283B" />
        <path d="M56.0704 153.892C52.0295 153.892 48.3055 152.941 45.0042 151.039C37.028 146.441 32.6438 136.851 32.6438 124.036C32.6438 98.1698 50.8674 66.5963 73.264 53.6763C84.3566 47.2559 94.8682 46.2783 102.844 50.8756C110.82 55.4729 115.205 65.0639 115.205 77.9046C115.205 103.771 96.9811 135.345 74.5845 148.265C68.0874 152.016 61.7752 153.919 56.0704 153.919V153.892ZM91.7517 50.6643C86.5223 50.6643 80.6591 52.4345 74.5845 55.9485C52.9275 68.4722 35.2849 98.9888 35.2849 124.036C35.2849 135.873 39.1937 144.645 46.3247 148.767C53.4557 152.862 63.0165 151.884 73.264 145.966C94.921 133.442 112.564 102.926 112.564 77.8782C112.564 66.0415 108.655 57.2696 101.524 53.1479C98.645 51.4833 95.3436 50.6643 91.7781 50.6643H91.7517Z" fill="#B0E2FA" />
        <path d="M59.7416 143.297C56.493 143.297 53.4821 142.531 50.8146 140.999C44.3967 137.3 40.8577 129.585 40.8577 119.307C40.8577 98.6717 55.3837 73.4658 73.264 63.1614C82.1645 58.0093 90.6161 57.2166 97.0339 60.9421C103.452 64.641 106.991 72.3561 106.991 82.6604C106.991 103.295 92.4648 128.501 74.5846 138.806C69.3816 141.818 64.3371 143.324 59.7416 143.324V143.297ZM88.107 61.2591C83.9869 61.2591 79.3914 62.6594 74.5846 65.4337C57.4438 75.3417 43.4988 99.4907 43.4988 119.307C43.4988 128.607 46.5624 135.503 52.1352 138.726C57.7079 141.95 65.2086 141.157 73.264 136.507C90.4048 126.599 104.35 102.45 104.35 82.6339C104.35 73.3336 101.286 66.4377 95.7134 63.2143C93.4684 61.9196 90.8802 61.2591 88.107 61.2591Z" fill="#B0E2FA" />
        <path d="M54.4862 120.443C53.9315 120.443 53.4033 120.311 52.9279 120.047C51.7394 119.36 51.0527 117.933 51.0527 116.083V110.693C51.0527 107.655 52.8751 104.247 55.1993 102.899L91.3031 82.0528C92.5445 81.3394 93.865 81.2601 94.895 81.8678C96.0835 82.5548 96.7438 83.9815 96.7438 85.831V91.221C96.7438 94.2594 94.9215 97.6678 92.5973 99.0153L56.4934 119.862C55.8067 120.258 55.12 120.443 54.4598 120.443H54.4862ZM93.3632 84.0872C93.0991 84.0872 92.7822 84.2457 92.6501 84.325L56.5462 105.171C55.0408 106.043 53.7202 108.606 53.7202 110.667V116.057C53.7202 117.061 54.0108 117.589 54.2749 117.722C54.539 117.88 55.0408 117.642 55.2257 117.537L91.3296 96.6902C92.835 95.8183 94.1555 93.2554 94.1555 91.1946V85.8046C94.1555 84.8006 93.865 84.2722 93.6009 84.1401C93.5217 84.0872 93.4424 84.0872 93.3632 84.0872Z" fill="#B0E2FA" />
    </SvgIcon>
)



export default Shield