import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_ROUTE_COST_RESET = 'PUT_ROUTE_COST_RESET';
export const PUT_ROUTE_COST_LOADING = 'PUT_ROUTE_COST_LOADING';
export const PUT_ROUTE_COST_SUCCESS = 'PUT_ROUTE_COST_SUCCESS';
export const PUT_ROUTE_COST_REJECT = 'PUT_ROUTE_COST_REJECT';

//* ACTIONS ------------------------------------------------
export const putRouteCostReset = () => ({ type: PUT_ROUTE_COST_RESET });
export const putRouteCostLoading = () => ({ type: PUT_ROUTE_COST_LOADING });
export const putRouteCostSuccess = (payload) => ({ type: PUT_ROUTE_COST_SUCCESS, payload });
export const putRouteCostReject = (payload) => ({ type: PUT_ROUTE_COST_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putRouteCostRequest = async ({ idRoute, costs }, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: costs,
    }, getState);
    return request(`/api/TypeCostsRoute/UpdateTypeCostsListByRoute/${idRoute}`, options);
};