/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, pickBy, identity, isEmpty, find } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Link as LinkMui,
  CircularProgress,
  Box,
  Fade,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from "react-router-dom";
import moment from "moment";

import Layout from "../../../components/layout/Layout"
import Table from "../../../components/form/Table";
import Toolbar from "./Toolbar";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import { PageSize, countPage } from "../../../config/const"

import { getRoute } from "../../../store/transactions/thunk/route/getAll";

const Route = () => {
  const [__] = useTranslation("route");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

  const route = useSelector(state => state.transactions.route);
  const put = useSelector(state => state.transactions.route.put);
  const delet = useSelector(state => state.transactions.route.delete);

  const routeStatus = __(`routeStatus`, { returnObjects: true });

  const getData = ({ page, filter }) => {
    dispatch(getRoute({ page, PageSize, Filters: filter }))
  }

  const reload = () => getData({ page: 1, filter })

  useEffect(() => {
    setAnchorEl(false)
    getData({ page: 1, filter })
  }, [dispatch, JSON.stringify(filter)])


  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  //  --------- Delete -------------

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
    })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delet" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delet") }
    if (get(put, "isSuccess", false)) { reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
  }, [put, delet])

  const headTable = [
    {
      key: "code",
      label: __(`table.cols.idRoute`),
      align: "left"
    },
    {
      key: "routeName",
      label: __(`table.cols.routeName`),
      align: "left"
    },
    {
      key: "dateRoute",
      label: __(`table.cols.dateToRoute`),
      align: "center"
    },
    {
      key: "create",
      label: __(`table.cols.creationDate`),
      align: "center",
    },
    {
      key: "routeStatus",
      label: __(`table.cols.statusOperative`),
      align: "center",
    },
    {
      key: "drivers.user.name",
      label: __(`table.cols.driverName`),
      align: "left"
    },
    {
      key: "startDP.deliveryName",
      label: __(`table.cols.startPoint`),
      align: "left"
    },
    {
      key: "returnDP.deliveryName",
      label: __(`table.cols.endPoint`),
      align: "left"
    },
    {
      key: "options",
      label: __(`table.cols.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]
  const dataTable = map(get(route, "data", []), (row, i) => ({
    ...row,
    dateRoute: get(row, "dateToRoute", null) && moment(get(row, "dateToRoute")).format("L"),
    create: get(row, "creationDate", null) && moment(get(row, "creationDate")).format("L"),
    routeStatus: <Chip label={get(find(routeStatus, ({ value }) => value === get(row, "idRouteStatus", 1)), "label")} sx={{ bgcolor: `${get(find(routeStatus, ({ value }) => value === get(row, "idRouteStatus", 1)), "color")}26`, color: get(find(routeStatus, ({ value }) => value === get(row, "idRouteStatus", 1)), "color") }} />,
    code: <LinkMui component={Link} to={toString(get(row, "idRoute"))} >{get(row, "idRoute")}</LinkMui>,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <Layout
      propsToolbar={{
        title: __(`head.name`),
        code: null,
        btnLabel: null
      }}
    >
      <Toolbar setFilter={setFilter} __={__} getData={getData} reload={reload} isLoading={get(route, "isLoading", false)} />
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(route, "isLoading", false)}
      />
      <Stack sx={{ mt: 2 }} alignItems="flex-end">
        <Pagination
          count={countPage(get(route, "totalRecords", 1))}
          page={get(route, "pageNumber", 1)}
          onChange={onChangePagination}
          color="secondary"
          sx={{ "& li > button": { borderRadius: 1 } }}
        />
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <Link to={toString(get(selected, "idRoute"))}><MenuItem >{__(`table.menu.edit`)}</MenuItem></Link>
        <Link to={`detail/${toString(get(selected, "idRoute"))}`}><MenuItem >{__(`table.menu.detail`)}</MenuItem></Link>
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
    </Layout>
  )
}

export default Route;


