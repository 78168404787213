import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            nameCost: yup.string().required(__(`form.nameCost.error`)),
        })
    )
}

export default validators
