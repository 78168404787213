import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_ORDER_ALL_LOADING = 'GET_ROUTE_ORDER_ALL_LOADING';
export const GET_ROUTE_ORDER_ALL_SUCCESS = 'GET_ROUTE_ORDER_ALL_SUCCESS';
export const GET_ROUTE_ORDER_ALL_REJECT = 'GET_ROUTE_ORDER_ALL_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteOrderAllLoading = () => ({ type: GET_ROUTE_ORDER_ALL_LOADING });
export const getRouteOrderAllSuccess = (payload) => ({ type: GET_ROUTE_ORDER_ALL_SUCCESS, payload });
export const getRouteOrderAllReject = (payload) => ({ type: GET_ROUTE_ORDER_ALL_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteOrderAllRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/RoutesDetails/GetRoutesDetailByRoute/${params.id}`, options);
};
