import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_ORDER_ITEM_RESET = 'PUT_ORDER_ITEM_RESET';
export const PUT_ORDER_ITEM_LOADING = 'PUT_ORDER_ITEM_LOADING';
export const PUT_ORDER_ITEM_SUCCESS = 'PUT_ORDER_ITEM_SUCCESS';
export const PUT_ORDER_ITEM_REJECT = 'PUT_ORDER_ITEM_REJECT';

//* ACTIONS ------------------------------------------------
export const putOrderItemReset = () => ({ type: PUT_ORDER_ITEM_RESET });
export const putOrderItemLoading = () => ({ type: PUT_ORDER_ITEM_LOADING });
export const putOrderItemSuccess = (payload) => ({ type: PUT_ORDER_ITEM_SUCCESS, payload });
export const putOrderItemReject = (payload) => ({ type: PUT_ORDER_ITEM_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putOrderItemRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/Items/UpdateOrderItem`, options);
};

