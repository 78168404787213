import Transport from "../../../../pages/Masters/Transport";
import TransportNewEdit from "../../../../pages/Masters/Transport/NewEdit";

const routerTransport = [
    {
        key: "transport",
        path: "/masters/transport",
        element: <Transport />,
    },
    {
        key: "transport",
        path: "/masters/transport/new",
        element: <TransportNewEdit />,
    },
    {
        key: "transport",
        path: "/masters/transport/:id",
        element: <TransportNewEdit />,
    },
]

export default routerTransport
