import * as yup from 'yup';
import { get } from "lodash";

const validators = (__) => () => {
    return (
        yup.object({
            name: yup.string().required(__(`form.name.error`)),
            state: yup.string().required(__(`form.state.error`)),
            city: yup.string().required(__(`form.city.error`)),
            address: yup.string().required(__(`form.address.error`)),
        })
    )
}

export default validators
