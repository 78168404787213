import {
    postUploadInvoiceDetailsLoading,
    postUploadInvoiceDetailsRequest,
    postUploadInvoiceDetailsSuccess,
    postUploadInvoiceDetailsReject,
    postUploadInvoiceDetailsReset
} from "../../actions/invoice/postUploadInvoiceDetails";

export const postUploadInvoiceDetails = (formData) => async (dispatch, getState) => {
    dispatch(postUploadInvoiceDetailsLoading());
    try {
        const { data } = await postUploadInvoiceDetailsRequest(formData, getState);
        dispatch(postUploadInvoiceDetailsSuccess(data))
    } catch (error) {
        dispatch(postUploadInvoiceDetailsReject(error))
    } finally {
        setTimeout(() => { dispatch(postUploadInvoiceDetailsReset()) }, 3000);
    }
    return Promise.resolve();
};