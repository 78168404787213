import { get, isEmpty } from "lodash";

import {
    getProfileLoading,
    getProfileReject,
    getProfileRequest,
    getProfileSuccess,
    getCompanyRequest
} from "../../actions/getProfile";

import {
} from "../../actions/getProfile";

export const getProfile = (formData) => async (dispatch, getState) => {
    dispatch(getProfileLoading());
    try {
        const { data } = await getProfileRequest(formData, getState);
        const userData = data;
        
        const res = await getCompanyRequest(formData, getState)
        const companyData = res.data.data
        userData.data.companyData = companyData

        if (!isEmpty(get(userData, "data", {}))) {
            dispatch(getProfileSuccess(userData))
        } else {
            dispatch(getProfileReject(userData))
        }

    } catch (error) {
        dispatch(getProfileReject(get(error, "response.data")))
    }
    return Promise.resolve();
};