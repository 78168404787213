import {
    getOrderNoInRouteLoading,
    getOrderNoInRouteReject,
    getOrderNoInRouteRequest,
    getOrderNoInRouteSuccess
} from "../../actions/order/getNoInRoute";

export const getOrderNoInRoute = (formData) => async (dispatch, getState) => {
    dispatch(getOrderNoInRouteLoading());
    try {
        const { data } = await getOrderNoInRouteRequest(formData, getState);
        dispatch(getOrderNoInRouteSuccess(data))
    } catch (error) {
        dispatch(getOrderNoInRouteReject(error))
    }
    return Promise.resolve();
};