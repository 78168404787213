import {
    postContactLoading,
    postContactRequest,
    postContactSuccess,
    postContactReject,
    postContactReset
} from "../../actions/contact/post";

export const postContact = (formData) => async (dispatch, getState) => {
    dispatch(postContactLoading());
    try {
        const { data } = await postContactRequest(formData, getState);
        dispatch(postContactSuccess(data))
    } catch (error) {
        dispatch(postContactReject(error))
    } finally {
        setTimeout(() => { dispatch(postContactReset()) }, 3000);
    }
    return Promise.resolve();
};