/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, isEmpty } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Link as LinkMui,
  Button
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import ContactModal from "./ContactModal"

import { getContact } from "../../../store/masters/thunk/contact/getAll";
import { deleteContact } from "../../../store/masters/thunk/contact/delete";

const Contact = ({ setBtns }) => {
  const [__] = useTranslation("contact");
  const dispatch = useDispatch();
  const { id } = useParams();
  const PageSize = 5

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState({})
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });
  const [openModal, setOpenModal] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);

  const contact = useSelector(state => state.masters.contact);
  const delet = useSelector(state => state.masters.contact.delete);
  const put = useSelector(state => state.masters.contact.put);
  const post = useSelector(state => state.masters.contact.post);

  const getData = ({ page, filter }) => {
    const parmas = { page, PageSize, idClient: id }
    dispatch(getContact(parmas))
  }

  const reload = () => getData({ page: 1 })

  useEffect(() => {
    setAnchorEl(false)
    getData({ page: 1 })
  }, [dispatch])

  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.btn`)}</Button>
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [])

  const handleOpenMenu = (row) => (event) => {
    setSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setSelected({})
    setAnchorEl(null);
  };

  const onDelete = () => {
    dispatch(deleteContact({ idContact: get(selected, "idContact") }))
    setSelected({})
  }
  const handleDelete = (row) => () => {
    setAnchorEl(null)
    setOpenQuestion(true)
    setSelected(row)
  }

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (contact) => <li>{`• ${contact}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(delet, "isSuccess", false)) { setOpenQuestion(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delet" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delet") }
    if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" })}
    if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
    if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" })}
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
  }, [post, put, delet])

  const headTable = [
    {
      key: "thisName",
      label: __(`table.cols.name`),
      align: "left"
    },
    {
      key: "phon",
      label: __(`table.cols.phone`),
      align: "left"
    },
    {
      key: "email",
      label: __(`table.cols.email`),
      align: "left"
    },
    {
      key: "status",
      label: __(`table.cols.isActive`),
      align: "center"
    },
    {
      key: "options",
      label: __(`table.cols.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(contact, "data", []), (row, i) => ({
    ...row,
    thisName: <LinkMui component={Link} to={toString(get(row, "idContact"))} >{get(row, "name")}</LinkMui>,
    phon: get(row, "phone", "") && <Stack direction="row" alignItems="center" spacing={1}><PhoneIcon fontSize="small" /><div>{get(row, "phone")}</div></Stack>,
    status: <Chip label={__(`isActive.${get(row, "isActive", false)}`)} color={get(row, "isActive", false) ? "success" : "error"} />,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))
  const onChangePagination = (e, page) => getData({ page })

  return (
    <div className='mt-4'>
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(contact, "isLoading", false)}
      />
      {get(contact, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignContacts="flex-end">
          <Pagination
            count={(Math.trunc((get(contact, "totalRecords", 1) + PageSize) / PageSize))}
            page={get(contact, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={() => { setOpenModal(true); setAnchorEl(null) }}>{__(`table.menu.edit`)}</MenuItem>
        <MenuItem onClick={handleDelete(selected)}>{__(`table.menu.delete`)}</MenuItem>
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <ContactModal
        __={__}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        selected={selected}
        setSelected={setSelected}
        idClient={id}
      />
      <Question
        title={__("alert.deletecontact.title")}
        detail={__("alert.deletecontact.detail")}
        onSuccess={onDelete}
        onCancel={() => setOpenQuestion(false)}
        open={openQuestion}
        loading={get(delet, "isLoading")}
      />
    </div>
  )
}

export default Contact;
