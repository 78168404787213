import Orders from "../../../../pages/Report/Orders";

const routerOrders = [
    {
        key: "orders",
        path: "/report/orders",
        element: <Orders />,
    },
]

export default routerOrders
