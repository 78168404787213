import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_TRANSPORT_BRAND_RESET = 'PUT_TRANSPORT_BRAND_RESET';
export const PUT_TRANSPORT_BRAND_LOADING = 'PUT_TRANSPORT_BRAND_LOADING';
export const PUT_TRANSPORT_BRAND_SUCCESS = 'PUT_TRANSPORT_BRAND_SUCCESS';
export const PUT_TRANSPORT_BRAND_REJECT = 'PUT_TRANSPORT_BRAND_REJECT';

//* ACTIONS ------------------------------------------------
export const putTransportBrandReset = () => ({ type: PUT_TRANSPORT_BRAND_RESET });
export const putTransportBrandLoading = () => ({ type: PUT_TRANSPORT_BRAND_LOADING });
export const putTransportBrandSuccess = (payload) => ({ type: PUT_TRANSPORT_BRAND_SUCCESS, payload });
export const putTransportBrandReject = (payload) => ({ type: PUT_TRANSPORT_BRAND_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putTransportBrandRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.PUT,
        data,
    }, getState);
    return request(`/api/TransportBrand`, options);
};


