import React, { useEffect } from 'react';
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    Stack,
    Divider,
    Grid,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import CloseIcon from '@mui/icons-material/Close';

import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import FormatMoney from "../../../components/form/FormatMoney"
import validatorItem from "./validatorItem"

import { postClientItem } from "../../../store/masters/thunk/client/postItem"
import { putClientItem } from "../../../store/masters/thunk/client/putItem"

const ItemModal = ({
    __,
    open,
    close,
    selected,
    setSelected,
    idClient,
    reload,
    setError
}) => {
    const idItem = get(selected, "idClientItem")
    const dispatch = useDispatch();

    const current = selected
    const putItem = useSelector(state => state.masters.client.putItem);
    const postItem = useSelector(state => state.masters.client.postItem);

    const onCloseModal = (r) => {
        setSelected({})
        close()
        if (r === "1") {
            reload()
        }
    }

    useEffect(() => {
        if (get(postItem, "isSuccess", false)) { onCloseModal("1") }
        if (get(postItem, "isReject", false)) { setError(get(postItem, "error", {}), "post") }
        if (get(putItem, "isSuccess", false)) { onCloseModal("1") }
        if (get(putItem, "isReject", false)) { setError(get(putItem, "error", {}), "put") }
    }, [postItem, putItem])

    const onSubmit = (values) => {
        const idClientItem = idItem;
        const whitOutNulls = pickBy(values, identity);

        if (idClientItem) { dispatch(putClientItem(whitOutNulls)) }
        else { dispatch(postClientItem(whitOutNulls)) }
    }

    const initialValues = {
        isActive: idItem ? get(current, "isActive", "") : true,
        erpCode: idItem ? get(current, "erpCode", "") : "",
        name: idItem ? get(current, "name", "") : "",

        weight: idItem ? get(current, "weight", "") : "",
        volume: idItem ? get(current, "volume", "") : "",
        price: idItem ? get(current, "price", "") : "",

        idClientItem: idItem ? get(current, "idClientItem", 0) : 0,
        idClient: idClient,
    }

    return (
        <div>
            <Dialog onClose={onCloseModal} open={open} maxWidth="lg" fullWidth>
                <Box>
                    <Typography variant="h6" sx={{ mt: 2, mb: 1, ml: 3 }}>
                        {idItem ? __("head.editItem.name") : __("head.newItem.name")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div>
                    {!open
                        ? (
                            <Box sx={{ minHeight: 200, width: "100%" }} />
                        ) : (
                            <Formik initialValues={initialValues}
                                validationSchema={validatorItem(__)}
                                onSubmit={onSubmit}>
                                {formik => (
                                    <form id="client-item-form" onSubmit={get(formik, "handleSubmit")}>
                                        <div className='relative'>
                                            <Divider />
                                            <Box sx={{ position: "absolute", top: 10, right: 20, zIndex: 10 }} >
                                                <Switch formik={formik} name="isActive" __={__} />
                                            </Box>
                                            <Box className='p-4' >
                                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={6} md={4} >
                                                        <InputText
                                                            formik={formik}
                                                            name="erpCode"
                                                            label={__(`formItems.erpCode.label`)}
                                                            placeholder={__(`formItems.erpCode.placeholder`)}
                                                            disabled={get(postItem, "isLoading") || get(putItem, "isLoading")}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={8} >
                                                        <InputText
                                                            formik={formik}
                                                            name="name"
                                                            label={__(`formItems.name.label`)}
                                                            placeholder={__(`formItems.name.placeholder`)}
                                                            disabled={get(postItem, "isLoading") || get(putItem, "isLoading")}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="price"
                                                            label={__(`formItems.price.label`)}
                                                            placeholder={__(`formItems.price.placeholder`)}
                                                            disabled={get(postItem, "isLoading") || get(putItem, "isLoading")}
                                                            InputProps={{
                                                                inputComponent: FormatMoney,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="weight"
                                                            label={__(`formItems.weight.label`)}
                                                            placeholder={__(`formItems.weight.placeholder`)}
                                                            disabled={get(postItem, "isLoading") || get(putItem, "isLoading")}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <InputText
                                                            formik={formik}
                                                            name="volume"
                                                            label={__(`formItems.volume.label`)}
                                                            placeholder={__(`formItems.volume.placeholder`)}
                                                            disabled={get(postItem, "isLoading") || get(putItem, "isLoading")}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        )}
                </div>
                <Stack className='p-4' direction="row" spacing={2} justifyContent="flex-end">
                    <LoadingButton variant="contained" color="secondary" type="submit" form="client-item-form" loading={get(postItem, "isLoading") || get(putItem, "isLoading")}>{__("action.save")}</LoadingButton>
                    <Button variant='outlined' color="secondary" onClick={onCloseModal} disabled={get(postItem, "isLoading") || get(putItem, "isLoading")} >{__(`action.cancel`)}</Button>
                </Stack>
            </Dialog>
        </div >
    );
}

export default ItemModal