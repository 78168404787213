import {
    postClientItemLoading,
    postClientItemRequest,
    postClientItemSuccess,
    postClientItemReject,
    postClientItemReset
} from "../../actions/client/postItem";

export const postClientItem = (formData) => async (dispatch, getState) => {
    dispatch(postClientItemLoading());
    try {
        const { data } = await postClientItemRequest(formData, getState);
        dispatch(postClientItemSuccess(data))
    } catch (error) {
        dispatch(postClientItemReject(error))
    } finally {
        setTimeout(() => { dispatch(postClientItemReset()) }, 3000);
    }
    return Promise.resolve();
};