import {
    getInvoiceLoading,
    getInvoiceReject,
    getInvoiceRequest,
    getInvoiceSuccess
} from "../../actions/invoice/getAll";

export const getInvoice = (formData) => async (dispatch, getState) => {
    dispatch(getInvoiceLoading());
    try {
        const { data } = await getInvoiceRequest(formData, getState);
        dispatch(getInvoiceSuccess(data))
    } catch (error) {
        dispatch(getInvoiceReject(error))
    }
    return Promise.resolve();
};