import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_INVOICE_ID_LOADING = 'GET_INVOICE_ID_LOADING';
export const GET_INVOICE_ID_SUCCESS = 'GET_INVOICE_ID_SUCCESS';
export const GET_INVOICE_ID_REJECT = 'GET_INVOICE_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getInvoiceIdLoading = () => ({ type: GET_INVOICE_ID_LOADING });
export const getInvoiceIdSuccess = (payload) => ({ type: GET_INVOICE_ID_SUCCESS, payload });
export const getInvoiceIdReject = (payload) => ({ type: GET_INVOICE_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getInvoiceIdRequest = async ({ params = {}, id }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Invoices/${id}`, options);
};