import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_REPORT_INVOICES_LOADING = 'GET_REPORT_INVOICES_LOADING';
export const GET_REPORT_INVOICES_SUCCESS = 'GET_REPORT_INVOICES_SUCCESS';
export const GET_REPORT_INVOICES_REJECT = 'GET_REPORT_INVOICES_REJECT';

//* ACTIONS ------------------------------------------------
export const getReportInvoicesLoading = () => ({ type: GET_REPORT_INVOICES_LOADING });
export const getReportInvoicesSuccess = (payload) => ({ type: GET_REPORT_INVOICES_SUCCESS, payload });
export const getReportInvoicesReject = (payload) => ({ type: GET_REPORT_INVOICES_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getReportInvoicesRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Reports/GetAllInvoicesBaseReport`, options);
};

