import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_PASS_DRIVER_RESET = 'PUT_PASS_DRIVER_RESET';
export const PUT_PASS_DRIVER_LOADING = 'PUT_PASS_DRIVER_LOADING';
export const PUT_PASS_DRIVER_SUCCESS = 'PUT_PASS_DRIVER_SUCCESS';
export const PUT_PASS_DRIVER_REJECT = 'PUT_PASS_DRIVER_REJECT';

//* ACTIONS ------------------------------------------------
export const putPassDriverReset = () => ({ type: PUT_PASS_DRIVER_RESET });
export const putPassDriverLoading = () => ({ type: PUT_PASS_DRIVER_LOADING });
export const putPassDriverSuccess = (payload) => ({ type: PUT_PASS_DRIVER_SUCCESS, payload });
export const putPassDriverReject = (payload) => ({ type: PUT_PASS_DRIVER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putPassDriverRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Users/ChangeDriverConfirmedPass`, options);
};
