/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { get, isNumber, replace } from "lodash"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";

import Layout from "../../../components/layout/Layout";
import Mail from "../../../assets/icons/Mail"
import Notification from "../../../components/form/Notification";

import { postValidateEmailRequest } from "../../../store/auth/actions/postValidateResend"
import { getValidateEmail } from "../../../store/auth/thunk/validate-email"

const Profile = () => {
  const [__] = useTranslation("validate_email");
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [count, setCount] = useState(0);
  const [thisTimer, setThisTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector(state => state.auth.login);
  const getState = useSelector(state => state);

  const [showNoti, setShowNoti] = useState({ open: false, variant: "", msg: "" })

  const validateToken = (token) => {
    dispatch(getValidateEmail({ token }))
  }

  useEffect(() => {
    const search = window.location.search
    const p = new URLSearchParams(search);
    const token = p.get("token")

    if (!!token) {
      validateToken(token)
    }

    if (get(auth, "dataUser.isActive")) {
      setIsActive(true)
    }

    return () => {
      clearTimeout(thisTimer)
    }
  }, [])

  const timer = (time) => {
    setThisTimer(
      setTimeout(() => {
        if (time > 0) {
          const update = time - 1
          setCount(update)
          timer(update)
        }
      }, 1000)
    )

  }
  const startTimer = () => {
    clearTimeout(thisTimer)
    setThisTimer(null)
    const time = __('seconds');
    if (!isNumber(time)) return;
    setCount(time)
    timer(time)
  }

  const handleClick = () => {
    startTimer()
    setIsLoading(true)
    postValidateEmailRequest({ toEmail: null, idUser: get(auth, "dataUser.idUser") }, () => getState)
      .then(({ data }) => {
        setShowNoti({ open: true, msg: __("send"), variant: "success" })
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }

  return (
    <Layout disableLayout={!get(auth, "isLogged", false)} propsToolbar={{ title: __("name") }}>
      <Box className='flex flex-1 flex-col justify-center items-center'>
        {isActive
          ? (
            <>
              <Typography variant="heading2" gutterBottom>{__('isActive.active')}</Typography>
              <Typography variant="bodyMedium">{__('isActive.description')}</Typography>
              <Box className='my-14'>
                <Button component={Link} to={__('isActive.to')} variant="contained" color="primary" >{__('isActive.btn')}</Button>
              </Box>
            </>
          ) : (
            <>
              <Mail className="mb-0" sx={{ mb: 4 }} />
              {/* <ValidateEmail className="mb-12" style={{ height: 147, width: 256 }} /> */}
              <Typography className='text-center' variant="heading2" gutterBottom>{__('title')}</Typography>
              <Typography className='text-center' variant="blm">{__('subtitle')}</Typography>
              <Box className='my-10 text-center' minHeight={80}>
                <LoadingButton onClick={handleClick} variant="contained" color="primary" disabled={!!count} loading={isLoading} >{__('button.name')}</LoadingButton>
                {count > 0 && <Typography className='pt-4' component="pre" variant="caption">{replace(__('await'), "[[count]]", count)}</Typography>}
              </Box>
            </>
          )}
        <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} timer={10000} />
      </Box >
    </Layout >
  )
}

export default Profile
