/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import Layout from "../../../components/layout/Layout";
import Switch from "../../../components/form/Switch";
import InputText from "../../../components/form/InputText";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import FormatMoney from "../../../components/form/FormatMoney"
import validator from "./validator"
import AutoComplete from "../../../components/form/AutoComplete";

import { getTransportId } from "../../../store/masters/thunk/transport/getId"
import { postTransport } from "../../../store/masters/thunk/transport/post"
import { putTransport } from "../../../store/masters/thunk/transport/put"

const NewEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("transport");
    const { id } = useParams();
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" })

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.masters.transport.detail);
    const put = useSelector(state => state.masters.transport.put);
    const post = useSelector(state => state.masters.transport.post);

    const transportFuelType = __(`transportFuelType`, { returnObjects: true });
    const transportDivision = __(`transportDivision`, { returnObjects: true });

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/masters/transport/${get(post, "id")}?from=new`); }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post") }
        if (get(put, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "put" }); }
        if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    }, [put, post])

    useEffect(() => {
        if (!!id) {
            dispatch(getTransportId({ id }))
        }
    }, [dispatch, id])

    const onSubmit = (values) => {
        const idTransport = id;
        const whitOutNulls = pickBy(values, identity);
        if (idTransport) { dispatch(putTransport({ idTransport, ...whitOutNulls })) }
        else { dispatch(postTransport(whitOutNulls)) }
    }

    const initialValues = {
        isActive: id ? get(current, "data.isActive", "") : true,

        numberPlate: id ? get(current, "data.numberPlate", "") : "",
        name: id ? get(current, "data.name", "") : "",
        vin: id ? get(current, "data.vin", "") : "",
        description: id ? get(current, "data.description", "") : "",
        dailyCost: id ? get(current, "data.dailyCost", "") : "",
        volumetry: id ? get(current, "data.volumetry", "") : "",
        kiloXGallon: id ? get(current, "data.kiloXGallon", "") : "",
        transportBrand: id ? get(current, "data.transportBrand", "") : "",
        idTransportDivision: id ? get(find(transportDivision, ({ value }) => value === get(current, "data.idTransportDivision", "")), "value") : "",
        idTransportFuelType: id ? get(find(transportFuelType, ({ value }) => value === get(current, "data.idTransportFuelType", "")), "value") : "",

        idUser: get(user, "idUser"),
    }

    return (
        <Layout
            propsToolbar={{
                title: !id ? __(`head.new.name`) : __(`head.edit.name`),
                srute: !id ? __(`head.new.code`) : id
            }}
        >
            {get(current, "isLoading")
                ? (
                    <Skeleton />
                ) : (
                    <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                        {formik => (
                            <form onSubmit={get(formik, "handleSubmit")}>
                                <Paper className='p-4'>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <LoadingButton className='w-[100px] min-w-[100px]' variant="contained" color="secondary" type="submit" loading={get(post, "isLoading") || get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                        <Button className='w-[100px] min-w-[100px]' variant='outlined' color="secondary" onClick={() => navigate("/masters/transport")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                                    </Stack>
                                </Paper>

                                <Paper className='mt-4'>
                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                        <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                        <Switch formik={formik} name="isActive" __={__} />
                                    </Stack>
                                    <Divider />
                                    <Box className='p-4' >
                                        <Grid container spacing={{ xs: 2, md: 3 }}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="numberPlate"
                                                    label={__(`form.numberPlate.label`)}
                                                    placeholder={__(`form.numberPlate.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="name"
                                                    label={__(`form.name.label`)}
                                                    placeholder={__(`form.name.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="vin"
                                                    label={__(`form.vin.label`)}
                                                    placeholder={__(`form.vin.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="transportBrand"
                                                    label={__(`form.transportBrand.label`)}
                                                    placeholder={__(`form.transportBrand.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idTransportDivision"}
                                                    formik={formik}
                                                    label={__(`form.idTransportDivision.label`)}
                                                    placeholder={__(`form.idTransportDivision.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={transportDivision}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <AutoComplete
                                                    name={"idTransportFuelType"}
                                                    formik={formik}
                                                    label={__(`form.idTransportFuelType.label`)}
                                                    placeholder={__(`form.idTransportFuelType.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    options={transportFuelType}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="kiloXGallon"
                                                    label={__(`form.kiloXGallon.label`)}
                                                    placeholder={__(`form.kiloXGallon.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="dailyCost"
                                                    label={__(`form.dailyCost.label`)}
                                                    placeholder={__(`form.dailyCost.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    InputProps={{
                                                        inputComponent: FormatMoney,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="volumetry"
                                                    label={__(`form.volumetry.label`)}
                                                    placeholder={__(`form.volumetry.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputText
                                                    formik={formik}
                                                    name="description"
                                                    label={__(`form.description.label`)}
                                                    placeholder={__(`form.description.placeholder`)}
                                                    disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                    multiline
                                                    rows={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </form>
                        )}
                    </Formik>
                )}
            <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
            <Alert
                title={get(alert, "title")}
                subtitle={get(alert, "subtitle")}
                btn1={{ func: get(alert, "func") }}
                btn2={{}}
                type={get(alert, "type")}
                openAlert={get(alert, "open")}
                closeAlert={closeAlert}
            />
        </Layout>
    )
}

export default NewEdit