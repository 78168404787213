import { concat, get, map } from "lodash";

import PublicRouter from "../PublicRouter";
import PrivateRoutes from "../PrivateRoutes";
import routesAuth from "./auth"
import routesLogout from "./logout"
import routesMasters from "./masters"
import routesTransactions from "./transactions"
import routesReports from "./report"
import routesConfig from "./config"
import routesPublic from "./public"
import routesError from "./error"

const routes = concat(
    map(routesLogout, (route) => ({ ...route, element: <PublicRouter>{get(route, "element")}</PublicRouter> })),
    map(routesAuth, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
    map(routesMasters, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
    map(routesTransactions, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
    map(routesReports, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
    map(routesConfig, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
    map(routesPublic, (route) => ({ ...route, element: <PublicRouter isPublic>{get(route, "element")}</PublicRouter> })),
    map(routesError, (route) => ({ ...route, element: <PrivateRoutes>{get(route, "element")}</PrivateRoutes> })),
)

export default routes