import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_CONTACT_RESET = 'DELETE_CONTACT_RESET';
export const DELETE_CONTACT_LOADING = 'DELETE_CONTACT_LOADING';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_REJECT = 'DELETE_CONTACT_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteContactReset = () => ({ type: DELETE_CONTACT_RESET });
export const deleteContactLoading = () => ({ type: DELETE_CONTACT_LOADING });
export const deleteContactSuccess = (payload) => ({ type: DELETE_CONTACT_SUCCESS, payload });
export const deleteContactReject = (payload) => ({ type: DELETE_CONTACT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteContactRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/Contact/${data.idContact}`, options);
};