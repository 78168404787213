import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ORDER_LOADING = 'GET_ORDER_LOADING';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_REJECT = 'GET_ORDER_REJECT';

//* ACTIONS ------------------------------------------------
export const getOrderLoading = () => ({ type: GET_ORDER_LOADING });
export const getOrderSuccess = (payload) => ({ type: GET_ORDER_SUCCESS, payload });
export const getOrderReject = (payload) => ({ type: GET_ORDER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOrderRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Order`, options);
};

