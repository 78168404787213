import { get } from "lodash";

import {
    getValidateEmailLoading,
    getValidateEmailReject,
    getValidateEmailRequest,
    getValidateEmailSuccess,
} from "../../actions/getValidate";

export const getValidateEmail = (formData) => async (dispatch, getState) => {
    dispatch(getValidateEmailLoading());
    try {
        await getValidateEmailRequest(formData, getState);
        // unicamente se actualiza el estado para identificar que ya se valido
        const dataUser = get(getState(), "auth.login.dataUser")
        dataUser.active = "True"
        const login = get(getState(), "auth.login")
        const dataLogin = { ...login, dataUser, allResp: { ...login.allResp, data: dataUser } }
        dispatch(getValidateEmailSuccess(dataLogin))
    } catch (error) {
        dispatch(getValidateEmailReject(get(error, "response.data")))
    }
    return Promise.resolve();
};