import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_CLIENT_ITEM_LOADING = 'GET_CLIENT_ITEM_LOADING';
export const GET_CLIENT_ITEM_SUCCESS = 'GET_CLIENT_ITEM_SUCCESS';
export const GET_CLIENT_ITEM_REJECT = 'GET_CLIENT_ITEM_REJECT';

//* ACTIONS ------------------------------------------------
export const getClientItemLoading = () => ({ type: GET_CLIENT_ITEM_LOADING });
export const getClientItemSuccess = (payload) => ({ type: GET_CLIENT_ITEM_SUCCESS, payload });
export const getClientItemReject = (payload) => ({ type: GET_CLIENT_ITEM_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getClientItemRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Items/GetClientItems`, options);
};