import {
    GET_REPORT_ROUTES_LOADING,
    GET_REPORT_ROUTES_SUCCESS,
    GET_REPORT_ROUTES_REJECT,
} from "../../actions/routes/getAll";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
}

const routesReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_REPORT_ROUTES_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_REPORT_ROUTES_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_REPORT_ROUTES_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        default: return state;
    }
}

export default routesReducer