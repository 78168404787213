import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_ID_LOADING = 'GET_ROUTE_ID_LOADING';
export const GET_ROUTE_ID_SUCCESS = 'GET_ROUTE_ID_SUCCESS';
export const GET_ROUTE_ID_REJECT = 'GET_ROUTE_ID_REJECT';

//* ACTIONS ------------------------------------------------
export const getRouteIdLoading = () => ({ type: GET_ROUTE_ID_LOADING });
export const getRouteIdSuccess = (payload) => ({ type: GET_ROUTE_ID_SUCCESS, payload });
export const getRouteIdReject = (payload) => ({ type: GET_ROUTE_ID_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getRouteIdRequest = async ({ params = {}, id }, getState) => {
    const companyId = getState()?.auth?.login?.dataUser?.idCompany
    const options = await withToken({
        method: Methods.GET,
        params: { ...params, routeId: id, companyId },
    }, getState);
    return request(`/api/Route/GetRouteDetailById/${id}`, options);
};
