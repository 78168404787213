import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_TRANSPORT_BRAND_LOADING = 'GET_TRANSPORT_BRAND_LOADING';
export const GET_TRANSPORT_BRAND_SUCCESS = 'GET_TRANSPORT_BRAND_SUCCESS';
export const GET_TRANSPORT_BRAND_REJECT = 'GET_TRANSPORT_BRAND_REJECT';

//* ACTIONS ------------------------------------------------
export const getTransportBrandLoading = () => ({ type: GET_TRANSPORT_BRAND_LOADING });
export const getTransportBrandSuccess = (payload) => ({ type: GET_TRANSPORT_BRAND_SUCCESS, payload });
export const getTransportBrandReject = (payload) => ({ type: GET_TRANSPORT_BRAND_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getTransportBrandRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TransportBrand`, options);
};

