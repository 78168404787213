import React, { useEffect, useState } from 'react';
import map from "lodash/map";
import get from "lodash/get";
import { Backdrop } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from "moment";
import { useSelector } from "react-redux";
import CircularProgress from "../../../components/form/CircularProgress"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const Graf = () => {
    const [gradient, setGradient] = useState(null);
    const [list, setList] = useState([])
    const [label, setLabel] = useState([])

    const coordsAll = useSelector(state => state.transactions.route.coordsAll);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        const grad = ctx.createLinearGradient(0, 0, 0, 400);
        grad.addColorStop(0, 'rgba(33,171,243,0.7)');
        grad.addColorStop(1, 'rgba(255,255,255,0)');
        setGradient(grad)
    }, [])

    useEffect(() => {
        if (!get(coordsAll, "isLoading")) {
            workData()
        }
    }, [get(coordsAll, "isLoading")])


    const workData = () => {
        setList(map(coordsAll?.data, ({ temperature }) => temperature))
        setLabel(map(coordsAll?.data, ({ dateCreated }) => moment(dateCreated).format("hh:mm a")))
    }

    const options = {
        animation: false,
        responsive: true,
        spanGaps: true,
        showLine: false,
        plugins: {
            title: {
                display: "none",
            },
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                border: { display: false },
                ticks: {
                    color: "black",
                    textStrokeWidth: 800
                }
            },
            y: {
                tickOptions: { showGridline: false },
                border: {
                    dash: [5, 5],
                    display: false
                },
                type: 'linear',
                min: -20,
                max: 50
            }
        }
    };


    const data = {
        labels: label,
        datasets: [
            {
                fill: true,
                data: list,
                backgroundColor: gradient,
                pointBackgroundColor: '#21ABF3',
                borderWidth: 0
            },
        ],
    }

    return (
        <div className='w-full h-full relative'>
            <Backdrop
                className='!absolute'
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={get(coordsAll, "isLoading")}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Line id="canvas" options={options} data={data} />
        </div>
    )
}

export default Graf
