import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_COST_LOADING = 'GET_COST_LOADING';
export const GET_COST_SUCCESS = 'GET_COST_SUCCESS';
export const GET_COST_REJECT = 'GET_COST_REJECT';

//* ACTIONS ------------------------------------------------
export const getCostLoading = () => ({ type: GET_COST_LOADING });
export const getCostSuccess = (payload) => ({ type: GET_COST_SUCCESS, payload });
export const getCostReject = (payload) => ({ type: GET_COST_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getCostRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/TypeCosts`, options);
};

