/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { get, map } from "lodash";

import {
  Pagination,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Layout from "../../../components/layout/Layout"
import Table from "../../../components/form/Table";
import Toolbar from "./Toolbar";
import Notification from "../../../components/form/Notification";
import { PageSize, countPage } from "../../../config/const"

import { getReportOrders } from "../../../store/reports/thunk/orders/getAll";

const ReportOrders = () => {
  const [__] = useTranslation("orders");
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({});
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })

  const orders = useSelector(state => state.reports.orders);

  const getData = ({ page }) => {
    dispatch(getReportOrders({ page, PageSize, ...filter }))
  }

  const reload = () => {
    getData({ page: 1, filter })
  }

  useEffect(() => {
    if (filter.from && filter.till) {
      getData({ page: 1, filter })
    }
  }, [dispatch, JSON.stringify(filter)])

  const headTable = [
    {
      key: "idOrder",
      label: __(`table.cols.idOrder`),
      align: "left"
    },
    {
      key: "docNum",
      label: __(`table.cols.docNum`),
      align: "left"
    },
    {
      key: "contactName",
      label: __(`table.cols.contactName`),
      align: "left",
      sx: { minWidth: 100 }
    },
    {
      key: "date",
      label: __(`table.cols.deliveryDate`),
      align: "center"
    },
    {
      key: "orderStatus",
      label: __(`table.cols.orderStatus`),
      align: "center"
    },
  ]

  const dataTable = map(get(orders, "data.innerData", []), (row, i) => ({
    ...row,
    date: get(row, "deliveryDate", null) && moment(get(row, "deliveryDate")).format("L"),
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <Layout
      propsToolbar={{
        title: __(`head.name`),
        code: null,
        btnLabel: null
      }}
    >
      <Toolbar setFilter={setFilter} __={__} getData={getData} reload={reload} isLoading={get(orders, "isLoading", false)} />
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(orders, "isLoading", false)}
      />
      {get(orders, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignItems="flex-end">
          <Pagination
            count={countPage(get(orders, "totalRecords", 1))}
            page={get(orders, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
    </Layout >
  )
}

export default ReportOrders;


