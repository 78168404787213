import {
    getReportRoutesLoading,
    getReportRoutesReject,
    getReportRoutesRequest,
    getReportRoutesSuccess
} from "../../actions/routes/getAll";

export const getReportRoutes = (formData) => async (dispatch, getState) => {
    dispatch(getReportRoutesLoading());
    try {
        const { data } = await getReportRoutesRequest(formData, getState);
        dispatch(getReportRoutesSuccess(data))
    } catch (error) {
        dispatch(getReportRoutesReject(error))
    }
    return Promise.resolve();
};