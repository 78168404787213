import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ROUTE_TRACKING_LOADING = 'GET_ROUTE_TRACKING_LOADING';
export const GET_ROUTE_TRACKING_SUCCESS = 'GET_ROUTE_TRACKING_SUCCESS';
export const GET_ROUTE_TRACKING_REJECT = 'GET_ROUTE_TRACKING_REJECT';

//* ACTIONS ------------------------------------------------
export const getTrackingRouteLoading = () => ({ type: GET_ROUTE_TRACKING_LOADING });
export const getTrackingRouteSuccess = (payload) => ({ type: GET_ROUTE_TRACKING_SUCCESS, payload });
export const getTrackingRouteReject = (payload) => ({ type: GET_ROUTE_TRACKING_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getTrackingRouteRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Order/GetOrderTracking`, options);
};