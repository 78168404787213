import {
    postOrderItemLoading,
    postOrderItemRequest,
    postOrderItemSuccess,
    postOrderItemReject,
    postOrderItemReset
} from "../../actions/order/postItem";

export const postOrderItem = (formData) => async (dispatch, getState) => {
    dispatch(postOrderItemLoading());
    try {
        const { data } = await postOrderItemRequest(formData, getState);
        dispatch(postOrderItemSuccess(data))
    } catch (error) {
        dispatch(postOrderItemReject(error))
    } finally {
        setTimeout(() => { dispatch(postOrderItemReset()) }, 3000);
    }
    return Promise.resolve();
};