import React from 'react';
import { Toolbar, Grid, Stack, MenuItem, Button, IconButton, Paper, InputAdornment } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { Link } from "react-router-dom"
import { useFormik } from 'formik';
import get from "lodash/get";
import CloseIcon from '@mui/icons-material/Close';

import InputText from "../../../components/form/InputText";

const ToolbarComponent = ({ setFilter, __, reload, isLoading }) => {


    const onSubmit = (values) => {
        let Filters = "";

        if (!!values.name) { Filters = `name@=${values.name}` }
        if (!!values.email) { Filters = `${!!Filters ? `${Filters},` : ""}email@=${values.email}` }
        switch (get(values, "isActive")) {
            case 1: { Filters = `${!!Filters ? `${Filters},` : ""}isActive==true` }; break;
            case 2: { Filters = `${!!Filters ? `${Filters},` : ""}isActive==false` }; break;
            default: break;
        }

        setFilter(Filters)
    }

    const isActive = [
        {
            value: 0,
            name: __(`isActive.all`),
        },
        {
            value: 1,
            name: __(`isActive.true`),
        },
        {
            value: 2,
            name: __(`isActive.false`),
        },
    ]

    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            isActive: get(isActive, "[0].value"),
        },
        onSubmit,
    });

    const onClear = (field) => async () => {
        await formik.setFieldValue(field, "")
        formik.submitForm()
    }

    return (
        <Toolbar component={Paper} className='p-2 mb-4' sx={{ borderBottomWidth: "1px", borderBottomColor: "text.sslite", borderBottomStyle: "solid" }}>
            <Stack component="form" onSubmit={get(formik, "handleSubmit")} direction={{ sx: "column", xl: "row" }} alignItems={{ sx: "flex-start", xl: "center" }} justifyContent={{ sx: "center", xl: "space-between" }} spacing={{ sx: 1, xl: 1 }} className='w-full'>
                <Grid container spacing={1} justifyContent={{ sx: "flex-start", md: "flex-end" }}>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <InputText
                            name={"name"}
                            formik={formik}
                            labelInput={__(`toolbar.filter.name`)}
                            placeholder={""}
                            disabled={isLoading}
                            onBlur={get(formik, "submitForm", () => { })}
                            InputProps={{ endAdornment: <InputAdornment position="end"><IconButton aria-label="close" size="small" edge="end" onClick={onClear("name")}><CloseIcon fontSize="inherit" /></IconButton></InputAdornment> }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <InputText
                            name={"email"}
                            formik={formik}
                            labelInput={__(`toolbar.filter.email`)}
                            placeholder={""}
                            disabled={isLoading}
                            onBlur={get(formik, "submitForm", () => { })}
                            InputProps={{ endAdornment: <InputAdornment position="end"><IconButton aria-label="close" size="small" edge="end" onClick={onClear("email")}><CloseIcon fontSize="inherit" /></IconButton></InputAdornment> }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                        <InputText
                            name={"isActive"}
                            formik={formik}
                            select
                            labelInput={__(`toolbar.filter.isActive`)}
                            placeholder={""}
                            disabled={isLoading}
                        >
                            {isActive.map(({ value, name }, index) => (
                                <MenuItem key={index} value={value} onClick={get(formik, "submitForm", () => { })}>
                                    {name}
                                </MenuItem>
                            ))}
                        </InputText>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3} xl={2}>
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                            <Button className='w-[100px] min-w-[100px]' component={Link} variant="contained" color="secondary" to="new">{__(`toolbar.btn`)}</Button>
                            <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Toolbar>
    )
}

export default ToolbarComponent