import * as yup from 'yup';

const validators = (__) => () => {
    return (
        yup.object({
            name: yup.string().required(__(`form.name.error`)),
            phone: yup.string().required(__(`form.phone.error`)),
            email: yup.string().email(__(`form.email.error2`)).required(__(`form.email.error`)),
        })
    )
}

export default validators
