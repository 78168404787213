import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_TRANSPORT_SUPPLIER_RESET = 'DELETE_TRANSPORT_SUPPLIER_RESET';
export const DELETE_TRANSPORT_SUPPLIER_LOADING = 'DELETE_TRANSPORT_SUPPLIER_LOADING';
export const DELETE_TRANSPORT_SUPPLIER_SUCCESS = 'DELETE_TRANSPORT_SUPPLIER_SUCCESS';
export const DELETE_TRANSPORT_SUPPLIER_REJECT = 'DELETE_TRANSPORT_SUPPLIER_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteTransportSupplierReset = () => ({ type: DELETE_TRANSPORT_SUPPLIER_RESET });
export const deleteTransportSupplierLoading = () => ({ type: DELETE_TRANSPORT_SUPPLIER_LOADING });
export const deleteTransportSupplierSuccess = (payload) => ({ type: DELETE_TRANSPORT_SUPPLIER_SUCCESS, payload });
export const deleteTransportSupplierReject = (payload) => ({ type: DELETE_TRANSPORT_SUPPLIER_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteTransportSupplierRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/TransportSuppliers/${data.id}`, options);
};