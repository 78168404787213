import {
    putTransportLoading,
    putTransportRequest,
    putTransportSuccess,
    putTransportReject,
    putTransportReset
} from "../../actions/transport/put";

export const putTransport = (formData) => async (dispatch, getState) => {
    dispatch(putTransportLoading());
    try {
        const { data } = await putTransportRequest(formData, getState);
        dispatch(putTransportSuccess(data))
    } catch (error) {
        dispatch(putTransportReject(error))
    } finally {
        setTimeout(() => { dispatch(putTransportReset()) }, 3000);
    }
    return Promise.resolve();
};