import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_CONTACT_LOADING = 'GET_CONTACT_LOADING';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_REJECT = 'GET_CONTACT_REJECT';

//* ACTIONS ------------------------------------------------
export const getContactLoading = () => ({ type: GET_CONTACT_LOADING });
export const getContactSuccess = (payload) => ({ type: GET_CONTACT_SUCCESS, payload });
export const getContactReject = (payload) => ({ type: GET_CONTACT_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getContactRequest = async ({idClient, ...params}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Contact/GetAllContactsByIdClient/${idClient}`, options);
};