import React from 'react'
import get from "lodash/get";
import map from "lodash/map";

import { Paper } from "@mui/material"

const Detail = ({ tracking }) => {
    return (
        <div className='flex flex-col h-full w-[full] bg-white rounded-md'>

            <div className='hidden md:flex flex-col justify-center items-center p-6 border-b border-gray-300 h-[188px]'>
                <img className='h-[91px] w-[112px]' src="/icp.png" />
                <div className='w-[250px] bg-gray-100 mt-4 text-center rounded-md py-1 text-gray-400'>
                    ICP Logística
                </div>
            </div>

            <div className='p-4 gap-4 flex flex-col flex-1' style={{ height: "calc(100% - 188px)" }}>
                <Paper elevation={3} className={`h-[88px] rounded-md flex justify-between items-center gap-x-6 py-5 "bg-gray-100" p-[20px] duration-300 cursor-pointer `}>
                    <div className="flex min-w-0 w-full max-w-full">
                        <svg className='w-12 h-12 flex-none rounded-full bg-gray-50' viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect className={`fill-[#B0E2FA] h-[44px] w-[44px]`} rx="22" fill="#B0E2FA" />
                            <g clipPath="url(#clip0_824_89124)">
                                <path className={`fill-[#0C709F]`} d="M28.75 13.9395L14.07 20.0795C13.24 20.4295 13.26 21.6095 14.09 21.9295L19.43 23.9995C19.69 24.0995 19.9 24.3095 20 24.5695L22.06 29.8995C22.38 30.7395 23.57 30.7595 23.92 29.9295L30.07 15.2595C30.4 14.4295 29.57 13.5995 28.75 13.9395Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_824_89124">
                                    <rect width="24" height="24" fill="white" transform="translate(10 10)" />
                                </clipPath>
                            </defs>
                        </svg>

                        <div className="min-w-0 flex-auto pl-[10px]">
                            <p className="text-sm font-semibold leading-6 text-gray-900">ID:&nbsp;{get(tracking, "data.idRoute")}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{get(tracking, "data.routeName")}</p>
                        </div>
                    </div>
                </Paper>

                <div className='flex flex-col h-full flex-1 rounded-md overflow-auto' style={{ height: "calc(100% - 88px)" }}>
                    <div className="flex justify-between py-5 p-[10px] duration-300">
                        <div className="flex min-w-0 w-full max-w-full">
                            <div className="min-w-0 flex-auto pl-[10px]">
                                <div className='relative flex flex-row justify-between'>
                                    <p className="text-sm font-semibold leading-6 text-gray-900 truncate">ID:&nbsp;{get(tracking, "data.idOrder")}</p>
                                </div>
                                <p className="truncate text-xs leading-5 text-gray-500">{get(tracking, "data.docNum")}</p>
                                <p className="truncate text-xs leading-5 text-gray-500">{get(tracking, "data.deliveryName")}</p>
                            </div>
                        </div>
                    </div>

                    <ul className='flex-1 flex flex-col gap-3'>
                        {map(get(tracking, "data.orderTranckingItems"), ({ itemId, itemName, quantity }) => (
                            <li key={itemId} className="flex h-[88px] justify-between gap-x-6 py-5 p-[10px] duration-300 bg-gray-200 rounded-md">
                                <div className="flex min-w-0 w-full max-w-full">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F7F9FC" />
                                        <path d="M24.4901 11.5195C24.4844 11.4931 24.4844 11.4658 24.4901 11.4395C24.4852 11.4164 24.4852 11.3926 24.4901 11.3695V11.2795L24.4301 11.1295C24.4057 11.0885 24.3754 11.0515 24.3401 11.0195L24.2501 10.9395H24.2001L20.2601 8.44947L16.5401 6.14947C16.454 6.0812 16.3555 6.03026 16.2501 5.99947H16.1701C16.0807 5.98455 15.9894 5.98455 15.9001 5.99947H15.8001C15.6839 6.02516 15.5725 6.06904 15.4701 6.12947L8.00007 10.7795L7.91007 10.8495L7.82007 10.9295L7.72007 10.9995L7.67007 11.0595L7.61007 11.2095V11.2995V11.3595C7.60035 11.4258 7.60035 11.4932 7.61007 11.5595V20.2895C7.60973 20.4594 7.6527 20.6266 7.73494 20.7754C7.81717 20.9241 7.93596 21.0494 8.08007 21.1395L15.5801 25.7795L15.7301 25.8395H15.8101C15.9792 25.8931 16.1609 25.8931 16.3301 25.8395H16.4101L16.5601 25.7795L24.0001 21.2095C24.1442 21.1194 24.263 20.9941 24.3452 20.8454C24.4274 20.6966 24.4704 20.5294 24.4701 20.3595V11.6295C24.4701 11.6295 24.4901 11.5595 24.4901 11.5195ZM16.0001 8.16947L17.7801 9.26947L12.1901 12.7295L10.4001 11.6295L16.0001 8.16947ZM15.0001 23.1695L9.50007 19.8095V13.4195L15.0001 16.8195V23.1695ZM16.0001 15.0595L14.0901 13.9095L19.6801 10.4395L21.6001 11.6295L16.0001 15.0595ZM22.5001 19.7795L17.0001 23.1995V16.8195L22.5001 13.4195V19.7795Z" fill="#3B405E" />
                                    </svg>
                                    <div className="min-w-0 flex-auto pl-[10px]">
                                        <div className='relative flex flex-row justify-between'>
                                            <p className="text-sm font-semibold leading-6 text-gray-900 truncate">ID:&nbsp;{itemId}</p>
                                        </div>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{itemName} ({quantity})</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Detail