import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Drawer as MuiDrawer,
    List,
    Typography,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Avatar,
    Collapse,
} from '@mui/material';
import { Link } from "react-router-dom"
import { get, map, filter, find } from "lodash";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { drawerWidth } from "../../Layout"
import TMS from "../../../../assets/icons/TMS";
import TMSFull from "../../../../assets/icons/TMS_Full";
import FlagEsp from "../../../../assets/icons/FlagEsp";
import FlagEng from "../../../../assets/icons/FlagEng";
// import FlagPort from "../../../../assets/icons/FlagPort";
// import FlagRus from "../../../../assets/icons/FlagRus";
import { main, others } from "./options"

import { putProfileRequest } from "../../../../store/auth/actions/putProfile";
import { putOperatorsRequest } from "../../../../store/masters/actions/operators/put";
import { putDriverRequest } from "../../../../store/masters/actions/driver/put";

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({ currentPath, openDrawer, setOpenDrawer }) {
    const [__, i18n] = useTranslation("global");

    const [open, setOpen] = useState(null);
    const [lang, setLang] = useState(i18n.language);

    const dataUser = useSelector(state => state.auth.login.dataUser);
    const getState = useSelector(state => state);

    const handleDrawerOpen = () => {
        if (!openDrawer) {
            setOpenDrawer(true);
            setOpen(get(find(main(__), ({ path }) => path === get(currentPath, "parentPath")), "key"))
        }
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setOpen(null)
    };

    const langs = [
        {
            key: "1",
            lang: "es",
            ico: <FlagEsp />,
            name: __(`lang.es`),
        },
        {
            key: "2",
            lang: "en",
            ico: <FlagEng />,
            name: __(`lang.en`),
        },
        // {
        //     key: "3",
        //     lang: "pt",
        //     ico: <FlagPort />,
        //     name: __(`lang.pt`),
        // },
        // {
        //     key: "4",
        //     lang: "ru",
        //     ico: <FlagRus />,
        //     name: __(`lang.ru`),
        // },
    ]
    const changeLanguage = (l) => async () => {
        await setLang(get(l, "key"));
        await setOpen(0);
        await i18n.changeLanguage(get(l, "key"));
        await localStorage.setItem("lang", get(l, "key"));

        const body = {
            ...dataUser,
            idLanguage: Number(get(l, "key"))
        }

        // idUserType: 1, Admin
        // idUserType: 2, Operario
        // idUserType: 3, Conductor
        if (get(dataUser, "idUserType") === 1) {
            await putProfileRequest(body, () => getState)
        } else if (get(dataUser, "idUserType") === 2) {
            await putOperatorsRequest(body, () => getState)
        } else {
            await putDriverRequest(body, () => getState)
        }

    }

    return (
        <div className='relative'>
            <Drawer
                variant="permanent"
                open={openDrawer}
                onMouseLeave={handleDrawerClose}
                onMouseEnter={handleDrawerOpen}
            >
                <DrawerHeader sx={{ bgcolor: "primary.main", height: "100px !important", minHeight: "100px !important", display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <Link to={"/"}>
                        {openDrawer ? <TMSFull /> : <TMS />}
                    </Link>
                </DrawerHeader>
                <List sx={{ bgcolor: "primary.main", flex: 1 }} >
                    <ListItemText sx={{ color: theme => theme.palette.color.skyblue[200], minHeight: 32, justifyContent: openDrawer ? 'initial' : 'center', px: 2.5 }} >
                        <Typography variant="body2">{openDrawer ? __("menu.label.op.name") : __("menu.label.op.short")}</Typography>
                    </ListItemText>
                    {map(main(__), (item, index) => (
                        <ListItem
                            key={get(item, "key")}
                            disablePadding
                            sx={{
                                display: 'block',
                                color: "#FFF",
                                "&:hover": { "& .btn-main": { bgcolor: theme => theme.palette.color.skyblue[400] } },
                                "& .btn-main": (get(item, "path") === get(currentPath, "parentPath") && !!get(currentPath, "parentPath")) ? { transition: "all 0.5s", bgcolor: theme => theme.palette.color.skyblue[400] } : {}
                            }}
                        >
                            <ListItemButton className='btn-main' onClick={() => setOpen(open !== item.key ? item.key : 0)} sx={{ minHeight: 48, justifyContent: openDrawer ? 'initial' : 'center', padding: "8px 10px", margin: "4px 10px", borderRadius: 2 }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: openDrawer ? 3 : 'auto', alignItems: "flex-start", justifyContent: 'center', color: "white" }}>
                                    {get(item, "ico")}
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: openDrawer ? 1 : 0, width: "100%" }} ><Typography >{get(item, "name")}</Typography></ListItemText>
                                <ListItemIcon sx={{ display: openDrawer ? "inline" : "none", minWidth: 0, color: "white", transform: `rotate(${open === item.key ? -180 : 0}deg)`, transition: "transform 0.3s" }}>
                                    <ExpandMoreIcon />
                                </ListItemIcon>
                            </ListItemButton>
                            <Collapse in={open === item.key}  >
                                {map(item.children, (sItem) => (
                                    <ListItemButton
                                        key={get(sItem, "key")}
                                        LinkComponent={Link}
                                        to={get(sItem, "path")}
                                        sx={{ justifyContent: openDrawer ? 'initial' : 'center', backgroundColor: get(sItem, "path") === get(currentPath, "path") ? "primary.dark" : "primary" }}
                                    >
                                        <ListItemText className='ml-2' sx={{ opacity: openDrawer ? 1 : 0 }} ><Typography className='truncate'>{get(sItem, "name")}</Typography></ListItemText>
                                    </ListItemButton>
                                ))}
                            </Collapse>
                        </ListItem>
                    ))}
                </List>
                <List sx={{ bgcolor: "primary.main" }} >
                    <ListItem disablePadding sx={{ display: 'block', color: "#FFF" }}>
                        <ListItemText sx={{ color: theme => theme.palette.color.skyblue[200], minHeight: 32, justifyContent: openDrawer ? 'initial' : 'center', px: 2.5 }} >
                            <Typography variant="body2">{openDrawer ? __("menu.label.oo.name") : __("menu.label.oo.short")}</Typography>
                        </ListItemText>
                    </ListItem>
                    {map(others(__), ({ key, name, ico }, index) => (
                        <ListItem key={key} disablePadding sx={{ display: 'block', color: "#FFF" }}>
                            <ListItemButton sx={{ minHeight: 48, my: 1, justifyContent: openDrawer ? 'initial' : 'center', px: 2.5 }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: openDrawer ? 3 : 'auto', justifyContent: 'center', color: "white", alignSelf: "flex-start", pt: 0.5 }}>
                                    {ico}
                                </ListItemIcon>
                                <ListItemText primary={name} sx={{ opacity: openDrawer ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <List sx={{ bgcolor: "primary.main" }} >
                    <ListItem disablePadding sx={{ display: 'block', color: "#FFF", "&:hover": { "& .btn-main": { bgcolor: theme => theme.palette.color.skyblue[400] } } }}>
                        <ListItemButton className='btn-main' onClick={() => setOpen(open !== "lang" ? "lang" : 0)} sx={{ minHeight: 48, justifyContent: openDrawer ? 'initial' : 'center', padding: "8px 6px", margin: "4px 10px", borderRadius: 2 }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: openDrawer ? 3 : 'auto', alignItems: "flex-start", justifyContent: 'center', color: "white" }}>
                                {get(find(langs, ({ key }) => key === lang), "ico")}
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: openDrawer ? 1 : 0, width: "100%" }} ><Typography >{get(find(langs, ({ key }) => key === lang), "name")}</Typography></ListItemText>
                            <ListItemIcon sx={{ display: openDrawer ? "inline" : "none", minWidth: 0, color: "white", transform: `rotate(${open === "lang" ? -180 : 0}deg)`, transition: "transform 0.3s" }}>
                                <ExpandMoreIcon />
                            </ListItemIcon>
                        </ListItemButton>
                        <Collapse in={open === "lang"} >
                            {map(filter(langs, ({ key }) => key !== lang), (l) => (
                                <ListItemButton key={get(l, "key")} onClick={changeLanguage(l)} sx={{ justifyContent: openDrawer ? 'initial' : 'center' }} >
                                    <ListItemIcon className='ml-2' sx={{ minWidth: 0, mr: openDrawer ? 3 : 'auto', alignItems: "flex-start", justifyContent: 'center', color: "white" }}>
                                        {get(l, `ico`)}
                                    </ListItemIcon>
                                    <ListItemText sx={{ opacity: openDrawer ? 1 : 0 }} ><Typography className='truncate'>{get(l, `name`)}</Typography></ListItemText>
                                </ListItemButton>
                            ))}
                        </Collapse>
                    </ListItem>
                </List>
                <List sx={{ bgcolor: "primary.main" }} >
                    <ListItem disablePadding sx={{ display: 'block', color: "#FFF" }}>
                        <ListItemButton LinkComponent={Link} to="/profile" sx={{ minHeight: 48, justifyContent: openDrawer ? 'initial' : 'center', px: 1.5 }}>
                            <ListItemIcon sx={{ minWidth: 0, mr: openDrawer ? 3 : 'auto', justifyContent: 'center', color: "white" }}>
                                <Avatar alt={get(dataUser, "name")} src={get(dataUser, "profileImageUrl")} />
                            </ListItemIcon>
                            <ListItemText primary={get(dataUser, "name")} sx={{ opacity: openDrawer ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </div >
    );
}
