import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_PASS_OPERATORS_RESET = 'PUT_PASS_OPERATORS_RESET';
export const PUT_PASS_OPERATORS_LOADING = 'PUT_PASS_OPERATORS_LOADING';
export const PUT_PASS_OPERATORS_SUCCESS = 'PUT_PASS_OPERATORS_SUCCESS';
export const PUT_PASS_OPERATORS_REJECT = 'PUT_PASS_OPERATORS_REJECT';

//* ACTIONS ------------------------------------------------
export const putPassOperatorsReset = () => ({ type: PUT_PASS_OPERATORS_RESET });
export const putPassOperatorsLoading = () => ({ type: PUT_PASS_OPERATORS_LOADING });
export const putPassOperatorsSuccess = (payload) => ({ type: PUT_PASS_OPERATORS_SUCCESS, payload });
export const putPassOperatorsReject = (payload) => ({ type: PUT_PASS_OPERATORS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putPassOperatorsRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Users/ChangeOperatorConfirmedPass`, options);
};
