import {
    putPassOperatorsLoading,
    putPassOperatorsRequest,
    putPassOperatorsSuccess,
    putPassOperatorsReject,
    putPassOperatorsReset
} from "../../actions/operators/putPass";

export const putPassOperators = (formData) => async (dispatch, getState) => {
    dispatch(putPassOperatorsLoading());
    try {
        const { data } = await putPassOperatorsRequest(formData, getState);
        dispatch(putPassOperatorsSuccess(data))
    } catch (error) {
        dispatch(putPassOperatorsReject(error))
    } finally {
        setTimeout(() => { dispatch(putPassOperatorsReset()) }, 3000);
    }
    return Promise.resolve();
};