// global
import global from "../../assets/traslations/ru/global.json";

// auth
import forgot_pass from "../../assets/traslations/ru/auth/forgot_pass.json";
import login from "../../assets/traslations/ru/auth/login.json";
import profile from "../../assets/traslations/ru/auth/profile.json";
import register from "../../assets/traslations/ru/auth/register.json";
import reset_pass from "../../assets/traslations/ru/auth/reset_pass.json";
import validate_email from "../../assets/traslations/ru/auth/validate_email.json";

// master
import contact from "../../assets/traslations/ru/master/contact.json";
import client from "../../assets/traslations/ru/master/client.json";
import delivery from "../../assets/traslations/ru/master/delivery.json";
import driver from "../../assets/traslations/ru/master/driver.json";
import transport from "../../assets/traslations/ru/master/transport.json";
import operators from "../../assets/traslations/ru/master/operators.json";

// reports
import invoices from "../../assets/traslations/ru/reports/invoices.json";
import orders from "../../assets/traslations/ru/reports/orders.json";
import routeCost from "../../assets/traslations/ru/reports/routeCost.json";
import routes from "../../assets/traslations/ru/reports/routes.json";

// transactions
import order from "../../assets/traslations/ru/transactions/order.json";
import quote from "../../assets/traslations/ru/transactions/quote.json";
import route from "../../assets/traslations/ru/transactions/route.json";
import invoice from "../../assets/traslations/ru/transactions/invoice.json";

// config
import cost from "../../assets/traslations/ru/config/cost.json";
import transportBrand from "../../assets/traslations/ru/config/transportBrand.json";
import transportSupplier from "../../assets/traslations/ru/config/transportSupplier.json";


const ru = {
    // global
    global,

    // auth
    forgot_pass,
    login,
    profile,
    register,
    reset_pass,
    validate_email,

    // master
    contact,
    client,
    delivery,
    driver,
    transport,
    operators,

    // reports
    invoices,
    orders,
    routeCost,
    routes,

    // transactions
    order,
    quote,
    route,
    invoice,

    // config
    cost,
    transportBrand,
    transportSupplier,
}

export default ru;
