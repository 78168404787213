import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_ORDER_IMAGES_LOADING = 'GET_ORDER_IMAGES_LOADING';
export const GET_ORDER_IMAGES_SUCCESS = 'GET_ORDER_IMAGES_SUCCESS';
export const GET_ORDER_IMAGES_REJECT = 'GET_ORDER_IMAGES_REJECT';

//* ACTIONS ------------------------------------------------
export const getOrderImagesLoading = () => ({ type: GET_ORDER_IMAGES_LOADING });
export const getOrderImagesSuccess = (payload) => ({ type: GET_ORDER_IMAGES_SUCCESS, payload });
export const getOrderImagesReject = (payload) => ({ type: GET_ORDER_IMAGES_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getOrderImagesRequest = async ({ idOrder, ...params }, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Order/GetImagesByOrder/${idOrder}`, options);
};
