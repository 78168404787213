import {
    getOrderImagesLoading,
    getOrderImagesReject,
    getOrderImagesRequest,
    getOrderImagesSuccess
} from "../../actions/order/getOrderImages";

export const getOrderImages = (formData) => async (dispatch, getState) => {
    dispatch(getOrderImagesLoading());
    try {
        const { data } = await getOrderImagesRequest(formData, getState);
        dispatch(getOrderImagesSuccess(data))
    } catch (error) {
        dispatch(getOrderImagesReject(error))
    }
    return Promise.resolve();
};