import {
    getOperatorsLoading,
    getOperatorsReject,
    getOperatorsRequest,
    getOperatorsSuccess
} from "../../actions/operators/getAll";

export const getOperators = (formData) => async (dispatch, getState) => {
    dispatch(getOperatorsLoading());
    try {
        const { data } = await getOperatorsRequest(formData, getState);
        dispatch(getOperatorsSuccess(data))
    } catch (error) {
        dispatch(getOperatorsReject(error))
    }
    return Promise.resolve();
};