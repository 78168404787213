import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const GET_REPORT_ROUTES_LOADING = 'GET_REPORT_ROUTES_LOADING';
export const GET_REPORT_ROUTES_SUCCESS = 'GET_REPORT_ROUTES_SUCCESS';
export const GET_REPORT_ROUTES_REJECT = 'GET_REPORT_ROUTES_REJECT';

//* ACTIONS ------------------------------------------------
export const getReportRoutesLoading = () => ({ type: GET_REPORT_ROUTES_LOADING });
export const getReportRoutesSuccess = (payload) => ({ type: GET_REPORT_ROUTES_SUCCESS, payload });
export const getReportRoutesReject = (payload) => ({ type: GET_REPORT_ROUTES_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const getReportRoutesRequest = async (params = {}, getState) => {
    const options = await withToken({
        method: Methods.GET,
        params,
    }, getState);
    return request(`/api/Reports/GetAllRoutesBaseReport`, options);
};

