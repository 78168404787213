import React from 'react'
import { Typography, FormControl, TextField, Skeleton } from '@mui/material';
import get from "lodash/get";

const InputText = ({ children, formik, name, label, disabled, labelInput = null, fullWidth = true, loading, ...props }) => {
    return (
        <FormControl fullWidth={fullWidth} >
            {!labelInput &&
                <Typography className='pb-2 pl-4' component="label" htmlFor={name} color={disabled ? "text.lite" : "text.main"} >
                    {label}
                </Typography>
            }
            {loading
                ? (
                    <Skeleton className='mb-2' variant="rounded" width={fullWidth ? "100%" : "30%"} height={39} />
                ) : (
                    <TextField
                        id={name}
                        name={name}
                        size="small"
                        value={get(formik, `values.${name}`)}
                        onChange={get(formik, `handleChange`)}
                        error={get(formik, `touched.${name}`) && Boolean(get(formik, `errors.${name}`))}
                        helperText={get(formik, `touched.${name}`) && get(formik, `errors.${name}`)}
                        disabled={disabled}
                        label={labelInput}
                        {...props}
                    >
                        {children}
                    </TextField>
                )}
        </FormControl>
    )
}

export default InputText