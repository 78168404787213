import { concat } from "lodash";

import routerOrders from "./orders";
// import routerQuotes from "./quotes";
import routerRoutes from "./routes";
import routerInvoices from "./invoice";
// import routerSettlementCarrier from "./settlement_carrier";
// import routerSettlementCustomers from "./settlement_customers";

import Transactions from "../../../../pages/Transactions";

const routesTransactions = [{ key: "transactions", path: "/transactions", element: <Transactions />, }]

const routes = concat(
    routesTransactions,
    routerOrders,
    // routerQuotes,
    routerRoutes,
    routerInvoices,
    // routerSettlementCarrier,
    // routerSettlementCustomers,
)

export default routes
