import {
    getClientIdLoading,
    getClientIdRequest,
    getClientIdSuccess,
    getClientIdReject,
} from "../../actions/client/getId";

export const getClientId = (formData) => async (dispatch, getState) => {
    dispatch(getClientIdLoading());
    try {
        const { data } = await getClientIdRequest(formData, getState);
        dispatch(getClientIdSuccess(data))
    } catch (error) {
        dispatch(getClientIdReject(error))
    }
    return Promise.resolve();
};