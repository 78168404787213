/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Stack,
    Paper,
    Typography,
    Divider,
    Grid,
    Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import moment from "moment";
import LocationOffIcon from '@mui/icons-material/LocationOff';

import get from "lodash/get";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import AutoComplete from "../../../components/form/AutoComplete";
import AutoComplete2 from "../../../components/form/AutoComplete2";
import { PageSize } from "../../../config/const"

import Layout from "../../../components/layout/Layout";
import InputText from "../../../components/form/InputText";
import DatePickerUi from "../../../components/form/DatePickerUi";
import validator from "./validator"
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Skeleton from "../../../components/form/Skeleton"
import MapToNew from "../../../components/map/MapToNew"
import MapOne from "../../../components/map/Map"
import Graf from "./Graf"

import { postRoute } from "../../../store/transactions/thunk/route/post"

import { getDeliveryPoint } from "../../../store/masters/thunk/deliveryPoint/getAll";
import { getTransport } from "../../../store/masters/thunk/transport/getAll";
import { getDriver } from "../../../store/masters/thunk/driver/getAll";
import { postRouteReset } from "../../../store/transactions/actions/route/post";

const NewEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [__] = useTranslation("route");
    const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
    const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });
    const [km, setKm] = useState(null)
    const [isMount, setIsMount] = useState(false);

    const user = useSelector(state => state.auth.login.dataUser);
    const current = useSelector(state => state.transactions.route.detail);
    const put = useSelector(state => state.transactions.route.put);
    const post = useSelector(state => state.transactions.route.post);
    const deliveryPoint = useSelector(state => state.masters.deliveryPoints);
    const transport = useSelector(state => state.masters.transport);
    const driver = useSelector(state => state.masters.driver);

    // const routeType = [{ value: 0, label: "Empty" }];
    const routeStatus = __(`routeStatus`, { returnObjects: true });
    const transports = map(transport?.data, ({ idTransport, numberPlate, name }) => ({ value: idTransport, label: `${numberPlate} | ${name}` }));
    const drivers = map(driver?.data, ({ idDriver, user, license }) => ({ value: idDriver, label: `${license} | ${user.name}` }));
    const deliveryPoints = map(deliveryPoint?.data, ({ idDeliveryPoint, deliveryCode, deliveryName }) => ({ value: idDeliveryPoint, label: `${deliveryCode} - ${deliveryName}` }));

    const isActive = "isActive==true";

    const getDataDriver = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `user.name@=${search},${isActive}`
        dispatch(getDriver(params))
    }
    const getDataTransport = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `name@=${search},${isActive}`
        dispatch(getTransport(params))
    }
    const getDataDelivery = (search) => {
        const params = { PageSize, Filters: isActive }
        if (search) params.Filters = `deliveryName@=${search},${isActive}`
        dispatch(getDeliveryPoint(params))
    }

    useEffect(() => {
        getDataDriver()
        getDataTransport()
        getDataDelivery()
    }, [dispatch])

    const closeAlert = () => {
        setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
    }
    const closeNoti = (e) => {
        setShowNoti(e)
        dispatch(postRouteReset())
    }

    const setError = (err, action) => {
        if (!isEmpty(err) && !!get(err, "Message", "")) {
            setAlert({
                open: true,
                title: get(err, "Message", ""),
                subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
                type: "error",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        } else {
            setAlert({
                open: true,
                type: "default",
                btn: __(`${module}.actions.close`),
                func: closeAlert
            })
        }
    }

    useEffect(() => {
        if (get(post, "isSuccess", false)) { setShowNoti({ open: true, msg: "", variant: "success", action: "post" }); navigate(`/transactions/routes/${get(post, "id")}?from=new`) }
        if (get(post, "isReject", false)) { setError(get(post, "error", {}), "post"); }
    }, [post])



    const onSubmit = (values) => {
        const body = {
            ...values,
            IsActive: get(values, "isActive"),
            routeName: get(values, "routeName"),
            idRouteStatus: Number(get(values, "idRouteStatus")),
            comments: get(values, "comments"),
            idTransport: Number(get(values, "idTransport")),
            idDriver: Number(get(values, "idDriver")),
            idUser: Number(get(values, "idUser")),
            idReturnDP: Number(get(values, "idReturnDP")),
            idStartDP: Number(get(values, "idStartDP")),
            routeStatus: get(values, "routeStatus"),
            address: get(values, "address"),
            dateToRoute: get(values, "dateToRoute"),
        }
        delete body.routeStatus
        const whitOutNulls = pickBy(body, identity);
        dispatch(postRoute(whitOutNulls))
    }

    const status = find(routeStatus, ({ value }) => value === get(current, "data.idRouteStatus", 1))

    const initialValues = {
        isActive: true,
        routeName: "",

        idRouteStatus: 1,
        routeStatus: get(find(routeStatus, ({ value }) => value === 1), "label"),

        idStartDP: "",
        idReturnDP: "",
        idTransport: "",
        idDriver: "",

        dateStart:  undefined,
        dateToRoute: undefined,
        address: "",
        comments: "",

        idUser: get(user, "idUser"),
    }

    const evaluateCoord = (idDP, v) => {
        const { latitude, longitude } = find(deliveryPoint?.data, ({ idDeliveryPoint }) => idDeliveryPoint === idDP) || {}
        if ((!!latitude && !!longitude) || !idDP) {
            return false
        } else {
            return true
        }
    }

    const getCoords = (start, end) => {
        const coords = []
        if (!!start) {
            const coordStart = {
                location: {
                    id: 1,
                    lat: Number(get(find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === start), "latitude", "")),
                    lng: Number(get(find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === start), "longitude", "")),
                }
            }
            coords.push(coordStart)
        }
        if (!!end) {
            const coordEnd = {
                location: {
                    id: 2,
                    lat: Number(get(find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === end), "latitude", "")),
                    lng: Number(get(find(deliveryPoint.data, ({ idDeliveryPoint }) => idDeliveryPoint === end), "longitude", "")),
                }
            }
            coords.push(coordEnd)
        }
        return coords;
    }

    return (
        <Layout
            propsToolbar={{
                title: __(`head.new.name`),
                srute: __(`head.new.code`),
            }}
        >
            <div>
                {false
                    ? (
                        <Skeleton />
                    ) : (
                        <Formik initialValues={initialValues} validationSchema={validator(__)} onSubmit={onSubmit}>
                            {formik => (
                                <form onSubmit={get(formik, "handleSubmit")}>
                                    <Paper className='p-4'>
                                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                                            <LoadingButton variant="contained" color="secondary" type="submit" loading={get(post, "isLoading") || get(put, "isLoading")}>{__(`action.save`)}</LoadingButton>
                                            <Button variant='outlined' color="secondary" onClick={() => navigate("/transactions/routes")} disabled={get(post, "isLoading") || get(put, "isLoading")} >{__(`action.cancel`)}</Button>
                                        </Stack>
                                    </Paper>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <Paper className='mt-4' >
                                                <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                                    <Typography component="h4" variant="h4">{__(`labels.1`)}</Typography>
                                                    <InputText
                                                        name={"routeStatus"}
                                                        formik={formik}
                                                        label=""
                                                        placeholder=""
                                                        disabled={true}
                                                        fullWidth={false}
                                                        sx={{ "fieldset": { borderColor: `${get(status, "color")} !important` }, "& .Mui-disabled": { "-webkit-text-fill-color": `${get(status, "color")} !important` } }}
                                                    />
                                                </Stack>
                                                <Divider />
                                                <Box className='p-4' >
                                                    <Grid container spacing={{ xs: 2, md: 3 }}>
                                                        <Grid item xs={12}>
                                                            <InputText
                                                                formik={formik}
                                                                name="routeName"
                                                                label={__(`form.routeName.label`)}
                                                                placeholder={__(`form.routeName.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <DatePickerUi
                                                                formik={formik}
                                                                name="dateToRoute"
                                                                label={__(`form.dateToRoute.label`)}
                                                                placeholder={__(`form.dateToRoute.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <AutoComplete
                                                                name={"idDriver"}
                                                                formik={formik}
                                                                label={__(`form.idDriver.label`)}
                                                                placeholder={__(`form.idDriver.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                                options={drivers}
                                                                reload={getDataDriver}
                                                                loading={get(driver, "isLoading")}
                                                                setIsMount={setIsMount}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography component="h5" variant="h5" color="text.lite">{__(`labels.2`)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <AutoComplete2
                                                                name={"idStartDP"}
                                                                formik={formik}
                                                                label={__(`form.idStartDP.label`)}
                                                                placeholder={__(`form.idStartDP.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                                options={deliveryPoints}
                                                                reload={getDataDelivery}
                                                                loading={get(deliveryPoint, "isLoading")}
                                                                setIsMount={setIsMount}
                                                                getOptionDisabled={(option) => evaluateCoord(option.value, 1)}
                                                                getOptionLabel={({ label, value }, v) =>
                                                                    evaluateCoord(value) ? <Stack direction="row" spacing={1} alignItems="center"><LocationOffIcon /><div>{label}</div></Stack> : label
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <AutoComplete2
                                                                name={"idReturnDP"}
                                                                formik={formik}
                                                                label={__(`form.idReturnDP.label`)}
                                                                placeholder={__(`form.idReturnDP.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                                options={deliveryPoints}
                                                                reload={getDataDelivery}
                                                                loading={get(deliveryPoint, "isLoading")}
                                                                setIsMount={setIsMount}
                                                                getOptionDisabled={(option) => evaluateCoord(option.value, 1)}
                                                                getOptionLabel={({ label, value }, v) =>
                                                                    evaluateCoord(value) ? <Stack direction="row" spacing={1} alignItems="center"><LocationOffIcon /><div>{label}</div></Stack> : label
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography component="h5" variant="h5" color="text.lite">{__(`labels.3`)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <AutoComplete
                                                                name={"idTransport"}
                                                                formik={formik}
                                                                label={__(`form.idTransport.label`)}
                                                                placeholder={__(`form.idTransport.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(put, "isLoading", false) || get(put, "isLoading", false)}
                                                                options={transports}
                                                                reload={getDataTransport}
                                                                loading={get(transport, "isLoading")}
                                                                setIsMount={setIsMount}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <InputText
                                                                formik={formik}
                                                                name="comments"
                                                                label={__(`form.comments.label`)}
                                                                placeholder={__(`form.comments.placeholder`)}
                                                                disabled={get(current, "isLoading", false) || get(post, "isLoading", false) || get(put, "isLoading", false)}
                                                                multiline
                                                                rows={3}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 500 }}>
                                                {getCoords(get(formik, "values.idStartDP"), get(formik, "values.idReturnDP"))?.length > 1 &&
                                                    <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                        <Typography component="h4" variant="h4">{__(`labels.4`)}{km && ` (${get(km, "distance.text")})`}</Typography>
                                                    </Stack>
                                                }
                                                {
                                                    getCoords(get(formik, "values.idStartDP"), get(formik, "values.idReturnDP"))?.length > 1
                                                        ? <MapToNew coords={map(getCoords(get(formik, "values.idStartDP"), get(formik, "values.idReturnDP")), ({ location: { lat, lng } }) => ({ lat, lng }))} getKm={setKm} __={__} />
                                                        : <MapOne position={getCoords(get(formik, "values.idStartDP"), get(formik, "values.idReturnDP"))[0]?.location} __={__} disableInput />
                                                }
                                            </Paper>
                                            <Paper className='mt-4 p-4 flex h-full flex-col' sx={{ height: 500 }}>
                                                <Stack className='p-4' direction="row" spacing={2} justifyContent="space-between">
                                                    <Typography component="h4" variant="h4">{__(`labels.5`)}</Typography>
                                                </Stack>
                                                <Graf />
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    )}
                <Notification __={__} showNoti={showNoti} setShowNoti={closeNoti} />
                <Alert
                    title={get(alert, "title")}
                    subtitle={get(alert, "subtitle")}
                    btn1={{ func: get(alert, "func") }}
                    btn2={{}}
                    type={get(alert, "type")}
                    openAlert={get(alert, "open")}
                    closeAlert={closeAlert}
                />
            </div>
        </Layout>
    )
}

export default NewEdit