import {
    GET_TRANSPORT_BRAND_LOADING,
    GET_TRANSPORT_BRAND_SUCCESS,
    GET_TRANSPORT_BRAND_REJECT,
} from "../../actions/transportBrand/getAll";

import {
    GET_TRANSPORT_BRAND_ID_LOADING,
    GET_TRANSPORT_BRAND_ID_SUCCESS,
    GET_TRANSPORT_BRAND_ID_REJECT,
} from "../../actions/transportBrand/getId";

import {
    POST_TRANSPORT_BRAND_LOADING,
    POST_TRANSPORT_BRAND_SUCCESS,
    POST_TRANSPORT_BRAND_REJECT,
    POST_TRANSPORT_BRAND_RESET,
} from "../../actions/transportBrand/post";

import {
    PUT_TRANSPORT_BRAND_LOADING,
    PUT_TRANSPORT_BRAND_SUCCESS,
    PUT_TRANSPORT_BRAND_REJECT,
    PUT_TRANSPORT_BRAND_RESET,
} from "../../actions/transportBrand/put";

import {
    DELETE_TRANSPORT_BRAND_LOADING,
    DELETE_TRANSPORT_BRAND_SUCCESS,
    DELETE_TRANSPORT_BRAND_REJECT,
    DELETE_TRANSPORT_BRAND_RESET,
} from "../../actions/transportBrand/delete";

const stateInit = {
    isLoading: false,
    isSuccess: false,
    isReject: false,
    error: {},
    data: [],
    detail: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    post: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    put: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
    delete: {
        isLoading: false,
        isSuccess: false,
        isReject: false,
        error: {},
        data: {},
    },
}

const transportBrandReducer = (state = stateInit, action) => {
    switch (action.type) {
        // ------------- GET ALL --------------------
        case GET_TRANSPORT_BRAND_LOADING: return {
            ...state,
            isLoading: true,
            isSuccess: false,
            isReject: false,
            data: [],
            error: {},
        }
        case GET_TRANSPORT_BRAND_SUCCESS: return {
            ...state,
            isLoading: false,
            isSuccess: true,
            isReject: false,
            error: {},
            ...action.payload,
        }
        case GET_TRANSPORT_BRAND_REJECT: return {
            ...state,
            isLoading: false,
            isSuccess: false,
            isReject: true,
            data: [],
            error: action.payload?.response?.data
        }

        // ------------- GET ID ID --------------------
        case GET_TRANSPORT_BRAND_ID_LOADING: return {
            ...state,
            detail: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case GET_TRANSPORT_BRAND_ID_SUCCESS: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case GET_TRANSPORT_BRAND_ID_REJECT: return {
            ...state,
            detail: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }

        // ------------- POST --------------------
        case POST_TRANSPORT_BRAND_LOADING: return {
            ...state,
            post: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case POST_TRANSPORT_BRAND_SUCCESS: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case POST_TRANSPORT_BRAND_REJECT: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case POST_TRANSPORT_BRAND_RESET: return {
            ...state,
            post: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- PUT --------------------
        case PUT_TRANSPORT_BRAND_LOADING: return {
            ...state,
            put: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case PUT_TRANSPORT_BRAND_SUCCESS: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case PUT_TRANSPORT_BRAND_REJECT: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case PUT_TRANSPORT_BRAND_RESET: return {
            ...state,
            put: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        // ------------- DELETE --------------------
        case DELETE_TRANSPORT_BRAND_LOADING: return {
            ...state,
            delete: {
                isLoading: true,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }
        case DELETE_TRANSPORT_BRAND_SUCCESS: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: true,
                isReject: false,
                error: {},
                ...action.payload,
            }
        }
        case DELETE_TRANSPORT_BRAND_REJECT: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: true,
                data: {},
                error: action.payload?.response?.data
            }
        }
        case DELETE_TRANSPORT_BRAND_RESET: return {
            ...state,
            delete: {
                isLoading: false,
                isSuccess: false,
                isReject: false,
                data: {},
                error: {},
            }
        }

        default: return state;
    }
}

export default transportBrandReducer