import React from 'react';
import { SvgIcon } from '@mui/material'

const TruckDron = (props) => (
    <SvgIcon viewBox="0 0 417 373" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ width: "417px", height: "373px" }}>
        <path d="M49.5025 193.092C50.0377 193.402 50.0377 193.909 49.5025 194.219C48.9672 194.529 48.0939 194.529 47.5587 194.219C47.0234 193.909 47.0234 193.402 47.5587 193.092C48.0939 192.782 48.9672 192.782 49.5025 193.092Z" fill="#00283B" />
        <path d="M54.5869 190.161C55.1222 190.471 55.1222 190.978 54.5869 191.288C54.0517 191.598 53.1784 191.598 52.6431 191.288C52.1079 190.978 52.1079 190.471 52.6431 190.161C53.1784 189.851 54.0517 189.851 54.5869 190.161Z" fill="#00283B" />
        <path d="M59.6577 187.216C60.193 187.526 60.193 188.033 59.6577 188.343C59.1225 188.653 58.2492 188.653 57.7139 188.343C57.1787 188.033 57.1787 187.526 57.7139 187.216C58.2492 186.906 59.1225 186.906 59.6577 187.216Z" fill="#00283B" />
        <path d="M64.7285 184.285C65.2638 184.595 65.2638 185.102 64.7285 185.412C64.1933 185.722 63.32 185.722 62.7847 185.412C62.2495 185.102 62.2495 184.595 62.7847 184.285C63.32 183.975 64.1933 183.975 64.7285 184.285Z" fill="#00283B" />
        <path d="M69.8135 181.354C70.3487 181.664 70.3487 182.171 69.8135 182.481C69.2782 182.791 68.4049 182.791 67.8697 182.481C67.3344 182.171 67.3344 181.664 67.8697 181.354C68.4049 181.044 69.2782 181.044 69.8135 181.354Z" fill="#00283B" />
        <path d="M39.3467 193.092C39.8819 193.402 39.8819 193.909 39.3467 194.219C38.8114 194.529 37.9381 194.529 37.4029 194.219C36.8677 193.909 36.8677 193.402 37.4029 193.092C37.9381 192.782 38.8114 192.782 39.3467 193.092Z" fill="#00283B" />
        <path d="M44.4317 190.161C44.9669 190.471 44.9669 190.978 44.4317 191.288C43.8964 191.598 43.0231 191.598 42.4879 191.288C41.9526 190.978 41.9526 190.471 42.4879 190.161C43.0231 189.851 43.8964 189.851 44.4317 190.161Z" fill="#00283B" />
        <path d="M49.5025 187.216C50.0377 187.526 50.0377 188.033 49.5025 188.343C48.9672 188.653 48.0939 188.653 47.5587 188.343C47.0234 188.033 47.0234 187.526 47.5587 187.216C48.0939 186.906 48.9672 186.906 49.5025 187.216Z" fill="#00283B" />
        <path d="M54.5869 184.285C55.1222 184.595 55.1222 185.102 54.5869 185.412C54.0517 185.722 53.1784 185.722 52.6431 185.412C52.1079 185.102 52.1079 184.595 52.6431 184.285C53.1784 183.975 54.0517 183.975 54.5869 184.285Z" fill="#00283B" />
        <path d="M59.6577 181.354C60.193 181.664 60.193 182.171 59.6577 182.481C59.1225 182.791 58.2492 182.791 57.7139 182.481C57.1787 182.171 57.1787 181.664 57.7139 181.354C58.2492 181.044 59.1225 181.044 59.6577 181.354Z" fill="#00283B" />
        <path d="M64.7285 178.423C65.2638 178.733 65.2638 179.24 64.7285 179.55C64.1933 179.86 63.32 179.86 62.7847 179.55C62.2495 179.24 62.2495 178.733 62.7847 178.423C63.32 178.113 64.1933 178.113 64.7285 178.423Z" fill="#00283B" />
        <path d="M69.8135 175.492C70.3487 175.802 70.3487 176.31 69.8135 176.62C69.2782 176.93 68.4049 176.93 67.8697 176.62C67.3344 176.31 67.3344 175.802 67.8697 175.492C68.4049 175.182 69.2782 175.182 69.8135 175.492Z" fill="#00283B" />
        <path d="M34.2759 190.161C34.8111 190.471 34.8111 190.978 34.2759 191.288C33.7406 191.598 32.8673 191.598 32.3321 191.288C31.7969 190.978 31.7969 190.471 32.3321 190.161C32.8673 189.851 33.7406 189.851 34.2759 190.161Z" fill="#00283B" />
        <path d="M39.3467 187.216C39.8819 187.526 39.8819 188.033 39.3467 188.343C38.8114 188.653 37.9381 188.653 37.4029 188.343C36.8677 188.033 36.8677 187.526 37.4029 187.216C37.9381 186.906 38.8114 186.906 39.3467 187.216Z" fill="#00283B" />
        <path d="M29.1909 187.216C29.7262 187.526 29.7262 188.033 29.1909 188.343C28.6557 188.653 27.7824 188.653 27.2471 188.343C26.7119 188.033 26.7119 187.526 27.2471 187.216C27.7824 186.906 28.6557 186.906 29.1909 187.216Z" fill="#00283B" />
        <path d="M34.2759 184.285C34.8111 184.595 34.8111 185.102 34.2759 185.412C33.7406 185.722 32.8673 185.722 32.3321 185.412C31.7969 185.102 31.7969 184.595 32.3321 184.285C32.8673 183.975 33.7406 183.975 34.2759 184.285Z" fill="#00283B" />
        <path d="M24.0781 184.257C24.6134 184.567 24.6134 185.074 24.0781 185.384C23.5429 185.694 22.6696 185.694 22.1343 185.384C21.5991 185.074 21.5991 184.567 22.1343 184.257C22.6696 183.947 23.5429 183.947 24.0781 184.257Z" fill="#00283B" />
        <path d="M29.1489 181.326C29.6842 181.636 29.6842 182.143 29.1489 182.453C28.6137 182.763 27.7404 182.763 27.2051 182.453C26.6699 182.143 26.6699 181.636 27.2051 181.326C27.7404 181.016 28.6137 181.016 29.1489 181.326Z" fill="#00283B" />
        <path d="M18.9932 181.326C19.5284 181.636 19.5284 182.143 18.9932 182.453C18.4579 182.763 17.5846 182.763 17.0494 182.453C16.5141 182.143 16.5141 181.636 17.0494 181.326C17.5846 181.016 18.4579 181.016 18.9932 181.326Z" fill="#00283B" />
        <path d="M24.0781 178.395C24.6134 178.705 24.6134 179.212 24.0781 179.522C23.5429 179.832 22.6696 179.832 22.1343 179.522C21.5991 179.212 21.5991 178.705 22.1343 178.395C22.6696 178.085 23.5429 178.085 24.0781 178.395Z" fill="#00283B" />
        <path d="M18.9932 175.464C19.5284 175.774 19.5284 176.281 18.9932 176.591C18.4579 176.901 17.5846 176.901 17.0494 176.591C16.5141 176.281 16.5141 175.774 17.0494 175.464C17.5846 175.154 18.4579 175.154 18.9932 175.464Z" fill="#00283B" />
        <path d="M43.2484 186.511L21.0356 173.688V148.043L43.2484 160.866V186.511Z" fill="#B0E2FA" />
        <path d="M65.4471 148.043L43.2484 160.866L21.0356 148.043L43.2484 135.22L65.4471 148.043Z" fill="#B0E2FA" />
        <path d="M65.4473 155.483L45.4882 166.882L23.2896 154.046L43.2486 142.66L65.4473 155.483Z" fill="#F5FCFF" />
        <path d="M43.2485 134.459L21.0498 147.282L20.4019 147.691V174.069L42.6005 186.892L43.2485 187.272L43.8964 186.892L66.0951 174.069V147.663L43.2344 134.459H43.2485ZM43.2485 135.967L64.1513 148.043L53.6576 154.102L43.2485 160.119L39.9666 158.231L22.3456 148.057L43.2485 135.981V135.967ZM21.6977 173.308V149.17L42.6005 161.246V185.384L21.6977 173.308Z" fill="#00283B" />
        <path d="M37.8962 178.832L34.5016 176.873C33.9523 176.563 33.5156 175.718 33.5156 174.999V171.434C33.5156 170.716 33.9523 170.377 34.5016 170.687L37.8962 172.646C38.4455 172.956 38.8822 173.802 38.8822 174.52V178.085C38.8822 178.804 38.4455 179.142 37.8962 178.832Z" fill="#00283B" />
        <path d="M149.791 335.818C150.326 336.128 150.326 336.636 149.791 336.946C149.256 337.256 148.382 337.256 147.847 336.946C147.312 336.636 147.312 336.128 147.847 335.818C148.382 335.508 149.256 335.508 149.791 335.818Z" fill="#00283B" />
        <path d="M144.72 332.888C145.255 333.198 145.255 333.705 144.72 334.015C144.185 334.325 143.312 334.325 142.776 334.015C142.241 333.705 142.241 333.198 142.776 332.888C143.312 332.578 144.185 332.578 144.72 332.888Z" fill="#00283B" />
        <path d="M139.635 329.957C140.171 330.267 140.171 330.774 139.635 331.084C139.1 331.394 138.227 331.394 137.691 331.084C137.156 330.774 137.156 330.267 137.691 329.957C138.227 329.647 139.1 329.647 139.635 329.957Z" fill="#00283B" />
        <path d="M134.564 327.025C135.1 327.335 135.1 327.843 134.564 328.153C134.029 328.463 133.156 328.463 132.621 328.153C132.085 327.843 132.085 327.335 132.621 327.025C133.156 326.715 134.029 326.715 134.564 327.025Z" fill="#00283B" />
        <path d="M160.144 342.329C159.608 342.639 158.735 342.639 158.2 342.329C157.665 342.019 157.665 341.511 158.2 341.201C158.735 340.891 159.608 340.891 160.144 341.201C160.679 341.511 160.679 342.019 160.144 342.329C160.679 342.019 159.608 342.639 160.144 342.329Z" fill="#00283B" />
        <path d="M155.073 338.284C155.608 338.594 155.608 339.102 155.073 339.412C154.538 339.722 153.664 339.722 153.129 339.412C152.594 339.102 152.594 338.594 153.129 338.284C153.664 337.974 154.538 337.974 155.073 338.284Z" fill="#00283B" />
        <path d="M154.89 332.93C155.425 333.24 155.425 333.747 154.89 334.057C154.354 334.367 153.481 334.367 152.946 334.057C152.411 333.747 152.411 333.24 152.946 332.93C153.481 332.62 154.354 332.62 154.89 332.93Z" fill="#00283B" />
        <path d="M159.96 329.985C160.496 330.295 160.496 330.802 159.96 331.112C159.425 331.422 158.552 331.422 158.017 331.112C157.481 330.802 157.481 330.295 158.017 329.985C158.552 329.675 159.425 329.675 159.96 329.985Z" fill="#00283B" />
        <path d="M165.045 327.054C165.581 327.364 165.581 327.871 165.045 328.181C164.51 328.491 163.637 328.491 163.102 328.181C162.566 327.871 162.566 327.364 163.102 327.054C163.637 326.744 164.51 326.744 165.045 327.054Z" fill="#00283B" />
        <path d="M170.116 324.123C170.651 324.433 170.651 324.94 170.116 325.25C169.581 325.56 168.708 325.56 168.172 325.25C167.637 324.94 167.637 324.433 168.172 324.123C168.708 323.813 169.581 323.813 170.116 324.123Z" fill="#00283B" />
        <path d="M175.187 321.192C175.722 321.502 175.722 322.009 175.187 322.319C174.652 322.629 173.778 322.629 173.243 322.319C172.708 322.009 172.708 321.502 173.243 321.192C173.778 320.882 174.652 320.882 175.187 321.192Z" fill="#00283B" />
        <path d="M147.861 329.999C148.396 329.689 149.269 329.689 149.805 329.999C150.34 330.309 150.34 330.816 149.805 331.126C149.269 331.436 148.396 331.436 147.861 331.126C147.326 330.816 147.326 330.309 147.861 329.999Z" fill="#00283B" />
        <path d="M154.89 327.054C155.425 327.364 155.425 327.871 154.89 328.181C154.354 328.491 153.481 328.491 152.946 328.181C152.411 327.871 152.411 327.364 152.946 327.054C153.481 326.744 154.354 326.744 154.89 327.054Z" fill="#00283B" />
        <path d="M159.96 324.123C160.496 324.433 160.496 324.94 159.96 325.25C159.425 325.56 158.552 325.56 158.017 325.25C157.481 324.94 157.481 324.433 158.017 324.123C158.552 323.813 159.425 323.813 159.96 324.123Z" fill="#00283B" />
        <path d="M165.045 321.192C165.581 321.502 165.581 322.009 165.045 322.319C164.51 322.629 163.637 322.629 163.102 322.319C162.566 322.009 162.566 321.502 163.102 321.192C163.637 320.882 164.51 320.882 165.045 321.192Z" fill="#00283B" />
        <path d="M170.116 318.261C170.651 318.571 170.651 319.079 170.116 319.389C169.581 319.699 168.708 319.699 168.172 319.389C167.637 319.079 167.637 318.571 168.172 318.261C168.708 317.951 169.581 317.951 170.116 318.261Z" fill="#00283B" />
        <path d="M175.187 316.457C174.652 316.767 173.778 316.767 173.243 316.457C172.708 316.147 172.708 315.64 173.243 315.33C173.778 315.02 174.652 315.02 175.187 315.33C175.722 315.64 175.722 316.147 175.187 316.457Z" fill="#00283B" />
        <path d="M142.791 327.068C143.326 326.758 144.199 326.758 144.734 327.068C145.27 327.378 145.27 327.885 144.734 328.195C144.199 328.505 143.326 328.505 142.791 328.195C142.255 327.885 142.255 327.378 142.791 327.068Z" fill="#00283B" />
        <path d="M149.805 324.123C150.34 324.433 150.34 324.94 149.805 325.25C149.269 325.56 148.396 325.56 147.861 325.25C147.326 324.94 147.326 324.433 147.861 324.123C148.396 323.813 149.269 323.813 149.805 324.123Z" fill="#00283B" />
        <path d="M154.89 321.192C155.425 321.502 155.425 322.009 154.89 322.319C154.354 322.629 153.481 322.629 152.946 322.319C152.411 322.009 152.411 321.502 152.946 321.192C153.481 320.882 154.354 320.882 154.89 321.192Z" fill="#00283B" />
        <path d="M159.96 318.261C160.496 318.571 160.496 319.079 159.96 319.389C159.425 319.699 158.552 319.699 158.017 319.389C157.481 319.079 157.481 318.571 158.017 318.261C158.552 317.951 159.425 317.951 159.96 318.261Z" fill="#00283B" />
        <path d="M165.045 315.33C165.581 315.64 165.581 316.147 165.045 316.457C164.51 316.767 163.637 316.767 163.102 316.457C162.566 316.147 162.566 315.64 163.102 315.33C163.637 315.02 164.51 315.02 165.045 315.33Z" fill="#00283B" />
        <path d="M170.116 312.399C170.651 312.709 170.651 313.217 170.116 313.527C169.581 313.837 168.708 313.837 168.172 313.527C167.637 313.217 167.637 312.709 168.172 312.399C168.708 312.089 169.581 312.089 170.116 312.399Z" fill="#00283B" />
        <path d="M175.187 310.581C174.652 310.891 173.778 310.891 173.243 310.581C172.708 310.271 172.708 309.764 173.243 309.454C173.778 309.144 174.652 309.144 175.187 309.454C175.722 309.764 175.722 310.271 175.187 310.581C175.722 310.271 174.652 310.891 175.187 310.581Z" fill="#00283B" />
        <path d="M137.706 324.137C138.241 323.827 139.114 323.827 139.649 324.137C140.185 324.447 140.185 324.955 139.649 325.265C139.114 325.575 138.241 325.575 137.706 325.265C137.17 324.955 137.17 324.447 137.706 324.137Z" fill="#00283B" />
        <path d="M144.734 321.192C145.27 321.502 145.27 322.009 144.734 322.319C144.199 322.629 143.326 322.629 142.791 322.319C142.255 322.009 142.255 321.502 142.791 321.192C143.326 320.882 144.199 320.882 144.734 321.192Z" fill="#00283B" />
        <path d="M149.805 318.261C150.34 318.571 150.34 319.079 149.805 319.389C149.269 319.699 148.396 319.699 147.861 319.389C147.326 319.079 147.326 318.571 147.861 318.261C148.396 317.951 149.269 317.951 149.805 318.261Z" fill="#00283B" />
        <path d="M154.89 315.33C155.425 315.64 155.425 316.147 154.89 316.457C154.354 316.767 153.481 316.767 152.946 316.457C152.411 316.147 152.411 315.64 152.946 315.33C153.481 315.02 154.354 315.02 154.89 315.33Z" fill="#00283B" />
        <path d="M159.96 312.399C160.496 312.709 160.496 313.217 159.96 313.527C159.425 313.837 158.552 313.837 158.017 313.527C157.481 313.217 157.481 312.709 158.017 312.399C158.552 312.089 159.425 312.089 159.96 312.399Z" fill="#00283B" />
        <path d="M165.045 309.468C165.581 309.778 165.581 310.286 165.045 310.596C164.51 310.906 163.637 310.906 163.102 310.596C162.566 310.286 162.566 309.778 163.102 309.468C163.637 309.158 164.51 309.158 165.045 309.468Z" fill="#00283B" />
        <path d="M170.116 306.538C170.651 306.848 170.651 307.355 170.116 307.665C169.581 307.975 168.708 307.975 168.172 307.665C167.637 307.355 167.637 306.848 168.172 306.538C168.708 306.228 169.581 306.228 170.116 306.538Z" fill="#00283B" />
        <path d="M175.187 303.607C175.722 303.917 175.722 304.424 175.187 304.734C174.652 305.044 173.778 305.044 173.243 304.734C172.708 304.424 172.708 303.917 173.243 303.607C173.778 303.297 174.652 303.297 175.187 303.607Z" fill="#00283B" />
        <path d="M132.635 321.192C133.17 320.882 134.043 320.882 134.579 321.192C135.114 321.502 135.114 322.009 134.579 322.319C134.043 322.629 133.17 322.629 132.635 322.319C132.1 322.009 132.1 321.502 132.635 321.192Z" fill="#00283B" />
        <path d="M139.649 318.261C140.185 318.571 140.185 319.079 139.649 319.389C139.114 319.699 138.241 319.699 137.706 319.389C137.17 319.079 137.17 318.571 137.706 318.261C138.241 317.951 139.114 317.951 139.649 318.261Z" fill="#00283B" />
        <path d="M165.045 303.607C165.581 303.917 165.581 304.424 165.045 304.734C164.51 305.044 163.637 305.044 163.102 304.734C162.566 304.424 164.51 303.297 165.045 303.607Z" fill="#00283B" />
        <path d="M210.978 335.41C211.513 335.72 211.513 336.227 210.978 336.537C210.443 336.847 209.569 336.847 209.034 336.537C208.499 336.227 208.499 335.72 209.034 335.41C209.569 335.1 210.443 335.1 210.978 335.41Z" fill="#00283B" />
        <path d="M216.049 332.479C216.584 332.789 216.584 333.296 216.049 333.606C215.514 333.916 214.64 333.916 214.105 333.606C213.57 333.296 213.57 332.789 214.105 332.479C214.64 332.169 215.514 332.169 216.049 332.479Z" fill="#00283B" />
        <path d="M221.12 329.548C221.655 329.858 221.655 330.365 221.12 330.675C220.584 330.985 219.711 330.985 219.176 330.675C218.641 330.365 218.641 329.858 219.176 329.548C219.711 329.238 220.584 329.238 221.12 329.548Z" fill="#00283B" />
        <path d="M226.205 326.617C226.74 326.927 226.74 327.435 226.205 327.745C225.669 328.055 224.796 328.055 224.261 327.745C223.726 327.435 223.726 326.927 224.261 326.617C224.796 326.307 225.669 326.307 226.205 326.617Z" fill="#00283B" />
        <path d="M231.275 323.686C231.811 323.996 231.811 324.503 231.275 324.813C230.74 325.123 229.867 325.123 229.332 324.813C228.796 324.503 228.796 323.996 229.332 323.686C229.867 323.376 230.74 323.376 231.275 323.686Z" fill="#00283B" />
        <path d="M236.36 320.755C236.895 321.065 236.895 321.573 236.36 321.883C235.825 322.193 234.951 322.193 234.416 321.883C233.881 321.573 233.881 321.065 234.416 320.755C234.951 320.445 235.825 320.445 236.36 320.755Z" fill="#00283B" />
        <path d="M241.431 317.824C241.966 318.134 241.966 318.642 241.431 318.952C240.895 319.262 240.022 319.262 239.487 318.952C238.952 318.642 238.952 318.134 239.487 317.824C240.022 317.514 240.895 317.514 241.431 317.824Z" fill="#00283B" />
        <path d="M246.516 314.894C247.051 315.204 247.051 315.711 246.516 316.021C245.98 316.331 245.107 316.331 244.572 316.021C244.037 315.711 244.037 315.204 244.572 314.894C245.107 314.584 245.98 314.584 246.516 314.894Z" fill="#00283B" />
        <path d="M200.822 335.41C201.358 335.72 201.358 336.227 200.822 336.537C200.287 336.847 199.414 336.847 198.878 336.537C198.343 336.227 198.343 335.72 198.878 335.41C199.414 335.1 200.287 335.1 200.822 335.41Z" fill="#00283B" />
        <path d="M205.893 332.479C206.428 332.789 206.428 333.296 205.893 333.606C205.358 333.916 204.485 333.916 203.949 333.606C203.414 333.296 203.414 332.789 203.949 332.479C204.485 332.169 205.358 332.169 205.893 332.479Z" fill="#00283B" />
        <path d="M210.978 329.548C211.513 329.858 211.513 330.365 210.978 330.675C210.443 330.985 209.569 330.985 209.034 330.675C208.499 330.365 208.499 329.858 209.034 329.548C209.569 329.238 210.443 329.238 210.978 329.548Z" fill="#00283B" />
        <path d="M216.049 326.617C216.584 326.927 216.584 327.435 216.049 327.745C215.514 328.055 214.64 328.055 214.105 327.745C213.57 327.435 213.57 326.927 214.105 326.617C214.64 326.307 215.514 326.307 216.049 326.617Z" fill="#00283B" />
        <path d="M221.12 323.686C221.655 323.996 221.655 324.503 221.12 324.813C220.584 325.123 219.711 325.123 219.176 324.813C218.641 324.503 218.641 323.996 219.176 323.686C219.711 323.376 220.584 323.376 221.12 323.686Z" fill="#00283B" />
        <path d="M226.205 320.755C226.74 321.065 226.74 321.573 226.205 321.883C225.669 322.193 224.796 322.193 224.261 321.883C223.726 321.573 223.726 321.065 224.261 320.755C224.796 320.445 225.669 320.445 226.205 320.755Z" fill="#00283B" />
        <path d="M231.275 317.824C231.811 318.134 231.811 318.642 231.275 318.952C230.74 319.262 229.867 319.262 229.332 318.952C228.796 318.642 228.796 318.134 229.332 317.824C229.867 317.514 230.74 317.514 231.275 317.824Z" fill="#00283B" />
        <path d="M236.36 314.894C236.895 315.204 236.895 315.711 236.36 316.021C235.825 316.331 234.951 316.331 234.416 316.021C233.881 315.711 233.881 315.204 234.416 314.894C234.951 314.584 235.825 314.584 236.36 314.894Z" fill="#00283B" />
        <path d="M241.431 311.962C241.966 312.272 241.966 312.78 241.431 313.09C240.895 313.4 240.022 313.4 239.487 313.09C238.952 312.78 238.952 312.272 239.487 311.962C240.022 311.652 240.895 311.652 241.431 311.962Z" fill="#00283B" />
        <path d="M246.516 309.032C247.051 309.342 247.051 309.849 246.516 310.159C245.98 310.469 245.107 310.469 244.572 310.159C244.037 309.849 244.037 309.342 244.572 309.032C245.107 308.722 245.98 308.722 246.516 309.032Z" fill="#00283B" />
        <path d="M195.695 332.451C196.231 332.761 196.231 333.268 195.695 333.578C195.16 333.888 194.287 333.888 193.752 333.578C193.216 333.268 193.216 332.761 193.752 332.451C194.287 332.141 195.16 332.141 195.695 332.451Z" fill="#00283B" />
        <path d="M200.78 329.52C201.316 329.83 201.316 330.337 200.78 330.647C200.245 330.957 199.372 330.957 198.836 330.647C198.301 330.337 198.301 329.83 198.836 329.52C199.372 329.21 200.245 329.21 200.78 329.52Z" fill="#00283B" />
        <path d="M205.851 326.589C206.386 326.899 206.386 327.406 205.851 327.716C205.316 328.026 204.443 328.026 203.907 327.716C203.372 327.406 203.372 326.899 203.907 326.589C204.443 326.279 205.316 326.279 205.851 326.589Z" fill="#00283B" />
        <path d="M210.936 323.658C211.471 323.968 211.471 324.475 210.936 324.785C210.401 325.095 209.528 325.095 208.992 324.785C208.457 324.475 208.457 323.968 208.992 323.658C209.528 323.348 210.401 323.348 210.936 323.658Z" fill="#00283B" />
        <path d="M216.006 320.727C216.542 321.037 216.542 321.544 216.006 321.854C215.471 322.164 214.598 322.164 214.063 321.854C213.527 321.544 213.527 321.037 214.063 320.727C214.598 320.417 215.471 320.417 216.006 320.727Z" fill="#00283B" />
        <path d="M221.077 317.796C221.612 318.106 221.612 318.613 221.077 318.923C220.542 319.233 219.669 319.233 219.133 318.923C218.598 318.613 218.598 318.106 219.133 317.796C219.669 317.486 220.542 317.486 221.077 317.796Z" fill="#00283B" />
        <path d="M226.162 314.865C226.697 315.175 226.697 315.683 226.162 315.993C225.627 316.303 224.754 316.303 224.218 315.993C223.683 315.683 223.683 315.175 224.218 314.865C224.754 314.555 225.627 314.555 226.162 314.865Z" fill="#00283B" />
        <path d="M231.233 311.934C231.768 312.244 231.768 312.751 231.233 313.061C230.698 313.371 229.824 313.371 229.289 313.061C228.754 312.751 228.754 312.244 229.289 311.934C229.824 311.624 230.698 311.624 231.233 311.934Z" fill="#00283B" />
        <path d="M236.318 308.989C236.853 309.299 236.853 309.807 236.318 310.117C235.783 310.427 234.909 310.427 234.374 310.117C233.839 309.807 233.839 309.299 234.374 308.989C234.909 308.679 235.783 308.679 236.318 308.989Z" fill="#00283B" />
        <path d="M241.389 306.059C241.924 306.369 241.924 306.876 241.389 307.186C240.853 307.496 239.98 307.496 239.445 307.186C238.91 306.876 238.91 306.369 239.445 306.059C239.98 305.749 240.853 305.749 241.389 306.059Z" fill="#00283B" />
        <path d="M168.37 341.257C168.905 340.947 169.778 340.947 170.313 341.257C170.849 341.567 170.849 342.075 170.313 342.385C169.778 342.695 168.905 342.695 168.37 342.385C167.834 342.075 167.834 341.567 168.37 341.257Z" fill="#00283B" />
        <path d="M175.398 338.313C175.934 338.623 175.934 339.13 175.398 339.44C174.863 339.75 173.99 339.75 173.455 339.44C172.919 339.13 172.919 338.623 173.455 338.313C173.99 338.003 174.863 338.003 175.398 338.313Z" fill="#00283B" />
        <path d="M180.469 335.381C181.004 335.691 181.004 336.199 180.469 336.509C179.934 336.819 179.06 336.819 178.525 336.509C177.99 336.199 177.99 335.691 178.525 335.381C179.06 335.071 179.934 335.071 180.469 335.381Z" fill="#00283B" />
        <path d="M185.54 332.451C186.075 332.761 186.075 333.268 185.54 333.578C185.004 333.888 184.131 333.888 183.596 333.578C183.061 333.268 183.061 332.761 183.596 332.451C184.131 332.141 185.004 332.141 185.54 332.451Z" fill="#00283B" />
        <path d="M190.625 329.52C191.16 329.83 191.16 330.337 190.625 330.647C190.089 330.957 189.216 330.957 188.681 330.647C188.145 330.337 188.145 329.83 188.681 329.52C189.216 329.21 190.089 329.21 190.625 329.52Z" fill="#00283B" />
        <path d="M195.695 326.589C196.231 326.899 196.231 327.406 195.695 327.716C195.16 328.026 194.287 328.026 193.752 327.716C193.216 327.406 193.216 326.899 193.752 326.589C194.287 326.279 195.16 326.279 195.695 326.589Z" fill="#00283B" />
        <path d="M200.78 323.658C201.316 323.968 201.316 324.475 200.78 324.785C200.245 325.095 199.372 325.095 198.836 324.785C198.301 324.475 198.301 323.968 198.836 323.658C199.372 323.348 200.245 323.348 200.78 323.658Z" fill="#00283B" />
        <path d="M205.851 320.727C206.386 321.037 206.386 321.544 205.851 321.854C205.316 322.164 204.443 322.164 203.907 321.854C203.372 321.544 203.372 321.037 203.907 320.727C204.443 320.417 205.316 320.417 205.851 320.727Z" fill="#00283B" />
        <path d="M210.936 317.796C211.471 318.106 211.471 318.613 210.936 318.923C210.401 319.233 209.528 319.233 208.992 318.923C208.457 318.613 208.457 318.106 208.992 317.796C209.528 317.486 210.401 317.486 210.936 317.796Z" fill="#00283B" />
        <path d="M216.006 314.865C216.542 315.175 216.542 315.683 216.006 315.993C215.471 316.303 214.598 316.303 214.063 315.993C213.527 315.683 213.527 315.175 214.063 314.865C214.598 314.555 215.471 314.555 216.006 314.865Z" fill="#00283B" />
        <path d="M221.077 311.934C221.612 312.244 221.612 312.751 221.077 313.061C220.542 313.371 219.669 313.371 219.133 313.061C218.598 312.751 218.598 312.244 219.133 311.934C219.669 311.624 220.542 311.624 221.077 311.934Z" fill="#00283B" />
        <path d="M226.162 308.989C226.697 309.299 226.697 309.807 226.162 310.117C225.627 310.427 224.754 310.427 224.218 310.117C223.683 309.807 223.683 309.299 224.218 308.989C224.754 308.679 225.627 308.679 226.162 308.989Z" fill="#00283B" />
        <path d="M231.233 306.059C231.768 306.369 231.768 306.876 231.233 307.186C230.698 307.496 229.824 307.496 229.289 307.186C228.754 306.876 228.754 306.369 229.289 306.059C229.824 305.749 230.698 305.749 231.233 306.059Z" fill="#00283B" />
        <path d="M236.318 303.128C236.853 303.438 236.853 303.945 236.318 304.255C235.783 304.565 234.909 304.565 234.374 304.255C233.839 303.945 233.839 303.438 234.374 303.128C234.909 302.818 235.783 302.818 236.318 303.128Z" fill="#00283B" />
        <path d="M163.285 338.327C163.82 338.017 164.693 338.017 165.229 338.327C165.764 338.637 165.764 339.144 165.229 339.454C164.693 339.764 163.82 339.764 163.285 339.454C162.749 339.144 162.749 338.637 163.285 338.327Z" fill="#00283B" />
        <path d="M170.313 335.381C170.849 335.691 170.849 336.199 170.313 336.509C169.778 336.819 168.905 336.819 168.37 336.509C167.834 336.199 167.834 335.691 168.37 335.381C168.905 335.071 169.778 335.071 170.313 335.381Z" fill="#00283B" />
        <path d="M175.398 332.451C175.934 332.761 175.934 333.268 175.398 333.578C174.863 333.888 173.99 333.888 173.455 333.578C172.919 333.268 172.919 332.761 173.455 332.451C173.99 332.141 174.863 332.141 175.398 332.451Z" fill="#00283B" />
        <path d="M180.469 329.52C181.004 329.83 181.004 330.337 180.469 330.647C179.934 330.957 179.06 330.957 178.525 330.647C177.99 330.337 177.99 329.83 178.525 329.52C179.06 329.21 179.934 329.21 180.469 329.52Z" fill="#00283B" />
        <path d="M185.54 326.589C186.075 326.899 186.075 327.406 185.54 327.716C185.004 328.026 184.131 328.026 183.596 327.716C183.061 327.406 183.061 326.899 183.596 326.589C184.131 326.279 185.004 326.279 185.54 326.589Z" fill="#00283B" />
        <path d="M190.625 323.658C191.16 323.968 191.16 324.475 190.625 324.785C190.089 325.095 189.216 325.095 188.681 324.785C188.145 324.475 188.145 323.968 188.681 323.658C189.216 323.348 190.089 323.348 190.625 323.658Z" fill="#00283B" />
        <path d="M195.695 320.727C196.231 321.037 196.231 321.544 195.695 321.854C195.16 322.164 194.287 322.164 193.752 321.854C193.216 321.544 193.216 321.037 193.752 320.727C194.287 320.417 195.16 320.417 195.695 320.727Z" fill="#00283B" />
        <path d="M200.78 317.796C201.316 318.106 201.316 318.613 200.78 318.923C200.245 319.233 199.372 319.233 198.836 318.923C198.301 318.613 198.301 318.106 198.836 317.796C199.372 317.486 200.245 317.486 200.78 317.796Z" fill="#00283B" />
        <path d="M158.214 335.396C158.749 335.086 159.622 335.086 160.158 335.396C160.693 335.706 160.693 336.213 160.158 336.523C159.622 336.833 158.749 336.833 158.214 336.523C157.679 336.213 157.679 335.706 158.214 335.396Z" fill="#00283B" />
        <path d="M165.243 332.451C165.778 332.761 165.778 333.268 165.243 333.578C164.707 333.888 163.834 333.888 163.299 333.578C162.764 333.268 162.764 332.761 163.299 332.451C163.834 332.141 164.707 332.141 165.243 332.451Z" fill="#00283B" />
        <path d="M170.313 329.52C170.849 329.83 170.849 330.337 170.313 330.647C169.778 330.957 168.905 330.957 168.37 330.647C167.834 330.337 167.834 329.83 168.37 329.52C168.905 329.21 169.778 329.21 170.313 329.52Z" fill="#00283B" />
        <path d="M175.398 326.589C175.934 326.899 175.934 327.406 175.398 327.716C174.863 328.026 173.99 328.026 173.455 327.716C172.919 327.406 172.919 326.899 173.455 326.589C173.99 326.279 174.863 326.279 175.398 326.589Z" fill="#00283B" />
        <path d="M180.469 323.658C181.004 323.968 181.004 324.475 180.469 324.785C179.934 325.095 179.06 325.095 178.525 324.785C177.99 324.475 177.99 323.968 178.525 323.658C179.06 323.348 179.934 323.348 180.469 323.658Z" fill="#00283B" />
        <path d="M185.54 320.727C186.075 321.037 186.075 321.544 185.54 321.854C185.004 322.164 184.131 322.164 183.596 321.854C183.061 321.544 183.061 321.037 183.596 320.727C184.131 320.417 185.004 320.417 185.54 320.727Z" fill="#00283B" />
        <path d="M190.625 317.796C191.16 318.106 191.16 318.613 190.625 318.923C190.089 319.233 189.216 319.233 188.681 318.923C188.145 318.613 188.145 318.106 188.681 317.796C189.216 317.486 190.089 317.486 190.625 317.796Z" fill="#00283B" />
        <path d="M195.695 315.979C195.16 316.289 194.287 316.289 193.752 315.979C193.216 315.669 193.216 315.161 193.752 314.851C194.287 314.541 196.231 315.669 195.695 315.979Z" fill="#00283B" />
        <path d="M68.5596 371.003C69.0948 371.313 69.0948 371.821 68.5596 372.131C68.0243 372.441 67.151 372.441 66.6158 372.131C66.0805 371.821 66.0805 371.313 66.6158 371.003C67.151 370.693 68.0243 370.693 68.5596 371.003Z" fill="#00283B" />
        <path d="M73.6445 368.072C74.1798 368.382 74.1798 368.89 73.6445 369.2C73.1093 369.51 72.236 369.51 71.7008 369.2C71.1655 368.89 71.1655 368.382 71.7008 368.072C72.236 367.762 73.1093 367.762 73.6445 368.072Z" fill="#00283B" />
        <path d="M78.7153 365.142C79.2506 365.452 79.2506 365.959 78.7153 366.269C78.1801 366.579 77.3068 366.579 76.7716 366.269C76.2363 365.959 76.2363 365.452 76.7716 365.142C77.3068 364.832 78.1801 364.832 78.7153 365.142Z" fill="#00283B" />
        <path d="M83.8003 362.211C84.3356 362.521 84.3356 363.028 83.8003 363.338C83.2651 363.648 82.3918 363.648 81.8565 363.338C81.3213 363.028 81.3213 362.521 81.8565 362.211C82.3918 361.901 83.2651 361.901 83.8003 362.211Z" fill="#00283B" />
        <path d="M88.8711 359.28C89.4064 359.59 89.4064 360.097 88.8711 360.407C88.3359 360.717 87.4626 360.717 86.9273 360.407C86.3921 360.097 86.3921 359.59 86.9273 359.28C87.4626 358.97 88.3359 358.97 88.8711 359.28Z" fill="#00283B" />
        <path d="M93.9561 356.349C94.4913 356.659 94.4913 357.166 93.9561 357.476C93.4208 357.786 92.5475 357.786 92.0123 357.476C91.477 357.166 91.477 356.659 92.0123 356.349C92.5475 356.039 93.4208 356.039 93.9561 356.349Z" fill="#00283B" />
        <path d="M99.0264 353.404C99.5616 353.714 99.5616 354.221 99.0264 354.531C98.4911 354.841 97.6178 354.841 97.0826 354.531C96.5473 354.221 96.5473 353.714 97.0826 353.404C97.6178 353.094 98.4911 353.094 99.0264 353.404Z" fill="#00283B" />
        <path d="M104.097 350.473C104.632 350.783 104.632 351.29 104.097 351.6C103.562 351.91 102.689 351.91 102.153 351.6C101.618 351.29 101.618 350.783 102.153 350.473C102.689 350.163 103.562 350.163 104.097 350.473Z" fill="#00283B" />
        <path d="M109.182 347.542C109.717 347.852 109.717 348.359 109.182 348.669C108.647 348.979 107.774 348.979 107.238 348.669C106.703 348.359 106.703 347.852 107.238 347.542C107.774 347.232 108.647 347.232 109.182 347.542Z" fill="#00283B" />
        <path d="M114.253 344.611C114.788 344.921 114.788 345.429 114.253 345.739C113.718 346.049 112.844 346.049 112.309 345.739C111.774 345.429 111.774 344.921 112.309 344.611C112.844 344.301 113.718 344.301 114.253 344.611Z" fill="#00283B" />
        <path d="M119.338 341.68C119.873 341.99 119.873 342.498 119.338 342.808C118.803 343.118 117.929 343.118 117.394 342.808C116.859 342.498 116.859 341.99 117.394 341.68C117.929 341.37 118.803 341.37 119.338 341.68Z" fill="#00283B" />
        <path d="M124.409 338.75C124.944 339.06 124.944 339.567 124.409 339.877C123.873 340.187 123 340.187 122.465 339.877C121.93 339.567 121.93 339.06 122.465 338.75C123 338.44 123.873 338.44 124.409 338.75Z" fill="#00283B" />
        <path d="M129.48 335.818C130.015 336.128 130.015 336.636 129.48 336.946C128.944 337.256 128.071 337.256 127.536 336.946C127 336.636 127 336.128 127.536 335.818C128.071 335.508 128.944 335.508 129.48 335.818Z" fill="#00283B" />
        <path d="M134.564 332.888C135.1 333.198 135.1 333.705 134.564 334.015C134.029 334.325 133.156 334.325 132.621 334.015C132.085 333.705 132.085 333.198 132.621 332.888C133.156 332.578 134.029 332.578 134.564 332.888Z" fill="#00283B" />
        <path d="M58.4185 371.003C58.9537 371.313 58.9537 371.821 58.4185 372.131C57.8832 372.441 57.0099 372.441 56.4747 372.131C55.9394 371.821 55.9394 371.313 56.4747 371.003C57.0099 370.693 57.8832 370.693 58.4185 371.003Z" fill="#00283B" />
        <path d="M63.4888 368.072C64.024 368.382 64.024 368.89 63.4888 369.2C62.9535 369.51 62.0802 369.51 61.545 369.2C61.0097 368.89 61.0097 368.382 61.545 368.072C62.0802 367.762 62.9535 367.762 63.4888 368.072Z" fill="#00283B" />
        <path d="M68.5596 365.142C69.0948 365.452 69.0948 365.959 68.5596 366.269C68.0243 366.579 67.151 366.579 66.6158 366.269C66.0805 365.959 66.0805 365.452 66.6158 365.142C67.151 364.832 68.0243 364.832 68.5596 365.142Z" fill="#00283B" />
        <path d="M73.6445 362.211C74.1798 362.521 74.1798 363.028 73.6445 363.338C73.1093 363.648 72.236 363.648 71.7008 363.338C71.1655 363.028 71.1655 362.521 71.7008 362.211C72.236 361.901 73.1093 361.901 73.6445 362.211Z" fill="#00283B" />
        <path d="M78.7153 359.28C79.2506 359.59 79.2506 360.097 78.7153 360.407C78.1801 360.717 77.3068 360.717 76.7716 360.407C76.2363 360.097 76.2363 359.59 76.7716 359.28C77.3068 358.97 78.1801 358.97 78.7153 359.28Z" fill="#00283B" />
        <path d="M83.8003 356.349C84.3356 356.659 84.3356 357.166 83.8003 357.476C83.2651 357.786 82.3918 357.786 81.8565 357.476C81.3213 357.166 81.3213 356.659 81.8565 356.349C82.3918 356.039 83.2651 356.039 83.8003 356.349Z" fill="#00283B" />
        <path d="M88.8711 353.404C89.4064 353.714 89.4064 354.221 88.8711 354.531C88.3359 354.841 87.4626 354.841 86.9273 354.531C86.3921 354.221 86.3921 353.714 86.9273 353.404C87.4626 353.094 88.3359 353.094 88.8711 353.404Z" fill="#00283B" />
        <path d="M93.9561 350.473C94.4913 350.783 94.4913 351.29 93.9561 351.6C93.4208 351.91 92.5475 351.91 92.0123 351.6C91.477 351.29 91.477 350.783 92.0123 350.473C92.5475 350.163 93.4208 350.163 93.9561 350.473Z" fill="#00283B" />
        <path d="M99.0264 347.542C99.5616 347.852 99.5616 348.359 99.0264 348.669C98.4911 348.979 97.6178 348.979 97.0826 348.669C96.5473 348.359 96.5473 347.852 97.0826 347.542C97.6178 347.232 98.4911 347.232 99.0264 347.542Z" fill="#00283B" />
        <path d="M104.097 344.611C104.632 344.921 104.632 345.429 104.097 345.739C103.562 346.049 102.689 346.049 102.153 345.739C101.618 345.429 101.618 344.921 102.153 344.611C102.689 344.301 103.562 344.301 104.097 344.611Z" fill="#00283B" />
        <path d="M109.182 341.68C109.717 341.99 109.717 342.498 109.182 342.808C108.647 343.118 107.774 343.118 107.238 342.808C106.703 342.498 106.703 341.99 107.238 341.68C107.774 341.37 108.647 341.37 109.182 341.68Z" fill="#00283B" />
        <path d="M114.253 338.75C114.788 339.06 114.788 339.567 114.253 339.877C113.718 340.187 112.844 340.187 112.309 339.877C111.774 339.567 111.774 339.06 112.309 338.75C112.844 338.44 113.718 338.44 114.253 338.75Z" fill="#00283B" />
        <path d="M119.338 335.818C119.873 336.128 119.873 336.636 119.338 336.946C118.803 337.256 117.929 337.256 117.394 336.946C116.859 336.636 116.859 336.128 117.394 335.818C117.929 335.508 118.803 335.508 119.338 335.818Z" fill="#00283B" />
        <path d="M124.409 332.888C124.944 333.198 124.944 333.705 124.409 334.015C123.873 334.325 123 334.325 122.465 334.015C121.93 333.705 121.93 333.198 122.465 332.888C123 332.578 123.873 332.578 124.409 332.888Z" fill="#00283B" />
        <path d="M129.494 329.957C130.029 330.267 130.029 330.774 129.494 331.084C128.958 331.394 128.085 331.394 127.55 331.084C127.015 330.774 127.015 330.267 127.55 329.957C128.085 329.647 128.958 329.647 129.494 329.957Z" fill="#00283B" />
        <path d="M53.3335 368.072C53.8688 368.382 53.8688 368.89 53.3335 369.2C52.7983 369.51 51.925 369.51 51.3897 369.2C50.8545 368.89 50.8545 368.382 51.3897 368.072C51.925 367.762 52.7983 367.762 53.3335 368.072Z" fill="#00283B" />
        <path d="M58.4185 365.142C58.9537 365.452 58.9537 365.959 58.4185 366.269C57.8832 366.579 57.0099 366.579 56.4747 366.269C55.9394 365.959 55.9394 365.452 56.4747 365.142C57.0099 364.832 57.8832 364.832 58.4185 365.142Z" fill="#00283B" />
        <path d="M63.4888 362.211C64.024 362.521 64.024 363.028 63.4888 363.338C62.9535 363.648 62.0802 363.648 61.545 363.338C61.0097 363.028 61.0097 362.521 61.545 362.211C62.0802 361.901 62.9535 361.901 63.4888 362.211Z" fill="#00283B" />
        <path d="M68.5596 359.28C69.0948 359.59 69.0948 360.097 68.5596 360.407C68.0243 360.717 67.151 360.717 66.6158 360.407C66.0805 360.097 66.0805 359.59 66.6158 359.28C67.151 358.97 68.0243 358.97 68.5596 359.28Z" fill="#00283B" />
        <path d="M73.6445 356.349C74.1798 356.659 74.1798 357.166 73.6445 357.476C73.1093 357.786 72.236 357.786 71.7008 357.476C71.1655 357.166 71.1655 356.659 71.7008 356.349C72.236 356.039 73.1093 356.039 73.6445 356.349Z" fill="#00283B" />
        <path d="M78.7153 353.404C79.2506 353.714 79.2506 354.221 78.7153 354.531C78.1801 354.841 77.3068 354.841 76.7716 354.531C76.2363 354.221 76.2363 353.714 76.7716 353.404C77.3068 353.094 78.1801 353.094 78.7153 353.404Z" fill="#00283B" />
        <path d="M99.0264 341.68C99.5616 341.99 99.5616 342.498 99.0264 342.808C98.4911 343.118 97.6178 343.118 97.0826 342.808C96.5473 342.498 96.5473 341.99 97.0826 341.68C97.6178 341.37 98.4911 341.37 99.0264 341.68Z" fill="#00283B" />
        <path d="M104.097 338.75C104.632 339.06 104.632 339.567 104.097 339.877C103.562 340.187 102.689 340.187 102.153 339.877C101.618 339.567 101.618 339.06 102.153 338.75C102.689 338.44 103.562 338.44 104.097 338.75Z" fill="#00283B" />
        <path d="M109.182 335.818C109.717 336.128 109.717 336.636 109.182 336.946C108.647 337.256 107.774 337.256 107.238 336.946C106.703 336.636 106.703 336.128 107.238 335.818C107.774 335.508 108.647 335.508 109.182 335.818Z" fill="#00283B" />
        <path d="M114.253 332.888C114.788 333.198 114.788 333.705 114.253 334.015C113.718 334.325 112.844 334.325 112.309 334.015C111.774 333.705 111.774 333.198 112.309 332.888C112.844 332.578 113.718 332.578 114.253 332.888Z" fill="#00283B" />
        <path d="M119.338 329.957C119.873 330.267 119.873 330.774 119.338 331.084C118.803 331.394 117.929 331.394 117.394 331.084C116.859 330.774 116.859 330.267 117.394 329.957C117.929 329.647 118.803 329.647 119.338 329.957Z" fill="#00283B" />
        <path d="M124.409 327.026C124.944 327.336 124.944 327.843 124.409 328.153C123.873 328.463 123 328.463 122.465 328.153C121.93 327.843 121.93 327.336 122.465 327.026C123 326.716 123.873 326.716 124.409 327.026Z" fill="#00283B" />
        <path d="M129.494 324.095C130.029 324.405 130.029 324.912 129.494 325.222C128.958 325.532 128.085 325.532 127.55 325.222C127.015 324.912 127.015 324.405 127.55 324.095C128.085 323.785 128.958 323.785 129.494 324.095Z" fill="#00283B" />
        <path d="M48.2202 365.113C48.7555 365.423 48.7555 365.931 48.2202 366.241C47.685 366.551 46.8117 366.551 46.2764 366.241C45.7412 365.931 45.7412 365.423 46.2764 365.113C46.8117 364.803 47.685 364.803 48.2202 365.113Z" fill="#00283B" />
        <path d="M53.291 362.183C53.8263 362.493 53.8263 363 53.291 363.31C52.7558 363.62 51.8825 363.62 51.3472 363.31C50.812 363 50.812 362.493 51.3472 362.183C51.8825 361.873 52.7558 361.873 53.291 362.183Z" fill="#00283B" />
        <path d="M43.1358 362.183C43.671 362.493 43.671 363 43.1358 363.31C42.6005 363.62 41.7272 363.62 41.192 363.31C40.6567 363 40.6567 362.493 41.192 362.183C41.7272 361.873 42.6005 361.873 43.1358 362.183Z" fill="#00283B" />
        <path d="M48.2202 359.237C48.7555 359.547 48.7555 360.055 48.2202 360.365C47.685 360.675 46.8117 360.675 46.2764 360.365C45.7412 360.055 45.7412 359.547 46.2764 359.237C46.8117 358.927 47.685 358.927 48.2202 359.237Z" fill="#00283B" />
        <path d="M38.065 359.237C38.6002 359.547 38.6002 360.055 38.065 360.365C37.5297 360.675 36.6564 360.675 36.1212 360.365C35.5859 360.055 35.5859 359.547 36.1212 359.237C36.6564 358.927 37.5297 358.927 38.065 359.237Z" fill="#00283B" />
        <path d="M43.1358 356.307C43.671 356.617 43.671 357.124 43.1358 357.434C42.6005 357.744 41.7272 357.744 41.192 357.434C40.6567 357.124 40.6567 356.617 41.192 356.307C41.7272 355.997 42.6005 355.997 43.1358 356.307Z" fill="#00283B" />
        <path d="M32.98 356.307C33.5152 356.617 33.5152 357.124 32.98 357.434C32.4447 357.744 31.5714 357.744 31.0362 357.434C30.501 357.124 30.501 356.617 31.0362 356.307C31.5714 355.997 32.4447 355.997 32.98 356.307Z" fill="#00283B" />
        <path d="M38.065 353.376C38.6002 353.686 38.6002 354.193 38.065 354.503C37.5297 354.813 36.6564 354.813 36.1212 354.503C35.5859 354.193 35.5859 353.686 36.1212 353.376C36.6564 353.066 37.5297 353.066 38.065 353.376Z" fill="#00283B" />
        <path d="M27.9092 353.376C28.4444 353.686 28.4444 354.193 27.9092 354.503C27.3739 354.813 26.5006 354.813 25.9654 354.503C25.4302 354.193 25.4302 353.686 25.9654 353.376C26.5006 353.066 27.3739 353.066 27.9092 353.376Z" fill="#00283B" />
        <path d="M32.98 350.445C33.5152 350.755 33.5152 351.262 32.98 351.572C32.4447 351.882 31.5714 351.882 31.0362 351.572C30.501 351.262 30.501 350.755 31.0362 350.445C31.5714 350.135 32.4447 350.135 32.98 350.445Z" fill="#00283B" />
        <path d="M22.8242 350.445C23.3595 350.755 23.3595 351.262 22.8242 351.572C22.289 351.882 21.4157 351.882 20.8804 351.572C20.3452 351.262 20.3452 350.755 20.8804 350.445C21.4157 350.135 22.289 350.135 22.8242 350.445Z" fill="#00283B" />
        <path d="M27.9092 347.514C28.4444 347.824 28.4444 348.331 27.9092 348.641C27.3739 348.951 26.5006 348.951 25.9654 348.641C25.4302 348.331 25.4302 347.824 25.9654 347.514C26.5006 347.204 27.3739 347.204 27.9092 347.514Z" fill="#00283B" />
        <path d="M17.7534 347.514C18.2887 347.824 18.2887 348.331 17.7534 348.641C17.2182 348.951 16.3449 348.951 15.8096 348.641C15.2744 348.331 15.2744 347.824 15.8096 347.514C16.3449 347.204 17.2182 347.204 17.7534 347.514Z" fill="#00283B" />
        <path d="M22.8242 344.583C23.3595 344.893 23.3595 345.4 22.8242 345.71C22.289 346.02 21.4157 346.02 20.8804 345.71C20.3452 345.4 20.3452 344.893 20.8804 344.583C21.4157 344.273 22.289 344.273 22.8242 344.583Z" fill="#00283B" />
        <path d="M12.6826 344.583C13.2179 344.893 13.2179 345.4 12.6826 345.71C12.1474 346.02 11.2741 346.02 10.7388 345.71C10.2036 345.4 10.2036 344.893 10.7388 344.583C11.2741 344.273 12.1474 344.273 12.6826 344.583Z" fill="#00283B" />
        <path d="M17.7534 341.652C18.2887 341.962 18.2887 342.469 17.7534 342.779C17.2182 343.089 16.3449 343.089 15.8096 342.779C15.2744 342.469 15.2744 341.962 15.8096 341.652C16.3449 341.342 17.2182 341.342 17.7534 341.652Z" fill="#00283B" />
        <path d="M7.55568 341.624C8.09093 341.934 8.09093 342.441 7.55568 342.751C7.02043 343.061 6.14713 343.061 5.61188 342.751C5.07664 342.441 5.07664 341.934 5.61188 341.624C6.14713 341.314 7.02043 341.314 7.55568 341.624Z" fill="#00283B" />
        <path d="M12.6265 338.693C13.1617 339.003 13.1617 339.51 12.6265 339.82C12.0912 340.13 11.2179 340.13 10.6827 339.82C10.1474 339.51 10.1474 339.003 10.6827 338.693C11.2179 338.383 12.0912 338.383 12.6265 338.693Z" fill="#00283B" />
        <path d="M2.48488 338.693C3.02013 339.003 3.02013 339.51 2.48488 339.82C1.94963 340.13 1.07633 340.13 0.541084 339.82C0.00583656 339.51 0.00583656 339.003 0.541084 338.693C1.07633 338.383 1.94963 338.383 2.48488 338.693Z" fill="#00283B" />
        <path d="M7.55568 335.762C8.09093 336.072 8.09093 336.58 7.55568 336.89C7.02043 337.2 6.14713 337.2 5.61188 336.89C5.07664 336.58 5.07664 336.072 5.61188 335.762C6.14713 335.452 7.02043 335.452 7.55568 335.762Z" fill="#00283B" />
        <path d="M2.48488 332.817C3.02013 333.127 3.02013 333.634 2.48488 333.944C1.94963 334.254 1.07633 334.254 0.541084 333.944C0.00583656 333.634 0.00583656 333.127 0.541084 332.817C1.07633 332.507 1.94963 332.507 2.48488 332.817Z" fill="#00283B" />
        <path d="M239.29 277.243C239.825 276.933 240.698 276.933 241.233 277.243C241.769 277.553 241.769 278.06 241.233 278.37C240.698 278.68 239.825 278.68 239.29 278.37C238.754 278.06 238.754 277.553 239.29 277.243Z" fill="#00283B" />
        <path d="M246.304 274.298C246.839 274.608 246.839 275.115 246.304 275.425C245.769 275.735 244.896 275.735 244.36 275.425C243.825 275.115 243.825 274.608 244.36 274.298C244.896 273.988 245.769 273.988 246.304 274.298Z" fill="#00283B" />
        <path d="M251.389 271.367C251.924 271.677 251.924 272.184 251.389 272.494C250.854 272.804 249.981 272.804 249.445 272.494C248.91 272.184 248.91 271.677 249.445 271.367C249.981 271.057 250.854 271.057 251.389 271.367Z" fill="#00283B" />
        <path d="M256.46 268.436C256.995 268.746 256.995 269.253 256.46 269.563C255.925 269.873 255.051 269.873 254.516 269.563C253.981 269.253 253.981 268.746 254.516 268.436C255.051 268.126 255.925 268.126 256.46 268.436Z" fill="#00283B" />
        <path d="M261.545 265.505C262.08 265.815 262.08 266.322 261.545 266.632C261.01 266.942 260.136 266.942 259.601 266.632C259.066 266.322 259.066 265.815 259.601 265.505C260.136 265.195 261.01 265.195 261.545 265.505Z" fill="#00283B" />
        <path d="M266.616 262.574C267.151 262.884 267.151 263.392 266.616 263.702C266.08 264.012 265.207 264.012 264.672 263.702C264.137 263.392 264.137 262.884 264.672 262.574C265.207 262.264 266.08 262.264 266.616 262.574Z" fill="#00283B" />
        <path d="M271.686 259.643C272.221 259.953 272.221 260.46 271.686 260.77C271.151 261.08 270.278 261.08 269.742 260.77C269.207 260.46 269.207 259.953 269.742 259.643C270.278 259.333 271.151 259.333 271.686 259.643Z" fill="#00283B" />
        <path d="M234.205 274.312C234.74 274.002 235.613 274.002 236.148 274.312C236.684 274.622 236.684 275.129 236.148 275.439C235.613 275.749 234.74 275.749 234.205 275.439C233.669 275.129 233.669 274.622 234.205 274.312Z" fill="#00283B" />
        <path d="M241.233 271.367C241.769 271.677 241.769 272.184 241.233 272.494C240.698 272.804 239.825 272.804 239.29 272.494C238.754 272.184 238.754 271.677 239.29 271.367C239.825 271.057 240.698 271.057 241.233 271.367Z" fill="#00283B" />
        <path d="M246.304 268.436C246.839 268.746 246.839 269.253 246.304 269.563C245.769 269.873 244.896 269.873 244.36 269.563C243.825 269.253 243.825 268.746 244.36 268.436C244.896 268.126 245.769 268.126 246.304 268.436Z" fill="#00283B" />
        <path d="M251.389 265.505C251.924 265.815 251.924 266.322 251.389 266.632C250.854 266.942 249.981 266.942 249.445 266.632C248.91 266.322 248.91 265.815 249.445 265.505C249.981 265.195 250.854 265.195 251.389 265.505Z" fill="#00283B" />
        <path d="M256.46 262.574C256.995 262.884 256.995 263.392 256.46 263.702C255.925 264.012 255.051 264.012 254.516 263.702C253.981 263.392 253.981 262.884 254.516 262.574C255.051 262.264 255.925 262.264 256.46 262.574Z" fill="#00283B" />
        <path d="M261.545 259.643C262.08 259.953 262.08 260.46 261.545 260.77C261.01 261.08 260.136 261.08 259.601 260.77C259.066 260.46 259.066 259.953 259.601 259.643C260.136 259.333 261.01 259.333 261.545 259.643Z" fill="#00283B" />
        <path d="M266.616 256.712C267.151 257.022 267.151 257.53 266.616 257.84C266.08 258.15 265.207 258.15 264.672 257.84C264.137 257.53 264.137 257.022 264.672 256.712C265.207 256.402 266.08 256.402 266.616 256.712Z" fill="#00283B" />
        <path d="M271.686 253.781C272.221 254.091 272.221 254.599 271.686 254.909C271.151 255.219 270.278 255.219 269.742 254.909C269.207 254.599 269.207 254.091 269.742 253.781C270.278 253.471 271.151 253.471 271.686 253.781Z" fill="#00283B" />
        <path d="M229.134 271.381C229.67 271.071 230.543 271.071 231.078 271.381C231.613 271.691 231.613 272.198 231.078 272.508C230.543 272.818 228.599 271.705 229.134 271.395V271.381Z" fill="#00283B" />
        <path d="M236.148 268.436C236.684 268.746 236.684 269.253 236.148 269.563C235.613 269.873 234.74 269.873 234.205 269.563C233.669 269.253 233.669 268.746 234.205 268.436C234.74 268.126 235.613 268.126 236.148 268.436Z" fill="#00283B" />
        <path d="M241.233 265.505C241.769 265.815 241.769 266.322 241.233 266.632C240.698 266.942 239.825 266.942 239.29 266.632C238.754 266.322 238.754 265.815 239.29 265.505C239.825 265.195 240.698 265.195 241.233 265.505Z" fill="#00283B" />
        <path d="M246.304 262.574C246.839 262.884 246.839 263.392 246.304 263.702C245.769 264.012 244.896 264.012 244.36 263.702C243.825 263.392 243.825 262.884 244.36 262.574C244.896 262.264 245.769 262.264 246.304 262.574Z" fill="#00283B" />
        <path d="M251.389 259.643C251.924 259.953 251.924 260.46 251.389 260.77C250.854 261.08 249.981 261.08 249.445 260.77C248.91 260.46 248.91 259.953 249.445 259.643C249.981 259.333 250.854 259.333 251.389 259.643Z" fill="#00283B" />
        <path d="M256.46 256.712C256.995 257.022 256.995 257.53 256.46 257.84C255.925 258.15 255.051 258.15 254.516 257.84C253.981 257.53 253.981 257.022 254.516 256.712C255.051 256.402 255.925 256.402 256.46 256.712Z" fill="#00283B" />
        <path d="M261.545 253.781C262.08 254.091 262.08 254.599 261.545 254.909C261.01 255.219 260.136 255.219 259.601 254.909C259.066 254.599 259.066 254.091 259.601 253.781C260.136 253.471 261.01 253.471 261.545 253.781Z" fill="#00283B" />
        <path d="M266.616 250.851C267.151 251.161 267.151 251.668 266.616 251.978C266.08 252.288 265.207 252.288 264.672 251.978C264.137 251.668 264.137 251.161 264.672 250.851C265.207 250.541 266.08 250.541 266.616 250.851Z" fill="#00283B" />
        <path d="M231.078 265.505C231.613 265.815 231.613 266.322 231.078 266.632C230.543 266.942 229.67 266.942 229.134 266.632C228.599 266.322 228.599 265.815 229.134 265.505C229.67 265.195 230.543 265.195 231.078 265.505Z" fill="#00283B" />
        <path d="M236.148 262.574C236.684 262.884 236.684 263.392 236.148 263.702C235.613 264.012 234.74 264.012 234.205 263.702C233.669 263.392 233.669 262.884 234.205 262.574C234.74 262.264 235.613 262.264 236.148 262.574Z" fill="#00283B" />
        <path d="M241.233 259.643C241.769 259.953 241.769 260.46 241.233 260.77C240.698 261.08 239.825 261.08 239.29 260.77C238.754 260.46 238.754 259.953 239.29 259.643C239.825 259.333 240.698 259.333 241.233 259.643Z" fill="#00283B" />
        <path d="M246.304 256.712C246.839 257.022 246.839 257.53 246.304 257.84C245.769 258.15 244.896 258.15 244.36 257.84C243.825 257.53 243.825 257.022 244.36 256.712C244.896 256.402 245.769 256.402 246.304 256.712Z" fill="#00283B" />
        <path d="M251.389 253.781C251.924 254.091 251.924 254.599 251.389 254.909C250.854 255.219 249.981 255.219 249.445 254.909C248.91 254.599 248.91 254.091 249.445 253.781C249.981 253.471 250.854 253.471 251.389 253.781Z" fill="#00283B" />
        <path d="M256.46 250.851C256.995 251.161 256.995 251.668 256.46 251.978C255.925 252.288 255.051 252.288 254.516 251.978C253.981 251.668 253.981 251.161 254.516 250.851C255.051 250.541 255.925 250.541 256.46 250.851Z" fill="#00283B" />
        <path d="M261.545 247.92C262.08 248.23 262.08 248.737 261.545 249.047C261.01 249.357 260.136 249.357 259.601 249.047C259.066 248.737 259.066 248.23 259.601 247.92C260.136 247.61 261.01 247.61 261.545 247.92Z" fill="#00283B" />
        <path d="M229.092 259.615C229.627 259.305 230.5 259.305 231.036 259.615C231.571 259.925 231.571 260.433 231.036 260.743C230.5 261.053 229.627 261.053 229.092 260.743C228.557 260.433 228.557 259.925 229.092 259.615Z" fill="#00283B" />
        <path d="M234.163 256.684C234.698 256.374 235.571 256.374 236.106 256.684C236.642 256.994 236.642 257.501 236.106 257.811C235.571 258.121 234.698 258.121 234.163 257.811C233.627 257.501 233.627 256.994 234.163 256.684C234.698 256.374 233.627 256.994 234.163 256.684Z" fill="#00283B" />
        <path d="M239.248 253.753C239.783 253.443 240.656 253.443 241.191 253.753C241.727 254.063 241.727 254.571 241.191 254.881C240.656 255.191 239.783 255.191 239.248 254.881C238.712 254.571 238.712 254.063 239.248 253.753C239.783 253.443 238.712 254.063 239.248 253.753Z" fill="#00283B" />
        <path d="M246.262 250.822C246.797 251.132 246.797 251.64 246.262 251.95C245.727 252.26 244.854 252.26 244.318 251.95C243.783 251.64 245.727 250.512 246.262 250.822Z" fill="#00283B" />
        <path d="M251.347 247.877C251.882 248.187 251.882 248.694 251.347 249.004C250.812 249.314 249.939 249.314 249.403 249.004C248.868 248.694 250.812 247.567 251.347 247.877Z" fill="#00283B" />
        <path d="M256.417 244.946C256.953 245.256 256.953 245.764 256.417 246.074C255.882 246.384 255.009 246.384 254.474 246.074C253.938 245.764 255.882 244.636 256.417 244.946Z" fill="#00283B" />
        <path d="M241.727 254.613L223.303 243.932L245.783 240.931L248.417 245.285L241.727 254.613Z" fill="#B0E2FA" />
        <path d="M241.318 255.501L221.57 243.58L246.065 240.311L249.093 245.313L241.318 255.501ZM225.035 244.27L241.248 254.303L247.741 245.256L245.487 241.536L225.035 244.27Z" fill="#00283B" />
        <path d="M225.303 278.243C225.303 278.243 220.965 278.285 216.26 275.312C211.556 272.353 209.161 270.873 207.767 269.126C206.372 267.379 215.19 252.146 215.19 252.146L221.613 242.1C221.613 242.1 231.585 245.651 236.064 250.413C240.544 255.176 230.515 268.407 230.515 268.407L225.303 278.229V278.243Z" fill="#F5FCFF" />
        <path d="M239.304 256.275C238.98 253.527 237.966 251.428 236.402 250.174C236.402 250.174 236.388 250.146 236.374 250.146C231.867 245.369 222.162 241.86 221.754 241.719L221.444 241.606L214.838 251.949C212.739 255.585 205.992 267.562 207.443 269.394C208.837 271.141 211.091 272.564 215.598 275.411L216.035 275.678C219.063 277.581 221.909 278.271 223.641 278.525C224.078 278.623 224.528 278.68 224.993 278.68C225.078 278.68 225.176 278.68 225.261 278.68C225.261 278.68 225.289 278.68 225.303 278.68H225.571V278.666C227.177 278.525 228.881 277.806 230.599 276.538C236.219 272.353 240.135 263.264 239.318 256.303L239.304 256.275ZM216.049 274.692C211.626 271.902 209.401 270.507 208.105 268.872C207.358 267.928 211.401 259.572 215.556 252.386L221.796 242.635C223.275 243.185 229.627 245.693 233.867 249.032C231.825 248.652 229.501 249.356 227.177 251.076C221.556 255.261 217.641 264.349 218.458 271.31C218.753 273.804 219.613 275.763 220.923 277.045C219.584 276.622 218.049 275.974 216.472 274.988L216.035 274.72L216.049 274.692Z" fill="#00283B" />
        <path d="M226.092 274.607C225.486 274.607 224.923 274.495 224.387 274.255C222.683 273.522 221.584 271.719 221.289 269.182C220.697 264.138 223.528 257.557 227.599 254.528C229.641 253.006 231.712 252.569 233.402 253.302C235.106 254.035 236.205 255.838 236.501 258.375C237.092 263.419 234.261 270 230.191 273.029C228.796 274.072 227.388 274.607 226.092 274.607ZM231.698 253.809C230.585 253.809 229.345 254.288 228.106 255.218C224.247 258.093 221.57 264.321 222.12 269.098C222.373 271.324 223.303 272.888 224.711 273.494C226.12 274.1 227.895 273.706 229.684 272.367C233.543 269.492 236.219 263.264 235.67 258.487C235.416 256.261 234.487 254.697 233.078 254.091C232.641 253.908 232.191 253.809 231.698 253.809Z" fill="#B0E2FA" />
        <path d="M226.092 274.593C225.134 274.593 224.247 274.312 223.5 273.72L222.768 273.029L223.725 272.973C224.782 272.903 225.937 272.423 227.064 271.578C230.923 268.703 233.599 262.475 233.05 257.698C232.839 255.895 232.191 254.528 231.163 253.739L230.388 253.147L231.388 252.992C234.149 252.823 236.092 254.88 236.501 258.389C237.092 263.433 234.261 270.014 230.191 273.043C228.796 274.072 227.388 274.608 226.092 274.608V274.593ZM224.993 273.593C226.345 274.044 227.993 273.621 229.684 272.367C233.543 269.493 236.219 263.264 235.67 258.488C235.374 255.965 234.205 254.303 232.515 253.908C233.233 254.838 233.712 256.092 233.881 257.6C234.473 262.644 231.641 269.225 227.571 272.254C226.726 272.888 225.852 273.339 224.993 273.593Z" fill="#B0E2FA" />
        <path d="M232.656 261.855C232.938 264.222 231.614 267.28 229.712 268.703C227.811 270.126 226.036 269.365 225.754 266.998C225.472 264.631 226.796 261.573 228.698 260.15C230.599 258.727 232.374 259.488 232.656 261.855Z" fill="#F5FCFF" />
        <path d="M271.081 220.541L121.606 306.678L54.7139 268.041L204.175 181.903L271.081 220.541Z" fill="#B0E2FA" />
        <path d="M271.335 225.853L239.065 244.495L234.712 243.664L221.204 251.189L215.866 257.896L121.874 312.005L54.9678 273.354L204.429 187.216L271.335 225.853Z" fill="#F5FCFF" />
        <path d="M121.606 307.341L53.5591 268.041L54.4183 267.548L204.161 181.241L204.443 181.41L271.63 220.217L121.592 307.341H121.606ZM55.8409 268.041L121.606 306.03L269.926 220.555L204.161 182.566L55.8409 268.041Z" fill="#00283B" />
        <path d="M271.081 220.541L130.663 301.451V313.315L193.203 277.102L200.288 278.722L213.302 271.184L215.613 264.096C217.232 258.502 220.88 251.766 228.895 247.13C235.515 243.312 238.952 245.862 240.741 249.427L241.727 254.585L255.77 246.341L261.292 237.436L271.081 231.828V220.527V220.541Z" fill="#00283B" />
        <path d="M130.085 314.316V301.127L130.367 300.958L271.644 220.203V232.166L261.686 237.859L256.164 246.764L241.304 255.486L240.191 249.61C239.22 247.694 237.924 246.482 236.374 246.017C234.431 245.439 232.022 245.975 229.177 247.609C221.035 252.316 217.598 259.249 216.148 264.251L213.767 271.564L200.372 279.328L193.287 277.708L130.085 314.302V314.316ZM131.226 301.789V312.343L193.104 276.524L200.189 278.144L212.823 270.817L215.063 263.927C216.556 258.755 220.134 251.541 228.613 246.637C231.74 244.834 234.459 244.256 236.712 244.932C238.6 245.496 240.135 246.933 241.262 249.174L241.318 249.329L242.149 253.697L255.376 245.933L260.897 237.027L270.532 231.518V221.527L131.255 301.789H131.226Z" fill="#00283B" />
        <path d="M271.081 228.84L262.926 233.547C262.01 234.068 260.869 233.42 260.869 232.363V231.334C260.869 230.221 261.461 229.192 262.419 228.643L271.081 223.641V228.84Z" fill="#B0E2FA" />
        <path d="M262.235 234.307C261.897 234.307 261.573 234.223 261.263 234.04C260.658 233.687 260.292 233.067 260.292 232.363V231.334C260.292 230.024 260.996 228.798 262.137 228.15L271.644 222.654V229.178L263.207 234.054C262.897 234.223 262.573 234.322 262.235 234.322V234.307ZM270.503 224.627L262.7 229.136C261.911 229.587 261.432 230.432 261.432 231.334V232.363C261.432 232.659 261.587 232.912 261.827 233.053C262.08 233.194 262.376 233.194 262.63 233.053L270.503 228.502V224.613V224.627Z" fill="#00283B" />
        <path d="M201.682 274.03C201.442 274.03 201.217 273.974 201.02 273.861C200.513 273.565 200.217 272.945 200.217 272.156V271.578C200.217 270.268 201.006 268.788 202.006 268.21L207.302 265.153C207.837 264.843 208.4 264.815 208.851 265.068C209.358 265.364 209.654 265.984 209.654 266.773V267.351C209.654 268.661 208.865 270.141 207.865 270.719L202.569 273.776C202.273 273.945 201.977 274.03 201.682 274.03ZM208.175 266.026C208.062 266.026 207.922 266.097 207.865 266.125L202.569 269.183C201.921 269.563 201.344 270.676 201.344 271.564V272.142C201.344 272.579 201.47 272.804 201.583 272.86C201.71 272.931 201.907 272.832 201.992 272.776L207.288 269.718C207.936 269.338 208.513 268.225 208.513 267.337V266.759C208.513 266.322 208.386 266.097 208.274 266.04C208.246 266.026 208.203 266.012 208.175 266.012V266.026Z" fill="#B0E2FA" />
        <path d="M261.953 222.091L142.41 290.629L142.424 211.748L259.362 144.704L261.953 165.29V222.091Z" fill="#00283B" />
        <path d="M79.6313 254.669L142.41 290.629L142.424 211.748L79.6313 175.802V254.669Z" fill="#F5FCFF" />
        <path d="M196.583 108.758L259.362 144.704L142.424 211.748L79.6313 175.802L196.583 108.758Z" fill="#B0E2FA" />
        <path d="M196.583 108.758L258.15 144.013L142.424 210.353V211.748L79.6313 175.802L196.583 108.758Z" fill="#B0E2FA" />
        <path d="M196.583 116.564L86.2095 179.832L142.425 212.016V210.607L251.573 148.043L196.583 116.564Z" fill="#F5FCFF" />
        <path d="M142.946 290.925L262.475 222.387V165.206L259.855 144.365L196.569 108.138L79.082 175.492V254.979L141.861 290.925L142.396 291.235L142.931 290.925H142.946ZM261.418 221.781L142.946 289.699V212.058L258.939 145.563L261.418 165.276V221.767V221.781ZM196.583 109.378L258.305 144.718L142.481 211.114L142.41 211.156L80.6878 175.816L196.569 109.392L196.583 109.378ZM80.1666 254.373V176.732L141.889 212.072V289.713L80.1666 254.373Z" fill="#00283B" />
        <path d="M138.438 290.136L133.621 287.346V216.525C133.621 215.651 134.564 215.102 135.325 215.538L136.931 216.468C137.874 217.004 138.452 218.018 138.452 219.103V290.136H138.438Z" fill="#B0E2FA" />
        <path d="M137.227 288.205L131.339 284.81V213.679C131.339 213.073 131.649 212.537 132.17 212.227C132.691 211.931 133.311 211.931 133.832 212.227L135.438 213.157C136.536 213.791 137.227 214.975 137.227 216.243V288.205ZM132.395 284.19L136.156 286.36V216.243C136.156 215.355 135.677 214.524 134.902 214.073L133.297 213.143C133.029 212.988 132.776 213.087 132.691 213.143C132.607 213.2 132.395 213.355 132.395 213.665V284.175V284.19Z" fill="#00283B" />
        <path d="M91.0125 263.039L86.1953 260.249V189.428C86.1953 188.555 87.139 188.005 87.8997 188.442L89.5054 189.372C90.4491 189.907 91.0266 190.922 91.0266 192.007V263.039H91.0125Z" fill="#B0E2FA" />
        <path d="M89.8013 261.109L83.9136 257.713V186.582C83.9136 185.976 84.2234 185.441 84.7446 185.131C85.2658 184.835 85.8855 184.835 86.4067 185.131L88.0124 186.061C89.1111 186.695 89.8013 187.878 89.8013 189.147V261.109ZM84.97 257.093L88.7308 259.263V189.147C88.7308 188.259 88.2519 187.427 87.4772 186.977L85.8715 186.047C85.6038 185.892 85.3503 185.99 85.2658 186.047C85.1813 186.103 84.97 186.258 84.97 186.568V257.079V257.093Z" fill="#00283B" />
        <path d="M103.661 333.128L77.7153 317.571L107.492 313.71V328.435L103.661 333.128Z" fill="#B0E2FA" />
        <path d="M103.788 333.874L76.0112 317.219L108.056 313.076V328.647L103.788 333.874ZM79.4199 317.923L103.534 332.381L106.929 328.224V314.358L79.4199 317.923Z" fill="#00283B" />
        <path d="M85.744 353.616C85.744 353.616 81.4057 353.658 76.7012 350.685C71.9966 347.726 69.6021 346.246 68.2076 344.499C66.8132 342.752 75.6307 327.519 75.6307 327.519L82.0536 317.473C82.0536 317.473 92.0261 321.024 96.5053 325.786C100.985 330.549 90.9557 343.78 90.9557 343.78L85.744 353.602V353.616Z" fill="#F5FCFF" />
        <path d="M99.745 331.648C99.421 328.9 98.4069 326.801 96.8434 325.547C96.8434 325.547 96.8293 325.519 96.8152 325.519C92.3079 320.742 82.603 317.233 82.1945 317.092L81.8846 316.979L75.2786 327.322C73.1798 330.958 66.4329 342.935 67.8837 344.767C69.2782 346.514 71.5318 347.937 76.0392 350.784L76.4758 351.051C79.5042 352.954 82.3495 353.644 84.082 353.898C84.5186 353.996 84.9694 354.053 85.4342 354.053C85.5187 354.053 85.6173 354.053 85.7018 354.053C85.7018 354.053 85.73 354.053 85.7441 354.053H86.0117V354.039C87.6174 353.898 89.3218 353.179 91.0402 351.911C96.6603 347.726 100.576 338.637 99.7591 331.676L99.745 331.648ZM76.4899 350.065C72.0671 347.275 69.8416 345.88 68.5457 344.245C67.7992 343.301 71.8417 334.945 75.9969 327.759L82.2368 318.008C83.7158 318.558 90.0683 321.066 94.308 324.405C92.2656 324.025 89.9415 324.729 87.6174 326.449C81.9973 330.634 78.0816 339.722 78.8985 346.683C79.1943 349.177 80.0535 351.136 81.3635 352.418C80.0254 351.995 78.4901 351.347 76.9125 350.361L76.4758 350.093L76.4899 350.065Z" fill="#00283B" />
        <path d="M86.5327 349.981C85.927 349.981 85.3636 349.868 84.8283 349.628C83.124 348.896 82.0253 347.092 81.7295 344.556C81.1379 339.511 83.9691 332.931 88.0398 329.901C90.0822 328.379 92.1528 327.942 93.843 328.675C95.5474 329.408 96.646 331.211 96.9418 333.734C97.5334 338.778 94.7022 345.359 90.6315 348.388C89.2371 349.431 87.8285 349.966 86.5327 349.966V349.981ZM92.1387 329.182C91.0259 329.182 89.7864 329.661 88.5469 330.591C84.6875 333.466 82.0112 339.694 82.5606 344.471C82.8141 346.697 83.7437 348.261 85.1523 348.867C86.5608 349.473 88.3356 349.079 90.1245 347.74C93.9839 344.866 96.6601 338.637 96.1108 333.861C95.8572 331.634 94.9276 330.07 93.5191 329.464C93.0824 329.281 92.6317 329.182 92.1387 329.182Z" fill="#B0E2FA" />
        <path d="M86.5327 349.967C85.5748 349.967 84.6875 349.685 83.9409 349.093L83.2085 348.402L84.1663 348.346C85.2227 348.276 86.3777 347.797 87.5046 346.951C91.364 344.077 94.0402 337.848 93.4909 333.072C93.2796 331.268 92.6317 329.901 91.6034 329.112L90.8287 328.52L91.8288 328.365C94.5895 328.196 96.5333 330.253 96.9418 333.762C97.5334 338.807 94.7022 345.387 90.6315 348.417C89.2371 349.445 87.8285 349.981 86.5327 349.981V349.967ZM85.434 348.966C86.7862 349.417 88.4342 348.994 90.1245 347.74C93.9839 344.866 96.6601 338.637 96.1108 333.861C95.815 331.338 94.6459 329.676 92.9556 329.281C93.674 330.211 94.1529 331.465 94.3219 332.973C94.9135 338.017 92.0823 344.598 88.0116 347.627C87.1665 348.262 86.2932 348.712 85.434 348.966Z" fill="#B0E2FA" />
        <path d="M93.0969 337.228C93.3786 339.595 92.0546 342.653 90.153 344.076C88.2515 345.499 86.4767 344.739 86.195 342.371C85.9133 340.004 87.2373 336.946 89.1389 335.523C91.0404 334.1 92.8152 334.861 93.0969 337.228Z" fill="#F5FCFF" />
        <path d="M74.8984 213.707C74.8984 213.707 74.7576 213.623 74.6872 213.581L74.5604 213.538C73.9125 213.285 73.0673 213.355 72.1518 213.891L47.7417 227.996C45.9106 229.052 44.2062 231.11 43.1921 233.463L28.3883 264.717C27.6981 266.323 26.6698 267.817 25.4726 268.944L5.68249 286.557C3.80912 288.319 2.52734 291.067 2.52734 293.335V320.164C2.52734 322.63 4.25986 323.617 6.38676 322.391L8.64043 321.094L57.2775 349.163L67.4895 345.796L77.7155 317.572L129.325 244.905L74.8984 213.707Z" fill="#B0E2FA" />
        <path d="M74.8984 220.048C74.8984 220.048 74.7576 219.964 74.6872 219.921L74.5604 219.879C73.9125 219.611 73.0673 219.696 72.1518 220.231L47.7417 234.336C45.9106 235.393 44.2062 237.45 43.1921 239.804L28.3883 271.057C27.6981 272.664 26.6698 274.157 25.4726 275.284L5.68249 292.898C3.80912 294.659 2.52734 297.407 2.52734 299.676V326.505C2.52734 328.971 4.25986 329.957 6.38676 328.731L8.64043 327.435L57.2775 355.504L67.4895 352.122L77.7155 323.898L129.325 251.231L74.8984 220.034V220.048Z" fill="#F5FCFF" />
        <path d="M57.2212 349.783L8.65454 321.742L6.68257 322.884C5.47122 323.588 4.30213 323.687 3.38657 323.151C2.47102 322.63 1.97803 321.559 1.97803 320.164V293.335C1.97803 290.911 3.31615 288.009 5.3022 286.134L25.1064 268.507C26.2332 267.45 27.2192 266.013 27.8671 264.477L42.685 233.209C43.7273 230.757 45.5162 228.615 47.46 227.488L71.8701 213.383C73.18 212.622 74.1801 212.749 74.7858 212.989L74.8985 213.031C75.0393 213.101 75.1238 213.144 75.2083 213.2L130.17 244.693L78.2367 317.811L67.9402 346.218L57.2212 349.755V349.783ZM8.65454 320.432L57.348 348.543L67.0529 345.33L77.2648 317.233L128.522 245.088L74.6027 214.186C74.6027 214.186 74.5041 214.13 74.4618 214.102L74.3914 214.074C73.8561 213.862 73.1941 213.975 72.4617 214.384L48.0516 228.489C46.3191 229.489 44.7133 231.434 43.7414 233.688L28.9235 264.956C28.2192 266.604 27.1347 268.183 25.8951 269.352L6.09098 286.98C4.35847 288.614 3.13304 291.235 3.13304 293.335V320.164C3.13304 321.136 3.42883 321.855 3.97816 322.165C4.5275 322.475 5.28811 322.376 6.13324 321.897L8.66862 320.432H8.65454Z" fill="#00283B" />
        <path d="M57.2773 324.715V351.544C57.2773 354.01 59.0098 354.996 61.1367 353.771L71.1796 347.965C72.9685 346.922 74.5038 344.597 74.8982 342.343C75.9405 336.34 79.2365 327.618 88.9696 322.01C98.5054 316.5 101.449 324.236 102.351 329.168C102.717 331.155 104.393 331.775 106.337 330.647L125.888 321.573C128.522 320.051 130.648 316.359 130.648 313.33V247.441C130.648 245.045 128.972 244.073 126.888 245.271L102.478 259.376C100.646 260.432 98.9421 262.49 97.9279 264.843L83.1241 296.096C82.4339 297.703 81.4057 299.196 80.2084 300.324L60.4183 317.937C58.545 319.699 57.2632 322.446 57.2632 324.715H57.2773Z" fill="#00283B" />
        <path d="M59.4042 354.869C58.9394 354.869 58.5027 354.757 58.1224 354.531C57.2069 354.01 56.7139 352.939 56.7139 351.544V324.715C56.7139 322.291 58.052 319.403 60.038 317.515L79.8422 299.887C80.969 298.83 81.955 297.393 82.6029 295.857L97.4208 264.589C98.4632 262.137 100.252 259.995 102.196 258.868L126.606 244.763C127.789 244.073 128.944 243.988 129.831 244.495C130.719 245.017 131.212 246.045 131.212 247.426V313.315C131.212 316.528 128.944 320.445 126.169 322.052L106.562 331.155C105.435 331.817 104.28 331.944 103.351 331.521C102.534 331.14 101.97 330.337 101.773 329.252C101.224 326.293 99.9844 322.475 97.111 321.15C95.0686 320.206 92.4205 320.657 89.2372 322.489C79.7577 327.97 76.5039 336.354 75.4475 342.427C75.025 344.851 73.377 347.331 71.4473 348.444L61.4043 354.249C60.7 354.658 60.0239 354.855 59.3901 354.855L59.4042 354.869ZM128.564 245.327C128.155 245.327 127.676 245.468 127.169 245.764L102.759 259.869C101.027 260.869 99.421 262.814 98.4491 265.068L83.6312 296.336C82.9269 297.985 81.8423 299.563 80.6028 300.732L60.7986 318.36C59.0661 319.994 57.8407 322.615 57.8407 324.715V351.544C57.8407 352.516 58.1365 353.235 58.6858 353.545C59.2211 353.855 59.9958 353.756 60.8409 353.277L70.8838 347.472C72.5178 346.528 73.9686 344.33 74.3348 342.244C75.4334 335.931 78.814 327.209 88.6738 321.516C92.1951 319.487 95.1953 319.022 97.5899 320.121C100.9 321.657 102.308 325.828 102.9 329.055C102.985 329.548 103.224 330.21 103.83 330.492C104.407 330.76 105.196 330.633 106.041 330.154L125.648 321.051C128.071 319.656 130.071 316.176 130.071 313.33V247.44C130.071 246.496 129.775 245.806 129.254 245.496C129.057 245.383 128.817 245.327 128.564 245.327Z" fill="#00283B" />
        <path d="M106.929 320.784C105.999 320.784 105.21 320.361 104.703 319.53C100.844 313.147 94.6604 314.908 90.153 317.501C88.8853 318.219 87.674 318.332 86.7443 317.783C85.8147 317.247 85.3076 316.162 85.3076 314.739V299.14C85.3076 298.069 85.6034 296.872 86.1527 295.702L100.28 265.632C101.083 263.927 102.323 262.49 103.689 261.701L120.197 252.175C121.437 251.457 122.634 251.358 123.578 251.893C124.507 252.429 125.029 253.514 125.029 254.951V306.003C125.029 308.75 123.155 311.991 120.775 313.372L109.013 320.164C108.295 320.573 107.591 320.784 106.943 320.784H106.929ZM97.5479 314.006C100.914 314.006 103.689 315.655 105.675 318.938C106.182 319.783 107.211 319.882 108.422 319.177L120.183 312.386C122.212 311.216 123.86 308.356 123.86 306.003V254.951C123.86 253.937 123.55 253.204 122.986 252.88C122.423 252.556 121.62 252.654 120.747 253.162L104.238 262.687C103.083 263.349 101.985 264.632 101.281 266.125L87.1528 296.195C86.688 297.196 86.4204 298.238 86.4204 299.14V314.739C86.4204 315.739 86.7302 316.472 87.2937 316.796C87.8712 317.12 88.674 317.022 89.5755 316.514C92.4771 314.837 95.1534 314.02 97.5479 314.02V314.006Z" fill="#B0E2FA" />
        <path d="M91.2094 296.026C90.8432 296.026 90.5192 295.899 90.2516 295.66C89.5896 295.054 89.5332 293.87 90.1107 292.658L102.041 267.407C102.83 265.745 104.027 264.364 105.351 263.603L119.394 255.487C120.127 255.064 120.845 255.007 121.423 255.346C122 255.67 122.31 256.318 122.31 257.177V275.665C122.31 277.187 121.282 278.962 119.972 279.723L92.2658 295.73C91.9137 295.942 91.5474 296.04 91.2235 296.04L91.2094 296.026ZM103.069 267.887L91.1389 293.137C90.7164 294.039 90.8432 294.645 91.0263 294.814C91.1953 294.969 91.5333 294.814 91.6742 294.73L119.38 278.722C120.338 278.173 121.155 276.764 121.155 275.651V257.163C121.155 256.741 121.042 256.445 120.831 256.318C120.634 256.205 120.31 256.247 119.944 256.459L105.901 264.575C104.788 265.209 103.731 266.449 103.055 267.887H103.069Z" fill="#B0E2FA" />
        <path d="M91.2094 296.026C90.8432 296.026 90.5192 295.899 90.2516 295.66C89.5896 295.054 89.5332 293.87 90.1107 292.658L90.5474 291.728L115.943 277.06C116.901 276.51 117.718 275.101 117.718 273.988V256.445L119.394 255.472C120.127 255.05 120.845 254.993 121.423 255.331C122 255.656 122.31 256.304 122.31 257.163V275.651C122.31 277.172 121.282 278.948 119.972 279.709L92.2658 295.716C91.9137 295.927 91.5474 296.026 91.2235 296.026H91.2094ZM91.4207 292.531L91.1389 293.137C90.7164 294.039 90.8432 294.645 91.0263 294.814C91.1953 294.969 91.5333 294.814 91.6742 294.73L119.38 278.722C120.338 278.173 121.155 276.764 121.155 275.651V257.163C121.155 256.741 121.042 256.445 120.831 256.318C120.634 256.205 120.31 256.247 119.944 256.459L118.845 257.093V273.974C118.845 275.496 117.817 277.271 116.507 278.032L91.4207 292.517V292.531Z" fill="#B0E2FA" />
        <path d="M111.873 296.632C111.661 296.632 111.45 296.575 111.253 296.463C110.774 296.181 110.506 295.589 110.506 294.828C110.506 293.518 111.281 292.052 112.281 291.475L118.549 287.853C119.056 287.557 119.577 287.529 120 287.769C120.479 288.05 120.746 288.642 120.746 289.403C120.746 290.714 119.972 292.179 118.972 292.757L112.704 296.378C112.422 296.547 112.14 296.618 111.873 296.618V296.632ZM119.38 288.473C119.225 288.473 119.07 288.544 118.972 288.6L112.704 292.221C111.971 292.644 111.366 293.842 111.366 294.828C111.366 295.265 111.492 295.603 111.69 295.716C111.887 295.829 112.14 295.716 112.281 295.631L118.549 292.01C119.282 291.587 119.901 290.39 119.901 289.403C119.901 288.966 119.775 288.628 119.577 288.515C119.521 288.487 119.451 288.473 119.394 288.473H119.38Z" fill="#B0E2FA" />
        <path d="M102.562 329.872L102.787 331.648C103.111 333.367 104.562 333.916 106.238 332.944L127.662 320.572C129.31 319.628 130.648 317.303 130.648 315.401V313.71L102.562 329.872Z" fill="#B0E2FA" />
        <path d="M104.675 333.987C104.309 333.987 103.971 333.916 103.661 333.775C102.928 333.437 102.421 332.719 102.238 331.76L101.957 329.576L131.226 312.724V315.401C131.226 317.515 129.789 320.009 127.958 321.065L106.534 333.437C105.886 333.804 105.253 334.001 104.675 334.001V333.987ZM103.182 330.182L103.365 331.577C103.436 331.958 103.633 332.507 104.14 332.733C104.619 332.944 105.267 332.845 105.971 332.437L127.395 320.065C128.86 319.22 130.099 317.078 130.099 315.387V314.682L103.196 330.168L103.182 330.182Z" fill="#00283B" />
        <path d="M57.2778 326.392L65.5883 321.615C66.9405 320.84 67.8701 321.897 67.3489 323.644L66.6447 325.998C65.7573 328.985 63.6585 331.817 61.3344 333.156L57.2778 335.495V326.378V326.392Z" fill="#B0E2FA" />
        <path d="M56.7143 336.495V326.068L65.3064 321.136C66.0811 320.699 66.8417 320.685 67.3911 321.136C68.0531 321.658 68.2362 322.672 67.8841 323.842L67.1798 326.195C66.2501 329.323 64.0669 332.268 61.616 333.691L56.7002 336.523L56.7143 336.495ZM57.8552 326.716V334.508L61.0526 332.663C63.264 331.38 65.2501 328.703 66.0952 325.828L66.7995 323.475C67.0389 322.7 66.9122 322.165 66.6868 321.996C66.4473 321.798 66.0389 321.996 65.8698 322.094L57.8411 326.702L57.8552 326.716Z" fill="#00283B" />
        <path d="M57.2776 359.59L59.9257 355.814L57.2776 349.149L13.0211 323.602L2.56967 317.571C1.1893 318.811 0.287826 320.967 0.639962 322.461L1.59777 326.477C1.7668 327.167 2.09076 327.674 2.52741 327.999L2.40064 327.9L2.76687 328.154L57.2776 359.576V359.59Z" fill="#F5FCFF" />
        <path d="M57.4465 360.351L56.9958 360.098C20.6741 339.159 2.48976 328.67 2.44281 328.633L2.20335 328.478C1.63993 328.069 1.24554 327.435 1.04834 326.632L0.0905334 322.602C-0.30386 320.953 0.625781 318.558 2.18927 317.149L2.49915 316.867L13.3168 323.109L57.7423 348.754L60.5735 355.884L57.4606 360.337L57.4465 360.351ZM2.75269 327.463L3.09074 327.689C3.5133 327.928 51.8828 355.814 57.1085 358.829L59.2776 355.744L56.8268 349.558L2.65409 318.29C1.55542 319.445 0.921576 321.193 1.1892 322.348L2.14701 326.378C2.25969 326.857 2.45689 327.224 2.7386 327.477L2.75269 327.463Z" fill="#00283B" />
        <path d="M58.0385 348.6L75.9129 338.257L74.2931 347.627C73.8705 350.079 72.1803 352.63 70.2224 353.757L60.4612 359.421C58.5174 360.549 56.813 359.985 56.3623 358.069L55.4045 354.053C54.9819 352.305 56.2919 349.628 58.0666 348.614L58.0385 348.6Z" fill="#B0E2FA" />
        <path d="M58.5454 360.563C58.151 360.563 57.7707 360.492 57.4326 360.337C56.6157 359.985 56.0241 359.224 55.7846 358.181L54.8268 354.151C54.362 352.178 55.7846 349.219 57.7566 348.078L76.6874 337.129L74.8563 347.698C74.4056 350.318 72.6168 353.01 70.5039 354.222L60.7427 359.886C59.9962 360.323 59.2496 360.534 58.5735 360.534L58.5454 360.563ZM75.138 339.356L58.32 349.093C56.7847 349.98 55.5733 352.418 55.9255 353.912L56.8833 357.928C57.0523 358.618 57.3904 359.083 57.8834 359.294C58.489 359.548 59.2919 359.421 60.1511 358.928L69.9123 353.263C71.7153 352.221 73.321 349.811 73.7154 347.528L75.124 339.356H75.138Z" fill="#00283B" />
        <path d="M47.3469 236.703L93.3641 263.208C94.3501 263.772 94.7445 265.012 94.2515 266.055L82.6451 290.545C82.0957 291.686 80.7013 292.137 79.5885 291.517L33.2755 265.646C32.2614 265.083 31.867 263.843 32.36 262.786L44.2622 237.662C44.8115 236.506 46.2342 236.055 47.3328 236.703H47.3469Z" fill="#B0E2FA" />
        <path d="M80.6592 292.377C80.1944 292.377 79.7296 292.264 79.307 292.024L32.994 266.154C31.7263 265.449 31.2193 263.871 31.839 262.56L43.7412 237.422C44.0652 236.732 44.685 236.196 45.4174 235.971C46.1499 235.745 46.9527 235.83 47.6147 236.21L93.6319 262.715C94.8715 263.434 95.3644 264.998 94.7447 266.294L83.1383 290.784C82.8143 291.475 82.2086 291.996 81.4903 292.236C81.2226 292.32 80.9269 292.363 80.6451 292.363L80.6592 292.377ZM46.2484 236.985C46.0794 236.985 45.9245 237.014 45.7555 237.056C45.3188 237.197 44.9667 237.493 44.7695 237.915L32.8673 263.039C32.501 263.814 32.7968 264.73 33.5434 265.153L79.8564 291.024C80.2508 291.249 80.7015 291.292 81.1381 291.151C81.5748 291.01 81.9128 290.714 82.11 290.305L93.7165 265.815C94.0827 265.054 93.7869 264.139 93.0544 263.716L47.0372 237.197C46.7837 237.056 46.5161 236.985 46.2344 236.985H46.2484Z" fill="#00283B" />
        <path d="M39.3745 260.94C38.3604 260.376 37.966 259.136 38.459 258.079L48.3188 237.267L47.3328 236.704C46.2201 236.069 44.8115 236.506 44.2622 237.662L32.36 262.786C31.867 263.829 32.2614 265.083 33.2755 265.646L79.5885 291.517C80.7013 292.137 82.0957 291.686 82.6451 290.545L84.6875 286.233L39.3886 260.926L39.3745 260.94Z" fill="#F5FCFF" />
        <path d="M80.6592 292.377C80.1944 292.377 79.7296 292.264 79.307 292.024L32.994 266.154C31.7263 265.449 31.2193 263.871 31.839 262.56L43.7412 237.422C44.0652 236.732 44.685 236.196 45.4174 235.971C46.1499 235.745 46.9527 235.83 47.6147 236.21L49.0515 237.042L38.9663 258.333C38.6 259.108 38.8958 260.024 39.6424 260.447L85.406 286.008L83.1383 290.799C82.8143 291.489 82.2086 292.01 81.4903 292.25C81.2227 292.334 80.9269 292.377 80.6451 292.377H80.6592ZM46.2485 236.985C46.0794 236.985 45.9245 237.014 45.7555 237.056C45.3188 237.197 44.9667 237.493 44.7695 237.915L32.8673 263.054C32.501 263.829 32.7968 264.744 33.5434 265.167L79.8564 291.038C80.2508 291.264 80.7156 291.306 81.1381 291.165C81.5748 291.024 81.9128 290.728 82.11 290.319L83.9271 286.501L39.093 261.447C37.8253 260.743 37.3183 259.164 37.938 257.854L47.5725 237.521L47.0513 237.225C46.7978 237.084 46.5302 237.014 46.2485 237.014V236.985Z" fill="#00283B" />
        <path d="M57.2775 326.392L2.52734 294.631V303.719L57.2775 335.509V326.392Z" fill="#B0E2FA" />
        <path d="M57.8409 336.495L1.96387 304.058V293.645L57.855 326.068V336.495H57.8409ZM3.10479 303.395L56.7141 334.522V326.73L3.10479 295.617V303.395Z" fill="#00283B" />
        <path d="M2.52734 294.631V303.719L7.66854 306.707C7.3164 305.861 7.10512 304.974 7.10512 304.156C7.10512 301.831 8.73903 300.887 10.7533 302.057C12.7675 303.226 14.4014 306.044 14.4014 308.369C14.4014 309.201 14.1901 309.849 13.8239 310.286L17.134 312.202V303.085L2.52734 294.617V294.631Z" fill="#B0E2FA" />
        <path d="M4.17529 297.238V306.326L9.31649 309.314C8.96435 308.468 8.75307 307.58 8.75307 306.763C8.75307 304.438 10.387 303.494 12.4012 304.664C14.4154 305.833 16.0493 308.651 16.0493 310.976C16.0493 311.808 15.8381 312.456 15.4718 312.893L18.7819 314.809V305.692L4.17529 297.224V297.238Z" fill="#F5FCFF" />
        <path d="M17.6973 313.203L12.9645 310.455L13.3871 309.934C13.6829 309.567 13.8378 309.032 13.8378 308.37C13.8378 306.27 12.3025 303.593 10.4714 302.55C9.68262 302.099 8.96427 302.001 8.45719 302.296C7.95011 302.592 7.6684 303.255 7.6684 304.171C7.6684 304.875 7.85152 305.678 8.18957 306.51L8.8375 308.074L1.94971 304.072V293.659L17.6832 302.79V313.231L17.6973 313.203ZM14.6125 310.089L16.5563 311.216V303.424L3.09063 295.617V303.396L6.68242 305.481C6.58382 305.03 6.52748 304.579 6.52748 304.156C6.52748 302.818 7.00639 301.803 7.87969 301.296C8.75298 300.789 9.86574 300.887 11.0207 301.55C13.2181 302.818 14.9506 305.819 14.9506 308.356C14.9506 309.004 14.8238 309.596 14.5984 310.075L14.6125 310.089Z" fill="#00283B" />
        <path d="M42.6709 317.923V327.012L45.9951 328.942C45.4457 327.9 45.1077 326.758 45.1077 325.716C45.1077 323.391 46.7416 322.447 48.7558 323.616C50.77 324.786 52.404 327.604 52.404 329.929C52.404 330.986 52.0518 331.732 51.5025 332.155L57.2775 335.509V326.392L42.6709 317.923Z" fill="#B0E2FA" />
        <path d="M44.5439 320.671V329.76L47.8681 331.69C47.3188 330.647 46.9807 329.506 46.9807 328.463C46.9807 326.138 48.6146 325.194 50.6289 326.364C52.6431 327.533 54.277 330.351 54.277 332.676C54.277 333.733 53.9249 334.48 53.3755 334.903L56.6997 336.946V327.829L44.5439 320.671Z" fill="#F5FCFF" />
        <path d="M57.8407 336.495L50.474 332.211L51.1501 331.69C51.5868 331.366 51.8262 330.732 51.8262 329.915C51.8262 327.815 50.2909 325.138 48.4598 324.095C47.671 323.644 46.9527 323.546 46.4456 323.841C45.9385 324.137 45.6568 324.8 45.6568 325.716C45.6568 326.631 45.9526 327.66 46.4738 328.675L47.3752 330.422L42.0791 327.336V316.923L57.8266 326.054V336.495H57.8407ZM52.4037 332.014L56.6998 334.522V326.73L43.2341 318.924V326.702L44.8258 327.632C44.6286 326.984 44.53 326.336 44.53 325.744C44.53 324.405 45.0089 323.391 45.8822 322.883C46.7555 322.376 47.8682 322.475 49.0232 323.137C51.2347 324.405 52.9531 327.406 52.9531 329.943C52.9531 330.774 52.7559 331.493 52.3897 332.028L52.4037 332.014Z" fill="#00283B" />
        <path d="M33.0365 316.176L26.1909 312.23C25.1345 311.61 24.2612 309.976 24.2612 308.553C24.2612 307.129 25.1204 306.481 26.1909 307.101L33.0365 311.047C34.0929 311.667 34.9662 313.301 34.9662 314.725C34.9662 316.148 34.107 316.796 33.0365 316.176Z" fill="#B0E2FA" />
        <path d="M34.1771 318.896L27.3316 314.95C26.2752 314.33 25.4019 312.696 25.4019 311.272C25.4019 309.849 26.2611 309.201 27.3316 309.821L34.1771 313.767C35.2335 314.387 36.1068 316.021 36.1068 317.444C36.1068 318.867 35.2476 319.516 34.1771 318.896Z" fill="#F5FCFF" />
        <path d="M33.797 316.979C33.4589 316.979 33.1068 316.881 32.7405 316.669L25.895 312.71C24.6555 311.991 23.6836 310.159 23.6836 308.539C23.6836 307.581 24.0357 306.834 24.6414 306.481C25.1767 306.171 25.8246 306.214 26.4725 306.58L33.318 310.526C34.5576 311.244 35.5295 313.076 35.5295 314.696C35.5295 315.655 35.1773 316.401 34.5857 316.74C34.3463 316.881 34.0787 316.951 33.811 316.951L33.797 316.979ZM33.318 315.683C33.473 315.781 33.7829 315.908 34.0082 315.781C34.2477 315.641 34.3885 315.246 34.3885 314.725C34.3885 313.513 33.642 312.047 32.7546 311.54L25.9091 307.581C25.7542 307.482 25.4443 307.355 25.2189 307.482C24.9795 307.623 24.8386 308.031 24.8386 308.539C24.8386 309.751 25.5851 311.216 26.4725 311.723L33.318 315.669V315.683Z" fill="#00283B" />
        <path d="M108.45 238.267L71.208 216.75L77.7577 212.974L115 234.477L108.45 238.267Z" fill="#B0E2FA" />
        <path d="M71.2081 216.75L66.3486 222.95L111.07 248.751L108.45 238.267L71.2081 216.75Z" fill="#B0E2FA" />
        <path d="M108.45 241.227L71.208 219.71L77.7577 215.934L115 237.436L108.45 241.227Z" fill="#F5FCFF" />
        <path d="M108.45 238.268L111.07 248.751L117.929 244.637L114.999 234.477L108.45 238.268Z" fill="#00283B" />
        <path d="M118.606 244.89L115.479 234.097L77.7577 212.312L70.8276 216.314L65.4893 223.106L111.07 249.413L118.606 244.89ZM111.436 247.863L109.112 238.535L114.662 235.337L117.267 244.369L111.436 247.863ZM72.3489 216.751L77.7577 213.622L113.859 234.477L108.45 237.605L80.3635 221.387L72.3489 216.751ZM67.1936 222.782L71.3488 217.497L79.5606 222.246L107.957 238.648L110.197 247.596L67.2077 222.782H67.1936Z" fill="#00283B" />
        <path d="M47.5586 345.697L15.6128 327.238V323.983C15.6128 320.629 17.6411 319.093 20.1624 320.545L43.0231 333.748C45.5303 335.199 47.5727 339.088 47.5727 342.428V345.683L47.5586 345.697Z" fill="#B0E2FA" />
        <path d="M45.6854 343.09L12.5986 323.983V320.389C12.5986 318.417 13.2747 316.895 14.4579 316.219C15.4721 315.641 16.7257 315.725 18.0075 316.458L40.8682 329.661C43.5726 331.225 45.6995 335.255 45.6995 338.834V343.076L45.6854 343.09ZM13.7396 323.32L44.5445 341.117V338.849C44.5445 335.692 42.6288 332.014 40.2906 330.662L17.4299 317.459C16.5144 316.923 15.6833 316.839 15.0213 317.219C14.2044 317.684 13.7396 318.854 13.7396 320.404V323.334V323.32Z" fill="#00283B" />
        <path d="M2.52734 303.72V307.862L57.2775 339.398V335.509L2.52734 303.72Z" fill="#B0E2FA" />
        <path d="M57.8409 340.384L1.96387 308.187V302.733L57.855 335.185V340.398L57.8409 340.384ZM3.10479 307.538L56.7141 338.426V335.847L3.10479 304.706V307.524V307.538Z" fill="#00283B" />
        <path d="M32.0786 263.715L38.3326 260.024L38.3044 258.558L48.3192 237.281L45.8401 236.421L44.7837 236.985L32.7265 261.898L32.0786 263.715Z" fill="#00283B" />
        <path d="M1.13281 324.532L55.8407 356.054L56.207 359.252L2.4005 327.928L1.13281 324.532Z" fill="#00283B" />
        <path d="M130.085 302.367V312.949L131.226 312.287V301.733L130.085 302.367Z" fill="#B0E2FA" />
        <path d="M237.994 263.688L202.09 242.946L166.172 263.688V305.171L202.09 325.913V284.542L237.994 263.688Z" fill="#F5FCFF" />
        <path d="M166.172 305.044L202.09 325.786V284.401L166.172 263.561V305.044Z" fill="#B0E2FA" />
        <path d="M238.713 263.279L202.076 242.114L165.553 263.208L165.44 263.265V264.096V305.58L201.358 326.322L202.076 326.744L202.795 326.322L238.346 305.791L238.713 305.58V264.096V263.265V263.279ZM202.076 243.777L218.951 253.528L184.469 273.438L167.595 263.687L202.076 243.777ZM201.358 324.673L166.877 304.762V264.942L201.358 284.852V324.673ZM216.697 275.157L202.076 283.598L185.202 273.847L219.683 253.937L236.557 263.687L216.697 275.157Z" fill="#00283B" />
        <path d="M187.822 284.486L181.159 280.639C180.427 280.216 179.68 280.174 179.061 280.526C178.37 280.921 177.976 281.78 177.976 282.851C177.976 284.669 179.061 286.712 180.441 287.515L187.103 291.362C187.512 291.602 187.92 291.714 188.301 291.714C188.625 291.714 188.92 291.63 189.202 291.475C189.892 291.08 190.287 290.221 190.287 289.15C190.287 287.332 189.202 285.289 187.822 284.486ZM188.484 290.235C188.287 290.348 187.977 290.221 187.822 290.122L181.159 286.275C180.216 285.726 179.413 284.162 179.413 282.865C179.413 282.217 179.61 281.879 179.779 281.78C179.99 281.653 180.286 281.794 180.441 281.893L180.906 282.161C181.075 283.725 181.99 285.359 183.16 286.022L188.85 289.305C188.822 289.84 188.653 290.136 188.498 290.235H188.484Z" fill="#00283B" />
        <path d="M232.811 293.053C232.473 292.856 232.064 292.884 231.656 293.123L226.346 296.181C225.557 296.632 224.937 297.802 224.937 298.83V304.523C224.937 305.129 225.162 305.608 225.543 305.819C225.698 305.904 225.867 305.946 226.036 305.946C226.247 305.946 226.472 305.876 226.698 305.749L232.008 302.691C232.797 302.24 233.402 301.071 233.402 300.042V294.349C233.402 293.743 233.177 293.264 232.797 293.053H232.811Z" fill="#B0E2FA" />
        <path d="M157.129 331.958L134.917 319.135V293.49L157.129 306.312V331.958Z" fill="#B0E2FA" />
        <path d="M179.328 293.49L157.129 306.312L134.917 293.49L157.129 280.667L179.328 293.49Z" fill="#B0E2FA" />
        <path d="M179.328 300.93L159.369 312.329L137.156 299.506L157.129 288.107L179.328 300.93Z" fill="#F5FCFF" />
        <path d="M157.129 279.906L134.931 292.729L134.283 293.138V319.516L156.481 332.339L157.129 332.719L157.777 332.339L179.976 319.516V293.109L157.115 279.906H157.129ZM157.129 281.414L178.032 293.49L167.538 299.549L157.129 305.566L153.847 303.678L136.227 293.504L157.129 281.428V281.414ZM135.579 318.755V294.617L156.481 306.693V330.831L135.579 318.755Z" fill="#00283B" />
        <path d="M151.777 324.279L148.382 322.32C147.833 322.01 147.396 321.165 147.396 320.446V316.881C147.396 316.162 147.833 315.824 148.382 316.134L151.777 318.093C152.326 318.403 152.763 319.248 152.763 319.967V323.532C152.763 324.251 152.326 324.589 151.777 324.279Z" fill="#00283B" />
        <path d="M311.084 346.119C311.619 346.429 311.619 346.936 311.084 347.246C310.548 347.556 309.675 347.556 309.14 347.246C308.604 346.936 308.604 346.429 309.14 346.119C309.675 345.809 310.548 345.809 311.084 346.119Z" fill="#00283B" />
        <path d="M316.168 343.188C316.704 343.498 316.704 344.005 316.168 344.315C315.633 344.625 314.76 344.625 314.225 344.315C313.689 344.005 313.689 343.498 314.225 343.188C314.76 342.878 315.633 342.878 316.168 343.188Z" fill="#00283B" />
        <path d="M321.239 340.257C321.775 340.567 321.775 341.075 321.239 341.385C320.704 341.695 319.831 341.695 319.295 341.385C318.76 341.075 318.76 340.567 319.295 340.257C319.831 339.947 320.704 339.947 321.239 340.257Z" fill="#00283B" />
        <path d="M326.31 337.326C326.845 337.636 326.845 338.144 326.31 338.454C325.775 338.764 324.902 338.764 324.366 338.454C323.831 338.144 323.831 337.636 324.366 337.326C324.902 337.016 325.775 337.016 326.31 337.326Z" fill="#00283B" />
        <path d="M331.395 334.396C331.93 334.706 331.93 335.213 331.395 335.523C330.86 335.833 329.986 335.833 329.451 335.523C328.916 335.213 328.916 334.706 329.451 334.396C329.986 334.086 330.86 334.086 331.395 334.396Z" fill="#00283B" />
        <path d="M336.466 331.464C337.001 331.774 337.001 332.282 336.466 332.592C335.931 332.902 335.057 332.902 334.522 332.592C333.987 332.282 333.987 331.774 334.522 331.464C335.057 331.154 335.931 331.154 336.466 331.464Z" fill="#00283B" />
        <path d="M341.55 328.52C342.086 328.83 342.086 329.337 341.55 329.647C341.015 329.957 340.142 329.957 339.607 329.647C339.071 329.337 339.071 328.83 339.607 328.52C340.142 328.21 341.015 328.21 341.55 328.52Z" fill="#00283B" />
        <path d="M346.621 325.589C347.156 325.899 347.156 326.406 346.621 326.716C346.086 327.026 345.213 327.026 344.677 326.716C344.142 326.406 344.142 325.899 344.677 325.589C345.213 325.279 346.086 325.279 346.621 325.589Z" fill="#00283B" />
        <path d="M306.013 343.188C306.548 343.498 306.548 344.005 306.013 344.315C305.477 344.625 304.604 344.625 304.069 344.315C303.534 344.005 303.534 343.498 304.069 343.188C304.604 342.878 305.477 342.878 306.013 343.188Z" fill="#00283B" />
        <path d="M311.084 340.257C311.619 340.567 311.619 341.075 311.084 341.385C310.548 341.695 309.675 341.695 309.14 341.385C308.604 341.075 308.604 340.567 309.14 340.257C309.675 339.947 310.548 339.947 311.084 340.257Z" fill="#00283B" />
        <path d="M316.168 337.326C316.704 337.636 316.704 338.144 316.168 338.454C315.633 338.764 314.76 338.764 314.225 338.454C313.689 338.144 313.689 337.636 314.225 337.326C314.76 337.016 315.633 337.016 316.168 337.326Z" fill="#00283B" />
        <path d="M321.239 334.396C321.775 334.706 321.775 335.213 321.239 335.523C320.704 335.833 319.831 335.833 319.295 335.523C318.76 335.213 318.76 334.706 319.295 334.396C319.831 334.086 320.704 334.086 321.239 334.396Z" fill="#00283B" />
        <path d="M326.31 331.464C326.845 331.774 326.845 332.282 326.31 332.592C325.775 332.902 324.902 332.902 324.366 332.592C323.831 332.282 323.831 331.774 324.366 331.464C324.902 331.154 325.775 331.154 326.31 331.464Z" fill="#00283B" />
        <path d="M331.395 328.52C331.93 328.83 331.93 329.337 331.395 329.647C330.86 329.957 329.986 329.957 329.451 329.647C328.916 329.337 328.916 328.83 329.451 328.52C329.986 328.21 330.86 328.21 331.395 328.52Z" fill="#00283B" />
        <path d="M336.466 325.589C337.001 325.899 337.001 326.406 336.466 326.716C335.931 327.026 335.057 327.026 334.522 326.716C333.987 326.406 333.987 325.899 334.522 325.589C335.057 325.279 335.931 325.279 336.466 325.589Z" fill="#00283B" />
        <path d="M341.55 322.658C342.086 322.968 342.086 323.475 341.55 323.785C341.015 324.095 340.142 324.095 339.607 323.785C339.071 323.475 339.071 322.968 339.607 322.658C340.142 322.348 341.015 322.348 341.55 322.658Z" fill="#00283B" />
        <path d="M300.928 340.257C301.463 340.567 301.463 341.075 300.928 341.385C300.393 341.695 299.52 341.695 298.984 341.385C298.449 341.075 298.449 340.567 298.984 340.257C299.52 339.947 300.393 339.947 300.928 340.257Z" fill="#00283B" />
        <path d="M306.013 337.326C306.548 337.636 306.548 338.144 306.013 338.454C305.477 338.764 304.604 338.764 304.069 338.454C303.534 338.144 303.534 337.636 304.069 337.326C304.604 337.016 305.477 337.016 306.013 337.326Z" fill="#00283B" />
        <path d="M311.084 334.396C311.619 334.706 311.619 335.213 311.084 335.523C310.548 335.833 309.675 335.833 309.14 335.523C308.604 335.213 308.604 334.706 309.14 334.396C309.675 334.086 310.548 334.086 311.084 334.396Z" fill="#00283B" />
        <path d="M316.168 331.464C316.704 331.774 316.704 332.282 316.168 332.592C315.633 332.902 314.76 332.902 314.225 332.592C313.689 332.282 313.689 331.774 314.225 331.464C314.76 331.154 315.633 331.154 316.168 331.464Z" fill="#00283B" />
        <path d="M321.239 328.52C321.775 328.83 321.775 329.337 321.239 329.647C320.704 329.957 319.831 329.957 319.295 329.647C318.76 329.337 318.76 328.83 319.295 328.52C319.831 328.21 320.704 328.21 321.239 328.52Z" fill="#00283B" />
        <path d="M326.31 325.589C326.845 325.899 326.845 326.406 326.31 326.716C325.775 327.026 324.902 327.026 324.366 326.716C323.831 326.406 323.831 325.899 324.366 325.589C324.902 325.279 325.775 325.279 326.31 325.589Z" fill="#00283B" />
        <path d="M331.395 322.658C331.93 322.968 331.93 323.475 331.395 323.785C330.86 324.095 329.986 324.095 329.451 323.785C328.916 323.475 328.916 322.968 329.451 322.658C329.986 322.348 330.86 322.348 331.395 322.658Z" fill="#00283B" />
        <path d="M336.466 319.727C337.001 320.037 337.001 320.544 336.466 320.854C335.931 321.164 335.057 321.164 334.522 320.854C333.987 320.544 333.987 320.037 334.522 319.727C335.057 319.417 335.931 319.417 336.466 319.727Z" fill="#00283B" />
        <path d="M295.857 337.326C296.393 337.636 296.393 338.144 295.857 338.454C295.322 338.764 294.449 338.764 293.914 338.454C293.378 338.144 293.378 337.636 293.914 337.326C294.449 337.016 295.322 337.016 295.857 337.326Z" fill="#00283B" />
        <path d="M300.928 334.396C301.463 334.706 301.463 335.213 300.928 335.523C300.393 335.833 299.52 335.833 298.984 335.523C298.449 335.213 298.449 334.706 298.984 334.396C299.52 334.086 300.393 334.086 300.928 334.396Z" fill="#00283B" />
        <path d="M306.013 331.464C306.548 331.774 306.548 332.282 306.013 332.592C305.477 332.902 304.604 332.902 304.069 332.592C303.534 332.282 303.534 331.774 304.069 331.464C304.604 331.154 305.477 331.154 306.013 331.464Z" fill="#00283B" />
        <path d="M311.084 328.52C311.619 328.83 311.619 329.337 311.084 329.647C310.548 329.957 309.675 329.957 309.14 329.647C308.604 329.337 308.604 328.83 309.14 328.52C309.675 328.21 310.548 328.21 311.084 328.52Z" fill="#00283B" />
        <path d="M316.168 325.589C316.704 325.899 316.704 326.406 316.168 326.716C315.633 327.026 314.76 327.026 314.225 326.716C313.689 326.406 313.689 325.899 314.225 325.589C314.76 325.279 315.633 325.279 316.168 325.589Z" fill="#00283B" />
        <path d="M321.239 322.658C321.775 322.968 321.775 323.475 321.239 323.785C320.704 324.095 319.831 324.095 319.295 323.785C318.76 323.475 318.76 322.968 319.295 322.658C319.831 322.348 320.704 322.348 321.239 322.658Z" fill="#00283B" />
        <path d="M326.31 319.727C326.845 320.037 326.845 320.544 326.31 320.854C325.775 321.164 324.902 321.164 324.366 320.854C323.831 320.544 323.831 320.037 324.366 319.727C324.902 319.417 325.775 319.417 326.31 319.727Z" fill="#00283B" />
        <path d="M331.395 316.796C331.93 317.106 331.93 317.613 331.395 317.923C330.86 318.233 329.986 318.233 329.451 317.923C328.916 317.613 328.916 317.106 329.451 316.796C329.986 316.486 330.86 316.486 331.395 316.796Z" fill="#00283B" />
        <path d="M290.772 334.396C291.308 334.706 291.308 335.213 290.772 335.523C290.237 335.833 289.364 335.833 288.829 335.523C288.293 335.213 288.293 334.706 288.829 334.396C289.364 334.086 290.237 334.086 290.772 334.396Z" fill="#00283B" />
        <path d="M295.857 331.464C296.393 331.774 296.393 332.282 295.857 332.592C295.322 332.902 294.449 332.902 293.914 332.592C293.378 332.282 293.378 331.774 293.914 331.464C294.449 331.154 295.322 331.154 295.857 331.464Z" fill="#00283B" />
        <path d="M300.928 328.52C301.463 328.83 301.463 329.337 300.928 329.647C300.393 329.957 299.52 329.957 298.984 329.647C298.449 329.337 298.449 328.83 298.984 328.52C299.52 328.21 300.393 328.21 300.928 328.52Z" fill="#00283B" />
        <path d="M306.013 325.589C306.548 325.899 306.548 326.406 306.013 326.716C305.477 327.026 304.604 327.026 304.069 326.716C303.534 326.406 303.534 325.899 304.069 325.589C304.604 325.279 305.477 325.279 306.013 325.589Z" fill="#00283B" />
        <path d="M311.084 322.658C311.619 322.968 311.619 323.475 311.084 323.785C310.548 324.095 309.675 324.095 309.14 323.785C308.604 323.475 308.604 322.968 309.14 322.658C309.675 322.348 310.548 322.348 311.084 322.658Z" fill="#00283B" />
        <path d="M316.168 319.727C316.704 320.037 316.704 320.544 316.168 320.854C315.633 321.164 314.76 321.164 314.225 320.854C313.689 320.544 313.689 320.037 314.225 319.727C314.76 319.417 315.633 319.417 316.168 319.727Z" fill="#00283B" />
        <path d="M321.239 316.796C321.775 317.106 321.775 317.613 321.239 317.923C320.704 318.233 319.831 318.233 319.295 317.923C318.76 317.613 318.76 317.106 319.295 316.796C319.831 316.486 320.704 316.486 321.239 316.796Z" fill="#00283B" />
        <path d="M326.31 313.865C326.845 314.175 326.845 314.683 326.31 314.993C325.775 315.303 324.902 315.303 324.366 314.993C323.831 314.683 323.831 314.175 324.366 313.865C324.902 313.555 325.775 313.555 326.31 313.865Z" fill="#00283B" />
        <path d="M285.659 331.422C286.194 331.732 286.194 332.24 285.659 332.55C285.124 332.86 284.251 332.86 283.715 332.55C283.18 332.24 283.18 331.732 283.715 331.422C284.251 331.112 285.124 331.112 285.659 331.422Z" fill="#00283B" />
        <path d="M290.73 328.491C291.265 328.801 291.265 329.309 290.73 329.619C290.195 329.929 289.321 329.929 288.786 329.619C288.251 329.309 288.251 328.801 288.786 328.491C289.321 328.181 290.195 328.181 290.73 328.491Z" fill="#00283B" />
        <path d="M295.815 325.561C296.35 325.871 296.35 326.378 295.815 326.688C295.28 326.998 294.406 326.998 293.871 326.688C293.336 326.378 293.336 325.871 293.871 325.561C294.406 325.251 295.28 325.251 295.815 325.561Z" fill="#00283B" />
        <path d="M300.886 322.629C301.421 322.939 301.421 323.447 300.886 323.757C300.35 324.067 299.477 324.067 298.942 323.757C298.407 323.447 298.407 322.939 298.942 322.629C299.477 322.319 300.35 322.319 300.886 322.629Z" fill="#00283B" />
        <path d="M305.971 319.699C306.506 320.009 306.506 320.516 305.971 320.826C305.435 321.136 304.562 321.136 304.027 320.826C303.492 320.516 303.492 320.009 304.027 319.699C304.562 319.389 305.435 319.389 305.971 319.699Z" fill="#00283B" />
        <path d="M311.042 316.768C311.577 317.078 311.577 317.585 311.042 317.895C310.506 318.205 309.633 318.205 309.098 317.895C308.562 317.585 308.562 317.078 309.098 316.768C309.633 316.458 310.506 316.458 311.042 316.768Z" fill="#00283B" />
        <path d="M316.112 313.837C316.648 314.147 316.648 314.654 316.112 314.964C315.577 315.274 314.704 315.274 314.169 314.964C313.633 314.654 313.633 314.147 314.169 313.837C314.704 313.527 315.577 313.527 316.112 313.837Z" fill="#00283B" />
        <path d="M280.575 328.491C281.11 328.801 281.11 329.309 280.575 329.619C280.039 329.929 279.166 329.929 278.631 329.619C278.096 329.309 278.096 328.801 278.631 328.491C279.166 328.181 280.039 328.181 280.575 328.491Z" fill="#00283B" />
        <path d="M285.659 325.561C286.194 325.871 286.194 326.378 285.659 326.688C285.124 326.998 284.251 326.998 283.715 326.688C283.18 326.378 283.18 325.871 283.715 325.561C284.251 325.251 285.124 325.251 285.659 325.561Z" fill="#00283B" />
        <path d="M290.73 322.629C291.265 322.939 291.265 323.447 290.73 323.757C290.195 324.067 289.321 324.067 288.786 323.757C288.251 323.447 288.251 322.939 288.786 322.629C289.321 322.319 290.195 322.319 290.73 322.629Z" fill="#00283B" />
        <path d="M295.815 319.699C296.35 320.009 296.35 320.516 295.815 320.826C295.28 321.136 294.406 321.136 293.871 320.826C293.336 320.516 293.336 320.009 293.871 319.699C294.406 319.389 295.28 319.389 295.815 319.699Z" fill="#00283B" />
        <path d="M300.886 316.768C301.421 317.078 301.421 317.585 300.886 317.895C300.35 318.205 299.477 318.205 298.942 317.895C298.407 317.585 298.407 317.078 298.942 316.768C299.477 316.458 300.35 316.458 300.886 316.768Z" fill="#00283B" />
        <path d="M305.971 313.837C306.506 314.147 306.506 314.654 305.971 314.964C305.435 315.274 304.562 315.274 304.027 314.964C303.492 314.654 303.492 314.147 304.027 313.837C304.562 313.527 305.435 313.527 305.971 313.837Z" fill="#00283B" />
        <path d="M311.042 310.906C311.577 311.216 311.577 311.723 311.042 312.033C310.506 312.343 309.633 312.343 309.098 312.033C308.562 311.723 308.562 311.216 309.098 310.906C309.633 310.596 310.506 310.596 311.042 310.906Z" fill="#00283B" />
        <path d="M275.504 325.561C276.039 325.871 276.039 326.378 275.504 326.688C274.969 326.998 274.095 326.998 273.56 326.688C273.025 326.378 273.025 325.871 273.56 325.561C274.095 325.251 274.969 325.251 275.504 325.561Z" fill="#00283B" />
        <path d="M280.575 322.629C281.11 322.939 281.11 323.447 280.575 323.757C280.039 324.067 279.166 324.067 278.631 323.757C278.096 323.447 278.096 322.939 278.631 322.629C279.166 322.319 280.039 322.319 280.575 322.629Z" fill="#00283B" />
        <path d="M285.659 319.699C286.194 320.009 286.194 320.516 285.659 320.826C285.124 321.136 284.251 321.136 283.715 320.826C283.18 320.516 283.18 320.009 283.715 319.699C284.251 319.389 285.124 319.389 285.659 319.699Z" fill="#00283B" />
        <path d="M290.73 316.768C291.265 317.078 291.265 317.585 290.73 317.895C290.195 318.205 289.321 318.205 288.786 317.895C288.251 317.585 288.251 317.078 288.786 316.768C289.321 316.458 290.195 316.458 290.73 316.768Z" fill="#00283B" />
        <path d="M295.815 313.837C296.35 314.147 296.35 314.654 295.815 314.964C295.28 315.274 294.406 315.274 293.871 314.964C293.336 314.654 293.336 314.147 293.871 313.837C294.406 313.527 295.28 313.527 295.815 313.837Z" fill="#00283B" />
        <path d="M300.886 310.906C301.421 311.216 301.421 311.723 300.886 312.033C300.35 312.343 299.477 312.343 298.942 312.033C298.407 311.723 298.407 311.216 298.942 310.906C299.477 310.596 300.35 310.596 300.886 310.906Z" fill="#00283B" />
        <path d="M270.433 322.629C270.968 322.939 270.968 323.447 270.433 323.757C269.898 324.067 269.025 324.067 268.489 323.757C267.954 323.447 267.954 322.939 268.489 322.629C269.025 322.319 269.898 322.319 270.433 322.629Z" fill="#00283B" />
        <path d="M275.504 319.699C276.039 320.009 276.039 320.516 275.504 320.826C274.969 321.136 274.095 321.136 273.56 320.826C273.025 320.516 273.025 320.009 273.56 319.699C274.095 319.389 274.969 319.389 275.504 319.699Z" fill="#00283B" />
        <path d="M280.575 316.768C281.11 317.078 281.11 317.585 280.575 317.895C280.039 318.205 279.166 318.205 278.631 317.895C278.096 317.585 278.096 317.078 278.631 316.768C279.166 316.458 280.039 316.458 280.575 316.768Z" fill="#00283B" />
        <path d="M285.659 313.837C286.194 314.147 286.194 314.654 285.659 314.964C285.124 315.274 284.251 315.274 283.715 314.964C283.18 314.654 283.18 314.147 283.715 313.837C284.251 313.527 285.124 313.527 285.659 313.837Z" fill="#00283B" />
        <path d="M290.73 310.906C291.265 311.216 291.265 311.723 290.73 312.033C290.195 312.343 289.321 312.343 288.786 312.033C288.251 311.723 288.251 311.216 288.786 310.906C289.321 310.596 290.195 310.596 290.73 310.906Z" fill="#00283B" />
        <path d="M295.815 307.975C296.35 308.285 296.35 308.792 295.815 309.102C295.28 309.412 294.406 309.412 293.871 309.102C293.336 308.792 293.336 308.285 293.871 307.975C294.406 307.665 295.28 307.665 295.815 307.975Z" fill="#00283B" />
        <path d="M265.348 319.699C265.883 320.009 265.883 320.516 265.348 320.826C264.813 321.136 263.94 321.136 263.404 320.826C262.869 320.516 262.869 320.009 263.404 319.699C263.94 319.389 264.813 319.389 265.348 319.699Z" fill="#00283B" />
        <path d="M270.433 316.768C270.968 317.078 270.968 317.585 270.433 317.895C269.898 318.205 269.025 318.205 268.489 317.895C267.954 317.585 267.954 317.078 268.489 316.768C269.025 316.458 269.898 316.458 270.433 316.768Z" fill="#00283B" />
        <path d="M275.504 313.837C276.039 314.147 276.039 314.654 275.504 314.964C274.969 315.274 274.095 315.274 273.56 314.964C273.025 314.654 273.025 314.147 273.56 313.837C274.095 313.527 274.969 313.527 275.504 313.837Z" fill="#00283B" />
        <path d="M280.575 310.906C281.11 311.216 281.11 311.723 280.575 312.033C280.039 312.343 279.166 312.343 278.631 312.033C278.096 311.723 278.096 311.216 278.631 310.906C279.166 310.596 280.039 310.596 280.575 310.906Z" fill="#00283B" />
        <path d="M285.659 307.975C286.194 308.285 286.194 308.792 285.659 309.102C285.124 309.412 284.251 309.412 283.715 309.102C283.18 308.792 283.18 308.285 283.715 307.975C284.251 307.665 285.124 307.665 285.659 307.975Z" fill="#00283B" />
        <path d="M260.277 316.768C260.813 317.078 260.813 317.585 260.277 317.895C259.742 318.205 258.869 318.205 258.334 317.895C257.798 317.585 257.798 317.078 258.334 316.768C258.869 316.458 259.742 316.458 260.277 316.768Z" fill="#00283B" />
        <path d="M265.348 313.837C265.883 314.147 265.883 314.654 265.348 314.964C264.813 315.274 263.94 315.274 263.404 314.964C262.869 314.654 262.869 314.147 263.404 313.837C263.94 313.527 264.813 313.527 265.348 313.837Z" fill="#00283B" />
        <path d="M270.433 310.906C270.968 311.216 270.968 311.723 270.433 312.033C269.898 312.343 269.025 312.343 268.489 312.033C267.954 311.723 267.954 311.216 268.489 310.906C269.025 310.596 269.898 310.596 270.433 310.906Z" fill="#00283B" />
        <path d="M275.504 307.975C276.039 308.285 276.039 308.792 275.504 309.102C274.969 309.412 274.095 309.412 273.56 309.102C273.025 308.792 273.025 308.285 273.56 307.975C274.095 307.665 274.969 307.665 275.504 307.975Z" fill="#00283B" />
        <path d="M260.277 310.906C260.813 311.216 260.813 311.723 260.277 312.033C259.742 312.343 258.869 312.343 258.334 312.033C257.798 311.723 257.798 311.216 258.334 310.906C258.869 310.596 259.742 310.596 260.277 310.906Z" fill="#00283B" />
        <path d="M265.348 307.975C265.883 308.285 265.883 308.792 265.348 309.102C264.813 309.412 263.94 309.412 263.404 309.102C262.869 308.792 262.869 308.285 263.404 307.975C263.94 307.665 264.813 307.665 265.348 307.975Z" fill="#00283B" />
        <path d="M270.433 305.044C270.968 305.354 270.968 305.861 270.433 306.171C269.898 306.481 269.025 306.481 268.489 306.171C267.954 305.861 267.954 305.354 268.489 305.044C269.025 304.734 269.898 304.734 270.433 305.044Z" fill="#00283B" />
        <path d="M260.277 305.044C260.813 305.354 260.813 305.861 260.277 306.171C259.742 306.481 258.869 306.481 258.334 306.171C257.798 305.861 257.798 305.354 258.334 305.044C258.869 304.734 259.742 304.734 260.277 305.044Z" fill="#00283B" />
        <path d="M265.348 302.099C265.883 302.409 265.883 302.916 265.348 303.226C264.813 303.536 263.94 303.536 263.404 303.226C262.869 302.916 262.869 302.409 263.404 302.099C263.94 301.789 264.813 301.789 265.348 302.099Z" fill="#00283B" />
        <path d="M346.861 360.337C347.396 360.647 347.396 361.154 346.861 361.464C346.326 361.774 345.452 361.774 344.917 361.464C344.382 361.154 344.382 360.647 344.917 360.337C345.452 360.027 346.326 360.027 346.861 360.337Z" fill="#00283B" />
        <path d="M351.945 357.406C352.481 357.716 352.481 358.223 351.945 358.533C351.41 358.843 350.537 358.843 350.002 358.533C349.466 358.223 349.466 357.716 350.002 357.406C350.537 357.096 351.41 357.096 351.945 357.406Z" fill="#00283B" />
        <path d="M357.016 354.475C357.551 354.785 357.551 355.292 357.016 355.602C356.481 355.912 355.608 355.912 355.072 355.602C354.537 355.292 354.537 354.785 355.072 354.475C355.608 354.165 356.481 354.165 357.016 354.475Z" fill="#00283B" />
        <path d="M362.101 351.544C362.636 351.854 362.636 352.361 362.101 352.671C361.566 352.981 360.693 352.981 360.157 352.671C359.622 352.361 359.622 351.854 360.157 351.544C360.693 351.234 361.566 351.234 362.101 351.544Z" fill="#00283B" />
        <path d="M367.172 348.613C367.707 348.923 367.707 349.431 367.172 349.741C366.637 350.051 365.763 350.051 365.228 349.741C364.693 349.431 364.693 348.923 365.228 348.613C365.763 348.303 366.637 348.303 367.172 348.613Z" fill="#00283B" />
        <path d="M372.243 345.682C372.778 345.992 372.778 346.499 372.243 346.809C371.707 347.119 370.834 347.119 370.299 346.809C369.764 346.499 369.764 345.992 370.299 345.682C370.834 345.372 371.707 345.372 372.243 345.682Z" fill="#00283B" />
        <path d="M377.328 342.752C377.863 343.062 377.863 343.569 377.328 343.879C376.792 344.189 375.919 344.189 375.384 343.879C374.849 343.569 374.849 343.062 375.384 342.752C375.919 342.442 376.792 342.442 377.328 342.752Z" fill="#00283B" />
        <path d="M336.705 360.337C337.24 360.647 337.24 361.154 336.705 361.464C336.17 361.774 335.297 361.774 334.761 361.464C334.226 361.154 334.226 360.647 334.761 360.337C335.297 360.027 336.17 360.027 336.705 360.337Z" fill="#00283B" />
        <path d="M341.79 357.406C342.325 357.716 342.325 358.223 341.79 358.533C341.255 358.843 340.382 358.843 339.846 358.533C339.311 358.223 339.311 357.716 339.846 357.406C340.382 357.096 341.255 357.096 341.79 357.406Z" fill="#00283B" />
        <path d="M346.861 354.475C347.396 354.785 347.396 355.292 346.861 355.602C346.326 355.912 345.452 355.912 344.917 355.602C344.382 355.292 344.382 354.785 344.917 354.475C345.452 354.165 346.326 354.165 346.861 354.475Z" fill="#00283B" />
        <path d="M351.945 351.544C352.481 351.854 352.481 352.361 351.945 352.671C351.41 352.981 350.537 352.981 350.002 352.671C349.466 352.361 349.466 351.854 350.002 351.544C350.537 351.234 351.41 351.234 351.945 351.544Z" fill="#00283B" />
        <path d="M357.016 348.613C357.551 348.923 357.551 349.431 357.016 349.741C356.481 350.051 355.608 350.051 355.072 349.741C354.537 349.431 354.537 348.923 355.072 348.613C355.608 348.303 356.481 348.303 357.016 348.613Z" fill="#00283B" />
        <path d="M362.101 345.682C362.636 345.992 362.636 346.499 362.101 346.809C361.566 347.119 360.693 347.119 360.157 346.809C359.622 346.499 359.622 345.992 360.157 345.682C360.693 345.372 361.566 345.372 362.101 345.682Z" fill="#00283B" />
        <path d="M367.172 342.752C367.707 343.062 367.707 343.569 367.172 343.879C366.637 344.189 365.763 344.189 365.228 343.879C364.693 343.569 364.693 343.062 365.228 342.752C365.763 342.442 366.637 342.442 367.172 342.752Z" fill="#00283B" />
        <path d="M372.243 339.82C372.778 340.13 372.778 340.638 372.243 340.948C371.707 341.258 370.834 341.258 370.299 340.948C369.764 340.638 369.764 340.13 370.299 339.82C370.834 339.51 371.707 339.51 372.243 339.82Z" fill="#00283B" />
        <path d="M331.592 357.378C332.127 357.688 332.127 358.195 331.592 358.505C331.057 358.815 330.183 358.815 329.648 358.505C329.113 358.195 329.113 357.688 329.648 357.378C330.183 357.068 331.057 357.068 331.592 357.378Z" fill="#00283B" />
        <path d="M336.663 354.447C337.198 354.757 337.198 355.264 336.663 355.574C336.127 355.884 335.254 355.884 334.719 355.574C334.184 355.264 334.184 354.757 334.719 354.447C335.254 354.137 336.127 354.137 336.663 354.447Z" fill="#00283B" />
        <path d="M341.748 351.516C342.283 351.826 342.283 352.333 341.748 352.643C341.212 352.953 340.339 352.953 339.804 352.643C339.269 352.333 339.269 351.826 339.804 351.516C340.339 351.206 341.212 351.206 341.748 351.516Z" fill="#00283B" />
        <path d="M346.818 348.585C347.354 348.895 347.354 349.402 346.818 349.712C346.283 350.022 345.41 350.022 344.875 349.712C344.339 349.402 344.339 348.895 344.875 348.585C345.41 348.275 346.283 348.275 346.818 348.585Z" fill="#00283B" />
        <path d="M351.903 345.654C352.439 345.964 352.439 346.472 351.903 346.782C351.368 347.092 350.495 347.092 349.96 346.782C349.424 346.472 349.424 345.964 349.96 345.654C350.495 345.344 351.368 345.344 351.903 345.654Z" fill="#00283B" />
        <path d="M356.974 342.723C357.509 343.033 357.509 343.54 356.974 343.85C356.439 344.16 355.566 344.16 355.03 343.85C354.495 343.54 354.495 343.033 355.03 342.723C355.566 342.413 356.439 342.413 356.974 342.723Z" fill="#00283B" />
        <path d="M362.059 339.793C362.594 340.103 362.594 340.61 362.059 340.92C361.524 341.23 360.651 341.23 360.115 340.92C359.58 340.61 359.58 340.103 360.115 339.793C360.651 339.483 361.524 339.483 362.059 339.793Z" fill="#00283B" />
        <path d="M367.129 336.847C367.665 337.157 367.665 337.665 367.129 337.975C366.594 338.285 365.721 338.285 365.186 337.975C364.65 337.665 364.65 337.157 365.186 336.847C365.721 336.537 366.594 336.537 367.129 336.847Z" fill="#00283B" />
        <path d="M326.522 354.447C327.057 354.757 327.057 355.264 326.522 355.574C325.986 355.884 325.113 355.884 324.578 355.574C324.042 355.264 324.042 354.757 324.578 354.447C325.113 354.137 325.986 354.137 326.522 354.447Z" fill="#00283B" />
        <path d="M331.592 351.516C332.127 351.826 332.127 352.333 331.592 352.643C331.057 352.953 330.183 352.953 329.648 352.643C329.113 352.333 329.113 351.826 329.648 351.516C330.183 351.206 331.057 351.206 331.592 351.516Z" fill="#00283B" />
        <path d="M336.663 348.585C337.198 348.895 337.198 349.402 336.663 349.712C336.127 350.022 335.254 350.022 334.719 349.712C334.184 349.402 334.184 348.895 334.719 348.585C335.254 348.275 336.127 348.275 336.663 348.585Z" fill="#00283B" />
        <path d="M341.748 345.654C342.283 345.964 342.283 346.472 341.748 346.782C341.212 347.092 340.339 347.092 339.804 346.782C339.269 346.472 339.269 345.964 339.804 345.654C340.339 345.344 341.212 345.344 341.748 345.654Z" fill="#00283B" />
        <path d="M346.818 342.723C347.354 343.033 347.354 343.54 346.818 343.85C346.283 344.16 345.41 344.16 344.875 343.85C344.339 343.54 344.339 343.033 344.875 342.723C345.41 342.413 346.283 342.413 346.818 342.723Z" fill="#00283B" />
        <path d="M351.903 339.793C352.439 340.103 352.439 340.61 351.903 340.92C351.368 341.23 350.495 341.23 349.96 340.92C349.424 340.61 349.424 340.103 349.96 339.793C350.495 339.483 351.368 339.483 351.903 339.793Z" fill="#00283B" />
        <path d="M356.974 336.847C357.509 337.157 357.509 337.665 356.974 337.975C356.439 338.285 355.566 338.285 355.03 337.975C354.495 337.665 354.495 337.157 355.03 336.847C355.566 336.537 356.439 336.537 356.974 336.847Z" fill="#00283B" />
        <path d="M362.059 333.917C362.594 334.227 362.594 334.734 362.059 335.044C361.524 335.354 360.651 335.354 360.115 335.044C359.58 334.734 359.58 334.227 360.115 333.917C360.651 333.607 361.524 333.607 362.059 333.917Z" fill="#00283B" />
        <path d="M321.437 351.516C321.972 351.826 321.972 352.333 321.437 352.643C320.901 352.953 320.028 352.953 319.493 352.643C318.957 352.333 318.957 351.826 319.493 351.516C320.028 351.206 320.901 351.206 321.437 351.516Z" fill="#00283B" />
        <path d="M326.522 348.585C327.057 348.895 327.057 349.402 326.522 349.712C325.986 350.022 325.113 350.022 324.578 349.712C324.042 349.402 324.042 348.895 324.578 348.585C325.113 348.275 325.986 348.275 326.522 348.585Z" fill="#00283B" />
        <path d="M331.592 345.654C332.127 345.964 332.127 346.472 331.592 346.782C331.057 347.092 330.183 347.092 329.648 346.782C329.113 346.472 329.113 345.964 329.648 345.654C330.183 345.344 331.057 345.344 331.592 345.654Z" fill="#00283B" />
        <path d="M336.663 342.723C337.198 343.033 337.198 343.54 336.663 343.85C336.127 344.16 335.254 344.16 334.719 343.85C334.184 343.54 334.184 343.033 334.719 342.723C335.254 342.413 336.127 342.413 336.663 342.723Z" fill="#00283B" />
        <path d="M341.748 339.793C342.283 340.103 342.283 340.61 341.748 340.92C341.212 341.23 340.339 341.23 339.804 340.92C339.269 340.61 339.269 340.103 339.804 339.793C340.339 339.483 341.212 339.483 341.748 339.793Z" fill="#00283B" />
        <path d="M346.818 336.847C347.354 337.157 347.354 337.665 346.818 337.975C346.283 338.285 345.41 338.285 344.875 337.975C344.339 337.665 344.339 337.157 344.875 336.847C345.41 336.537 346.283 336.537 346.818 336.847Z" fill="#00283B" />
        <path d="M351.903 333.917C352.439 334.227 352.439 334.734 351.903 335.044C351.368 335.354 350.495 335.354 349.96 335.044C349.424 334.734 349.424 334.227 349.96 333.917C350.495 333.607 351.368 333.607 351.903 333.917Z" fill="#00283B" />
        <path d="M356.974 330.985C357.509 331.295 357.509 331.803 356.974 332.113C356.439 332.423 355.566 332.423 355.03 332.113C354.495 331.803 354.495 331.295 355.03 330.985C355.566 330.675 356.439 330.675 356.974 330.985Z" fill="#00283B" />
        <path d="M316.366 348.585C316.901 348.895 316.901 349.402 316.366 349.712C315.83 350.022 314.957 350.022 314.422 349.712C313.887 349.402 313.887 348.895 314.422 348.585C314.957 348.275 315.83 348.275 316.366 348.585Z" fill="#00283B" />
        <path d="M321.437 345.654C321.972 345.964 321.972 346.472 321.437 346.782C320.901 347.092 320.028 347.092 319.493 346.782C318.957 346.472 318.957 345.964 319.493 345.654C320.028 345.344 320.901 345.344 321.437 345.654Z" fill="#00283B" />
        <path d="M326.522 342.723C327.057 343.033 327.057 343.54 326.522 343.85C325.986 344.16 325.113 344.16 324.578 343.85C324.042 343.54 324.042 343.033 324.578 342.723C325.113 342.413 325.986 342.413 326.522 342.723Z" fill="#00283B" />
        <path d="M331.592 339.793C332.127 340.103 332.127 340.61 331.592 340.92C331.057 341.23 330.183 341.23 329.648 340.92C329.113 340.61 329.113 340.103 329.648 339.793C330.183 339.483 331.057 339.483 331.592 339.793Z" fill="#00283B" />
        <path d="M336.663 336.847C337.198 337.157 337.198 337.665 336.663 337.975C336.127 338.285 335.254 338.285 334.719 337.975C334.184 337.665 334.184 337.157 334.719 336.847C335.254 336.537 336.127 336.537 336.663 336.847Z" fill="#00283B" />
        <path d="M341.748 333.917C342.283 334.227 342.283 334.734 341.748 335.044C341.212 335.354 340.339 335.354 339.804 335.044C339.269 334.734 339.269 334.227 339.804 333.917C340.339 333.607 341.212 333.607 341.748 333.917Z" fill="#00283B" />
        <path d="M346.818 330.985C347.354 331.295 347.354 331.803 346.818 332.113C346.283 332.423 345.41 332.423 344.875 332.113C344.339 331.803 344.339 331.295 344.875 330.985C345.41 330.675 346.283 330.675 346.818 330.985Z" fill="#00283B" />
        <path d="M351.903 328.055C352.439 328.365 352.439 328.872 351.903 329.182C351.368 329.492 350.495 329.492 349.96 329.182C349.424 328.872 349.424 328.365 349.96 328.055C350.495 327.745 351.368 327.745 351.903 328.055Z" fill="#00283B" />
        <path d="M351.72 322.7C352.255 323.01 352.255 323.518 351.72 323.828C351.185 324.138 350.312 324.138 349.776 323.828C349.241 323.518 349.241 323.01 349.776 322.7C350.312 322.39 351.185 322.39 351.72 322.7Z" fill="#00283B" />
        <path d="M356.791 319.769C357.326 320.079 357.326 320.586 356.791 320.896C356.256 321.206 355.382 321.206 354.847 320.896C354.312 320.586 354.312 320.079 354.847 319.769C355.382 319.459 356.256 319.459 356.791 319.769Z" fill="#00283B" />
        <path d="M361.876 316.838C362.411 317.148 362.411 317.656 361.876 317.966C361.341 318.276 360.467 318.276 359.932 317.966C359.397 317.656 359.397 317.148 359.932 316.838C360.467 316.528 361.341 316.528 361.876 316.838Z" fill="#00283B" />
        <path d="M344.691 319.769C345.227 319.459 346.1 319.459 346.635 319.769C347.171 320.079 347.171 320.586 346.635 320.896C346.1 321.206 345.227 321.206 344.691 320.896C344.156 320.586 344.156 320.079 344.691 319.769Z" fill="#00283B" />
        <path d="M351.72 316.838C352.255 317.148 352.255 317.656 351.72 317.966C351.185 318.276 350.312 318.276 349.776 317.966C349.241 317.656 349.241 317.148 349.776 316.838C350.312 316.528 351.185 316.528 351.72 316.838Z" fill="#00283B" />
        <path d="M339.621 316.838C340.156 316.528 341.029 316.528 341.564 316.838C342.1 317.148 342.1 317.656 341.564 317.966C341.029 318.276 340.156 318.276 339.621 317.966C339.085 317.656 339.085 317.148 339.621 316.838Z" fill="#00283B" />
        <path d="M336.48 313.907C337.015 314.217 337.015 314.725 336.48 315.035C335.944 315.345 335.071 315.345 334.536 315.035C334 314.725 334 314.217 334.536 313.907C335.071 313.597 335.944 313.597 336.48 313.907Z" fill="#00283B" />
        <path d="M380.469 339.863C381.004 339.553 381.877 339.553 382.413 339.863C382.948 340.173 382.948 340.68 382.413 340.99C381.877 341.3 381.004 341.3 380.469 340.99C379.934 340.68 379.934 340.173 380.469 339.863Z" fill="#00283B" />
        <path d="M387.497 336.918C388.032 337.228 388.032 337.735 387.497 338.045C386.962 338.355 386.089 338.355 385.553 338.045C385.018 337.735 385.018 337.228 385.553 336.918C386.089 336.608 386.962 336.608 387.497 336.918Z" fill="#00283B" />
        <path d="M392.568 333.987C393.103 334.297 393.103 334.804 392.568 335.114C392.033 335.424 391.159 335.424 390.624 335.114C390.089 334.804 390.089 334.297 390.624 333.987C391.159 333.677 392.033 333.677 392.568 333.987Z" fill="#00283B" />
        <path d="M397.653 331.056C398.188 331.366 398.188 331.874 397.653 332.184C397.118 332.494 396.244 332.494 395.709 332.184C395.174 331.874 395.174 331.366 395.709 331.056C396.244 330.746 397.118 330.746 397.653 331.056Z" fill="#00283B" />
        <path d="M375.398 336.932C375.933 336.622 376.807 336.622 377.342 336.932C377.877 337.242 377.877 337.749 377.342 338.059C376.807 338.369 375.933 338.369 375.398 338.059C374.863 337.749 374.863 337.242 375.398 336.932Z" fill="#00283B" />
        <path d="M382.413 333.987C382.948 334.297 382.948 334.804 382.413 335.114C381.877 335.424 381.004 335.424 380.469 335.114C379.934 334.804 379.934 334.297 380.469 333.987C381.004 333.677 381.877 333.677 382.413 333.987Z" fill="#00283B" />
        <path d="M387.497 331.056C388.032 331.366 388.032 331.874 387.497 332.184C386.962 332.494 386.089 332.494 385.553 332.184C385.018 331.874 385.018 331.366 385.553 331.056C386.089 330.746 386.962 330.746 387.497 331.056Z" fill="#00283B" />
        <path d="M392.568 328.125C393.103 328.435 393.103 328.942 392.568 329.252C392.033 329.562 391.159 329.562 390.624 329.252C390.089 328.942 390.089 328.435 390.624 328.125C391.159 327.815 392.033 327.815 392.568 328.125Z" fill="#00283B" />
        <path d="M397.653 325.194C398.188 325.504 398.188 326.012 397.653 326.322C397.118 326.632 396.244 326.632 395.709 326.322C395.174 326.012 395.174 325.504 395.709 325.194C396.244 324.884 397.118 324.884 397.653 325.194Z" fill="#00283B" />
        <path d="M370.271 333.959C370.806 333.649 371.679 333.649 372.214 333.959C372.75 334.269 372.75 334.776 372.214 335.086C371.679 335.396 370.806 335.396 370.271 335.086C369.735 334.776 369.735 334.269 370.271 333.959Z" fill="#00283B" />
        <path d="M377.299 331.028C377.835 331.338 377.835 331.845 377.299 332.155C376.764 332.465 375.891 332.465 375.356 332.155C374.82 331.845 374.82 331.338 375.356 331.028C375.891 330.718 376.764 330.718 377.299 331.028Z" fill="#00283B" />
        <path d="M382.37 328.097C382.905 328.407 382.905 328.914 382.37 329.224C381.835 329.534 380.962 329.534 380.426 329.224C379.891 328.914 379.891 328.407 380.426 328.097C380.962 327.787 381.835 327.787 382.37 328.097Z" fill="#00283B" />
        <path d="M387.455 325.166C387.99 325.476 387.99 325.983 387.455 326.293C386.92 326.603 386.047 326.603 385.511 326.293C384.976 325.983 384.976 325.476 385.511 325.166C386.047 324.856 386.92 324.856 387.455 325.166Z" fill="#00283B" />
        <path d="M392.526 322.235C393.061 322.545 393.061 323.052 392.526 323.362C391.991 323.672 391.117 323.672 390.582 323.362C390.047 323.052 390.047 322.545 390.582 322.235C391.117 321.925 391.991 321.925 392.526 322.235Z" fill="#00283B" />
        <path d="M365.2 331.028C365.735 330.718 366.608 330.718 367.144 331.028C367.679 331.338 367.679 331.845 367.144 332.155C366.608 332.465 365.735 332.465 365.2 332.155C364.665 331.845 364.665 331.338 365.2 331.028Z" fill="#00283B" />
        <path d="M372.214 328.097C372.75 328.407 372.75 328.914 372.214 329.224C371.679 329.534 370.806 329.534 370.271 329.224C369.735 328.914 369.735 328.407 370.271 328.097C370.806 327.787 371.679 327.787 372.214 328.097Z" fill="#00283B" />
        <path d="M377.299 325.166C377.835 325.476 377.835 325.983 377.299 326.293C376.764 326.603 375.891 326.603 375.356 326.293C374.82 325.983 374.82 325.476 375.356 325.166C375.891 324.856 376.764 324.856 377.299 325.166Z" fill="#00283B" />
        <path d="M382.37 322.235C382.905 322.545 382.905 323.052 382.37 323.362C381.835 323.672 380.962 323.672 380.426 323.362C379.891 323.052 379.891 322.545 380.426 322.235C380.962 321.925 381.835 321.925 382.37 322.235Z" fill="#00283B" />
        <path d="M360.115 328.097C360.651 327.787 361.524 327.787 362.059 328.097C362.594 328.407 362.594 328.914 362.059 329.224C361.524 329.534 360.651 329.534 360.115 329.224C359.58 328.914 359.58 328.407 360.115 328.097Z" fill="#00283B" />
        <path d="M367.144 325.166C367.679 325.476 367.679 325.983 367.144 326.293C366.608 326.603 365.735 326.603 365.2 326.293C364.665 325.983 364.665 325.476 365.2 325.166C365.735 324.856 366.608 324.856 367.144 325.166Z" fill="#00283B" />
        <path d="M372.214 322.235C372.75 322.545 372.75 323.052 372.214 323.362C371.679 323.672 370.806 323.672 370.271 323.362C369.735 323.052 369.735 322.545 370.271 322.235C370.806 321.925 371.679 321.925 372.214 322.235Z" fill="#00283B" />
        <path d="M377.299 319.304C377.835 319.614 377.835 320.122 377.299 320.432C376.764 320.742 375.891 320.742 375.356 320.432C374.82 320.122 374.82 319.614 375.356 319.304C375.891 318.994 376.764 318.994 377.299 319.304Z" fill="#00283B" />
        <path d="M355.045 325.166C355.58 324.856 356.453 324.856 356.988 325.166C357.524 325.476 357.524 325.983 356.988 326.293C356.453 326.603 355.58 326.603 355.045 326.293C354.509 325.983 354.509 325.476 355.045 325.166Z" fill="#00283B" />
        <path d="M362.073 322.235C362.608 322.545 362.608 323.052 362.073 323.362C361.538 323.672 360.664 323.672 360.129 323.362C359.594 323.052 359.594 322.545 360.129 322.235C360.664 321.925 361.538 321.925 362.073 322.235Z" fill="#00283B" />
        <path d="M367.144 319.304C367.679 319.614 367.679 320.122 367.144 320.432C366.608 320.742 365.735 320.742 365.2 320.432C364.665 320.122 364.665 319.614 365.2 319.304C365.735 318.994 366.608 318.994 367.144 319.304Z" fill="#00283B" />
        <path d="M299.478 276.44V280.879L379.455 326.928V322.63L299.478 276.44Z" fill="#B0E2FA" />
        <path d="M379.455 322.63L299.478 276.44L309.042 270.916L389.019 317.106L379.455 322.63Z" fill="#B0E2FA" />
        <path d="M379.454 322.63L301.069 277.356L309.041 272.748L387.638 318.149L379.454 322.63Z" fill="#F5FCFF" />
        <path d="M389.61 317.444V316.768L389.019 316.43L309.337 270.409L309.041 270.24L299.477 275.763L298.886 276.102V281.217L378.863 327.28L379.455 327.618L380.046 327.28L389.61 321.77V317.458V317.444ZM387.85 317.106L385.554 318.431L379.455 321.953L351.058 305.552L300.646 276.44L309.041 271.592L387.85 317.106ZM300.055 280.526V277.44L378.863 322.954V325.899L300.055 280.512V280.526Z" fill="#00283B" />
        <path d="M286.42 283.443V287.882L366.397 333.945V329.633L286.42 283.443Z" fill="#B0E2FA" />
        <path d="M366.397 329.633L286.42 283.443L295.984 277.919L375.961 324.109L366.397 329.633Z" fill="#B0E2FA" />
        <path d="M366.397 329.633L288.011 284.359L295.984 279.766L374.58 325.152L366.397 329.633Z" fill="#F5FCFF" />
        <path d="M376.553 324.448V323.771L375.961 323.433L296.28 277.412L295.984 277.243L286.42 282.767L285.828 283.105V288.22L365.805 334.283L366.397 334.621L366.989 334.283L376.553 328.774V324.462V324.448ZM374.792 324.11L372.496 325.434L366.397 328.957L338.001 312.555L287.589 283.443L295.984 278.596L374.792 324.11ZM286.997 287.53V284.444L365.805 329.957V332.902L286.997 287.515V287.53Z" fill="#00283B" />
        <path d="M273.363 290.996V295.434L353.34 341.497V337.2L273.363 290.996Z" fill="#B0E2FA" />
        <path d="M353.34 337.199L273.363 290.995L282.927 285.472L362.904 331.676L353.34 337.199Z" fill="#B0E2FA" />
        <path d="M353.34 337.2L274.954 291.926L282.926 287.318L361.523 332.719L353.34 337.2Z" fill="#F5FCFF" />
        <path d="M363.495 332.014V331.338L362.904 331L283.222 284.979L282.927 284.81L273.363 290.333L272.771 290.671V295.786L352.748 341.85L353.34 342.188L353.931 341.85L363.495 336.34V332.028V332.014ZM361.735 331.676L359.439 333L353.34 336.523L324.944 320.121L274.532 291.01L282.927 286.162L361.735 331.676ZM273.94 295.096V292.01L352.748 337.524V340.469L273.94 295.082V295.096Z" fill="#00283B" />
        <path d="M260.306 298.577V303.015L340.283 349.078V344.767L260.306 298.577Z" fill="#B0E2FA" />
        <path d="M340.283 344.766L260.306 298.576L269.87 293.053L349.847 339.243L340.283 344.766Z" fill="#B0E2FA" />
        <path d="M340.283 344.767L261.897 299.493L269.869 294.899L348.466 340.286L340.283 344.767Z" fill="#F5FCFF" />
        <path d="M350.438 339.581V338.905L349.847 338.566L270.165 292.546L269.869 292.376L260.305 297.9L259.714 298.238V303.353L339.691 349.416L340.283 349.755L340.874 349.416L350.438 343.907V339.595V339.581ZM348.678 339.243L346.382 340.567L340.283 344.09L311.886 327.688L261.475 298.576L269.869 293.729L348.678 339.243ZM260.883 302.663V299.577L339.691 345.091V348.036L260.883 302.649V302.663Z" fill="#00283B" />
        <path d="M299.478 262.49V266.928L379.455 312.992V308.694L299.478 262.49Z" fill="#B0E2FA" />
        <path d="M379.455 308.694L299.478 262.489L309.042 256.966L389.019 303.17L379.455 308.694Z" fill="#B0E2FA" />
        <path d="M379.454 308.694L301.069 263.42L309.041 258.812L387.638 304.213L379.454 308.694Z" fill="#F5FCFF" />
        <path d="M389.61 303.508V302.832L389.019 302.494L309.337 256.473L309.041 256.304L299.477 261.827L298.886 262.166V267.281L378.863 313.344L379.455 313.682L380.046 313.344L389.61 307.834V303.522V303.508ZM387.85 303.17L385.554 304.495L379.455 308.017L351.058 291.616L300.646 262.504L309.041 257.656L387.85 303.17ZM300.055 266.59V263.504L378.863 309.018V311.963L300.055 266.576V266.59Z" fill="#00283B" />
        <path d="M286.42 269.507V273.932L366.397 319.995V315.697L286.42 269.507Z" fill="#B0E2FA" />
        <path d="M366.397 315.697L286.42 269.507L295.984 263.983L375.961 310.173L366.397 315.697Z" fill="#B0E2FA" />
        <path d="M366.397 315.697L288.011 270.423L295.984 265.815L374.58 311.216L366.397 315.697Z" fill="#F5FCFF" />
        <path d="M376.553 310.512V309.835L375.961 309.497L296.28 263.476L295.984 263.307L286.42 268.831L285.828 269.169V274.284L365.805 320.347L366.397 320.685L366.989 320.347L376.553 314.838V310.526V310.512ZM374.792 310.173L372.496 311.498L366.397 315.021L338.001 298.619L287.589 269.507L295.984 264.66L374.792 310.173ZM286.997 273.593V270.508L365.805 316.021V318.966L286.997 273.579V273.593Z" fill="#00283B" />
        <path d="M273.363 277.06V281.485L353.34 327.548V323.25L273.363 277.06Z" fill="#B0E2FA" />
        <path d="M353.34 323.25L273.363 277.06L282.927 271.536L362.904 317.726L353.34 323.25Z" fill="#B0E2FA" />
        <path d="M353.34 323.25L274.954 277.976L282.926 273.368L361.523 318.769L353.34 323.25Z" fill="#F5FCFF" />
        <path d="M363.495 318.064V317.388L362.904 317.05L283.222 271.029L282.927 270.86L273.363 276.383L272.771 276.722V281.837L352.748 327.9L353.34 328.238L353.931 327.9L363.495 322.39V318.078V318.064ZM361.735 317.726L359.439 319.051L353.34 322.573L324.944 306.172L274.532 277.06L282.927 272.213L361.735 317.726ZM273.94 281.146V278.06L352.748 323.574V326.519L273.94 281.132V281.146Z" fill="#00283B" />
        <path d="M260.306 284.641V289.065L340.283 335.128V330.831L260.306 284.641Z" fill="#B0E2FA" />
        <path d="M340.283 330.83L260.306 284.64L269.87 279.103L349.847 325.307L340.283 330.83Z" fill="#B0E2FA" />
        <path d="M340.283 330.831L261.897 285.556L269.869 280.949L348.466 326.35L340.283 330.831Z" fill="#F5FCFF" />
        <path d="M350.438 325.645V324.969L349.847 324.63L270.165 278.61L269.869 278.44L260.305 283.964L259.714 284.302V289.417L339.691 335.48L340.283 335.819L340.874 335.48L350.438 329.971V325.659V325.645ZM348.678 325.307L346.382 326.631L340.283 330.154L311.886 313.752L261.475 284.64L269.869 279.793L348.678 325.307ZM260.883 288.727V285.641L339.691 331.155V334.1L260.883 288.713V288.727Z" fill="#00283B" />
        <path d="M318.633 332.338V322.629L330.747 329.633V339.271L318.633 332.338Z" fill="#B0E2FA" />
        <path d="M339.1 334.452L331.325 329.971V329.294L318.042 321.615V332.676L331.325 340.271V338.918L339.1 334.437V334.452ZM330.155 338.256L319.211 332V323.644L330.155 329.971V338.256Z" fill="#00283B" />
        <path d="M268.785 303.41V293.955L280.899 301.028V310.469L268.785 303.41Z" fill="#B0E2FA" />
        <path d="M289.251 305.65L281.476 301.169V300.69L268.193 292.926V303.748L281.476 311.484V310.131L289.251 305.65ZM280.307 309.44L269.362 303.071V294.969L280.307 301.366V309.44Z" fill="#00283B" />
        <path d="M293.125 317.458V307.989L305.238 315.076V324.503L293.125 317.458Z" fill="#B0E2FA" />
        <path d="M313.591 319.685L305.816 315.204V314.725L292.533 306.96V317.768L305.816 325.504V324.151L313.591 319.67V319.685ZM304.647 323.475L293.702 317.106V309.004L304.647 315.401V323.475Z" fill="#00283B" />
        <path d="M349.593 246.074L313.534 225.248L277.476 246.074V287.727L313.534 308.553V266.999L349.593 246.074Z" fill="#F5FCFF" />
        <path d="M277.476 287.585L313.534 308.412V266.872L277.476 245.933V287.585Z" fill="#B0E2FA" />
        <path d="M350.326 245.651L313.549 224.402L276.87 245.58L276.757 245.651V246.482V288.135L312.816 308.961L313.534 309.384L314.253 308.961L349.945 288.346L350.312 288.135V246.482V245.651H350.326ZM313.549 226.079L330.493 235.872L295.871 255.867L278.926 246.074L313.549 226.079ZM312.83 307.299L278.208 287.304V247.314L312.83 267.309V307.299ZM328.226 257.586L313.549 266.069L296.604 256.275L331.226 236.28L348.171 246.074L328.226 257.586Z" fill="#00283B" />
        <path d="M299.21 266.942L292.519 263.081C291.787 262.659 291.026 262.616 290.406 262.969C289.716 263.377 289.308 264.223 289.308 265.308C289.308 267.125 290.392 269.183 291.787 269.986L298.477 273.847C298.886 274.086 299.294 274.199 299.689 274.199C300.013 274.199 300.308 274.115 300.59 273.96C301.28 273.551 301.689 272.705 301.689 271.62C301.689 269.803 300.604 267.745 299.21 266.942ZM299.872 272.705C299.675 272.818 299.365 272.677 299.21 272.593L292.519 268.732C291.561 268.182 290.758 266.618 290.758 265.308C290.758 264.66 290.956 264.321 291.125 264.223C291.336 264.096 291.632 264.237 291.787 264.335L292.251 264.603C292.421 266.167 293.35 267.802 294.519 268.478L300.224 271.775C300.196 272.311 300.027 272.621 299.872 272.705Z" fill="#00283B" />
        <path d="M352.749 315.697L330.55 302.874V277.229L352.749 290.051V315.697Z" fill="#B0E2FA" />
        <path d="M374.961 277.228L352.749 290.051L330.55 277.228L352.749 264.406L374.961 277.228Z" fill="#B0E2FA" />
        <path d="M375.609 283.739L355.65 295.124L333.438 282.301L353.411 270.916L375.609 283.739Z" fill="#F5FCFF" />
        <path d="M352.748 263.645L330.55 276.468L329.902 276.876V303.255L352.101 316.077L352.748 316.458L353.396 316.077L375.595 303.255V276.848L352.734 263.645H352.748ZM352.748 265.153L373.651 277.229L363.158 283.288L352.748 289.305L349.467 287.416L331.846 277.243L352.748 265.167V265.153ZM331.198 302.494V278.356L352.101 290.432V314.57L331.198 302.494Z" fill="#00283B" />
        <path d="M347.396 308.018L344.002 306.059C343.452 305.749 343.016 304.903 343.016 304.185V300.62C343.016 299.901 343.452 299.563 344.002 299.873L347.396 301.832C347.946 302.142 348.382 302.987 348.382 303.706V307.271C348.382 307.989 347.946 308.328 347.396 308.018Z" fill="#00283B" />
        <path d="M81.4907 106.348C82.026 106.658 82.026 107.165 81.4907 107.475C80.9555 107.785 80.0822 107.785 79.5469 107.475C79.0117 107.165 79.0117 106.658 79.5469 106.348C80.0822 106.038 80.9555 106.038 81.4907 106.348Z" fill="#00283B" />
        <path d="M86.561 103.417C87.0963 103.727 87.0963 104.234 86.561 104.544C86.0258 104.854 85.1525 104.854 84.6173 104.544C84.082 104.234 84.082 103.727 84.6173 103.417C85.1525 103.107 86.0258 103.107 86.561 103.417Z" fill="#00283B" />
        <path d="M91.646 100.486C92.1813 100.796 92.1813 101.304 91.646 101.614C91.1108 101.924 90.2375 101.924 89.7022 101.614C89.167 101.304 89.167 100.796 89.7022 100.486C90.2375 100.176 91.1108 100.176 91.646 100.486Z" fill="#00283B" />
        <path d="M96.7168 97.5553C97.2521 97.8653 97.2521 98.3725 96.7168 98.6825C96.1816 98.9925 95.3083 98.9925 94.773 98.6825C94.2378 98.3725 94.2378 97.8653 94.773 97.5553C95.3083 97.2453 96.1816 97.2453 96.7168 97.5553Z" fill="#00283B" />
        <path d="M101.802 94.6243C102.337 94.9343 102.337 95.4416 101.802 95.7516C101.267 96.0616 100.393 96.0616 99.858 95.7516C99.3227 95.4416 99.3227 94.9343 99.858 94.6243C100.393 94.3143 101.267 94.3143 101.802 94.6243Z" fill="#00283B" />
        <path d="M106.873 91.6934C107.408 92.0034 107.408 92.5107 106.873 92.8207C106.337 93.1307 105.464 93.1307 104.929 92.8207C104.394 92.5107 104.394 92.0034 104.929 91.6934C105.464 91.3834 106.337 91.3834 106.873 91.6934Z" fill="#00283B" />
        <path d="M111.958 88.7625C112.493 89.0725 112.493 89.5798 111.958 89.8898C111.422 90.1998 110.549 90.1998 110.014 89.8898C109.478 89.5798 109.478 89.0725 110.014 88.7625C110.549 88.4525 111.422 88.4525 111.958 88.7625Z" fill="#00283B" />
        <path d="M117.028 85.8316C117.564 86.1416 117.564 86.6489 117.028 86.9589C116.493 87.2689 115.62 87.2689 115.085 86.9589C114.549 86.6489 114.549 86.1416 115.085 85.8316C115.62 85.5216 116.493 85.5216 117.028 85.8316Z" fill="#00283B" />
        <path d="M71.335 106.348C71.8702 106.658 71.8702 107.165 71.335 107.475C70.7997 107.785 69.9264 107.785 69.3912 107.475C68.8559 107.165 68.8559 106.658 69.3912 106.348C69.9264 106.038 70.7997 106.038 71.335 106.348Z" fill="#00283B" />
        <path d="M76.4199 103.417C76.9552 103.727 76.9552 104.234 76.4199 104.544C75.8847 104.854 75.0114 104.854 74.4761 104.544C73.9409 104.234 73.9409 103.727 74.4761 103.417C75.0114 103.107 75.8847 103.107 76.4199 103.417Z" fill="#00283B" />
        <path d="M81.4907 100.486C82.026 100.796 82.026 101.304 81.4907 101.614C80.9555 101.924 80.0822 101.924 79.5469 101.614C79.0117 101.304 79.0117 100.796 79.5469 100.486C80.0822 100.176 80.9555 100.176 81.4907 100.486Z" fill="#00283B" />
        <path d="M86.561 97.5553C87.0963 97.8653 87.0963 98.3725 86.561 98.6825C86.0258 98.9925 85.1525 98.9925 84.6173 98.6825C84.082 98.3725 84.082 97.8653 84.6173 97.5553C85.1525 97.2453 86.0258 97.2453 86.561 97.5553Z" fill="#00283B" />
        <path d="M91.646 94.6243C92.1813 94.9343 92.1813 95.4416 91.646 95.7516C91.1108 96.0616 90.2375 96.0616 89.7022 95.7516C89.167 95.4416 89.167 94.9343 89.7022 94.6243C90.2375 94.3143 91.1108 94.3143 91.646 94.6243Z" fill="#00283B" />
        <path d="M96.7168 91.6934C97.2521 92.0034 97.2521 92.5107 96.7168 92.8207C96.1816 93.1307 95.3083 93.1307 94.773 92.8207C94.2378 92.5107 94.2378 92.0034 94.773 91.6934C95.3083 91.3834 96.1816 91.3834 96.7168 91.6934Z" fill="#00283B" />
        <path d="M101.802 88.7625C102.337 89.0725 102.337 89.5798 101.802 89.8898C101.267 90.1998 100.393 90.1998 99.858 89.8898C99.3227 89.5798 99.3227 89.0725 99.858 88.7625C100.393 88.4525 101.267 88.4525 101.802 88.7625Z" fill="#00283B" />
        <path d="M106.873 85.8316C107.408 86.1416 107.408 86.6489 106.873 86.9589C106.337 87.2689 105.464 87.2689 104.929 86.9589C104.394 86.6489 104.394 86.1416 104.929 85.8316C105.464 85.5216 106.337 85.5216 106.873 85.8316Z" fill="#00283B" />
        <path d="M111.958 82.9007C112.493 83.2107 112.493 83.718 111.958 84.028C111.422 84.338 110.549 84.338 110.014 84.028C109.478 83.718 109.478 83.2107 110.014 82.9007C110.549 82.5907 111.422 82.5907 111.958 82.9007Z" fill="#00283B" />
        <path d="M117.028 79.9556C117.564 80.2656 117.564 80.7729 117.028 81.0829C116.493 81.3929 115.62 81.3929 115.085 81.0829C114.549 80.7729 114.549 80.2656 115.085 79.9556C115.62 79.6456 116.493 79.6456 117.028 79.9556Z" fill="#00283B" />
        <path d="M66.2642 103.417C66.7994 103.727 66.7994 104.234 66.2642 104.544C65.7289 104.854 64.8556 104.854 64.3204 104.544C63.7851 104.234 63.7851 103.727 64.3204 103.417C64.8556 103.107 65.7289 103.107 66.2642 103.417Z" fill="#00283B" />
        <path d="M71.335 100.486C71.8702 100.796 71.8702 101.304 71.335 101.614C70.7997 101.924 69.9264 101.924 69.3912 101.614C68.8559 101.304 68.8559 100.796 69.3912 100.486C69.9264 100.176 70.7997 100.176 71.335 100.486Z" fill="#00283B" />
        <path d="M76.4199 97.5553C76.9552 97.8653 76.9552 98.3725 76.4199 98.6825C75.8847 98.9925 75.0114 98.9925 74.4761 98.6825C73.9409 98.3725 73.9409 97.8653 74.4761 97.5553C75.0114 97.2453 75.8847 97.2453 76.4199 97.5553Z" fill="#00283B" />
        <path d="M81.4907 94.6243C82.026 94.9343 82.026 95.4416 81.4907 95.7516C80.9555 96.0616 80.0822 96.0616 79.5469 95.7516C79.0117 95.4416 79.0117 94.9343 79.5469 94.6243C80.0822 94.3143 80.9555 94.3143 81.4907 94.6243Z" fill="#00283B" />
        <path d="M86.561 91.6934C87.0963 92.0034 87.0963 92.5107 86.561 92.8207C86.0258 93.1307 85.1525 93.1307 84.6173 92.8207C84.082 92.5107 84.082 92.0034 84.6173 91.6934C85.1525 91.3834 86.0258 91.3834 86.561 91.6934Z" fill="#00283B" />
        <path d="M91.646 88.7625C92.1813 89.0725 92.1813 89.5798 91.646 89.8898C91.1108 90.1998 90.2375 90.1998 89.7022 89.8898C89.167 89.5798 89.167 89.0725 89.7022 88.7625C90.2375 88.4525 91.1108 88.4525 91.646 88.7625Z" fill="#00283B" />
        <path d="M96.7168 85.8316C97.2521 86.1416 97.2521 86.6489 96.7168 86.9589C96.1816 87.2689 95.3083 87.2689 94.773 86.9589C94.2378 86.6489 94.2378 86.1416 94.773 85.8316C95.3083 85.5216 96.1816 85.5216 96.7168 85.8316Z" fill="#00283B" />
        <path d="M101.802 82.9007C102.337 83.2107 102.337 83.718 101.802 84.028C101.267 84.338 100.393 84.338 99.858 84.028C99.3227 83.718 99.3227 83.2107 99.858 82.9007C100.393 82.5907 101.267 82.5907 101.802 82.9007Z" fill="#00283B" />
        <path d="M106.873 79.9556C107.408 80.2656 107.408 80.7729 106.873 81.0829C106.337 81.3929 105.464 81.3929 104.929 81.0829C104.394 80.7729 104.394 80.2656 104.929 79.9556C105.464 79.6456 106.337 79.6456 106.873 79.9556Z" fill="#00283B" />
        <path d="M111.958 77.0247C112.493 77.3347 112.493 77.842 111.958 78.152C111.422 78.462 110.549 78.462 110.014 78.152C109.478 77.842 109.478 77.3347 110.014 77.0247C110.549 76.7147 111.422 76.7147 111.958 77.0247Z" fill="#00283B" />
        <path d="M61.1792 100.486C61.7145 100.796 61.7145 101.304 61.1792 101.614C60.644 101.924 59.7707 101.924 59.2354 101.614C58.7002 101.304 58.7002 100.796 59.2354 100.486C59.7707 100.176 60.644 100.176 61.1792 100.486Z" fill="#00283B" />
        <path d="M66.2642 97.5553C66.7994 97.8653 66.7994 98.3725 66.2642 98.6825C65.7289 98.9925 64.8556 98.9925 64.3204 98.6825C63.7851 98.3725 63.7851 97.8653 64.3204 97.5553C64.8556 97.2453 65.7289 97.2453 66.2642 97.5553Z" fill="#00283B" />
        <path d="M71.335 94.6243C71.8702 94.9343 71.8702 95.4416 71.335 95.7516C70.7997 96.0616 69.9264 96.0616 69.3912 95.7516C68.8559 95.4416 68.8559 94.9343 69.3912 94.6243C69.9264 94.3143 70.7997 94.3143 71.335 94.6243Z" fill="#00283B" />
        <path d="M56.1084 97.5553C56.6437 97.8653 56.6437 98.3725 56.1084 98.6825C55.5732 98.9925 54.6999 98.9925 54.1646 98.6825C53.6294 98.3725 53.6294 97.8653 54.1646 97.5553C54.6999 97.2453 55.5732 97.2453 56.1084 97.5553Z" fill="#00283B" />
        <path d="M61.1792 94.6243C61.7145 94.9343 61.7145 95.4416 61.1792 95.7516C60.644 96.0616 59.7707 96.0616 59.2354 95.7516C58.7002 95.4416 58.7002 94.9343 59.2354 94.6243C59.7707 94.3143 60.644 94.3143 61.1792 94.6243Z" fill="#00283B" />
        <path d="M66.2642 91.6934C66.7994 92.0034 66.7994 92.5107 66.2642 92.8207C65.7289 93.1307 64.8556 93.1307 64.3204 92.8207C63.7851 92.5107 63.7851 92.0034 64.3204 91.6934C64.8556 91.3834 65.7289 91.3834 66.2642 91.6934Z" fill="#00283B" />
        <path d="M51.0376 94.6243C51.5729 94.9343 51.5729 95.4416 51.0376 95.7516C50.5024 96.0616 49.6291 96.0616 49.0938 95.7516C48.5586 95.4416 48.5586 94.9343 49.0938 94.6243C49.6291 94.3143 50.5024 94.3143 51.0376 94.6243Z" fill="#00283B" />
        <path d="M56.1084 91.6934C56.6437 92.0034 56.6437 92.5107 56.1084 92.8207C55.5732 93.1307 54.6999 93.1307 54.1646 92.8207C53.6294 92.5107 53.6294 92.0034 54.1646 91.6934C54.6999 91.3834 55.5732 91.3834 56.1084 91.6934Z" fill="#00283B" />
        <path d="M61.1792 88.7625C61.7145 89.0725 61.7145 89.5798 61.1792 89.8898C60.644 90.1998 59.7707 90.1998 59.2354 89.8898C58.7002 89.5798 58.7002 89.0725 59.2354 88.7625C59.7707 88.4525 60.644 88.4525 61.1792 88.7625Z" fill="#00283B" />
        <path d="M45.9107 91.6654C46.4459 91.9754 46.4459 92.4826 45.9107 92.7926C45.3754 93.1026 44.5021 93.1026 43.9669 92.7926C43.4316 92.4826 43.4316 91.9754 43.9669 91.6654C44.5021 91.3554 45.3754 91.3554 45.9107 91.6654Z" fill="#00283B" />
        <path d="M50.9815 88.7345C51.5167 89.0445 51.5167 89.5517 50.9815 89.8617C50.4462 90.1717 49.5729 90.1717 49.0377 89.8617C48.5024 89.5517 48.5024 89.0445 49.0377 88.7345C49.5729 88.4245 50.4462 88.4245 50.9815 88.7345Z" fill="#00283B" />
        <path d="M56.0664 85.7894C56.6017 86.0994 56.6017 86.6067 56.0664 86.9167C55.5312 87.2267 54.6579 87.2267 54.1226 86.9167C53.5874 86.6067 53.5874 86.0994 54.1226 85.7894C54.6579 85.4794 55.5312 85.4794 56.0664 85.7894Z" fill="#00283B" />
        <path d="M40.8399 88.7345C41.3751 89.0445 41.3751 89.5517 40.8399 89.8617C40.3046 90.1717 39.4313 90.1717 38.8961 89.8617C38.3608 89.5517 38.3608 89.0445 38.8961 88.7345C39.4313 88.4245 40.3046 88.4245 40.8399 88.7345Z" fill="#00283B" />
        <path d="M45.9107 85.7894C46.4459 86.0994 46.4459 86.6067 45.9107 86.9167C45.3754 87.2267 44.5021 87.2267 43.9669 86.9167C43.4316 86.6067 43.4316 86.0994 43.9669 85.7894C44.5021 85.4794 45.3754 85.4794 45.9107 85.7894Z" fill="#00283B" />
        <path d="M50.9815 82.8585C51.5167 83.1685 51.5167 83.6757 50.9815 83.9857C50.4462 84.2957 49.5729 84.2957 49.0377 83.9857C48.5024 83.6757 48.5024 83.1685 49.0377 82.8585C49.5729 82.5485 50.4462 82.5485 50.9815 82.8585Z" fill="#00283B" />
        <path d="M40.8399 82.8585C41.3751 83.1685 41.3751 83.6757 40.8399 83.9857C40.3046 84.2957 39.4313 84.2957 38.8961 83.9857C38.3608 83.6757 38.3608 83.1685 38.8961 82.8585C39.4313 82.5485 40.3046 82.5485 40.8399 82.8585Z" fill="#00283B" />
        <path d="M45.9107 79.9276C46.4459 80.2376 46.4459 80.7448 45.9107 81.0548C45.3754 81.3648 44.5021 81.3648 43.9669 81.0548C43.4316 80.7448 43.4316 80.2376 43.9669 79.9276C44.5021 79.6176 45.3754 79.6176 45.9107 79.9276Z" fill="#00283B" />
        <path d="M40.8399 76.9967C41.3751 77.3067 41.3751 77.8139 40.8399 78.1239C40.3046 78.4339 39.4313 78.4339 38.8961 78.1239C38.3608 77.8139 38.3608 77.3067 38.8961 76.9967C39.4313 76.6867 40.3046 76.6867 40.8399 76.9967Z" fill="#00283B" />
        <path d="M111.422 31.5533L75.518 10.8115L39.6001 31.5533V73.0511L75.518 93.7929V52.4079L111.422 31.5533Z" fill="#F5FCFF" />
        <path d="M39.6001 72.9102L75.518 93.652V52.2811L39.6001 31.4265V72.9102Z" fill="#B0E2FA" />
        <path d="M112.14 31.1448L75.504 9.98022L38.9804 31.0743L38.8677 31.1307V31.962V73.4457L74.7856 94.1875L75.504 94.6102L76.2223 94.1875L111.774 73.657L112.14 73.4457V31.962V31.1307V31.1448ZM75.504 11.643L92.3783 21.3939L57.8971 41.3043L41.0227 31.5534L75.504 11.643ZM74.7856 92.5389L40.3044 72.6284V32.8075L74.7856 52.7179V92.5389ZM90.1247 43.0234L75.504 51.4639L58.6296 41.713L93.1108 21.8025L109.985 31.5534L90.1247 43.0234Z" fill="#00283B" />
        <path d="M61.2495 52.3515L54.5871 48.5047C53.8547 48.0819 53.1082 48.0397 52.4884 48.392C51.7982 48.7865 51.4038 49.646 51.4038 50.717C51.4038 52.5347 52.4884 54.5779 53.8688 55.381L60.5312 59.2279C60.9397 59.4674 61.3481 59.5801 61.7285 59.5801C62.0524 59.5801 62.3482 59.4956 62.6299 59.3406C63.3201 58.946 63.7145 58.0865 63.7145 57.0156C63.7145 55.1979 62.6299 53.1547 61.2495 52.3515ZM61.9116 58.1006C61.7144 58.2133 61.4045 58.0865 61.2495 57.9879L54.5871 54.141C53.6434 53.5915 52.8405 52.0274 52.8405 50.731C52.8405 50.0829 53.0377 49.7447 53.2067 49.646C53.418 49.5192 53.7138 49.6601 53.8688 49.7588L54.3336 50.0265C54.5026 51.5906 55.4182 53.2251 56.5873 53.8874L62.2778 57.1706C62.2496 57.706 62.0806 58.002 61.9256 58.1006H61.9116Z" fill="#00283B" />
        <path d="M106.239 60.9188C105.9 60.7215 105.492 60.7497 105.084 60.9892L99.7733 64.0469C98.9845 64.4978 98.3647 65.6674 98.3647 66.696V72.3888C98.3647 72.9947 98.5901 73.4738 98.9704 73.6851C99.1254 73.7697 99.2944 73.8119 99.4634 73.8119C99.6747 73.8119 99.9001 73.7415 100.125 73.6147L105.436 70.5569C106.224 70.106 106.844 68.9365 106.844 67.9078V62.2151C106.844 61.6092 106.619 61.1301 106.239 60.9188Z" fill="#B0E2FA" />
        <path d="M322.337 198.573C322.873 198.883 322.873 199.39 322.337 199.7C321.802 200.01 320.929 200.01 320.394 199.7C319.858 199.39 319.858 198.883 320.394 198.573C320.929 198.263 321.802 198.263 322.337 198.573Z" fill="#00283B" />
        <path d="M327.422 195.628C327.958 195.938 327.958 196.445 327.422 196.755C326.887 197.065 326.014 197.065 325.479 196.755C324.943 196.445 324.943 195.938 325.479 195.628C326.014 195.318 326.887 195.318 327.422 195.628Z" fill="#00283B" />
        <path d="M332.493 192.697C333.028 193.007 333.028 193.514 332.493 193.824C331.958 194.134 331.085 194.134 330.549 193.824C330.014 193.514 330.014 193.007 330.549 192.697C331.085 192.387 331.958 192.387 332.493 192.697Z" fill="#00283B" />
        <path d="M317.267 195.628C317.802 195.938 317.802 196.445 317.267 196.755C316.731 197.065 315.858 197.065 315.323 196.755C314.788 196.445 314.788 195.938 315.323 195.628C315.858 195.318 316.731 195.318 317.267 195.628Z" fill="#00283B" />
        <path d="M322.337 192.697C322.873 193.007 322.873 193.514 322.337 193.824C321.802 194.134 320.929 194.134 320.394 193.824C319.858 193.514 319.858 193.007 320.394 192.697C320.929 192.387 321.802 192.387 322.337 192.697Z" fill="#00283B" />
        <path d="M327.422 189.766C327.958 190.076 327.958 190.583 327.422 190.893C326.887 191.203 326.014 191.203 325.479 190.893C324.943 190.583 324.943 190.076 325.479 189.766C326.014 189.456 326.887 189.456 327.422 189.766Z" fill="#00283B" />
        <path d="M307.111 195.628C307.647 195.938 307.647 196.445 307.111 196.755C306.576 197.065 305.703 197.065 305.168 196.755C304.632 196.445 304.632 195.938 305.168 195.628C305.703 195.318 306.576 195.318 307.111 195.628Z" fill="#00283B" />
        <path d="M312.182 192.697C312.717 193.007 312.717 193.514 312.182 193.824C311.647 194.134 310.774 194.134 310.238 193.824C309.703 193.514 309.703 193.007 310.238 192.697C310.774 192.387 311.647 192.387 312.182 192.697Z" fill="#00283B" />
        <path d="M317.267 189.766C317.802 190.076 317.802 190.583 317.267 190.893C316.731 191.203 315.858 191.203 315.323 190.893C314.788 190.583 314.788 190.076 315.323 189.766C315.858 189.456 316.731 189.456 317.267 189.766Z" fill="#00283B" />
        <path d="M322.337 186.835C322.873 187.145 322.873 187.652 322.337 187.962C321.802 188.272 320.929 188.272 320.394 187.962C319.858 187.652 319.858 187.145 320.394 186.835C320.929 186.525 321.802 186.525 322.337 186.835Z" fill="#00283B" />
        <path d="M296.956 195.628C297.491 195.938 297.491 196.445 296.956 196.755C296.42 197.065 295.547 197.065 295.012 196.755C294.477 196.445 294.477 195.938 295.012 195.628C295.547 195.318 296.42 195.318 296.956 195.628Z" fill="#00283B" />
        <path d="M302.041 192.697C302.576 193.007 302.576 193.514 302.041 193.824C301.505 194.134 300.632 194.134 300.097 193.824C299.562 193.514 299.562 193.007 300.097 192.697C300.632 192.387 301.505 192.387 302.041 192.697Z" fill="#00283B" />
        <path d="M307.111 189.766C307.647 190.076 307.647 190.583 307.111 190.893C306.576 191.203 305.703 191.203 305.168 190.893C304.632 190.583 304.632 190.076 305.168 189.766C305.703 189.456 306.576 189.456 307.111 189.766Z" fill="#00283B" />
        <path d="M291.885 192.697C292.42 193.007 292.42 193.514 291.885 193.824C291.35 194.134 290.476 194.134 289.941 193.824C289.406 193.514 289.406 193.007 289.941 192.697C290.476 192.387 291.35 192.387 291.885 192.697Z" fill="#00283B" />
        <path d="M296.956 189.766C297.491 190.076 297.491 190.583 296.956 190.893C296.42 191.203 295.547 191.203 295.012 190.893C294.477 190.583 294.477 190.076 295.012 189.766C295.547 189.456 296.42 189.456 296.956 189.766Z" fill="#00283B" />
        <path d="M286.758 189.738C287.293 190.048 287.293 190.555 286.758 190.865C286.223 191.175 285.349 191.175 284.814 190.865C284.279 190.555 284.279 190.048 284.814 189.738C285.349 189.428 286.223 189.428 286.758 189.738Z" fill="#00283B" />
        <path d="M291.843 186.807C292.378 187.117 292.378 187.624 291.843 187.934C291.308 188.244 290.434 188.244 289.899 187.934C289.364 187.624 289.364 187.117 289.899 186.807C290.434 186.497 291.308 186.497 291.843 186.807Z" fill="#00283B" />
        <path d="M281.687 186.807C282.222 187.117 282.222 187.624 281.687 187.934C281.152 188.244 280.278 188.244 279.743 187.934C279.208 187.624 279.208 187.117 279.743 186.807C280.278 186.497 281.152 186.497 281.687 186.807Z" fill="#00283B" />
        <path d="M286.758 183.876C287.293 184.186 287.293 184.693 286.758 185.003C286.223 185.313 285.349 185.313 284.814 185.003C284.279 184.693 284.279 184.186 284.814 183.876C285.349 183.566 286.223 183.566 286.758 183.876Z" fill="#00283B" />
        <path d="M276.602 183.876C277.137 184.186 277.137 184.693 276.602 185.003C276.067 185.313 275.194 185.313 274.658 185.003C274.123 184.693 274.123 184.186 274.658 183.876C275.194 183.566 276.067 183.566 276.602 183.876Z" fill="#00283B" />
        <path d="M281.687 180.945C282.222 181.255 282.222 181.762 281.687 182.072C281.152 182.382 280.278 182.382 279.743 182.072C279.208 181.762 279.208 181.255 279.743 180.945C280.278 180.635 281.152 180.635 281.687 180.945Z" fill="#00283B" />
        <path d="M276.602 178.014C277.137 178.324 277.137 178.832 276.602 179.142C276.067 179.452 275.194 179.452 274.658 179.142C274.123 178.832 274.123 178.324 274.658 178.014C275.194 177.704 276.067 177.704 276.602 178.014Z" fill="#00283B" />
        <path d="M358.115 218.652C358.65 218.962 358.65 219.47 358.115 219.78C357.58 220.09 356.706 220.09 356.171 219.78C355.636 219.47 355.636 218.962 356.171 218.652C356.706 218.342 357.58 218.342 358.115 218.652Z" fill="#00283B" />
        <path d="M363.2 215.721C363.735 216.031 363.735 216.539 363.2 216.849C362.664 217.159 361.791 217.159 361.256 216.849C360.721 216.539 360.721 216.031 361.256 215.721C361.791 215.411 362.664 215.411 363.2 215.721Z" fill="#00283B" />
        <path d="M368.27 212.791C368.805 213.101 368.805 213.608 368.27 213.918C367.735 214.228 366.861 214.228 366.326 213.918C365.791 213.608 365.791 213.101 366.326 212.791C366.861 212.481 367.735 212.481 368.27 212.791Z" fill="#00283B" />
        <path d="M373.355 209.859C373.89 210.169 373.89 210.677 373.355 210.987C372.82 211.297 371.946 211.297 371.411 210.987C370.876 210.677 370.876 210.169 371.411 209.859C371.946 209.549 372.82 209.549 373.355 209.859Z" fill="#00283B" />
        <path d="M347.973 218.652C348.508 218.962 348.508 219.47 347.973 219.78C347.438 220.09 346.565 220.09 346.029 219.78C345.494 219.47 345.494 218.962 346.029 218.652C346.565 218.342 347.438 218.342 347.973 218.652Z" fill="#00283B" />
        <path d="M353.044 215.721C353.579 216.031 353.579 216.539 353.044 216.849C352.509 217.159 351.635 217.159 351.1 216.849C350.565 216.539 350.565 216.031 351.1 215.721C351.635 215.411 352.509 215.411 353.044 215.721Z" fill="#00283B" />
        <path d="M358.115 212.791C358.65 213.101 358.65 213.608 358.115 213.918C357.58 214.228 356.706 214.228 356.171 213.918C355.636 213.608 355.636 213.101 356.171 212.791C356.706 212.481 357.58 212.481 358.115 212.791Z" fill="#00283B" />
        <path d="M363.2 209.859C363.735 210.169 363.735 210.677 363.2 210.987C362.664 211.297 361.791 211.297 361.256 210.987C360.721 210.677 360.721 210.169 361.256 209.859C361.791 209.549 362.664 209.549 363.2 209.859Z" fill="#00283B" />
        <path d="M368.27 206.929C368.805 207.239 368.805 207.746 368.27 208.056C367.735 208.366 366.861 208.366 366.326 208.056C365.791 207.746 365.791 207.239 366.326 206.929C366.861 206.619 367.735 206.619 368.27 206.929Z" fill="#00283B" />
        <path d="M373.355 203.998C373.89 204.308 373.89 204.815 373.355 205.125C372.82 205.435 371.946 205.435 371.411 205.125C370.876 204.815 370.876 204.308 371.411 203.998C371.946 203.688 372.82 203.688 373.355 203.998Z" fill="#00283B" />
        <path d="M342.888 215.721C343.423 216.031 343.423 216.539 342.888 216.849C342.353 217.159 341.48 217.159 340.944 216.849C340.409 216.539 340.409 216.031 340.944 215.721C341.48 215.411 342.353 215.411 342.888 215.721Z" fill="#00283B" />
        <path d="M347.973 212.791C348.508 213.101 348.508 213.608 347.973 213.918C347.438 214.228 346.565 214.228 346.029 213.918C345.494 213.608 345.494 213.101 346.029 212.791C346.565 212.481 347.438 212.481 347.973 212.791Z" fill="#00283B" />
        <path d="M368.27 201.067C368.805 201.377 368.805 201.884 368.27 202.194C367.735 202.504 366.861 202.504 366.326 202.194C365.791 201.884 365.791 201.377 366.326 201.067C366.861 200.757 367.735 200.757 368.27 201.067Z" fill="#00283B" />
        <path d="M337.775 212.762C338.311 213.072 338.311 213.58 337.775 213.89C337.24 214.2 336.367 214.2 335.832 213.89C335.296 213.58 335.296 213.072 335.832 212.762C336.367 212.452 337.24 212.452 337.775 212.762Z" fill="#00283B" />
        <path d="M342.846 209.832C343.381 210.142 343.381 210.649 342.846 210.959C342.311 211.269 341.438 211.269 340.902 210.959C340.367 210.649 340.367 210.142 340.902 209.832C341.438 209.522 342.311 209.522 342.846 209.832Z" fill="#00283B" />
        <path d="M332.69 209.832C333.226 210.142 333.226 210.649 332.69 210.959C332.155 211.269 331.282 211.269 330.747 210.959C330.211 210.649 330.211 210.142 330.747 209.832C331.282 209.522 332.155 209.522 332.69 209.832Z" fill="#00283B" />
        <path d="M337.775 206.9C338.311 207.21 338.311 207.718 337.775 208.028C337.24 208.338 336.367 208.338 335.832 208.028C335.296 207.718 335.296 207.21 335.832 206.9C336.367 206.59 337.24 206.59 337.775 206.9Z" fill="#00283B" />
        <path d="M327.62 206.9C328.155 207.21 328.155 207.718 327.62 208.028C327.084 208.338 326.211 208.338 325.676 208.028C325.141 207.718 325.141 207.21 325.676 206.9C326.211 206.59 327.084 206.59 327.62 206.9Z" fill="#00283B" />
        <path d="M332.69 203.956C333.226 204.266 333.226 204.773 332.69 205.083C332.155 205.393 331.282 205.393 330.747 205.083C330.211 204.773 330.211 204.266 330.747 203.956C331.282 203.646 332.155 203.646 332.69 203.956Z" fill="#00283B" />
        <path d="M327.62 201.024C328.155 201.334 328.155 201.842 327.62 202.152C327.084 202.462 326.211 202.462 325.676 202.152C325.141 201.842 325.141 201.334 325.676 201.024C326.211 200.714 327.084 200.714 327.62 201.024Z" fill="#00283B" />
        <path d="M332.69 198.094C333.226 198.404 333.226 198.911 332.69 199.221C332.155 199.531 331.282 199.531 330.747 199.221C330.211 198.911 330.211 198.404 330.747 198.094C331.282 197.784 332.155 197.784 332.69 198.094Z" fill="#00283B" />
        <path d="M412.469 105.963L364.366 134.015L365.075 135.232L413.178 107.18L412.469 105.963Z" fill="#F5FCFF" />
        <path d="M407.891 126.039L397.366 132.18L398.075 133.397L408.6 127.257L407.891 126.039Z" fill="#F5FCFF" />
        <path d="M392.778 134.88L366.149 150.428L366.859 151.645L393.488 136.097L392.778 134.88Z" fill="#F5FCFF" />
        <path d="M294.829 122.032C302.238 119.157 310.45 120.228 315.239 121.327C317.45 121.834 318.718 124.173 317.957 126.315C317.309 128.133 315.436 129.19 313.577 128.767C308.154 127.541 297.181 126.259 291.42 133.727C286.223 140.462 288.505 146.451 290.575 149.664C291.547 151.172 291.364 153.144 290.096 154.427C288.392 156.146 285.518 155.892 284.223 153.849C280.546 148.1 278.025 138.659 285.406 129.091C288.082 125.611 291.378 123.398 294.843 122.06L294.829 122.032Z" fill="#B0E2FA" />
        <path d="M316.746 124.751C311.957 123.666 303.745 122.595 296.336 125.456C292.871 126.794 289.575 129.006 286.899 132.487C281.884 138.997 281.448 145.436 282.828 150.692C283.279 151.665 283.786 152.581 284.307 153.398C285.603 155.427 288.49 155.681 290.181 153.976C290.815 153.327 291.181 152.51 291.251 151.665C290.73 150.636 290.265 149.424 289.998 148.086C288.195 144.718 286.871 139.293 291.505 133.29C297.266 125.822 308.238 127.104 313.661 128.33C315.535 128.753 317.408 127.696 318.042 125.892C318.098 125.723 318.126 125.568 318.154 125.413C317.746 125.103 317.267 124.864 316.732 124.751H316.746Z" fill="#F5FCFF" />
        <path d="M287.363 156.3C287.194 156.3 287.04 156.3 286.87 156.272C285.504 156.131 284.307 155.385 283.56 154.243C281.025 150.284 276.095 139.885 284.786 128.612C287.335 125.3 290.617 122.863 294.561 121.327C302.308 118.325 310.957 119.565 315.408 120.58C316.661 120.862 317.746 121.679 318.366 122.82C318.999 123.962 319.112 125.329 318.661 126.569C317.887 128.753 315.619 130.007 313.393 129.5C307.731 128.231 297.435 127.146 292.012 134.178C287.293 140.307 288.969 145.746 291.195 149.227C292.364 151.059 292.138 153.412 290.617 154.934C289.744 155.821 288.575 156.3 287.363 156.3ZM295.111 122.736C291.434 124.159 288.364 126.442 285.997 129.528C277.898 140.04 282.504 149.734 284.856 153.412C285.335 154.173 286.138 154.652 287.04 154.75C287.969 154.849 288.885 154.525 289.547 153.863C290.561 152.848 290.716 151.27 289.927 150.044C287.885 146.86 285.279 140.434 290.814 133.234C296.758 125.526 307.731 126.639 313.746 127.992C315.225 128.33 316.732 127.485 317.253 126.033C317.549 125.202 317.478 124.286 317.056 123.525C316.633 122.764 315.943 122.229 315.098 122.045C310.802 121.073 302.505 119.861 295.125 122.722L295.111 122.736Z" fill="#00283B" />
        <path d="M314.295 120.087C314.295 120.087 314.239 120.045 314.126 119.96C314.056 119.918 313.985 119.861 313.915 119.819C312.436 118.889 307.013 115.958 303.238 120.707C299.463 125.427 303.21 130.091 304.393 131.36C304.463 131.444 304.534 131.515 304.604 131.585C304.675 131.655 304.731 131.698 304.731 131.698C305.59 132.402 306.914 132.543 308.506 131.951C311.689 130.768 314.718 127.09 315.281 123.736C315.563 122.06 315.168 120.791 314.309 120.073L314.295 120.087Z" fill="#B0E2FA" />
        <path d="M306.618 133.107C305.703 133.107 304.886 132.839 304.238 132.304L304.069 132.134C303.985 132.064 303.886 131.965 303.801 131.867C302.506 130.472 298.491 125.441 302.632 120.256C305.942 116.113 310.83 116.987 314.309 119.199C314.365 119.227 314.464 119.298 314.549 119.354L314.774 119.509C315.887 120.425 316.323 121.975 316.013 123.877C315.408 127.527 312.21 131.388 308.759 132.684C307.999 132.966 307.281 133.107 306.618 133.107ZM308.492 118.861C306.914 118.861 305.238 119.439 303.83 121.2C300.449 125.427 303.83 129.654 304.942 130.852C305.013 130.937 305.069 130.993 305.125 131.049C305.886 131.684 306.957 131.74 308.224 131.261C311.168 130.162 313.999 126.738 314.506 123.638C314.732 122.313 314.478 121.27 313.816 120.707L313.689 120.622C313.689 120.622 313.591 120.552 313.534 120.524C312.689 119.988 310.675 118.875 308.478 118.875L308.492 118.861Z" fill="#00283B" />
        <path d="M328.55 156.582L321.169 152.355C320.676 151.946 320.112 151.636 319.521 151.411C317.394 150.622 314.675 150.974 311.717 152.693C304.928 156.625 299.407 166.164 299.407 174.013C299.407 177.691 300.618 180.326 302.604 181.692L311.281 186.751C311.872 187.103 312.647 186.836 312.901 186.187L316.366 177.366C319.549 174.027 322.042 169.588 323.253 165.178L326.141 160.838L327.789 160.401L329 158.076C329.282 157.541 329.085 156.878 328.564 156.582H328.55Z" fill="#F5FCFF" />
        <path d="M311.844 187.667C311.52 187.667 311.182 187.582 310.9 187.413L302.224 182.355C299.9 180.734 298.646 177.789 298.646 174.013C298.646 165.939 304.337 156.075 311.337 152.031C314.379 150.27 317.38 149.79 319.788 150.692C320.478 150.946 321.098 151.312 321.662 151.763L328.944 155.92C329.831 156.427 330.155 157.526 329.676 158.428L328.31 161.049L326.62 161.5L323.958 165.502C322.732 169.955 320.211 174.421 317.028 177.789L313.619 186.469C313.422 186.976 312.999 187.385 312.478 187.568C312.281 187.639 312.069 187.667 311.858 187.667H311.844ZM317.211 151.777C315.647 151.777 313.886 152.313 312.084 153.355C305.506 157.16 300.153 166.418 300.153 174.013C300.153 177.282 301.167 179.79 303.027 181.072L311.647 186.103C311.774 186.173 311.886 186.145 311.957 186.131C312.013 186.117 312.126 186.06 312.182 185.92L315.802 176.859C318.887 173.618 321.338 169.292 322.507 164.995L322.605 164.769L325.662 160.19L327.268 159.753L328.324 157.724C328.423 157.555 328.352 157.343 328.183 157.245L320.802 153.017C320.267 152.595 319.788 152.313 319.267 152.13C318.633 151.89 317.957 151.777 317.225 151.777H317.211Z" fill="#00283B" />
        <path d="M332.944 164.995C332.944 172.843 327.437 182.397 320.633 186.314C313.83 190.231 308.323 187.061 308.323 179.212C308.323 171.364 313.83 161.81 320.633 157.893C327.423 153.961 332.944 157.146 332.944 164.995Z" fill="#00283B" />
        <path d="M315.14 188.766C313.844 188.766 312.633 188.456 311.562 187.836C308.999 186.357 307.576 183.285 307.576 179.212C307.576 171.138 313.267 161.275 320.267 157.231C323.803 155.187 327.155 154.892 329.733 156.371C332.296 157.851 333.719 160.922 333.719 164.995C333.719 173.069 328.028 182.932 321.028 186.977C318.971 188.16 316.971 188.766 315.154 188.766H315.14ZM326.127 156.963C324.577 156.963 322.831 157.498 321.014 158.541C314.436 162.346 309.083 171.603 309.083 179.198C309.083 182.707 310.224 185.3 312.309 186.512C314.394 187.709 317.211 187.399 320.253 185.652C326.831 181.847 332.183 172.59 332.183 164.995C332.183 161.486 331.043 158.893 328.958 157.682C328.127 157.202 327.169 156.963 326.127 156.963Z" fill="#00283B" />
        <path d="M328.86 167.193C328.86 172.435 325.183 178.818 320.648 181.439C316.112 184.06 312.436 181.932 312.436 176.69C312.436 171.448 316.112 165.065 320.648 162.444C325.183 159.823 328.86 161.951 328.86 167.193Z" fill="#00283B" />
        <path d="M316.971 183.327C316.056 183.327 315.211 183.116 314.45 182.679C312.647 181.636 311.661 179.522 311.661 176.704C311.661 171.223 315.521 164.544 320.253 161.81C322.69 160.401 325.028 160.204 326.831 161.232C328.634 162.275 329.62 164.389 329.62 167.207C329.62 172.688 325.761 179.367 321.028 182.101C319.619 182.918 318.239 183.327 316.971 183.327ZM324.31 162.092C323.324 162.092 322.197 162.43 321.028 163.121C316.704 165.615 313.182 171.716 313.182 176.704C313.182 178.959 313.901 180.607 315.211 181.368C316.521 182.115 318.309 181.918 320.267 180.791C324.592 178.296 328.113 172.209 328.113 167.207C328.113 164.952 327.395 163.304 326.085 162.543C325.563 162.247 324.972 162.092 324.324 162.092H324.31Z" fill="#B0E2FA" />
        <path d="M351.96 178.423L329.381 165.403C329.085 165.164 328.747 164.967 328.381 164.84C327.099 164.361 325.465 164.586 323.69 165.615C319.605 167.982 316.281 173.717 316.281 178.452C316.281 180.664 317.014 182.242 318.211 183.073L341.579 196.601C341.931 196.812 342.396 196.643 342.551 196.262L344.635 190.95C346.551 188.949 348.058 186.272 348.776 183.609L350.523 181.002L351.509 180.734L352.241 179.339C352.41 179.015 352.298 178.621 351.974 178.437L351.96 178.423Z" fill="#F5FCFF" />
        <path d="M351.96 175.126L329.381 162.106C329.085 161.866 328.747 161.669 328.381 161.542C327.099 161.063 325.465 161.289 323.69 162.317C319.605 164.685 316.281 170.42 316.281 175.154C316.281 177.366 317.014 178.945 318.211 179.776L341.579 193.303C341.931 193.515 342.396 193.346 342.551 192.965L344.635 187.653C346.551 185.652 348.058 182.975 348.776 180.311L350.523 177.705L351.509 177.437L352.241 176.042C352.41 175.718 352.298 175.323 351.974 175.14L351.96 175.126Z" fill="#B0E2FA" />
        <path d="M351.945 178.649L350.607 177.874L350.481 177.902L348.734 180.509C348.016 183.172 346.508 185.835 344.593 187.85L342.508 193.162C342.353 193.543 341.903 193.712 341.536 193.501L318.169 179.973C317.281 179.353 316.647 178.325 316.394 176.916C316.309 177.507 316.253 178.085 316.253 178.649C316.253 180.861 316.985 182.439 318.183 183.271L341.55 196.798C341.903 197.009 342.367 196.84 342.522 196.46L344.607 191.147C346.523 189.146 348.03 186.469 348.748 183.806L350.495 181.199L351.481 180.931L352.213 179.536C352.382 179.212 352.269 178.818 351.945 178.635V178.649Z" fill="#00283B" />
        <path d="M349.368 177.099L329.042 165.375C328.747 165.135 328.408 164.938 328.042 164.811C326.76 164.332 325.127 164.558 323.352 165.586C320.676 167.136 318.351 170.138 317.042 173.35C317.295 174.788 317.929 175.859 318.83 176.493L342.198 190.02C342.55 190.231 343.015 190.062 343.17 189.682L345.255 184.37C347.156 182.383 348.635 179.748 349.368 177.113V177.099Z" fill="#F5FCFF" />
        <path d="M341.903 197.432C341.649 197.432 341.395 197.361 341.184 197.235L317.816 183.707C316.309 182.665 315.506 180.79 315.506 178.423C315.506 173.463 319 167.418 323.296 164.938C325.197 163.839 327.099 163.543 328.634 164.107C329.071 164.276 329.479 164.501 329.845 164.797L352.326 177.747C353.002 178.127 353.241 178.973 352.889 179.663L352.002 181.354L350.959 181.636L349.452 183.905C348.706 186.596 347.185 189.287 345.269 191.345L343.241 196.516C343.086 196.91 342.776 197.206 342.381 197.347C342.226 197.404 342.072 197.432 341.903 197.432ZM326.986 165.347C326.099 165.347 325.099 165.657 324.056 166.249C320.183 168.489 317.028 173.942 317.028 178.423C317.028 180.283 317.591 181.706 318.619 182.425L341.931 195.924L344.058 190.415C345.889 188.498 347.325 185.948 348.016 183.397L348.114 183.172L350.016 180.325L350.959 180.072L351.495 179.043L328.972 166.051C328.634 165.784 328.38 165.643 328.085 165.53C327.747 165.403 327.366 165.333 326.958 165.333L326.986 165.347Z" fill="#00283B" />
        <path d="M354.608 183.482C354.608 188.202 351.298 193.951 347.199 196.319C343.114 198.686 339.79 196.769 339.79 192.035C339.79 187.3 343.1 181.565 347.199 179.198C351.284 176.831 354.608 178.747 354.608 183.482Z" fill="#F5FCFF" />
        <path d="M343.889 198.094C343.058 198.094 342.269 197.897 341.579 197.502C339.931 196.558 339.029 194.614 339.029 192.049C339.029 187.089 342.522 181.044 346.819 178.564C349.044 177.282 351.171 177.099 352.819 178.043C354.467 178.987 355.368 180.931 355.368 183.496C355.368 188.456 351.875 194.501 347.579 196.981C346.297 197.728 345.044 198.094 343.889 198.094ZM350.509 178.959C349.636 178.959 348.636 179.269 347.579 179.875C343.706 182.115 340.551 187.568 340.551 192.049C340.551 194.05 341.184 195.516 342.339 196.192C343.494 196.854 345.086 196.671 346.819 195.671C350.692 193.43 353.847 187.977 353.847 183.496C353.847 181.495 353.213 180.03 352.058 179.353C351.608 179.086 351.086 178.959 350.509 178.959Z" fill="#00283B" />
        <path d="M365.172 177.704L357.791 173.477C357.298 173.069 356.734 172.759 356.143 172.533C354.016 171.744 351.297 172.096 348.339 173.815C341.55 177.747 336.029 187.286 336.029 195.135C336.029 198.813 337.24 201.448 339.226 202.814L347.903 207.873C348.494 208.225 349.269 207.958 349.523 207.309L352.988 198.489C356.171 195.149 358.664 190.71 359.875 186.3L362.763 181.96L364.411 181.523L365.622 179.198C365.904 178.663 365.707 178 365.186 177.704H365.172Z" fill="#B0E2FA" />
        <path d="M365.087 177.62L363.228 176.563L362.791 177.394L361.143 177.831L358.255 182.171C357.058 186.596 354.551 191.034 351.368 194.36L347.903 203.181C347.649 203.829 346.888 204.083 346.283 203.744L337.606 198.686C337.029 198.277 336.522 197.756 336.071 197.136C336.451 199.728 337.536 201.645 339.113 202.744L347.79 207.803C348.381 208.155 349.156 207.887 349.41 207.239L352.875 198.418C356.058 195.078 358.551 190.64 359.763 186.229L362.65 181.889L364.298 181.453L365.509 179.128C365.791 178.592 365.594 177.93 365.073 177.634L365.087 177.62Z" fill="#00283B" />
        <path d="M364.678 180.382L358.368 176.775C357.875 176.366 357.312 176.056 356.72 175.83C354.593 175.041 351.875 175.394 348.917 177.113C345.311 179.198 342.071 182.862 339.832 187.019C340.071 187.258 340.325 187.47 340.606 187.667L349.283 192.725C349.874 193.078 350.649 192.81 350.903 192.162L359.439 187.582C359.579 187.16 359.72 186.737 359.833 186.314L362.72 181.974L364.368 181.537L364.833 180.664C364.791 180.579 364.763 180.48 364.721 180.396L364.678 180.382Z" fill="#F5FCFF" />
        <path d="M348.452 208.789C348.128 208.789 347.804 208.704 347.508 208.535L338.832 203.477C336.508 201.87 335.254 198.911 335.254 195.135C335.254 187.061 340.944 177.197 347.931 173.153C350.973 171.392 353.973 170.913 356.382 171.814C357.058 172.068 357.692 172.434 358.255 172.885L365.538 177.042C366.425 177.549 366.749 178.648 366.27 179.55L364.904 182.171L363.214 182.622L360.551 186.624C359.326 191.077 356.805 195.543 353.621 198.911L350.213 207.591C350.015 208.098 349.593 208.507 349.072 208.676C348.875 208.747 348.663 208.775 348.452 208.775V208.789ZM353.819 172.885C352.255 172.885 350.494 173.421 348.691 174.463C342.114 178.268 336.761 187.526 336.761 195.121C336.761 198.39 337.775 200.898 339.634 202.18L348.255 207.211C348.382 207.281 348.494 207.253 348.551 207.239C348.607 207.225 348.72 207.168 348.776 207.028L352.396 197.967C355.481 194.726 357.931 190.4 359.101 186.103L359.199 185.877L362.256 181.298L363.861 180.861L364.918 178.832C365.016 178.663 364.946 178.437 364.777 178.338L357.396 174.111C356.861 173.688 356.368 173.407 355.847 173.223C355.213 172.984 354.537 172.871 353.804 172.871L353.819 172.885Z" fill="#00283B" />
        <path d="M365.943 198.251C370.746 189.928 370.747 180.931 365.943 178.157C361.14 175.382 353.352 179.881 348.548 188.204C343.744 196.527 343.744 205.524 348.548 208.298C353.351 211.073 361.139 206.574 365.943 198.251Z" fill="#00283B" />
        <path d="M351.762 209.888C350.466 209.888 349.255 209.578 348.184 208.958C345.621 207.479 344.198 204.407 344.198 200.335C344.198 192.26 349.889 182.397 356.875 178.353C360.411 176.31 363.763 176.014 366.341 177.493C368.904 178.973 370.327 182.045 370.327 186.117C370.327 194.191 364.636 204.055 357.636 208.099C355.579 209.282 353.579 209.888 351.762 209.888ZM362.749 178.085C361.199 178.085 359.453 178.62 357.636 179.663C351.058 183.468 345.705 192.725 345.705 200.32C345.705 203.829 346.846 206.436 348.931 207.634C351.002 208.831 353.833 208.521 356.875 206.774C363.453 202.97 368.806 193.712 368.806 186.117C368.806 182.608 367.665 180.001 365.58 178.804C364.749 178.325 363.791 178.085 362.749 178.085Z" fill="#00283B" />
        <path d="M362.608 191.795C362.608 194.487 360.721 197.742 358.397 199.095C356.072 200.433 354.185 199.348 354.185 196.657C354.185 193.965 356.072 190.71 358.397 189.358C360.721 188.019 362.608 189.104 362.608 191.795Z" fill="#F5FCFF" />
        <path d="M353.593 204.449C352.678 204.449 351.833 204.238 351.072 203.801C349.269 202.758 348.283 200.644 348.283 197.812C348.283 192.331 352.143 185.652 356.875 182.918C359.312 181.509 361.65 181.298 363.453 182.34C365.256 183.383 366.242 185.497 366.242 188.315C366.242 193.796 362.383 200.475 357.65 203.209C356.241 204.026 354.861 204.435 353.593 204.435V204.449ZM360.932 183.214C359.946 183.214 358.819 183.552 357.65 184.243C353.326 186.737 349.819 192.838 349.819 197.826C349.819 200.081 350.537 201.729 351.847 202.49C353.157 203.251 354.946 203.04 356.903 201.913C361.228 199.419 364.749 193.317 364.749 188.329C364.749 186.074 364.031 184.426 362.721 183.665C362.2 183.369 361.608 183.214 360.96 183.214H360.932Z" fill="#B0E2FA" />
        <path d="M353.072 130.035L325.183 113.929C323.732 113.083 322.436 113.013 321.493 113.52C321.45 113.548 321.394 113.577 321.352 113.605L306.266 122.567C306.041 122.707 305.984 123.017 306.153 123.229L324.69 146L324.803 146.62C325.042 148.029 325.591 149.508 326.38 150.889L330.676 158.442C330.719 158.527 330.747 158.611 330.733 158.71L328.408 178.775C328.366 179.142 328.761 179.395 329.071 179.198L343.424 170.743L351.917 165.628C352.706 165.149 353.255 164.304 353.495 163.177L358.298 140.504C359.016 137.08 356.495 131.993 353.1 130.035H353.072Z" fill="#B0E2FA" />
        <path d="M329.353 179.438L343.706 170.983L352.199 165.868C352.988 165.389 353.537 164.543 353.777 163.416L358.242 142.336C357.425 139.715 355.594 137.094 353.382 135.812L325.493 119.706C324.042 118.861 322.746 118.79 321.803 119.298C321.76 119.326 321.704 119.354 321.662 119.382L309.182 126.794L324.986 146.225L325.099 146.845C325.338 148.254 325.887 149.734 326.676 151.115L330.972 158.668C331.015 158.752 331.043 158.837 331.029 158.935L330.508 163.402L330.972 164.219C331.015 164.304 331.043 164.388 331.029 164.487L329.296 179.452C329.296 179.452 329.338 179.452 329.367 179.438H329.353Z" fill="#F5FCFF" />
        <path d="M328.831 180.015C328.606 180.015 328.38 179.945 328.183 179.818C327.803 179.564 327.592 179.128 327.648 178.677L329.958 158.724L325.704 151.256C324.873 149.79 324.296 148.24 324.042 146.733L323.972 146.31L305.562 123.694C305.337 123.426 305.252 123.074 305.308 122.722C305.365 122.383 305.576 122.073 305.886 121.904L321.126 112.858C322.366 112.182 323.943 112.337 325.577 113.267L353.467 129.373C357.185 131.514 359.833 136.897 359.03 140.659L354.227 163.332C353.946 164.67 353.283 165.699 352.298 166.277L343.804 171.392L329.451 179.846C329.254 179.959 329.057 180.015 328.845 180.015H328.831ZM306.971 123.018L325.394 145.662L325.535 146.479C325.76 147.803 326.268 149.198 327.014 150.495L331.31 158.048C331.437 158.259 331.493 158.527 331.465 158.78L329.226 178.198L343.015 170.081L351.509 164.966C352.114 164.6 352.537 163.923 352.734 163.008L357.537 140.335C358.185 137.249 355.791 132.473 352.692 130.683L324.803 114.577C323.662 113.915 322.605 113.774 321.845 114.183L306.971 123.018Z" fill="#00283B" />
        <path d="M304.872 127.273L309.675 155.497C309.915 156.906 310.464 158.386 311.253 159.767L319.746 174.689C322.098 178.804 325.944 181.03 328.296 179.621L336.79 174.506C337.578 174.027 338.128 173.181 338.367 172.054L343.17 149.382C343.889 145.958 341.367 140.871 337.973 138.912L310.084 122.806C306.689 120.848 304.154 123.004 304.886 127.259L304.872 127.273Z" fill="#B0E2FA" />
        <path d="M326.69 180.819C326.282 180.819 325.845 180.762 325.408 180.65C323.126 180.058 320.704 177.916 319.084 175.084L310.59 160.161C309.759 158.696 309.182 157.132 308.928 155.638L304.125 127.414C303.703 124.934 304.28 122.933 305.703 121.947C306.956 121.073 308.703 121.158 310.464 122.172L338.353 138.278C342.071 140.42 344.719 145.803 343.917 149.565L339.113 172.237C338.832 173.59 338.156 174.605 337.184 175.182L328.69 180.297C328.099 180.65 327.423 180.833 326.704 180.833L326.69 180.819ZM305.618 127.146L310.421 155.37C310.647 156.695 311.154 158.076 311.9 159.386L320.394 174.309C321.816 176.803 323.873 178.663 325.789 179.156C326.62 179.367 327.324 179.311 327.887 178.959L336.381 173.844C336.987 173.477 337.409 172.801 337.606 171.885L342.409 149.213C343.057 146.127 340.663 141.35 337.564 139.56L309.675 123.455C308.421 122.736 307.308 122.623 306.548 123.159C305.632 123.793 305.28 125.272 305.604 127.132L305.618 127.146Z" fill="#00283B" />
        <path d="M342.156 143.379L357.058 134.164L358.397 139.321L354.946 156.202L353.058 163.825L351.903 165.629L337.691 174.083L343.17 149.396L342.536 144.563L342.156 143.379Z" fill="#00283B" />
        <path d="M301.576 188.456L276.729 174.097V145.394L301.576 159.739V188.456Z" fill="#B0E2FA" />
        <path d="M326.437 145.394L301.576 159.738L276.729 145.394L301.576 131.035L326.437 145.394Z" fill="#B0E2FA" />
        <path d="M326.437 153.722L304.083 166.474L279.236 152.116L301.576 139.377L326.437 153.722Z" fill="#F5FCFF" />
        <path d="M301.576 130.19L276.729 144.549L275.997 144.999V174.52L300.843 188.879L301.576 189.301L302.308 188.879L327.155 174.52V144.971L301.576 130.204V130.19ZM301.576 131.881L324.972 145.394L313.224 152.172L301.576 158.907L297.914 156.794L278.194 145.394L301.576 131.881ZM277.461 173.674V146.648L300.857 160.161V187.174L277.461 173.66V173.674Z" fill="#00283B" />
        <path d="M295.589 179.86L291.8 177.676C291.195 177.324 290.702 176.38 290.702 175.577V171.589C290.702 170.786 291.195 170.405 291.8 170.758L295.589 172.942C296.195 173.294 296.688 174.238 296.688 175.041V179.029C296.688 179.832 296.195 180.213 295.589 179.86Z" fill="#00283B" />
        <path d="M351.17 110.688H314.717L324.281 124.737C324.619 125.667 325.408 126.568 326.676 127.287C330.141 129.288 335.747 129.288 339.212 127.287C340.466 126.554 341.254 125.667 341.606 124.737L351.17 110.688Z" fill="#00283B" />
        <path d="M332.944 129.556C330.549 129.556 328.155 129.02 326.295 127.935C324.985 127.174 324.056 126.202 323.619 125.103L314.21 111.28C313.816 110.702 314.224 109.913 314.928 109.913H350.283C351.269 109.913 351.846 111.012 351.297 111.829L342.268 125.103C341.832 126.202 340.902 127.174 339.592 127.935C337.733 129.006 335.338 129.556 332.944 129.556ZM324.69 123.962H324.816L324.999 124.455C325.295 125.258 326.014 126.005 327.056 126.611C330.31 128.485 335.592 128.485 338.831 126.611C339.888 126.005 340.592 125.258 340.888 124.455L341.071 123.962H341.212L349.734 111.435H316.154L324.675 123.962H324.69Z" fill="#00283B" />
        <path d="M352.34 106.884C352.34 106.884 352.34 106.686 352.297 106.348C352.283 106.123 352.269 105.911 352.227 105.686C351.664 101.219 348.593 85.6344 332.944 85.8881C317.365 86.1417 314.253 101.205 313.675 105.658C313.619 105.925 313.605 106.193 313.576 106.447C313.548 106.714 313.534 106.884 313.534 106.884C313.534 109.758 315.422 112.619 319.211 114.803C326.789 119.185 339.071 119.185 346.649 114.803C350.438 112.619 352.326 109.744 352.326 106.884H352.34Z" fill="#B0E2FA" />
        <path d="M332.944 118.833C327.831 118.833 322.732 117.705 318.844 115.465C314.929 113.21 312.788 110.153 312.788 106.884L312.83 106.376C312.844 106.109 312.872 105.813 312.928 105.517C313.534 100.839 316.816 85.3949 332.944 85.1272C349.199 84.8877 352.382 100.726 352.987 105.587C353.03 105.77 353.044 106.01 353.058 106.235C353.1 106.658 353.114 106.869 353.114 106.869C353.114 110.139 350.959 113.196 347.058 115.451C343.17 117.691 338.071 118.819 332.958 118.819L332.944 118.833ZM333.31 86.649C333.197 86.649 333.071 86.649 332.958 86.649C318.056 86.8886 314.999 101.332 314.421 105.756C314.365 106.038 314.351 106.264 314.337 106.503L314.295 106.94C314.295 109.603 316.182 112.182 319.605 114.154C326.958 118.396 338.93 118.396 346.283 114.154C349.692 112.182 351.579 109.617 351.579 106.912C351.579 106.855 351.565 106.686 351.537 106.419C351.523 106.193 351.509 106.01 351.466 105.827C350.889 101.275 347.917 86.649 333.296 86.649H333.31Z" fill="#00283B" />
        <path d="M338.381 89.2135C341.381 90.9466 341.381 93.7507 338.381 95.498C335.381 97.2312 330.507 97.2312 327.507 95.498C324.507 93.7648 324.507 90.9607 327.507 89.2135C330.507 87.4803 335.381 87.4803 338.381 89.2135Z" fill="#F5FCFF" />
        <path d="M332.944 92.9194C331.831 92.9194 330.718 92.6657 329.845 92.1726C328.873 91.6089 328.338 90.8198 328.338 89.9462C328.338 89.0726 328.873 88.2835 329.845 87.7198C331.578 86.7194 334.296 86.7194 336.029 87.7198C337 88.2835 337.536 89.0726 337.536 89.9462C337.536 90.8198 337 91.6089 336.029 92.1726C335.155 92.6798 334.042 92.9194 332.93 92.9194H332.944ZM332.944 88.4807C332.085 88.4807 331.239 88.6639 330.606 89.0303C330.127 89.298 329.859 89.6362 329.859 89.9462C329.859 90.2562 330.127 90.5803 330.606 90.8621C331.873 91.5948 334.014 91.5948 335.282 90.8621C335.761 90.5944 336.029 90.2562 336.029 89.9462C336.029 89.6362 335.761 89.3121 335.282 89.0303C334.648 88.6639 333.803 88.4807 332.944 88.4807Z" fill="#00283B" />
        <path d="M336.451 66.2595C338.395 67.3868 338.395 69.1904 336.451 70.3177C334.507 71.445 331.366 71.445 329.422 70.3177C327.478 69.1904 327.478 67.3868 329.422 66.2595C331.366 65.1322 334.507 65.1322 336.451 66.2595Z" fill="#F5FCFF" />
        <path d="M332.944 69.1058C331.535 69.1058 330.141 68.7958 329.056 68.1617C327.873 67.4712 327.225 66.5271 327.225 65.4703C327.225 64.4135 327.873 63.4694 329.056 62.7789C331.239 61.5249 334.662 61.5249 336.845 62.7789C338.029 63.4553 338.677 64.4135 338.677 65.4703C338.677 66.5271 338.029 67.4712 336.845 68.1617C335.761 68.7958 334.352 69.1058 332.958 69.1058H332.944ZM332.944 63.3708C331.803 63.3708 330.662 63.6103 329.817 64.1035C329.127 64.498 328.746 64.9912 328.746 65.4703C328.746 65.9494 329.141 66.4426 329.817 66.8371C331.521 67.8235 334.381 67.8235 336.085 66.8371C336.775 66.4426 337.155 65.9494 337.155 65.4703C337.155 64.9912 336.761 64.498 336.085 64.1035C335.24 63.6103 334.099 63.3708 332.958 63.3708H332.944Z" fill="#00283B" />
        <path d="M334.69 60.0172C335.662 60.5808 335.662 61.4827 334.69 62.0322C333.718 62.5958 332.155 62.5958 331.197 62.0322C330.225 61.4686 330.225 60.5668 331.197 60.0172C332.169 59.4536 333.733 59.4536 334.69 60.0172Z" fill="#F5FCFF" />
        <path d="M332.944 60.3976C332.183 60.3976 331.409 60.2285 330.817 59.8763C330.113 59.4676 329.718 58.8617 329.718 58.2135C329.718 57.5653 330.127 56.9453 330.817 56.5508C332.014 55.8603 333.888 55.8603 335.071 56.5508C335.775 56.9594 336.169 57.5653 336.169 58.2135C336.169 58.8617 335.761 59.4817 335.071 59.8763C334.479 60.2144 333.704 60.3976 332.944 60.3976ZM332.944 57.5372C332.437 57.5372 331.944 57.6358 331.578 57.8472C331.324 57.9881 331.239 58.1431 331.239 58.1994C331.239 58.2558 331.338 58.4108 331.578 58.5517C332.31 58.9744 333.592 58.9744 334.31 58.5517C334.564 58.4108 334.648 58.2558 334.648 58.1994C334.648 58.1431 334.55 57.9881 334.31 57.8472C333.944 57.6358 333.437 57.5372 332.944 57.5372Z" fill="#00283B" />
        <path d="M333.704 59.6367H332.183V65.4704H333.704V59.6367Z" fill="#00283B" />
        <path d="M333.704 68.3308H332.183V89.9322H333.704V68.3308Z" fill="#00283B" />
        <path d="M339.001 107.94C338.55 111.449 336.155 114.028 333.648 113.703C331.141 113.379 329.479 110.279 329.93 106.785C330.38 103.276 332.775 100.698 335.282 101.022C337.789 101.346 339.451 104.446 339.001 107.94Z" fill="#F5FCFF" />
        <path d="M331.634 114.493C331.465 114.493 331.296 114.493 331.127 114.465C328.211 114.084 326.253 110.604 326.746 106.686C327.253 102.769 330.028 99.8946 332.944 100.261C335.86 100.641 337.818 104.122 337.325 108.039C336.846 111.717 334.353 114.493 331.634 114.493ZM332.423 101.755C330.465 101.755 328.634 103.967 328.254 106.884C327.859 109.97 329.24 112.689 331.31 112.957C333.409 113.239 335.409 110.928 335.803 107.856C336.198 104.77 334.817 102.051 332.747 101.783C332.634 101.769 332.521 101.755 332.409 101.755H332.423Z" fill="#00283B" />
        <path d="M322.591 104.051C321.873 107.504 319.887 110.026 318.168 109.659C316.45 109.307 315.647 106.207 316.366 102.755C317.084 99.3026 319.07 96.7803 320.788 97.1467C322.507 97.499 323.31 100.599 322.591 104.051Z" fill="#F5FCFF" />
        <path d="M316.422 110.448C316.253 110.448 316.098 110.434 315.929 110.392C314.802 110.152 313.943 109.152 313.534 107.546C313.168 106.122 313.168 104.361 313.534 102.586C314.365 98.5979 316.647 95.9347 318.859 96.3856C321.056 96.8506 322.084 100.204 321.253 104.192C320.887 105.982 320.183 107.588 319.267 108.743C318.394 109.857 317.394 110.434 316.422 110.434V110.448ZM318.366 97.8651C317.253 97.8651 315.647 99.9083 315.027 102.91C314.704 104.474 314.689 105.982 314.999 107.179C315.253 108.18 315.704 108.814 316.239 108.927C316.76 109.039 317.436 108.631 318.07 107.827C318.831 106.855 319.436 105.46 319.76 103.91C320.422 100.754 319.662 98.1329 318.549 97.8933C318.493 97.8933 318.436 97.8792 318.38 97.8792L318.366 97.8651Z" fill="#00283B" />
        <path d="M348.649 154.736C348.649 154.736 348.72 154.764 348.846 154.807C348.931 154.835 349.001 154.863 349.086 154.891C350.748 155.455 356.692 157.033 359.27 151.524C361.833 146.042 357.101 142.393 355.65 141.434C355.565 141.378 355.481 141.322 355.382 141.265C355.298 141.209 355.241 141.181 355.241 141.181C354.241 140.688 352.917 140.871 351.509 141.815C348.692 143.717 346.607 148.001 346.846 151.383C346.959 153.074 347.649 154.229 348.649 154.708V154.736Z" fill="#B0E2FA" />
        <path d="M355.678 141.42C355.593 141.364 355.509 141.307 355.41 141.251C355.326 141.195 355.269 141.166 355.269 141.166C354.903 140.983 354.495 140.927 354.058 140.941C351.945 143.111 350.523 146.549 350.72 149.367C350.833 151.058 351.523 152.214 352.523 152.693C352.523 152.693 352.593 152.721 352.72 152.763C352.805 152.791 352.875 152.82 352.959 152.848C353.833 153.144 355.917 153.721 358.058 153.383C358.523 152.876 358.946 152.256 359.312 151.495C361.876 146.014 357.143 142.364 355.692 141.406L355.678 141.42Z" fill="#F5FCFF" />
        <path d="M352.777 156.3C351.438 156.3 350.086 156.047 348.833 155.624C348.776 155.61 348.678 155.567 348.579 155.525C348.466 155.483 348.382 155.455 348.311 155.412C347.016 154.778 346.227 153.369 346.086 151.453C345.832 147.775 348.016 143.266 351.086 141.195C352.678 140.11 354.27 139.87 355.565 140.504L355.777 140.631C355.861 140.673 355.988 140.758 356.087 140.828C357.678 141.871 362.749 145.831 359.932 151.847C358.411 155.117 355.622 156.3 352.762 156.3H352.777ZM348.959 154.046L349.1 154.102C349.171 154.13 349.227 154.144 349.269 154.158C350.903 154.722 356.27 156.103 358.566 151.213C360.862 146.31 356.58 142.984 355.213 142.082C355.115 142.012 355.058 141.984 355.002 141.942C354.101 141.491 353.044 141.702 351.917 142.463C349.311 144.224 347.368 148.212 347.593 151.354C347.692 152.707 348.171 153.651 348.945 154.046H348.959Z" fill="#00283B" />
        <path d="M311.647 170.575C311.647 170.575 311.718 170.603 311.845 170.645C311.929 170.673 312 170.701 312.084 170.73C313.746 171.293 319.69 172.871 322.268 167.362C324.831 161.881 320.099 158.231 318.648 157.273C318.563 157.216 318.479 157.16 318.38 157.104C318.296 157.047 318.239 157.019 318.239 157.019C317.239 156.526 315.915 156.709 314.507 157.653C311.69 159.556 309.605 163.839 309.844 167.221C309.957 168.912 310.647 170.067 311.647 170.546V170.575Z" fill="#B0E2FA" />
        <path d="M314.366 158.47C314.281 158.414 314.197 158.358 314.098 158.301C314.013 158.245 313.957 158.217 313.957 158.217C313.844 158.16 313.718 158.146 313.605 158.118C311.295 160.246 309.689 163.938 309.9 166.925C310.013 168.616 310.703 169.771 311.703 170.25C311.703 170.25 311.774 170.279 311.901 170.321C311.985 170.349 312.056 170.377 312.14 170.405C312.858 170.645 314.394 171.082 316.112 171.054C316.831 170.462 317.478 169.645 317.986 168.56C320.549 163.078 315.816 159.429 314.366 158.47Z" fill="#F5FCFF" />
        <path d="M315.788 172.138C314.45 172.138 313.098 171.885 311.844 171.448C311.774 171.434 311.689 171.392 311.591 171.349L311.323 171.237C310.027 170.603 309.239 169.193 309.098 167.277C308.844 163.585 311.027 159.09 314.084 157.019C315.676 155.934 317.281 155.694 318.577 156.328L318.788 156.441C318.887 156.498 319.014 156.568 319.112 156.653C320.69 157.695 325.761 161.655 322.958 167.658C321.437 170.927 318.648 172.11 315.788 172.11V172.138ZM311.971 169.87L312.112 169.926C312.196 169.954 312.253 169.983 312.309 169.997C313.929 170.546 319.296 171.941 321.591 167.038C323.887 162.134 319.605 158.808 318.239 157.907C318.141 157.836 318.07 157.794 318.014 157.766C317.126 157.329 316.07 157.526 314.943 158.287C312.337 160.048 310.394 164.036 310.619 167.178C310.717 168.517 311.196 169.475 311.971 169.87Z" fill="#00283B" />
        <path d="M339.213 178.395C331.268 178.395 324 174.421 319.93 171.674C318.042 170.405 317.718 167.77 319.197 166.051C320.451 164.6 322.578 164.29 324.169 165.361C328.789 168.461 338.537 173.632 346.622 168.757C353.89 164.36 353.932 157.949 353.171 154.215C352.819 152.454 353.692 150.678 355.34 149.945C357.538 148.959 360.13 150.241 360.608 152.609C361.961 159.288 360.89 169.01 350.551 175.267C346.791 177.535 342.917 178.409 339.199 178.409L339.213 178.395Z" fill="#B0E2FA" />
        <path d="M350.608 175.647C359.904 170.025 361.693 161.612 360.974 155.102C359.904 154.172 358.326 153.848 356.904 154.496C355.256 155.229 354.382 157.005 354.735 158.766C355.495 162.5 355.453 168.911 348.185 173.308C340.114 178.197 330.353 173.012 325.733 169.912C324.141 168.841 322.014 169.151 320.76 170.602C320.38 171.053 320.127 171.546 319.972 172.082C324.042 174.83 331.31 178.789 339.241 178.789C342.959 178.789 346.833 177.915 350.593 175.647H350.608Z" fill="#F5FCFF" />
        <path d="M339.212 179.156C331.325 179.156 324.141 175.436 319.507 172.307C318.436 171.589 317.732 170.433 317.549 169.151C317.38 167.855 317.76 166.544 318.62 165.544C320.127 163.796 322.69 163.43 324.592 164.712C329.409 167.939 338.621 172.674 346.227 168.08C352.847 164.078 353.256 158.4 352.425 154.341C351.988 152.214 353.058 150.1 355.016 149.226C356.298 148.649 357.749 148.691 359.003 149.339C360.228 149.973 361.073 151.101 361.341 152.425C362.27 157.019 363.115 168.517 350.932 175.886C347.368 178.042 343.41 179.141 339.184 179.141L339.212 179.156ZM322.085 165.473C321.225 165.473 320.38 165.84 319.775 166.53C319.197 167.206 318.929 168.08 319.056 168.94C319.169 169.799 319.634 170.546 320.352 171.039C324 173.505 331.311 177.634 339.212 177.634C343.156 177.634 346.833 176.605 350.171 174.604C361.524 167.742 360.735 157.033 359.876 152.749C359.693 151.876 359.129 151.129 358.326 150.706C357.495 150.269 356.523 150.255 355.664 150.636C354.354 151.227 353.65 152.622 353.932 154.06C354.692 157.78 354.791 164.712 347.03 169.405C338.705 174.435 328.874 169.433 323.761 165.995C323.254 165.656 322.676 165.487 322.099 165.487L322.085 165.473Z" fill="#00283B" />
        <path d="M415.292 102.703L367.189 130.756L367.898 131.973L416.001 103.921L415.292 102.703Z" fill="#00283B" />
        <path d="M410.706 122.792L400.181 128.932L400.891 130.149L411.416 124.009L410.706 122.792Z" fill="#00283B" />
        <path d="M395.606 131.66L368.977 147.208L369.687 148.425L396.316 132.877L395.606 131.66Z" fill="#00283B" />
        <path d="M142.635 125.23L122.62 113.675V98.3159L142.635 109.87V125.23Z" fill="#B0E2FA" />
        <path d="M143.635 126.977L121.605 114.253V96.5688L143.635 109.293V126.977ZM123.634 113.097L141.621 123.482V110.462L123.634 100.077V113.097Z" fill="#00283B" />
        <path d="M162.637 113.675L142.635 125.23V109.87L162.637 98.3159V113.675Z" fill="#00283B" />
        <path d="M141.621 126.977V109.293L163.651 96.5688V114.253L141.621 126.977ZM143.635 110.448V123.468L161.622 113.083V100.063L143.635 110.448Z" fill="#00283B" />
        <path d="M145.762 23.0708C145.762 23.0708 145.762 23.0144 145.762 22.9863V20.9994H143.001C142.424 20.9149 141.804 20.9149 141.227 20.9994H138.466V22.9863C138.466 22.9863 138.466 23.0144 138.466 23.0426V23.099V23.1554C138.494 23.6767 138.846 24.1276 139.536 24.5222C140.959 25.3394 143.269 25.3394 144.692 24.5222C145.368 24.1276 145.72 23.6204 145.748 23.099V23.0708H145.762Z" fill="#B0E2FA" />
        <path d="M142.128 26.1284C141.015 26.1284 139.903 25.8748 139.043 25.3816C138.043 24.8039 137.522 24.0712 137.48 23.2116V23.1553V22.9157V19.9848H141.241C141.776 19.9003 142.48 19.9003 143.156 19.9848H146.776V23.1693C146.734 24.0289 146.171 24.818 145.213 25.3816C144.354 25.8748 143.241 26.1284 142.128 26.1284ZM139.494 21.9857V23.2116C139.494 23.2116 139.72 23.4371 140.058 23.6343C141.156 24.2684 143.1 24.2684 144.213 23.6343C144.607 23.4089 144.762 23.1693 144.776 23.0284V21.9857H143.03C142.396 21.9012 141.889 21.9012 141.396 21.9857H139.508H139.494Z" fill="#00283B" />
        <path d="M149.143 17.6599C149.143 17.6599 149.143 17.5472 149.143 17.4908V13.6581H143.847C142.72 13.489 141.551 13.489 140.438 13.6581H135.142V17.4908C135.142 17.4908 135.142 17.5472 135.142 17.6035V17.7022V17.8149C135.198 18.8013 135.889 19.6749 137.199 20.4358C139.945 22.014 144.382 22.014 147.115 20.4358C148.425 19.6749 149.101 18.6885 149.157 17.7022V17.6599H149.143Z" fill="#00283B" />
        <path d="M142.128 22.6339C140.156 22.6339 138.198 22.1971 136.663 21.3093C135.043 20.3652 134.184 19.2098 134.1 17.8711V17.8148V17.6316V12.6434H140.41C141.466 12.4743 142.762 12.4743 143.974 12.6434H150.143V17.7302C150.058 19.1252 149.157 20.3934 147.593 21.2952C146.058 22.183 144.1 22.6198 142.128 22.6198V22.6339ZM136.128 14.6584V17.7725C136.156 18.3784 136.677 18.9843 137.677 19.5621C140.1 20.9571 144.171 20.9571 146.593 19.5621C147.551 19.0125 148.101 18.3221 148.143 17.6457H149.157L148.143 17.5189V14.6725H143.847C142.678 14.5175 141.593 14.5175 140.579 14.6725H136.142L136.128 14.6584Z" fill="#00283B" />
        <path d="M149.016 12.7279C148.65 11.0793 147.241 6.49976 142.128 6.49976C137.015 6.49976 135.621 11.0793 135.241 12.7279C135.156 12.9816 135.114 13.2493 135.114 13.517C135.114 14.5598 135.804 15.5884 137.17 16.3916C139.917 17.9698 144.354 17.9698 147.086 16.3916C148.805 15.4052 149.439 14.0243 149.002 12.742L149.016 12.7279Z" fill="#B0E2FA" />
        <path d="M149.002 15.236C148.622 13.5874 147.227 9.00781 142.114 9.00781C137.001 9.00781 135.607 13.5874 135.226 15.236C135.142 15.4896 135.1 15.7574 135.1 16.0251C135.1 17.0678 135.79 18.0964 137.156 18.8996C139.903 20.4778 144.34 20.4778 147.072 18.8996C148.791 17.9133 149.425 16.5324 148.988 15.2501L149.002 15.236Z" fill="#F5FCFF" />
        <path d="M142.128 18.5897C140.156 18.5897 138.198 18.1529 136.663 17.2652C135.015 16.307 134.1 14.9825 134.1 13.5311C134.1 13.1506 134.156 12.7843 134.283 12.4179C134.733 10.417 136.452 5.49927 142.114 5.49927C147.748 5.49927 149.481 10.3324 149.974 12.4743C150.551 14.2497 149.636 16.0815 147.579 17.2793C146.044 18.167 144.086 18.6038 142.114 18.6038L142.128 18.5897ZM142.128 7.51427C138.072 7.51427 136.691 10.9243 136.227 12.9534C136.142 13.207 136.114 13.362 136.114 13.517C136.114 14.2215 136.677 14.9402 137.663 15.5179C140.086 16.9129 144.157 16.9129 146.579 15.5179C147.157 15.1797 148.467 14.2779 148.058 13.0661L148.03 12.9674C147.565 10.9384 146.199 7.52836 142.128 7.52836V7.51427Z" fill="#00283B" />
        <path d="M85.561 81.7171H53.9251C52.7419 81.7171 51.77 80.7589 51.77 79.5612C51.77 78.3775 52.7278 77.4053 53.9251 77.4053H85.561C86.7442 77.4053 87.7161 78.3635 87.7161 79.5612C87.7161 80.7448 86.7583 81.7171 85.561 81.7171Z" fill="#B0E2FA" />
        <path d="M85.5611 82.7175H53.9252C52.1786 82.7175 50.77 81.2943 50.77 79.5611C50.77 77.828 52.1927 76.4048 53.9252 76.4048H85.5611C87.3077 76.4048 88.7163 77.828 88.7163 79.5611C88.7163 81.2943 87.2936 82.7175 85.5611 82.7175ZM53.9252 78.4198C53.2913 78.4198 52.7842 78.9271 52.7842 79.5611C52.7842 80.1952 53.2913 80.7025 53.9252 80.7025H85.5611C86.195 80.7025 86.702 80.1952 86.702 79.5611C86.702 78.9271 86.195 78.4198 85.5611 78.4198H53.9252Z" fill="#00283B" />
        <path d="M231.684 81.7171H200.048C198.864 81.7171 197.893 80.7589 197.893 79.5612C197.893 78.3775 198.85 77.4053 200.048 77.4053H231.684C232.867 77.4053 233.839 78.3635 233.839 79.5612C233.839 80.7448 232.881 81.7171 231.684 81.7171Z" fill="#B0E2FA" />
        <path d="M231.684 82.7175H200.048C198.301 82.7175 196.893 81.2943 196.893 79.5611C196.893 77.828 198.315 76.4048 200.048 76.4048H231.684C233.43 76.4048 234.839 77.828 234.839 79.5611C234.839 81.2943 233.416 82.7175 231.684 82.7175ZM200.048 78.4198C199.414 78.4198 198.907 78.9271 198.907 79.5611C198.907 80.1952 199.414 80.7025 200.048 80.7025H231.684C232.318 80.7025 232.825 80.1952 232.825 79.5611C232.825 78.9271 232.318 78.4198 231.684 78.4198H200.048Z" fill="#00283B" />
        <path d="M140.001 52.6757L139.945 21.0275C139.945 19.8439 140.903 18.8716 142.086 18.8716C143.269 18.8716 144.241 19.8298 144.241 21.0134L144.297 52.6616C144.297 53.8452 143.339 54.8175 142.156 54.8175C140.973 54.8175 140.001 53.8593 140.001 52.6757Z" fill="#B0E2FA" />
        <path d="M142.156 55.832C141.311 55.832 140.522 55.5079 139.931 54.9161C139.339 54.3243 139.001 53.5211 139.001 52.6897L138.945 21.0415C138.945 20.1961 139.269 19.407 139.86 18.8011C140.452 18.2093 141.255 17.8711 142.1 17.8711C142.945 17.8711 143.734 18.1952 144.325 18.787C144.917 19.3788 145.255 20.182 145.255 21.0134L145.311 52.6615C145.311 53.507 144.987 54.2961 144.396 54.902C143.804 55.4938 143.001 55.832 142.17 55.832H142.156ZM142.1 19.8861C141.79 19.8861 141.508 19.9988 141.297 20.2243C141.086 20.4356 140.959 20.7315 140.959 21.0275L141.015 52.6756C141.015 52.9856 141.142 53.2675 141.353 53.4788C141.565 53.6902 141.818 53.7606 142.156 53.817C142.466 53.817 142.748 53.7043 142.959 53.4788C143.17 53.2675 143.297 52.9715 143.297 52.6756L143.241 21.0275C143.241 20.3934 142.734 19.8861 142.1 19.8861Z" fill="#00283B" />
        <path d="M172.13 93.314V92.8349V92.8067V87.0576H170.398C168.975 84.7608 166.665 82.6049 163.482 80.759C151.946 74.094 133.254 74.094 121.718 80.759C118.535 82.6049 116.225 84.7608 114.802 87.0576H113.084V92.7926C113.084 92.7926 113.084 92.8208 113.084 92.849V93.3281H113.098C113.309 97.5272 116.183 101.684 121.732 104.897C133.268 111.562 151.96 111.562 163.496 104.897C169.046 101.698 171.919 97.5272 172.13 93.3281H172.144L172.13 93.314Z" fill="#B0E2FA" />
        <path d="M142.607 110.857C134.86 110.857 127.113 109.152 121.226 105.756C115.958 102.713 112.788 98.6825 112.183 94.3284H112.098V92.8489V86.0571H114.281C115.845 83.7321 118.183 81.6607 121.24 79.8994C133.043 73.0934 152.228 73.0934 164.017 79.8994C167.074 81.6607 169.412 83.7321 170.975 86.0571H173.159V94.3284H173.06C172.454 98.6825 169.285 102.713 164.017 105.756C158.115 109.166 150.382 110.857 142.635 110.857H142.607ZM114.084 93.0039V93.2716C114.31 97.2875 117.197 101.106 122.226 104.009C133.466 110.505 151.749 110.505 162.975 104.009C168.017 101.092 170.905 97.2875 171.102 93.2716V92.8348V88.0721H169.82L169.525 87.593C168.13 85.3525 165.933 83.3516 162.961 81.6325C151.735 75.1507 133.452 75.1366 122.212 81.6325C119.239 83.3516 117.042 85.3525 115.648 87.593L115.352 88.0721H114.084V93.0039Z" fill="#00283B" />
        <path d="M163.482 75.3481C175.018 82.0131 175.018 92.8067 163.482 99.4717C151.946 106.137 133.254 106.137 121.718 99.4717C110.182 92.8067 110.182 82.0131 121.718 75.3481C133.254 68.6831 151.946 68.6831 163.482 75.3481Z" fill="#B0E2FA" />
        <path d="M142.607 105.46C134.86 105.46 127.113 103.755 121.225 100.359C115.324 96.9494 112.07 92.3557 112.07 87.4239C112.07 82.4921 115.324 77.8985 121.225 74.4885C133.029 67.6826 152.213 67.6826 164.003 74.4885C169.905 77.8985 173.158 82.4921 173.158 87.4239C173.158 92.3557 169.905 96.9494 164.003 100.359C158.101 103.769 150.368 105.46 142.621 105.46H142.607ZM142.607 71.3603C135.226 71.3603 127.846 72.9807 122.225 76.2216C116.972 79.2512 114.084 83.2248 114.084 87.4098C114.084 91.5948 116.972 95.5685 122.225 98.598C133.466 105.094 151.749 105.08 162.975 98.598C168.229 95.5685 171.116 91.5948 171.116 87.4098C171.116 83.2248 168.229 79.2512 162.975 76.2216C157.355 72.9807 149.974 71.3603 142.593 71.3603H142.607Z" fill="#00283B" />
        <path d="M198.484 77.0671L184.821 77.1094C178.976 75.193 173.553 72.8258 168.75 70.0358C168.75 70.0358 167.369 69.2467 167.228 69.1621L167.186 69.134C156.636 63.0326 149.438 55.2685 146.523 46.814L145.565 44.0521C145.227 43.094 143.762 42.3894 142.058 42.3894C140.339 42.3894 138.86 43.094 138.55 44.0803L137.747 46.5744C134.959 55.2262 127.705 63.1876 116.929 69.4017C111.436 72.5721 105.154 75.2212 98.3364 77.2644L70.1514 77.053V82.013C70.1514 82.9994 71.3486 83.8449 73.0248 84.0421L77.8139 84.5917C92.4487 86.2685 105.9 90.4253 116.464 96.5267L118.028 97.4144C128.578 103.516 135.776 111.28 138.677 119.734L139.635 122.496C139.973 123.454 141.438 124.159 143.128 124.159C144.847 124.159 146.326 123.454 146.635 122.468L147.438 119.974C150.227 111.322 157.481 103.361 168.257 97.1467C179.032 90.9185 192.822 86.7335 207.794 85.1271L212.119 84.6621C213.809 84.479 215.034 83.6335 215.034 82.633V77.673L198.47 77.0671H198.484Z" fill="#00283B" />
        <path d="M143.128 125.174C140.945 125.174 139.156 124.229 138.677 122.834L137.719 120.073C134.874 111.815 127.888 104.291 117.521 98.3022L115.972 97.4144C105.492 91.3694 92.2657 87.2831 77.7154 85.6063L72.9263 85.0567C70.7149 84.8031 69.1655 83.5631 69.1655 82.0272V76.0385L98.2098 76.264C104.957 74.2349 111.084 71.6281 116.45 68.5422C127.043 62.4267 134.085 54.719 136.804 46.2785L137.607 43.7844C138.072 42.3613 139.86 41.4031 142.072 41.4031C144.283 41.4031 146.044 42.3472 146.523 43.7422L147.467 46.504C150.312 54.7613 157.298 62.2858 167.679 68.2744L169.243 69.1622C173.905 71.8535 179.201 74.1926 184.976 76.0949L198.498 76.0526H198.512L216.049 76.7008V82.6331C216.049 84.169 214.485 85.4231 212.232 85.6626L207.907 86.1276C193.005 87.734 179.469 91.8485 168.764 98.0203C158.172 104.136 151.129 111.844 148.396 120.284L147.593 122.778C147.129 124.201 145.34 125.159 143.128 125.159V125.174ZM71.1797 78.0817V82.0272C71.1797 82.309 71.8418 82.9008 73.1517 83.0417L77.9408 83.5913C92.7728 85.2963 106.267 89.4672 116.972 95.6531L118.521 96.5408C129.325 102.783 136.621 110.688 139.621 119.41L140.579 122.172C140.72 122.581 141.677 123.144 143.128 123.159C144.565 123.159 145.551 122.581 145.678 122.172L146.481 119.678C149.368 110.744 156.721 102.656 167.75 96.2872C178.694 89.9744 192.498 85.7613 207.682 84.1267L212.006 83.6617C213.33 83.5208 214.02 82.9149 214.02 82.6331V78.6454L198.442 78.0676L184.638 78.1099L184.483 78.0535C178.511 76.0949 173.046 73.6854 168.229 70.8954L166.721 70.0358C155.862 63.7654 148.565 55.8604 145.565 47.1381L144.621 44.3763C144.48 43.9676 143.509 43.404 142.072 43.404C140.635 43.404 139.649 43.9817 139.522 44.3904L138.72 46.8844C135.846 55.8181 128.493 63.9063 117.45 70.2753C111.915 73.474 105.591 76.1513 98.6323 78.2226L98.4915 78.2649L71.1797 78.0535V78.0817Z" fill="#00283B" />
        <path d="M207.386 75.0943C192.751 73.4175 179.3 69.2607 168.75 63.1593C168.75 63.1593 167.369 62.3702 167.228 62.2857L167.186 62.2575C156.636 56.1561 149.438 48.392 146.523 39.9375L145.565 37.1757C145.227 36.2175 143.762 35.5129 142.058 35.5129C140.339 35.5129 138.86 36.2175 138.55 37.2038L137.747 39.6979C134.959 48.3498 127.705 56.3111 116.929 62.5252C106.154 68.7534 92.3641 72.9384 77.3913 74.5588L73.0671 75.0239C71.3768 75.207 70.1514 76.0525 70.1514 77.0529C70.1514 78.0393 71.3486 78.8848 73.0248 79.082L77.8139 79.6316C92.4487 81.3084 105.9 85.4652 116.464 91.5666L118.028 92.4543C128.578 98.5557 135.776 106.32 138.677 114.774L139.635 117.536C139.973 118.494 141.438 119.199 143.128 119.199C144.847 119.199 146.326 118.494 146.635 117.508L147.438 115.014C150.227 106.362 157.481 98.4007 168.257 92.1866C179.032 85.9584 192.822 81.7734 207.794 80.167L212.119 79.702C213.809 79.5189 215.034 78.6734 215.034 77.6729C215.034 76.6866 213.837 75.8411 212.161 75.6439L207.372 75.0943H207.386Z" fill="#B0E2FA" />
        <path d="M146.649 117.508L147.452 115.014C150.241 106.362 157.495 98.4007 168.271 92.1866C178.229 86.4375 190.765 82.4498 204.414 80.6039C190.948 78.7157 178.595 74.7562 168.778 69.0775C168.778 69.0775 167.397 68.2884 167.256 68.2039L167.214 68.1757C156.664 62.0743 149.467 54.3102 146.551 45.8557L145.593 43.0939C145.255 42.1357 143.79 41.4312 142.086 41.4312C140.367 41.4312 138.888 42.1357 138.578 43.1221L137.776 45.6162C134.987 54.268 127.733 62.2293 116.957 68.4434C106.999 74.1925 94.4769 78.1802 80.814 80.0262C94.2797 81.9143 106.633 85.8739 116.45 91.5384L118.014 92.4261C128.564 98.5275 135.761 106.292 138.663 114.746L139.621 117.508C139.959 118.466 141.424 119.171 143.114 119.171C144.832 119.171 146.311 118.466 146.621 117.48L146.649 117.508Z" fill="#F5FCFF" />
        <path d="M143.128 120.199C140.945 120.199 139.156 119.255 138.677 117.86L137.719 115.098C134.874 106.841 127.888 99.3166 117.521 93.3279L115.972 92.4402C105.492 86.3952 92.2657 82.3088 77.7154 80.632L72.9263 80.0825C70.7149 79.8288 69.1655 78.5888 69.1655 77.0529C69.1655 75.517 70.729 74.2629 72.9827 74.0234L77.3069 73.5584C92.2093 71.952 105.745 67.8375 116.45 61.6515C127.043 55.5361 134.085 47.8284 136.804 39.3879L137.607 36.8938C138.072 35.4706 139.86 34.5125 142.072 34.5125C144.255 34.5125 146.044 35.4565 146.523 36.8515L147.467 39.6134C150.312 47.8565 157.298 55.3952 167.679 61.3838L169.243 62.2715C179.722 68.3165 192.949 72.4029 207.499 74.0797L212.288 74.6293C214.513 74.8829 216.063 76.1229 216.049 77.6588C216.049 79.1947 214.485 80.4488 212.232 80.6884L207.907 81.1534C193.005 82.7597 179.469 86.8743 168.764 93.0461C158.172 99.1615 151.129 106.855 148.396 115.31L147.593 117.804C147.129 119.227 145.34 120.185 143.128 120.185V120.199ZM142.072 36.5275C140.635 36.5275 139.649 37.1052 139.522 37.5138L138.72 40.0079C135.846 48.9415 128.493 57.0297 117.45 63.3988C106.506 69.7115 92.7023 73.9247 77.5182 75.5593L73.194 76.0243C71.8699 76.1652 71.1797 76.7711 71.1797 77.0529C71.1797 77.3347 71.8418 77.9265 73.1517 78.0675L77.9408 78.617C92.7728 80.322 106.267 84.4929 116.972 90.6788L118.521 91.5665C129.325 97.8088 136.621 105.714 139.621 114.436L140.579 117.198C140.72 117.607 141.677 118.17 143.128 118.17C144.565 118.17 145.551 117.592 145.678 117.184L146.481 114.69C149.368 105.756 156.721 97.6679 167.75 91.2988C178.694 84.9861 192.512 80.7729 207.682 79.1384L212.006 78.6734C213.33 78.5325 214.02 77.9265 214.02 77.6447C214.02 77.3629 213.358 76.7711 212.034 76.6302L207.259 76.0806C192.427 74.3756 178.934 70.2047 168.229 64.0188L166.721 63.1593C155.862 56.8888 148.565 48.9838 145.565 40.2615L144.621 37.4997C144.48 37.0911 143.509 36.5275 142.072 36.5275Z" fill="#00283B" />
        <path d="M172.13 76.25V75.7709V75.7428V69.9937H170.398C168.975 67.6969 166.665 65.5409 163.482 63.695C151.946 57.03 133.254 57.03 121.718 63.695C118.535 65.5409 116.225 67.6969 114.802 69.9937H113.084V75.7287C113.084 75.7287 113.084 75.7569 113.084 75.785V76.2641H113.098C113.309 80.4632 116.183 84.62 121.732 87.8328C133.268 94.4978 151.96 94.4978 163.496 87.8328C169.046 84.6341 171.919 80.4632 172.13 76.2641H172.144L172.13 76.25Z" fill="#00283B" />
        <path d="M142.607 93.7931C134.86 93.7931 127.113 92.0881 121.226 88.6922C115.958 85.6486 112.788 81.6186 112.183 77.2645H112.098V75.7849V68.9931H114.281C115.845 66.6681 118.183 64.5968 121.24 62.8354C133.043 56.0295 152.228 56.0295 164.017 62.8354C167.074 64.5968 169.412 66.6681 170.975 68.9931H173.159V77.2645H173.06C172.454 81.6186 169.285 85.6486 164.017 88.6922C158.115 92.1022 150.382 93.7931 142.635 93.7931H142.607ZM114.084 75.9399V76.2077C114.31 80.2236 117.197 84.0422 122.226 86.9449C133.466 93.4408 151.749 93.4408 162.975 86.9449C168.017 84.0281 170.905 80.2236 171.102 76.2077V75.7708V71.0081H169.82L169.525 70.529C168.13 68.2886 165.933 66.2877 162.961 64.5686C151.735 58.0727 133.452 58.0727 122.212 64.5686C119.239 66.2877 117.042 68.2886 115.648 70.529L115.352 71.0081H114.084V75.9399Z" fill="#00283B" />
        <path d="M163.482 58.2839C175.018 64.9489 175.018 75.7425 163.482 82.4075C151.946 89.0725 133.254 89.0725 121.718 82.4075C110.182 75.7425 110.182 64.9489 121.718 58.2839C133.254 51.6189 151.946 51.6189 163.482 58.2839Z" fill="#B0E2FA" />
        <path d="M121.761 64.2585C117.31 66.8231 114.591 70.0217 113.577 73.3472C114.619 76.6303 117.324 79.7867 121.733 82.3231C133.269 88.9881 151.96 88.9881 163.496 82.3231C167.947 79.7585 170.666 76.5599 171.68 73.2344C170.637 69.9512 167.933 66.7949 163.524 64.2585C151.988 57.5935 133.297 57.5935 121.761 64.2585Z" fill="#F5FCFF" />
        <path d="M142.607 88.3822C134.86 88.3822 127.113 86.6772 121.225 83.2812C115.324 79.8712 112.07 75.2776 112.07 70.3458C112.07 65.414 115.324 60.8203 121.225 57.4103C133.029 50.6044 152.213 50.6044 164.003 57.4103C169.905 60.8203 173.158 65.414 173.158 70.3458C173.158 75.2776 169.905 79.8712 164.003 83.2812C158.101 86.6912 150.368 88.3822 142.621 88.3822H142.607ZM142.607 54.2822C135.226 54.2822 127.846 55.9026 122.225 59.1435C116.972 62.1731 114.084 66.1467 114.084 70.3317C114.084 74.5167 116.972 78.4903 122.225 81.5199C133.466 88.0158 151.749 88.0158 162.975 81.5199C168.229 78.4903 171.116 74.5167 171.116 70.3317C171.116 66.1467 168.229 62.1731 162.975 59.1435C157.355 55.9026 149.974 54.2822 142.593 54.2822H142.607Z" fill="#00283B" />
        <path d="M141.325 143.971L141.269 122.835C141.269 121.651 142.227 120.679 143.41 120.679C144.593 120.679 145.565 121.637 145.565 122.821L145.621 143.957C145.621 145.141 144.664 146.113 143.48 146.113C142.297 146.113 141.325 145.155 141.325 143.971Z" fill="#B0E2FA" />
        <path d="M143.466 147.127C142.621 147.127 141.832 146.803 141.241 146.211C140.649 145.62 140.311 144.83 140.311 143.985L140.255 122.849C140.255 122.003 140.579 121.214 141.17 120.608C141.762 120.016 142.565 119.678 143.396 119.678C144.241 119.678 145.03 120.002 145.621 120.594C146.213 121.186 146.551 121.989 146.551 122.821L146.607 143.957C146.607 144.802 146.283 145.591 145.692 146.197C145.1 146.789 144.297 147.127 143.452 147.127H143.466ZM143.41 121.693C143.1 121.693 142.818 121.82 142.607 122.031C142.396 122.243 142.269 122.539 142.283 122.835L142.34 143.971C142.34 144.281 142.466 144.563 142.678 144.774C142.889 144.985 143.185 145.112 143.48 145.112C143.79 145.112 144.072 145 144.283 144.774C144.495 144.563 144.621 144.267 144.621 143.971L144.565 122.835C144.565 122.525 144.438 122.243 144.227 122.031C144.016 121.82 143.72 121.693 143.424 121.693H143.41Z" fill="#00283B" />
        <path d="M242.585 86.3672C242.585 86.3672 242.585 86.3108 242.585 86.2826V84.2958H239.825C239.247 84.2113 238.627 84.2113 238.05 84.2958H235.289V86.2826C235.289 86.2826 235.289 86.3108 235.289 86.339V86.3954V86.4517C235.317 86.9731 235.669 87.424 236.36 87.8186C237.782 88.6358 240.092 88.6358 241.515 87.8186C242.191 87.424 242.543 86.9167 242.571 86.3954V86.3672H242.585Z" fill="#B0E2FA" />
        <path d="M238.937 89.4389C237.824 89.4389 236.712 89.1853 235.852 88.6921C234.852 88.1144 234.331 87.3816 234.275 86.5221V86.4657V86.2121V83.2812H238.036C238.571 83.1966 239.275 83.1966 239.951 83.2812H243.571V86.4657C243.529 87.3253 242.966 88.1144 242.008 88.678C241.149 89.1712 240.036 89.4248 238.923 89.4248L238.937 89.4389ZM236.303 85.2962V86.5221C236.303 86.5221 236.529 86.7475 236.867 86.9448C237.965 87.5789 239.909 87.5789 241.022 86.9448C241.416 86.7194 241.571 86.4798 241.585 86.3389V85.2962H239.839C239.205 85.2116 238.698 85.2116 238.205 85.2962H236.317H236.303Z" fill="#00283B" />
        <path d="M245.952 80.956C245.952 80.956 245.952 80.8433 245.952 80.7869V76.9542H240.656C239.529 76.7851 238.36 76.7851 237.247 76.9542H231.951V80.7869C231.951 80.7869 231.951 80.8433 231.951 80.8997V80.9983V81.111C232.008 82.0974 232.698 82.971 234.008 83.7319C236.754 85.3101 241.191 85.3101 243.924 83.7319C245.234 82.971 245.91 81.9847 245.966 80.9983V80.956H245.952Z" fill="#00283B" />
        <path d="M238.937 85.9442C236.965 85.9442 235.008 85.5074 233.472 84.6196C231.852 83.6755 230.993 82.5201 230.909 81.1814V81.1251V80.9419V75.9537H237.219C238.289 75.7846 239.557 75.7846 240.783 75.9537H246.952V81.0405C246.868 82.4355 245.966 83.7037 244.403 84.6055C242.867 85.4933 240.909 85.9301 238.937 85.9301V85.9442ZM232.937 77.9687V81.0828C232.965 81.6887 233.486 82.2946 234.486 82.8724C236.909 84.2674 240.98 84.2674 243.402 82.8724C244.36 82.3228 244.91 81.6464 244.952 80.956V80.8996L245.966 80.9701L244.952 80.8574V77.9687H240.656C239.487 77.8137 238.402 77.8137 237.388 77.9687H232.951H232.937Z" fill="#00283B" />
        <path d="M245.825 76.0385C245.445 74.3898 244.05 69.8103 238.937 69.8103C233.824 69.8103 232.43 74.3898 232.05 76.0385C231.965 76.2921 231.923 76.5598 231.923 76.8276C231.923 77.8703 232.613 78.8989 233.979 79.7021C236.726 81.2803 241.163 81.2803 243.895 79.7021C245.614 78.7158 246.248 77.3348 245.811 76.0526L245.825 76.0385Z" fill="#B0E2FA" />
        <path d="M245.825 78.5465C245.445 76.8979 244.05 72.3184 238.937 72.3184C233.824 72.3184 232.43 76.8979 232.05 78.5465C231.965 78.8002 231.923 79.0679 231.923 79.3356C231.923 80.3784 232.613 81.407 233.979 82.2102C236.726 83.7884 241.163 83.7884 243.895 82.2102C245.614 81.2238 246.248 79.8429 245.811 78.5606L245.825 78.5465Z" fill="#F5FCFF" />
        <path d="M238.937 81.8861C236.965 81.8861 235.008 81.4493 233.472 80.5616C231.824 79.6034 230.909 78.2788 230.909 76.8275C230.909 76.447 230.965 76.0807 231.092 75.7143C231.543 73.7134 233.261 68.7957 238.923 68.7957C244.543 68.7957 246.29 73.6288 246.783 75.7707C247.361 77.5461 246.445 79.3779 244.388 80.5757C242.853 81.4634 240.895 81.9002 238.923 81.9002L238.937 81.8861ZM238.937 70.8107C234.881 70.8107 233.5 74.2207 233.036 76.2497C232.951 76.5034 232.923 76.6584 232.923 76.8134C232.923 77.5179 233.486 78.2366 234.472 78.8143C236.895 80.2093 240.966 80.2093 243.388 78.8143C243.966 78.4761 245.276 77.5743 244.867 76.3625L244.839 76.2638C244.374 74.2347 243.008 70.8247 238.937 70.8247V70.8107Z" fill="#00283B" />
        <path d="M147.086 155.117C147.086 155.117 147.086 155.06 147.086 155.032V153.045H144.326C143.748 152.961 143.128 152.961 142.551 153.045H139.79V155.032C139.79 155.032 139.79 155.06 139.79 155.089V155.145V155.201C139.818 155.723 140.17 156.174 140.861 156.568C142.283 157.385 144.593 157.385 146.016 156.568C146.692 156.174 147.044 155.666 147.072 155.145V155.117H147.086Z" fill="#B0E2FA" />
        <path d="M143.438 158.188C142.325 158.188 141.227 157.935 140.354 157.442C139.353 156.864 138.832 156.131 138.79 155.272V155.215V154.976V152.045H142.551C143.086 151.96 143.79 151.96 144.466 152.045H148.086V155.243C148.044 156.103 147.481 156.892 146.523 157.442C145.664 157.935 144.551 158.188 143.438 158.188ZM140.804 154.046V155.272C140.804 155.272 141.03 155.497 141.368 155.694C142.48 156.328 144.41 156.328 145.523 155.694C145.917 155.469 146.072 155.229 146.086 155.088V154.06H144.34C143.706 153.975 143.199 153.975 142.706 154.06H140.818L140.804 154.046Z" fill="#00283B" />
        <path d="M150.453 149.72C150.453 149.72 150.453 149.607 150.453 149.551V145.718H145.157C144.03 145.549 142.861 145.549 141.748 145.718H136.452V149.551C136.452 149.551 136.452 149.607 136.452 149.664V149.762V149.875C136.508 150.861 137.199 151.735 138.509 152.496C141.255 154.074 145.692 154.074 148.425 152.496C149.735 151.735 150.411 150.749 150.467 149.762V149.72H150.453Z" fill="#00283B" />
        <path d="M143.438 154.694C141.466 154.694 139.509 154.257 137.973 153.369C136.353 152.439 135.494 151.284 135.41 149.931V149.875V149.692V144.703H141.72C142.776 144.534 144.072 144.534 145.284 144.703H151.453V149.79C151.368 151.185 150.467 152.453 148.904 153.355C147.368 154.243 145.41 154.68 143.438 154.68V154.694ZM137.438 146.718V149.833C137.466 150.438 137.987 151.044 138.987 151.622C141.41 153.017 145.481 153.017 147.903 151.622C148.861 151.073 149.411 150.396 149.453 149.706V149.649L150.467 149.72L149.453 149.607V146.718H145.157C143.988 146.563 142.903 146.563 141.889 146.718H137.452H137.438Z" fill="#00283B" />
        <path d="M150.326 144.788C149.946 143.14 148.551 138.56 143.438 138.56C138.325 138.56 136.931 143.14 136.551 144.788C136.466 145.042 136.424 145.31 136.424 145.577C136.424 146.62 137.114 147.649 138.48 148.452C141.227 150.03 145.664 150.03 148.396 148.452C150.115 147.466 150.749 146.085 150.312 144.802L150.326 144.788Z" fill="#B0E2FA" />
        <path d="M150.326 147.297C149.946 145.648 148.551 141.068 143.438 141.068C138.325 141.068 136.931 145.648 136.551 147.297C136.466 147.55 136.424 147.818 136.424 148.086C136.424 149.128 137.114 150.157 138.48 150.96C141.227 152.538 145.664 152.538 148.396 150.96C150.115 149.974 150.749 148.593 150.312 147.311L150.326 147.297Z" fill="#F5FCFF" />
        <path d="M143.438 150.65C141.466 150.65 139.509 150.213 137.973 149.325C136.325 148.367 135.41 147.043 135.41 145.591C135.41 145.211 135.48 144.83 135.593 144.478C136.044 142.477 137.762 137.56 143.424 137.56C149.058 137.56 150.791 142.393 151.284 144.535C151.861 146.31 150.946 148.142 148.889 149.34C147.354 150.227 145.396 150.664 143.424 150.664L143.438 150.65ZM143.438 139.575C139.382 139.575 138.001 142.985 137.537 145.014C137.452 145.267 137.424 145.422 137.424 145.577C137.424 146.268 137.987 147 138.973 147.564C141.396 148.959 145.467 148.959 147.889 147.564C148.467 147.226 149.777 146.324 149.368 145.112L149.34 145.014C148.875 142.985 147.509 139.575 143.438 139.575Z" fill="#00283B" />
        <path d="M50.7557 86.4517C50.7557 86.4517 50.7557 86.3953 50.7557 86.3671V84.3803H47.995C47.4175 84.2958 46.7977 84.2958 46.2202 84.3803H43.4595V86.3671C43.4595 86.3671 43.4595 86.3953 43.4595 86.4235V86.4798V86.5362C43.4876 87.0576 43.8398 87.5085 44.53 87.903C45.9526 88.7203 48.2626 88.7203 49.6852 87.903C50.3613 87.5085 50.7135 87.0012 50.7417 86.4798V86.4517H50.7557Z" fill="#B0E2FA" />
        <path d="M47.1076 89.5095C45.9949 89.5095 44.8962 89.2558 44.0229 88.7626C43.0228 88.1849 42.5017 87.4522 42.4453 86.5786V86.5363V86.3108V83.3517H46.2061C46.7414 83.2672 47.4456 83.2672 48.1218 83.3517H51.7417V86.494C51.6854 87.3958 51.136 88.1849 50.1782 88.7486C49.319 89.2417 48.2063 89.4954 47.0935 89.4954L47.1076 89.5095ZM44.4736 86.5926C44.4736 86.5926 44.6426 86.7899 45.037 87.0154C46.1498 87.6495 48.0795 87.6495 49.1782 87.0154C49.5726 86.7899 49.7275 86.5504 49.7416 86.4095V85.3667H47.995C47.3611 85.2822 46.8541 85.2822 46.3611 85.3667H44.4736V86.5926Z" fill="#00283B" />
        <path d="M54.1225 81.041C54.1225 81.041 54.1225 80.9283 54.1225 80.8719V77.0392H48.8264C47.6996 76.8701 46.5305 76.8701 45.4177 77.0392H40.1216V80.8719C40.1216 80.8719 40.1216 80.9283 40.1216 80.9846V81.0833V81.196C40.1779 82.1824 40.8681 83.056 42.1781 83.8169C44.9247 85.3951 49.3616 85.3951 52.0942 83.8169C53.4042 83.056 54.0803 82.0696 54.1366 81.0833V81.041H54.1225Z" fill="#00283B" />
        <path d="M47.1078 86.015C45.1359 86.015 43.178 85.5782 41.6427 84.6904C40.0228 83.7463 39.1636 82.5909 39.0791 81.2522V81.1959V81.0268V76.0245H45.3894C46.4599 75.8554 47.7417 75.8554 48.953 76.0245H55.1224V81.1677C55.052 82.5204 54.1505 83.7745 52.573 84.6904C51.0377 85.5782 49.0798 86.015 47.1078 86.015ZM41.1074 78.0395V81.1395C41.1356 81.7595 41.6567 82.3654 42.6568 82.9432C45.0795 84.3382 49.1502 84.3382 51.5729 82.9432C52.5307 82.3936 53.08 81.7172 53.1082 81.0268H54.1224L53.1082 80.9V78.0536H48.8122C47.6431 77.8986 46.5585 77.8986 45.5443 78.0536H41.1074V78.0395Z" fill="#00283B" />
        <path d="M53.9956 76.109C53.6153 74.4604 52.2208 69.8809 47.1078 69.8809C41.9948 69.8809 40.6003 74.4604 40.22 76.109C40.1355 76.3627 40.0933 76.6304 40.0933 76.8981C40.0933 77.9409 40.7835 78.9695 42.1497 79.7727C44.8964 81.3509 49.3333 81.3509 52.0659 79.7727C53.7843 78.7863 54.4182 77.4054 53.9815 76.1231L53.9956 76.109Z" fill="#B0E2FA" />
        <path d="M53.9956 79.1244C53.6153 77.4758 52.2208 72.8962 47.1078 72.8962C41.9948 72.8962 40.6003 77.4758 40.22 79.1244C40.1355 79.3781 40.0933 79.6458 40.0933 79.9135C40.0933 80.9562 40.7835 81.9849 42.1497 82.7881C44.8964 84.3662 49.3333 84.3662 52.0659 82.7881C53.7843 81.8017 54.4182 80.4208 53.9815 79.1385L53.9956 79.1244Z" fill="#F5FCFF" />
        <path d="M47.1078 81.9708C45.1359 81.9708 43.178 81.534 41.6427 80.6463C39.9947 79.6881 39.0791 78.3636 39.0791 76.9122C39.0791 76.5317 39.1495 76.1654 39.2622 75.799C39.7129 73.784 41.4314 68.8804 47.0937 68.8804C52.7138 68.8804 54.4604 73.7136 54.9534 75.8554C55.5309 77.6308 54.6154 79.4626 52.5589 80.6604C51.0236 81.5481 49.0657 81.9849 47.0937 81.9849L47.1078 81.9708ZM47.1078 70.8954C43.0512 70.8954 41.6708 74.3054 41.2201 76.3345C41.1356 76.5881 41.1074 76.7431 41.1074 76.8981C41.1074 77.5886 41.6708 78.3213 42.6568 78.8849C45.0795 80.2799 49.1502 80.2799 51.5729 78.8849C52.1504 78.5467 53.4604 77.6449 53.0378 76.4331L53.0096 76.3345C52.5448 74.3054 51.1785 70.8954 47.1078 70.8954Z" fill="#00283B" />
        <path d="M143.438 161.584C136.973 161.584 130.888 160.119 126.296 157.47C122.676 155.384 120.267 152.707 119.324 149.748L121.253 149.142C122.042 151.622 124.127 153.891 127.31 155.737C131.606 158.217 137.325 159.584 143.438 159.584C149.551 159.584 155.284 158.217 159.566 155.737L160.157 156.568L160.608 157.47C155.988 160.133 149.903 161.599 143.438 161.599V161.584ZM165.609 144.802C164.82 142.336 162.721 140.082 159.566 138.25C155.27 135.77 149.537 134.403 143.438 134.403C137.339 134.403 131.606 135.77 127.31 138.25L126.268 136.517L126.31 136.489C130.902 133.839 136.987 132.374 143.452 132.374C149.917 132.374 156.002 133.839 160.594 136.489C164.186 138.574 166.595 141.223 167.538 144.168L165.623 144.788L165.609 144.802Z" fill="#00283B" />
        <path d="M143.438 158.653C138.339 158.653 133.522 157.498 129.888 155.398C126.085 153.2 123.986 150.213 123.986 146.986C123.986 143.759 126.085 140.786 129.888 138.574C133.522 136.474 138.325 135.319 143.438 135.319C146.903 135.319 150.297 135.854 153.255 136.883L152.593 138.785C149.847 137.827 146.678 137.334 143.438 137.334C138.691 137.334 134.24 138.391 130.902 140.321C127.747 142.139 126 144.506 126 146.972C126 149.438 127.733 151.805 130.902 153.637C134.24 155.567 138.691 156.624 143.438 156.624C148.185 156.624 152.636 155.567 155.974 153.637L157.059 155.342L156.988 155.384C153.354 157.484 148.537 158.639 143.438 158.639V158.653Z" fill="#00283B" />
        <path d="M47.1077 92.9757C40.6424 92.9757 34.5575 91.5102 29.9657 88.8611C25.1907 86.1134 22.5708 82.3793 22.5708 78.3775C22.5708 74.3757 25.1907 70.6557 29.9657 67.8938C34.5575 65.2448 40.6424 63.7793 47.1077 63.7793C49.3191 63.7793 51.5023 63.9484 53.6292 64.3007L53.3053 66.2875C51.2911 65.9634 49.2064 65.7943 47.1077 65.7943C40.9946 65.7943 35.2759 67.1611 30.9798 69.6411C26.8669 72.0225 24.5991 75.1225 24.5991 78.3775C24.5991 81.6325 26.8669 84.7325 30.9798 87.1138C35.2759 89.5938 40.9946 90.9607 47.1077 90.9607C53.2208 90.9607 58.9395 89.5938 63.2355 87.1138L64.3342 88.8048L64.2215 88.8752C59.6578 91.5102 53.5729 92.9757 47.1077 92.9757Z" fill="#00283B" />
        <path d="M47.1078 90.0306C42.0088 90.0306 37.1916 88.8751 33.5576 86.7756C29.7545 84.5774 27.6558 81.5901 27.6558 78.3633C27.6558 75.1365 29.7545 72.1492 33.5576 69.951C37.1916 67.8515 42.0088 66.696 47.1078 66.696C48.8403 66.696 50.5728 66.8369 52.249 67.1047L51.925 69.0915C50.3615 68.8379 48.7417 68.711 47.1078 68.711C42.361 68.711 37.8959 69.7679 34.5717 71.6983C31.4166 73.516 29.67 75.8833 29.67 78.3633C29.67 80.8433 31.4025 83.1965 34.5717 85.0142C37.91 86.9447 42.361 88.0015 47.1078 88.0015C51.8546 88.0015 56.3056 86.9447 59.6438 85.0142L60.6721 86.7474C57.024 88.861 52.2067 90.0165 47.1078 90.0165V90.0306Z" fill="#00283B" />
        <path d="M238.938 92.9757C232.472 92.9757 226.387 91.5102 221.796 88.8611C219.697 87.6493 218.007 86.2543 216.767 84.6902L218.345 83.4361C219.429 84.7888 220.922 86.0288 222.796 87.1138C227.092 89.5938 232.825 90.9607 238.924 90.9607C245.023 90.9607 250.755 89.5938 255.051 87.1138L255.643 87.9452L256.094 88.847C251.474 91.5102 245.389 92.9757 238.938 92.9757ZM259.502 73.2907C258.418 71.9379 256.925 70.712 255.065 69.6411C250.769 67.1611 245.051 65.7943 238.938 65.7943C232.825 65.7943 227.106 67.1611 222.81 69.6411L221.796 67.8938C226.402 65.2448 232.486 63.7793 238.938 63.7793C245.389 63.7793 251.488 65.2448 256.08 67.8938C258.15 69.0916 259.84 70.4866 261.08 72.0225L259.502 73.2907Z" fill="#00283B" />
        <path d="M238.938 90.0307C233.839 90.0307 229.022 88.8752 225.387 86.7757C223.725 85.8175 222.373 84.6902 221.387 83.4502L222.965 82.1961C223.796 83.2388 224.951 84.197 226.388 85.0284C229.726 86.9588 234.177 88.0157 238.924 88.0157C243.67 88.0157 248.121 86.9588 251.46 85.0284L252.544 86.7334L252.474 86.7757C248.84 88.8752 244.037 90.0307 238.924 90.0307H238.938ZM254.882 74.5166C254.051 73.4879 252.91 72.5438 251.474 71.7125C248.135 69.782 243.684 68.7252 238.938 68.7252C234.191 68.7252 229.726 69.782 226.402 71.7125L225.345 69.9934L225.387 69.9652C229.022 67.8657 233.825 66.7102 238.924 66.7102C244.023 66.7102 248.84 67.8657 252.474 69.9652C254.122 70.9093 255.46 72.0225 256.446 73.2484L254.868 74.5166H254.882Z" fill="#00283B" />
        <path d="M142.128 29.5526C135.663 29.5526 129.578 28.0871 124.986 25.438C123.352 24.4939 121.958 23.423 120.845 22.2676L122.296 20.8726C123.282 21.9012 124.536 22.8453 126 23.6907C130.297 26.1707 136.015 27.5376 142.128 27.5376C148.241 27.5376 153.974 26.1707 158.256 23.6907L158.834 24.5221L159.284 25.4239C154.664 28.0871 148.579 29.5526 142.114 29.5526H142.128ZM161.933 9.00802C160.947 7.99347 159.707 7.04938 158.256 6.21802C153.96 3.73802 148.241 2.3712 142.128 2.3712C136.015 2.3712 130.282 3.73802 126 6.21802L124.944 4.49893L124.986 4.47075C129.578 1.82166 135.663 0.356201 142.128 0.356201C148.594 0.356201 154.678 1.82166 159.27 4.47075C160.89 5.40075 162.271 6.45756 163.383 7.61302L161.933 9.02211V9.00802Z" fill="#00283B" />
        <path d="M142.114 26.6217C137.015 26.6217 132.198 25.4663 128.564 23.3667C127.268 22.6199 126.155 21.7745 125.268 20.8444L126.719 19.4495C127.479 20.2385 128.437 20.9713 129.578 21.6195C132.916 23.5499 137.367 24.6067 142.114 24.6067C146.861 24.6067 151.312 23.5499 154.65 21.6195L155.735 23.3244L155.664 23.3667C152.03 25.4663 147.213 26.6217 142.114 26.6217ZM157.482 10.4454C156.721 9.67036 155.777 8.93763 154.65 8.30354C151.312 6.37309 146.861 5.31627 142.114 5.31627C137.367 5.31627 132.916 6.37309 129.578 8.30354L128.55 6.57036C132.198 4.45672 137.015 3.30127 142.114 3.30127C147.213 3.30127 152.03 4.45672 155.664 6.55627C156.946 7.289 158.045 8.13445 158.932 9.05036L157.482 10.4595V10.4454Z" fill="#00283B" />
    </SvgIcon>
)

export default TruckDron