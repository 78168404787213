import {
    getInvoiceIdLoading,
    getInvoiceIdRequest,
    getInvoiceIdSuccess,
    getInvoiceIdReject,
} from "../../actions/invoice/getId";

export const getInvoiceId = (formData) => async (dispatch, getState) => {
    dispatch(getInvoiceIdLoading());
    try {
        const { data } = await getInvoiceIdRequest(formData, getState);
        dispatch(getInvoiceIdSuccess(data))
    } catch (error) {
        dispatch(getInvoiceIdReject(error))
    }
    return Promise.resolve();
};