import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const DELETE_TRANSPORT_BRAND_RESET = 'DELETE_TRANSPORT_BRAND_RESET';
export const DELETE_TRANSPORT_BRAND_LOADING = 'DELETE_TRANSPORT_BRAND_LOADING';
export const DELETE_TRANSPORT_BRAND_SUCCESS = 'DELETE_TRANSPORT_BRAND_SUCCESS';
export const DELETE_TRANSPORT_BRAND_REJECT = 'DELETE_TRANSPORT_BRAND_REJECT';

//* ACTIONS ------------------------------------------------
export const deleteTransportBrandReset = () => ({ type: DELETE_TRANSPORT_BRAND_RESET });
export const deleteTransportBrandLoading = () => ({ type: DELETE_TRANSPORT_BRAND_LOADING });
export const deleteTransportBrandSuccess = (payload) => ({ type: DELETE_TRANSPORT_BRAND_SUCCESS, payload });
export const deleteTransportBrandReject = (payload) => ({ type: DELETE_TRANSPORT_BRAND_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const deleteTransportBrandRequest = async (data = {}, getState) => {
    const options = await withToken({
        method: Methods.DELETE,
        data,
    }, getState);
    return request(`/api/TransportBrand/${data.id}`, options);
};