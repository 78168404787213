import React from 'react';
import { lowerCase, get } from 'lodash';
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

import en from "./en"
import es from "./es"
import pt from "./pt"
import ru from "./ru"

const I18n = ({ children }) => {

    const defaultLanguage = lowerCase(process.env.REACT_APP_START_LANG || "1");

    const getCurrentLang = () => {

        let startLang = defaultLanguage
        const preSelect = localStorage.getItem("lang");
        
        try {
            if (!!preSelect) {
                startLang = preSelect;
            } else {
                const dataUser = get(JSON.parse(get(JSON.parse(window.localStorage.getItem("persist:tms")), "auth", "")), "login.dataUser", "")
                startLang = get(dataUser, "language", defaultLanguage)
            }
        } catch (error) {
            startLang = defaultLanguage
        }

        return startLang;
    }

    i18next.init({
        interpolation: { escapeValue: false },
        lng: getCurrentLang(),
        resources: {
            "1": es,
            "2": en,
            "3": pt,
            "4": ru,
        }
    })

    return (
        <I18nextProvider i18n={i18next} >
            {children}
        </I18nextProvider>
    )
}

export default I18n