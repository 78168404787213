import {
    deleteClientItemLoading,
    deleteClientItemRequest,
    deleteClientItemSuccess,
    deleteClientItemReject,
    deleteClientItemReset
} from "../../actions/client/deleteItem";

export const deleteClientItem = (formData) => async (dispatch, getState) => {
    dispatch(deleteClientItemLoading());
    try {
        const { data } = await deleteClientItemRequest(formData, getState);
        dispatch(deleteClientItemSuccess(data))
    } catch (error) {
        dispatch(deleteClientItemReject(error))
    } finally {
        setTimeout(() => { dispatch(deleteClientItemReset()) }, 3000);
    }
    return Promise.resolve();
};