/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { get, map, toString, pickBy, identity, isEmpty } from "lodash";
import {
  IconButton,
  Pagination,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Button,
  Box
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams, useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import InputText from "../../../components/form/InputText";
import CircularProgress from '@mui/material/CircularProgress';

import Table from "../../../components/form/Table";
import Notification from "../../../components/form/Notification";
import Alert from "../../../components/form/Alert";
import Question from "../../../components/form/Question";
import ItemModal from "./ItemModal"

import { getOrderItem } from "../../../store/transactions/thunk/order/getItem";
import { putOrderItem } from "../../../store/transactions/thunk/order/putItem"
import { deleteOrderItem } from "../../../store/transactions/thunk/order/deleteItem";

const Item = ({ setBtns }) => {
  const [__] = useTranslation("order");
  const dispatch = useDispatch();
  const { id } = useParams();
  const PageSize = 10

  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([])
  const [toSelected, setToSelected] = useState({});
  const [toEdit, setToEdit] = useState({});
  const [valueQuantity, setValueCuantity] = useState();
  const [showNoti, setShowNoti] = useState({ open: false, msg: "", variant: "error", action: "post" })
  const [alert, setAlert] = useState({ open: false, title: "", subtitle: "", type: "" });
  const [openModal, setOpenModal] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(null);

  const item = useSelector(state => state.transactions.order.getItem);
  const put = useSelector(state => state.transactions.order.putItem);
  const delet = useSelector(state => state.transactions.order.deleteItem);

  const getData = ({ page, filter }) => {
    const Filters = encodeURI(`idOrder==${id}`)
    const parmas = { page, Filters, PageSize }
    dispatch(getOrderItem(parmas))
  }

  const reload = () => getData({ page: 1, filter })

  useEffect(() => {
    setAnchorEl(false)
    // getData({ page: 1, filter })
  }, [dispatch])

  useEffect(() => {
    setBtns(
      <Stack className='mr-4' direction="row" spacing={1} justifyContent="flex-end">
        <Button className='w-[100px] min-w-[100px]' variant="contained" color="secondary" onClick={() => setOpenModal(true)}>{__(`toolbar.btn`)}</Button>
        <IconButton title={__(`toolbar.reload`)} onClick={reload}><ReplayIcon sx={{ transform: "scaleX(-1) rotate(-45deg)" }} /></IconButton>
      </Stack>
    )
  }, [])

  const handleOpenMenu = (row) => (event) => {
    setToSelected(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setToSelected({})
    setAnchorEl(null);
  };

  const onCancel = () => {
    setValueCuantity(undefined)
    setToEdit({})
    setToSelected({})
    setOpenQuestion(false)
  }

  const onDelete = () => {
    dispatch(deleteOrderItem({ id: get(toSelected, "idOrderItem") }))
    setToSelected({})
  }
  const handleDelete = (row) => () => {
    setAnchorEl(null)
    setOpenQuestion(true)
    setToSelected(row)
  }

  const onEdit = (row) => () => {
    if (!!valueQuantity) {
      const body = { ...row, quantity: valueQuantity }
      delete body.clientItem
      dispatch(putOrderItem(body))
    } else {
      onCancel()
    }
  }

  //  --------- Delete -------------

  const closeAlert = () => {
    setAlert({ open: false, title: "", subtitle: "", type: "", btn: "" })
  }

  const setError = (err, action) => {
    if (!isEmpty(err) && !!get(err, "Message", "")) {
      setAlert({
        open: true,
        title: get(err, "Message", ""),
        subtitle: (<ul>{map(get(err, "ValidationError", []), (item) => <li>{`• ${item}`}</li>)}</ul>),
        type: "error",
        btn: __(`${module}.actions.close`),
        func: closeAlert
      })
    } else {
      setAlert({
        open: true,
        type: "default",
        btn: __(`${module}.actions.close`),
        func: closeAlert
    })
    }
    handleCloseMenu()
  }

  useEffect(() => {
    if (get(put, "isSuccess", false)) { onCancel(); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "put" }) }
    if (get(put, "isReject", false)) { setError(get(put, "error", {}), "put") }
    if (get(delet, "isSuccess", false)) { setOpenQuestion(false); reload(); setShowNoti({ open: true, msg: "", variant: "success", action: "delete" }) }
    if (get(delet, "isReject", false)) { setError(get(delet, "error", {}), "delete") }
  }, [delet, put])

  const headTable = [
    {
      key: "clientItem.erpCode",
      label: __(`table.colsItems.erpCode`),
      align: "left",
    },
    {
      key: "clientItem.name",
      label: __(`table.colsItems.name`),
      align: "left",
    },
    {
      key: "value",
      label: __(`table.colsItems.price`),
      align: "center"
    },
    {
      key: "clientItem.weight",
      label: __(`table.colsItems.weight`),
      align: "center"
    },
    {
      key: "clientItem.volume",
      label: __(`table.colsItems.volume`),
      align: "center"
    },
    {
      key: "quantity",
      label: __(`table.colsItems.quantity`),
      align: "center",
    },
    {
      key: "status",
      label: __(`table.cols.isActive`),
      align: "center"
    },
    {
      key: "options",
      label: __(`table.colsItems.options`),
      align: "center",
      sx: { position: "sticky", right: 0, pr: 1, background: "linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 87%, 1) 8px, hsla(0, 0%, 100%, 1) 8px, hsla(0, 0%, 100%, 1) 100%)" }
    },
  ]

  const dataTable = map(get(item, "data", []), (row, i) => ({
    ...row,
    quantity: toEdit.idOrderItem === row.idOrderItem ? (
      <Box className='flex justify-center items-center' sx={{ ".MuiInputBase-root": { height: 25 } }}>
        <InputText name="quantity" size="small" defaultValue={row.quantity} sx={{ width: 75, }} fullWidth={false} onChange={(e) => setValueCuantity(e.target.value)} />
        <div className='pl-4'>
          <IconButton aria-label="edit" size="small" onClick={onEdit(row)}>
            {get(put, "isLoading", false)
              ? <CircularProgress size={20} />
              : <EditIcon fontSize="inherit" />
            }
          </IconButton>
        </div>
        <div className='pl-2'>
          <IconButton aria-label="delete" size="small" onClick={onCancel}>
            <HighlightOffIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Box>
    ) : row.quantity,
    status: <Chip label={__(`isActive.${get(row, "clientItem.isActive", false)}`)} color={get(row, "clientItem.isActive", false) ? "success" : "error"} />,
    value: `$ ${get(row, "clientItem.price", 0)?.toFixed(2)}`,
    options: <IconButton aria-label="more" size="small" onClick={handleOpenMenu(row)}><MoreVertIcon fontSize="inherit" /></IconButton>
  }))

  const onChangePagination = (e, page) => getData({ page, filter })

  return (
    <div className='mt-4'>
      <Table headTable={headTable}
        dataTable={dataTable}
        __={__}
        sizeFilters={125}
        loading={get(item, "isLoading", false)}
        empty="items"
      />
      {get(item, "isSuccess", true) &&
        <Stack sx={{ mt: 2 }} alignItems="flex-end">
          <Pagination
            count={(Math.trunc((get(item, "totalRecords", 1) + PageSize) / PageSize))}
            page={get(item, "pageNumber", 1)}
            onChange={onChangePagination}
            color="secondary"
            sx={{ "& li > button": { borderRadius: 1 } }}
          />
        </Stack>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <MenuItem onClick={() => { setToEdit(toSelected); setAnchorEl(null) }}>{__(`table.menu.edit`)}</MenuItem>
        <MenuItem onClick={handleDelete(toSelected)}>{__(`table.menu.delete`)}</MenuItem>
      </Menu>
      <Notification __={__} showNoti={showNoti} setShowNoti={setShowNoti} />
      <Alert
        title={get(alert, "title")}
        subtitle={get(alert, "subtitle")}
        btn1={{ func: get(alert, "func") }}
        btn2={{}}
        type={get(alert, "type")}
        openAlert={get(alert, "open")}
        closeAlert={closeAlert}
      />
      <ItemModal
        __={__}
        open={openModal}
        close={() => setOpenModal(false)}
        showNoti={showNoti}
        setShowNoti={setShowNoti}
        alert={alert}
        setAlert={setAlert}
        setError={setError}
        reload={reload}
        selected={selected}
        setSelected={setSelected}
        idOrder={id}
      />
      <Question
        title={__("alert.deleteitem.title")}
        detail={__("alert.deleteitem.detail")}
        onSuccess={onDelete}
        onCancel={onCancel}
        open={openQuestion}
        loading={get(delet, "isLoading")}
      />
    </div>
  )
}

export default Item;
