import Driver from "../../../../pages/Masters/Driver";
import DriverNewEdit from "../../../../pages/Masters/Driver/NewEdit";

const routerDriver = [
    {
        key: "driver",
        path: "/masters/driver",
        element: <Driver />,
    },
    {
        key: "driver",
        path: "/masters/driver/new",
        element: <DriverNewEdit />,
    },
    {
        key: "driver",
        path: "/masters/driver/:id",
        element: <DriverNewEdit />,
    },
]

export default routerDriver
