import request, { Methods, withToken } from "../../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const POST_UPLOAD_INVOICE_DETAILS_RESET = 'POST_UPLOAD_INVOICE_DETAILS_RESET';
export const POST_UPLOAD_INVOICE_DETAILS_LOADING = 'POST_UPLOAD_INVOICE_DETAILS_LOADING';
export const POST_UPLOAD_INVOICE_DETAILS_SUCCESS = 'POST_UPLOAD_INVOICE_DETAILS_SUCCESS';
export const POST_UPLOAD_INVOICE_DETAILS_REJECT = 'POST_UPLOAD_INVOICE_DETAILS_REJECT';

//* ACTIONS ------------------------------------------------
export const postUploadInvoiceDetailsReset = () => ({ type: POST_UPLOAD_INVOICE_DETAILS_RESET });
export const postUploadInvoiceDetailsLoading = () => ({ type: POST_UPLOAD_INVOICE_DETAILS_LOADING });
export const postUploadInvoiceDetailsSuccess = (payload) => ({ type: POST_UPLOAD_INVOICE_DETAILS_SUCCESS, payload });
export const postUploadInvoiceDetailsReject = (payload) => ({ type: POST_UPLOAD_INVOICE_DETAILS_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const postUploadInvoiceDetailsRequest = async ({ idInvoice, orders }, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data: orders,
    }, getState);
    return request(`/api/Invoices/UploadInvoiceDetails/${idInvoice}`, options);
};