import Operators from "../../../../pages/Masters/Operators";
import OperatorsNewEdit from "../../../../pages/Masters/Operators/NewEdit";

const routerOperators = [
    {
        key: "operators",
        path: "/masters/operators",
        element: <Operators />,
    },
    {
        key: "operators",
        path: "/masters/operators/new",
        element: <OperatorsNewEdit />,
    },
    {
        key: "operators",
        path: "/masters/operators/:id",
        element: <OperatorsNewEdit />,
    },
]

export default routerOperators
