import * as yup from 'yup';
import { get } from "lodash";

const validators = (inputs) => (value) => {
    return (
        yup.object({
            login: yup.string().email(get(inputs, "email.error-2")).required(get(inputs, "email.error")),
            pass: yup.string()
                .matches(/^(?=.*[a-z])/, get(inputs, "pass.error2"))
                .matches(/^(?=.*[A-Z])/, get(inputs, "pass.error3"))
                .matches(/^(?=.*[0-9])/, get(inputs, "pass.error4"))
                // .matches(/^(?=.*\W)/, 'Ingrese al menos 1 caracter especial')
                .min(8, get(inputs, "pass.error5"))
                .required(get(inputs, "pass.error")),
        })
    )
}

export default validators
