import request, { Methods, withToken } from  "../../../assets/util/request";

//* ACTIONTYPES --------------------------------------------
export const PUT_PASS_ADMIN_RESET = 'PUT_PASS_ADMIN_RESET';
export const PUT_PASS_ADMIN_LOADING = 'PUT_PASS_ADMIN_LOADING';
export const PUT_PASS_ADMIN_SUCCESS = 'PUT_PASS_ADMIN_SUCCESS';
export const PUT_PASS_ADMIN_REJECT = 'PUT_PASS_ADMIN_REJECT';

//* ACTIONS ------------------------------------------------
export const putPassAdminReset = () => ({ type: PUT_PASS_ADMIN_RESET });
export const putPassAdminLoading = () => ({ type: PUT_PASS_ADMIN_LOADING });
export const putPassAdminSuccess = (payload) => ({ type: PUT_PASS_ADMIN_SUCCESS, payload });
export const putPassAdminReject = (payload) => ({ type: PUT_PASS_ADMIN_REJECT, payload });

//* REQUEST SERVICE ---------------------------------------
export const putPassAdminRequest = async (data, getState) => {
    const options = await withToken({
        method: Methods.POST,
        data,
    }, getState);
    return request(`/api/Users/ChangeAdminConfirmedPass`, options);
};
