import * as yup from 'yup';

const validators = (__) => (value) => {
    return (
        yup.object({
            numberPlate: yup.string().required(__(`form.numberPlate.error`)),
            name: yup.string().required(__(`form.name.error`)),
            vin: yup.string().required(__(`form.vin.error`)),
            description: yup.string().required(__(`form.description.error`)),
            transportBrand: yup.string().required(__(`form.transportBrand.error`)),
            idTransportDivision: yup.string().required(__(`form.idTransportDivision.error`)),
            idTransportFuelType: yup.string().required(__(`form.idTransportFuelType.error`)),
        })
    )
}

export default validators
