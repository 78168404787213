import {
    getDriverIdLoading,
    getDriverIdRequest,
    getDriverIdSuccess,
    getDriverIdReject,
} from "../../actions/driver/getId";

export const getDriverId = (formData) => async (dispatch, getState) => {
    dispatch(getDriverIdLoading());
    try {
        const { data } = await getDriverIdRequest(formData, getState);
        dispatch(getDriverIdSuccess(data))
    } catch (error) {
        dispatch(getDriverIdReject(error))
    }
    return Promise.resolve();
};